import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialLibModule } from '@app/material-lib.module';
import { EditableResolveService } from '@ig-core/resolves/editable-resolve.service';
import { NonEditableResolveService } from '@ig-core/resolves/non-editable-resolve.service';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { DeviceComponent } from './device.component';
import { DeviceEditorComponent } from './device-edit/device-edit.component';

export const deviceRoute: Route = {
  path: 'device-directory',
  canActivate: [AuthenticatedUserService],
  children: [
    { path: '', component: DeviceComponent },

    {
      path: 'update/:uid', component: DeviceEditorComponent,
      resolve: {
        editable: EditableResolveService
      }
    },

    {
      path: 'view/:uid', component: DeviceEditorComponent,
      resolve: {
        editable: NonEditableResolveService
      }
    }]
    
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [DeviceEditorComponent],
  entryComponents: []
})
export class DeviceModule { }
