import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JhiLanguageService } from 'ng-jhipster';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'jhi-loan-od-accounts-udf-editor',
    templateUrl: './loan-od-accounts-udf-editor.component.html',

})
export class LoanOdAccountUdfEditorComponent implements OnInit {

    @Input() classifierValues: any;
    @Input() udfForm: FormGroup;
    @Input() loanOdAccounts: any;
    @Input() udfTextDisplayAsSelect: boolean[];
    @Input() udfTabVisitCount: number;
    @Output() udfTabVisited: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        private jhiLanguageService: JhiLanguageService,
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        if (this.udfTabVisitCount === 0) {
            this.udfTextDisplayAsSelect[0] = this.loanOdAccounts.udfText1 !== null;
            this.udfTextDisplayAsSelect[1] = this.loanOdAccounts.udfText2 !== null;
            this.udfTextDisplayAsSelect[2] = this.loanOdAccounts.udfText3 !== null;
            this.udfTextDisplayAsSelect[3] = this.loanOdAccounts.udfText4 !== null;
            this.udfTextDisplayAsSelect[4] = this.loanOdAccounts.udfText5 !== null;
            this.udfTextDisplayAsSelect[5] = this.loanOdAccounts.udfText6 !== null;
            this.udfTextDisplayAsSelect[6] = this.loanOdAccounts.udfText7 !== null;
            this.udfTextDisplayAsSelect[7] = this.loanOdAccounts.udfText8 !== null;
            this.udfTextDisplayAsSelect[8] = this.loanOdAccounts.udfText9 !== null;
            this.udfTextDisplayAsSelect[9] = this.loanOdAccounts.udfText10 !== null;
            this.udfTextDisplayAsSelect[10] = this.loanOdAccounts.udfText11 !== null;
            this.udfTextDisplayAsSelect[11] = this.loanOdAccounts.udfText12 !== null;
            this.udfTextDisplayAsSelect[12] = this.loanOdAccounts.udfText13 !== null;
            this.udfTextDisplayAsSelect[13] = this.loanOdAccounts.udfText14 !== null;
            this.udfTextDisplayAsSelect[14] = this.loanOdAccounts.udfText15 !== null;
            this.udfTextDisplayAsSelect[15] = this.loanOdAccounts.udfText16 !== null;
            this.udfTextDisplayAsSelect[16] = this.loanOdAccounts.udfText17 !== null;
            this.udfTextDisplayAsSelect[17] = this.loanOdAccounts.udfText18 !== null;
            this.udfTextDisplayAsSelect[18] = this.loanOdAccounts.udfText19 !== null;
            this.udfTextDisplayAsSelect[19] = this.loanOdAccounts.udfText20 !== null;
        }
        this.udfTabVisited.emit(this.udfTabVisitCount);
    }

}
