import { Address } from '../../applicant/applicantAddress/applicant-address.model';

export class ColletralDetailsComposite {
    constructor(
        public colletralDetails?: ColletralDetails[],
    ) { }
}

export class ColletralDetails {
    constructor(
        public address?: Address,
        public applicants?: Applicant,
        public collateral?: Collateral,
        public deleteBtnDisabled?:boolean//added for client side use this paramenter will not come from server
    ) { }
}

export class Collateral {
    constructor(
        public uid?: string,
        public version?: number,
        public collateralType?: string,
        public collateralUse?: string,
        public locationType?: string,
        public addressUid?: string,
        public ownedBy?: string,
        public ownedByUid?: string,
        public buildingArea?: string,
        public landArea?: string,
        public fairMarketValue?: string,
        public evalMarketValue?: string,
        public distance?: string,
        public maxLtv?: string,
        public ltvApplied?: string,
        public eligibleLoan?: string,
        public fileFolderUid?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public agriProperty?:Boolean,
        public advocateName?:string,
        public legalRemarks?:string,
        public techRemarks?:string,
        public visitedBy?:string,
        public valuerName?:string
    ) { }
}

export class Applicant {
    constructor(
        public name?: string,
        public uid?: string,
        public play?: string,
    ) { }
}
