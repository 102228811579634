import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Device, DeviceSearchCriteria } from './device.model';
@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    private API = 'api/devices';
    constructor(private http: HttpClient) { }

    getDeviceData(req: any, searchCriteria: DeviceSearchCriteria): Observable<HttpResponse<Device[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if (searchCriteria != undefined && searchCriteria.branch != null && searchCriteria.branch != "") {
            params = params.set('branch', searchCriteria.branch);
        }
        if (searchCriteria != undefined && searchCriteria.user != null && searchCriteria.user != "") {
            params = params.set('user', searchCriteria.user);
        }
        if (searchCriteria != undefined && searchCriteria.app != null && searchCriteria.app != "") {
            params = params.set('app', searchCriteria.app);
        }
        if (searchCriteria != undefined && searchCriteria.status != null && searchCriteria.status != "") {
            params = params.set('status', searchCriteria.status);
        }
        return this.http.get<any>(this.API, { params, observe: 'response' });
    }

    deviceAction(deviceUid: string, action: string) {
        return this.http.put<any>(this.API + '/' + deviceUid + '/' + action, { observe: 'response' });
    }
}