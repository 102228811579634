export class BranchSet {
    id?: number;
    version?: number;
    name?: string;
    code?: string;
    branchCodes?: string[];
    branches?: BranchSetBranch[];
}

export class BranchSetBranch {
  uid?: number;
  version?: number;
  name?: string;
  code?: string;
}
