export const admin = 'ROLE_ADMIN';
export const user = 'user';
export const sysadmin = 'SYSTEM_ADMIN';

export const KYC_VERIFICATION_MODE_ELECTRONIC = 'ELECTRONIC';

export const BANK_ACCOUNT_VERIFICATION_MODE_PENNY_DROP = 'PENNY_DROP';

export const SOURCE_DIGITAL = 'DIGITAL';

export const SOURCE_MANUAL = 'MANUAL';

export const LINK_TO_TYPE_PERSON = 'person';

export const USER_AGENT_WEB = 'web';

export const BUREAU_VERIFICATION_RULE_STRICT = 'STRICT';

export const BUREAU_VERIFICATION_RULE_LENIENT = 'LENIENT';

export const REQUEST_TYPE_APR = 'APR';

export const REQUEST_TYPE_INIT = 'INIT';
