import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot,  ActivatedRouteSnapshot } from '@angular/router';
import { Observable} from 'rxjs';
import { LoanApplicationComposite } from './applications.model';
import { ApplicationService } from './application.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsResolverService implements Resolve<LoanApplicationComposite> {
  constructor(private applicationService: ApplicationService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    return this.applicationService.getBasicApplicationInfo(route.paramMap.get('applicationUid'), 'fullChain');
  }
}