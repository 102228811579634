import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';

import { takeUntil } from 'rxjs/operators';

import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { ListSource } from '@ig-core/list/list-source';

import { ProductSetService } from '../product-set.service';
import { ProductSetListDataSource } from './product-set-list.data.source';

@Component({
  selector: 'app-product-set',
  templateUrl: 'product-set-list.component.html',
  styleUrls: ['../product-set.component.css']
})
export class ProductSetListComponent implements OnInit, AfterViewInit, OnDestroy {

  pagingAndSortParams: PagingAndSortParams;
  dataSource: ProductSetListDataSource;
  listSource: ListSource;
  showAllProducts = false;

  displayedColumns = ['code', 'name', 'productCodes', 'action'];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  constructor(private productSetService: ProductSetService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.dataSource = new ProductSetListDataSource(this.productSetService);
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        // Load paging and sort params from url
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        let sortColumn = this.pagingAndSortParams.sortColumn;
        if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
          sortColumn = 'id';
        }
        // Load other, params and queryParams
        this.dataSource.loadProductsets(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
          this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize);
      });
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  ngAfterViewInit(): void {
    // initiate sort and pagination change once the view is initialized
    this.listSource.subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadProductsetsPageWithParams());
  }

  loadProductsetsPageWithParams(): void {
    this.router.navigate([], this.getQueryParams());
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  // Function to toggle showAllProducts variable
  toggleShowAllProducts(element: any) {
    element.showAllProducts = !element.showAllProducts;
  }
}
