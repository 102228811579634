import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationService } from "@app/applications/application.service";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { OnDestroy } from "@angular/core";
import { Applicant } from "@app/applications/application-details/collateral/collateralDetails/collateral-details.model";
import { KycProof, DocumentVerificationDTO } from "@app/applications/application-details/business/kycProofs/kyc.model";
import { KycVerificationResponseComposite } from "@app/applications/application-details/applicant/applicantKycProofs/applicant-kyc-proof.model";
import { ReferenceCode } from "@app/admin/reference-code/reference-code.model";
import { ReferenceCodeService } from "@app/admin/reference-code/reference-code.service";
import { KYC_VERIFICATION_MODE_ELECTRONIC } from "@app/constants/data.constants";
import { ConfirmationDialogService } from "@app/utils/confirmation-dialog/confirmation-dialog.service";
@Component({
  selector: "eng-kyc-verification",
  templateUrl: "kyc-verification.template.html",
  styleUrls: [
    "../../../application-details.styles.scss",
    "../xdata-service.style.scss",
  ],
})
export class KycVerificationComponent implements OnInit, OnDestroy {
  applicationUid: string;

  xDataServicesForm: FormGroup;
  selectedKycDocument: ReferenceCode;
  applicants: Applicant[];
  serviceProviderCode: string;
  applicantKycProofs: KycProof[] = [];
  documentVerificationResponse: KycVerificationResponseComposite;
  documentType: string;
  listOfKycDocument: Map<string, KycProof[]> = new Map<string, KycProof[]>();
  isShowResultCard: boolean;
  verificationDto: DocumentVerificationDTO = {};
  isCheckboxSelected: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private referenceCodeService: ReferenceCodeService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.xDataServicesForm = this.formBuilder.group({
      borrower: ["", Validators.required],
      documentProof: ["", Validators.required],
    });
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.applicationUid;
        this.serviceProviderCode = params.xDataService;
        this.documentType = params.serviceType;
      });
  }

  ngOnInit() {
    this.getRefCodes();
    this.getApplicants();
  }

  // since in service type we will get the code of person_proof_doc to show the name of the particular code we are getting the value from refCode
  getRefCodes() {
    this.referenceCodeService
      .getRefCodesForClassifier("person_proof_doc")
      .subscribe((response: any) => {
        response.forEach((element) => {
          if (this.documentType == element.code) {
            this.selectedKycDocument = element;
          }
        });
      });
  }

  getApplicants() {
    this.applicationService
      .getApplicants(this.applicationUid)
      .subscribe((response: any) => {
        if (response) {
          this.applicants = response;
        }
      });
  }

  onInitiateClick() {
    if (this.xDataServicesForm.valid) {
      this.verificationDto.isVerified = false;
      this.isCheckboxSelected = false;
      let partyUid = this.xDataServicesForm.controls.borrower.value.uid;
      let idProofUid = this.xDataServicesForm.controls.documentProof.value;
      this.applicationService
        .getVerifiedDocument(
          this.applicationUid,
          partyUid,
          this.selectedKycDocument.code,
          idProofUid
        )
        .toPromise()
        .then(
          (_success) => {
            this.documentVerificationResponse = _success.body;
            this.isShowResultCard = true;
            this._snackbar.open("Data retrieved successfully", "Close", {
              duration: 5000,
            });
          },
          (failure) => {
            this.isShowResultCard = false;
            this._snackbar.open(failure, "close", {
              duration: 5000,
            });
          }
        );
    }
  }

  // this method is called whenever user changes the dropdown value we need to fetch and show the documents listed for selected user
  // we are using map to avoid multiple api calls if kycProof already fetched for particular user on chance of dropdown we will get value from our map and will display the same else
  // we need to call the api and get the data and save to map.
  getApplicantKycProofs(partyPlay) {
    this.onDocumentNumberOrApplicantChange();
    this.verificationDto.isVerified = false;
    const applicantKycProof = this.listOfKycDocument.get(partyPlay.uid);
    if (applicantKycProof) {
      this.buildForm(partyPlay);
      return;
    }
    this.applicationService
      .getKycProofsOnProofType(
        this.applicationUid,
        partyPlay.uid,
        this.selectedKycDocument.code
      )
      .subscribe((response: any) => {
        this.applicantKycProofs = response.body;
        // this is used to push the records to map for further reference
        this.listOfKycDocument.set(partyPlay.uid, this.applicantKycProofs);
        this.buildForm(partyPlay);
      });
  }

  buildForm(partyPlay) {
    this.applicantKycProofs = this.listOfKycDocument.get(partyPlay.uid);

    // if there is no kycProof captured then we need to display error message
    if (this.applicantKycProofs.length === 0) {
      this._snackbar.open(
          this.selectedKycDocument.name +
          " is not available for " +
          partyPlay.name +
          " to validate.",
        "close",
        {
          duration: 5000,
        }
      );
      return;
    }

    // we need to show only kycProof which has document number hence we are filtering records from the list which does not have document number
    this.applicantKycProofs = this.applicantKycProofs.filter(
      (proof) => proof.documentNumber
    );

    // if there is no document number present then we need to display error message
    if (this.applicantKycProofs.length === 0) {
      this._snackbar.open(
          this.selectedKycDocument.name +
          " number is not available to validate.",
        "close",
        {
          duration: 5000,
        }
      );
      return;
    }

    // if there is only one record in applicantKycProof then we are prePopulating the document number else user need to select document number from dropdown
    if (this.applicantKycProofs.length === 1) {
      this.xDataServicesForm.patchValue({
        documentProof: this.applicantKycProofs[0].uid,
      });
    }
  }

  onDocumentNumberOrApplicantChange(){
    this.isShowResultCard = false;
    this.documentVerificationResponse = new KycVerificationResponseComposite();
  }

  onVerifyClick() {
    if(!this.isCheckboxSelected){
      this._snackbar.open("Checkbox must be ticked to confirm verification", "close", {
        duration: 5000,
      });
      return;
    }
    let documentUid = this.xDataServicesForm.controls.documentProof.value;
    this.verificationDto.isVerified = true;
    this.verificationDto.verificationMode = KYC_VERIFICATION_MODE_ELECTRONIC;
    this.verificationDto.jdata = this.documentVerificationResponse.serviceProviderData;
    this.applicationService
      .verifyKycDetails(this.applicationUid, documentUid, this.verificationDto)
      .subscribe((response) => {
        if (response) {
          this._snackbar.open("Kyc verified", "close", {
            duration: 5000,
          });
          this.verificationDto = response.body;
        }
      });    
  }

  ngOnDestroy(): void {}
}
