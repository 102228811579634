import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApprovalLimit, ApprovalLimitSearchCriteria } from './approval-limit.model';
@Injectable({
    providedIn: 'root'
})
export class ApprovalLimitService {
    private API = 'api/v1/masters/approvalgrants';
    constructor(private http: HttpClient) { }

    getApprovalLimit(req: any, searchCriteria: ApprovalLimitSearchCriteria): Observable<HttpResponse<ApprovalLimit[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        params = params.set('type', req.type = "loanamount");
        if (searchCriteria != undefined && searchCriteria.user != null && searchCriteria.user != "") {
            params = params.set('user', searchCriteria.user);
        }
        if (searchCriteria != undefined && searchCriteria.productType != null && searchCriteria.productType != "") {
            params = params.set('productType', searchCriteria.productType);
        }
        if (searchCriteria != undefined && searchCriteria.grant != null && searchCriteria.grant != "") {
            params = params.set('grant', searchCriteria.grant);
        }
        return this.http.get<any>(this.API, { params, observe: 'response' });
    }

    saveApprovalLimit(approvalGrantUid: string, approvalLimit: ApprovalLimit): Observable<HttpResponse<ApprovalLimit>> {
        if (approvalLimit.uid && approvalLimit.uid != null) {
            return this.http.put<ApprovalLimit>(this.API + '/' + approvalGrantUid + '/save', approvalLimit, { observe: 'response' });
        } else {
            return this.http.post<ApprovalLimit>(this.API + '/' + 0 + '/save', approvalLimit, { observe: 'response' });
        }
    }

}