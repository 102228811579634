import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Response} from '@angular/http';
import {EventManager, AlertService, JhiLanguageService} from 'ng-jhipster';
import { MakerCheckerMasterAction } from '../shared/model/maker-checker-master-action.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-maker-checker-approve',
    templateUrl: './loan-od-accounts-maker-checker-approve.component.html',
})
export class LoanOdAccountsMakerCheckerApproveComponent implements OnInit {

    makerCheckerMaster: MakerCheckerMasterAction;
    private subscription: any;

    constructor(private jhiLanguageService: JhiLanguageService,
                private alertService: AlertService,
                private router: Router,
                private loanOdAccountsService: LoanOdAccountsService,
                private eventManager: EventManager,
                private route: ActivatedRoute) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
    }

    load(id) {
        this.loanOdAccountsService.makerCheckerApproveEntry(id).subscribe((makerCheckerMaster) => {
            this.makerCheckerMaster = makerCheckerMaster;
        });
    }

    clear() {
        this.router.navigate(['/loans/loan-od-accounts']);
    }
    back() {
        this.router.navigate(['/loans/loan-od-accounts/makerChecker']);
    }

    confirm() {
        this.loanOdAccountsService.makerCheckerExecute(this.makerCheckerMaster).subscribe(
            (res) => { this.onConfirmSuccess(res) },
            (res) => { this.onConfirmError(res) }
        );
    }

    private onConfirmSuccess(result) {
       this.alertService.success('encoreclientApp.loanOdAccounts.approveSuccess', { code: this.makerCheckerMaster.code });
       this.router.navigate(['/loans/loan-od-accounts/makerChecker']);
    }

    private onConfirmError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
            this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
        this.router.navigate(['/loans/loan-od-accounts/makerChecker']);
    }

    private onError(error) {
        this.alertService.error('encoreclientApp.loanOdAccounts.saveError');
        this.router.navigate(['/loans/loan-od-accounts/makerChecker']);
    }
}
