import { Component, Input, OnInit } from '@angular/core';
import {CSRFService} from '../authentication/csrf.service';
import {SocialService} from '../authentication/social-auth/social.service';

@Component({
    selector: 'ig-social',
    templateUrl: './social.component.html'
})
export class SocialComponent implements OnInit {
    @Input() provider: string;
    @Input() providerIcon: string;
    @Input() providerName: string;
    providerSetting: string;
    providerURL: string;
    csrf: string;

    constructor(
        private csrfService: CSRFService,
        private socialService: SocialService
    ) {}

    ngOnInit() {
        this.providerSetting = this.socialService.getProviderSetting(this.provider);
        this.providerURL = this.socialService.getProviderURL(this.provider);
        this.csrf = this.csrfService.getCSRF();
    }
}
