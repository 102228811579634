import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { InsurancePolicyDto } from './insurance-policy-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'ng-jhipster';
import { DatePipe } from '@angular/common';
import { DateConverterService } from '../shared/date/date-converter.service';

@Component({
    selector: 'jhi-loan-od-accounts-insurance-policy-proceed',
    templateUrl: './loan-od-accounts-insurance-policy-proceed.html',
})
export class LoanOdAccountsInsurancePolicyProceedComponent implements OnInit {

    policyList: any = [];
    insurancePolicyDto: InsurancePolicyDto;
    productList: any = [];
    applicantList: any = [];
    subscription: any;
    accountId: any;
    startDate: any;
    constructor(private loanOdAccountsService: LoanOdAccountsService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private datePipe: DatePipe,
        private dateConverter: DateConverterService) { }
    ngOnInit(): void {
        this.fnloadProductList();
        this.subscription = this.route.params.subscribe((params) => {
            this.accountId = params['accountId'];
            this.loadApplicantList(params['accountId']);
        });
        this.insurancePolicyDto = new InsurancePolicyDto();
        this.insurancePolicyDto.accountId = this.accountId;
        this.insurancePolicyDto.id = 0;
        this.insurancePolicyDto.version = 0;
    }
    fnloadProductList() {
        this.loadProductList();
    }
    loadProductList() {
        this.loanOdAccountsService.getInsuranceProductList().subscribe((list) => {
            this.productList = list;
        });
    }
    loadApplicantList(accountId) {
        this.loanOdAccountsService.getApplicantList(accountId).subscribe((list) => {
            this.applicantList = list;
        });
    }
    isPremium: boolean = false;
    premium: any = {};
    isExecuted: boolean = false;
    clear() {
        this.isPremium = false;
    }


    proceed() {
        this.loanOdAccountsService.fnProceed(this.insurancePolicyDto).subscribe(
            (res: any) => {
                this.insurancePolicyDto = res;
                this.insurancePolicyDto.policyStartDate = this.dateConverter.fromServerToNgbDatePicker(this.insurancePolicyDto.policyStartDate);
                this.isPremium = true;
            },
            (res) => this.onSaveError(res)
        );
    }
    convertLocalDateArrayToServer(date: any) {
        if (date === null || date === undefined) {
            return null;
        }
        const newDate = new Date(date[0], date[1] - 1, date[2]);
        return this.datePipe.transform(newDate, 'yyyy-MM-dd');
    }
    execute() {
        this.insurancePolicyDto.policyStartDate = this.dateConverter.fromNgbDatePickerToString(this.insurancePolicyDto.policyStartDate);
        this.loanOdAccountsService.fnPolicyExecute(this.insurancePolicyDto).subscribe(
            (res: any) => {
                this.isExecuted = true;
            },
            (res) => this.onSaveError(res)
        );
    }
    back() {
        this.isPremium = false;
    }
    close() {
        // this.isPremium = false;
    }

    private onSaveError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
                this.onError();
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError();
                }
            } catch (exception) {
                this.onError();
            }
        }
    }

    private onError() {
        this.alertService.error('encoreclientApp.loanOdAccounts.saveError');
    }
}