import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ApplicationService } from '../../../application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { FormControl, FormGroup, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { Condition } from '../cam.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ValidationService } from '../../../application-validators/validation.service';
import { MatSnackBar } from '@angular/material';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'eng-conditions',
  templateUrl: './conditions.template.html',
  styleUrls: ['../../application-details.styles.scss', './conditions.style.scss']
})
export class ConditionsComponent implements OnInit {
  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;
  conditionList: Condition[];

  conditionsCategories: NameValueDto;
  conditionsCodes: NameValueDto;
  conditionsType:NameValueDto;

  conditionForm: FormGroup;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;

    applicationAccessSpecifiers: ApplicationAccessSpecifier[];
    menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,
    private customDatepipe: DateFormatPipe,
    private validationService: ValidationService,
    private confirmationDialogService: ConfirmationDialogService) {
      this.allowAccess = this.applicationService.allowAccess;
     }

  ngOnInit() {
    this.conditionForm = this.formBuilder.group({
      conditionList: this.formBuilder.array([])
    })
    this.getRefCodes()
    this.getConditions()
    this.getMenuItemAccess();

  }
  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('deviation_type').subscribe((response: any) => {
      this.conditionsCategories = response.deviation_type;
    });
    this.referenceCodeService.getShortRefCodes('deviation_item').subscribe((response: any) => {
      this.conditionsCodes = response.deviation_item;
    });
    this.referenceCodeService.getShortRefCodes('condition_type').subscribe((response: any) => {
      this.conditionsType = response.condition_type;
    });
  }
  getConditions() {
    this.applicationService.getConditions(this.application.uid, "POST").subscribe(response => {
      this.conditionList = response.body[0].condition
      this.buildFormArrayWithData()
      this.conditionForm.disable();
    });
  }
  buildFormArrayWithData() {
    const array = this.conditionForm.controls.conditionList as FormArray;
    this.conditionList.forEach(element => {
      const group = new FormGroup({
        type: new FormControl(element.type === null ? '' : element.type),
        dueDate: new FormControl(element.type == 'PRE' ? { value: element.dueDate === null ? '' : new Date(element.dueDate), disabled: true } : { value: element.dueDate === null ? '' : new Date(element.dueDate), disabled: false }),
        description: new FormControl(element.description === null ? '' : element.description),
        completedAndApproved: new FormControl(element.approvedFlag && element.completedFlag)
      });
      this.applyValidatorsToConditionForm(group)
      array.push(group);
    });
    this.conditionForm.disable();
  }
  async addNew() {
    this.conditionForm.enable();
    const newArray = this.conditionForm.controls.conditionList as FormArray;
    const group = new FormGroup({
      type: new FormControl(''),
      dueDate: new FormControl(''),
      description: new FormControl(''),
      completedAndApproved: new FormControl(false)
    });
    this.applyValidatorsToConditionForm(group)
    newArray.push(group);
    this.disableDueDate()
  }

  enableDisableForm() {
    this.conditionForm.enable();
    this.disableDueDate()
  }

  cancelForm() {

    this.conditionForm.get('conditionList')['controls'] = []
    this.buildFormArrayWithData()
  }

  saveCondition() {
    this.validationService.markFormGroupTouched(this.conditionForm)
    this.conditionForm.updateValueAndValidity();
    if (this.conditionForm.valid) {
      let conditionsFormArray = this.conditionForm.get('conditionList')['controls'];
      conditionsFormArray.forEach((conditionFormGroup, index) => {
        if (index === this.conditionList.length) {
          let newCondition = new Condition();
          newCondition.contextType = 'application';
          newCondition.contextUid = this.application.uid;
          this.conditionList.push(newCondition);
        }
        this.conditionList[index].type = conditionFormGroup.controls.type.value;
        this.conditionList[index].dueDate =  this.customDatepipe.transform(conditionFormGroup.controls.dueDate.value,'ISODATE');
        this.conditionList[index].description = conditionFormGroup.controls.description.value;
      });
      this.applicationService.saveCamCondition(this.application.uid,
        this.conditionList).toPromise().then(
          (_success) => {
            this._snackbar.open("Conditions updated successfully", "Close", {
              duration: 2000,
            });
            this.conditionForm = this.formBuilder.group({
              conditionList: this.formBuilder.array([])
            })
            this.getConditions();
          }, (failure) => {
            let errormesg =[]
            errormesg.push("Failed to update conditions")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
          }
        );
    }
  }
  disableDueDate() {
    const arrayControls = this.conditionForm.get('conditionList')['controls']
    arrayControls.forEach(element => {
      const ctrl = element.controls.dueDate
      if (element.controls.type.value === 'POST') {
        ctrl.enable();
      } else {
        ctrl.disable();
      }
    })
  }
  checkValue(event, index) {
    const ctrl = this.conditionForm.get('conditionList')['controls'][index].controls.dueDate
    if (event.value === 'POST') {
      ctrl.enable();
    } else {
      ctrl.disable();
    }
  }

   //this function will apply validators to form group
   applyValidatorsToConditionForm(group) {
    this.validationService.applyValidationRules(group, "CAMCondition")
      .then((controlValidators) => {
        //this.buildFormArrayWithData();
      }).catch(() => { });
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

    openDeleteConfirmation(index:number){
      this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this condition?"})
      this.confirmationDialogService.confirmed().subscribe(data=>{
        if(data){
        this.deleteCondition(index)
        }
      })
    
  }

  deleteCondition(index:number){
    const selectedCondition = this.conditionList[index];
    const newArray = this.conditionForm.controls.conditionList as FormArray;
    if(selectedCondition && selectedCondition.uid ){
      this.applicationService.conditionDelete(this.application.uid, selectedCondition.uid).subscribe((response) => {
        if(response.body.status == "success") {
          newArray.removeAt(index)
          this.conditionList.splice(index,1);
        this._snackbar.open("Condition deleted successfully", "Close", {
          duration: 2000,
        });
      } else {
        let errormesg =[]
        errormesg.push("Condition deletion failed")
        errormesg.push(response.body.message)
        this.applicationService.displayErrorMessages(errormesg);
      }}, (failure) => {
        let errormesg =[]
        errormesg.push("Condition deletion failed")
        errormesg.push(failure)
        this.applicationService.displayErrorMessages(errormesg);
      });
    } else {
      newArray.removeAt(index)
    }
  }
}
