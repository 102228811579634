import { Injectable } from '@angular/core';
import { Http, Response, BaseRequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs';
//import { UserComposite } from '../../entities/base/access/users/users-composite.model';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountService {
    constructor(private http: Http) { }

    get(): Observable<any> {
        return this.http.get('api/account').pipe(map((res: Response) => res.json()));
    }

    save(account: any): Observable<Response> {
        return this.http.post('api/account', account);
    }

    getUserDetails(): Observable<Response> {
        return this.http.get('api/settings');
    }

    updateSettings(userComposite: any): Observable<Response> {
        const copy = Object.assign({}, userComposite);
        const options = this.createRequestOptionJson();
        return this.http.post('api/settings', copy, options);
    }

    private createRequestOptionJson(): BaseRequestOptions {
        const header = new Headers();
        header.set('Content-Type', 'application/json');
        const requestOptions = new BaseRequestOptions();
        requestOptions.headers = header;
        return requestOptions;
    }
}
