import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateConverterService } from '../shared/date/date-converter.service';
// import { Bank } from '../../base/bank';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdAmendment } from './loan-od-amendment.model';

@Component({
    selector: 'jhi-loan-od-accounts-amend-repaymentdate',
    templateUrl: './loan-od-accounts-amendrepaymentdate.component.html'
})
export class LoanOdAccountsAmendrepaymentdateComponent implements OnInit, OnDestroy {
    onError: any;
    loanOdAccounts: LoanOdAccounts;
    loanOdAmendment: LoanOdAmendment;
    //bank: Bank;
    bank:any
    proceedIsTrue: boolean;
    proceedToResult: boolean;

    constructor(
        private loanODAccountsService: LoanOdAccountsService,
        private route: ActivatedRoute,
        private router: Router,
        private dateConverter: DateConverterService,
    ) {
        this.proceedIsTrue = false;
        this.proceedToResult = false;
    }
    ngOnInit() {
        this.loanOdAmendment = new LoanOdAmendment();
        this.loanOdAccounts = this.route.snapshot.data['loanOdAccounts'];
        this.loanOdAmendment.nextDemandScheduledDate = this.dateConverter.fromServerToNgbDatePicker(this.loanOdAccounts.nextDemandRunDate);
        this.loanOdAmendment.accountId = this.route.params['_value'].accountId;
        this.loanODAccountsService.getBanks().subscribe(
            (res) => {
                this.setBank(res.json());
            });
    }
    ngOnDestroy() {
    }

    private setBank(data) {
        this.bank = data;
        this.loanOdAmendment.valueDate = this.dateConverter.fromServerToNgbDatePicker(this.bank.currentWorkingDate);
    }

    previousState() {
        window.history.back();
    }

    clear() {
        this.loanOdAmendment = new LoanOdAmendment();
        this.router.navigate(['/loan-od-accounts']);
    }

    proceed(loanOdAmendment) {
        this.loanOdAmendment = loanOdAmendment;
        this.loanOdAmendment.amendmentType = 'REPAYMENT_DATE';
        this.loanOdAmendment.valueDate = this.dateConverter.fromNgbDatePickerToDateObject(this.loanOdAmendment.valueDate);
        this.loanOdAmendment.nextDemandScheduledDate = this.dateConverter.fromNgbDatePickerToDateObject(this.loanOdAmendment.nextDemandScheduledDate);
        this.proceedIsTrue = true;

    }

    back() {
        this.loanOdAmendment.valueDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.loanOdAmendment.valueDate);
        this.loanOdAmendment.nextDemandScheduledDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.loanOdAmendment.nextDemandScheduledDate);
        this.proceedIsTrue = false;
    }

    executeError(error) {
        this.loanOdAmendment.valueDate = this.dateConverter.fromStringToNgbDatePicker(this.loanOdAmendment.valueDate);
        this.loanOdAmendment.nextDemandScheduledDate = this.dateConverter.fromStringToNgbDatePicker(this.loanOdAmendment.nextDemandScheduledDate);
        this.proceedIsTrue = false;
    }

    executeSuccess(result) {
        this.loanOdAmendment = result;
        this.proceedToResult = true;
    }
}
