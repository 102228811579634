import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AlertService, JhiLanguageService } from 'ng-jhipster';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdRepaymentCompositeDto } from './loan-od-accounts-repayment-composite-dto.model';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { DateConverterService } from '../shared/date/date-converter.service';
import { ApplicationDateService } from '../shared/date/application-date.service';

@Component({
    selector: 'app-loan-od-accounts-advance-repayment-result',
    templateUrl: './loan-od-accounts-advance-repayment-result.component.html',
})
export class LoanOdAccountsAdvanceRepaymentResultComponent implements OnInit {

    dateSubscription: Subscription;
    loanOdAccounts: LoanOdAccounts;
    @Input() loanOdRepaymentCompositeDto: LoanOdRepaymentCompositeDto;
    @Output() executeError: EventEmitter<any> = new EventEmitter<any>();
    @Output() previousState: EventEmitter<any> = new EventEmitter<any>();
    @Output() executeSuccess: EventEmitter<any> = new EventEmitter<any>();
    backDatedTransaction: boolean;
    currentWorkingDate: any;

    constructor(private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private router: Router,
        private alertService: AlertService,
        private applicationDateService: ApplicationDateService,
        private dateConverter: DateConverterService) {
        //   this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        this.dateSubscription = this.applicationDateService.getDate().subscribe(
            (date) => {
                this.currentWorkingDate = date;
                this.isBackDated();
            }
        );
    }

    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
    }


    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }


    Print() {
        let printPage, pWindow;
        printPage = document.getElementById('print-section1').innerHTML;
        pWindow = window.open();
        pWindow.document.open();
        pWindow.document.write(`
      <html>
        <head>
          <style>
          body{
              font-size:20px !important;
          }
          .fontb {
            font-weight: bold;
        }
        .fs18 {
          font-size: 18px;
          line-height: 28px;
      }
      
      .fs16 {
          font-size: 16px;
          line-height: 26px;
      }
      
      .fs14 {
          font-size: 14px;
          line-height: 21px;
      }
      
      .fs12 {
          font-size: 12px;
          line-height: 18px;
      }
          </style>
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS" crossorigin="anonymous">
        </head>
    <body onload="window.print();window.close()">${printPage}
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.6/umd/popper.min.js" integrity="sha384-wHAiFfRlMFy6i5SRaxvfOCifBUQy1xHdJ/yoi7FRNXMRBu5WHdZYu1hA6ZOblgut" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js" integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k" crossorigin="anonymous"></script>
    </body>
      </html>`
        );
        pWindow.document.close();
    }

    PrintElem(elem) {

    }
    view() {
        this.router.navigate(['/loan-od-accounts/view', this.loanOdRepaymentCompositeDto.accountId]);
    }

    private isBackDated() {
        const currentDate = this.dateConverter.fromServerToDateObject(this.currentWorkingDate);
        if (this.loanOdRepaymentCompositeDto.repaymentDate < currentDate) {
            this.backDatedTransaction = true;
        }
    }

    execute() {
        this.loanOdRepaymentCompositeDto.repaymentDate = this.dateConverter.fromDateObjectToServer(this.loanOdRepaymentCompositeDto.repaymentDate);
        this.loanOdAccountsService.feePaymentResult(this.loanOdRepaymentCompositeDto).subscribe((res) =>
            this.onSaveSuccess(res), (res) => this.onSaveError(res));
    }

    private onSaveSuccess(result) {
        this.executeSuccess.emit(result);
    }

    private onSaveError(error) {
        try {
            const errorString = error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError();
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError();
                }
            } catch (exception) {
                this.onError();
            }
        }
        this.executeError.emit('error');
    }

    private onError() {
        this.alertService.error('An error occured. Action could not be performed.');
    }

}