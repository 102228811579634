
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { JhiLanguageService } from 'ng-jhipster';
import { Router } from '@angular/router';
import { TransactionSummaryDto } from './transaction-summary-dto.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-charge-fee-result',
    templateUrl: './loan-od-accounts-charge-fee-result.component.html'
})
export class LoanOdAccountsChargeFeeResultComponent implements OnInit, OnDestroy {

    @Input() transactionSummaryDto: TransactionSummaryDto;
    @Input() computeFeeForFeeAmountDetails: any;
    displayDate: string;

    constructor(private jhiLanguageService: JhiLanguageService, private loanOdAccountsService: LoanOdAccountsService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        if (!this.transactionSummaryDto.part6) {
            this.transactionSummaryDto.part6 = '0';
        }
    }

    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
    }

    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }

    print() {
        window.print();
    }

    view() {
        this.router.navigate(['/loan-od-accounts/view', this.transactionSummaryDto.accountId]);
    }

}
