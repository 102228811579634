import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-insurance-policy-view',
    templateUrl: './loan-od-accounts-insurance-policy-view.html',
})
export class LoanOdAccountsInsurancePolicyViewComponent implements OnInit {

    policyList: any = [];
    constructor(private loanOdAccountsService: LoanOdAccountsService) { }
    ngOnInit(): void {
    }
}