import { Component, OnInit, Input } from '@angular/core';
import { Form, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JhiLanguageService } from 'ng-jhipster';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'jhi-loan-od-accounts-basic-editor',
    templateUrl: './loan-od-accounts-basic-editor.component.html',

})
export class LoanOdAccountsBasicEditorComponent implements OnInit {

    @Input() basicForm: FormGroup;
    @Input() locationCodesList: any;
    showLocationCode: boolean;
    showGuarantorCustomerId: boolean;

    constructor(
        private jhiLanguageService: JhiLanguageService,
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        if (this.basicForm.get('locationCode').value) {
            this.showLocationCode = true;
        } else {
            this.showLocationCode = false;
        }
        if (this.basicForm.get('guarantorCustomerId').value) {
            this.showGuarantorCustomerId = true;
        } else {
            this.showGuarantorCustomerId = false;
        }
    }

}
