import { Component, OnInit } from '@angular/core';
import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';

@Component({
  selector: 'app-list-example',
  templateUrl: './list-example.component.html',
  styleUrls: ['./list-example.component.css']
})
export class ListExampleComponent implements OnInit {
  displayedColumns: string[] = ['username', 'email', 'salary', 'action'];
  users: any[] = [];

  constructor() {}

  ngOnInit() {
    const tempData = [
      {
        username: 'John',
        email: 'john@gmail.com',
        salary: '10000'
      },
      {
        username: 'Dhiraj',
        email: 'dhiraj@gmail.com',
        salary: '50000'
      },
      {
        username: 'Ramesh',
        email: 'ramesh.cp@gmail.com',
        salary: '15000'
      },
      {
        username: 'Mahesh',
        email: 'mahi@gmail.com',
        salary: '20000'
      }
    ];
    this.users = tempData;
  }
}
