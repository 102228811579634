import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validator, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { IgFormService } from '../../../../projects/ig-core/src/lib/form/form.service';
import { IgPatternValidator } from '../../../../projects/ig-core/src/lib/form/additional-validators/ig-pattern-validator';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../../projects/ig-core/src/lib/authentication/login.service';
import { FormErrorModel } from '../../../../projects/ig-core/src/lib/form/form-error.model';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { environment } from '../../../environments/environment';
import { SecureStorageService} from '../../../../projects/ig-core/src/lib/authentication/secureStorage.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  public resetForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    oldPassword: '',
    password: '',
    newPassword: '',
  };
  key: any;
  user: any;
  environmentVariable:any=environment;
  changePassword = false;
  loggedInUser:boolean=false;

  constructor(private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private secureStorageService: SecureStorageService) { }

  ngOnInit() {
    if(this.secureStorageService.secureSessionStorage.getItem('authenticationToken')){
      this.loggedInUser=true
    }
    if (this.route.snapshot.queryParams['changePassword']) {
      this.changePassword = JSON.parse(this.route.snapshot.queryParams['changePassword']);
    }
    if (this.route.snapshot.data['user']) {
      this.user = this.route.snapshot.data['user'];
    }
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    this.key = this.route.snapshot.queryParams['key'];
  }

  ngOnDestroy(): void {
  }

  buildForm() {
    this.resetForm = this.formBuilder.group({
      oldPassword: [''],
      password: [''],
      newPassword: ['']
    });
    if (this.changePassword) {
      this.resetForm.controls['oldPassword'].setValidators(Validators.compose([Validators.required]));
    }
    this.resetForm.controls['password'].setValidators(Validators.compose([Validators.required,
    Validators.maxLength(100),
    IgPatternValidator.patternValidator(/\d/, {
      hasNumber: true
    }),
    IgPatternValidator.patternValidator(/[A-Z]/, {
      hasCapitalCase: true
    }),
    IgPatternValidator.patternValidator(/[a-z]/, {
      hasSmallCase: true
    }),
    IgPatternValidator.patternValidator(
      /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
      {
        hasSpecialCharacters: true
      }
    ),
    Validators.minLength(8)]));
    this.resetForm.controls['newPassword'].setValidators(Validators.compose([Validators.required,
    IgPatternValidator.compareWith('password', {
      mustMatch: true
    })
    ]));
    this.resetForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.resetForm, this.formErrors, true);
    });
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.resetForm);

    if (this.resetForm.valid && this.key) {
      this.loginService.resetPassword(this.resetForm.get('password'), this.key)
        .pipe(takeUntil(componentDestroyed(this))).subscribe((response) => this.onSubmitSuccess(response),
          (response) => this.onSubmitError(response));
    } else
      if (this.resetForm.valid && this.changePassword) {
        this.loginService.changePassword(this.resetForm.value)
          .pipe(takeUntil(componentDestroyed(this))).subscribe((response) => this.onSubmitSuccess(response),
            (response) => this.onSubmitError(response));
      } else {
        this.formErrors = this.igFormService.validateForm(this.resetForm, this.formErrors, false);
      }
  }

  onSubmitSuccess(response) {
    this.snackBar.open('Password has been reset successfully');
    this.loginService.logout();
      return this.router.navigate(['signin']);
    
  }

  onSubmitError(response) {
    if (response.error.status === 400) {
      this.snackBar.open(`${response.error.title}`, 'Close');
    } else {
      this.snackBar.open('There was an error while processing your request', 'Close');
    }
  }
}
