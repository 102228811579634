import {Params} from '@angular/router';
import {PagingAndSortParams} from './paging-and-sort-params';

export class ListParams {

  constructor(
    public params: Params,
    public pagingAndSortParam: PagingAndSortParams
  ) { }
}
