import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SecureStorageService } from '@ig-core/authentication/secureStorage.service';

@Injectable()
export class ScheduledMaintenanceInterceptor implements HttpInterceptor {

  constructor(private secureStorageService: SecureStorageService) { }

  // Intercept HTTP requests and responses to handle scheduled maintenance headers.
  // if 'scheduled-maintance' header is present then we are showing the server downtime message.
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.headers.has('Scheduled-Maintenance')) {
            let downtime = event.headers.get("Scheduled-Maintenance")
            this.secureStorageService.setScheduledMaintenanceTime(downtime);
          }else{
            this.secureStorageService.setScheduledMaintenanceTime(null);
          }
        }
      })
    );
  }
}