import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AlertService, JhiLanguageService } from 'ng-jhipster';
import { Router } from '@angular/router';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { TransactionSummaryDto } from './transaction-summary-dto.model';
import { DateConverterService } from '../shared/date/date-converter.service';
import { FeeDto } from './loan-od-accounts-fee-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-charge-fee-execute',
    templateUrl: './loan-od-accounts-charge-fee-execute.component.html'
})
export class LoanOdAccountsChargeFeeExecuteComponent implements OnInit, OnDestroy {
    @Input() feeDto: FeeDto;
    @Input() fee: any;
    @Input() transactionSummaryDto: TransactionSummaryDto;
    displayDate: string;
    @Output() executeError: EventEmitter<any> = new EventEmitter<any>();
    @Output() previousState: EventEmitter<any> = new EventEmitter<any>();
    @Output() computeFeeForFeeAmountDetailsData: EventEmitter<any> = new EventEmitter<any>();
    @Output() executeSuccess: EventEmitter<TransactionSummaryDto> = new EventEmitter<TransactionSummaryDto>();
    computeFeeForFeeAmountDetails: any;

    constructor(private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private router: Router,
        private dateConverter: DateConverterService,
        private alertService: AlertService) {
    }

    ngOnInit() {
        console.log(this.transactionSummaryDto.valueDate);
        this.transactionSummaryDto.valueDate = this.dateConverter.fromNgbDatePickerToString(this.transactionSummaryDto.valueDate);
        console.log(this.transactionSummaryDto.valueDate);
        this.computeFeeForFeeAmount();
    }

    confirm() {
    }

    ngOnDestroy() {
    }

    execute() {
        if (this.feeDto.accountId !== undefined) {
            if (!this.feeDto.deduction || !this.feeDto.deduction.magnitude) {
                this.feeDto.deduction = { magnitude: 0, currency: null, displayValue: null }
            }
            this.loanOdAccountsService.chargeFeeResult(this.feeDto).subscribe((res) => this.onSaveSuccess(res), (res: Response) => this.onSaveError(res));
        }
    }

    computeFeeForFeeAmount() {
        const accountId = this.feeDto.accountId;
        const amount: number = this.feeDto.amount.magnitude;
        const currencyCode = this.feeDto.amount.currency;
        const feeName = this.feeDto.feeCategory;
        this.loanOdAccountsService.computeFeeForFeeAmount(accountId, amount, currencyCode, feeName).
            subscribe((data) => {
                this.computeFeeForFeeAmountDetails = data;
                this.computeFeeForFeeAmountDetailsData.emit(this.computeFeeForFeeAmountDetails);

            });
    }

    private onSaveSuccess(res) {
        this.computeFeeForFeeAmountDetailsData.emit(this.computeFeeForFeeAmountDetails);
        this.executeSuccess.emit(res);
    }

    private onSaveError(error) {
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
        this.executeError.emit('error');
    }

    private onError(error) {
        this.alertService.error(error.error);
    }

    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }

    back() {
        this.previousState.emit('back');
    }

}
