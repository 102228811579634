import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable} from 'rxjs';
import { ReferenceCodeService } from './reference-code.service';
import { ReferenceCode } from './reference-code.model';

@Injectable({
    providedIn: 'root'
})
export class ReferenceCodeResolverService implements Resolve<ReferenceCode> {
    constructor(private referenceCodeService: ReferenceCodeService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ReferenceCode> | Observable<never> {
        return this.referenceCodeService.getReferenceCode(+route.paramMap.get('id'));
    }
}
