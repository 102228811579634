import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialLibModule } from '@app/material-lib.module';

import { EditableResolveService } from '@ig-core/resolves/editable-resolve.service';
import { NonEditableResolveService } from '@ig-core/resolves/non-editable-resolve.service';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';


import { ReferenceCodeResolverService } from '@app/admin/reference-code/reference-code.resolve';
import { ReferenceCodeListComponent } from '@app/admin/reference-code/reference-code-list/reference-code-list.component';
import { ReferenceCodeDeleteDialogComponent } from '@app/admin/reference-code/reference-code-list/reference-code-delete-dialog.component';
import { ReferenceCodeEditorComponent } from '@app/admin/reference-code/reference-code-edit/reference-code-editor.component';

export const referenceCodeRoute: Route = {
  path: 'reference-code',
  canActivate: [AuthenticatedUserService],
  children: [
    { path: '', component: ReferenceCodeListComponent },
    {path: 'create', component: ReferenceCodeEditorComponent,
      resolve: {
        editable: EditableResolveService
      }
    },
    {
      path: 'update/:id', component: ReferenceCodeEditorComponent,
      resolve: {
        referenceCode: ReferenceCodeResolverService,
        editable: EditableResolveService
      }
    },
    {
      path: 'view/:id', component: ReferenceCodeEditorComponent,
      resolve: {
        referenceCode: ReferenceCodeResolverService,
        editable: NonEditableResolveService
      }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [ ReferenceCodeEditorComponent, ReferenceCodeDeleteDialogComponent], /* ReferenceCodeListComponent, */
  entryComponents: [ReferenceCodeDeleteDialogComponent]
})
export class ReferenceCodeModule { }
