import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventManager, JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { ConfigureService } from '../build-config/configure.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-maker-checker-view',
    templateUrl: './loan-od-accounts-maker-checker-view.component.html'
})
export class LoanOdAccountsMakerCheckerViewComponent implements OnInit, OnDestroy {

    loanOdAccounts: LoanOdAccounts;
    private subscription: any;
    private eventSubscriber: Subscription;
    BASE_URL: string;
    hideEditButton: boolean;

    constructor(private eventManager: EventManager,
                private jhiLanguageService: JhiLanguageService,
                private loanOdAccountsService: LoanOdAccountsService,
                private route: ActivatedRoute,
                private configService: ConfigureService) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.hideEditButton = true;
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.BASE_URL = this.configService.buildUrl();
    }

    load(id) {
        this.loanOdAccountsService.makerCheckerView(id).subscribe((loanOdAccounts) => {
            this.loanOdAccounts = loanOdAccounts;
        });
    }

    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
