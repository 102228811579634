import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LoanApplication, Person,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ApplicationService } from '@app/applications/application.service';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { BankAccount } from '../../business/businessBankAccount/business-bank-accounts.model';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';

import { ValidationService } from '../../../application-validators/validation.service'
import { MatSnackBar } from '@angular/material';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { element } from '@angular/core/src/render3';
@Component({
  selector: 'eng-bank-statements',
  templateUrl: './bank-statements.template.html',
  styleUrls: ['../../application-details.styles.scss', './bank-statements.style.scss']
})
export class BankStatementsComponent implements OnInit, OnDestroy {
  @Input() application: LoanApplication;
  @Input() applicants: Person[];
  @Input()
    menuCode:string;
  allBankAccounts: Array<BankAccount> = [];
  selectedBankAccount: BankAccount;

  businessAccountTypes: ReferenceCode[] = [];
  personaccountTypes: ReferenceCode[] = [];
  limitCalRefcodeArray: ReferenceCode[] = [];

  bankStatementsForm: FormGroup;

  recentMonths: Array<string> = [];
  allBankStatements: any = []
  businessIncomeDetails: any;
  CreditStatements: any;
  DebitStatements: any;
  avdBalanceStatements: any;
  iwreturnStatements: any;
  owreturnStatements: any;
  newBankStatement: any;

  isRadioSelectionDisabled: boolean = false;
  newItemSave: boolean = true;
  allowAccess: Boolean;
  showForm: boolean;
  cfaFinalized: boolean;
  menuItemAllowAccess:boolean;

  subscription: Subscription

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  limitCalexit:ReferenceCode[];
  selectedBankStatement: any
  bankStatementAmountType = [
    { "bankTxnType": "credit", "formcontrol": "mCreditAmount" },
    { "bankTxnType": "debit", "formcontrol": "mDebitAmount" },
    { "bankTxnType": "avgbalance", "formcontrol": "mAvgBalanceAmount" },
    { "bankTxnType": "iwreturn", "formcontrol": "miwreturnAmount" },
    { "bankTxnType": "owreturn", "formcontrol": "mowreturnAmount" }]
  constructor(private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private formBuilder: FormBuilder, private validationService: ValidationService,
    private _snackbar: MatSnackBar,
    private changeDetectorref: ChangeDetectorRef) {
    this.allowAccess = this.applicationService.allowAccess;
    this.bankStatementsForm = this.formBuilder.group({
      bankStatementsMonthlySummaryForm: this.formBuilder.group({
        monthlySales: null,
        eligibleTurnover: null
      }),
      bankStatementsSummaryForm: this.formBuilder.group({
        totalmCreditAmount: null,
        totalmDebitAmount: null,
        totalmAvgBalanceAmount: null,
        totalmInwardAmount: null,
        totalmOutwardAmount: null,
        totalPercentageUtilization: null
      }),
      overdraftlimit: new FormControl(null),
      monthlyStatementsForm: this.formBuilder.array([])
    })
  }

  ngOnInit() {

    this.getCfaStatus()
    this.disableFormGroups()
    this.getRefCodes();
    this.getMenuItemAccess();

    this.fetchBusinessBankAccounts();
  }


  getRefCodes() {
    this.referenceCodeService.getRefCodesForClassifier('business_bankac_type').subscribe((response: any) => {
      this.businessAccountTypes = response;
      this.businessAccountTypes.forEach(element => {
        if (element.field2 == "LIMIT-CALC") {
          this.limitCalRefcodeArray.push(element)
        }
      });
    });
    this.referenceCodeService.getRefCodesForClassifier('person_bankac_type').subscribe((response: any) => {
      this.personaccountTypes = response
      this.personaccountTypes.forEach(element => {
        if (element.field2 == "LIMIT-CALC") {
          this.limitCalRefcodeArray.push(element)
        }
      });
    });
  }


  fetchAllBankStatements() {
    if (this.allBankAccounts.length !== 0 && this.selectedBankAccount == undefined) {
      this.selectedBankAccount = this.allBankAccounts[0];
     
    }
    this.fetchAssessmentYearAndMonths();
    this.applicationService.getBankStatements(
      this.application.uid).subscribe((response: any) => {
        if (response) {
          this.allBankStatements = response.body;
        }
       
        this.assignValuesToForm(this.selectedBankAccount)
        this.bankStatementsForm.controls.overdraftlimit.patchValue(this.selectedBankAccount.overdraftLimit)
      });
  }


  fetchBusinessBankAccounts() {
    this.applicationService.getBusinessBankAccounts(
      this.application.uid).subscribe((response: any) => {
        if (response) {
          this.allBankAccounts = response.body;
          
          this.fetchApplicantBankAccounts()

        }
      });
  }


  fetchApplicantBankAccounts() {
    if (this.applicants.length !== 0) {
      this.applicants.forEach(person => {
        if (person.partyPlay !== 'reference') {
          this.applicationService.getApplicantBankAccounts(this.application.uid, person.uid)
            .subscribe((response: any) => {
              if (response) {
                this.allBankAccounts = this.allBankAccounts.concat(response.body);
                this.fetchAllBankStatements()
              }
            });
        }
      });
    }
  }


  buildBankStatementsForm() {
    if (this.bankStatementsForm.controls.monthlyStatementsForm) {
      this.bankStatementsForm.controls.monthlyStatementsForm = this.formBuilder.array([])
      const array = this.bankStatementsForm.controls.monthlyStatementsForm as FormArray;
      this.recentMonths.forEach((month, index) => {
        const group = new FormGroup({
          mCreditAmount: new FormControl(this.CreditStatements !== undefined ? this.CreditStatements['m' + (index + 1) + 'Amount'] : ''),
          mDebitAmount: new FormControl(this.DebitStatements !== undefined ? this.DebitStatements['m' + (index + 1) + 'Amount'] : ''),
          mAvgBalanceAmount: new FormControl(this.avdBalanceStatements !== undefined ? this.avdBalanceStatements['m' + (index + 1) + 'Amount'] : ''),
          miwreturnAmount: new FormControl(this.iwreturnStatements !== undefined ? this.iwreturnStatements['m' + (index + 1) + 'Amount'] : ''),
          mowreturnAmount: new FormControl(this.owreturnStatements !== undefined ? this.owreturnStatements['m' + (index + 1) + 'Amount'] : ''),
        });

        group.addControl("utilization", new FormControl(this.avdBalanceStatements !== undefined ? this.getUtilizationValue(this.avdBalanceStatements['m' + (index + 1) + 'Amount']) : 0))

        this.applyValidatorsToGstReturn(group)
        array.push(group);
      });
      // subscribe to changes in the formArray
      this.subscription = (this.bankStatementsForm.get('monthlyStatementsForm') as FormArray).valueChanges.subscribe(values => {
        //storing total value's control in a different constant parameter's 
        const creditAmountCtrl = this.bankStatementsForm.get('bankStatementsSummaryForm')['controls']['totalmCreditAmount'];
        const totalmDebitAmountCtrl = this.bankStatementsForm.get('bankStatementsSummaryForm')['controls']['totalmDebitAmount'];
        const totalmAvgBalanceCtrl = this.bankStatementsForm.get('bankStatementsSummaryForm')['controls']['totalmAvgBalanceAmount'];
        const inwardAmountCtrl = this.bankStatementsForm.get('bankStatementsSummaryForm')['controls']['totalmInwardAmount'];
        const outwardAmountCtrl = this.bankStatementsForm.get('bankStatementsSummaryForm')['controls']['totalmOutwardAmount'];
        const totalPercentageUtilizationCtrl = this.bankStatementsForm.get('bankStatementsSummaryForm')['controls']['totalPercentageUtilization'];
        //setting total form control values zero
        creditAmountCtrl.patchValue(0)
        totalmDebitAmountCtrl.patchValue(0)
        totalmAvgBalanceCtrl.patchValue(0)
        inwardAmountCtrl.patchValue(0)
        outwardAmountCtrl.patchValue(0)
        totalPercentageUtilizationCtrl.patchValue(0)

        const ctrl = <FormArray>this.bankStatementsForm.get('monthlyStatementsForm');
        // iterate each object in the form array
        ctrl.controls.forEach(x => {


          // get the 'mCreditAmount' value and need to parse the input to number
          let parsed = (x.get('mCreditAmount').value !='' && x.get('mCreditAmount').value != null)? parseInt(x.get('mCreditAmount').value) : 0
          // add to total
          let creditsummed = creditAmountCtrl.value
          creditsummed += parsed
          if (Number.isNaN(creditsummed)) creditsummed = 0;
          creditAmountCtrl.patchValue(creditsummed)



          // get the 'mDebitAmount' value and need to parse the input to number
          let debitValue = (x.get('mDebitAmount').value !== ''&&x.get('mDebitAmount').value !== null) ? parseInt(x.get('mDebitAmount').value) : 0
          let debitsummed = totalmDebitAmountCtrl.value
          debitsummed += debitValue
          if (Number.isNaN(debitsummed)) debitsummed = 0;
          totalmDebitAmountCtrl.patchValue(debitsummed)



          // get the 'mAvgBalanceAmount' value and need to parse the input to number
          let avgBalanceValue = (x.get('mAvgBalanceAmount').value !== ''&&x.get('mAvgBalanceAmount').value !== null) ? parseInt(x.get('mAvgBalanceAmount').value) : 0
          let avgBalancesummed = this.bankStatementsForm.get('monthlyStatementsForm').value.reduce((object, group) => object + Number(group.mAvgBalanceAmount), 0)
          //filter non zero 'mAvgBalanceAmount' values 
          let noOfNonZeroEntries = this.bankStatementsForm.get('monthlyStatementsForm').value.filter(function (el) { return el.mAvgBalanceAmount != null && el.mAvgBalanceAmount != '' && el.mAvgBalanceAmount != undefined && el.mAvgBalanceAmount != '0' })
          //computing average as 'sum of non-zero values in average column divided by number of non-zero entries'
          let averageBalance = avgBalancesummed / noOfNonZeroEntries.length
          if (Number.isNaN(averageBalance)) averageBalance = 0;
          totalmAvgBalanceCtrl.patchValue(averageBalance.toFixed(2))



          let inwardValue = (x.get('miwreturnAmount').value !== ''&&x.get('miwreturnAmount').value !== null) ? parseInt(x.get('miwreturnAmount').value) : 0
          let inwardSummed = inwardAmountCtrl.value
          if (inwardSummed != NaN) {
            inwardSummed += inwardValue
            if (Number.isNaN(inwardSummed)) inwardSummed = 0;
            inwardAmountCtrl.patchValue(inwardSummed)
          }



          let outwardValue = (x.get('mowreturnAmount').value !== ''&&x.get('mowreturnAmount').value !== null ) ? parseInt(x.get('mowreturnAmount').value) : 0
          let outwardSummed = outwardAmountCtrl.value
          outwardSummed += outwardValue
          if (Number.isNaN(outwardSummed)) outwardSummed = 0;
          outwardAmountCtrl.patchValue(outwardSummed)

          let utilizationValue = (x.get('utilization').value !== ''&&x.get('utilization').value !== null) ? parseInt(x.get('utilization').value) : 0
          let utilizationSummed = totalPercentageUtilizationCtrl.value
          utilizationSummed += utilizationValue
          if (Number.isNaN(utilizationSummed)) utilizationSummed = 0;
          totalPercentageUtilizationCtrl.patchValue(utilizationSummed)

          this.changeDetectorref.detectChanges()
        });
      })
      this.bankStatementsForm.get("monthlyStatementsForm").disable()
    }
  }


  fetchAssessmentYearAndMonths() {
    this.showForm = false;
    this.applicationService.getAssessmentYearAndMonths(this.application.uid)
      .subscribe((response: any) => {
        if (response) {
          this.showForm = true;
          this.recentMonths = response.recentMonths;

          this.buildBankStatementsForm()
        }
      });
  }


  enableDisableForm() {
    this.isRadioSelectionDisabled = true;
    this.bankStatementsForm.get("monthlyStatementsForm").enable()
    this.limitCalexit = this.limitCalRefcodeArray.filter(element => {
      if (element.code == this.selectedBankAccount.accountType) {
        return true
      }
    })
    if (this.limitCalexit.length != 0) {
      this.bankStatementsForm.get("overdraftlimit").enable()
    }
  }


  assignValuesToForm(selectedBankAccount) {
    if (this.allBankStatements.length != 0) {
      for (var i = 0; i < this.allBankStatements.bankStatements.length; ++i) {
        let obj = this.allBankStatements.bankStatements[i]
        if (obj.bankAccountUid === selectedBankAccount.uid) {
          this.selectedBankStatement = obj
          this.businessIncomeDetails = obj.businessIncomeDetail
          this.newItemSave = false
          if (this.businessIncomeDetails.length !== 0) {
            this.businessIncomeDetails.forEach(element => {
              if (element.bankTxnType === "credit") {
                this.CreditStatements = element
              } else if (element.bankTxnType === "debit") {
                this.DebitStatements = element
              } else if (element.bankTxnType === "avgbalance") {
                this.avdBalanceStatements = element
              } else if (element.bankTxnType === "iwreturn") {
                this.iwreturnStatements = element
              } else if (element.bankTxnType === "owreturn") {
                this.owreturnStatements = element
              }
            });
            let summaryForm = this.bankStatementsForm.get('bankStatementsMonthlySummaryForm')['controls']
            summaryForm.monthlySales.setValue(this.allBankStatements.businessIncome.monthlySales)
            summaryForm.eligibleTurnover.setValue(this.allBankStatements.businessIncome.eligibleMonthlyTurnover)
            this.buildBankStatementsForm()
            break
          }
        } else {
          this.bankStatementsForm.reset()
          
          this.newItemSave = true
        }
      };
    } else {
      this.selectedBankStatement = this.allBankAccounts[0]
      this.bankStatementsForm.reset()
      this.newItemSave = true
    }
  }


  changeSelectedAccounts(BankAccount?: BankAccount) {

    if (this.allBankAccounts.length !== 0) {
      this.selectedBankAccount = BankAccount;
    
    }
    this.showForm = true;
    this.assignValuesToForm(this.selectedBankAccount)
    this.bankStatementsForm.controls.overdraftlimit.patchValue(this.selectedBankAccount.overdraftLimit)
    this.statementscomputeSummary()
  }


  cancelForm() {
    this.isRadioSelectionDisabled = false;
    this.fetchAllBankStatements()
    this.disableFormGroups()
  }


  applyValidatorsToGstReturn(group) {
    this.validationService.applyValidationRules(group, "BankStatements")
      .then((controlValidators) => {
      }).catch(() => { });
  }

  saveBankStatement() {
    this.validationService.markFormGroupTouched(this.bankStatementsForm)
    this.bankStatementsForm.updateValueAndValidity();
    if (this.bankStatementsForm.valid && this.selectedBankAccount.uid) {
      this.formRequestBodyOfStaments()
      this.saveBankAccountOverDraftLimit()
      this.applicationService.saveBankStatements(this.application.uid, this.selectedBankAccount.uid, this.newBankStatement, this.newItemSave)
        .toPromise().then(
          (_success) => {
            this._snackbar.open("Bank statements updated successfully", "Close", {
              duration: 2000,
            });
            this.isRadioSelectionDisabled = false;
            this.fetchAllBankStatements()
            this.disableFormGroups()
          }, (failure) => {
            let errormesg =[]
            errormesg.push("Bank statements update failed")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
          }
        );
    }
  }


  disableFormGroups() {
    this.bankStatementsForm.get("bankStatementsMonthlySummaryForm").disable()
    this.bankStatementsForm.get("bankStatementsSummaryForm").disable()
    this.bankStatementsForm.get("overdraftlimit").disable()
    this.bankStatementsForm.get("monthlyStatementsForm").disable()
  }

  //onclick of compute summary button this function will be called  
  statementscomputeSummary() {
    this.formRequestBodyOfStaments()
    this.applicationService.bankStatementComputeSummary(this.application.uid, this.selectedBankAccount.uid, this.newBankStatement).subscribe((response: any) => {
      let summaryForm = this.bankStatementsForm.get('bankStatementsMonthlySummaryForm')['controls']
      summaryForm.monthlySales.setValue(response.body.businessIncome.monthlySales)
      summaryForm.eligibleTurnover.setValue(response.body.businessIncome.eligibleMonthlyTurnover)
    })
  }



  //this function will call a api to get CFA Finalized or not status
  getCfaStatus() {
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response => {
      this.cfaFinalized = response.body.cfaFinalized
      if (this.cfaFinalized) {
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close", {
          duration: 5000,
        });
      }
    })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getUtilizationValue(monthAvgBalance) {
    let utilization
    // calculating utilization formula is 'average balance for the month / overdraft limit ) * 100 rounded off to 2 decimals'
    if (this.selectedBankAccount.overdraftLimit != 0) {
      utilization = (monthAvgBalance / this.selectedBankAccount.overdraftLimit) * 100
    } else {
      utilization = 0
    }
    if (Number.isNaN(utilization)) {
      utilization = 0
    } else {
      utilization.toFixed(2)
    }
    return utilization
  }

  //forming request body for saving and compute summary 
  formRequestBodyOfStaments() {
    this.newBankStatement = {
      "businessIncome": null,
      "bankStatements": [
        {
          "bankAccountUid": this.selectedBankAccount.uid,
          "businessIncomeDetail": []
        }
      ]
    }
    let monthlyAmountArray = this.bankStatementsForm.get('monthlyStatementsForm')['controls']
    //this.newItemSave parameter will be true for newly adding statements.
    if (this.newItemSave) {
      this.bankStatementAmountType.forEach((type, index) => {
        monthlyAmountArray[0].value[type.formcontrol]
        let object = {
          "uid": null,
          "version": 0,
          "category": "bankstatement",
          "description": null,
          "sourceOfIncome": null,
          "incomeDocumentType": "BNK_STMT",
          "incomeDocumentSection": null,
          "frequency": "M",
          "pmAmount": null,
          "pqAmount": null,
          "phAmount": null,
          "pyAmount": null,
          "mmyyStart": null,
          "m1Amount": monthlyAmountArray[0].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[0].value[type.formcontrol]),
          "m2Amount": monthlyAmountArray[1].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[1].value[type.formcontrol]),
          "m3Amount": monthlyAmountArray[2].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[2].value[type.formcontrol]),
          "m4Amount": monthlyAmountArray[3].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[3].value[type.formcontrol]),
          "m5Amount": monthlyAmountArray[4].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[4].value[type.formcontrol]),
          "m6Amount": monthlyAmountArray[5].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[5].value[type.formcontrol]),
          "m7Amount": monthlyAmountArray[6].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[6].value[type.formcontrol]),
          "m8Amount": monthlyAmountArray[7].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[7].value[type.formcontrol]),
          "m9Amount": monthlyAmountArray[8].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[8].value[type.formcontrol]),
          "m10Amount": monthlyAmountArray[9].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[9].value[type.formcontrol]),
          "m11Amount": monthlyAmountArray[10].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[10].value[type.formcontrol]),
          "m12Amount": monthlyAmountArray[11].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[11].value[type.formcontrol]),
          "q1Amount": null,
          "q2Amount": null,
          "q3Amount": null,
          "q4Amount": null,
          "h1Amount": null,
          "h2Amount": null,
          "yrAmount": null,
          "finYear": null,
          "calYear": null,
          "salesMargin": null,
          "taxAssessmentYear": null,
          "totalSales": null,
          "totalPurchase": null,
          "grossProfilt": null,
          "netProfit": null,
          "taxFilingDate": null,
          "frozenFlag": null,
          "contextType": null,
          "contextId": null,
          "contextUid": null,
          "bankAccountUid": null,
          "bankTxnType": type.bankTxnType,
          "bankAccountCfaFlag": null,
          "linkToType": null,
          "linkToId": null,
          "linkToUid": null
        }
        this.newBankStatement.bankStatements[0].businessIncomeDetail.push(object)
      })
    } else {
      this.newBankStatement.businessIncome = this.allBankStatements.businessIncome
      this.newBankStatement.bankStatements[0].businessIncomeDetail = this.selectedBankStatement.businessIncomeDetail
      this.bankStatementAmountType.forEach((type, index) => {
        this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m1Amount = monthlyAmountArray[0].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[0].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m2Amount = monthlyAmountArray[1].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[1].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m3Amount = monthlyAmountArray[2].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[2].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m4Amount = monthlyAmountArray[3].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[3].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m5Amount = monthlyAmountArray[4].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[4].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m6Amount = monthlyAmountArray[5].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[5].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m7Amount = monthlyAmountArray[6].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[6].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m8Amount = monthlyAmountArray[7].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[7].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m9Amount = monthlyAmountArray[8].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[8].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m10Amount = monthlyAmountArray[9].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[9].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m11Amount = monthlyAmountArray[10].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[10].value[type.formcontrol]),
          this.newBankStatement.bankStatements[0].businessIncomeDetail[index].m12Amount = monthlyAmountArray[11].value[type.formcontrol] == null ? null : parseInt(monthlyAmountArray[11].value[type.formcontrol])
      })
    }
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

    saveBankAccountOverDraftLimit(){
      this.selectedBankAccount.overdraftLimit=this.bankStatementsForm.controls.overdraftlimit.value
      if(this.selectedBankAccount.linkToType == "person"){
        this.applicationService.saveApplicantBankAccount(this.application.uid, this.selectedBankAccount.linkToUid,
          this.selectedBankAccount).subscribe(response=> { })
      }else if (this.selectedBankAccount.linkToType == "entity"){
        this.applicationService.saveBusinessBankAccount(this.application.uid,
          this.selectedBankAccount.linkToUid, this.selectedBankAccount).subscribe(response=> {});
      }
    }
    

}
