import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JhiLanguageService } from 'ng-jhipster';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { DateConverterService } from '../shared/date/date-converter.service';
import { TransactionSummaryDto } from './transaction-summary-dto.model';
import { FeeDto } from './loan-od-accounts-fee-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-charge-fee',
    templateUrl: './loan-od-accounts-charge-fee.component.html'
})
export class LoanOdAccountsChargeFeeComponent implements OnInit, OnDestroy {
    loanOdAccounts: LoanOdAccounts;
    proceedIsTrue: boolean;
    proceedToResult: boolean;
    feeValues: any[];
    transactionSummaryDto: TransactionSummaryDto;
    computeFeeForFeeAmountDetails: any;
    showTemplate: boolean;
    fee: any[];
    feeDto: FeeDto;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private dateConverter: DateConverterService,
        private loanODAccountsService: LoanOdAccountsService,
        private jhiLanguageService: JhiLanguageService,
    ) {
        this.proceedIsTrue = false;
        this.proceedToResult = false;
    }
    ngOnInit() {
        this.fee = this.route.snapshot.data['fee'];
        this.loanODAccountsService.findChargeFeeEntry(this.fee[0].accountId).subscribe(
            (res) => { this.feeValues = res; }
        );
        this.loanOdAccounts = this.route.snapshot.data['loanOdAccounts'];
        this.transactionSummaryDto = new TransactionSummaryDto();
        this.transactionSummaryDto.accountId = this.fee[0].accountId;
        this.transactionSummaryDto.valueDate = this.dateConverter.fromStringToNgbDatePicker(this.fee[0].chargedValueDate);
        this.transactionSummaryDto.transactionName = 'Fee Charge';
        this.transactionSummaryDto.amount1 = this.fee[0].amount.magnitude.toString();
        this.transactionSummaryDto.description = '';
        this.transactionSummaryDto.param1 = this.fee[0].feeCategory;
        this.transactionSummaryDto.currencyCode = this.loanOdAccounts.currencyCode;
        this.showTemplate = true;
    }
    computeFeeForFeeAmountDetailsDataEvent(e) {
        this.computeFeeForFeeAmountDetails = e;
    }

    ngOnDestroy() {
    }

    clear() {
        this.loanOdAccounts = new LoanOdAccounts();
        this.router.navigate(['/loan-od-accounts']);
    }

    proceed(e) {
        this.feeDto = e;
        this.proceedIsTrue = true;
    }

    back() {
        this.proceedIsTrue = false;
    }

    executeError() {
        this.proceedIsTrue = false;
    }

    executeSuccess(result) {
        this.transactionSummaryDto = result;
        this.proceedToResult = true;
    }
}
