import { Money } from './money';


export class FeeDto {

    constructor(

        public id?: number,
        public version?: number,
        public chargedValueDate?: any,
        public chargedTransactionDate?: any,
        public chargedTransactionId?: string,
        public chargedTransactionLotId?: string,
        public chargeUserId?: string,
        public feeCategory?: string,
        public feeDescription?: string,
        public surcharge?: string,
        public feeNum?: number,
        public tax1?: string,   
        public tax2?: string,
        public tax3?: string,
        public tax4?: string,
        public amount?: Money,
        public displayamount?: number,
        public grossAmount?: Money,
        public accountId?: string,
        public paymentValueDate?: any,
        public paymentTransactionDate?: any,
        public paymentTransactionId?: any,
        public paymentTransactionLotId?: any,
        public paymentUserId?: string,
        public deduction?: Money,
        public surchargeDeduction?: any,
        public transactionName?: string,
        public currencyCode?: string,
        public tax1Deduction?: string,
        public tax2Deduction?: string,
        public tax3Deduction?: string,
        public tax4Deduction?: string,
        public instrument?: string,
        public reference?: string,
        public paymentStatus?: boolean,) {
        this.amount = {
            magnitude: 0
        };
        this.deduction = {
            magnitude: 0
        };
        this.grossAmount = {
            magnitude: 0
        };
        {
            this.paymentStatus = false;
        }
    }
}
