import { Component, Input, OnInit } from '@angular/core';
import { LoanApplication ,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { MatSnackBar } from '@angular/material';
import { ApplicationService } from '../../../application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { BusinessExpense } from '../financials.model';
import { ValidationService } from '../../../application-validators/validation.service';
import { IncomeProfile } from '../../business/incomeProfile/income.model';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
@Component({
  selector: 'eng-business-expenses',
  templateUrl: './business-expenses.template.html',
  styleUrls: ['../../application-details.styles.scss', 'business-expenses.styles.css']
})
export class BusinessExpensesComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;

  businessExpensesList: BusinessExpense[];
  businessexpensesTypes: ReferenceCode[];

  businessExpenseForm: FormGroup;
  businessExpensesSummaryForm: FormGroup;

  isFormDisbled: Boolean = true;
  allowAccess: Boolean;
  cfaFinalized:boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(public toast: MatSnackBar,
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,
    private validationService: ValidationService) {
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.businessExpenseForm = this.formBuilder.group({
      expenseslist: this.formBuilder.array([]),
      businessExpensesSummaryForm: this.formBuilder.group({
        averageBusinessExpenses: null
      })
    })
    this.getCfaStatus()
    this.getRefCodes()
    this.getBusinessExpensesList()
    this.getMenuItemAccess();
  }

  //get refCodes for business eapenses
  getRefCodes() {
    this.referenceCodeService.requestRefCodesForClassfier('business_expense').subscribe((response: any) => {
      this.businessexpensesTypes = response;
    });
  }

  //this function will get all the list of Business Expenses
  getBusinessExpensesList() {
    this.applicationService.getBusinessExpenses(this.application.uid).subscribe((response) => {
      this.businessExpensesList = response.body;
      this.buildFormArrayWithData()
    })
  }

  isIncomeExpenseDisabled(referenceCode: ReferenceCode){
      return referenceCode.field1 !== null && referenceCode.field1 === 'NOENTRY'
  }

  buildFormArrayWithData() {
    const array = this.businessExpenseForm.controls.expenseslist as FormArray;
    this.businessExpensesList.forEach(businessExpense => {
      const group = new FormGroup({
        expenseType: new FormControl(businessExpense.expenseType),
        amount: new FormControl(businessExpense.pmAmount),
      });
      this.applyValidatorsToBusinessExpanse(group)
      array.push(group);
    });
    this.businessExpenseForm.disable();
    this.computeSummary()
  }
  async addNew() {
    this.businessExpenseForm.enable();
    this.isFormDisbled = false;
    this.disableExpenseType();
    const newArray = this.businessExpenseForm.controls.expenseslist as FormArray;
    const group = new FormGroup({
      expenseType: new FormControl(''),
      amount: new FormControl(''),
    });
    this.applyValidatorsToBusinessExpanse(group)
    newArray.push(group);
  }

  enableDisableForm() {
    this.businessExpenseForm.enable();
    /* let expensesFormArray = this.businessExpenseForm.get('expenseslist')['controls'];
    expensesFormArray.forEach(expensesFormGroup => {
      expensesFormGroup.controls.expenseType.disable();
    }); */
    this.disableExpenseType();
    this.isFormDisbled = false;
  }

  cancelForm() {
    this.businessExpenseForm.get('expenseslist')['controls'] = []
    this.buildFormArrayWithData()
    this.isFormDisbled = true;
  }

  disableExpenseType() {
    let expensesFormArray = this.businessExpenseForm.get('expenseslist')['controls'];
    for (let i = 0; i < this.businessExpensesList.length; i++) {
      expensesFormArray[i].controls.expenseType.disable();
      if(this.businessExpensesList[i].frozenFlag !== null && this.businessExpensesList[i].frozenFlag == true){
      expensesFormArray[i].controls.amount.disable();
      }
    }
  }

  saveBusinessExpenses() {
    this.validationService.markFormGroupTouched(this.businessExpenseForm)
    this.businessExpenseForm.updateValueAndValidity();
    if (!this.isFormDisbled && this.businessExpenseForm.valid) {
      let expensesFormArray = this.businessExpenseForm.get('expenseslist')['controls'];
      expensesFormArray.forEach((expensesFormGroup, index) => {
        if (index === this.businessExpensesList.length) {
          let newBusinessExpense = new BusinessExpense();
          newBusinessExpense.contextType = 'application';
          newBusinessExpense.contextUid = this.application.uid;
          this.businessExpensesList.push(newBusinessExpense);
        }
        this.businessExpensesList[index].expenseType = expensesFormGroup.controls.expenseType.value;
        this.businessExpensesList[index].pmAmount = expensesFormGroup.controls.amount.value;
      });
      this.applicationService.saveBusinessExpenses(this.application.uid,
        this.businessExpensesList).toPromise().then(
          (_success) => {
            this._snackbar.open("Business expenses updated successfully", "Close", {
              duration: 2000,
            });
            this.businessExpenseForm.get('expenseslist')['controls'] =[]
            this.getBusinessExpensesList();
          }, (failure) => {
            let errormesg =[]
            errormesg.push("Failed to update business expenses")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
           
            console.log(failure);
          }
        );
    }
  }
  //this function will apply validators to form group
  applyValidatorsToBusinessExpanse(group) {
    this.validationService.applyValidationRules(group, "BusinessExpense")
      .then((controlValidators) => {
        //this.buildFormArrayWithData();
      }).catch(() => { });
  }

  //this function is called onclick of "compute summary" button
  //this function will calculate sum of all amounts
  computeSummary() {
    let amountArray = this.businessExpenseForm.controls.expenseslist.value
    let sumOfAmount = amountArray
      .filter((expensesObject) => expensesObject.amount)
      .reduce((sum: number, expensesObject: any) => sum + parseInt(expensesObject.amount), 0);
    this.businessExpenseForm.get("businessExpensesSummaryForm")['controls'].averageBusinessExpenses.patchValue(sumOfAmount);

  }

  //this function will call a api to get CFA Finalized or not status
  getCfaStatus(){
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response =>{
      this.cfaFinalized=response.body.cfaFinalized
      if(this.cfaFinalized){
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close" ,{
          duration:5000,
        });
      }
    })
  }
  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}
