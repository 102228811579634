import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class DefaultCurrencySymbolService {
    private baseUrl = environment.API_URL;
    private symbol: string;
    private currencyCode: string
    constructor(
        private http: HttpClient
    ) { }

    getSymbol() {
        return this.symbol;
    }

    syncSymbol() {
        this.http.get(`${this.baseUrl}/encore/api/getDefaultCurrencySymbol`).subscribe(
            (res) => {
                this.fnSymbol(res);
            },
            (res) => {
                this.fnSymbol(res.error.text)
            }
        );
    }
    fnSymbol(res) {
        this.symbol = res;
    }

    getCurrencyCode() {
        return this.currencyCode;
    }

    syncCurrencyCode() {
        this.http.get(`${this.baseUrl}/encore/api/getDefaultCurrencyCode`).subscribe(
            (res) => {
                this.currencyCode = res.toString();
                this.fnSymbol(res);
            },
            (res) => { console.log('Failure to update default currency code.'); }
        );
    }

}
