import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LoanApplication, ApplicationAccessSpecifier, Person } from '@app/applications/applications.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { MatExpansionPanel } from '@angular/material';
import { File, Folder, FolderFilesComposite } from './loan-folder-files.model';
import { MatSnackBar } from '@angular/material';
import { SessionStorageService } from 'angular-web-storage';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as fileSaver from 'file-saver';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'eng-loan-folder-files',
  templateUrl: './loan-folder-files.component.html',
  styleUrls: ['../../application-details.styles.scss', './loan-folder-files.component.css']
})
export class LoanFolderFilesComponent implements OnInit, OnChanges {
  @Input() application: LoanApplication;
  @Input() menuCode: string;
  @Input()
  applicant: Person;

  selectedFolder: string;

  documents: Files[] = [];
  allFoldersData: Folder[] = [];
  allFilesInFolder: FolderFilesComposite;
  fileData: File

  allowAccess: Boolean;
  showFolderAdd: boolean = false
  isFormDisabled: Boolean = true;
  menuItemAllowAccess: boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  folderFilesForm: FormGroup;

  myThumbnail: any;

  // headerText is used to show title name and no folder/financials found message, since we are re-using same component we are setting this value
  headerText: string;
  // for financial we need to hide viewImage button hence we are using allowViewImage variable. Initially we are making it as true
  allowViewImage: boolean = true;
  // for financial component we need to show show file delete button this is not based on menuItemAccess hence we are using allowDeleteFile boolean
  allowDeleteFile: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private $sessionStorage: SessionStorageService,
    private fileService: IgFileService,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private confirmationDialogService:ConfirmationDialogService,
  ) {
    this.allowAccess = this.applicationService.allowAccess;
  }
  ngOnInit() {
    this.folderFilesForm = this.formBuilder.group({
      newFolderName: '',
      selectedFolder: ''
    })
    this.getMenuItemAccess();
  }

  // here we are re-using LoanFolderFilesComponent for Financial compoent as well, hence we are checking 
  // based on menuCode which component is getting called. For financials we have given menuCode as "FINANCIALS"
  // In Financials we are not showing view button and edit, save and cancle button is not present this is done based on allowAccess
  // Right now we are showing delete button for files in financial screen later we need to remove this condition
  ngOnChanges(changes: SimpleChanges) {
    if (changes.menuCode || changes.applicant) {
      if(this.menuCode === 'FINANCIALS'){
        this.headerText = "FINANCIALS";
        this.allowViewImage = false;
        this.allowDeleteFile = true;
        this.getFinancialFolders();
      }else{
        this.headerText = "FOLDERS";
        this.getAllFolders();
      }
    }
  }

  getAllFolders() {
    this.applicationService.getApplicationFolders(this.application.uid).subscribe(response => {
      this.allFoldersData = response.body
      if (this.allFoldersData.length != 0) {
        this.isFormDisabled = true
        this.getSelectedFolderFiles(this.allFoldersData[0].uid)
      }
    }, error => {
      let errormesg =[]
            errormesg.push('Feching Folders data Failed')
            errormesg.push(error)
            this.applicationService.displayErrorMessages(errormesg);
    })
  }

  getFinancialFolders() {
    this.applicationService.getFinancialFolders(this.application.uid, this.applicant.uid).subscribe(response => {
      // here we are resetting values to clear the previous saved values or else on change it will show the older value
      this.allFoldersData = [];
      this.allFilesInFolder = null;
      // here allFoldersData is expecting list but response we are getting is object, since we are re-using same component we are
      // pushing object into empty list to work for both component. Hencer we are creating empty list and pushing the response object to it
      if(response.body){
        let listOfValue = [];
        listOfValue.push(response.body);
        this.allFoldersData = listOfValue;
      }
      if (this.allFoldersData.length != 0) {
        this.isFormDisabled = true
        this.getSelectedFolderFiles(this.allFoldersData[0].uid)
      }
    }, error => {
      let errormesg =[]
            errormesg.push('Feching Folders data Failed')
            errormesg.push(error)
            this.applicationService.displayErrorMessages(errormesg);
    })
  }

  clickAddNewFolder() {
    this.showFolderAdd = true
  }

  //onclick ok function this function will trigger to create new folder
  createNewFolder() {
    this.isFormDisabled = false
    this.showFolderAdd = false
    let newFolderObject = {
      "uid": null,
      "name": this.folderFilesForm.controls.newFolderName.value
    }
    this.applicationService.saveOrUpdateApplicationFolder(this.application.uid, newFolderObject).subscribe((response: any) => {
      if (response.body) {
        this.getAllFolders();
      }
    }, error => {

      let errormesg =[]
      errormesg.push('New Folder creation failed')
      errormesg.push(error)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  getSelectedFolderFiles(uid) {
    this.folderFilesForm.controls.selectedFolder.patchValue(uid)
    if(this.menuCode === 'FINANCIALS'){
      this.applicationService.getAllFilesInFinancialFolder(this.application.uid,this.applicant.uid, uid).subscribe((response: any) => {
        if (response) {
          this.allFilesInFolder = response.body;
          this.convertBytesToSize();
        }
      })
      }else{
        this.applicationService.getAllFilesInApplicationFolder(this.application.uid, uid).subscribe((response: any) => {
          if (response) {
            this.allFilesInFolder = response.body;
            this.convertBytesToSize();
          }
        })
      }
  }

  convertBytesToSize(){
    this.allFilesInFolder.fileInfo.forEach(element => {
      if (element.fileSize) {
        element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
      }
    });
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  cancelForm() {
    this.showFolderAdd = false
  }


  //this function open up the image upload dailog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if(this.folderFilesForm.controls.selectedFolder.value != ''){
       this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.folderFilesForm.controls.selectedFolder.value, "applicationUid": this.application.uid, "files": this.allFilesInFolder.fileInfo })
      this.fileUploadService.confirmed().subscribe(data=>{
        this.getSelectedFolderFiles(data.folderUid)
      })
       
      
    }else{
      this._snackbar.open("Please add a folder to upload files", "Close", {
        duration: 5000,
      });
    }
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      fileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }

  //this function will be called on click of delete icon on file list
deleteFile(selectedFile){
  this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
    if(response.body.status == "success") {
    this._snackbar.open("File deleted successfully", "Close", {
      duration: 2000,
    });
    this.getSelectedFolderFiles(this.folderFilesForm.controls.selectedFolder.value);
  } else {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(response.body.message)
    this.applicationService.displayErrorMessages(errormesg);
  }}, (failure) => {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(failure)
    this.applicationService.displayErrorMessages(errormesg);
  });
}

  openfileDeleteConfirmation(selectedFile){
      this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this File?"})
      this.confirmationDialogService.confirmed().subscribe(data=>{
        if(data){
        this.deleteFile(selectedFile)
        }
      })
    
  }
 
   //this function open up the image viewer dailog box
   openImagePreviewDialog() {
    this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
  }

}
