import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot,  ActivatedRouteSnapshot } from '@angular/router';
import { Observable} from 'rxjs';
import { Zone } from './zone.model';
import { ZoneService } from './zone.service';

@Injectable({
  providedIn: 'root',
})
export class ZoneResolverService implements Resolve<Zone> {
  constructor(private zoneService: ZoneService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    return this.zoneService.getZone(route.queryParamMap.get('zoneUid'));
  }
}
