import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { JhiLanguageService } from 'ng-jhipster';
import {LoanOdMoratoriumDtoModel} from './loan-od-moratorium-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-amend-moratorium-entry',
    templateUrl: './loan-od-accounts-amend-moratorium-entry.component.html'
})
export class LoanOdAccountsAmendMoratoriumEntryComponent implements OnInit, OnDestroy {
    @Input() moratoriums: LoanOdMoratoriumDtoModel[];
    currentSearchString: string;
    currentAccount: any;
    subscription: Subscription;
    accountId: string;
    @Output() proceedToExecute: EventEmitter<LoanOdMoratoriumDtoModel> = new EventEmitter<LoanOdMoratoriumDtoModel>();

    constructor(
        private jhiLanguageService: JhiLanguageService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.accountId = params['accountId']
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    createMoratorium() {
        this.router.navigate(['/loans/loan-od-accounts/createMoratorium', this.accountId]);
    }

    editMoratorium(moratorium) {
        const moratoriumClone = JSON.parse(JSON.stringify(moratorium));
        this.proceedToExecute.emit(moratoriumClone);
    }

}
