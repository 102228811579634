///<reference path="transaction-summary-dto.model.ts"/>
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AlertService, JhiLanguageService } from 'ng-jhipster';
import { Router } from '@angular/router';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdRepaymentCompositeDto } from './loan-od-accounts-repayment-composite-dto.model';
import { NameValueDto } from './name-value-dto';
import { Accountcode } from '../internal/accountcode.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';

@Component({
    selector: 'jhi-loan-od-accounts-composite-repayment-entry',
    templateUrl: 'loan-od-accounts-composite-repayment-entry.component.html'
})
export class LoanOdAccountsCompositeRepaymentEntryComponent implements OnInit, OnDestroy {
    @Input() instruments: NameValueDto;
    accountcodes: Accountcode;
    @Input() loanOdAccounts: LoanOdAccounts;
    @Input() loanOdRepaymentCompositeDto: LoanOdRepaymentCompositeDto;
    totalAmount: number;
    displayTotalAmount: string;
    @Input() repaymentEffects: any[];
    isDemandDue: boolean;
    @Output() proceedToExecute: EventEmitter<LoanOdRepaymentCompositeDto> = new EventEmitter<LoanOdRepaymentCompositeDto>();

    constructor(
        private jhiLanguageService: JhiLanguageService,
        private loanODAccountsService: LoanOdAccountsService,
        private router: Router,
        private alertService: AlertService,
        // private dateservice: DateConverterService
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }
    ngOnInit() {
        this.loanOdRepaymentCompositeDto.repaymentDate = new NgbDate(this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[0] ?
            parseInt(this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[0]) : 2020, this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[1] ?
            parseInt(this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[1]) : 1, this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[2] ?
            parseInt(this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[2]) : 1);
        this.displayAccountCodes(this.loanOdRepaymentCompositeDto.instrument);
        if (this.loanOdRepaymentCompositeDto.summary.totalDemandDue.magnitude > 0) {
            this.isDemandDue = true;
        }
    }
    ngOnDestroy() {
    }

    displayAccountCodes(instrument) {
        if (instrument === 'CHEQUE_OR_DD' || instrument === 'OTHER' || instrument === 'NEFT' || instrument === 'RTGS' || instrument === 'IMPS'
            || instrument === 'UPI' || instrument === 'AUTOPAY' || instrument === 'DEBIT_CARD' || instrument === 'CREDIT_CARD') {
            this.loanODAccountsService.getAccountCodesByGroup().subscribe(
                (res) => {
                    this.setAccountCodes(res);
                });
        } else {
            this.accountcodes = undefined;
        }
    }

    private setAccountCodes(data) {
        this.accountcodes = data;
    }

    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }

    private checkDemandSatisfied(loanOdRepaymentCompositeDto): boolean {
        if (this.isDemandDue &&
            (loanOdRepaymentCompositeDto.repaymentItems.prepayment.magnitude > 0 ||
                loanOdRepaymentCompositeDto.repaymentItems.advanceRepayment.magnitude > 0 ||
                loanOdRepaymentCompositeDto.repaymentItems.feePayment.magnitude > 0 ||
                loanOdRepaymentCompositeDto.repaymentItems.feeDeduction.magnitude > 0
            )
        ) {
            if (loanOdRepaymentCompositeDto.repaymentItems.demandRepayment.magnitude >=
                loanOdRepaymentCompositeDto.summary.totalDemandDue.magnitude) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    proceed() {
        const isDemandSatisfied = this.checkDemandSatisfied(this.loanOdRepaymentCompositeDto);
        if (isDemandSatisfied) {
            this.loanODAccountsService.feePaymentExecute(this.loanOdRepaymentCompositeDto).subscribe(
                (res) => {
                    this.proceedToExecute.emit(res);
                },
                (res) => this.onSaveError(res)
            );
        } else {
            this.alertService.error('Scheduled Demand not met');
        }
    }

    private onSaveError(error) {
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
    }

    private onError(error) {
        this.alertService.error(error.error);
    }
}
