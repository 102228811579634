import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { MaterialLibModule } from '@app/material-lib.module';
import { FileUploadService} from './file-upload.service';
import { FileDragnDropDirective} from './file-drag-drop-upload.directive';
import { ImageCropperModule } from 'ngx-image-cropper-upgraded';
import { WebcamModule } from 'ngx-webcam';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [FileUploadComponent,FileDragnDropDirective],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MaterialLibModule,
    ImageCropperModule,
    WebcamModule,
    FlexLayoutModule
  ],
  exports: [FileUploadComponent],
  entryComponents: [FileUploadComponent],
  providers: [FileUploadService],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class FileUploadModule { }
