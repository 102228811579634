export class BusinessDocumentComposite {
    constructor(
        public documents?: Documents
    ) { }
}

export class Documents {
    constructor(
        public uid?: string,
        public documentType?: string,
        public documentName?: string,
        public documentDescription?: string,
        public verifiedBy?: string,
        public verificationDate?: Date,
        public verificationStatus?: string,
        public verifiedFlag?: boolean,
        public fileFolderUid?: string,
        public linkToType?: string,
        public linkToId?: string,
        public linkToUid?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public version?: number,
        public deleteBtnDisabled?: boolean//added for client side use this paramenter will not come from server
    ) { }
}
