import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BranchService } from '@app/admin/branch/branch.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
import { LoggedInUserService } from '@ig-core/authentication/logged-in-user.service';
import { DashboardService } from '../dashboard.service';
import { FieldBoard, FieldSearchCriteria } from './field-board.model';

@Component({
  selector: 'eng-field-board',
  templateUrl: './field-board.component.html',
  styleUrls: ['./field-board.component.scss', '../dashboard.component.scss']
})
export class FieldBoardComponent implements OnInit, OnDestroy {
  fieldBoardDetails: FieldBoard;
  userBranches: NameValueDto[];
  fieldSearchForm: FormGroup;
  listOfFeatures: string[];
  branch: string[] = []; // Initialize as an empty array

  constructor(
    private dashboardService: DashboardService,
    private branchService: BranchService,
    private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private loggedInUserService: LoggedInUserService
  ) {}

  ngOnInit() {
    // Initialize the form with empty values and default branch
    this.fieldSearchForm = this.formBuilder.group({
      login: '',
      branches: '' // Set the default value here
    });

    // Fetch user branches and available features
    this.getUserBranches();
    this.showForDefaultBranch();
  }

  // This function is called when the default branch for the user is retrieved
  showForDefaultBranch() {
    this.loggedInUserService.get().subscribe((response: any) => {
      if (response) {
        this.branch = response.body.branchCode;
        this.getRoAndBranch();
      }
    });
  }

  // Fetch the branches available for the user
  getUserBranches() {
    this.branchService.getBranchesForUser().subscribe((response: any) => {
      if (response) {
        this.userBranches = response.body;
      }
    });

    // Fetch the list of features needed to be shown in the expansion panel on the left
    // The features are converted to lowercase for easier comparison later
    this.referenceCodeService.getFeatureSet().subscribe((response) => {
      this.listOfFeatures = response.body.map((feature) => feature.toLocaleLowerCase());
    });
  }

  handleAllOption() {
    let selectedOptions = this.fieldSearchForm.value.branches;

    if (selectedOptions.includes('all')) {
      // If "ALL" is selected, select all other options
      let allBranchCodes = this.userBranches.map(branch => branch.code);
      let selectedValues = allBranchCodes.concat('all');
      this.fieldSearchForm.get('branches').setValue(selectedValues);
    }
    else {
      // If "ALL" is not selected, set the form value with empty, this will deselect all the options
      this.fieldSearchForm.get('branches').setValue([]);
    }
  }

  selectedValues(){
    let selectedOptions = this.fieldSearchForm.value.branches;
    
    // this filter we are doing to uncheck "all" option if we uncheck any one value after clicking "all"
    let updatedSelection = selectedOptions.filter(option => option !== 'all');
    this.fieldSearchForm.get('branches').setValue(updatedSelection);

    // this we are checking beacause if we select all the dropdown values that is nothing but selectAll option
    // hence adding "all" so that option "all" will be checked
    if (updatedSelection.length === this.userBranches.length) {
      let selectedValue = selectedOptions.concat('all');
      this.fieldSearchForm.get('branches').setValue(selectedValue);
    } 
  }


  // This function is called when the user performs a search by selecting branches and/or entering a login
  getRoAndBranch() {
    // Determine the branches to search for based on form values or default branch
    let branches =
      this.fieldSearchForm.value.branches.length === 0 && this.fieldSearchForm.value.login === ''
        ? this.branch
        : this.fieldSearchForm.value.branches;
    const login = this.fieldSearchForm.value.login;

    // if user select all and click on show then it will send option 'all' as well,
    // hence we are removing 'all' from selected branches if 'all' is included
    if (branches.includes('all')) {
      branches = branches.filter(option => option !== 'all');
    }

    // Call the dashboard service to retrieve the field board details
    this.dashboardService.findFieldBoard(branches, login).subscribe(
      (response: HttpResponse<FieldBoard>) => {
        // Handle the response here if needed
        this.fieldBoardDetails = response.body;
      },
      (error) => {
        // Handle errors here if needed
      }
    );
  }

  ngOnDestroy(): void {
    // Perform cleanup operations if needed when the component is destroyed
  }
}
