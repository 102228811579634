export class ReferenceCode {
    id?: number;
    version?: number;
    classifier?: string;
    name?: string;
    code?: string;
    parentReferenceCode?: string;
    parentClassifier?: string;
    status?: string;
    field1?: string;
    field2?: string;
    field3?: string;
    field4?: string;
    field5?: string;
}

export class ReferenceCodeSearchCriteria {
    constructor(
        public classifier ?: string,
    ) { }
}
