import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import { MaterialLibModule } from '@app/material-lib.module';

import {EditableResolveService} from '@ig-core/resolves/editable-resolve.service';
import {NonEditableResolveService} from '@ig-core/resolves/non-editable-resolve.service';
import {AuthenticatedUserService} from '@ig-core/authentication/authenticated-user-service';


import {BranchSetResolverService} from '@app/admin/branch-set/branch-set.resolve';
import {BranchSetListComponent} from '@app/admin/branch-set/branch-set-list/branch-set-list.component';
import {BranchSetDeleteDialogComponent} from '@app/admin/branch-set/branch-set-list/branch-set-delete-dialog.component';
import {BranchSetEditorComponent} from '@app/admin/branch-set/branch-set-edit/branch-set-editor.component';

export const branchSetRoute: Route = {
  path: 'branch-set',
  canActivate: [AuthenticatedUserService],
  children: [
    {path: '', component: BranchSetListComponent},
    {path: 'create', component: BranchSetEditorComponent,
      resolve: {
        editable: EditableResolveService
      }},
    {path: 'update/:id', component: BranchSetEditorComponent,
      resolve: {
        branchSet: BranchSetResolverService,
        editable: EditableResolveService
      }
    },
    {path: 'view/:id', component: BranchSetEditorComponent,
      resolve: {
        branchSet: BranchSetResolverService,
        editable: NonEditableResolveService
      }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [ BranchSetListComponent, BranchSetEditorComponent, BranchSetDeleteDialogComponent],
  entryComponents: [BranchSetDeleteDialogComponent]
})
export class BranchSetModule { }
