import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSidenav, MatSnackBar, MatSort, DateAdapter } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ListSource } from '../../../../projects/ig-core/src/lib/list/list-source';
import { PagingAndSortParams } from '../../../../projects/ig-core/src/lib/list/paging-and-sort-params';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DeviceDataSource } from './device.data.source';
import { DeviceSearchCriteria } from './device.model';
import { DeviceService } from './device.service';
import { ReferenceCode } from '../reference-code/reference-code.model';
import { ReferenceCodeService } from '../reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { UserService } from '../user/user.service';
import { Branch } from '../branch/branch.model';

@Component({
  selector: 'eng-device-directory',
  templateUrl: './device.template.html',
  styleUrls: ['./device.style.scss'],
})

export class DeviceComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;
  
  pagingAndSortParams: PagingAndSortParams;

  dataSource: DeviceDataSource;
  listSource: ListSource;

  deviceSearchForm: FormGroup
  searchCriteria: DeviceSearchCriteria;

  flexMediaWatcher: Subscription;
  status: NameValueDto[];
  branches: Branch;

  currentScreenWidth: string = '';
  displayedColumns: string[] = ['user', 'app', 'device', 'node', 'status', 'branch', 'action'];

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private referenceCodeService: ReferenceCodeService,
    private router: Router,
    private media: MediaObserver,
    private deviceService: DeviceService,
    private userService: UserService,
    private dateAdapter: DateAdapter<Date>) {
    this.flexMediaWatcher = media.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias !== this.currentScreenWidth) {
        this.currentScreenWidth = change.mqAlias;
        this.setTableColumns();
      }
    });
    this.dateAdapter.setLocale('en-IN');
  }

  setTableColumns() {
    this.displayedColumns = ['user', 'app', 'device', 'node', 'status', 'branch', 'action'];
    if (this.currentScreenWidth === 'xs') {
      this.displayedColumns = ['user', 'app', 'device', 'action'];
    }
  };

  ngOnInit() {
    //Instantiate empty search form
    this.getBranches();
    this.getRefCodes();
    this.deviceSearchForm = this.formBuilder.group({
      user: "",
      app: "",
      status: "",
      branch: ""
    });

    //Instantiate dataSource for subscribing changes in list data
    this.dataSource = new DeviceDataSource(this.deviceService);

    //Instantiate listSource for subscribing changes in search criteria
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);

    //Subscribe to changes in search criteria
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        this.getDeviceDirectory('');
      });
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  loadDevicePageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  ngAfterViewInit(): void {
    //Subscribe to changes in pagination and sort criteria
    this.listSource.subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this))).subscribe(
        () => this.loadDevicePageWithParams());
  }

  getDeviceDirectory(deviceSearch) {
    if(deviceSearch === 'onClickDeviceSearch'){
      this.pagingAndSortParams.pageIndex =0
    }
    this.generateDeviceSearchCriteria();
    let sortCriteria = 'uid' + ',' + 'desc';
    this.dataSource.getDeviceDirectory(sortCriteria, this.pagingAndSortParams.pageIndex,
      this.pagingAndSortParams.pageSize, this.searchCriteria);
  }

  
  generateDeviceSearchCriteria() {
    this.searchCriteria = {
      user: this.deviceSearchForm.value.user,
      app: this.deviceSearchForm.value.app,
      status: this.deviceSearchForm.value.status,
      branch: this.deviceSearchForm.value.branch
    }
    // this.getDeviceDirectory();
  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('device_status').subscribe((response: any) => {
      this.status = response.device_status;
    });
  }

  redirectEditDevice(device) {
    this.router.navigate(['admin/device-directory/update/' + device.uid], { queryParams: { data: JSON.stringify(device) } })
  }

  getBranches() {
    this.userService.getAllBranches().subscribe((response: any) => {
      if (response) {
        this.branches = response;
      }
    });
  }

}