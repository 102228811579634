import { AccountMandateDto } from './account-mandate-dto.model';
import { TransactionSummaryDto } from './transaction-summary-dto.model';
import {AccountProfileDto} from './accountt-profile-dto.model';
import {ContactDto} from './contact-dto.model';
import {Money} from './money';
//import {any} from '../../customer/profile/customer-name.model';
import {Tenure} from './tenure';
export class LoanOdAccounts {
    constructor(
        public id?: number,
        public accountId?: string,
        public productType?: string,
        public productCode?: string,
        public accountName?: string,
        public any?: string,
        public branchCode?: string,
        public glSubHead?: string,
        public normalInterestRate?: any,
        public penalInterestRate?: any,
        public postMaturityNormalInterestRate?: string,
        public operationalMode?: string,
        public operationalStatus?: string,
        public dayNumSchedule?: boolean,
        public customerId1?: string,
        public customerName1?: any,
        public contact1?: ContactDto,
        public altContact1?: ContactDto,
        public altContact2?: ContactDto,
        public customerId2?: string,
        public customerName2?: any,
        public contact2?: ContactDto,
        public displayAmount?: number,
        public amount?: Money,
        public tenure?: Tenure,
        public tenureUnit?: string,
        public tenureMagnitude?: number,
        public disbursementByDate?: any,
        public migrated?: boolean,
        public moratoriumPeriod?: Tenure,
        public moratoriumType?: string,
        public accountBalance?: Money,
        public accountOpenDate?: any,
        public lastArchiveBalance?: string,
        public lastArchiveDate?: any,
        public lastEntryDate?: any,
        public bookedNotDueNormalInterest?: Money,
        public bookedNotDuePenalInterest?: Money,
        public moratoriumInstallment?: Money,
        public bookedNormalInterest?: string,
        public bookedPenalInterest?: string,
        public demandAdjustment?: string,
        public interestAdjustment?: string,
        public normalInterestLimit?: string,
        public nominalInterestRate?: any,
        public totalSecurityDepositDue?: Money,
        public securityDeposit?: Money,
        public defaultSecurityDeposit?: string,
        public securityDepositAllowed?: boolean,
        public numDisbursements?: number,
        public numDemands?: number,
        public numSatisifiedDemands?: number,
        public numRepayments?: number,
        public numAdvanceSatisfiedDemands?: number,
        public numScheduledDemandsRaised?: number,
        public firstDisbursementDate?: any,
        public lastDisbursementDate?: any,
        public lastDemandRunDate?: any,
        public nextDemandRunDate?: any,
        public nextDemandScheduledDate?: any,
        public lastBookingDate?: any,
        public firstRepaymentDate?: any,
        public lastRepaymentDate?: any,
        public maturityDate?: any,
        public closedOnValueDate?: any,
        public lastActivatedDate?: any,
        public lastFreezedDate?: any,
        public totalPrincipalRepaid?: string,
        public totalNormalInterestRepaid?: string,
        public totalPenalInterestRepaid?: string,
        public totalFeeRepaid?: string,
        public totalRepaid?: string,
        public totalPrincipalDue?: string,
        public totalNormalInterestDue?: string,
        public totalPenalInterestDue?: string,
        public totalFeeDue?: Money,
        public totalDemandDue?: Money,
        public totalPrincipalRaised?: string,
        public totalNormalInterestRaised?: Money,
        public totalPenalInterestRaised?: Money,
        public totalDemandRaised?: string,
        public totalDisbursed?: any,
        public recoverableInterest?: Money,
        public principalNotDue?: Money,
        public payOffAmount?: string,
        public payOffAndDueAmount?: Money,
        public disbursableAmount?: Money,
        public daysPastDue?: number,
        public npa?: boolean,
        public forceNpa?: boolean,
        public principalWriteOff?: string,
        public totalNormalInterestWaiver?: string,
        public totalPenalInterestWaiver?: string,
        public writeOffDate?: any,
        public tag?: string,
        public netDisbursementRequired?: boolean,
        public fee?: string,
        public defaultFirstRepaymentDate?: any,
        public minFirstRepaymentDate?: any,
        public maxFirstRepaymentDate?: any,
        public accountMandate?: string,
        public guarantorCustomerId?: string,
        public equatedInstallment?: Money,
        public linkedAccountId?: string,
        public preclosureFee?: Money,
        public assetCategory?: string,
        public provisionedAmount?: string,
        public lastProvisioningDate?: any,
        public nextProvisioningDate?: any,
        public udfText1?: string,
        public udfText2?: string,
        public udfText3?: string,
        public udfText4?: string,
        public udfText5?: string,
        public udfText6?: string,
        public udfText7?: string,
        public udfText8?: string,
        public udfText9?: string,
        public udfText10?: string,
        public udfText11?: string,
        public udfText12?: string,
        public udfText13?: string,
        public udfText14?: string,
        public udfText15?: string,
        public udfText16?: string,
        public udfText17?: string,
        public udfText18?: string,
        public udfText19?: string,
        public udfText20?: string,
        public udfDate1?: any,
        public udfDate2?: any,
        public udfTextLabel1?: string,
        public udfTextLabel2?: string,
        public udfTextLabel3?: string,
        public udfTextLabel4?: string,
        public udfTextLabel5?: string,
        public udfTextLabel6?: string,
        public udfTextLabel7?: string,
        public udfTextLabel8?: string,
        public udfTextLabel9?: string,
        public udfTextLabel10?: string,
        public udfTextLabel11?: string,
        public udfTextLabel12?: string,
        public udfTextLabel13?: string,
        public udfTextLabel14?: string,
        public udfTextLabel15?: string,
        public udfTextLabel16?: string,
        public udfTextLabel17?: string,
        public udfTextLabel18?: string,
        public udfTextLabel19?: string,
        public udfTextLabel20?: string,
        public udfDateLabel1?: string,
        public udfDateLabel2?: string,
        public underSettlement?: boolean,
        public lastNpaMarkingDate?: any,
        public lastNpaUnmarkingDate?: any,
        public demandInterval?: string,
        public postMaturityDemandInterval?: string,
        public gstin?: string,
        public locationCode?: string,
        public originalAccountId?: string,
        public transactions?: string,
        public historyTransactions?: string,
        public repaymentSchedule?: string,
        public accountStatement?: string,
        public accountEntryDto?: string,
        public balance?: string,
        public subAccountId?: string,
        public instrument?: string,
        public  remarks?: string,
        public fees?: TransactionSummaryDto[],
        public transactionSummary?: TransactionSummaryDto[],
        public accountMandateDto?: AccountMandateDto,
        public accountProfileDto?: AccountProfileDto,
        public openedOnDate?: any,
        public displayAccountOpenDate?: string,
        public reference?: string,
        public oldBranchCode?: string,
        public operativeAccountId?: string,
        public userSecurityDeposit?: Money,
        public displayName1?: string,
        public preclosureAllowed?: any,
        public advancePaymentAllowed?: any,
        public prepaymentAllowed?: any,
        public guarantorCustomerId1?: string,
        public guarantorCustomerId2?: string,
        public normalInterestWriteOff?: any,
        public penalInterestWriteOff?: any,
        public feeWriteOff?: any,
        public segment?: string,
        public portfolioName?: string,
        public collateralCode?: string,
        public customerId3?: string,
        public customerName3?: any,
        public contact3?: ContactDto,
        public customerId4?: string,
        public customerName4?: any,
        public currencyCode?: string,
        public contact4?: ContactDto,
        public customerId5?: string,
        public customerName5?: any,
        public contact5?: ContactDto,
        public customerId6?: string,
        public customerName6?: any,
        public contact6?: ContactDto,
        public relationshipType2?: string,
        public relationshipType3?: string,
        public relationshipType4?: string,
        public relationshipType5?: string,
        public relationshipType6?: string,
        public customer1DateOfBirth?: any,
        public customer2DateOfBirth?: any,
        public customer3DateOfBirth?: any,
        public customer4DateOfBirth?: any,
        public customer5DateOfBirth?: any,
        public customer6DateOfBirth?: any
) {
        this.dayNumSchedule = false;
        this.migrated = false;
        this.securityDepositAllowed = false;
        this.npa = false;
        this.forceNpa = false;
        this.netDisbursementRequired = false;
        this.underSettlement = false;
        relationshipType2 = null;
        relationshipType3 = null;
        relationshipType4 = null;
        relationshipType5 = null;
        relationshipType6 = null;

    }
}
