import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ZoneService } from '../zone.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GoogleMapsHelperService } from '../google-maps-helpers/google-maps-helper.service';
import { BottomSheetService } from '@app/ig-layout/bottom-sheet/bottom-sheet.service';
import { RoTripViewService } from './ro-trip-view.service';
import { MapMarkersComposite } from "./map-marker.model";
import { MapMarkerSegment } from "./map-marker-segment.model";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Zone } from '../zone.model';

@Component({
    selector: 'app-ro-trip-view',
    templateUrl: 'ro-trip-view.component.html',
    styleUrls: ['ro-trip-view.component.css']
})
export class ROTripViewComponent implements OnInit, OnDestroy {

    zone: Zone;

    @ViewChild('mapContainer')
    mapContainer: ElementRef;

    map: google.maps.Map;

    mapMarker : MapMarkersComposite;
    segments : MapMarkerSegment[] = [];

    mapMarkersArray = [];

    mapOptions: google.maps.MapOptions = {
        zoom: 12,
        disableDoubleClickZoom: true
    };

    markerIconUrl = "http://maps.google.com/mapfiles/ms/icons/";

    markersFilterForm : FormGroup;

    constructor(private zoneService: ZoneService,
            private activatedRoute: ActivatedRoute,
            private router: Router,
            private googleMapsHelper: GoogleMapsHelperService,
            private bottomSheetService : BottomSheetService,
            private roTripViewService : RoTripViewService,
            private formBuilder: FormBuilder) {
        this.activatedRoute.data
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data) => {
            this.zone = data.zone;
        });
        this.activatedRoute.queryParams
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((params) => {
            this.zone.color = params.zoneColor;
        });
        this.markersFilterForm = formBuilder.group({
            zoneUid : this.zone.uid,
            markerType : 'track',
            timeFrame : 'today'
        });
    }

    ngOnInit() {
        this.mapInitializer();
        this.getZonePolygon();
        this.getRoTripCoordinates();
    }

    mapInitializer() {
        this.map = new google.maps.Map(this.mapContainer.nativeElement, this.mapOptions);
        var centerLatLong = JSON.parse(this.zone.center);
        this.map.setCenter(new google.maps.LatLng(centerLatLong.lat, centerLatLong.lng));
    }

    getZonePolygon() {
        const polygon = new google.maps.Polygon(this.googleMapsHelper.getPolygonForZone(
              JSON.parse(this.zone.coordinates), this.zone));
        polygon.setMap(this.map);
    }

    getRoTripCoordinates() {
        this.setMapForMarkers(null);
        this.mapMarkersArray = [];
        this.roTripViewService.getRoTripCoordinates(this.markersFilterForm.value)
            .subscribe((response) => {
                this.mapMarker = response.body;
                this.segments = this.mapMarker.segments;
                this.showRoCoordinates();
        });
    }

    showRoCoordinates() {
        this.segments.forEach(segment => {
            segment.points.forEach(point => {
                var marker = new google.maps.Marker({
                    position: {lat: point.lat, lng: point.lng},
                    icon: {
                        url: this.markerIconUrl + segment.mapMarkerColor + "-dot.png"
                    }
                });
                this.mapMarkersArray.push(marker);
            });
        });
        this.setMapForMarkers(this.map);
    }

    setMapForMarkers(map) {
        this.mapMarkersArray.forEach(mapMarker => {
            mapMarker.setMap(map);
        });
    }

    selectZone(zone) {
        this.zoneService.$selectedZoneFeeder.next(zone);
    }

    openBottomSheet(): void {
        this.bottomSheetService.open();
    }

    generateCordinates(): void {
        var centerLatLong = JSON.parse(this.zone.center);
        var userTrack = {
            lat : centerLatLong.lat,
            lng : centerLatLong.lng,
            personUID : this.zone.ro.id,
            zoneUID : this.zone.uid
        }
        this.roTripViewService.generateCordinates(userTrack)
            .subscribe((response) => {
                this.getRoTripCoordinates();
        });
    }

    close() {
        this.router.navigate(['/zones']);
    }
    
    ngOnDestroy(): void {
    }
}