import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';
import { CollectionViewer } from '@angular/cdk/collections';

import { OrgStructureSearchCriteria, OrganizationStructure } from './organization-structure.model';
import { OrganizationStructureService } from './organization-struture.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';

export class OrganizationStructureDataSource extends DataSource<OrganizationStructure> {

  private applicationSubject = new BehaviorSubject<OrganizationStructure[]>([]);
  public totalCount$ = new BehaviorSubject<number>(0);

  organizationStructureService: OrganizationStructureService;
  levels: NameValueDto[];

  constructor(private applicationService: OrganizationStructureService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<OrganizationStructure[]> {
    return this.applicationSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.applicationSubject.complete();
    this.totalCount$.complete();
  }

  getOrganizationStructure(sortDirection = 'asc', pageIndex = 0, pageSize = 10,
    searchCriteria: OrgStructureSearchCriteria) {
    this.applicationService.getOrgStructureData({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }, searchCriteria).pipe(
      catchError(() => of([])),
      finalize(() => { })
    ).pipe(take(1)).subscribe((response) => {
        this.totalCount$.next(response['body'].totalElements);
        this.applicationSubject.next(response['body'].content);
      });
    }
} 