import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { SecureStorageService } from './secureStorage.service'
@Injectable({providedIn: 'root'})
export class AuthTokenService {
  constructor(
    private http: HttpClient,
    private secureStorageService: SecureStorageService
  ) {
  }

  getToken() {
    return this.secureStorageService.secureLocalStorage.getItem('authenticationToken') || this.secureStorageService.secureSessionStorage.getItem('authenticationToken'); 
  }

  storeAuthenticationToken(jwt, rememberMe) {
    if (jwt && jwt.slice(0, 7) === 'Bearer ') {
      jwt = jwt.slice(7, jwt.length);
    }
    if (rememberMe) {
      this.secureStorageService.secureLocalStorage.setItem('authenticationToken',jwt)
    } else {
      this.secureStorageService.secureSessionStorage.setItem('authenticationToken',jwt); 
    }
  }

  clearToken() {
    this.secureStorageService.secureLocalStorage.clear();
    this.secureStorageService.secureSessionStorage.clear();
  }
}
