import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ProductMasterComposite } from '../product-master.model';
import { ProductMasterService } from '../product-master.service';
//validation code
import { ValidationService } from '@app/applications/application-validators/validation.service';
import { ControlValidators } from '@app/applications/application-validators/control-validators.directive'
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
@Component({
    selector: 'app-product-master',
    templateUrl: './product-master-edit.template.html',
    styleUrls: ['../product-master.style.scss'],
})

export class ProductMasterEditComponent implements OnInit, OnDestroy {

    productMaster: ProductMasterComposite;
    editable = true;
    showButton: boolean;
    title: string;
    mode: string;
    productMasterStatus: NameValueDto[];
    productType: NameValueDto[];
    collateralSetFromRefcode: NameValueDto[];
    subCollateralSet: NameValueDto[];
    workflowNameFromGetWorkflow: string[];
    public productMasterForm: FormGroup;
    todayDate: Date;
    productMasterComposite: any;
    // this variable is used to show the matching collateralSet for the given productType
    // this is matching collateralset for given productType
    collateralSetRefcodeForMatchingExistingProduct: NameValueDto[];
    // this is matching workflowName for given productType
    workflowNameForMAtchingExistingProduct: string[];

    constructor(
        private productMasterService: ProductMasterService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private referenceCodeService: ReferenceCodeService,
        private validationService: ValidationService,
        private formBuilder: FormBuilder,
        private _snackbar: MatSnackBar,
        private customDatepipe: DateFormatPipe,
        private igFormService: IgFormService) {
        this.productMasterForm = new FormGroup({
            retrieveProductCode: new FormControl(''),
            encoreProductCode: new FormControl(''),
            productType: new FormControl(''),
            productCode: new FormControl(''),
            productName: new FormControl(''),
            collateralSet: new FormControl(''),
            minAmount: new FormControl(''),
            maxAmount: new FormControl(''),
            minTenure: new FormControl(null),
            maxTenure: new FormControl(null),
            defaultTenure: new FormControl(''),
            tenureUnit: new FormControl(''),
            minInterest: new FormControl(null),
            maxInterest: new FormControl(null),
            status: new FormControl(null),
            defaultInterest: new FormControl(''),
            workflowName: new FormControl(''),
            startDate:new FormControl(''),
            sourcingEndDate:new FormControl(''),
            changeEndDate:new FormControl(''),
            processEndDate:new FormControl(''),
        });
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
                if (data.productMaster === undefined) {
                    this.productMaster = {};
                } else {
                    this.productMaster = data.productMaster.body;
                }
                this.editable = data.editable;
            });
    }

    ngOnInit() {
        this.applyValidatorsToProductMaster();
        this.activatedRoute.queryParams.subscribe(params => {
            let data = JSON.parse(params.data)
            this.title = data.title;
            this.mode = data.mode;
            if (data.mode === 'create') {
                this.productMaster = {};
            } else if (data.mode === 'edit') {
                this.productMaster = data.productMaster;
            } else if (data.mode === 'view') {
                this.productMaster = data.productMaster;
            }
        })
        this.getReferenceCodes();
        this.showButton = false;
    }

    ngOnDestroy(): void { }

    buildProductMasterForm() {
        this.productMasterForm.patchValue({
            encoreProductCode: this.productMaster.encoreProductCode,
            productType: this.productMaster.productType,
            productCode: this.productMaster.productCode,
            productName: this.productMaster.productName,
            minAmount: this.productMaster.minAmount,
            maxAmount: this.productMaster.maxAmount,
            minTenure: this.productMaster.minTenure,
            maxTenure: this.productMaster.maxTenure,
            defaultTenure: this.productMaster.defaultTenure,
            tenureUnit: this.productMaster.tenureUnit,
            minInterest: this.productMaster.minInterest,
            maxInterest: this.productMaster.maxInterest,
            status: this.productMaster.status,
            defaultInterest: this.productMaster.defaultInterest,
            workflowName: this.productMaster.workflowName,
            startDate:this.productMaster.startDate === null || this.productMaster.startDate === undefined ?undefined : new Date(this.productMaster.startDate),
            sourcingEndDate:this.productMaster.sourcingEndDate === null || this.productMaster.sourcingEndDate === undefined ?undefined : new Date(this.productMaster.sourcingEndDate),
            changeEndDate:this.productMaster.changeEndDate === null || this.productMaster.changeEndDate === undefined ?undefined : new Date(this.productMaster.changeEndDate),
            processEndDate:this.productMaster.processEndDate === null || this.productMaster.processEndDate === undefined ?undefined : new Date(this.productMaster.processEndDate),
            collateralSet: this.productMaster.collateralSet
        });

        if (this.mode === 'view') {
            this.productMasterForm.disable();
            this.showButton = true;
            // this is called to get the matching collateralSet and workflow for the selected productType.
            this.getProductTypeDetails(this.productMaster.productType)
        }

        if (this.mode === 'edit') {
            this.productMasterForm.controls.encoreProductCode.disable();
            this.productMasterForm.controls.productType.disable();
            this.productMasterForm.controls.productCode.disable();
            this.productMasterForm.controls.productName.disable();
            // this.productMasterForm.controls.minAmount.disable();
            // this.productMasterForm.controls.maxAmount.disable();
            this.productMasterForm.controls.tenureUnit.disable();
            this.productMasterForm.controls.workflowName.disable();
            // this.productMasterForm.controls.defaultTenure.disable();
            // this.productMasterForm.controls.defaultInterest.disable();
            this.showButton = true;
            // this is called to get the matching collateralSet and workflow for the selected productType.
            this.getProductTypeDetails(this.productMaster.productType)
        }

        if (this.mode === 'create') {
            this.productMasterForm.controls.encoreProductCode.disable();
            this.productMasterForm.controls.tenureUnit.disable();
        }
        this.productMasterForm.controls.maxTenure.setValidators([
            ControlValidators.requiredValidator("Max Tenure is required"),
            ControlValidators.patternValidator("^\\d+", "Max Tenure should be a whole number"),
            ControlValidators.minValueValidator(this.productMasterForm.controls.minTenure.value, false, "Max Tenure value should be greater than Min Tenure", false)]);
        this.productMasterForm.controls.maxTenure.updateValueAndValidity();
    }

    saveProductMaster() {
        this.validationService.markFormGroupTouched(this.productMasterForm)
        if (this.productMasterForm.valid) {
            let productMasterFormFields = this.productMasterForm.value;
            this.productMaster.encoreProductCode = this.productMaster.encoreProductCode;
            this.productMaster.productType = this.productMasterForm.getRawValue().productType;
            this.productMaster.productCode = this.productMasterForm.getRawValue().productCode;
            this.productMaster.productName = this.productMasterForm.getRawValue().productName;
            this.productMaster.collateralSet = productMasterFormFields.collateralSet;
            this.productMaster.workflowName = this.productMasterForm.getRawValue().workflowName;

            this.productMaster.minTenure = productMasterFormFields.minTenure;
            this.productMaster.maxTenure = productMasterFormFields.maxTenure;
            this.productMaster.minAmount = productMasterFormFields.minAmount;
            this.productMaster.maxAmount = productMasterFormFields.maxAmount;
            this.productMaster.minInterest = productMasterFormFields.minInterest;
            this.productMaster.maxInterest = productMasterFormFields.maxInterest;
            this.productMaster.status = productMasterFormFields.status;
            this.productMaster.defaultTenure = productMasterFormFields.defaultTenure;
            this.productMaster.defaultInterest = productMasterFormFields.defaultInterest;
            this.productMaster.tenureUnit = this.productMasterForm.getRawValue().tenureUnit;
            if (this.productMasterForm.controls.startDate.value != undefined && !isNaN(this.productMasterForm.controls.startDate.value.getTime())) {
                if (this.productMasterForm.controls.startDate.valid) {
                  this.productMaster.startDate = this.customDatepipe.transform(this.productMasterForm.controls.startDate.value, 'ISODATE');
                }
              } else {
                this.productMaster.startDate = null
              }
              if (this.productMasterForm.controls.sourcingEndDate.value != undefined && !isNaN(this.productMasterForm.controls.sourcingEndDate.value.getTime())) {
                if (this.productMasterForm.controls.sourcingEndDate.valid) {
                  this.productMaster.sourcingEndDate = this.customDatepipe.transform(this.productMasterForm.controls.sourcingEndDate.value, 'ISODATE');
                }
              } else {
                this.productMaster.sourcingEndDate = null
              }
              if (this.productMasterForm.controls.changeEndDate.value != undefined && !isNaN(this.productMasterForm.controls.changeEndDate.value.getTime())) {
                if (this.productMasterForm.controls.changeEndDate.valid) {
                  this.productMaster.changeEndDate = this.customDatepipe.transform(this.productMasterForm.controls.changeEndDate.value, 'ISODATE');
                }
              } else {
                this.productMaster.changeEndDate = null
              }
              if (this.productMasterForm.controls.processEndDate.value != undefined && !isNaN(this.productMasterForm.controls.processEndDate.value.getTime())) {
                if (this.productMasterForm.controls.processEndDate.valid) {
                  this.productMaster.processEndDate = this.customDatepipe.transform(this.productMasterForm.controls.processEndDate.value, 'ISODATE');
                }
              } else {
                this.productMaster.processEndDate = null
              }
            this.productMasterService.saveProductMaster(this.productMaster).toPromise().then(
                (_success) => {
                    if (this.productMaster.uid) {
                        this._snackbar.open("Product Master updated successfully", "Close", {
                            duration: 2000,
                        });
                        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
                    } else {
                        this._snackbar.open("Product Master created successfully", "Close", {
                            duration: 2000,
                        });
                        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
                    }
                }, (failure) => {
                    this._snackbar.open("Product Master update failed, " + failure, "Close", {
                        duration: 3000,
                    })
                    console.log(failure);
                }
            );
        }
    }

    goToProductMasterListPage() {
        if (this.productMaster.uid) {
            this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        }
    }

    //this function will apply validators to form group
    applyValidatorsToProductMaster() {
        this.validationService.applyValidationRules(this.productMasterForm, "ProductMasterValidation")
            .then((controlValidators) => {
                this.buildProductMasterForm();
            }).catch(() => {
            })
    }

    setMaxTenureValidator() {
        this.productMasterForm.controls.maxTenure.clearValidators();
        this.productMasterForm.controls.maxTenure.setValidators([ControlValidators.requiredValidator("Max Tenure is required"),
        ControlValidators.patternValidator("^\\d+", "Max Tenure should be a whole number"),
        ControlValidators.minValueValidator(this.productMasterForm.controls.minTenure.value, false, "Max Tenure value should be greater than Min Tenure", false)]);
        this.productMasterForm.controls.maxTenure.updateValueAndValidity();
    }

    setMaxInterestValidator() {
        this.productMasterForm.controls.maxInterest.clearValidators();
        this.productMasterForm.controls.maxInterest.setValidators([ControlValidators.requiredValidator("Max Interest is required"),
        ControlValidators.minValueValidator(this.productMasterForm.controls.minInterest.value, false, "Max Interest value should be greater than Min Interest", false)]);
        this.productMasterForm.controls.maxInterest.updateValueAndValidity();
    }

    getReferenceCodes() {
        this.referenceCodeService.getShortRefCodes('product_status').subscribe((response: any) => {
          this.productMasterStatus = response.product_status;
        });

        this.referenceCodeService.getShortRefCodes('loan_type').subscribe((response: any) => {
            this.productType = response.loan_type;
        }); 
        this.referenceCodeService.getWorkflowName().subscribe((response: any) => {
            this.workflowNameFromGetWorkflow = response.body;
        });
        this.referenceCodeService.getShortRefCodes('CollateralSet').subscribe((response: any) => {
            this.collateralSetFromRefcode = response.CollateralSet;
        })
      }

      //this function is called on click of expirydate input
  addEvent(type: string, event: MatDatepickerInputEvent<Date>,control) {
      if (event.value == null) {
        this.productMasterForm.controls[control].patchValue(undefined);
      }
    }

    retrieveProduct(){
        let productType: string;
        productType = this.productMasterForm.value.retrieveProductCode;
        this.productMasterService.reterieve(productType).toPromise().then(
            (_success) => {
            this.productMaster = _success.body;
            this.buildProductMasterForm();
        }, (failure) => {
            this._snackbar.open("Product fetch failed, " + failure, "Close", {
                duration: 3000,
            });
            this.productMaster = new ProductMasterComposite();
            this.buildProductMasterForm()
        })
    }

    getProductTypeDetails(productType){
        this.workflowNameForMAtchingExistingProduct = []
        this.collateralSetRefcodeForMatchingExistingProduct = []

        this.productMasterService.getProductTypeDetails(productType).subscribe(response => {
            this.productMasterComposite = response.body;
            let workflowNames = this.productMasterComposite.workflowNames
            let collateralSet = this.productMasterComposite.collateralSet

            if (workflowNames.length === 0 || collateralSet.length === 0) {
                this._snackbar.open("Product cannot be added for " + productType , "close", {
                    duration: 5000,
                });
                return;
            }
        
            if (workflowNames.length === 1) {
                this.productMasterForm.controls.workflowName.patchValue(workflowNames[0]);
            }
        
            if (collateralSet.length === 1) {
                this.productMasterForm.controls.collateralSet.patchValue(collateralSet[0]);
            }

            this.filterWorkflowOnProductType(workflowNames)
            this.filterCollateralSetOnProductType(collateralSet)
            });
    }

    // for particular productType select we will get collateralSet for the selected productType. We have collateralSet fetched from refcode 
    // and we are taking only matching collateralSet values in filteredCollaterals.
    filterCollateralSetOnProductType(collateralSetInExistingProduct){
        const collateralMap = new Map();
        this.collateralSetFromRefcode.forEach(collateral => {
            collateralMap.set(collateral.code, collateral);
          });

          collateralSetInExistingProduct.forEach(element => {
                if(collateralMap.has(element)){
                this.collateralSetRefcodeForMatchingExistingProduct.push(collateralMap.get(element)) 
            }
        })
    }

    // for particular productType select we will get workflowName for the selected productType. We have already fetched workflow
    // and we are taking only matching workflowName values in filteredWorkflow.
    filterWorkflowOnProductType(workflowNameInExistingProduct){
        const workflowMap = new Map();
        this.workflowNameFromGetWorkflow.forEach(workflow => {
            workflowMap.set(workflow, workflow);
          });

          workflowNameInExistingProduct.forEach(element => {
            if(workflowMap.has(element)){
                this.workflowNameForMAtchingExistingProduct.push(workflowMap.get(element))
            }
        })
    }
}
