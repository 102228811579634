import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialLibModule } from '@app/material-lib.module';
import { LeadsRoutingModule } from './leads-routing.module';
import { LeadsComponent } from './leads.component';
import { AllPipesModule } from '../utils/pipes.module'
import { LeadDetailsComponent } from './leads-details/lead-details.component';
import {GoToPageModule} from'@app/utils/go-to-page/go-to-page.module';
import { LeadQuickViewComponent } from './lead-quick-view/lead-quick-view.component';
import { LeadsViewComponent } from './leads-view/leads-view.component';
@NgModule({
    imports: [
      LeadsRoutingModule,
      BrowserModule,
      HttpClientModule,
      MaterialLibModule,
      FormsModule,
      RouterModule,
      FlexLayoutModule,
      ReactiveFormsModule,
      AllPipesModule,
      GoToPageModule
    ],
    declarations: [LeadsComponent, LeadDetailsComponent, LeadQuickViewComponent, LeadsViewComponent]
})
export class LeadsModule { }