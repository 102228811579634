import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot,  ActivatedRouteSnapshot } from '@angular/router';
import { Observable} from 'rxjs';
import { LeadsService } from './leads.service';
import { LeadDetails } from './leads.model';

@Injectable({
  providedIn: 'root',
})
export class LeadsResolverService implements Resolve<LeadDetails> {
  constructor(private leadsService: LeadsService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    return this.leadsService.getLeadDetails(route.paramMap.get('leadsUid'));
  }
}