import { Component, OnInit ,Input} from '@angular/core';
import { LoanApplication, Person ,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { PersonProfile,ApplicantInformations } from '../../applicant/applicantInformation/applicant-information.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ActivatedRoute ,Router} from '@angular/router';
import { ValidationService } from '../../../application-validators/validation.service';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'eng-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['../../application-details.styles.scss','./score-card.component.scss']
})
export class ScoreCardComponent implements OnInit {
  @Input() application: LoanApplication;
  @Input() menuCode:string;

  allowAccess: Boolean;
  menuItemAllowAccess:boolean;
  
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;


scoreCardDetails:any
  constructor(  private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private confirmationDialogService:ConfirmationDialogService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,private validationService: ValidationService) { 
      this.allowAccess = this.applicationService.allowAccess;
    }

  ngOnInit() {
    this.getMenuItemAccess();
    this.getscoreCardDetails()
  }

  //this function will get either this menu item  is editable or readonly 
getMenuItemAccess(){
  this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if(this.menuItemAccessSpecifier){
        this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
          }
    });
  }

  /*
   Scorecard component, on loading executes this method to retrieve the Score Card Details from server by passing Application UID,
  */
  getscoreCardDetails(){
   this.applicationService.getscoreCardDetails(this.application.uid).subscribe(res=>{
     this.scoreCardDetails = res.body
   })
  }

}
