import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {
    LoanOdAccountsService,
    LoanOdAccountsPopupService,
    LoanOdAccountsComponent,
    LoanOdAccountsDetailComponent,
    LoanOdAccountsEditorComponent,
    LoanOdAccountsDeletePopupComponent,
    LoanOdAccountsDeleteDialogComponent,
    LoanOdAccountsResolve,
    loanOdAccountsRoute,
    loanOdAccountsPopupRoute,
    LoanOdAccountsResolvePagingParams,
    loanOdAccountsErrorMessage,
    LoanOdAccountsAmendNormalInterestComponent,
    LoanOdAccountsAmendLoanAmountComponent,
    LoanOdAccountsSecurityDepositRefundComponent,
    LoanOdAccountsCheckTransactionsComponent,
    LoanOdAccountsAmendPenalInterestComponent,
    LoanOdAccountsSecurityDepositPaymentComponent,
    LoanOdAccountsAmendtenureComponent,
    LoanOdAccountsAmendrepaymentdateComponent,
    LoanOdAccountsAmendUpdateComponent,
    LoanOdAccountsDeleteMoratoriumComponent,
    LoanOdAccountsDeleteMoratoriumExecuteComponent,
    LoanOdAccountsAmendMaturityDateComponent,
    LoanOdAccountsTransactionsMakerCheckerComponent,
    LoanOdAccountsTransactionsMakerCheckerApproveComponent,
    LoanOdAccountsTransactionsMakerCheckerRejectComponent,
    LoanOdAccountsMakerCheckerComponent,
    LoanOdAccountsMakerCheckerApproveComponent,
    LoanOdAccountsMakerCheckerRejectComponent,
    LoanOdAccountsMakerCheckerViewComponent,
    LoanOdAccountsFeePaymentComponent,
    LoanOdAccountsChargeFeeComponent,
    LoanOdScheduledDemandRepaymentComponent,
    LoanOdAccountsPrepaymentComponent,
    LoanOdAccountsAdvanceRepaymentComponent,
    LoanOdAccountsPreclosureComponent,
    LoanOdAccountsDisburseComponent,
    LoanOdAccountsAmendOpenDateComponent,
    LoanOdAccountsAmendOpenDateResolve,
    LoanOdAccountsAmendMoratoriumComponent,
    LoanOdAccountsAmendMoratoriumEntryComponent,
    LoanOdAccountsAmendMoratoriumExecuteComponent,
    LoanOdAccountsCreateMoratoriumComponent,
    LoanOdAccountsRecoveryComponent,
    LoanOdAccountsCompositeRepaymentComponent,
    LoanOdAccountsCompositeRepaymentEntryComponent
} from './';
import { EncoreclientSharedModule } from '../shared/shared.module';
import { LoanOdAccountsProceedComponent } from './loan-od-accounts-proceed.component';
import { LoanOdAccountsBasicEditorComponent } from './loan-od-accounts-basic-editor.component';
import { LoanOdAccountsTermsEditorComponent } from './loan-od-accounts-terms-editor.component';
import { LoanOdAccountsCustomerEditorComponent } from './loan-od-accounts-customer-editor.component';
import { LoanOdAccountUdfEditorComponent } from './loan-od-accounts-udf-editor.component';
import { LoanOdAccountsBulkActionsComponent } from './loan-od-accounts-bulk-actions.component';
import { LoanOdAccountsSetTagsComponent } from './loan-od-accounts-set-tags.component';
import { LoanOdAccountsAssignPostDatedChequesComponent } from './loan-od-accounts-assign-pdc.component';
import { LoanOdAccountsRejectPostDatedChequesComponent } from './loan-od-accounts-reject-pdc.component';
import { LoanOdAccountsDisbursementsComponent } from './loan-od-accounts-disbursements.component';
import { LoanOdAccountsDisburseEntryComponent } from './loan-od-accounts-disburse-entry.component';
import { LoanOdAccountsDisburseEntryResolve } from './loan-od-accounts-disburse-entry.resolve';
import { LoanOdAccountsBasicDetailComponent } from './loan-od-accounts-basic-detail.component';
import { LoanOdAccountsDisburseExecuteComponent } from './loan-od-accounts-disburse-execute.component';
import { LoanOdAccountsDisburseExecuteResolve } from './loan-od-accounts-disburse-execute.resolve';
import { LoanOdAccountsSetStatusComponent } from './loan-od-accounts-set-status.component';
import { LoanOdAccountsDisburseResultComponent } from './loan-od-accounts-disburse-result.component';
import { LoanOdAccountsSetStatusResolve } from './loan-od-accounts-set-status-resolve';
import { LoanOdAccountsSetStatusExecuteComponent } from './loan-od-accounts-set-status-execute.component';
import { LoanOdAccountsChargeFeeEntryComponent } from './loan-od-accounts-charge-fee-entry.component';
import { LoanOdAccountsChargeFeeResolve } from './loan-od-accounts-charge-fee-entry.resolve';
import { LoanOdAccountsChargeFeeExecuteComponent } from './loan-od-accounts-charge-fee-execute.component';
import { LoanOdAccountsChargeFeeResultComponent } from './loan-od-accounts-charge-fee-result.component';
import { LoanOdAccountsReverseEntryComponent } from './loan-od-accounts-reverse-entry.component';
import { LoanOdAccountsReverseResolve } from './loan-od-accounts-reverse.resolve';
import { LoanOdAccountsReverseExecuteComponent } from './loan-od-accounts-reverse-execute.component';
import { LoanOdAccountsReverseResultComponent } from './loan-od-accounts-reverse-result.component';
import { LoanOdScheduledDemandRepaymentEntryComponent } from './loan-od-scheduled-demand-repayment-entry.component';
import { LoanOdAccountsRepaymentResolve } from './loan-od-accounts-repayment-resolve';
import { LoanOdAccountsPrepaymentEntryComponent } from './loan-od-accounts-prepayment-entry-component';
import { LoanOdAccountsAdvanceRepaymentEntryComponent } from './loan-od-accounts-advance-repayment-entry.component';
import { LoanOdAccountsPreclosureEntryComponent } from './loan-od-accounts-preclosure-entry.component';
import { LoanOdAccountsFeePaymentExecuteComponent } from './loan-od-accounts-fee-payment-execute.component';
import { LoanOdAccountsFeePaymentResultComponent } from './loan-od-accounts-fee-payment-result.component';
import { LoanOdAccountsFeePaymentEntryComponent } from './loan-od-accounts-fee-payment-entry.component';
import { LoanOdAccountsAmendAccountComponent } from './loan-od-account-amend-account.component';
import { LoanOdAccountsAmendAccountResolve } from './loan-od-accounts-amend-resolve';
import { LoanOdAccountsSetElectronicMandateComponent } from './loan-od-accounts-set-electronic-mandate.component';
import { LoanOdAccountsAmendEditorComponent } from './loan-od-accounts-amend-editor-component';
import { LoanOdAccountsAmendRepaymentDateEntryResolve } from './loan-od-accounts-amendrepaymentdate-entry.resolve'
import { LoanOdAccountsAmendrepaymentdateEntryComponent } from './loan-od-accounts-amendrepaymentdate-entry.component';
import { LoanOdAccountsAmendtenureEntryResolve } from './loan-od-accounts-amendtenure-entry.resolve';
import { LoanOdAccountsAmendtenureEntryComponent } from './loan-od-accounts-amendtenure-entry.component';
import { LoanOdAccountsAmendinstallmentamountEntryResolve } from './loan-od-accounts-amendinstallmentamount-entry.resolve';
import { LoanOdAccountsAmendinstallmentamountEntryComponent } from './loan-od-accounts-amendinstallmentamount-entry.component';
import { LoanOdAccountsCreatemoratoriumEntryResolve } from './loan-od-accounts-createmoratorium-entry.resolve';
import { LoanOdAccountsCreatemoratoriumEntryComponent } from './loan-od-accounts-createmoratorium-entry.component';
import { LoanOdAccountsSetpostdatedchequesResolve } from './loan-od-accounts-setpostdatedcheques.resolve';
import { LoanOdAccountsAmendguarantorEntryComponent } from './loan-od-accounts-amendguarantor-entry.component';
import { LoanOdAccountsWriteoffEntryComponent } from './loan-od-accounts-writeoff-entry.component';
import { LoanOdAccountsWriteoffEntryResolve } from './loan-od-accounts-writeoff-entry.resolve';
import { LoanOdAccountsAmendpenalinterestEntryComponent } from './loan-od-accounts-amendpenalinterest-entry.component';
import { LoanOdAccountsAmendpenalinterestEntryResolve } from './loan-od-accounts-amendpenalinterest-entry.resolve';
import { LoanOdAccountsTransfertoanotherbranchComponent } from './loan-od-accounts-transfertoanotherbranch-entry.component';
import { LoanOdAccountsTransfertoanotherbranchResolve } from './loan-od-accounts-transfertoanotherbranch.resolve';
import { LoanOdAccountsCreatemoratoriumExecuteComponent } from './loan-od-accounts-createmoratorium-execute.component';
import { LoanOdAccountsCreatemoratoriumResultComponent } from './loan-od-accounts-createmoratorium-result.component';
import { LoanOdAccountsPopupComponent } from './loan-od-accounts-popup.component';
import { LoanOdAccountsAmendguarantorExecuteComponent } from './loan-od-accounts-amendguarantor-execute.component';
import { LoanOdAccountsAmendguarantorResultComponent } from './loan-od-accounts-amendguarantor-result.component';
import { LoanOdAccountsAmendPenalInterestEntryComponent } from './loan-od-accounts-amend-penal-interest-entry.component';
import { LoanOdAccountsAmendPenalInterestExecuteComponent } from './loan-od-accounts-amend-penal-interest-execute.component';
import { LoanOdAccountsAmendPenalInterestResultComponent } from './loan-od-accounts-amend-penal-interest-result.component';
import { LoanOdAccountsTransfertoanotherbranchExecuteComponent } from './loan-od-accounts-transfertoanotherbranch-execute.component';
import { LoanOdAccountsDeleteMoratoriumEntryComponent } from './loan-od-accounts-delete-moratorium-entry.component';
import { LoanOdAccountsSecurityDepositPaymentEntryComponent } from './loan-od-accounts-security-deposit-payment-entry.component';
import { LoanOdAccountsScheduledDisbursementsComponent } from './loan-od-accounts-scheduled-disbursements.component';
import { LoanOdAccountsPostDatedChequeComponent } from './loan-od-accounts-post-dated-cheque.component';
import { LoanOdAccountsPdcDeleteComponent } from './loan-od-accounts-pdc-delete.component';

import {RoleEditorComponent } from '../admin/role/role-edit/role-editor.component';
import { LoanOdAccountsSecurityDepositPaymentExecuteComponent } from './loan-od-accounts-security-deposit-payment-execute.component';
import { LoanOdAccountsPdcSaveComponent } from './loan-od-accounts-pdc-save.component';
import { LoanOdAccountsWriteoffExecuteComponent } from './loan-od-accounts-writeoff-execute.component';
import { LoanOdAccountsWriteoffrecoveryEntryComponent } from './loan-od-accounts-writeoffrecovery-entry.component';
import { LoanOdAccountsWriteoffrecoveryExecuteComponent } from './loan-od-accounts-writeoffrecovery-execute.component';
import { LoanOdAccountsWriteoffrecoveryEntryResolve } from './loan-od-accounts-writeoffrecovery-entry.resolve';
import { LoanOdAccountsComputePayOffAmountComponent } from './loan-od-accounts-compute-payOff-Amount.component';
import { LoanOdAccountsComputePayOffAmountResolve } from './loan-od-accounts-compute-payOff-Amount.resolve';
import { LoanOdAccountsCollectionsComponent } from './loan-od-accounts-collections.component';
import { LoanOdAccountsCollectionsResolve } from './loan-od-accounts-collections.resolve';
import { LoanOdAccountsCollectionsDownloadComponent } from './loan-od-accounts-collections-download.component';
import {LoanOdAccountsCollectionsUploadReverseComponent} from './loan-od-accounts-collections-upload-reverse.component';
import { LoanOdAccountsCollectionsUploadComponent } from './loan-od-accounts-collections-upload.component';
import { LoanOdAccountsElectronicMandateComponent } from './loan-od-accounts-electronic-mandate.component';
import { LoanOdAccountsElectronicMandateResolve } from './loan-od-accounts-electronic-mandate.resolve';
import { LoanOdAccountsElectronicMandateShowComponent } from './loan-od-accounts-electronic-mandate-show.component';
import { LoanOdAccountsElectronicMandateShowResolve } from './loan-od-accounts-electronic-mandate-show.resolve';
import { LoanOdAccountsElectronicMandateDownloadComponent } from './loan-od-accounts-electronic-mandate-download.component';
import { LoanOdAccountsCollectionsUploadPdcComponent } from './upload-pdc-collection.component';
import { LoanOdAccountsTransactionsMakerCheckerShowComponent } from './LoanOdAccountsTransactionsMakerCheckerShowComponent';
import { LoanOdAccountsInsurancePolicyListComponent } from './loan-od-accounts-insurancepolicylist.component';
import { LoanOdAccountsInsurancePolicyViewComponent } from './loan-od-accounts-insurance-policy-view';
import { LoanOdAccountsInsurancePolicyConfirmComponent } from './loan-od-accounts-insurance-policy-confirm';
import { LoanOdAccountsInsurancePolicyProceedComponent } from './loan-od-accounts-insurance-policy-proceed';
import { BrowserModule } from '@angular/platform-browser';
import {IgCoreModule} from '../../../projects/ig-core/src/lib/ig-core.module';
import {AuthInterceptor} from '../../../projects/ig-core/src/lib/interceptors/auth.interceptor'
import { LoanOdAccountsPreclosureResultComponent } from './loan-od-accounts-preclosure-result.component';
import { LoanOdAccountsPrepaymentExecuteComponent } from './loan-od-accounts-prepayment-execute.component';
import { LoanOdAccountsPreclosureExecuteComponent } from './loan-od-accounts-preclosure-execute.component';
import { LoanOdAccountsPrepaymentResultComponent } from './loan-od-accounts-prepayment-result.component';
import { LoanOdAccountsCompositeRepaymentResultComponent } from './loan-od-accounts-composite-repayment-result.component';
import { LoanOdAccountsCompositeRepaymentExecuteComponent } from './loan-od-accounts-composite-repayment-execute.component';
import { LoanOdAccountsAdvanceRepaymentResultComponent } from './loan-od-accounts-advance-repayment-result.component';
import { LoanOdAccountsAdvanceRepaymentExecuteComponent } from './loan-od-accounts-advance-repayment-execute.component';
const ENTITY_STATES = [
    loanOdAccountsRoute,
    loanOdAccountsPopupRoute,
    loanOdAccountsErrorMessage,
];

@NgModule({
    imports: [
        EncoreclientSharedModule,
        RouterModule.forRoot(ENTITY_STATES, { useHash: true })
    ],
    declarations: [
        LoanOdAccountsComponent,
        LoanOdAccountsDetailComponent,
        LoanOdAccountsEditorComponent,
        // LoanOdAccountsDeleteDialogComponent,
        LoanOdAccountsDeletePopupComponent,
        LoanOdAccountsProceedComponent,
        // LoanOdAccountsBasicEditorComponent,
        // LoanOdAccountsTermsEditorComponent,
        // LoanOdAccountsCustomerEditorComponent,
        // LoanOdAccountUdfEditorComponent,
        LoanOdAccountsBulkActionsComponent,
        // LoanOdAccountsSetTagsComponent,
        // LoanOdAccountsAssignPostDatedChequesComponent,
        // LoanOdAccountsRejectPostDatedChequesComponent,
        LoanOdAccountsReverseEntryComponent,
        LoanOdAccountsDisbursementsComponent,
        LoanOdAccountsDisburseEntryComponent,
        // LoanOdAccountsBasicDetailComponent,
        LoanOdAccountsDisburseExecuteComponent,
        LoanOdAccountsSetStatusComponent,
        LoanOdAccountsDisburseResultComponent,
        LoanOdAccountsSetStatusExecuteComponent,
        LoanOdAccountsChargeFeeEntryComponent,
        LoanOdAccountsChargeFeeExecuteComponent,
        LoanOdAccountsChargeFeeResultComponent,
        LoanOdAccountsReverseExecuteComponent,
        LoanOdAccountsReverseResultComponent,
        LoanOdScheduledDemandRepaymentEntryComponent,
        LoanOdAccountsPrepaymentEntryComponent,
        LoanOdAccountsPrepaymentResultComponent,
        LoanOdAccountsAdvanceRepaymentEntryComponent,
        LoanOdAccountsAdvanceRepaymentExecuteComponent,
        LoanOdAccountsAdvanceRepaymentResultComponent,
        LoanOdAccountsPreclosureEntryComponent,
        LoanOdAccountsPreclosureExecuteComponent,
        LoanOdAccountsPrepaymentExecuteComponent,
        LoanOdAccountsPreclosureResultComponent,
        LoanOdAccountsFeePaymentEntryComponent,
        LoanOdAccountsFeePaymentExecuteComponent,
        LoanOdAccountsFeePaymentResultComponent,
        LoanOdAccountsAmendAccountComponent,
        // LoanOdAccountsSetElectronicMandateComponent,
        // LoanOdAccountsAmendEditorComponent,
        // LoanOdAccountsAmendrepaymentdateEntryComponent,
        // LoanOdAccountsAmendtenureEntryComponent,
        // LoanOdAccountsAmendinstallmentamountEntryComponent,
        // LoanOdAccountsCreatemoratoriumEntryComponent,
        // LoanOdAccountsAmendguarantorEntryComponent,
        // LoanOdAccountsAmendguarantorExecuteComponent,
        // LoanOdAccountsAmendguarantorResultComponent,
        // LoanOdAccountsWriteoffEntryComponent,
        // LoanOdAccountsAmendpenalinterestEntryComponent,
        // LoanOdAccountsTransfertoanotherbranchComponent,
        // LoanOdAccountsInsurancePolicyListComponent,
        // LoanOdAccountsCreatemoratoriumExecuteComponent,
        // LoanOdAccountsCreatemoratoriumResultComponent,
        // LoanOdAccountsAdvanceRepaymentEntryComponent,
        // LoanOdAccountsAmendPenalInterestEntryComponent,
        // LoanOdAccountsAmendPenalInterestExecuteComponent,
        // LoanOdAccountsAmendPenalInterestResultComponent,
        // LoanOdAccountsAmendNormalInterestComponent,
        // LoanOdAccountsAmendLoanAmountComponent,
        // LoanOdAccountsSecurityDepositRefundComponent,
        // LoanOdAccountsCheckTransactionsComponent,
        // LoanOdAccountsTransfertoanotherbranchExecuteComponent,
        // LoanOdAccountsDeleteMoratoriumEntryComponent,
        // LoanOdAccountsSecurityDepositPaymentEntryComponent,
        // LoanOdAccountsScheduledDisbursementsComponent,
        // LoanOdAccountsPostDatedChequeComponent,
        // LoanOdAccountsPdcDeleteComponent,
        // LoanOdAccountsSecurityDepositPaymentExecuteComponent,
        // LoanOdAccountsPdcSaveComponent,
        // LoanOdAccountsWriteoffExecuteComponent,
        // LoanOdAccountsWriteoffrecoveryEntryComponent,
        // LoanOdAccountsWriteoffrecoveryExecuteComponent,
        // LoanOdAccountsAmendPenalInterestComponent,
        // LoanOdAccountsSecurityDepositPaymentComponent,
        // LoanOdAccountsAmendtenureComponent,
        LoanOdAccountsAmendrepaymentdateComponent,
        // LoanOdAccountsAmendUpdateComponent,
        // LoanOdAccountsDeleteMoratoriumComponent,
        // LoanOdAccountsDeleteMoratoriumExecuteComponent,
        // LoanOdAccountsAmendMaturityDateComponent,
        // LoanOdAccountsTransactionsMakerCheckerComponent,
        // LoanOdAccountsTransactionsMakerCheckerApproveComponent,
        // LoanOdAccountsTransactionsMakerCheckerRejectComponent,
        // LoanOdAccountsMakerCheckerComponent,
        // LoanOdAccountsMakerCheckerApproveComponent,
        // LoanOdAccountsMakerCheckerRejectComponent,
        // LoanOdAccountsTransactionsMakerCheckerShowComponent,
        // LoanOdAccountsMakerCheckerViewComponent,
        LoanOdAccountsFeePaymentComponent,
        LoanOdAccountsChargeFeeComponent,
        LoanOdScheduledDemandRepaymentComponent,
        LoanOdAccountsPrepaymentComponent,
        LoanOdAccountsAdvanceRepaymentComponent,
        LoanOdAccountsPreclosureComponent,
        LoanOdAccountsDisburseComponent,
        // LoanOdAccountsAmendOpenDateComponent,
        // LoanOdAccountsAmendMoratoriumComponent,
        // LoanOdAccountsAmendMoratoriumEntryComponent,
        // LoanOdAccountsAmendMoratoriumExecuteComponent,
        // LoanOdAccountsCreateMoratoriumComponent,
        // LoanOdAccountsRecoveryComponent,
        LoanOdAccountsCompositeRepaymentComponent,
        LoanOdAccountsCompositeRepaymentEntryComponent,
        LoanOdAccountsCompositeRepaymentExecuteComponent,
        LoanOdAccountsCompositeRepaymentResultComponent,
        // LoanOdAccountsComputePayOffAmountComponent,
        // LoanOdAccountsCollectionsComponent,
        // LoanOdAccountsCollectionsDownloadComponent,
        // LoanOdAccountsCollectionsUploadComponent,
        // LoanOdAccountsCollectionsUploadReverseComponent,
        // LoanOdAccountsElectronicMandateComponent,
        // LoanOdAccountsElectronicMandateShowComponent,
        // LoanOdAccountsElectronicMandateDownloadComponent,
        // LoanOdAccountsCollectionsUploadPdcComponent,
        // LoanOdAccountsInsurancePolicyViewComponent,
        // LoanOdAccountsInsurancePolicyConfirmComponent,
        // LoanOdAccountsInsurancePolicyProceedComponent,
        // LoanOdAccountsInsurancePolicyListComponent,
        
    ],
    providers: [
        //FileService,
        LoanOdAccountsService,
        LoanOdAccountsPopupService,
        LoanOdAccountsResolvePagingParams,
        LoanOdAccountsResolve,
        LoanOdAccountsProceedComponent,
        LoanOdAccountsBulkActionsComponent,
        LoanOdAccountsDisburseEntryResolve,
        LoanOdAccountsDisburseExecuteResolve,
        LoanOdAccountsSetStatusResolve,
        LoanOdAccountsChargeFeeResolve,
        LoanOdAccountsReverseResolve,
        LoanOdAccountsAmendRepaymentDateEntryResolve,
        LoanOdAccountsRepaymentResolve,
        LoanOdAccountsAmendAccountResolve,
        LoanOdAccountsAmendtenureEntryResolve,
        LoanOdAccountsAmendinstallmentamountEntryResolve,
        LoanOdAccountsCreatemoratoriumEntryResolve,
        LoanOdAccountsSetpostdatedchequesResolve,
        LoanOdAccountsWriteoffEntryResolve,
        LoanOdAccountsAmendpenalinterestEntryResolve,
        LoanOdAccountsTransfertoanotherbranchResolve,
        LoanOdAccountsPopupComponent,
        LoanOdAccountsBasicEditorComponent,
        LoanOdAccountsTermsEditorComponent,
        LoanOdAccountsCustomerEditorComponent,
        LoanOdAccountUdfEditorComponent,
        LoanOdAccountsProceedComponent,
        RoleEditorComponent,
        LoanOdAccountsAmendOpenDateResolve,
        LoanOdAccountsWriteoffrecoveryEntryResolve,
        LoanOdAccountsComputePayOffAmountResolve,
        LoanOdAccountsCollectionsResolve,
        LoanOdAccountsElectronicMandateResolve,
        LoanOdAccountsElectronicMandateShowResolve,
        LoanOdAccountsInsurancePolicyViewComponent,
        LoanOdAccountsInsurancePolicyConfirmComponent,
        LoanOdAccountsInsurancePolicyProceedComponent,
        LoanOdAccountsInsurancePolicyListComponent,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
          },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoanOdAccountsModule { }
