import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { Person, LoanApplication, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ApplicantReferencesComposite } from './applicant-reference.model';
import { MatSnackBar } from '@angular/material';
import { ValidationService } from '../../../application-validators/validation.service'
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'eng-applicant-reference',
  templateUrl: 'applicant-reference.template.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class ApplicantReferenceComponent implements OnInit, OnChanges {

  @Input()
  application: LoanApplication;

  @Input()
  applicant: Person;

  @Input()
  menuCode: string;

  applicantReferences: ApplicantReferencesComposite[];
  applicantReferenceForm: FormGroup;
  applicantReferenceTypes: NameValueDto[];
  selectedReference: ApplicantReferencesComposite;
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  isFormDisabled: boolean;
  showForm: boolean;
  allowAccess: Boolean;
  menuItemAllowAccess: boolean;

  constructor(private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService,
    private validationService: ValidationService) {
    this.applicantReferenceForm = this.formBuilder.group({
      referenceType: '',
      referenceName: '',
      firstLine: '',
      secondLine: '',
      thirdLine: '',
      mobileNumber: '',
      landlineNumber: ''
    });
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.getRefCodes();
    this.applyValidatorsToApplicantReference();
    this.getMenuItemAccess();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.applicant) {
      this.isFormDisabled = true;
      this.fetchapplicantReference();
    }
  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('person_reference_type').subscribe((response: any) => {
      this.applicantReferenceTypes = response.person_reference_type;
    });
  }

  fetchapplicantReference() {
    this.showForm = false;
    this.applicationService.getApplicantReference(this.application.uid,
      this.applicant.uid).subscribe((response: any) => {
        if (response) {
          this.applicantReferences = response.body.referenceBasic;
          this.applicantReferences.forEach((element, index) => {
            this.applicantReferences[index]["deleteBtnDisabled"] = true;
          });
          this.showForm = (this.applicantReferences && this.applicantReferences.length > 0);
          this.buildApplicantReferenceForm();
        }
      });
  }

  addNewReference() {
    this.showForm = true;
      this.changeSelectedReference(new ApplicantReferencesComposite(), undefined, "new");
      this.isFormDisabled = false;
  }

  changeSelectedReference(applicantReference?: ApplicantReferencesComposite, index?: number, from?: string) {
    this.showForm = true;
    this.applicantReferences.forEach((element, i) => {
      this.applicantReferences[i].deleteBtnDisabled = true;
    });
    if (from != "new") {
      this.applicantReferences[index].deleteBtnDisabled = false;
    }
    this.buildApplicantReferenceForm(applicantReference)
  }

  buildApplicantReferenceForm(reference?: ApplicantReferencesComposite) {
    if (this.showForm) {
      if (!reference){
        reference = this.applicantReferences[0];
        this.applicantReferences[0].deleteBtnDisabled = false;
      }
      this.applicantReferenceForm.patchValue({
        referenceType: reference.relationshipType,
        referenceName: reference.name,
        firstLine: reference.addressLine1,
        secondLine: reference.addressLine2,
        thirdLine: reference.addressLine3,
        mobileNumber: reference.primaryPhone,
        landlineNumber: reference.landlinePhone
      });
      this.selectedReference = reference;
    }
  }
 
  saveReference() {
    this.validationService.markFormGroupTouched(this.applicantReferenceForm)
    if (!this.isFormDisabled && this.applicantReferenceForm.valid) {
      let personUid = this.selectedReference.personUid ? this.selectedReference.personUid : "0";
      let applicantReferenceFormFields = this.applicantReferenceForm.value;
      this.selectedReference.relationshipType = applicantReferenceFormFields.referenceType;
      this.selectedReference.name = applicantReferenceFormFields.referenceName;
      this.selectedReference.addressLine1 = applicantReferenceFormFields.firstLine;
      this.selectedReference.addressLine2 = applicantReferenceFormFields.secondLine;
      this.selectedReference.addressLine3 = applicantReferenceFormFields.thirdLine;
      this.selectedReference.primaryPhone = applicantReferenceFormFields.mobileNumber;
      this.selectedReference.landlinePhone = applicantReferenceFormFields.landlineNumber;
      this.applicationService.saveApplicantReference(this.application.uid,
        this.applicant.uid, personUid, this.selectedReference).toPromise().then(
          (_success) => {
            this._snackbar.open("Applicant reference updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.fetchapplicantReference();
          }, (failure) => {
            let errormesg = []
            errormesg.push("Applicant reference update failed.")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
          });
    }
  }


  enableDisableForm() {
    this.isFormDisabled = false;
  }

  cancelForm() {
    this.applicantReferences.forEach((element, i) => {
      this.applicantReferences[i].deleteBtnDisabled = true;
    });
    if (this.applicantReferences && this.applicantReferences.length > 0) {
      this.applicantReferences[0].deleteBtnDisabled = false;
      this.buildApplicantReferenceForm(this.applicantReferences[0]);
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
  }
  //this function will apply validators to form group
  applyValidatorsToApplicantReference() {
    this.validationService.applyValidationRules(this.applicantReferenceForm, "ApplicantReference").then((controlValidators) => {
    }).catch(() => {
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

   //this function will be called on click of delete icon on Reference
   deleteReference(selectedReference) {
    this.applicationService.referenceDelete(this.application.uid, selectedReference.partyLinkUid).subscribe((response) => {
      if(response.body.status == "success") {
      this._snackbar.open("Applicant reference deleted successfully", "Close", {
        duration: 2000,
      });
      this.fetchapplicantReference();
    } else {
      let errormesg = []
      errormesg.push("Applicant reference deleted failed")
      errormesg.push(response.body.message);
      this.applicationService.displayErrorMessages(errormesg);
    }}, (failure) => {
      let errormesg = []
      errormesg.push("Applicant reference deleted failed")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this Applicant reference?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteReference(this.selectedReference)
      }
    })
  }

}
