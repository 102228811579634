import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { IgValidationService } from './ignition/igvalidation.service';
import { IgInputComponent } from './ignition/ig-input.component';
import {IgFormHelperService} from './ignition/ig-form-helper.service';
import { AadharValidator, IgMinValidator, IgMaxValidator, MinFloatValidator, MaxFloatValidator } from './validators/customValidators.directive';
import { ValidationErrorComponent } from './validators/validation-error.component';
import { DateConverterService } from './date/date-converter.service';
import { ApplicationDateService } from './date/application-date.service';
import { DefaultValueNullDirective } from './validators/default-value-null.directive';
import { MoneyWithCurrencyPipe } from './moneyWithCurrency.pipe';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { DefaultCurrencySymbolService } from './defaultCurrencySymbol.service';
import {
    EncoreclientSharedLibsModule,
    EncoreclientSharedCommonModule,
    CSRFService,
    AuthService,
    AuthServerProvider,
    AccountService,
    UserService,
    StateStorageService,
    LoginService,
    LoginModalService,
    Principal,
    HasAnyAuthorityDirective,
    JhiLoginModalComponent,
    EncoreclientLoginWrapperComponent
} from './';
import { EncoreclientLogoutComponent } from './logout/logout.component';
import { CookieOptions } from 'angular2-cookie';

@NgModule({
    imports: [
        EncoreclientSharedLibsModule,
        EncoreclientSharedCommonModule
    ],
    declarations: [
        JhiLoginModalComponent,
        EncoreclientLoginWrapperComponent,
        HasAnyAuthorityDirective,
        IgInputComponent,
        ValidationErrorComponent,
        AadharValidator,
        IgMinValidator,
        IgMaxValidator,
        DefaultValueNullDirective,
        MinFloatValidator,
        MaxFloatValidator,
        MoneyWithCurrencyPipe,
        JhiLoginModalComponent,
        EncoreclientLogoutComponent
    ],
    providers: [
        CookieService,
        LoginService,
        LoginModalService,
        AccountService,
        StateStorageService,
        Principal,
        CSRFService,
        IgValidationService,
        IgFormHelperService,
        AuthServerProvider,
        AuthService,
        UserService,
        DatePipe,
        DateConverterService,
        ApplicationDateService,
        DefaultCurrencySymbolService,
        MoneyWithCurrencyPipe
    ],
    entryComponents: [JhiLoginModalComponent],
    exports: [
        EncoreclientSharedCommonModule,
        JhiLoginModalComponent,
        HasAnyAuthorityDirective,
        DatePipe,
        IgInputComponent,
        ValidationErrorComponent,
        AadharValidator,
        IgMinValidator,
        IgMaxValidator,
        DefaultValueNullDirective,
        MinFloatValidator,
        MaxFloatValidator,
        MoneyWithCurrencyPipe,
        JhiLoginModalComponent,
        EncoreclientLogoutComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class EncoreclientSharedModule {}
