import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, FormArray } from "@angular/forms";
import { ReferenceCodeService } from "@app/admin/reference-code/reference-code.service";
import { ApplicationService } from "@app/applications/application.service";
import { MatSnackBar } from "@angular/material";
import {
  LoanApplication,
  ApplicationAccessSpecifier,
} from "@app/applications/applications.model";
//validation code
import { ValidationService } from "../../../application-validators/validation.service";
import {
  IncomeExpenseDetail,
  IncomeProfile,
  IncomeProfileResponse,
  YieldDetail,
} from "./income.model";
import { BusinessEntity } from "../businessInformation/business-information.model";
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: "eng-income-profile",
  templateUrl: "income-profile.template.html",
  styleUrls: [
    "./income-profile.style.scss",
    "../../application-details.styles.scss",
    "../../../../applications/application-details/application-details.styles.scss",
  ],
})
export class IncomeProfileComponent implements OnInit {
  @Input()
  application: LoanApplication;

  @Input()
  menuCode: string;
  
  @Input()
  business: BusinessEntity;

  incomeProfile: IncomeProfile = {};
  incomeProfilePristine: IncomeProfile = {};

  
  incomeExpenseDetailList: IncomeExpenseDetail[] = [];
  yieldList: YieldDetail[] = [];
  incomeProfileForm: FormGroup;
  yieldForm: FormGroup;

  isFormDisabled: boolean;
  buttonStatus: boolean;
  showProcessButton: boolean;
  allowAccess: Boolean;
  menuItemAllowAccess: boolean;
  incomeProfileCategories: NameValueDto[]
  incomeProfileResponse: IncomeProfileResponse;
  yieldResponse: YieldDetail;
  userAgent: string = "webapp";
  cfaFinalized:boolean;


  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(
    private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private validationService: ValidationService
  ) {
    this.allowAccess = this.applicationService.allowAccess;
    this.incomeProfileForm = this.formBuilder.group({
      numberOfCattle: '',
      numberOfMilkingCattle: '',
      yieldPerCattle: '',
      pricePerLitre: '',
      ownLandForFodder: ''
    });
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit(): void {
    this.isFormDisabled = true;
    this.buttonStatus =true;
    this.showProcessButton = false;
    this.incomeProfile ={uid:'0', type:'Dairy', party:'business', partyUid: this.business.uid, contextUid:this.application.uid};
    this.resetIncomeProfile();
    this.getRefCodes();
    this.getIncomeProfile(this.application.uid, this.incomeProfile.party, this.business.uid, this.incomeProfile.uid);
    this.getCfaStatus();
    this.getMenuItemAccess();
  
  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('income_profile_category_1').subscribe((response: any) => {
      this.incomeProfileCategories = response.income_profile_category_1;
    });

  }

  getIncomeProfile(applicationUid: string, party:string, partyUid:string, incomeProfileUid:string) {
    this.applicationService.getIncomeProfile(applicationUid,party,partyUid,incomeProfileUid)
      .subscribe((response: HttpResponse<IncomeProfile[]>) => {
        if(response.body !== null && response.body.length > 0 && response.body[0].uid !== '0') {
          this.incomeProfile = response.body[0];
          this.incomeProfile.party = 'business';
          this.resetIncomeProfile();
          this.calculate();
        }else{
          this.getTemplate()
        } 
        
      });
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

  calculate(){
    this.applicationService.calculate( this.application.uid, this.incomeProfile.uid , this.incomeProfile.party, this.business.uid, this.userAgent, this.incomeProfile )
    .toPromise().then(
      (_success) => {
      this.incomeProfileResponse = _success.body;
      this.yieldList = this.incomeProfileResponse.incomeProfileCalculation.yieldDetail;
      this.buildIncomeProfileForm()
      this.buildYieldForm()
  },
  (failure) => {
    this._snackbar.open(failure.message, "Close", {
    duration: 8000,
  })
  })
}

getTemplate(){
  this.applicationService.getTemplate(this.application.uid, this.incomeProfile.party, this.business.uid, this.incomeProfile.type )
  .toPromise().then(
    (_success) => {
    this.incomeProfileResponse = _success.body;
    this.yieldList = this.incomeProfileResponse.incomeProfileCalculation.yieldDetail;
    this.buildIncomeProfileForm()
    this.buildYieldForm()
},
(failure) => {
  this._snackbar.open(failure.message, "Close", {
  duration: 8000,
})
})
}

  buildYieldForm(){
    this.yieldForm = this.formBuilder.group({
      yieldList: this.formBuilder.array([])
    })
    const array = this.yieldForm.controls.yieldList as FormArray;
    this.yieldList.forEach(element => {
      const group = new FormGroup({
        name: new FormControl(element.name),
        val1: new FormControl(element.val1),
        val2: new FormControl(element.val2),
      });
      array.push(group);
    });
  }

  buildIncomeProfileForm() {
    this.incomeProfileForm.patchValue({
      numberOfCattle: this.incomeProfile.resourceCount1,
      numberOfMilkingCattle: this.incomeProfile.resourceCount2,
      yieldPerCattle: this.incomeProfile.yield1,
      pricePerLitre: this.incomeProfile.salePrice1,
      ownLandForFodder: this.incomeProfile.category1
    });
    this.validationService.applyValidationRules(this.incomeProfileForm, "BusinessInformation").then((controlValidators) => {
    }).catch(() => {

    })
 
  }
  enableDisableForm() {
    this.isFormDisabled = false;
    this.buttonStatus =false;
    this.showProcessButton = false;
    return this.showProcessButton = (this.incomeProfile.uid === '0');
}

  saveIncomeInformation() {
    this.validationService.markFormGroupTouched(this.incomeProfileForm)
    if(!this.isFormDisabled && this.incomeProfileForm.valid){
    this.updateIncomeProfile();
    // for saving the income profile uid should be auto genetated and need to send null 
    if (this.incomeProfile.uid === '0'){
      this.incomeProfile.uid = null
    }
    this.applicationService.saveIncomeProfile(this.application.uid, 
      this.incomeProfile.party, this.business.uid, this.incomeProfile ).toPromise().then(
        (_success) => {
          this._snackbar.open("Income profile saved. Click the Process button to apply this income/expense in CFA.", "Close", {
            duration: 8000,
          })
          this.incomeProfile = _success.body.incomeProfile;
          this.incomeProfileResponse = _success.body;
          this.yieldList = _success.body.incomeProfileCalculation.yieldDetail;
          this.buildIncomeProfileForm()
          this.buildYieldForm();
          this.incomeProfile.party = 'business';
          this.resetIncomeProfile();
          this.showProcessButton = false;
        },(failure) => {
          this._snackbar.open("Income profile save failed, " + failure.message, "Close", {
          duration: 8000,
        })
        console.log(failure);
      })
    }
    else{
      this._snackbar.open("Please fill all mandatory fields", "Close", {
        duration: 3000,
      })
    }
  }


  cancelForm() {
    this.incomeProfile = Object.assign({} ,this.incomeProfilePristine);
    this.calculate();
  }

  //this function is used to get the last value which is stored in database to display when we click on cancel
  //and used to keep the latest value when we click on save
  resetIncomeProfile(){
    this.incomeProfilePristine = Object.assign({} ,this.incomeProfile);
  }


  // this function will update the entered values from the input form
  updateIncomeProfile() {
    this.validationService.markFormGroupTouched(this.incomeProfileForm);
    let incomeProfileFormFields = this.incomeProfileForm.controls;

    this.incomeProfile.category1 = incomeProfileFormFields.ownLandForFodder.value;
    this.incomeProfile.resourceCount1 = incomeProfileFormFields.numberOfCattle.value;;
    this.incomeProfile.resourceCount2 = incomeProfileFormFields.numberOfMilkingCattle.value;
    this.incomeProfile.yield1 = incomeProfileFormFields.yieldPerCattle.value;
    this.incomeProfile.salePrice1 = incomeProfileFormFields.pricePerLitre.value;
    let yieldFormArray = this.yieldForm.get('yieldList')['controls'];
    yieldFormArray.forEach((yieldFormGroup, index) => {
      switch(index) {
        case 0: {
          this.incomeProfile.m1c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 1: {
          this.incomeProfile.m2c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 2: {
          this.incomeProfile.m3c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 3: {
          this.incomeProfile.m4c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 4: {
          this.incomeProfile.m5c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 5: {
          this.incomeProfile.m6c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 6: {
          this.incomeProfile.m7c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 7: {
          this.incomeProfile.m8c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 8: {
          this.incomeProfile.m9c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 9: {
          this.incomeProfile.m10c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 10: {
          this.incomeProfile.m11c1Value = yieldFormGroup.controls.val1.value
          break
        }
        case 11: {
          this.incomeProfile.m12c1Value = yieldFormGroup.controls.val1.value
          break
        }
      }
    })
}

onCalculateClicked(){
  if(this.incomeProfileForm.valid){
  this.updateIncomeProfile();
  this.calculate();
}
}

onProcessClicked(){  
  if(this.incomeProfileForm.valid){
  this.updateIncomeProfile();
  this.applicationService.processIncomeProfile(this.application.uid, 
    this.incomeProfile.party, this.business.uid, this.incomeProfile.uid, this.userAgent,  this.incomeProfile ).toPromise().then(
      (_success) => {
        this._snackbar.open("Income profile processed successfully", "Close", {
          duration: 4000,
        });
        this.incomeProfile = _success.body.incomeProfile;
        this.incomeProfileResponse = _success.body;
        this.yieldList = _success.body.incomeProfileCalculation.yieldDetail;
        this.buildIncomeProfileForm()
        this.buildYieldForm();
        this.incomeProfile.party = 'business';
        this.resetIncomeProfile();
      },
      (failure) => {
        this._snackbar.open("Income process failed, " + failure.message, "Close", {
        duration: 8000,
      })
      console.log(failure);
    })
  }
}

    //this function will call a api to get CFA Finalized or not status
  getCfaStatus(){
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response =>{
      this.cfaFinalized=response.body.cfaFinalized
      if(this.cfaFinalized){
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close" ,{
          duration:5000,
        });
      }
    })
  }

  }

  
