import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { IgLayoutService } from '../ig-layout.service';
import { MenuItem } from '../../../../projects/multi-level-menu/src/lib/menu-item';
import { delay, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PrincipalService } from '@ig-core/authentication/principal.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'ig-layout-two-column',
  templateUrl: './two-column.component.html',
  styleUrls: ['./two-column.component.css'],
  animations: [
    trigger('openCloseSidenav', [
      state('open', style({width: '210px'})),
      state('closed', style({width: '65px'})),
      transition('open <=> closed',
      animate('0.2s')
      ),
    ]),
    trigger('openCloseSidenavContent', [
      state('hide', style({'margin-left': '0px'})),
      state('open', style({'margin-left': '210px'})),
      state('closed', style({'margin-left': '65px'})),
      transition('open <=> closed',
      animate('0.2s')
      ),
    ])
  ]
})
export class TwoColumnComponent implements OnInit, OnDestroy {
  
  loggedInUser: any;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  @ViewChild('outerSideNav') public outerSideNav: MatSidenav;
  public showOuterSideNav = false;
  public isOuterSideNavOpen = true;

  menuItems: MenuItem[] = [
    {
      displayName: 'HOME',
      iconName: 'home',
      route: 'home',
    },
    {
      displayName: 'LEADS',
      iconName: 'storefront',
      route: 'leads'
    },
    {
      displayName: 'APPLICATIONS',
      iconName: 'source',
      route: 'applications'
    },{
      displayName: 'ACCOUNTS',
      iconName: 'business',
      route: 'loan-od-accounts'
    },
    {
      displayName: 'TASKS',
      iconName: 'event_available',
      route: 'home'
    },
    {
      displayName: 'ZONES',
      iconName: 'grain',
      route: 'zones'
    },
    {
      displayName: 'ADMIN',
      iconName: 'admin_panel_settings',
      route: 'admin'
    },
    {
      displayName: 'DASHBOARD',
      iconName: 'dashboard',
      route: 'dashboard',
    },
    {
      displayName: 'DATA PORT',
      iconName: 'dataset',
      route:'dataport',
    },
  ];

  showHead: boolean = false;
  constructor(changeDetectorRef: ChangeDetectorRef,
      private igLayoutService: IgLayoutService,
      private principalService: PrincipalService,
      private router: Router) {
    this.mobileQuery = this.igLayoutService.getMediaQueryForMobile();
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     if (event['url'] == '/signin') {
    //       this.showHead = false;
    //     } else {
    //       // console.log("NU")
    //       this.showHead = true;
    //     }
    //   }
    // });

  }

  ngOnInit(): void {
    this.igLayoutService.setSideNav(this.outerSideNav);
    this.principalService.getAuthenticationState()
      .pipe(delay(0),takeUntil(componentDestroyed(this)))
      .subscribe(account => {
        this.loggedInUser = account;
        this.showHead = true;
        this.showSidenav();
    });
    this.igLayoutService.getSelectedMenu().subscribe(menu => {
      if(menu) this.miniToggleSideNav(true);
    });
  }

  showSidenav() {
    if (this.loggedInUser && !this.mobileQuery.matches) {
      this.showOuterSideNav = true;
    } else {
      this.showOuterSideNav = false;
    }
  }

  miniToggleSideNav(isItemSelected : boolean) {
    /* if(this.isOuterSideNavOpen){
      if(this.isZoneSelected && (isItemSelected || this.isInnerSideNavOpen) )
        this.isInnerSideNavOpen = this.showInnerSideNav = true;
      else this.showInnerSideNav = false;
    }else{
      if(isItemSelected && this.isZoneSelected){
        this.isInnerSideNavOpen = this.showInnerSideNav = true;
        return;
      }else this.showInnerSideNav = false;
    } */
    
    /* this.igLayoutService.changeOuterSidenavOpen(!this.isOuterSideNavOpen); */
    if(isItemSelected) this.isOuterSideNavOpen = false;
    else this.isOuterSideNavOpen = !this.isOuterSideNavOpen;
  }

  /* miniToggleInnerSideNav() {
    this.igLayoutService.changeInnerSidenavOpen(!this.isInnerSideNavOpen);
    this.isInnerSideNavOpen = this.showInnerSideNav = !this.isInnerSideNavOpen;
  } */

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}
