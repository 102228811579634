
//import { PaginationConfig } from '../../../blocks/config/uib-pagination.config';
import { Principal } from '../shared/auth/principal.service';
import { ITEMS_PER_PAGE } from '../shared/constants/pagination.constants';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventManager, ParseLinks, PaginationUtil, JhiLanguageService, AlertService } from 'ng-jhipster';

import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-check-transactions.component',
    templateUrl: './loan-od-accounts-check-transactions.component.html'
})
export class LoanOdAccountsCheckTransactionsComponent implements OnInit, OnDestroy {

    currentAccount: any;
    loanOdAccounts: LoanOdAccounts[];
    error: any;
    success: any;
    /*eventSubscriber: Subscription;*/
    currentSearchString: string;
    currentSearch: {
        branchName: string;
    }
    routeData: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    aggregations: any;
    isSearch: any;
    checkerTransactionDtoList: any[];
    isProceed: boolean;
    isApprove: boolean;
    isReject: boolean;
    checkerTransactionDto: any;

    constructor(
        private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private parseLinks: ParseLinks,
        private alertService: AlertService,
        private principal: Principal,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eventManager: EventManager,
        private paginationUtil: PaginationUtil,
       // private paginationConfig: PaginationConfig,
    ) {
        this.loanOdAccounts = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            this.page = data['pagingParams'].page;
            this.previousPage = data['pagingParams'].page;
            this.reverse = data['pagingParams'].ascending;
            this.predicate = data['pagingParams'].predicate;
        });

        // this.jhiLanguageService.setLocations(['loanOdAccounts']);

        this.currentSearch = {
            branchName: ''
        }
        this.isProceed = false;
    }

    loadAll() {
        this.loanOdAccountsService.queryCheckTransactions({
            page: this.page - 1,
            size: this.itemsPerPage,
            query: this.currentSearch,
            sort: this.sort()}).subscribe(
            (res: Response) => this.onSuccess(res.json()),
            (res: Response) => this.onError(res.json())
        );
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        this.router.navigate(['/loan-od-accounts'],
            { queryParams:
                {
                    page: this.page,
                    size: this.itemsPerPage,
                    sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
                }
            });
        this.loadAll();
    }

    clear() {
        this.loanOdAccounts = [];
        this.page = 1;
        this.currentSearch =  {
            branchName: ''
        }
        this.router.navigate(['/loan-od-accounts', {
            page: this.page,
            sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
        }]);
        this.loadAll();
    }
    search() {
        this.page = 1;
        this.currentSearchString = '?';
        if (this.currentSearch) {
            for (const key of Object.keys(this.currentSearch)) {
                if (typeof(this.currentSearch[key]) === 'object') {
                    let month: any = 0;
                    let day: any = 0;

                    if (this.currentSearch[key].month < 10) {
                        month = '0' + this.currentSearch[key].month;
                    }else {
                        month = this.currentSearch[key].month;
                    }

                    if (this.currentSearch[key].day < 10) {
                        day = '0' + this.currentSearch[key].day;
                    }else {
                        day = this.currentSearch[key].day;
                    }

                    this.currentSearch[key] = this.currentSearch[key].year + '-' + month + '-' + day;
                }

                if (this.currentSearch[key] !== '' && this.currentSearch[key] !== undefined) {
                    this.currentSearchString += key + '=' + this.currentSearch[key] + '&';
                }
            }
        }

        this.router.navigate(['/loan-od-accounts', {
            search: this.currentSearchString,
            page: this.page,
            sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
        }]);
        this.loadAll();
    }

    ngOnInit() {
        this.loadAll();
        this.principal.identity().then((account) => {
            this.currentAccount = account;
        });
        /* this.registerChangeInLoanOdAccounts();*/
    }

    ngOnDestroy() {
        /* this.eventManager.destroy(this.eventSubscriber);*/
    }

    trackId(index: number, item: LoanOdAccounts) {
        return item.id;
    }
    /* registerChangeInLoanOdAccounts() {
         this.eventSubscriber = this.eventManager.subscribe('loanOdAccountsListModification', (response) => this.loadAll());
     }*/

    navigateToView(loanOdAccounts) {
        this.router.navigate(['/loanOdAccounts/view/' + loanOdAccounts.id]);
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    private onSuccess(data) {
        this.checkerTransactionDtoList = data;
    }

    private onReindexSuccess() {
        this.alertService.success('encoreclientApp.loanOdAccounts.reindex');
    }

    approve(transactionId) {
        this.loanOdAccountsService.approveCheckTransaction(transactionId).subscribe(
            (res) => this.onApproveSuccess(res.json()),
            (res) => this.onSaveError(res)
        );
    }

    reject(transactionId) {
        this.loanOdAccountsService.rejectCheckTransaction(transactionId).subscribe(
            (res) => this.onRejectSuccess(res.json()),
            (res) => this.onSaveError(res)
        );
    }

    private onRejectSuccess(data) {
        this.checkerTransactionDto = data;
        this.isReject = true;
        this.isProceed = true;
    }

    private onApproveSuccess(data) {
        this.checkerTransactionDto = data;
        this.isApprove = true;
        this.isProceed = true;
    }

    confirm() {
        this.loanOdAccountsService.confirmCheckTransaction(this.checkerTransactionDto).subscribe(
            (res) => this.onConfirmSuccess(),
            (res) => this.onSaveError(res)
        );
    }

    private onConfirmSuccess() {
        this.alertService.success('encoreclientApp.loanOdAccounts.checkTransactionSuccess',
            { id: this.checkerTransactionDto.transactionId });
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    back() {
        this.checkerTransactionDto = null;
        this.isApprove = false;
        this.isReject = false;
        this.isProceed = false;
    }

    cancel() {
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    private onSaveError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
            this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
    }

    private onError(error) {
        this.alertService.error('encoreclientApp.loanOdAccounts.saveError');
        this.router.navigate(['/loans/loan-od-accounts']);
    }
}
