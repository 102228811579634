import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {userRoute} from './user/user.module';
import {branchRoute} from '@app/admin/branch/branch.module';
import {branchSetRoute} from '@app/admin/branch-set/branch-set.module';
import {roleRoute} from '@app/admin/role/role.module';
import {referenceCodeRoute} from '@app/admin/reference-code/reference-code.module';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { AdminComponent } from './admin.component';
import { orgStructure } from './organization-structure/organization-structure.module';
import { deviceRoute } from './devices/device.module';
import { approvalLimitRoute } from './approval-limits/approval-limit.module';
import { deviationApprovalRoute } from './deviation-approval/deviation-approval.module';
import {AdminRouteGuardService} from './admin-route-guard.service';
import { productMasterRoute } from './product-master/product-master.module';
import {ScorekeyRoute} from './score-key/score-key.module'
import {WorkflowRoute} from './workflow/workflow.module'
import { productSetRoute } from '@app/admin/product-set/product-set.module';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthenticatedUserService,AdminRouteGuardService],
    children: [
      // {path: 'approval-limits', component: ApprovalLimit},
      userRoute, 
      referenceCodeRoute, 
      roleRoute, 
      branchRoute, 
      orgStructure, 
      deviceRoute, 
      approvalLimitRoute,
      deviationApprovalRoute,
      productMasterRoute,
      ScorekeyRoute,
      branchSetRoute,
      WorkflowRoute,
      productSetRoute
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AdminRoutingModule {
}