import { Injectable } from '@angular/core';
import { Coordinate, Zone } from '../zone.model';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsHelperService {

  constructor() { }

  /* Color hexcode for a red polygon. To be used while create/edit. */
  redHexCode: string = '#FF0000';

  /* Get the LatLng coordinates for the default polygon's path based on map's center. */
  getNewZonePolygonCoordinates(centerLat: number, centerLng: number) {
    let newPolygonCoordinates : any[] = [];
    
    newPolygonCoordinates.push({lat: centerLat + 0.05502, lng: centerLng - 0.00055});
    newPolygonCoordinates.push({lat: centerLat + 0.00970, lng: centerLng + 0.06440});
    newPolygonCoordinates.push({lat: centerLat - 0.05471, lng: centerLng + 0.04250});
    newPolygonCoordinates.push({lat: centerLat - 0.05502, lng: centerLng - 0.04093});
    newPolygonCoordinates.push({lat: centerLat + 0.01223, lng: centerLng - 0.06441});

    return newPolygonCoordinates;
  }
  /*
    Returns a polygon for latLngArray input parameter which is a coordinates array.
    The 'zone' and 'selectedZone' parameters both are Zone of type that are used to
    will define further characteristics of the polygon like polygon's
    draggable, editable properties and the polygon's color.
  */
  getPolygonForZone(latLngArray: any[], zone: Zone, selectedZone ?: Zone) {
    
    /* If selected zone is present and is actually the zone that is
      currently requesting for its polygon. */
    let isEditMode = selectedZone && selectedZone.uid === zone.uid;
    
    /* If edit mode is true then polygon color is red,
      otherwise take polygon color from it's zone. */
    let zoneHexCode = isEditMode ? this.redHexCode : zone.color;

    return {
      paths: latLngArray,
      draggable: isEditMode,
      editable: isEditMode,
      strokeColor: zoneHexCode,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: zoneHexCode,
      fillOpacity: 0.35,
      zoneKey: zone.uid
    };
  }

  /* Returns a coordinate object representation of coordinate string. */
  convertToLatLngObject(coordinate: string): Coordinate {
    let latLngCoordinate: Coordinate; 
    let coordinateArray = coordinate.split(",");
    if(coordinateArray.length == 2){
      latLngCoordinate = new Coordinate();
      latLngCoordinate.lat = +coordinateArray[0];
      latLngCoordinate.lng = +coordinateArray[1];
    }
    return latLngCoordinate;
  }

  /* Returns center coordinate of a polygon. */
  getPolygonCenter(polygonCoordinatesArray: any[]) {
    /* Create a 'Bounds' object. */
    var bounds = new google.maps.LatLngBounds();
    for(var i=0; i<polygonCoordinatesArray.length; i++){
      /* "Extend" the 'Bounds' object for every coordinate one by one.
        At the end we get a 'Bounds' object representation of the polygon.
        'Bounds' object can give us it's center coordinate.
        For regular shapes, corresponding 'Bounds' can be gotten by directly
        passing their coordinates array as constructor parameter. */
      bounds.extend(polygonCoordinatesArray[i]);
    }
    /* Return center of the resulting 'Bounds' object using its inbuild method. */
    return bounds.getCenter();
  }
  
}
