import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {AfterViewInit, OnDestroy, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { ListSource } from '@ig-core/list/list-source';

import { BranchSetService } from '../branch-set.service';
import {  BranchSetListDataSource } from './branch-set-list.data.source';
import {  BranchSetDeleteDialogComponent } from './branch-set-delete-dialog.component';

@Component({
    selector: 'app-branch-set',
    templateUrl: 'branch-set-list.component.html',
    styleUrls: ['../branch-set.component.css']
})
export class  BranchSetListComponent implements OnInit, AfterViewInit, OnDestroy {

    pagingAndSortParams: PagingAndSortParams;
    dataSource:  BranchSetListDataSource;
    listSource: ListSource;

    displayedColumns = [      'name',    'code',    'branchCodes',  'action'];

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    constructor(private branchSetService: BranchSetService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private snackBar: MatSnackBar) { }



    ngOnInit() {
        this.dataSource = new BranchSetListDataSource(this.branchSetService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                // Load paging and sort params from url
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'id';
                }
                // Load other, params and queryParams
                this.dataSource.loadBranchSets(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
                    this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize);
            });
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    ngAfterViewInit(): void {
        // initiate sort and pagination change once the view is initialized
        this.listSource.subscribePaginationAndSortChange()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadBranchSetsPageWithParams());
    }

    loadBranchSetsPageWithParams() {
        this.router.navigate([], this.getQueryParams());
    }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    deleteDialog(branchSetData: any) {
        const dialogRef = this.dialog.open(BranchSetDeleteDialogComponent, {
            data: branchSetData
        });
      dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe((result) => {
        if (result && result === 'deleted') {
          this.snackBar.open(`Deleted branch-set successfully`, 'Close');
          this.loadBranchSetsPageWithParams();
        }
      });
    }

}
