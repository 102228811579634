import { Component, Input, OnInit } from "@angular/core";
import { KycVerificationResponseComposite } from "@app/applications/application-details/applicant/applicantKycProofs/applicant-kyc-proof.model";

@Component({
  selector: "kyc-idproof-verification",
  templateUrl: "kyc-idproof-verification.template.html",
  styleUrls: [
    "../../../application-details.styles.scss",
    "../xdata-service.style.scss",
  ],
})
export class KycIdProofVerificationComponent implements OnInit {
  @Input()
  documentVerificationResponse: KycVerificationResponseComposite;

  @Input()
  // this is to determine from which component this common component is called from, right now possible values are "evidence" and "verification"
  // "evidence" will be from kycProof which will show server provider data used for verification, "verification" is from kycVerificationComponent which will show both internal and service provider data for user to verify.
  caller: any;

  constructor( ) {}

  ngOnInit() {

  }

}