import { Task } from '@app/tasks/task.model';
import { Note } from '@app/note/note.model';

export class LeadDetails {
    constructor(
        public opportunity ?: Lead,
        public interactionTrails ?: InteractionTrail[],
        public tasks ?: Task[],
        public notes ?: Note[],
        public plannedFollowup ?: Task,
        public nextFollowup ?: Task
    ) { }
}

export class Lead {
    constructor(
        public uid ?: string,
        public branchCode ?: string,
        public zoneCode ?: string,
        public salesPersonId ?: string,
        public initiatedOn ?: Date,
        public closedOn ?: Date,
        public source ?: string,
        public lat ?: number,
        public lng ?: number,
        public activeFlag ?: boolean,
        public applicationFlag ?: boolean,
        public reviewedFlag ?: boolean,
        public followupType ?: string,
        public interactionDate ?: Date,
        public interactionType ?: string,
        public interestedInLoan ?: boolean,
        public followupDate ?: Date,
        public applicationDate ?: Date,
        public personUid ?: string,
        public product ?: string,
        public amount ?: number,
        public businessName ?: string,
        public ownerName ?: string,
        public businessNature ?: string,
        public ownerPrimaryPhone ?: string,
        public bizPrimaryPhone ?: string,
        public addressLine1 ?: string,
        public addressLine2 ?: string,
        public addressLine3 ?: string,
        public pincode ?: string,
        public businessPicFileUid ?: string,
        public ownerPicFileUid ?: string,
        public status ?: string,
        public statusDate ?: Date,
        public entityUid ?: string,
        public branchUid ?: string,
        public zoneUid ?: string,
        public salesPersonUid ?: string,
        public outcome ?: string,
        public outcomeReason ?: string,
        public version ?: number
    ) { }
}
export  class UpdateLead {
    constructor(
    public opportunity ?: Lead,
    public type ?: string,
    public remarks ?: string
    ) { }
    
}
export class BusinessEntity {
    constructor(
        public uid ?: string,
        public entityId ?: string,
        public name ?: string,
        public legalName ?: string,
        public dateOfIncorporation ?: Date,
        public gstin ?: string,
        public pan ?: string,
        public form6061Flag ?: boolean,
        public description ?: string,
        public lat ?: number,
        public lng ?: number,
        public homeBranch ?: string,
        public operationalStatus ?: string,
        public weekendDay1 ?: string,
        public weekendDay2 ?: string,
        public branchUid ?: string,
        public profilePicFileId ?: string,
        public partyPlay ?: string,
        public dedupFlag ?: boolean,
        public dedupReference ?: string,
        public dedupResolution ?: string,
        public version ?: number
    ) { }
}

export class InteractionTrail {
    constructor(
        public uid ?: string,
        public type ?: string,
        public completedDate ?: string,
        public plannedDueDate ?: string,
        public revisedDueDate ?: string,
        public content ?: string,
        public overdue ?: boolean
    ) { }
}

export class LeadSearchCriteria {
    constructor(
        public leadStatuses ?: string[],
        public leadBranches ?: string[],
        public leadZone ?: number,
        public leadDateFrom ?: string,
        public leadDateTo ?: string,
    ) { }
}