import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ApplicationService } from '../../application.service';
import { LoanApplication, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { DeDupCheck, LmsDedupeMatch } from './dedup-check.model';
import { FormControl, FormGroup, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router'
@Component({
  selector: 'eng-dedup-check',
  templateUrl: './dedup-check.component.html',
  styleUrls: ['../application-details.styles.scss', './dedup-check.component.css']
})
export class DedupCheckComponent implements OnInit, OnChanges {
  @Input()
  application: LoanApplication;
  @Input() menuItem: string
  @Input() menuCode: string;
  @Input() from: any;

  deDupForm: FormGroup;
  showLmsCard: boolean = false;

  deDupDetails: Array<DeDupCheck> = [];

  deDupDetailsFromLms: Array<LmsDedupeMatch> = [];
  lmsDuplicate: Array<LmsDedupeMatch> = [];
  losDuplicate: Array<DeDupCheck> = [];
  losDeDupMatchAttribute: any;
  lmsDeDupMatchAttribute: any;

  hideOrShowLms:boolean;
  hideOrShowLos:boolean;
  

  constructor(private applicationService: ApplicationService,
    private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.resetMatchAttribute()
    this.getApplicationDeDupDetails()
  }

  ngOnChanges() {
    this.resetMatchAttribute();
    this.getApplicationDeDupDetails()
  }
/*
   Dedup component, on loading executes this method to retrieve matching records from server
   Dedup may be called for business, applicant, or coapplicant. Calling parent components sends "from" details
*/
  getApplicationDeDupDetails() {
    let fromType = ""
    //this fromType is set based on this.from value, from value is passed from applicant details component based on user click
    if (this.from.partyPlay == "applicant" || this.from.partyPlay == "coapplicant") {
      fromType = "person"
      this.showLmsCard = true;
    } else if (this.from.partyPlay == "business") {
      fromType = "businesses"
    }
     //API call to server with application uid, fromType as set above, and  specific record uid
    this.applicationService.getDeDupDetails(this.application.uid, fromType, this.from.uid).subscribe(response => {
      this.deDupDetails = response.body
      this.setMatchAttributes(this.deDupDetails,this.losDeDupMatchAttribute);
      this.losDuplicate = this.deDupDetails;
      //here we are splitting the "match" parameter comma separated values and storing in "matchArray" for display purpose 
      this.setMatchArray(this.deDupDetails)
    })

    if(fromType === "person"){
    this.applicationService.getDeDupDetailsFromLms(this.application.uid, fromType, this.from.uid).subscribe(response => {
      this.deDupDetailsFromLms = response.body
      this.setMatchAttributes(this.deDupDetailsFromLms, this.lmsDeDupMatchAttribute)
      this.lmsDuplicate = this.deDupDetailsFromLms;
      this.setMatchArray(this.deDupDetailsFromLms)
  })
}
  }   /*
   'Open Application' click in dedup card calls this method with application data as parameter
   This method picks UID from application data passed, and redirects to applications/uid
  */
  goToMatchedApplication(goToApplication) {
    //First redirect to home page then to the respective application using application uid. This is done to reload the component
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/applications', goToApplication.uid]);
    });
  }

  //here we are splitting the "match" parameter comma separated values and storing in "matchArray" for display purpose
  setMatchArray(dedupResponse){
    dedupResponse.forEach((element, index) => {
      dedupResponse[index]["matchArray"] = element.match.split(',')
    });
  }

  // This function updates a filterArray with unique values from a dedupDetails array. If the element has a "matches" property,
  // loop through its values and checking if the value is not already in the filterArray.
  // If it's not in the filterArray, then we are creating object with code as value and selected set to default fals initially 
  setMatchAttributes(dedupDetails, filterArray) {
    dedupDetails.forEach(ele => {
      if (ele.matches) {
        ele.matches.forEach(value => {
          if (!filterArray.some(item => item.code === value)) {
            filterArray.push({ code: value, selected: false });
          }
        });
      }
    });
  }

  // here we are passing losDuplicate and deDupDetailsFromLms it has selected field indicating whether it's selected or not.
  onLmsCheckboxChange() {
    this.deDupDetailsFromLms = this.filterData(this.lmsDuplicate, this.lmsDeDupMatchAttribute);
  }

  // here we are passing losDuplicate and losDeDupMatchAttribute it has selected field indicating whether it's selected or not.
  onLosCheckboxChange() {
     this.deDupDetails = this.filterData(this.losDuplicate,this.losDeDupMatchAttribute);
  }

  // This function filters an array of dedupDuplicateDetails based on an array of filter values (deDupMAtchingAttributes).
  // For each item, use the `every` method to check if every value in deDupMAtchingAttributes is included in the 'matches'
  filterData(copyOfDedupDetails, deDupMAtchingAttributes) {
    return copyOfDedupDetails.filter(item => {
      return deDupMAtchingAttributes.every(filterItem => {
        if (filterItem.selected) {
          return item.matches.includes(filterItem.code);
        }
        return true;
      });
    });
  }

  resetMatchAttribute() {
    this.hideOrShowLms = false;
    this.hideOrShowLos = false;
    this.losDeDupMatchAttribute = [];
    this.deDupDetails = this.filterData(this.losDuplicate,this.losDeDupMatchAttribute);
    this.lmsDeDupMatchAttribute = [];
    this.deDupDetailsFromLms = this.filterData(this.lmsDuplicate,this.lmsDeDupMatchAttribute);
  }

  // this method is called when we click on expand_more or expand_less button, to make it common method we are passing attribute as
  // lms or los based on the attribute respective dedup details cards will hide and show
  hideOrShowDetails(attribute: string) {
    if (attribute === 'lms') {
      this.hideOrShowLms = !this.hideOrShowLms;
    } else if (attribute === 'los') {
      this.hideOrShowLos = !this.hideOrShowLos;
    }
  }
}
