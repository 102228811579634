import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialLibModule } from '@app/material-lib.module';
import { EditableResolveService } from '@ig-core/resolves/editable-resolve.service';
import { NonEditableResolveService } from '@ig-core/resolves/non-editable-resolve.service';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { ScorekeyComponent } from './score-key.component';

export const ScorekeyRoute: Route = {
  path: 'score-key',
  canActivate: [AuthenticatedUserService],
  children: [
    { path: '', component: ScorekeyComponent },

  
    ]
    
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [ ScorekeyComponent],
  entryComponents: []
})
export class ScorekeyModule { }
