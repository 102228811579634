import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';
import { IgPatternValidator } from '@ig-core/form/additional-validators/ig-pattern-validator';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

import { User } from './user.model';
import { UserService } from './user.service';
import { Branch } from '../branch/branch.model';
import { OrganizationStructureService } from '../organization-structure/organization-struture.service';
import { OrganizationStructure } from '../organization-structure/organization-structure.model';
import { BranchSetService } from '../branch-set/branch-set.service';
import { BranchSet } from '../branch-set/branch-set.model';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { CoLenderDTO } from '@app/applications/application-details/loanInformation/co-lender/co-lender.model';
import { ApplicationService } from '@app/applications/application.service';
@Component({
  selector: 'app-user-creation',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserEditorComponent implements OnInit, OnDestroy {
  user: User;
  branches: Branch[];
  roleValue: any;
  orgStructureForRoles: OrganizationStructure [] = [];
  allBranchsets: BranchSet [] = [];
  organizationStructureService: OrganizationStructureService;
  userTypes:NameValueDto[]=[]
  partnerTypes:CoLenderDTO[]=[]
  public rolesObservable$;
  editable = true;
  public userEditorForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    firstName: '',
    lastName: '',
    email: '',
    login: '',
    role: '',
    imageUrl: '',
    langKey: '',
    newPassword: '',
    branchCode: '',
    orgStrUid: '',
    branchSet: '',
    userType:'',
    branchCodes: '',
    partnerCode: '',
  };
  show_password: Boolean = false;

  // public allBrancheSet$;
  constructor(private userService: UserService,
    private branchSetService: BranchSetService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    private referenceCodeService: ReferenceCodeService,
    private colenderService: ApplicationService,
    private igFormService: IgFormService) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.user === undefined) {
          this.user = {};
        } else {
          this.user = data.user;
        }
        this.editable = data.editable;
      });

    this.rolesObservable$ = this.userService.getAllRoles();
    // this.allBrancheSet$ = this.branchSetService.getAllBranchSet();
    this.userEditorForm = this.formBuilder.group({
      firstName: '',
      lastName: '',
      email: '',
      login: '',
      role: '',
      imageUrl: '',
      activated:'',
      langKey: '',
      newPassword: '',
      branchCode: '',
      orgStrUid: null,
      branchSet: '',
      userType:'',
      branchCodes: '',
      partnerCode: '',
      checkboxes:''
    })
  }

  showPassword() {
    this.show_password = !this.show_password;
  }

  ngOnInit() {
    this.getBranches();
    this.getRefCodes(); 
    this.getCoLender();   
    this.formError = new FormErrorModel(false, '');
    // here this condition is disabling the dropDown fields and not the input fields. This is workaround to disable whole form
    // this code still need to be fixed with proper code
    if (!this.editable) {
      this.userEditorForm.disable();
    }
    this.fetchAllBranchset();
  }

  // this api is to get the coLender dropdown values
  getCoLender(){
    this.colenderService.getCoLender().subscribe(response =>{
      this.partnerTypes = response.body;
    })
  }

  getRefCodes(){
    this.referenceCodeService.getShortRefCodes('user_type').subscribe((response: any) => {
      this.userTypes = response.user_type;
    });
  }

  ngOnDestroy(): void { }

  buildForm() {
    this.userEditorForm = this.formBuilder.group({
      id: [this.user.id],
      firstName: [this.user.firstName],
      lastName: [this.user.lastName],
      email: [this.user.email, Validators.required],
      login: [this.user.login, Validators.required],
      role: [this.user.role, Validators.required],
      imageUrl: [this.user.imageUrl],
      langKey: [this.user.langKey],
      newPassword: [''],
      branchCode: [this.user.branchCode],
      orgStrUid: [this.user.orgStrUid],
      activated: [this.user.activated],
      passwordAutoGenerate: [this.user.passwordAutoGenerate],
      userType:[this.user.userType, Validators.required],
      branchSet:[this.user.branchsetCode],
      branchCodes: [this.user.additionalBranches],  
      partnerCode: [this.user.partnerCode],
      checkboxes: this.formBuilder.array( this.branches.map(x => false))
    });
    
    this.fetchOrgStructureForRole("onload");

    // this is used to set true that means mark check boxes as selected we are executing this set of code
    let checkboxControl = (this.userEditorForm.controls.checkboxes as FormArray);
    if(this.user.additionalBranches){
        checkboxControl.controls.forEach((element,index) => {
          if(this.user.additionalBranches.includes(this.branches[index].code)){
            checkboxControl.controls[index].patchValue(true)
          }
        });
      }

    // here this condition is disabling the input fields and not the dropDown fields. This is workaround to disable whole form
    // this code still need to be fixed with proper code. In ngOnInit it is disabling dropdown here it is disabling input fields.
      if (!this.editable) {
        this.userEditorForm.disable();
      }

    if (this.editable && this.user.id) {
      this.userEditorForm.controls.login.disable();
    }
    
    if (this.editable && !this.user.id) {
      this.userEditorForm.controls['newPassword'].setValidators(Validators.compose([Validators.required,
      Validators.maxLength(100),
      // check whether the entered password has a number
      IgPatternValidator.patternValidator(/\d/, {
        hasNumber: true
      }),
      // check whether the entered password has upper case letter
      IgPatternValidator.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      // check whether the entered password has a lower case letter
      IgPatternValidator.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      // check whether the entered password has a special character
      IgPatternValidator.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      ),
      Validators.minLength(8)]));
    }
    // on each value change we call the validateForm function
    // We only validate form controls that are dirty, meaning they are touched
    // the result is passed to the formErrors object
    this.userEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.userEditorForm, this.formErrors, true);
    });
  }

  passwordAutoGenerateChange($event) {
    if ($event.checked) {
      this.userEditorForm.controls['newPassword'].disable();
    } else {
      this.userEditorForm.controls['newPassword'].enable();
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  saveUser() {
    this.markFormGroupTouched(this.userEditorForm)
    if (this.userEditorForm.valid) {
      let userFormFields = this.userEditorForm.controls;
      this.user.id = userFormFields.id.value;
      this.user.firstName = userFormFields.firstName.value;
      this.user.lastName = userFormFields.lastName.value;
      this.user.email = userFormFields.email.value;
      this.user.login = userFormFields.login.value;
      this.user.role = userFormFields.role.value;
      this.user.imageUrl = userFormFields.imageUrl.value;
      this.user.langKey = userFormFields.langKey.value;
      this.user.newPassword = userFormFields.newPassword.value;
      this.user.branchCode = userFormFields.branchCode.value;
      this.user.orgStrUid = userFormFields.orgStrUid.value;
      this.user.activated = userFormFields.activated.value;
      this.user.passwordAutoGenerate = userFormFields.passwordAutoGenerate.value;
      this.user.branchsetCode=userFormFields.branchSet.value;
      this.user.userType =userFormFields.userType.value;
      this.user.partnerCode = userFormFields.partnerCode.value;

      let checkboxControl = (this.userEditorForm.controls.checkboxes as FormArray);
      let codes = []
      checkboxControl.controls.forEach((element, index) => {
        if (element.value) {
          codes.push(this.branches[index].code)
        }
      });
      this.userEditorForm.controls.branchCodes.patchValue(codes)
      let request=this.userEditorForm.value
      delete request.checkboxes
      this.user.additionalBranches =request.branchCodes;

      this.userService.saveUser(this.user).toPromise().then(
          (_success) => {
            if (this.user.id) {
              this._snackbar.open("User updated successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
            } else {
              this._snackbar.open("User created successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../'], { relativeTo: this.activatedRoute });
            }
          }, (failure) => {
            this._snackbar.open("User update failed, " + failure.title, "Close", {
              duration: 2000,
            })
            console.log(failure);
          }
        );
    }
  }


  goToUserListPage() {
    if (this.user.id) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

  getBranches() {
    this.userService.getAllBranches().subscribe((response: any) => {
      if (response) {
        this.branches = response;
        this.branches.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        this.buildForm();
      }
    });
  }


  UserRoleValue() {
    this.roleValue = {
      role: this.userEditorForm.controls['role'].value,
    }
  }

  fetchOrgStructureForRole(from?:string) {
    // if(this.userEditorForm.controls.branchSet.value != true ) {
    this.UserRoleValue();
    // this.enableDisable();
    this.userService.getOrgStructureForRole(this.roleValue.role).subscribe((response: any) => {
      if (response) {
        this.orgStructureForRoles = response.body;
        if(from != "onload"){
        this.userEditorForm.controls.orgStrUid.patchValue("")
        }
        
      }
    });
  // } else {
  //   this.userEditorForm.controls.orgStrUid.disable();
  // }
  }

  fetchAllBranchset() {
    this.branchSetService.getAllBranchSet().subscribe((response : any) => {
      if (response) {
        this.allBranchsets = response;
      }
    })
  }

  get isPartnerCodeDisabled() {
    // Check if the form is in view mode and the selected userType is 'Employee'
    return !this.editable || this.userEditorForm.get('userType').value === 'EMPLOYEE';
  }
  

  onUserTypeChange(selectedUserType: string) {
    if (this.isPartnerCodeDisabled) {
      this.userEditorForm.get('partnerCode').setValue(null);
    }
  }

}
