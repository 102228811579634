import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';
import { IgPatternValidator } from '@ig-core/form/additional-validators/ig-pattern-validator';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { OrganizationStructure, OrgUnit, User } from '../organization-structure.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { OrganizationStructureService } from '../organization-struture.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { SnackbarService } from '@app/utils/multiple-snackbar-messages-display.service';

@Component({
  selector: 'app-organization-structure',
  templateUrl: './organization-structure-edit.template.html',
  styleUrls: ['../organization-structure.component.scss']
})

export class OrganizationStructureEditorComponent implements OnInit, OnDestroy {

  organizationStructure: OrganizationStructure;
  userForOrgStructures: User[];
  editable = true;
  title: string;

  orgChains: NameValueDto[] = [];
  chainLevels: OrgUnit[] = [];
  allLevels: OrgUnit[] = [];
  chainLevelValue: any;
  parentUnits: OrganizationStructure[] = [];

  public organizationStructureEditorForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    chain: '',
    level: '',
    orgUnitCode: '',
    orgUnitName: '',
    branch: ''
  };

  constructor(
    private organizationStructureService: OrganizationStructureService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private referenceCodeService: ReferenceCodeService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    private igFormService: IgFormService,
    private customSnackBarService: SnackbarService
  ) {
    this.activatedRoute.data /* taking data from resolver */
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.organizationStructure === undefined) {
          this.organizationStructure = {};
        } else {
          this.organizationStructure = data.organizationStructure.body;
        }
        this.editable = data.editable;
      });
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((param) => {
        this.title = JSON.parse(param.title)
      });
  }

  ngOnInit() {
    this.getReferenceCodes();
    //fetch all levels
    this.fetchLevels();
    this.buildForm();
    this.getUserByOrgStructure();
    this.formError = new FormErrorModel(false, '');
    if (!this.editable) {
      this.organizationStructureEditorForm.disable();
    }
    //fetch levels for a given chain
    this.fetchLevels(this.organizationStructureEditorForm.controls['chain'].value);
    this.fetchParentsUnit();
  }

  ngOnDestroy(): void { }

  buildForm() {
    this.organizationStructureEditorForm = this.formBuilder.group({
      uid: [this.organizationStructure.uid, []],
      version: [this.organizationStructure.version, []],
      chain: [this.organizationStructure.chain, []],
      level: [this.organizationStructure.level, []],
      orgUnitCode: [this.organizationStructure.orgCode, []],
      orgUnitName: [this.organizationStructure.name, []],
      branch: [this.organizationStructure.branchCode, []],
      parentUnit: [this.organizationStructure.parentUid, []]
    });
    this.organizationStructureEditorForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.formErrors = this.igFormService.validateForm(
          this.organizationStructureEditorForm,
          this.formErrors,
          true
        );
      });
      this.onChangeLevels(this.organizationStructure.level);
  }

  getReferenceCodes() {
    this.referenceCodeService.getShortRefCodes('org_chain').subscribe((response: any) => {
      this.orgChains = response.org_chain;
    });
  }

  /* This method has sets response to either 'allLevels' or 'chainLevels'..
    depending on the existence of 'chain' parameter */
  fetchLevels(chain ?: string) {
    this.organizationStructureService.getLevels(chain).subscribe((response: any) => {
      if (response) {
        if (!chain && chain !== '') { //if 'chain' parameter is not specified
          this.allLevels = response.body;
        } else {  //if 'chain' parameter is specified
          this.chainLevels = response.body;
        }
      }
    });
  }

  onChangeLevels(isLevelBase) {
    if (isLevelBase.charAt(isLevelBase.length-1) == 0 &&  isLevelBase != null &&  isLevelBase != ' ') {
      this.organizationStructureEditorForm.get('branch').enable();
    } else {
      
      this.organizationStructureEditorForm.get('branch').disable();
    }
  }

  chainAndLevelValues() {
    this.chainLevelValue = {
      chain: this.organizationStructureEditorForm.controls['chain'].value,
      level: this.organizationStructureEditorForm.controls['level'].value
    }
  }

  fetchParentsUnit() {
    this.chainAndLevelValues();
    this.organizationStructureService.getParentUnit(this.chainLevelValue.chain, this.chainLevelValue.level).subscribe((response: any) => {
      if (response) {
        this.parentUnits = response.body;
      }
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  saveOrgStructure() {
    this.markFormGroupTouched(this.organizationStructureEditorForm)
    if (this.organizationStructureEditorForm.valid) {
      let organizationStructureFormFields = this.organizationStructureEditorForm.value;
      this.organizationStructure.uid = organizationStructureFormFields.uid;
      this.organizationStructure.version = organizationStructureFormFields.version;
      this.organizationStructure.chain = organizationStructureFormFields.chain;
      this.organizationStructure.level = organizationStructureFormFields.level;
      this.organizationStructure.orgCode = organizationStructureFormFields.orgUnitCode;
      this.organizationStructure.name = organizationStructureFormFields.orgUnitName;
      this.organizationStructure.branchCode = organizationStructureFormFields.branch;
      this.organizationStructure.parentUid = organizationStructureFormFields.parentUnit;
      
      /* Fetch and set level number of the org structure based on level code. */
      this.organizationStructure.levelNum = this.chainLevels.find(level =>
        this.organizationStructure.level === level.code).levelNum;
      
      /* Fetch and set parent org unit name and code by uid. */
      if (organizationStructureFormFields.parentUnit) {
        let selectedParent = this.parentUnits.find(parentUnit =>
            parentUnit.uid === organizationStructureFormFields.parentUnit);
        this.organizationStructure.parentOrgCode = selectedParent.orgCode;
        this.organizationStructure.parentOrgUnitName = selectedParent.name;
      }
      
      this.organizationStructureService.saveOrgStructure(this.organizationStructure.uid,
        this.organizationStructure).toPromise().then(
          (_success) => {
            if (this.organizationStructure.uid) {
              this._snackbar.open("Organization Structure updated successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
            } else {
              this._snackbar.open("Organization Structure created successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../'], { relativeTo: this.activatedRoute });
            }
          }, (_failure) => {
            let errormesg =[]
            errormesg.push("Organization Structure updated Faild.")
            errormesg.push(_failure)
            this.displayErrorMessages(errormesg);
          }
      );
    }
  }

  displayErrorMessages(mesg?: any) {
    this.customSnackBarService.openSnackBar(mesg, 'Close');
  }

  getUserByOrgStructure() {
    this.organizationStructureService.fetchUserByOrgStructure(this.organizationStructure.uid).subscribe((response: any) => {
      if (response) {
        this.userForOrgStructures = response.body;
      }
    });
  }

  goToOrgStructureListPage() {
    if (this.organizationStructure.uid) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

}
