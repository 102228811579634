import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, EventManager, JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { DateConverterService } from '../shared/date/date-converter.service';
import { CustomValidators } from '../shared/validators/customValidators.directive';
import { Money } from './money';
import { Tenure } from './tenure';
import { AccountMandateDto } from './account-mandate-dto.model';
import { LoanOdAccountsBasicEditorComponent } from './loan-od-accounts-basic-editor.component';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { TransactionSummaryDto } from './transaction-summary-dto.model';
import { Principal, Account, AccountService } from '../shared';

@Component({
    selector: 'jhi-loan-od-accounts-editor',
    templateUrl: './loan-od-accounts-editor.component.html',
})
export class LoanOdAccountsEditorComponent implements OnInit, OnDestroy {
    loanOdAccounts: LoanOdAccounts;
    blankLoanOdAccounts: LoanOdAccounts = {};
    proceedIsTrue = false;
    mandateFileDataFile: any;
    enableSave: Boolean;
    files: any;
    purposeDict: any;
    isProceed = false;
    loanForm: FormGroup;
    basicTabIsInvalid: boolean;
    termsTabIsInvalid: boolean;
    customerTabIsInvalid: boolean;
    udfTabIsInvalid: boolean;
    locationDataIsAvailable: boolean;
    locationData: any;
    newLocationData: any;
    udfDataIsAvailable: boolean;
    classifierValues: any;
    locationCodesList: any;
    valueChangeBasic: Subscription;
    valueChangeTerms: Subscription;
    valueChangeUdf: Subscription;
    valueChangeCustomer: Subscription;
    valueChangeCountry1: Subscription;
    valueChangeCountry2: Subscription;
    valueChangeCountry3: Subscription;
    valueChangeCountry4: Subscription;
    valueChangeCountry5: Subscription;
    valueChangeCountry6: Subscription;
    valueChangeState1: Subscription;
    valueChangeState2: Subscription;
    valueChangeState3: Subscription;
    valueChangeState4: Subscription;
    valueChangeState5: Subscription;
    valueChangeState6: Subscription;
    udfTextDisplayAsSelect: boolean[];
    udfTabVisitCount: number;
    // account: Account;
    role: any;

    constructor(
        // private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService,
        private router: Router,
        private loanOdAccountsBasicEditorComponent: LoanOdAccountsBasicEditorComponent,
        private fb: FormBuilder,
        private loanOdAccountsService: LoanOdAccountsService,
        private eventManager: EventManager,
        private datePipe: DatePipe,
        private dateConverter: DateConverterService,
        private route: ActivatedRoute,
        public principal: Principal,
        public account: AccountService
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.locationDataIsAvailable = false;
        this.mandateFileDataFile = '';
        this.files = new Array;
        this.purposeDict = {};
        this.locationData = new Array();
        this.classifierValues = new Array(20);
        this.locationData[0] = {
            countryList: [],
            stateList: [],
            cityList: [],
            districtList: []
        };
        this.locationData[1] = {
            countryList: [],
            stateList: [],
            cityList: [],
            districtList: []
        };
        this.locationData[2] = {
            countryList: [],
            stateList: [],
            cityList: [],
            districtList: []
        };
        this.locationData[3] = {
            countryList: [],
            stateList: [],
            cityList: [],
            districtList: []
        };
        this.udfTextDisplayAsSelect = new Array(20);
        this.udfTabVisitCount = 0;
    }

    ngOnInit() {
        this.loanOdAccounts = new LoanOdAccounts();
        this.loanOdAccounts.tenure = new Tenure();
        this.loanOdAccounts.amount = new Money();
        this.loanOdAccountsService.setLoanOdAccount(this.loanOdAccounts);
        this.loanOdAccounts = this.route.snapshot.data['loanOdAccounts'] ?
            this.route.snapshot.data['loanOdAccounts'] : new LoanOdAccounts();
        if (this.loanOdAccounts.id != null) {
        }
        if (this.loanOdAccounts.transactionSummary === null) {
            this.loanOdAccounts.transactionSummary = new Array<TransactionSummaryDto>();
        }
        if (this.loanOdAccounts.accountMandateDto === null) {
            this.loanOdAccounts.accountMandateDto = new AccountMandateDto();
        }
        this.loanOdAccountsService.getCountries().subscribe(
            (res) => {
                this.updateCountryList(res);
            });
        // this.principal.identity().then((account) => {
        //     this.account = account;
        // });
        this.account.get().subscribe((data) => { this.role = data.roleCode });
    }

    udfTabVisited(event): void {
        this.udfTabVisitCount++;
    }

    private updateCountryList(data): void {
        for (let index = 0; index < 4; index++) {
            if (this.locationData[index] && this.locationData[index] !== null) {
                this.locationData[index] = data;
            }
        }
    }

    fetchStates(countryCode: string, index: number): void {
        this.loanOdAccountsService.getState(countryCode).subscribe(
            (res) => {
                this.updateStateList(res, index);
            });
    }

    private updateStateList(data, index: number) {
        this.locationData[index].stateList = data;
    }

    fetchCities(countryCode: string, stateCode: string, index: number): void {
        this.loanOdAccountsService.getCities(countryCode, stateCode).subscribe(
            (res) => {
                this.updateCityList(res.json(), countryCode, stateCode, index);
            });
    }

    private updateCityList(data, countryCode: string, stateCode: string, index: number) {
        this.locationData[index].cityList = data;
        this.loanOdAccountsService.getDistrict(countryCode, stateCode).subscribe(
            (res) => {
                this.updateDistrictList(res.json(), index);
            });
    }

    private updateDistrictList(data, index: number) {
        this.locationData[index].districtList = data;
        this.newLocationData = JSON.parse(JSON.stringify(this.locationData));
        this.locationData = JSON.parse(JSON.stringify(this.newLocationData));
        this.locationDataIsAvailable = true;
    }

    basicCheck() {
        this.loanOdAccounts = this.loanOdAccountsService.getLoanOdAccount();
        this.loanOdAccountsService.createLoanAccountPrecheck(this.loanOdAccounts)
            .subscribe((res: Response) =>
                this.onSaveSuccessPrecheck(res), (res: Response) => this.onSaveError(res));
    }

    private onSaveSuccessPrecheck(result) {
        this.loanOdAccounts = result.loanOdAccountDto;
        const tenure = new Tenure();
        tenure.magnitude = result.loanOdAccountDto.tenure.magnitude;
        tenure.unit = result.loanOdAccountDto.tenure.unit;
        this.loanOdAccounts.tenure = tenure;
        const moratoriumPeriod = new Tenure();
        moratoriumPeriod.magnitude = result.loanOdAccountDto.moratoriumPeriod.magnitude;
        moratoriumPeriod.unit = result.loanOdAccountDto.moratoriumPeriod.unit;
        this.loanOdAccounts.moratoriumPeriod = moratoriumPeriod;
        this.classifierValues = result.classifierValues;
        this.loanOdAccountsService.setLoanOdAccount(this.loanOdAccounts);
        this.loanOdAccounts.openedOnDate = this.dateConverter.fromServerToNgbDatePicker(this.loanOdAccounts.openedOnDate);
        this.loanOdAccounts.firstRepaymentDate = this.dateConverter.fromServerToNgbDatePicker(this.loanOdAccounts.firstRepaymentDate);
        if (!this.loanOdAccounts.amount) {
            this.loanOdAccounts.amount = new Money();
        }
        if (!this.loanOdAccounts.userSecurityDeposit) {
            this.loanOdAccounts.userSecurityDeposit = new Money();
        }
        if (this.loanOdAccounts.locationCode) {
            // tslint:disable-next-line:no-shadowed-variable
            this.loanOdAccountsService.getLocationCodes(this.loanOdAccounts.customerId1).subscribe((result) => this.locationCodesList = result);
        }

        if (this.loanOdAccounts.contact1 && this.loanOdAccounts.contact1.countryCode) {
            this.fetchStates(this.loanOdAccounts.contact1.countryCode, 0);
        }
        if (this.loanOdAccounts.contact1 && this.loanOdAccounts.contact1.stateCode) {
            this.fetchCities(this.loanOdAccounts.contact1.countryCode, this.loanOdAccounts.contact1.stateCode, 0);
        }


        if (this.loanOdAccounts.customerId2 && this.loanOdAccounts.contact2 && this.loanOdAccounts.contact2.countryCode) {
            this.fetchStates(this.loanOdAccounts.contact2.countryCode, 1);
        }
        if (this.loanOdAccounts.customerId2 && this.loanOdAccounts.contact2 && this.loanOdAccounts.contact2.stateCode) {
            this.fetchCities(this.loanOdAccounts.contact2.countryCode, this.loanOdAccounts.contact2.stateCode, 1);
        }

        if (this.loanOdAccounts.customerId3 && this.loanOdAccounts.contact3 && this.loanOdAccounts.contact3.countryCode) {
            this.fetchStates(this.loanOdAccounts.contact3.countryCode, 2);
        }
        if (this.loanOdAccounts.customerId3 && this.loanOdAccounts.contact3 && this.loanOdAccounts.contact3.stateCode) {
            this.fetchCities(this.loanOdAccounts.contact3.countryCode, this.loanOdAccounts.contact3.stateCode, 2);
        }

        if (this.loanOdAccounts.customerId4 && this.loanOdAccounts.contact4 && this.loanOdAccounts.contact4.countryCode) {
            this.fetchStates(this.loanOdAccounts.contact4.countryCode, 3);
        }
        if (this.loanOdAccounts.customerId4 && this.loanOdAccounts.contact4 && this.loanOdAccounts.contact4.stateCode) {
            this.fetchCities(this.loanOdAccounts.contact4.countryCode, this.loanOdAccounts.contact4.stateCode, 3);
        }

        if (this.loanOdAccounts.customerId5 && this.loanOdAccounts.contact5 && this.loanOdAccounts.contact5.countryCode) {
            this.fetchStates(this.loanOdAccounts.contact5.countryCode, 4);
        }
        if (this.loanOdAccounts.customerId5 && this.loanOdAccounts.contact5 && this.loanOdAccounts.contact5.stateCode) {
            this.fetchCities(this.loanOdAccounts.contact5.countryCode, this.loanOdAccounts.contact5.stateCode, 4);
        }

        if (this.loanOdAccounts.customerId6 && this.loanOdAccounts.contact6 && this.loanOdAccounts.contact6.countryCode) {
            this.fetchStates(this.loanOdAccounts.contact6.countryCode, 5);
        }
        if (this.loanOdAccounts.customerId6 && this.loanOdAccounts.contact6 && this.loanOdAccounts.contact6.stateCode) {
            this.fetchCities(this.loanOdAccounts.contact6.countryCode, this.loanOdAccounts.contact6.stateCode, 5);
        }

        if (this.loanOdAccounts.contact1 && !this.loanOdAccounts.contact1.stateCode) {
            this.locationDataIsAvailable = true;
        }
        this.initForm();
        this.proceedIsTrue = true;
    }

    private onSaveError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
    }

    initForm() {
        this.loanForm = this.fb.group({
            basic: this.fb.group({
                productCode: this.loanOdAccounts.productCode,
                accountName: this.loanOdAccounts.accountName,
                branchCode: this.loanOdAccounts.branchCode,
                locationCode: this.loanOdAccounts.locationCode,
                guarantorCustomerId: this.loanOdAccounts.guarantorCustomerId,
                linkedAccountId: this.loanOdAccounts.linkedAccountId,
                collateralCode: this.loanOdAccounts.collateralCode,
                portfolioName: this.loanOdAccounts.portfolioName,
            }),
            terms: this.fb.group({
                tenureMagnitude: this.loanOdAccounts.tenure.magnitude,
                tenureUnit: this.loanOdAccounts.tenure.unit,
                tenureMagnitudeAndUnit: this.loanOdAccounts.tenure.magnitudeAndUnit,
                amountMagnitude: [this.loanOdAccounts.amount.magnitude, Validators.required],
                operativeAccountId: this.loanOdAccounts.operativeAccountId,
                openedOnDate: [this.loanOdAccounts.openedOnDate, Validators.required],
                firstRepaymentDate: this.loanOdAccounts.firstRepaymentDate,
                normalInterestRateMagnitude: null,
                nominalInterestRateMagnitude: null,
                // moratoriumPeriodMagnitude: this.loanOdAccounts.moratoriumPeriod.magnitude,
                // moratoriumPeriodUnit: this.loanOdAccounts.moratoriumPeriod.unit,
                // moratoriumInstallmentMagnitude: [this.loanOdAccounts.moratoriumInstallment.magnitude, Validators.min(0)],
                equatedInstallmentMagnitude: this.loanOdAccounts.equatedInstallment.magnitude,
                userSecurityDepositMagnitude: null
            }),
            customer: this.fb.group({
                customer1: this.fb.group({
                    customerId1: this.loanOdAccounts.customerId1,
                    firstName: [this.loanOdAccounts.customerName1.firstName, Validators.required],
                    middleName: this.loanOdAccounts.customerName1.middleName,
                    lastName: this.loanOdAccounts.customerName1.lastName,
                    address1: [this.loanOdAccounts.contact1.address1, Validators.required],
                    address2: [this.loanOdAccounts.contact1.address2, Validators.required],
                    address3: [this.loanOdAccounts.contact1.address3, Validators.required],
                    countryCode: [this.loanOdAccounts.contact1.countryCode, Validators.required],
                    stateCode: [this.loanOdAccounts.contact1.stateCode, Validators.required],
                    cityCode: [this.loanOdAccounts.contact1.cityCode, Validators.required],
                    districtCode: this.loanOdAccounts.contact1.districtCode,
                    pinCode: [this.loanOdAccounts.contact1.pinCode, [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]],
                    phone1: this.loanOdAccounts.contact1.phone1,
                    email: [this.loanOdAccounts.contact1.email, CustomValidators.emailOrEmpty]
                }),
                customer2: this.fb.group({
                    customerId2: null,
                    firstName: null,
                    middleName: null,
                    lastName: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    countryCode: null,
                    stateCode: null,
                    cityCode: null,
                    districtCode: null,
                    pinCode: null,
                    phone1: null,
                    email: null,
                }),
                customer3: this.fb.group({
                    customerId3: null,
                    firstName: null,
                    middleName: null,
                    lastName: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    countryCode: null,
                    stateCode: null,
                    cityCode: null,
                    districtCode: null,
                    pinCode: null,
                    phone1: null,
                    email: null,
                }),
                customer4: this.fb.group({
                    customerId4: null,
                    firstName: null,
                    middleName: null,
                    lastName: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    countryCode: null,
                    stateCode: null,
                    cityCode: null,
                    districtCode: null,
                    pinCode: null,
                    phone1: null,
                    email: null,
                }),
                customer5: this.fb.group({
                    customerId5: null,
                    firstName: null,
                    middleName: null,
                    lastName: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    countryCode: null,
                    stateCode: null,
                    cityCode: null,
                    districtCode: null,
                    pinCode: null,
                    phone1: null,
                    email: null,
                }),
                customer6: this.fb.group({
                    customerId6: null,
                    firstName: null,
                    middleName: null,
                    lastName: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    countryCode: null,
                    stateCode: null,
                    cityCode: null,
                    districtCode: null,
                    pinCode: null,
                    phone1: null,
                    email: null,
                })
            }),
            udf: this.fb.group({
                udfTextLabel1: this.loanOdAccounts.udfTextLabel1,
                udfTextLabel2: this.loanOdAccounts.udfTextLabel2,
                udfTextLabel3: this.loanOdAccounts.udfTextLabel3,
                udfTextLabel4: this.loanOdAccounts.udfTextLabel4,
                udfTextLabel5: this.loanOdAccounts.udfTextLabel5,
                udfTextLabel6: this.loanOdAccounts.udfTextLabel6,
                udfTextLabel7: this.loanOdAccounts.udfTextLabel7,
                udfTextLabel8: this.loanOdAccounts.udfTextLabel8,
                udfTextLabel9: this.loanOdAccounts.udfTextLabel9,
                udfTextLabel10: this.loanOdAccounts.udfTextLabel10,
                udfTextLabel11: this.loanOdAccounts.udfTextLabel11,
                udfTextLabel12: this.loanOdAccounts.udfTextLabel12,
                udfTextLabel13: this.loanOdAccounts.udfTextLabel13,
                udfTextLabel14: this.loanOdAccounts.udfTextLabel14,
                udfTextLabel15: this.loanOdAccounts.udfTextLabel15,
                udfTextLabel16: this.loanOdAccounts.udfTextLabel16,
                udfTextLabel17: this.loanOdAccounts.udfTextLabel17,
                udfTextLabel18: this.loanOdAccounts.udfTextLabel18,
                udfTextLabel19: this.loanOdAccounts.udfTextLabel19,
                udfTextLabel20: this.loanOdAccounts.udfTextLabel20,
                udfText1: this.loanOdAccounts.udfText1,
                udfText2: this.loanOdAccounts.udfText2,
                udfText3: this.loanOdAccounts.udfText3,
                udfText4: this.loanOdAccounts.udfText4,
                udfText5: this.loanOdAccounts.udfText5,
                udfText6: this.loanOdAccounts.udfText6,
                udfText7: this.loanOdAccounts.udfText7,
                udfText8: this.loanOdAccounts.udfText8,
                udfText9: this.loanOdAccounts.udfText9,
                udfText10: this.loanOdAccounts.udfText10,
                udfText11: this.loanOdAccounts.udfText11,
                udfText12: this.loanOdAccounts.udfText12,
                udfText13: this.loanOdAccounts.udfText13,
                udfText14: this.loanOdAccounts.udfText14,
                udfText15: this.loanOdAccounts.udfText15,
                udfText16: this.loanOdAccounts.udfText16,
                udfText17: this.loanOdAccounts.udfText17,
                udfText18: this.loanOdAccounts.udfText18,
                udfText19: this.loanOdAccounts.udfText19,
                udfText20: this.loanOdAccounts.udfText20,
                udfDateLabel1: this.loanOdAccounts.udfDateLabel1,
                udfDateLabel2: this.loanOdAccounts.udfDateLabel2,
                udfDate1: this.loanOdAccounts.udfDate1,
                udfDate2: this.loanOdAccounts.udfDate2
            }),
        });
        if (this.loanOdAccounts.operativeAccountId) {
            this.loanForm.get('terms').get('operativeAccountId').setValidators(Validators.required);
        }
        if (this.loanOdAccounts.normalInterestRate) {
            this.loanForm.get('terms').get('normalInterestRateMagnitude').setValue(this.loanOdAccounts.normalInterestRate.magnitude);
            this.loanForm.get('terms').get('normalInterestRateMagnitude').setValidators(Validators.required);
        }
        if (this.loanOdAccounts.nominalInterestRate) {
            this.loanForm.get('terms').get('nominalInterestRateMagnitude').setValue(this.loanOdAccounts.nominalInterestRate.magnitude);
        }
        if (this.loanOdAccounts.userSecurityDeposit) {
            this.loanForm.get('terms').get('userSecurityDepositMagnitude').setValue(this.loanOdAccounts.userSecurityDeposit.magnitude);
        }
        if (this.loanOdAccounts.customerId2) {
            const customer2 = this.loanForm.get('customer').get('customer2');
            customer2.setValue({
                customerId2: this.loanOdAccounts.customerId2,
                firstName: this.loanOdAccounts.customerName2.firstName,
                middleName: this.loanOdAccounts.customerName2.middleName,
                lastName: this.loanOdAccounts.customerName2.lastName,
                address1: this.loanOdAccounts.contact2.address1,
                address2: this.loanOdAccounts.contact2.address2,
                address3: this.loanOdAccounts.contact2.address3,
                countryCode: this.loanOdAccounts.contact2.countryCode,
                stateCode: this.loanOdAccounts.contact2.stateCode,
                cityCode: this.loanOdAccounts.contact2.cityCode,
                districtCode: this.loanOdAccounts.contact2.districtCode,
                pinCode: this.loanOdAccounts.contact2.pinCode,
                phone1: this.loanOdAccounts.contact2.phone1,
                email: this.loanOdAccounts.contact2.email
            });
            customer2.get('firstName').setValidators(Validators.required);
            customer2.get('countryCode').setValidators(Validators.required);
            customer2.get('stateCode').setValidators(Validators.required);
            customer2.get('cityCode').setValidators(Validators.required);
            customer2.get('pinCode').setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]);
            customer2.get('email').setValidators(CustomValidators.emailOrEmpty);
        }

        if (this.loanOdAccounts.customerId3) {
            const customer3 = this.loanForm.get('customer').get('customer3');
            customer3.setValue({
                customerId3: this.loanOdAccounts.customerId3,
                firstName: this.loanOdAccounts.customerName3.firstName,
                middleName: this.loanOdAccounts.customerName3.middleName,
                lastName: this.loanOdAccounts.customerName3.lastName,
                address1: this.loanOdAccounts.contact3.address1,
                address2: this.loanOdAccounts.contact3.address2,
                address3: this.loanOdAccounts.contact3.address3,
                countryCode: this.loanOdAccounts.contact3.countryCode,
                stateCode: this.loanOdAccounts.contact3.stateCode,
                cityCode: this.loanOdAccounts.contact3.cityCode,
                districtCode: this.loanOdAccounts.contact3.districtCode,
                pinCode: this.loanOdAccounts.contact3.pinCode,
                phone1: this.loanOdAccounts.contact3.phone1,
                email: this.loanOdAccounts.contact3.email
            });
            customer3.get('firstName').setValidators(Validators.required);
            customer3.get('countryCode').setValidators(Validators.required);
            customer3.get('stateCode').setValidators(Validators.required);
            customer3.get('cityCode').setValidators(Validators.required);
            customer3.get('pinCode').setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]);
            customer3.get('email').setValidators(CustomValidators.emailOrEmpty);
        }

        if (this.loanOdAccounts.customerId4) {
            const customer4 = this.loanForm.get('customer').get('customer4');
            customer4.setValue({
                customerId4: this.loanOdAccounts.customerId4,
                firstName: this.loanOdAccounts.customerName4.firstName,
                middleName: this.loanOdAccounts.customerName4.middleName,
                lastName: this.loanOdAccounts.customerName4.lastName,
                address1: this.loanOdAccounts.contact4.address1,
                address2: this.loanOdAccounts.contact4.address2,
                address3: this.loanOdAccounts.contact4.address3,
                countryCode: this.loanOdAccounts.contact4.countryCode,
                stateCode: this.loanOdAccounts.contact4.stateCode,
                cityCode: this.loanOdAccounts.contact4.cityCode,
                districtCode: this.loanOdAccounts.contact4.districtCode,
                pinCode: this.loanOdAccounts.contact4.pinCode,
                phone1: this.loanOdAccounts.contact4.phone1,
                email: this.loanOdAccounts.contact4.email
            });
            customer4.get('firstName').setValidators(Validators.required);
            customer4.get('countryCode').setValidators(Validators.required);
            customer4.get('stateCode').setValidators(Validators.required);
            customer4.get('cityCode').setValidators(Validators.required);
            customer4.get('pinCode').setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]);
            customer4.get('email').setValidators(CustomValidators.emailOrEmpty);
        }
        if (this.loanOdAccounts.customerId5) {
            const customer5 = this.loanForm.get('customer').get('customer5');
            customer5.setValue({
                customerId5: this.loanOdAccounts.customerId5,
                firstName: this.loanOdAccounts.customerName5.firstName,
                middleName: this.loanOdAccounts.customerName5.middleName,
                lastName: this.loanOdAccounts.customerName5.lastName,
                address1: this.loanOdAccounts.contact5.address1,
                address2: this.loanOdAccounts.contact5.address2,
                address3: this.loanOdAccounts.contact5.address3,
                countryCode: this.loanOdAccounts.contact5.countryCode,
                stateCode: this.loanOdAccounts.contact5.stateCode,
                cityCode: this.loanOdAccounts.contact5.cityCode,
                districtCode: this.loanOdAccounts.contact5.districtCode,
                pinCode: this.loanOdAccounts.contact5.pinCode,
                phone1: this.loanOdAccounts.contact5.phone1,
                email: this.loanOdAccounts.contact5.email
            });
            customer5.get('firstName').setValidators(Validators.required);
            customer5.get('countryCode').setValidators(Validators.required);
            customer5.get('stateCode').setValidators(Validators.required);
            customer5.get('cityCode').setValidators(Validators.required);
            customer5.get('pinCode').setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]);
            customer5.get('email').setValidators(CustomValidators.emailOrEmpty);
        }

        if (this.loanOdAccounts.customerId6) {
            const customer6 = this.loanForm.get('customer').get('customer6');
            customer6.setValue({
                customerId6: this.loanOdAccounts.customerId6,
                firstName: this.loanOdAccounts.customerName6.firstName,
                middleName: this.loanOdAccounts.customerName6.middleName,
                lastName: this.loanOdAccounts.customerName6.lastName,
                address1: this.loanOdAccounts.contact6.address1,
                address2: this.loanOdAccounts.contact6.address2,
                address3: this.loanOdAccounts.contact6.address3,
                countryCode: this.loanOdAccounts.contact6.countryCode,
                stateCode: this.loanOdAccounts.contact6.stateCode,
                cityCode: this.loanOdAccounts.contact6.cityCode,
                districtCode: this.loanOdAccounts.contact6.districtCode,
                pinCode: this.loanOdAccounts.contact6.pinCode,
                phone1: this.loanOdAccounts.contact6.phone1,
                email: this.loanOdAccounts.contact6.email
            });
            customer6.get('firstName').setValidators(Validators.required);
            customer6.get('countryCode').setValidators(Validators.required);
            customer6.get('stateCode').setValidators(Validators.required);
            customer6.get('cityCode').setValidators(Validators.required);
            customer6.get('pinCode').setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]);
            customer6.get('email').setValidators(CustomValidators.emailOrEmpty);
        }
        this.onFormChanges();
    }

    private prepareSave(loanForm, loanOdAccounts) {

        const basicForm = loanForm.get('basic');
        const basicFormControls = basicForm.controls;
        Object.keys(basicFormControls).forEach((key) => {
            loanOdAccounts[key] = basicForm.get(key).value;
        });

        const termsForm = loanForm.get('terms');
        loanOdAccounts.tenure.magnitude = termsForm.get('tenureMagnitude').value;
        loanOdAccounts.tenure.magnitudeAndUnit = `${loanOdAccounts.tenure.magnitude} ${loanOdAccounts.tenure.unit}`;
        loanOdAccounts.amount.magnitude = termsForm.get('amountMagnitude').value;
        loanOdAccounts.operativeAccountId = termsForm.get('operativeAccountId').value;
        loanOdAccounts.openedOnDate = termsForm.get('openedOnDate').value;
        loanOdAccounts.firstRepaymentDate = termsForm.get('firstRepaymentDate').value;
        if (this.loanOdAccounts.normalInterestRate) {
            loanOdAccounts.normalInterestRate.magnitude = termsForm.get('normalInterestRateMagnitude').value;
        }
        if (this.loanOdAccounts.nominalInterestRate) {
            loanOdAccounts.nominalInterestRate.magnitude = termsForm.get('nominalInterestRateMagnitude').value;
        }
        loanOdAccounts.equatedInstallment.magnitude = termsForm.get('equatedInstallmentMagnitude').value;

        const customer1Form = loanForm.get('customer').get('customer1');
        const customer1FormControls = customer1Form.controls;
        Object.keys(customer1FormControls).forEach((key) => {
            if (key === 'customerId1') {
                loanOdAccounts[key] = customer1Form.get(key).value;
            } else if (key === 'firstName' || key === 'middleName' || key === 'lastName') {
                loanOdAccounts.customerName1[key] = customer1Form.get(key).value;
            } else {
                loanOdAccounts.contact1[key] = customer1Form.get(key).value;
            }
        });

        if (this.loanOdAccounts.customerId2) {
            const customer2Form = loanForm.get('customer').get('customer2');
            const customer2FormControls = customer2Form.controls;
            Object.keys(customer2FormControls).forEach((key) => {
                if (key === 'customerId2') {
                    loanOdAccounts[key] = customer2Form.get(key).value;
                } else if (key === 'firstName' || key === 'middleName' || key === 'lastName') {
                    loanOdAccounts.customerName2[key] = customer2Form.get(key).value;
                } else {
                    loanOdAccounts.contact2[key] = customer2Form.get(key).value;
                }
            });
        }

        if (this.loanOdAccounts.customerId3) {
            const customer3Form = loanForm.get('customer').get('customer3');
            const customer3FormControls = customer3Form.controls;
            Object.keys(customer3FormControls).forEach((key) => {
                if (key === 'customerId3') {
                    loanOdAccounts[key] = customer3Form.get(key).value;
                } else if (key === 'firstName' || key === 'middleName' || key === 'lastName') {
                    loanOdAccounts.customerName3[key] = customer3Form.get(key).value;
                } else {
                    loanOdAccounts.contact3[key] = customer3Form.get(key).value;
                }
            });
        }

        if (this.loanOdAccounts.customerId4) {
            const customer4Form = loanForm.get('customer').get('customer4');
            const customer4FormControls = customer4Form.controls;
            Object.keys(customer4FormControls).forEach((key) => {
                if (key === 'customerId4') {
                    loanOdAccounts[key] = customer4Form.get(key).value;
                } else if (key === 'firstName' || key === 'middleName' || key === 'lastName') {
                    loanOdAccounts.customerName4[key] = customer4Form.get(key).value;
                } else {
                    loanOdAccounts.contact4[key] = customer4Form.get(key).value;
                }
            });
        }
        if (this.loanOdAccounts.customerId5) {
            const customer5Form = loanForm.get('customer').get('customer5');
            const customer5FormControls = customer5Form.controls;
            Object.keys(customer5FormControls).forEach((key) => {
                if (key === 'customerId5') {
                    loanOdAccounts[key] = customer5Form.get(key).value;
                } else if (key === 'firstName' || key === 'middleName' || key === 'lastName') {
                    loanOdAccounts.customerName5[key] = customer5Form.get(key).value;
                } else {
                    loanOdAccounts.contact5[key] = customer5Form.get(key).value;
                }
            });
        }
        if (this.loanOdAccounts.customerId6) {
            const customer6Form = loanForm.get('customer').get('customer6');
            const customer6FormControls = customer6Form.controls;
            Object.keys(customer6FormControls).forEach((key) => {
                if (key === 'customerId6') {
                    loanOdAccounts[key] = customer6Form.get(key).value;
                } else if (key === 'firstName' || key === 'middleName' || key === 'lastName') {
                    loanOdAccounts.customerName6[key] = customer6Form.get(key).value;
                } else {
                    loanOdAccounts.contact6[key] = customer6Form.get(key).value;
                }
            });
        }
        const udfForm = loanForm.get('udf');
        const udfFormControls = udfForm.controls;
        Object.keys(udfFormControls).forEach((key) => {
            loanOdAccounts[key] = udfForm.get(key).value;
        });
    }

    private getUdfData(udfForm) {
        const udfFormControls = udfForm.controls;
        Object.keys(udfFormControls).forEach((key) => {
            if (this.loanOdAccounts[key] && key.charAt(7) !== 'L' && key.charAt(3) !== 'D') {
                let index: number;
                if (key.length === 9) {
                    const tempIndex: string = key.charAt(key.length - 2) + key.charAt(key.length - 1);
                    const tempIndex2: number = +tempIndex;
                    index = tempIndex2 - 1;
                } else {
                    const tempIndex = +key.charAt(key.length - 1);
                    index = tempIndex - 1;
                }
                this.loanOdAccountsService.getEnumeration(this.loanOdAccounts[key]).subscribe(
                    (result) => this.classifierValues[index] = result);
            }
        });
        this.udfDataIsAvailable = true;
    }

    private onFormChanges(): void {
        this.valueChangeBasic = this.loanForm.get('basic').valueChanges.subscribe((value) => {
            this.basicTabIsInvalid = this.loanForm.get('basic').invalid && (this.loanForm.get('basic').dirty || this.loanForm.get('basic').touched);
        });
        this.valueChangeTerms = this.loanForm.get('terms').valueChanges.subscribe((value) => {
            this.termsTabIsInvalid = this.loanForm.get('terms').invalid && (this.loanForm.get('terms').dirty || this.loanForm.get('terms').touched);
        });
        this.valueChangeCustomer = this.loanForm.get('customer').valueChanges.subscribe((value) => {
            this.customerTabIsInvalid = this.loanForm.get('customer').invalid && (this.loanForm.get('customer').dirty || this.loanForm.get('customer').touched);
        });
        if (this.loanOdAccounts.udfTextLabel1) {
            this.valueChangeUdf = this.loanForm.get('udf').valueChanges.subscribe((value) => {
                this.udfTabIsInvalid = this.loanForm.get('udf').invalid && (this.loanForm.get('udf').dirty || this.loanForm.get('udf').touched);
            });
        }
        const customer1 = this.loanForm.get('customer').get('customer1');
        const customer2 = this.loanForm.get('customer').get('customer2');
        const customer3 = this.loanForm.get('customer').get('customer3');
        const customer4 = this.loanForm.get('customer').get('customer4');
        const customer5 = this.loanForm.get('customer').get('customer5');
        const customer6 = this.loanForm.get('customer').get('customer6');

        this.valueChangeCountry1 = customer1.get('countryCode').valueChanges.subscribe((countryCode) => {
            customer1.get('stateCode').setValue(null);
            customer1.get('cityCode').setValue(null);
            customer1.get('districtCode').setValue(null);
            this.fetchStates(countryCode, 0);
        });
        this.valueChangeState1 = customer1.get('stateCode').valueChanges.subscribe((stateCode) => {
            customer1.get('cityCode').setValue(null);
            customer1.get('districtCode').setValue(null);
            this.fetchCities(customer1.get('countryCode').value, stateCode, 0);
        });

        this.valueChangeCountry2 = customer2.get('countryCode').valueChanges.subscribe((countryCode) => {
            customer2.get('stateCode').setValue(null);
            customer2.get('cityCode').setValue(null);
            customer2.get('districtCode').setValue(null);
            this.fetchStates(countryCode, 1);
        });
        this.valueChangeState2 = customer2.get('stateCode').valueChanges.subscribe((stateCode) => {
            customer2.get('cityCode').setValue(null);
            customer2.get('districtCode').setValue(null);
            this.fetchCities(customer2.get('countryCode').value, stateCode, 1);
        });

        this.valueChangeCountry3 = customer3.get('countryCode').valueChanges.subscribe((countryCode) => {
            customer3.get('stateCode').setValue(null);
            customer3.get('cityCode').setValue(null);
            customer3.get('districtCode').setValue(null);
            this.fetchStates(countryCode, 2);
        });
        this.valueChangeState3 = customer3.get('stateCode').valueChanges.subscribe((stateCode) => {
            customer3.get('cityCode').setValue(null);
            customer3.get('districtCode').setValue(null);
            this.fetchCities(customer3.get('countryCode').value, stateCode, 2);
        });

        this.valueChangeCountry4 = customer4.get('countryCode').valueChanges.subscribe((countryCode) => {
            customer4.get('stateCode').setValue(null);
            customer4.get('cityCode').setValue(null);
            customer4.get('districtCode').setValue(null);
            this.fetchStates(countryCode, 3);
        });
        this.valueChangeState4 = customer4.get('stateCode').valueChanges.subscribe((stateCode) => {
            customer4.get('cityCode').setValue(null);
            customer4.get('districtCode').setValue(null);
            this.fetchCities(customer4.get('countryCode').value, stateCode, 3);
        });

        this.valueChangeCountry5 = customer5.get('countryCode').valueChanges.subscribe((countryCode) => {
            customer5.get('stateCode').setValue(null);
            customer5.get('cityCode').setValue(null);
            customer5.get('districtCode').setValue(null);
            this.fetchStates(countryCode, 4);
        });
        this.valueChangeState5 = customer5.get('stateCode').valueChanges.subscribe((stateCode) => {
            customer5.get('cityCode').setValue(null);
            customer5.get('districtCode').setValue(null);
            this.fetchCities(customer5.get('countryCode').value, stateCode, 4);
        });

        this.valueChangeCountry6 = customer6.get('countryCode').valueChanges.subscribe((countryCode) => {
            customer6.get('stateCode').setValue(null);
            customer6.get('cityCode').setValue(null);
            customer6.get('districtCode').setValue(null);
            this.fetchStates(countryCode, 5);
        });
        this.valueChangeState6 = customer6.get('stateCode').valueChanges.subscribe((stateCode) => {
            customer6.get('cityCode').setValue(null);
            customer6.get('districtCode').setValue(null);
            this.fetchCities(customer6.get('countryCode').value, stateCode, 5);
        });
    }

    ngOnDestroy() {
        if (this.proceedIsTrue) {
            this.valueChangeBasic.unsubscribe();
            this.valueChangeTerms.unsubscribe();
            this.valueChangeCustomer.unsubscribe();
            if (this.loanOdAccounts.udfTextLabel1) {
                this.valueChangeUdf.unsubscribe();
            }
            this.valueChangeCountry1.unsubscribe();
            this.valueChangeCountry2.unsubscribe();
            this.valueChangeCountry3.unsubscribe();
            this.valueChangeCountry4.unsubscribe();
            this.valueChangeCountry5.unsubscribe();
            this.valueChangeCountry6.unsubscribe();

            this.valueChangeState1.unsubscribe();
            this.valueChangeState2.unsubscribe();
            this.valueChangeState3.unsubscribe();
            this.valueChangeState4.unsubscribe();
            this.valueChangeState5.unsubscribe();
            this.valueChangeState6.unsubscribe();
        }
    }

    clear() {
        this.loanOdAccounts = new LoanOdAccounts();
        this.router.navigate(['/loan-od-accounts']);
    }

    save() {
        this.prepareSave(this.loanForm, this.loanOdAccounts);
        this.loanOdAccounts.openedOnDate = this.convertLocalDateToServer(this.loanOdAccounts.openedOnDate);
        this.loanOdAccounts.firstRepaymentDate = this.convertLocalDateToServer(this.loanOdAccounts.firstRepaymentDate);
        if (this.loanOdAccounts.udfDate1) {
            this.loanOdAccounts.udfDate1 = this.convertLocalDateToServer(this.loanOdAccounts.udfDate1);
        }
        if (this.loanOdAccounts.udfDate2) {
            this.loanOdAccounts.udfDate2 = this.convertLocalDateToServer(this.loanOdAccounts.udfDate2);
        }
        if (this.loanOdAccounts.moratoriumPeriod.magnitude === undefined) {
            this.loanOdAccounts.moratoriumPeriod.magnitude = 0;
        }
        if (this.loanOdAccounts.moratoriumPeriod.magnitude) {
            this.loanOdAccounts.moratoriumPeriod.magnitudeAndUnit = this.loanOdAccounts.moratoriumPeriod.magnitude
                + ' ' + this.loanOdAccounts.tenureUnit;
        }
        if (this.loanOdAccounts.id !== undefined && this.loanOdAccounts.id.toString() !== '') {
            this.loanOdAccountsService.update(this.loanOdAccounts)
                .subscribe((res: LoanOdAccounts) =>
                    this.onSaveSuccess(res), (res: Response) => this.onSaveError(res));
        } else {
            this.loanOdAccountsService.create(this.loanOdAccounts)
                .subscribe((res: LoanOdAccounts) =>
                    this.onSaveSuccess(res), (res: Response) => this.onSaveError(res));
        }
    }

    private onSaveSuccess(result: LoanOdAccounts) {
        this.eventManager.broadcast({ name: 'loanOdAccountsListModification', content: 'OK' });
        this.loanOdAccounts = result;
        this.alertService.success(this.role == 'Admin' ? 'Successfully saved Loan Account' : 'Loan Account went to Checker ' + this.loanOdAccounts.accountId);
        this.router.navigate(['/loans/loan-od-accounts/view/' + this.loanOdAccounts.accountId]);
    }

    private onFileSaveError(error) {
        try {
            error.json();
        } catch (exception) {
            error.message = error.text();
        }
        this.onError(error);
    }

    onChangemandateFileData(event: any) {
        this.mandateFileDataFile = event.target.files.item(0);
        this.purposeDict[this.mandateFileDataFile.name] = 'mandateFileDataId';
    }

    private onError(error) {
        this.alertService.error('An error occured while saving your document');
    }

    convertLocalDateToServer(date: any): String {
        if (date === null || date === undefined) {
            return null;
        }
        const newDate = new Date(date['year'], date['month'] - 1, date['day']);
        return this.datePipe.transform(newDate, 'yyyy-MM-dd');
    }
}
