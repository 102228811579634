import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {AfterViewInit, OnDestroy, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { ListSource } from '@ig-core/list/list-source';

import { RoleService } from '../role.service';
import {  RoleListDataSource } from './role-list.data.source';
import {  RoleDeleteDialogComponent } from './role-delete-dialog.component';

@Component({
    selector: 'app-role',
    templateUrl: 'role-list.component.html',
    styleUrls: ['../role.component.css']
})
export class  RoleListComponent implements OnInit, AfterViewInit, OnDestroy {

    pagingAndSortParams: PagingAndSortParams;
    dataSource:  RoleListDataSource;
    listSource: ListSource;

    displayedColumns = [      'name',    'code',    'authorities',  'action'];

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    constructor(private roleService: RoleService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private snackBar: MatSnackBar) { }



    ngOnInit() {
        this.dataSource = new RoleListDataSource(this.roleService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                // Load paging and sort params from url
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'id';
                }
                // Load other, params and queryParams
                this.dataSource.loadRoles(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
                    this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize);
            });
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    ngAfterViewInit(): void {
        // initiate sort and pagination change once the view is initialized
        this.listSource.subscribePaginationAndSortChange()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadRolesPageWithParams());
    }

    loadRolesPageWithParams() {
        this.router.navigate([], this.getQueryParams());
    }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    deleteDialog(roleData: any) {
        const dialogRef = this.dialog.open(RoleDeleteDialogComponent, {
            data: roleData
        });
      dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe((result) => {
        if (result && result === 'deleted') {
          this.snackBar.open(`Deleted role successfully`, 'Close');
          this.loadRolesPageWithParams();
        }
      });
    }

}
