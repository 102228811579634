import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AllPipesModule } from '@app/utils/pipes.module';
import { MaterialLibModule } from '@app/material-lib.module';
import { ZonesComponent } from '@app/zones/zones.component';
import { ZonesRoutingModule } from './zones-routing.module';
import { ROTripViewComponent } from './ro-trip-view/ro-trip-view.component';

@NgModule({
  imports: [
    ZonesRoutingModule,
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    AllPipesModule
  ],
  declarations: [ZonesComponent, ROTripViewComponent]
})
export class ZonesModule { }