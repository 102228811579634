import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';

import {Role} from '../role.model';
import {RoleService} from '../role.service';


export class RoleListDataSource extends  DataSource<Role> {

  private roleSubject = new BehaviorSubject<Role[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private roleService: RoleService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<Role[]> {
    return this.roleSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.roleSubject.complete();
    this.totalCount$.complete();
  }

  loadRoles(sortDirection = 'asc', pageIndex = 0, pageSize = 3) {

    this.roleService.findRoles({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.roleSubject.next(response['body']);
    });
  }

}
