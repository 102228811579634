/*
* @author Divyjyot Saraon
*/

import { Injectable } from '@angular/core';

@Injectable()
export class DateConverterService {

    constructor() { }

    /*
    * This method will convert the date 'January 25, 2018' as follows:
    *
    * Format to convert :   Array object '[2018, 1, 25]' where,
    *                           object[0] is the year,
    *                           object[1] is the month,
    *                           object[2] is the day of month,
    *
    * Format converted to : Javascript Date object
    *                       'Thu Jan 25 2018 00:00:00 GMT+0530 (IST)'
    *
    * The months from the server range from 1-12 whereas Javascript Date
    * objects have months ranging from 0-11
    */
    fromServerToDateObject(dateToConvert: any): Date {
        if (dateToConvert !== undefined && dateToConvert !== null) {
            return new Date(
                dateToConvert[0],
                dateToConvert[1] - 1,
                dateToConvert[2]
            );
        } else {
            return undefined;
        }
    }

    /*
    * This method will convert the date 'January 25, 2018  11:31 AM' as follows:
    *
    * Format to convert :   Array object '[2018, 1, 25]' where,
    *                           object[0] is the year,
    *                           object[1] is the month,
    *                           object[2] is the day of month,
    *                           object[3] is the hour of day,
    *                           object[4] is the minute of hour,
    *                           object[5] is the second of minute,
    *
    * Format converted to : Javascript Date object
    *                       'Thu Jan 25 2018 11:31:00 GMT+0530 (IST)'
    *
    * The months from the server range from 1-12 whereas Javascript Date
    * objects have months ranging from 0-11
    */
    fromServerToDateTimeObject(dateToConvert: any): Date {
        if (dateToConvert !== undefined && dateToConvert !== null) {
            return new Date(
                dateToConvert[0],
                dateToConvert[1] - 1,
                dateToConvert[2],
                dateToConvert[3],
                dateToConvert[4],
                dateToConvert[5]
            );
        } else {
            return undefined;
        }
    }

    /*
    * This method will convert the date 'January 25, 2018' as follows:
    *
    * Format to convert :   Javascript Date object
    *                       'Thu Jan 25 2018 00:00:00 GMT+0530 (IST)'
    *
    * Format converted to : Object 'date' where,
    *                       date = {
    *                               year: 2018,
    *                               month: 01,
    *                               day: 25,
    *                              }
    *
    * The months for NgbDatePicker range from 1-12 whereas Javascript Date
    * objects have months ranging from 0-11
    */
    fromDateObjectToNgbDatePicker(dateToConvert: any): any {
        if (dateToConvert !== null && dateToConvert !== undefined) {
            return {
                year: dateToConvert.getFullYear(),
                month: dateToConvert.getMonth() + 1,
                day: dateToConvert.getDate()
            }
        }else {
            return null;
        }
    }

    /*
    * This method will convert the date 'January 25, 2018' as follows:
    *
    * Format to convert :   '2018-01-25'
    *
    * Format converted to : Object 'date' where,
    *                       date = {
    *                               year: 2018,
    *                               month: 01,
    *                               day: 25,
    *                              }
    *
    */
    fromStringToNgbDatePicker(dateToConvert: any): any {
        if (dateToConvert !== null && dateToConvert !== undefined) {
            const split = dateToConvert.split('-');
            return {
                year : parseInt(split[0]),
                month : parseInt(split[1]),
                day : parseInt(split[2])
            }
        }else {
            return null;
        }
    }

    /*
    * This method will convert the date 'January 25, 2018' as follows:
    *
    * Format to convert :   Object 'date' where,
    *                       date = {
    *                               year: 2018,
    *                               month: 1,
    *                               day: 25,
    *                              }
    *
    * Format converted to : Javascript Date object
    *                       'Thu Jan 25 2018 00:00:00 GMT+0530 (IST)'
    *
    * The months for NgbDatePicker range from 1-12 whereas Javascript Date
    * objects have months ranging from 0-11
    */
    fromNgbDatePickerToDateObject(dateToConvert: any): Date {
        if (dateToConvert !== undefined && dateToConvert !== null) {
            return new Date(
                dateToConvert.year,
                dateToConvert.month - 1,
                dateToConvert.day
            );
        } else {
            return undefined;
        }
    }

    /*
    * This method will convert the date 'January 25, 2018' as follows:
    *
    * Format to convert :   Array object '[2018, 1, 25]' where,
    *                           object[0] is the year,
    *                           object[1] is the month,
    *                           object[2] is the day of month,
    *
    * Format converted to : Object 'date' where,
    *                       date = {
    *                               year: 2018,
    *                               month: 1,
    *                               day: 25,
    *                              }
    */
    fromServerToNgbDatePicker(dateToConvert: any): any {
        if (dateToConvert !== undefined && dateToConvert !== null) {
            return {
                year : dateToConvert[0],
                month : dateToConvert[1],
                day : dateToConvert[2],
            };
        } else {
            return null;
        }
    }

    fromNgbDatePickerToString(dateToConvert: any): any {
        if (dateToConvert !== undefined && dateToConvert !== null) {
            let day = dateToConvert.day;
            if (day < 10) {
                day = '0' + day;
            }
            let month = dateToConvert.month;
            if (month < 10) {
                month = '0' + month;
            }
            return dateToConvert.year + '-' + month + '-' + day;
        } else {
            return null;
        }
    }

    fromDateObjectToServer(dateToConvert: any): any {
        if (dateToConvert !== undefined && dateToConvert !== null) {
            let day = dateToConvert.getDate();
            if (day < 10) {
                day = '0' + day;
            }
            let month = dateToConvert.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            return dateToConvert.getFullYear() + '-'
                    + month + '-'
                    +  day;
        } else {
            return null;
        }
    }

}
