import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication} from '@app/applications/applications.model';
import { ApplicationService } from '../../../application.service';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { MatSnackBar } from '@angular/material';
import { ActionItemService } from '@app/applications/action-item.service';
import { ActionItem } from '../../action-item.model';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
import { Task } from '@app/tasks/task.model';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
@Component({
  selector: 'eng-action-items',
  templateUrl: './action-items.template.html',
  styleUrls: ['../../application-details.styles.scss', './action-item.styles.css']
})
export class ActionItemsComponent implements OnInit {
  @Input()
  application: LoanApplication;

  myActionItemForm: FormGroup
  viewOnlyActionItemForm: FormGroup

  taskStatusList : ReferenceCode[] = [];
  sortStatusList: ReferenceCode[] = [];
  myActionItems: ActionItem[];
  viewOnlyActionItems: ActionItem[];
  localPrestine: any;
  userRoles: any;

  isFormDisabled: boolean;
  trailLabel:string='HIDE TRAIL';
  showTrail:boolean=true;
  //this variable is used not to show previous dates in due date and revised due date
  minDate: Date;
  statusCodes: any;
  
  constructor(private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    private referenceCodeService: ReferenceCodeService,
    private actionItemService: ActionItemService) { 
    this.minDate = new Date();
    }

  ngOnInit() {
    this.getStatus()
    this.getActionItems();
    this.getAssignRoles();
  }

  getStatus(){
    this.referenceCodeService.getRefCodesForClassifier('task_status').subscribe((response: any) => {
      this.taskStatusList = response;
  })

  this.referenceCodeService.getRefCodesForClassifier('settings').subscribe((response: any) => {
    this.sortStatusList = response;
    this.statusCodes = []
    this.sortStatusList.forEach(element => {
      if(element.code == "ACTION-ITEM-STATUS-SORT"){
        if (element.field1 !== null){
        this.statusCodes = element.field1;
        this.statusCodes = this.statusCodes.split(',');
        }
      }
    })
  })
}

  // this will fetch actionItems present for particular application
  getActionItems(){
    this.actionItemService.getActionItem(this.application.uid).subscribe(response =>{
      this.myActionItems = response.body.myActionItems;
      this.viewOnlyActionItems = response.body.viewOnlyActionItems;
      this.localPrestine = JSON.parse(JSON.stringify(this.myActionItems));
      // For both the form we are using same formArrayList, so we need to initilize to default value before building the form 
      this.initilizeFormArrayList();
      // there are two forms myActionItemForm and viewOnlyActionItemForm, actionItems in myActionItemForm form has access to edit
      // and actionItems in viewOnlyActionItemForm does not have access to edit
      this.buildActionItem(this.myActionItemForm, this.myActionItems);
      this.buildActionItem(this.viewOnlyActionItemForm, this.viewOnlyActionItems);
    })
  }

  getAssignRoles(){
    this.actionItemService.getAssignRolesToActionItems(this.application.uid).subscribe(response => {
      this.userRoles = response;
      this.userRoles.sort((a, b) => a.name.localeCompare(b.name));
    })
  }

  buildActionItem(actionItemFormGroup: FormGroup, actionItems: ActionItem[]){
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const array = actionItemFormGroup.controls.actionItemList as FormArray;
    let statusMap = new Map();
    this.taskStatusList.forEach(taskStatus => statusMap.set(taskStatus.code, taskStatus));
    actionItems.forEach(element => {
      let plannedDueDate = element.task.revisedDueDate ? element.task.revisedDueDate : element.task.plannedDueDate;
      let status = element.task.status
      // isPastDueDate is to show the date in red clour if the date is previous date and status
      // here "CNCLD" is code for canceld status and "COMP" is code for completed status
      // we are adding new Date() because if date is null then it will set the date as some default date, hence now planedDueDate will be string and when we do new Date() it will convert to date format
      let isPastDueDate = new Date(plannedDueDate) < currentDate && !this.statusCodes.includes(status);

      // this selectedActionItem is used to show the icon name for mat-icon. In field1 we there will be icon name for code.
      // if there is value in field1 icon name will be shown else icon name will be empty
      const selectedActinItem = statusMap.get(status);
  
        // this statusIconName is to identify which icon to show for respective status
      let statusIconName = selectedActinItem ? selectedActinItem.field1 : '';

      let actionItemForm = new FormGroup({
        remarks: new FormControl(''),
        reAssignToRole: new FormControl(''),
        rescheduleDate: new FormControl  (''),
        description: new FormControl(element.task.description),
        assignedTo: new FormControl(element.task.assignedTo),
        plannedDueDate: new FormControl(plannedDueDate),
        actionItemStatus: new FormControl(status),

        notes: new FormArray(element.note ? element.note.map(data => new FormControl(data)) : []),

        //this is used only to show date in red we are using showDate
        showDate: new FormControl(isPastDueDate),

        // this showStatus is used to show the icon name for respective status
        showStatus: new FormControl(statusIconName)

      })
      array.push(actionItemForm);
      actionItemFormGroup.disable();
    })    
    this.isFormDisabled = true;
  }

  initilizeFormArrayList(){
    this.myActionItemForm = this.formBuilder.group({
      actionItemList: this.formBuilder.array([])
    })
    this.viewOnlyActionItemForm = this.formBuilder.group({
      actionItemList: this.formBuilder.array([])
    })
  }

  enableDisableForm(){
    this.myActionItemForm.enable()
    this.isFormDisabled = false;
  }

  cancelForm(){
    this.initilizeFormArrayList();
    this.buildActionItem(this.myActionItemForm, this.myActionItems);
    this.buildActionItem(this.viewOnlyActionItemForm, this.viewOnlyActionItems);
    this.myActionItemForm.disable();
    this.isFormDisabled = true;
  }

  // on click on save this function will be called 
  updateActionItem() {
    if(!this.isFormDisabled && this.myActionItems.length != 0){
    this.myActionItems.forEach((element, index) => {
      element.remarks = this.myActionItemForm.value.actionItemList[index].remarks;
      if(this.myActionItemForm.value.actionItemList[index].reAssignToRole){
      element.task.assignedTo = this.myActionItemForm.value.actionItemList[index].reAssignToRole
      }
      if(this.myActionItemForm.value.actionItemList[index].rescheduleDate){
        element.task.revisedDueDate = this.myActionItemForm.value.actionItemList[index].rescheduleDate
      }
      element.task.status = this.myActionItemForm.value.actionItemList[index].actionItemStatus

      element.note = []
    });

      this.actionItemService.updateActionItems(this.application.uid, this.myActionItems).subscribe(response =>{
        // after getting the response we are not updating the actionItems with the response, because in response if reassign role is assigned to some other role
        // then it will show other role assigned actionItem for this application. If we call getActionItems then it will fetch records for this particular application
        let message:string = '';
        if(response.body.message){
        message = response.body.message;
        }
        this._snackbar.open("Updated action items successfully " + message , "close", {
          duration: 5000
        });
        // here we are calling getActionItems, this is done because in response if the role is assigned to some other role
        // then it will show other role assigned actionItem for this application. If we call getActionItems then it will fetch records for this particular application
        this.getActionItems();
      },(error)=>{
        this._snackbar.open("Update action failed, " + error , "close", {
          duration: 5000
        });
        this.myActionItems = JSON.parse(JSON.stringify(this.localPrestine));
      })
    }
}

hideOrShowTrailfunction(){
  this.showTrail=!this.showTrail
  if(this.showTrail){
    this.trailLabel='HIDE TRAIL'
  }else{
    this.trailLabel='SHOW TRAIL'
  }
 
}

}
