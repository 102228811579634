import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {LoanOdAccountsService} from './loan-od-accounts.service';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// import { Observable } from 'rxjs';
import { AlertService } from 'ng-jhipster';
import { LoanOdAmendment} from './loan-od-amendment.model';

@Injectable()
export class LoanOdAccountsAmendOpenDateResolve implements Resolve<LoanOdAmendment> {

    accountId: string;

    constructor(private loanOdAccountsService: LoanOdAccountsService,
        private alertService: AlertService,
        private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId')) {
            this.accountId = route.paramMap.get('accountId');
            return this.loanOdAccountsService.amendAccountOpenDateEntry(route.paramMap.get('accountId')).pipe(map(
                // tslint:disable-next-line:arrow-return-shorthand
                (res) =>  { return res.json(); }
            )).pipe(catchError(
                (err) => {
                    this.onSaveError(err);
                    return of(null);
                }
            ));
        } else {
            return new LoanOdAmendment();
        }
    }

    private onSaveError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
                this.onError();
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError();
                }
            } catch (exception) {
                this.onError();
            }
        }
        this.router.navigate(['/loans/loan-od-accounts/amendAmount', this.accountId]);
    }

    private onError() {
        this.alertService.error('encoreclientApp.loanOdAccounts.saveError');
        this.router.navigate(['/loans/loan-od-accounts/amendAmount', this.accountId]);
    }
}
