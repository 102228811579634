import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { BusinessIncomeSummary, BusinessIncomeType, BusinessIncomeDetail, BusinessIncome } from '../financials.model';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ValidationService } from '../../../application-validators/validation.service'
@Component({
  selector: 'eng-sales-bills',
  templateUrl: 'sales-bills.template.html',
  styleUrls: ['../../application-details.styles.scss', 'sales-bills.styles.css']
})
export class SalesBillsComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;

  salesBills: BusinessIncomeSummary;
  salesBillsWithGst: BusinessIncomeType = new BusinessIncomeType();
  salesBillsWithoutGst: BusinessIncomeType = new BusinessIncomeType();
  recentMonths: string[];
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  salesBillsForm: FormGroup;

  allowAccess:Boolean; 
  showForm: boolean;
  isFormDisabled: boolean;
  cfaFinalized:boolean;
  menuItemAllowAccess:boolean;
  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private validationService: ValidationService) {
      this.allowAccess = this.applicationService.allowAccess; 
  }

  ngOnInit() {
    this.salesBillsForm = this.formBuilder.group({
      salesSummaryForm: this.formBuilder.group({}),
      monthlySalesForm: this.formBuilder.array([])
    })
    this.isFormDisabled = true;
    this.getCfaStatus()
    this.fetchSalesBills();
    this.getMenuItemAccess();
  }

  fetchSalesBills() {
    this.showForm = false;
    this.applicationService.getBusinessFinancials(this.application.uid, 'salesbill')
      .subscribe((response: any) => {
        if (response) {
          this.salesBills = response;
          this.showForm = (this.salesBills.businessIncome.length > 0
            && this.salesBills.businessIncomeDetail.length > 0);
          if (this.showForm) this.getSeparateWithAndWithoutGstSalesBillsSummary();
          if (this.showForm) this.fetchAssessmentYearAndMonths();
        }
      });
  }

  getSeparateWithAndWithoutGstSalesBillsSummary() {
    this.salesBillsWithGst.businessIncome =
      this.salesBills.businessIncome.find(
        businessIncome => businessIncome.incomeDocumentType === 'SALES_GST');
    this.salesBillsWithGst.businessIncomeDetail =
      this.salesBills.businessIncomeDetail.find(
        businessIncomeDetail => businessIncomeDetail.incomeDocumentType === 'SALES_GST');
    this.salesBillsWithoutGst.businessIncome =
      this.salesBills.businessIncome.find(
        businessIncome => businessIncome.incomeDocumentType === 'SALES_WO_GST');
    this.salesBillsWithoutGst.businessIncomeDetail =
      this.salesBills.businessIncomeDetail.find(
        businessIncomeDetail => businessIncomeDetail.incomeDocumentType === 'SALES_WO_GST');
    this.showForm = (this.salesBillsWithGst.businessIncome !== undefined &&
      this.salesBillsWithGst.businessIncomeDetail !== undefined &&
      this.salesBillsWithoutGst.businessIncome !== undefined &&
      this.salesBillsWithoutGst.businessIncomeDetail !== undefined);
  }

  fetchAssessmentYearAndMonths(salesBillsWithGst ?: BusinessIncomeType,
      salesBillsWithoutGst ?: BusinessIncomeType) {
    if(!salesBillsWithGst) salesBillsWithGst = this.salesBillsWithGst;
    if(!salesBillsWithoutGst) salesBillsWithoutGst = this.salesBillsWithoutGst;
    this.showForm = false;
    this.applicationService.getAssessmentYearAndMonths(this.application.uid)
      .subscribe((response: any) => {
        if (response) {
          this.showForm = true;
          this.recentMonths = response.recentMonths;
          this.buildSalesBillsForm(salesBillsWithGst, salesBillsWithoutGst);
        }
      });
  }

  addNewSalesBillsSummary() {
    this.isFormDisabled = false;

    let newSalesBillsWithGst = new BusinessIncomeType();
    newSalesBillsWithGst.businessIncome = new BusinessIncome();
    newSalesBillsWithGst.businessIncomeDetail = new BusinessIncomeDetail();

    let newSalesBillsWithoutGst = new BusinessIncomeType();
    newSalesBillsWithoutGst.businessIncome = new BusinessIncome();
    newSalesBillsWithoutGst.businessIncomeDetail = new BusinessIncomeDetail();

    this.fetchAssessmentYearAndMonths(newSalesBillsWithGst, newSalesBillsWithoutGst);
  }

  buildSalesBillsForm(salesBillsWithGst ?: BusinessIncomeType,
      salesBillsWithoutGst ?: BusinessIncomeType) {
    if (this.showForm) {
      this.salesBillsForm.controls.salesSummaryForm = this.formBuilder.group({
        averageSalesWithGst: salesBillsWithGst.businessIncome.monthlySales,
        averageSalesWithoutGst: salesBillsWithoutGst.businessIncome.monthlySales,
        eligibleMonthlyTurnoverWithGst: salesBillsWithGst.businessIncome.monthlySales,
        eligibleMonthlyTurnoverWithoutGst: (salesBillsWithGst.businessIncome.monthlySales
          || salesBillsWithoutGst.businessIncome.monthlySales) ? salesBillsWithGst
            .businessIncome.monthlySales + salesBillsWithoutGst.businessIncome
              .monthlySales : undefined
      })
      
      this.salesBillsForm.controls.monthlyPurchaseForm = this.formBuilder.array([]);
      const array = this.salesBillsForm.controls.monthlySalesForm as FormArray;
      this.recentMonths.forEach((month, index) => {
        const group = new FormGroup({
          mWithGst: new FormControl(salesBillsWithGst
            .businessIncomeDetail['m' + (index + 1) + 'Amount']),
          mWithoutGst: new FormControl(salesBillsWithoutGst
            .businessIncomeDetail['m' + (index + 1) + 'Amount']),
        });
        this.applyValidatorsToSalesBill(group)
        array.push(group);
      });

      this.salesBillsWithGst = salesBillsWithGst;
      this.salesBillsWithoutGst = salesBillsWithoutGst;
    }
  }

  computeSummary() {
    this.validationService.markFormGroupTouched(this.salesBillsForm)
    if (this.salesBillsForm.valid) {
      let monthlySalesBills: BusinessIncomeDetail[] = [];
      let monthlySalesBillsWithGst = this.salesBillsWithGst.businessIncomeDetail;
      let monthlySalesBillsWithoutGst = this.salesBillsWithoutGst.businessIncomeDetail;
      this.recentMonths.forEach((month, index) => {
        monthlySalesBillsWithGst['m' + (index + 1) + 'Amount'] =
          this.salesBillsForm.get('monthlySalesForm')['controls'][index].value.mWithGst
        monthlySalesBillsWithoutGst['m' + (index + 1) + 'Amount'] =
          this.salesBillsForm.get('monthlySalesForm')['controls'][index].value.mWithoutGst
      });
      monthlySalesBillsWithGst.incomeDocumentType = 'SALES_GST';
      monthlySalesBillsWithoutGst.incomeDocumentType = 'SALES_WO_GST';
      monthlySalesBills.push(monthlySalesBillsWithGst);
      monthlySalesBills.push(monthlySalesBillsWithoutGst);

      this.applicationService.getCalculatedBusinessIncomeSummary(this.application.uid, "salesbill",
        monthlySalesBills).subscribe(response => {
          if (response && response.body.length > 0) {
            this.updateSalesBillsSummary(response.body);
          }
        });
    }
  }

  updateSalesBillsSummary(salesbillsSummary) {
    let salesBillsSummaryWithGst = salesbillsSummary.find(
      businessIncome => businessIncome.incomeDocumentType === 'SALES_GST');
    let salesBillsSummaryWithoutGst = salesbillsSummary.find(
      businessIncome => businessIncome.incomeDocumentType === 'SALES_WO_GST');
    this.salesBillsForm.patchValue({
      salesSummaryForm: {
        averageSalesWithGst: salesBillsSummaryWithGst.monthlySales,
        averageSalesWithoutGst: salesBillsSummaryWithoutGst.monthlySales,
        eligibleMonthlyTurnoverWithGst: salesBillsSummaryWithGst.eligibleMonthlyTurnover,
        eligibleMonthlyTurnoverWithoutGst: salesBillsSummaryWithoutGst.eligibleMonthlyTurnover
      }
    });
  }

  
  saveSalesBillsDetails() {
    this.validationService.markFormGroupTouched(this.salesBillsForm)
    this.salesBillsForm.updateValueAndValidity();
    if(!this.isFormDisabled && this.salesBillsForm.valid) {
      if(!this.salesBillsWithGst.businessIncome.uid && 
          !this.salesBillsWithGst.businessIncomeDetail.uid) {
        this.salesBillsWithGst.businessIncome.category = 'salesbill';
        this.salesBillsWithGst.businessIncome.incomeDocumentType = 'SALES_GST';
        this.salesBillsWithGst.businessIncome.frequency = 'M';
        this.salesBillsWithGst.businessIncome.contextType = 'application';
        this.salesBillsWithGst.businessIncome.contextUid = this.application.uid;

        this.salesBillsWithGst.businessIncomeDetail.category = 'salesbill';
        this.salesBillsWithGst.businessIncomeDetail.incomeDocumentType = 'SALES_GST';
        this.salesBillsWithGst.businessIncomeDetail.frequency = 'M';
        this.salesBillsWithGst.businessIncomeDetail.contextType = 'application';
        this.salesBillsWithGst.businessIncomeDetail.contextUid = this.application.uid;
      }

      if(!this.salesBillsWithoutGst.businessIncome.uid && 
          !this.salesBillsWithoutGst.businessIncomeDetail.uid) {
        this.salesBillsWithoutGst.businessIncome.category = 'salesbill';
        this.salesBillsWithoutGst.businessIncome.incomeDocumentType = 'SALES_WO_GST';
        this.salesBillsWithoutGst.businessIncome.frequency = 'M';
        this.salesBillsWithoutGst.businessIncome.contextType = 'application';
        this.salesBillsWithoutGst.businessIncome.contextUid = this.application.uid;

        this.salesBillsWithoutGst.businessIncomeDetail.category = 'salesbill';
        this.salesBillsWithoutGst.businessIncomeDetail.incomeDocumentType = 'SALES_WO_GST';
        this.salesBillsWithoutGst.businessIncomeDetail.frequency = 'M';
        this.salesBillsWithoutGst.businessIncomeDetail.contextType = 'application';
        this.salesBillsWithoutGst.businessIncomeDetail.contextUid = this.application.uid;
      }

      let salesBillsFormFields = this.salesBillsForm.value;
      this.salesBillsWithGst.businessIncome.monthlySales =
        salesBillsFormFields.salesSummaryForm.averageSalesWithGst;
      this.salesBillsWithGst.businessIncome.eligibleMonthlyTurnover =
        salesBillsFormFields.salesSummaryForm.eligibleMonthlyTurnoverWithGst;
      this.salesBillsWithoutGst.businessIncome.monthlySales =
        salesBillsFormFields.salesSummaryForm.averageSalesWithoutGst;
      this.salesBillsWithoutGst.businessIncome.eligibleMonthlyTurnover =
        salesBillsFormFields.salesSummaryForm.eligibleMonthlyTurnoverWithoutGst;
      
      this.recentMonths.forEach((month, index) => {
      this.salesBillsWithGst.businessIncomeDetail['m' + (index + 1) + 'Amount'] =
          this.salesBillsForm.get('monthlySalesForm')['controls'][index].value.mWithGst
        this.salesBillsWithoutGst.businessIncomeDetail['m' + (index + 1) + 'Amount'] =
          this.salesBillsForm.get('monthlySalesForm')['controls'][index].value.mWithoutGst
      });

      this.salesBills.businessIncome = [];
      this.salesBills.businessIncomeDetail = [];

      this.salesBills.businessIncome.push(this.salesBillsWithGst.businessIncome);
      this.salesBills.businessIncomeDetail.push(this.salesBillsWithGst.businessIncomeDetail);
      this.salesBills.businessIncome.push(this.salesBillsWithoutGst.businessIncome);
      this.salesBills.businessIncomeDetail.push(this.salesBillsWithoutGst.businessIncomeDetail);

      this.applicationService.saveBusinessIncomeSummary(this.application.uid, "salesbill",
        this.salesBills).toPromise().then(
          (_success) => {
            this._snackbar.open("Sales bills details updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.salesBillsForm = this.formBuilder.group({
              salesSummaryForm: this.formBuilder.group({}),
              monthlySalesForm: this.formBuilder.array([])
            });
            this.fetchSalesBills();
          }, (failure) => {
            let errormesg =[]
            errormesg.push("Sales bills details update failed")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
      });
    }
  }

  enableDisableForm() {
    this.isFormDisabled = !this.isFormDisabled;
  }

  cancelForm() {
    this.salesBillsForm = this.formBuilder.group({
      salesSummaryForm: this.formBuilder.group({}),
      monthlySalesForm: this.formBuilder.array([])
    });

    this.salesBillsWithGst.businessIncome = this.salesBills
        .businessIncome[0];
    this.salesBillsWithGst.businessIncomeDetail = this.salesBills
        .businessIncomeDetail[0];

    this.salesBillsWithoutGst.businessIncome = this.salesBills
        .businessIncome[0];
    this.salesBillsWithoutGst.businessIncomeDetail = this.salesBills
        .businessIncomeDetail[0];
    if(this.salesBillsWithGst.businessIncome ==undefined) this.salesBillsWithGst.businessIncome=[] as any
    if(this.salesBillsWithGst.businessIncomeDetail == undefined)this.salesBillsWithGst.businessIncomeDetail =[] as any
    if(this.salesBillsWithoutGst.businessIncome == undefined)this.salesBillsWithoutGst.businessIncome =[] as any
    if(this.salesBillsWithoutGst.businessIncomeDetail == undefined) this.salesBillsWithoutGst.businessIncomeDetail =[] as any
    this.buildSalesBillsForm(this.salesBillsWithGst, this.salesBillsWithoutGst);
    this.isFormDisabled = true;
  }

  applyValidatorsToSalesBill(group) {
    this.validationService.applyValidationRules(group, "SalesBill")
      .then((controlValidators) => {
      }).catch(() => { });
  }

  //this function will call a api to get CFA Finalized or not status
  getCfaStatus(){
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response =>{
      this.cfaFinalized=response.body.cfaFinalized
      if(this.cfaFinalized){
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close" ,{
          duration:5000,
        });
      }
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}