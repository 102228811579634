import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ApplicationService } from '@app/applications/application.service';
import { ColletralSummaryComposite, CollateralDetail } from './collateral-summary.model';
import { MatSnackBar } from '@angular/material';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
@Component({
  selector: 'eng-collateral-summary',
  templateUrl: 'collateral-summary.template.html',
  styleUrls: ['../../application-details.styles.scss', 'collateral-summary.style.css']
})

export class ColletralSummaryComponent implements OnInit {

    @Input()
    application: LoanApplication;
    @Input()
    menuCode:string
    colletralSummaries : ColletralSummaryComposite[];
    collateralDetails : any = [];
    collateralTypes: ReferenceCode[] = [];

    showForm: boolean;
    isFormDisabled: boolean;
    allowAccess: Boolean; 

    applicationState: string;
    isCollateralFinalized: boolean;
    menuItemAllowAccess:boolean;
    applicationFinalApprovedFlag:boolean;

    applicationAccessSpecifiers: ApplicationAccessSpecifier[];
    menuItemAccessSpecifier: ApplicationAccessSpecifier;
  
    constructor(private applicationService: ApplicationService,
      private referenceCodeService: ReferenceCodeService,
        private _snackbar: MatSnackBar) {
      this.allowAccess = this.applicationService.allowAccess; 
    }

    ngOnInit() {
      this.applicationFinalApprovedFlag=this.application.finalApprovedFlag
      this.isFormDisabled = true;
      this.getRefCodes()
      
      this.getApplicationState();
      this.getCollateralStatus();
      this.isFormDisabled = true;
      this.getMenuItemAccess();

    }

    getRefCodes() {
      //it is possible that we may restrict that -prop must be present 
      let collateralSet = this.application.collateralSet
      this.referenceCodeService.getRefCodesForClassifier(collateralSet).subscribe((response: any) => {
        this.collateralTypes = response;
        this.fetchCollateranSummary();
      });
    }

    fetchCollateranSummary() {
      this.showForm = false;
      this.applicationService.getCollateralSummary(this.application.uid)
          .subscribe((response: any) => {
        if (response) {
          this.collateralDetails = response;
          this.showForm = (this.collateralDetails && this.collateralDetails.length > 0);
          this.collateralDetails.collateralDetail.forEach((element, index) => {
            
            let CheckType=this.collateralTypes.filter(object=>{return object.code ==element.code })
            if(CheckType.length ==0 && this.collateralTypes.length !=0 ){
              this.collateralDetails.collateralDetail[index]["showInRed"] = true
            }
          });
        }
      });
    }

    getApplicationState() {
      this.applicationService.getApplicationState(this.application.uid)
          .subscribe( response => {
        this.applicationState = response.body.applicationState;
      });
    }

    getCollateralStatus() {
      this.applicationService.getCollateralStatus(this.application.uid)
        .subscribe(response => {
          this.isCollateralFinalized = response.body.collateralFinalized;
      });
    }

    finalizeCollateral() {
      this.applicationService.finalizeCollateral(this.application.uid)
          .subscribe(response => {
        if(response.body.success) {
          this.isCollateralFinalized = true;
          this._snackbar.open("Collateral valuations have been finalized", "Close", {
            duration:5000,
          });
        }
      });
    }

    reviseCollateral() {
      this.applicationService.reviseCollateral(this.application.uid)
          .subscribe(response => {
        if(response.body.success) {
          this.isCollateralFinalized = false;
          this._snackbar.open("Collateral valuations can now be updated.", "Close", {
            duration:5000,
          });
        }
      });
    }

    //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}