import { AbstractControl } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { countryCode } from './countryCode';


export class IgFormValidator {

    static email(control: AbstractControl): any {
        const emailPattern: RegExp = /^[a-z]+[a-z0-9._-]+@[a-z._-]+\.[a-z.]{2,5}$/;
        const isValid = emailPattern.test(control.value);
        return isValid ? null : { igEmail: true };
    }

    static contactNumber(regionCode: countryCode): any {
        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        return (control: AbstractControl): { [key: string]: any } => {
            let validNumber = false;
            try {
                if (control.value) {
                    const phoneNumber = phoneNumberUtil.parse(control.value, regionCode);
                    validNumber = phoneNumberUtil.isValidNumberForRegion(phoneNumber, regionCode);
                }
            } catch (e) { }
            return validNumber ? null : { igContactNumber: true };
        };
    }

    static zipcode(control: AbstractControl): any {
        const pattern: RegExp = /[a-zA-Z\d\s\-\,\#\.\+]+/;
        let valid = false;
        if (control.value) {
            const data: string = control.value;
            if ( data.length <= 12 ) {
                valid = pattern.test(data);
            }
        }
        return valid ? null : { igPincode : true};
    }

    static panCard(control: AbstractControl): any {
        const pattern: RegExp = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        const valid = pattern.test(control.value);
        return valid ? null : { igPanCard : true };
    }

    static aadhar(control: AbstractControl): any {
        const pattern: RegExp = /^([0-9]{12})$/;
        const valid = pattern.test(control.value);
        return valid ? null : { igAadhar : true };
    }
}
