import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import FunctionUtils from '../../../utils/function-utils';
import {BaseChartDirective} from 'ng2-charts';
import {ChartData} from '../metrics.component';

@Component({
  selector: 'ig-jvm-memory-chart',
  templateUrl: './jvm-memory-chart.component.html',
  styleUrls: ['./jvm-memory-chart.component.css']
})
export class JvmMemoryChartComponent implements OnInit {

  objectKeys = Object.keys;
  public chartData: ChartData = new ChartData([], [], 'pie', {});
  memoryUnit: string;
  totalMemory: number;
  physicalMemoryUnits = FunctionUtils.physicalMemoryUnits;
  private defaultChartOptions = {
    legend: {
      position: 'bottom',
    }
  };

  @ViewChild('baseChart')
  chart: BaseChartDirective;

  @Input('gauges')
  jvmMemoryData: any;

  constructor() { }

  ngOnInit() {
    this.memoryUnit = 'GB';
    this.chartData = this.populateJVMMemoryChart(this.jvmMemoryData, this.memoryUnit);
  }

  populateJVMMemoryChart(gauge: any, unit: string): ChartData {
    const chartLabels: any = [];
    const chartData: any = [];
    const totalMemory = FunctionUtils.convertNumberToPhysicalMemory(gauge['jvm.memory.total.max'].value, 2, unit);
    chartLabels.push(`Total ${totalMemory} ${unit}`);
    chartData.push(totalMemory);
    this.totalMemory = totalMemory;
    const usedMemory = FunctionUtils.convertNumberToPhysicalMemory(gauge['jvm.memory.total.used'].value, 2, unit);
    chartLabels.push(`Used ${usedMemory} ${unit}`);
    chartData.push(usedMemory);
    const committedMemory = FunctionUtils.convertNumberToPhysicalMemory(gauge['jvm.memory.total.committed'].value, 2, unit);
    chartLabels.push(`Committed ${committedMemory} ${unit}`);
    chartData.push(committedMemory);
    const initializedMemory = FunctionUtils.convertNumberToPhysicalMemory(gauge['jvm.memory.total.init'].value, 2, unit);
    chartLabels.push(`Initialized ${initializedMemory} ${unit}`);
    chartData.push(initializedMemory);

    return new ChartData(chartLabels, chartData, 'pie', this.defaultChartOptions);
  }

  changeChartType(chartData: ChartData, type: string) {
    chartData.type = type;
  }
  updateChartData(event, chartData: ChartData) {
    const newChartData = this.populateJVMMemoryChart(this.jvmMemoryData, event.value);
    chartData.labels = newChartData.labels;
    chartData.data = newChartData.data;
    if (this.chart !== undefined) {
      this.chart.data = newChartData.data;
      this.chart.labels = newChartData.labels;
      this.chart.ngOnChanges({} as SimpleChanges);
    }
  }

}
