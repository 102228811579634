import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validator, Validators } from '@angular/forms';
import {
  MatSnackBar,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material';
import { IgFormService } from '../../../../projects/ig-core/src/lib/form/form.service';
import { IgPatternValidator } from '../../../../projects/ig-core/src/lib/form/additional-validators/ig-pattern-validator';
import { ServerErrorModel } from '../../../../projects/ig-core/src/lib/form/server-error.model';
import { Router, NavigationStart } from '@angular/router';
import { LoginService } from '../../../../projects/ig-core/src/lib/authentication/login.service';
import { FormErrorModel } from '../../../../projects/ig-core/src/lib/form/form-error.model';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { IgFormValidator } from '@ig-core/form/igFormValidator';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment'
@Component({
  selector: 'app-login',
  /* templateUrl: './login.component.html', */
  templateUrl: './loginTry.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public formError: FormErrorModel;
  environmentVariable:any=environment;
  public formErrors = {
    username: '',
    password: ''
  };
  loginError: string = '';
  loginErrorEmitter$ = new BehaviorSubject<string>(this.loginError);
  public forgorPassword: boolean = false;
  public resetForm: FormGroup;
  public resetFormError: FormErrorModel;
  public resetFormErrors = {
    email: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService,
    private loginService: LoginService,
    private router: Router,
    public dialog: MatDialog
  ) { }


  ngOnInit() {
    this.buildForm();
    this.formError = new FormErrorModel(false, '');

  }

  ngOnDestroy(): void { }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100)
        ])
      ],
      rememberMe: [false]
    });
    this.loginForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.formErrors = this.igFormService.validateForm(
          this.loginForm,
          this.formErrors,
          true
        );
      });
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.loginForm);

    if (this.loginForm.valid) {
      this.loginService
        .login(this.loginForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          response => this.onSubmitSuccess(response),
          response => this.onSubmitError(response)
        );
    } else {
      this.formErrors = this.igFormService.validateForm(
        this.loginForm,
        this.formErrors,
        false
      );
    }
  }

  private onSubmitSuccess(response) {
    this.snackBar.open('Sign in successful', 'Close');
    this.router.navigate(['home']);
  }

  private onSubmitError(response) {
    const errorModel = this.igFormService.handleServerError(
      this.loginForm,
      this.formErrors,
      response.error,
      false
    );
    if (errorModel && errorModel.isValidationError) {
      this.formErrors = errorModel.formErrors;
    } else if (errorModel && !errorModel.isValidationError) {
      this.formError = new FormErrorModel(true, errorModel.otherError);
      this.snackBar.open(this.formError.error.detail, 'Close');
    }
  }

  // register user
  registerUser() {
    this.router.navigate(['register']);
  }

  openForgotPassword(): void {
    this.buildResetForm();
    this.resetFormError = new FormErrorModel(false, '');
    this.forgorPassword = true;
    this.success = false;
  }

  buildResetForm() {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, IgFormValidator.email]]
    });

    this.resetForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.resetFormErrors = this.igFormService.validateForm(
          this.resetForm,
          this.resetFormErrors,
          true
        );
      });
  }

  /* FORGOT PASSWORD */

  success = false;
  ngOnInitForgot() {
    this.buildFormReset();
    this.formError = new FormErrorModel(false, '');
  }
  buildFormReset() {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, IgFormValidator.email]]
    });

    this.resetForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.resetFormErrors = this.igFormService.validateForm(
          this.resetForm,
          this.resetFormErrors,
          true
        );
      });
  }

  onSubmitForgot() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.resetForm);
    if (this.resetForm.valid) {
      this.loginService
        .sendMail(this.resetForm.value.email)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          response => this.onSubmitForgotSuccess(response),
          response => this.onSubmitForgotError(response)
        );
    } else {
      this.resetFormErrors = this.igFormService.validateForm(
        this.resetForm,
        this.resetFormErrors,
        false
      );
    }
  }
  onSubmitForgotSuccess(response) {
    this.success = true;
    this.snackBar.open('Success! Please check your mail', 'Close');
  }

  onSubmitForgotError(response) {
    if (response.error.status === 400) {
      this.snackBar.open(` ${response.error.title} `, 'Close');
    } else {
      this.snackBar.open(
        'There was an error while processing your request',
        'Close');
    }
  }

  forgetPasswordcancel() {
    this.forgorPassword = false;
  }
}