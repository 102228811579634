import { Component, OnInit, Input } from '@angular/core';
import { JhiLanguageService } from 'ng-jhipster';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'jhi-loan-od-accounts-customer-editor',
    templateUrl: './loan-od-accounts-customer-editor.component.html',

})
export class LoanOdAccountsCustomerEditorComponent implements OnInit {

    @Input() customerForm: FormGroup;
    @Input() locationData: any;
    showCustomer2: boolean;
    showCustomer3: boolean;
    showCustomer4: boolean;

    constructor(
        private jhiLanguageService: JhiLanguageService,
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        if (this.customerForm.get('customer2').get('customerId2').value) {
            this.showCustomer2 = true;
        }
        if (this.customerForm.get('customer3').get('customerId3').value) {
            this.showCustomer3 = true;
        }
        if (this.customerForm.get('customer4').get('customerId4').value) {
            this.showCustomer4 = true;
        }
    }

}
