import { Directive, forwardRef, Input } from '@angular/core';
import { FormControl, AbstractControl, ValidatorFn, NG_VALIDATORS, Validator, Validators, ValidationErrors } from '@angular/forms';

function validateAadhar(): ValidatorFn {

  return (c: AbstractControl) => {
    const d = [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
      [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
      [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
      [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
      [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
      [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
      [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
      [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
      [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
    ];

    // permutation table p
    const p = [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
      [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
      [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
      [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
      [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
      [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
      [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
    ];

    // inverse table inv
    const inv = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9];

    // converts string or number to an array and inverts it
    function invArray(array) {

      if (Object.prototype.toString.call(array) === '[object Number]') {
        array = String(array);
      }

      if (Object.prototype.toString.call(array) === '[object String]') {
        array = array.split('').map(Number);
      }

      return array.reverse();

    }

    // generates checksum
    function generate(array) {

      let c = 0;
      const invertedArray = invArray(array);

      for (let i = 0; i < invertedArray.length; i++) {
        c = d[c][p[((i + 1) % 8)][invertedArray[i]]];
      }

      return inv[c];
    }

    // validates checksum
    function validate(array) {

      let c = 0;
      const invertedArray = invArray(array);

      for (let i = 0; i < invertedArray.length; i++) {
        c = d[c][p[(i % 8)][invertedArray[i]]];
      }

      return (c === 0);
    }

    if (c.value !== null && validate(c.value)) {
      return null;
    } else {
      return {
        validateAadhar: {
          valid: false
        }
      };
    }
  }
}

@Directive({
  selector: '[validateaadhar]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: AadharValidator, multi: true }
  ]
})
export class AadharValidator implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = validateAadhar();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
}

@Directive({
  selector: '[igmin]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: IgMinValidator, multi: true }
  ]
})
export class IgMinValidator implements Validator {

  @Input()
  private igmin: any;

  constructor() {
  }

  validate(c: FormControl) {
    return c.value > parseInt(this.igmin) ? null : { 'igmin': { 'valid': false } };
  }
}

@Directive({
  selector: '[igmax]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: IgMaxValidator, multi: true }
  ]
})
export class IgMaxValidator implements Validator {

  @Input()
  private igmax: any;

  constructor() {
  }

  validate(c: FormControl) {
    return c.value < parseInt(this.igmax) ? null : { 'igmax': { 'valid': false } };
  }
}

export class CustomValidators {

  static emailOrEmpty(control: AbstractControl): ValidationErrors | null {
    return control.value === '' ? null : control.value === null ? null : control.value === undefined ? null : Validators.email(control);
  }

}

@Directive({
  selector: '[minFloat]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinFloatValidator, multi: true }
  ]
})
export class MinFloatValidator implements Validator {

  @Input()
  private minFloat: any;

  constructor() {
  }

  validate(c: FormControl) {
    return c.value >= parseFloat(this.minFloat) ? null : { 'minFloat': { 'valid': false, 'min': this.minFloat } };
  }
}

@Directive({
  selector: '[maxFloat]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxFloatValidator, multi: true }
  ]
})
export class MaxFloatValidator implements Validator {

  @Input()
  private maxFloat: any;

  constructor() {
  }

  validate(c: FormControl) {
    return c.value <= parseFloat(this.maxFloat) ? null : { 'maxFloat': { 'valid': false, 'max': this.maxFloat } };
  }
}