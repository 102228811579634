export class LiabilityComposite {
    constructor(
        public liability?: Liability,
        public liabilityEmi?: LiabilityEmi,
    ) { }
}

export class Liability {
    constructor(
        public uid?: string,
        public liabilityType?: string,
        public lendingInstitution?: string,
        public product?: string,
        public purpose?: string,
        public accountNumber?: string,
        public amount?: number,
        public emi?: number,
        public tenure?: number,
        public emiPaid?: number,
        public balanceTenure?: number,
        public currentOs?: number,
        public status?: string,
        public bureauStatus?: boolean,
        public obligationStatus?: boolean,
        public remarks?: string,
        public fileFolderUid?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public linkToType?: string,
        public linkToId?: string,
        public linkToUid?: string,
        public emiBounce6m?: number,
        public emiBounceTotal?: number,
    ) { }
}

export class LiabilityEmi {
    constructor(
        public uid?: string,
        public version?: number,
        public liabilityUid?: string,
        public mmyyStart?: Date,
        public m1Amount?: number,
        public m2Amount?: number,
        public m3Amount?: number,
        public m4Amount?: number,
        public m5Amount?: number,
        public m6Amount?: number,
        public m7Amount?: number,
        public m8Amount?: number,
        public m9Amount?: number,
        public m10Amount?: number,
        public m11mmount?: number,
        public m12Amount?: number,
        public m1RepayDate?: Date,
        public m2RepayDate?: Date,
        public m3RepayDate?: Date,
        public m4RepayDate?: Date,
        public m5RepayDate?: Date,
        public m6RepayDate?: Date,
        public m7RepayDate?: Date,
        public m8RepayDate?: Date,
        public m9RepayDate?: Date,
        public m10RepayDate?: Date,
        public m11RepayDate?: Date,
        public m12RepayDate?: Date,
        public fileFolderUid?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
    ) { }
}