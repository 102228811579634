import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../../../projects/ig-core/src/lib/authentication/login.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logout',
  template: `
    <p>
      Signed out
    </p>
  `,
  styles: []
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,
              private loginService: LoginService) { }

  ngOnInit() {
    this.loginService.logout();
  }

}
