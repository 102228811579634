import { KycVerificationResponse } from "../../applicant/applicantKycProofs/applicant-kyc-proof.model";

export class KycProof {
    constructor(
        public uid?: string,
        public kycProofType?: string,
        public kycProofDoc?: string,
        public documentNumber?: string,
        public issuingAuthority?: string,
        public documentDate?: Date,
        public documentExpiryDate?: string,
        public validUpto?: string,
        public documentDescription?: string,
        public proofValidity?: string,
        public proofValidityNames?: string[],
        public verifiedBy?: string,
        public verificationDate?: Date,
        public verificationStatus?: string,
        public verifiedFlag?: boolean,
        public fileFolderUid?: string,
        public linkToType?: string,
        public linkToId?: string,
        public linkToUid?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public version?: number,
        public deleteBtnDisabled?: boolean,//added for client side use this paramenter will not come from server
        public source?: string,
        public verificationMode?: string
        ) { }
}

export class KycProofType {
    constructor(
        public kycProofType?: string,
        public kycProofTypeDisplay?: string,
        public kycProofDoc?: string,
    ) { }
}

export class DocumentVerificationDTO {
    constructor(
        public isVerified?: boolean,
        public verificationMode?: string,
        public jdata?: KycVerificationResponse
    ) { }
}