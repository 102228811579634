import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import { MaterialLibModule } from '@app/material-lib.module';

import {EditableResolveService} from '@ig-core/resolves/editable-resolve.service';
import {NonEditableResolveService} from '@ig-core/resolves/non-editable-resolve.service';
import {AuthenticatedUserService} from '@ig-core/authentication/authenticated-user-service';


import { RoleResolverService } from '@app/admin/role/role.resolve';
import { RoleListComponent } from '@app/admin/role/role-list/role-list.component';
import { RoleDeleteDialogComponent } from '@app/admin/role/role-list/role-delete-dialog.component';
import { RoleEditorComponent } from '@app/admin/role/role-edit/role-editor.component';

export const roleRoute: Route = {
  path: 'role',
  canActivate: [AuthenticatedUserService],
  children: [
    {path: '', component: RoleListComponent},
    {path: 'create', component: RoleEditorComponent,
      resolve: {
        editable: EditableResolveService
      }},
    {path: 'update/:id', component: RoleEditorComponent,
      resolve: {
        role: RoleResolverService,
        editable: EditableResolveService
      }
    },
    {path: 'view/:id', component: RoleEditorComponent,
      resolve: {
        role: RoleResolverService,
        editable: NonEditableResolveService
      }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [ RoleEditorComponent, RoleDeleteDialogComponent], /* RoleListComponent,  */
  entryComponents: [RoleDeleteDialogComponent]
})
export class RoleModule { }
