import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AlertService, JhiLanguageService } from 'ng-jhipster';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoanOdRepaymentCompositeDto } from './loan-od-accounts-repayment-composite-dto.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { DateConverterService } from '../shared/date/date-converter.service';
import { ApplicationDateService } from '../shared/date/application-date.service';
@Component({
    selector: 'app-loan-od-accounts-composite-repayment-execute',
    templateUrl: './loan-od-accounts-composite-repayment-execute.component.html',
})
export class LoanOdAccountsCompositeRepaymentExecuteComponent implements OnInit {

    dateSubscription: Subscription;
    loanOdAccounts: LoanOdAccounts;
    @Input() loanOdRepaymentCompositeDto: LoanOdRepaymentCompositeDto;
    @Output() executeError: EventEmitter<any> = new EventEmitter<any>();
    @Output() previousState: EventEmitter<any> = new EventEmitter<any>();
    @Output() executeSuccess: EventEmitter<any> = new EventEmitter<any>();
    backDatedTransaction: boolean;
    currentWorkingDate: any;

    constructor(private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private router: Router,
        private alertService: AlertService,
        private applicationDateService: ApplicationDateService,
        private dateConverter: DateConverterService) {
        //   this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        this.dateSubscription = this.applicationDateService.getDate().subscribe(
            (date) => {
                this.currentWorkingDate = date;
                this.isBackDated();
            }
        );
    }

    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
    }

    private isBackDated() {
        const currentDate = this.dateConverter.fromServerToDateObject(this.currentWorkingDate);
        if (this.loanOdRepaymentCompositeDto.repaymentDate < currentDate) {
            this.backDatedTransaction = true;
        }
    }

    execute() {
        //   this.loanOdRepaymentCompositeDto.repaymentDate = this.dateConverter.fromDateObjectToServer(this.loanOdRepaymentCompositeDto.repaymentDate);
        this.loanOdAccountsService.feePaymentResult(this.loanOdRepaymentCompositeDto).subscribe((res) =>
            this.onSaveSuccess(res), (res) => this.onSaveError(res));
    }

    private onSaveSuccess(result) {
        this.executeSuccess.emit(result);
    }

    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }

    private onSaveError(error) {
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
        this.executeError.emit('error');
    }

    private onError(error) {
        this.alertService.error(error.error);
    }

    back() {
        this.previousState.emit('back');
    }

}