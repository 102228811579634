import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoToPageComponent } from './go-to-page.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { MaterialLibModule } from '@app/material-lib.module';
@NgModule({
  declarations: [GoToPageComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MaterialLibModule,
    FormsModule
  ],
  exports: [GoToPageComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class GoToPageModule { }