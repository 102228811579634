import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { InsurancePolicyDto } from './insurance-policy-dto';

@Component({
    selector: 'jhi-loan-od-accounts-insurance-policy-confirm',
    templateUrl: './loan-od-accounts-insurance-policy-confirm.html',
})
export class LoanOdAccountsInsurancePolicyConfirmComponent implements OnInit {

    policyList: any = [];
    insurancePolicyDto: InsurancePolicyDto;
    constructor(private loanOdAccountsService: LoanOdAccountsService) { }
    ngOnInit(): void {
    }
}