import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '@app/applications/application.service';
import { LoanApplication, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { BusinessIncome, BusinessIncomeDetail, BusinessIncomeSummary, BusinessIncomeType } from '../financials.model';
import { ValidationService } from '../../../application-validators/validation.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
@Component({
  selector: 'eng-it-returns',
  templateUrl: 'it-returns.template.html',
  styleUrls: ['../../application-details.styles.scss', 'it-returns.styles.css']
})
export class ITReturnsComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode: string;

  itReturns: BusinessIncome = new BusinessIncome();
  itReturnsDetails: BusinessIncomeDetail[] = [];
  recentYearFiling = new BusinessIncomeDetail();
  previousYearFiling = new BusinessIncomeDetail();
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  itReturnFullData: BusinessIncomeSummary;
  itReturnsForm: FormGroup;

  showForm: boolean;
  isFormDisabled: boolean;
  allowAccess: Boolean;
  cfaFinalized: boolean;
  menuItemAllowAccess: boolean;

  assessmentYears: any;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private validationService: ValidationService) {
    this.dateAdapter.setLocale('en-IN');
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.getCfaStatus()
    this.fetchItReturns();
    this.getMenuItemAccess();

    this.isFormDisabled = true;
  }

  fetchItReturns() {
    this.showForm = false;
    this.applicationService.getBusinessFinancials(this.application.uid, 'itr')
      .subscribe((response: any) => {
        if (response) {
          this.itReturnFullData = response
          this.showForm = (response.businessIncome.length > 0
            && response.businessIncomeDetail.length > 0);
          if (this.showForm) {
            this.itReturns = response.businessIncome[0];
            this.itReturnsDetails = response.businessIncomeDetail;
            this.getSeparateRecentAndPreviousYearFilings();
            this.buildItReturnsForm();
          }
        }
      });
  }

  getSeparateRecentAndPreviousYearFilings() {
    if (this.itReturnsDetails.length === 1) {
      this.recentYearFiling = this.itReturnsDetails[0];
    } else if (this.itReturnsDetails.length === 2) {
      if (this.itReturnsDetails[0].taxAssessmentYear.split('-')[0] >
        this.itReturnsDetails[1].taxAssessmentYear.split('-')[0]) {
        this.recentYearFiling = this.itReturnsDetails[0];
        this.previousYearFiling = this.itReturnsDetails[1];
      } else {
        this.recentYearFiling = this.itReturnsDetails[1];
        this.previousYearFiling = this.itReturnsDetails[0];
      }
    }
  }

  addNewItReturnsSummary() {
    this.isFormDisabled = false;
    this.showForm = false;
    let newItReturns = new BusinessIncome();
    let newRecentYearFiling = new BusinessIncomeDetail();;
    let newPreviousYearFiling = new BusinessIncomeDetail();;
    this.applicationService.getAssessmentYearAndMonths(this.application.uid)
      .subscribe((response: any) => {
        if (response) {
          this.showForm = true;
          newRecentYearFiling.taxAssessmentYear = response.assesementYears[0].recent_ay;
          newPreviousYearFiling.taxAssessmentYear = response.assesementYears[0].previous_ay;
          this.buildItReturnsForm(newItReturns, newRecentYearFiling, newPreviousYearFiling);
          this.itReturnsForm.enable();
        }
      });
  }

  buildItReturnsForm(itReturns?: BusinessIncome, recentYearFiling?:
    BusinessIncomeDetail, previousYearFiling?: BusinessIncomeDetail) {
    if (this.showForm) {
      if (!itReturns) itReturns = this.itReturns;
      if (!recentYearFiling) recentYearFiling = this.recentYearFiling;
      if (!previousYearFiling) previousYearFiling = this.previousYearFiling;
      this.itReturnsForm = this.formBuilder.group({
        itReturnsSummaryForm: this.formBuilder.group({
          monthlySales: itReturns.monthlySales,
          incomeMultiplier: itReturns.incomeMultiplier ? itReturns
            .incomeMultiplier.toFixed(2) : undefined,
          eligibleMonthlyTurnover: itReturns.eligibleMonthlyTurnover
        }),
        recentYearFilingForm: this.formBuilder.group({
          recentAssessmentYear: recentYearFiling.taxAssessmentYear,
          recentDateOfFiling: (recentYearFiling.taxFilingDate === null ||recentYearFiling.taxFilingDate == undefined) ?
            undefined : new Date(recentYearFiling.taxFilingDate),
          recentTotalSales: recentYearFiling.totalSales,
          recentTotalPurchases: recentYearFiling.totalPurchase,
          recentGrossProfit: recentYearFiling.grossProfilt,
          recentNetProfit: recentYearFiling.netProfit
        }),
        previousYearFilingForm: this.formBuilder.group({
          previousAssessmentYear: previousYearFiling.taxAssessmentYear,
          previousDateOfFiling: (previousYearFiling.taxFilingDate === null||previousYearFiling.taxFilingDate == undefined ) ?
            undefined : new Date(previousYearFiling.taxFilingDate),
          previousTotalSales: previousYearFiling.totalSales,
          previousTotalPurchases: previousYearFiling.totalPurchase,
          previousGrossProfit: previousYearFiling.grossProfilt,
          previousNetProfit: previousYearFiling.netProfit
        })
      });
    }
    this.itReturns = itReturns;
    this.recentYearFiling = recentYearFiling;
    this.previousYearFiling = previousYearFiling;
    this.itReturnsForm.disable();
    this.applyValidatorsToItReturn(this.itReturnsForm.controls.itReturnsSummaryForm)
    this.applyValidatorsToItReturn(this.itReturnsForm.controls.recentYearFilingForm)
    this.applyValidatorsToItReturn(this.itReturnsForm.controls.previousYearFilingForm)
  }

  computeSummary() {
    this.validationService.markFormGroupTouched(this.itReturnsForm)
    if (this.itReturnsForm.valid) {
      let yearlyItReturns: BusinessIncomeDetail[] = [];
      let recentYearFilingFormFields = this.itReturnsForm.value.recentYearFilingForm;
      let previousYearFilingFormFields = this.itReturnsForm.value.previousYearFilingForm;

      let recentYearFilingReturns = this.recentYearFiling;
      recentYearFilingReturns.incomeDocumentType = 'ITR';
      recentYearFilingReturns.totalSales = recentYearFilingFormFields.recentTotalSales;
      recentYearFilingReturns.totalPurchase = recentYearFilingFormFields.recentTotalPurchases;
      recentYearFilingReturns.grossProfilt = recentYearFilingFormFields.recentGrossProfit;
      recentYearFilingReturns.netProfit = recentYearFilingFormFields.recentNetProfit;
      yearlyItReturns.push(recentYearFilingReturns);

      if (this.previousYearFiling) {
        let previousYearFilingReturns = this.previousYearFiling;
        previousYearFilingReturns.incomeDocumentType = 'ITR';
        previousYearFilingReturns.totalSales = previousYearFilingFormFields.previousTotalSales;
        previousYearFilingReturns.totalPurchase = previousYearFilingFormFields.previousTotalPurchases;
        previousYearFilingReturns.grossProfilt = previousYearFilingFormFields.previousGrossProfit;
        previousYearFilingReturns.netProfit = previousYearFilingFormFields.previousNetProfit;
        yearlyItReturns.push(previousYearFilingReturns);
      }
      this.applicationService.getCalculatedBusinessIncomeSummary(this.application.uid, "itr",
        yearlyItReturns).subscribe(response => {
          if (response && response.body.length > 0) {
            this.updateItReturnsSummary(response.body[0]);
          }
        });
    }
  }

  updateItReturnsSummary(itReturnsSummary) {
    this.itReturnsForm.patchValue({
      itReturnsSummaryForm: {
        monthlySales: itReturnsSummary.monthlySales,
        incomeMultiplier: itReturnsSummary.incomeMultiplier.toFixed(2),
        eligibleMonthlyTurnover: itReturnsSummary.eligibleMonthlyTurnover
      }
    });
  }

  saveItReturnsDetails() {
    this.itReturnsForm.get('recentYearFilingForm')['controls'].recentDateOfFiling.errors
    this.validationService.markFormGroupTouched(this.itReturnsForm)
    this.itReturnsForm.updateValueAndValidity();
    if (!this.isFormDisabled && this.itReturnsForm.valid) {
      let itReturnsSummary = new BusinessIncomeSummary();
      itReturnsSummary.businessIncome = [];
      let itReturnsFormFields = this.itReturnsForm.value;
      if (this.itReturnFullData.businessIncome && this.itReturnFullData.businessIncome.length === 0) {
        let newGstReturns = new BusinessIncome();
        newGstReturns.category = 'itr';
        newGstReturns.incomeDocumentType = 'ITR';
        newGstReturns.frequency = 'M';
        newGstReturns.contextType = 'application';
        newGstReturns.contextUid = this.application.uid;
        itReturnsSummary.businessIncome.push(newGstReturns);
        itReturnsSummary.businessIncome[0].monthlySales = itReturnsFormFields.itReturnsSummaryForm.monthlySales;
        itReturnsSummary.businessIncome[0].incomeMultiplier = itReturnsFormFields.itReturnsSummaryForm.incomeMultiplier;
        itReturnsSummary.businessIncome[0].eligibleMonthlyTurnover = itReturnsFormFields.itReturnsSummaryForm.eligibleMonthlyTurnover;
      }else{
        this.itReturns.monthlySales = itReturnsFormFields.itReturnsSummaryForm.monthlySales;
      this.itReturns.incomeMultiplier = itReturnsFormFields.itReturnsSummaryForm.incomeMultiplier;
      this.itReturns.eligibleMonthlyTurnover = itReturnsFormFields.itReturnsSummaryForm.eligibleMonthlyTurnover;
      itReturnsSummary.businessIncome.push(this.itReturns);
      }
      //itReturnsSummary.businessIncome.push(this.itReturns);
      if (this.recentYearFiling.uid == undefined) {
        this.recentYearFiling.category = 'itr';
        this.recentYearFiling.incomeDocumentType = 'ITR';
        this.recentYearFiling.frequency = 'M';
        this.recentYearFiling.contextType = 'application';
        this.recentYearFiling.contextUid = this.application.uid;
      }
      this.recentYearFiling.taxFilingDate = itReturnsFormFields.recentYearFilingForm.recentDateOfFiling;
      this.recentYearFiling.totalSales = itReturnsFormFields.recentYearFilingForm.recentTotalSales;
      this.recentYearFiling.totalPurchase = itReturnsFormFields.recentYearFilingForm.recentTotalPurchases;
      this.recentYearFiling.grossProfilt = itReturnsFormFields.recentYearFilingForm.recentGrossProfit;
      this.recentYearFiling.netProfit = itReturnsFormFields.recentYearFilingForm.recentNetProfit;
      this.itReturnsDetails[0] = this.recentYearFiling;
      if (this.previousYearFiling) {
        if (this.previousYearFiling.uid == undefined) {
          this.previousYearFiling.category = 'itr';
          this.previousYearFiling.incomeDocumentType = 'ITR';
          this.previousYearFiling.frequency = 'M';
          this.previousYearFiling.contextType = 'application';
          this.previousYearFiling.contextUid = this.application.uid;
        }
        this.previousYearFiling.taxFilingDate = itReturnsFormFields.previousYearFilingForm.previousDateOfFiling;
        this.previousYearFiling.totalSales = itReturnsFormFields.previousYearFilingForm.previousTotalSales;
        this.previousYearFiling.totalPurchase = itReturnsFormFields.previousYearFilingForm.previousTotalPurchases;
        this.previousYearFiling.grossProfilt = itReturnsFormFields.previousYearFilingForm.previousGrossProfit;
        this.previousYearFiling.netProfit = itReturnsFormFields.previousYearFilingForm.previousNetProfit;
        this.itReturnsDetails[1] = this.previousYearFiling;
      }
      itReturnsSummary.businessIncomeDetail = this.itReturnsDetails;

      this.applicationService.saveBusinessIncomeSummary(this.application.uid, "itr",
        itReturnsSummary).subscribe(
          (_success) => {
            this._snackbar.open("IT Returns details updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.fetchItReturns();
          }, (failure) => {
            let errormesg =[]
            errormesg.push('It Returns details update failed.')
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
          }
        );
    }
  }

  enableDisableForm() {
    this.isFormDisabled = false;
    this.itReturnsForm.enable();
    this.disableAssessmentYearFields();
  }

  disableAssessmentYearFields() {
    this.itReturnsForm.get('previousYearFilingForm')['controls'].previousAssessmentYear.disable();
    this.itReturnsForm.get('recentYearFilingForm')['controls'].recentAssessmentYear.disable();
  }

  cancelForm() {
    this.isFormDisabled = true;
    this.itReturnsForm.disable();
    this.buildItReturnsForm();
  }
  applyValidatorsToItReturn(group) {
    this.validationService.applyValidationRules(group, "ITReturns")
      .then((controlValidators) => {
      }).catch(() => { });
  }

  //this function will call a api to get CFA Finalized or not status
  getCfaStatus() {
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response => {
      this.cfaFinalized = response.body.cfaFinalized
      if (this.cfaFinalized) {
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close", {
          duration: 5000,
        });
      }
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  //this function is called on click of expirydate input
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (!this.isFormDisabled) {
      if (event.value == null) {
        this.itReturnsForm.get('previousYearFilingForm')['controls'].recentDateOfFiling.patchValue(undefined);
      }
    }
  }

}