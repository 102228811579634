export class ModelObject {
    constructor(
        public title?: string,
        public entity?: string,
        public maticon?: string,
        public api?: API,
        public fields?: FieldData[]
    ) { }
}

export interface API {
    url?: string;
}

export interface Validator {
    name?: string;
}

export interface FieldData {
    name?: string;
    label?: string;
    isId?: boolean;
    readonly?: boolean;
    type?: string;
    widget?: string;
    validators?: Validator[];
}

export const FieldDataTypes: string[] = [
    'string',
    'number',
    'date',
    'boolean',
    'any'
];

export const WidgetDataTypeS: string[] = [
    'text',
    'number',
    'date',
    'select',
    'checkbox',
    'hidden',
    'radio'
];

export const ValidatorsList: string[] = [
    'required',
    'email'
];
