import { Component, OnInit, Input } from '@angular/core';
import { JhiLanguageService } from 'ng-jhipster';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'jhi-loan-od-accounts-terms-editor',
    templateUrl: './loan-od-accounts-terms-editor.component.html',

})
export class LoanOdAccountsTermsEditorComponent implements OnInit {

    @Input() termsForm: FormGroup;
    tenureUnit: string;
    showOperativeAccountId: boolean;
    showfirstRepaymentDate: boolean;
    showNormalInterestRate: boolean;
    showNominalInterestRate: boolean;
    moratoriumIsReadOnly: boolean;
    moratoriumPeriodUnit: string;
    showTemplate: boolean;

    constructor(
        private jhiLanguageService: JhiLanguageService,
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        this.tenureUnit = this.termsForm.get('tenureUnit').value;
        // this.moratoriumPeriodUnit = this.termsForm.get('moratoriumPeriodUnit').value;
        if (this.termsForm.get('operativeAccountId').value) {
            this.showOperativeAccountId = true;
        } else {
            this.showOperativeAccountId = false;
        }
        if (this.termsForm.get('firstRepaymentDate').value) {
            this.showfirstRepaymentDate = true;
        } else {
            this.showfirstRepaymentDate = false;
        }
        if (this.termsForm.get('normalInterestRateMagnitude').value !== null) {
            this.showNormalInterestRate = true;
        } else {
            this.showNormalInterestRate = false;
        }
        if (this.termsForm.get('nominalInterestRateMagnitude').value !== null) {
            this.showNominalInterestRate = true;
        } else {
            this.showNominalInterestRate = false;
        }
        /* if (this.termsForm.get('moratoriumPeriodUnit').value === 'NONE') {
            this.moratoriumIsReadOnly = true;
        } else {
            this.moratoriumIsReadOnly = false;
        } */
        this.showTemplate = true;
    }

}
