import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MaterialLibModule } from '@app/material-lib.module';
import {AuthenticatedUserService} from '@ig-core/authentication/authenticated-user-service';
import { WorkflowComponent } from './workflow.component';

export const WorkflowRoute: Route = {
  path: 'workflow',
  canActivate: [AuthenticatedUserService],
  children: [
     {path: '', component: WorkflowComponent}]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
  ],
  declarations: [ ],
  entryComponents: []
})
export class WorkflowModule { }
