import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { ApplicationService } from '@app/applications/application.service';
import { FormGroup, FormControl } from '@angular/forms';
import { LoanApplication, Person ,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { MatSnackBar } from '@angular/material';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
import { ApplicantInformations } from '../../applicant/applicantInformation/applicant-information.model';
import { IgFileService } from '@ig-core/form/igFile.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
@Component({
  selector: 'eng-guarantor-info',
  templateUrl: './guarantor-info.template.html',
  styleUrls: ['../../application-details.styles.scss', './guarantor-info.styles.css']
})
export class GuarantorComponent implements OnInit  {
  @Input() application: LoanApplication;
  @Input() applicants: Person[];
  @Input() menuCode:string;

  listOfCoApplicant: Person[];
  genders: NameValueDto[];

  allowAccess: Boolean;
  menuItemAllowAccess:boolean;
  selectedCoApplicant: Person;
  applicantInformations: ApplicantInformations;
  personImageDetails:any
  applicantImage: any;
  imageLoaded: boolean;

  guarantorForm: FormGroup;
  isRadioButtonSelect: string;
  isGuarantorEnabled: boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  // here applicationDataChanged is used to update application data value after saving guarantor, this will emit value and this will be caught in application-details component.
  // then in application component we will update this.application with new updated value
  @Output() applicationDataChanged: EventEmitter<object> = new EventEmitter();
  // updateGuarantor is also emitted after saving guarantor to move selected guarantor to the end of the list of co-applicant
  @Output() updateGuarantor: EventEmitter<object> = new EventEmitter();

  constructor(
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private referenceCodeService: ReferenceCodeService,
    private fileService: IgFileService,
    private imagePreviewDialogService: ImagePreviewDialogService
  ) {
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.isGuarantorEnabled = false;
    this.guarantorForm = new FormGroup({
      selectedCoApplicant: new FormControl(""),
      coApplicants: new FormControl("")
    })
    this.getRefCodes();
    this.getMenuItemAccess();
    this.fetchCoApplicants();
  }

  getRefCodes(){
  this.referenceCodeService.getShortRefCodes('Gender').subscribe((response: any) => {
    this.genders = response.Gender;
  });
}

// this api is used to show selected guarantor image in guarantorDetails card
  loadApplicantImage(folderUid: string) {
    //Fetch all FileDtos stored in the folder
    this.fileService.getAllFilesFromFolder(folderUid).subscribe((response) => {
      if (response && response.body && response.body.length > 0) {
        this.personImageDetails=response.body
        //If response received then use fileId from response's first object to stream
        this.fileService.fileStreamById(response.body[0].fileId,
          "true").subscribe(image => {
            let reader = new FileReader();
            reader.addEventListener("load", () => {
              //"applicantImage" is used in img src attr
              this.applicantImage = reader.result;
              this.imageLoaded = true;
            }, false);
            if (image) reader.readAsDataURL(image.data);
          });
      }
    });
  }

  fetchCoApplicants() {
    // since guarantor is optional once if he selects any guarantor will not be able to reset hence we are pushing
    // customerDisplayName as none and uid as "0" to unselect already selected guarantor
    this.applicationService.getCoApplicants(this.application.uid).subscribe(response => {
      this.listOfCoApplicant = response.body;
          // atleast two coApplicant should be present to set any one as guarantor so we are checking first for length is greater than 2 or not
      if(this.listOfCoApplicant.length >= 2){
        this.isGuarantorEnabled = true
      }
      this.getSelectedCoApplicant();

      // when screen is loaded if we have already selected as guarantor then we need to show none option in the co-applicant list 
      // so if there is record in selectedCoApplicant then we will push none to the list and we will display
      // for the first case we don't need to display none in the co-applicant list hence if there is no guarantor selected then we are not pussing none to list 
      if(this.selectedCoApplicant){
        this.listOfCoApplicant.push({customerDisplayName : "None", uid: "0"})
        }
      this.buildForm();
    })
    } 

    buildForm() {
      this.guarantorForm.controls.selectedCoApplicant.patchValue(this.application.guarantorUid)
      this.guarantorForm.disable();
      if(this.selectedCoApplicant.profilePicFileUid){
      this.loadApplicantImage(this.selectedCoApplicant.profilePicFileUid);
    }else{
      this.imageLoaded = false;
    }
  }

    saveGuarantor() {
    // if none of the coApplicant is selected then we need to check radio button is selected or not we are using isRadioButtonSelect to check
    // if any of the radio button is selected then it will proceed or else it will show error 
      if (this.isRadioButtonSelect) {
      let guarantorInformation = {
      "applicationUid": this.application.uid,
      "applicationVersion": this.application.version,
      "personUid": this.guarantorForm.controls.coApplicants.value
    } 
    this.applicationService.saveGuarantor(this.application.uid, guarantorInformation).subscribe(response =>{
      // in success case after saving guarantor first we are calling getApplicationInfo to update application information and we are emitting this.applicatoin data to application-details.component
      this.getApplicationInfo();
      // if we select none and save then personUid is zero, this we are updating for guarantorUid and then we are calling
      // getSelectedCoApplicant to remove none option from the listOfCoapplicant, this will be done by matching personUid with guarantorUid
      this.application.guarantorUid = response.body.personUid;
      // to show the None at the last if the uid is 0 then we are pushing it to last else we and pushing the element to top of the list
      // so that the none select will always be at the last
      if(this.selectedCoApplicant){
        if(this.selectedCoApplicant.uid !== "0"){
            this.listOfCoApplicant.unshift( this.selectedCoApplicant)
        }else{
          this.listOfCoApplicant.push(this.selectedCoApplicant)
        }
      }
      // here we are checking because for the first time when we select guarantor and save we need to give option of none to select
      // hence for the first time nothing present in selectedCoApplicant then we will push none to list, so that after selecting any one guarantor then they will see the none option
      if(!this.selectedCoApplicant){
        this.listOfCoApplicant.push({customerDisplayName : "None", uid: "0"})
        }
        this.getSelectedCoApplicant();
        this.buildForm();
    },(failure) => {
      this._snackbar.open("Guarantor save failed, " + failure, "Close", {
        duration: 4000,
    })
    })
    }else{
      this._snackbar.open("Please select guarantor ", "Close", {
        duration: 4000,
    })
    }
}

  enableDisableForm() {
    this.guarantorForm.enable()
  }

  cancelForm() {
    this.guarantorForm.reset()
    this.buildForm()
  }

  // this method is used to check if any of the coApplicant is selected as guarantor or not if there is match found with guarantorUid
  // then we will set that to selectedCoApplicant and we are displaying the value in the first card.
  getSelectedCoApplicant(){
    this.listOfCoApplicant.forEach((person, index) => {
      if (person.uid == this.application.guarantorUid) {
        this.selectedCoApplicant = person
        this.listOfCoApplicant.splice(index, 1);
      }
    });
  }



//this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

    // this is used to view the image on click of the displayed image
    openImageViewElement() {
      let fileDetails=  {
        folderInfo : {},
        fileInfo :  [],
      }
      fileDetails.fileInfo=this.personImageDetails
     this.imagePreviewDialogService.open({ "images": fileDetails})
   }

   getApplicationInfo(){
    this.applicationService.getBasicApplicationInfo(
      this.application.uid, 'ApplicationOnly').subscribe((response) => {
        this.application = response.body.applicationDTO;
        this.applicationDataChanged.emit(this.application);
        this.updateGuarantor.emit();
      })
  }
  
}
