import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { ListSource } from '@ig-core/list/list-source';

import { BranchService } from '../branch.service';
import { BranchListDataSource } from './branch-list.data.source';
import { BranchDeleteDialogComponent } from './branch-delete-dialog.component';

@Component({
  selector: 'app-branch',
  templateUrl: 'branch-list.component.html',
  styleUrls: ['../branch.component.css']
})
export class BranchListComponent implements OnInit, AfterViewInit, OnDestroy {
  pagingAndSortParams: PagingAndSortParams;
  dataSource: BranchListDataSource;
  listSource: ListSource;

  displayedColumns = ['name', 'code', 'action'];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  constructor(
    private branchService: BranchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.dataSource = new BranchListDataSource(this.branchService);
    this.listSource = new ListSource(
      this.activatedRoute,
      this.paginator,
      this.sort
    );
    this.listSource
      .getListParams()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(listParams => {
        // Load paging and sort params from url
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        let sortColumn = this.pagingAndSortParams.sortColumn;
        if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
          sortColumn = 'uid';
        }
        // Load other, params and queryParams
        this.dataSource.loadBranchs(
          sortColumn + ',' + this.pagingAndSortParams.sortDirection,
          this.pagingAndSortParams.pageIndex,
          this.pagingAndSortParams.pageSize
        );
      });
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  ngAfterViewInit(): void {
    // initiate sort and pagination change once the view is initialized
    this.listSource
      .subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => this.loadBranchsPageWithParams());
  }

  loadBranchsPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  deleteDialog(branchData: any) {
    const dialogRef = this.dialog.open(BranchDeleteDialogComponent, {
      data: branchData
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(result => {
        if (result && result === 'deleted') {
          this.snackBar.open(`Deleted branch successfully`, 'Close');
          this.loadBranchsPageWithParams();
        }
      });
  }
}
