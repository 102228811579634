import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';
import { CollectionViewer } from '@angular/cdk/collections';
import { DeviationApproval, DeviationApprovalSearchCriteria } from './deviation-approval.model';
import { DeviationApprovalService } from './deviation-approval.service';


export class DeviationApprovalSource extends DataSource<DeviationApproval> {

  private deviationApprovalSubject = new BehaviorSubject<DeviationApproval[]>([]);
  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private deviationApprovalService: DeviationApprovalService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<DeviationApproval[]> {
    return this.deviationApprovalSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.deviationApprovalSubject.complete();
    this.totalCount$.complete();
  }

  getDeviationApprovals(sortDirection = 'asc', pageIndex = 0, pageSize = 10, deviationApprovalSearchCriteria: DeviationApprovalSearchCriteria) {
    this.deviationApprovalService.getDeviationApproval({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }, deviationApprovalSearchCriteria).pipe(
      catchError(() => of([])),
      finalize(() => { })
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['body'].totalElements);
      this.deviationApprovalSubject.next(response['body'].content);
    });
  }
} 