import { Injectable, Output, EventEmitter } from '@angular/core';
import {MatSidenav} from '@angular/material';
import {MediaMatcher} from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class IgLayoutService {

  private sideNav: MatSidenav;
  private innerSideNav: MatSidenav;

  private media: MediaMatcher;

  /* private isOuterSidenavOpen: boolean;
  private isInnerSidenavOpen: boolean; */
  
  @Output() selectedMenu: EventEmitter<any> = new EventEmitter();

  constructor(media: MediaMatcher) {
    this.media = media;
  }

  public getMediaQueryForMobile(): MediaQueryList {
    return this.media.matchMedia('(max-width: 600px)');
  }

  changeSelectedMenu(menu: string) {
    this.selectedMenu.emit(menu);
  }

  getSelectedMenu() {
    return this.selectedMenu;
  }

  public setSideNav(sidenav: MatSidenav) {
    this.sideNav = sidenav;
  }

  public setInnerSideNav(sidenav: MatSidenav) {
    this.innerSideNav = sidenav;
  }

  public closeSideNav() {
    this.sideNav.close();
    this.innerSideNav.close();
  }

  public toggleSideNav(): void {
    this.sideNav.toggle();
    /* if(this.innerSideNav.opened) this.innerSideNav.close();
    else{
      if(!this.isOuterSidenavOpen && this.isInnerSidenavOpen) this.innerSideNav.open();
    } */
  }

  /* changeOuterSidenavOpen(isOuterSidenavOpen: boolean) {
    this.isOuterSidenavOpen = isOuterSidenavOpen;
  }

  changeInnerSidenavOpen(isInnerSidenavOpen: boolean) {
    this.isInnerSidenavOpen = isInnerSidenavOpen;
  } */

}
