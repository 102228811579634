import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationAccessSpecifier, LoanApplication } from '@app/applications/applications.model';
import { MatSnackBar } from '@angular/material';
//validation code
import { ValidationService } from '../../../application-validators/validation.service'
import { LoggedInUserService } from '@ig-core/authentication/logged-in-user.service';
import { CoLenderDTO, ColenderCompositeDTO, ColenderInfoDTO } from './co-lender.model';
import { ControlValidators } from '@app/applications/application-validators/control-validators.directive';
@Component({
  selector: 'eng-co-lender',
  templateUrl: 'co-lender.template.html',
  styleUrls: ['../../application-details.styles.scss', 'co-lender.styles.css']
})
export class CoLenderComponent implements OnInit {
  @Input()
  application: LoanApplication;
  @Input()
  menuCode: string;

  loanProposedForm: FormGroup;
  coLenderForm: FormGroup;

  allowAccess: boolean;
  menuItemAllowAccess: boolean;
  colenderDto: CoLenderDTO;
  colenderCompositResponse: ColenderCompositeDTO;
  colenderInfo: ColenderInfoDTO = {};
  isFormDisabled: boolean;
  localPrestine: any;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,
    private loggedInUserService: LoggedInUserService,
    private validationService: ValidationService) {
      this.loanProposedForm = this.formBuilder.group({
        proposedProductName: '',
        proposedRequestedAmount: '',
        proposedTenure: '',
        proposedInterestRate: ''
      })

      this.coLenderForm = this.formBuilder.group({
        coLender: '',
        interestRate: '',
        sharePercentage: '',
        coLenderAmount: '',
        coLenderTenure: ''
      })
    
  }

  ngOnInit() {
    this.getMenuItemAccess();
    this.getCoLender();
    this.retreiveColender();
    this.allowAccess = !this.application.approvedFlag && this.applicationService.allowAccess;
  }

  // this api is to get the coLender dropdown values
  getCoLender(){
    this.applicationService.getCoLender().subscribe(response =>{
      this.colenderDto = response.body;
    })
  }

  // this api is to get coLenderDetails details saved for particular application
  retreiveColender(){
    this.applicationService.coLenderRetrieve(this.application.uid).subscribe(response =>{
      this.colenderCompositResponse = response.body;
      this.colenderInfo = {
        colenderCode: null,
        colenderPctg: null,
        colenderRoi:null,
        colenderTenure: null,
        colenderAmt: null
    };
    this.localPrestine = JSON.parse(JSON.stringify(this.colenderInfo));
      if(this.colenderCompositResponse.colenderInfo){
      this.colenderInfo = this.colenderCompositResponse.colenderInfo;
      // we are using localPrestine to store previous value to display when we click on cancel
      this.localPrestine = JSON.parse(JSON.stringify(this.colenderInfo));
      }
      this.onColenderChange();
      this.buildCoLenderForm();
    })
  }

  buildCoLenderForm() {
    this.loanProposedForm.patchValue({
      proposedProductName: this.colenderCompositResponse.productNameProp, 
      proposedRequestedAmount: this.colenderCompositResponse.amountProp,
      proposedInterestRate: this.colenderCompositResponse.interestProp,
      proposedTenure: this.colenderCompositResponse.tenureProp
    })

    this.coLenderForm.patchValue({
      coLender: this.colenderInfo && this.colenderInfo.colenderCode ? this.colenderInfo.colenderCode :'',
      interestRate: this.colenderInfo && this.colenderInfo.colenderRoi ? this.colenderInfo.colenderRoi : '',
      sharePercentage: this.colenderInfo && this.colenderInfo.colenderPctg ? this.colenderInfo.colenderPctg :'',
      coLenderAmount: '',
      coLenderTenure: this.colenderInfo && this.colenderInfo.colenderTenure ? this.colenderInfo.colenderTenure : ''
    })
    this.coLenderForm.disable();
    this.isFormDisabled = true;
    if(this.colenderInfo.colenderCode !== null && this.colenderInfo.colenderCode !== 'NONE'){
      this.setDefaultValues();
      }
    this.calculateAmount();
  }

  calculateAmount(){
    let sharePercentage = this.coLenderForm.controls.sharePercentage.value;
    let proposedRequestedAmount = this.colenderCompositResponse.amountProp;
    let calculatedAmount: any;

    if(sharePercentage){
    calculatedAmount = (sharePercentage * proposedRequestedAmount)/100;
    calculatedAmount = calculatedAmount.toFixed(2);
    this.coLenderForm.patchValue({
      coLenderAmount: calculatedAmount
    });
  }
  }

  onColenderChange(){
    let coLender = this.coLenderForm.controls.coLender.value
    // we need to clear all the field values, clear all the validation and need to disable all the fields except the coLender dropdown hence we are checking this
    if(coLender === 'NONE' || coLender === ''){
      this.coLenderForm.patchValue({
        interestRate: '',
        sharePercentage: '',
        coLenderAmount: '',
        coLenderTenure: ''
      });
      this.coLenderForm.disable();
      this.coLenderForm.controls.coLender.enable();
      let interestControl = this.coLenderForm.controls['interestRate'];
      interestControl.clearValidators();
      interestControl.updateValueAndValidity();

      let shareControl = this.coLenderForm.controls['sharePercentage'];
          shareControl.clearValidators();
          shareControl.updateValueAndValidity();
      return;
    }
    // we are doing this because if the dropdown value is not "NONE" then we need to enable all the fields and tenure and amount field will be always dissable
    this.coLenderForm.enable();
    this.disableFormFields();
    this.validateFormFields();
    this.setDefaultValues();
    this.calculateAmount();
  }

  setDefaultValues(){
  this.coLenderForm.patchValue({
    interestRate: this.colenderInfo && this.colenderInfo.colenderRoi ? this.colenderInfo.colenderRoi : this.colenderCompositResponse.interestProp,
    coLenderTenure: this.colenderCompositResponse.tenureProp
  })
}

validateFormFields(){
let colenderControl = this.coLenderForm.controls['coLender'];
          colenderControl.clearValidators();
          colenderControl.setErrors({});
          colenderControl.setValidators([  
          ControlValidators.requiredValidator("Co-lender is required")])
          colenderControl.updateValueAndValidity();

      let shareControl = this.coLenderForm.controls['sharePercentage'];
          shareControl.clearValidators();
          shareControl.setErrors({});
          shareControl.setValidators([  
          ControlValidators.maxLengthValidator(5,"Invalid"),  
          ControlValidators.numericRangeValidator(1, 100, false, true, "Share percentage should be in between 1 to 100")])
          shareControl.updateValueAndValidity();
      
      let interestControl = this.coLenderForm.controls['interestRate'];
          interestControl.clearValidators();
          interestControl.setErrors({});
          interestControl.setValidators([ControlValidators.requiredValidator("Interest is required"),
          ControlValidators.maxLengthValidator(5,"Invalid"),
          ControlValidators.minValueValidator(0.001, true,"Interest should be non-zero positive number",false)])
          interestControl.updateValueAndValidity();
}

  enableForm(){
    let coLender = this.coLenderForm.controls.coLender.value
    this.coLenderForm.enable();
    this.isFormDisabled = false;
    this.disableFormFields();
    this.onColenderChange();
    // this is done because if user click on edit and select colender as "NONE" and again if user click on edit then it will enable share% and interest fields.
    // since we are pre populating interest rate we need to clear the field as well, and we need to disable share% and interest fields. So we are checking for "NONE" condition
    if(coLender === 'NONE'){
      this.coLenderForm.disable();
      this.coLenderForm.patchValue({
        interestRate: ''
      })
      this.coLenderForm.controls.coLender.enable();
    }
  }

  saveLoanApplication(){
    this.validationService.markFormGroupTouched(this.coLenderForm)
    if(!this.isFormDisabled && this.coLenderForm.valid){
      this.colenderInfo.colenderCode = this.coLenderForm.controls.coLender.value;
      this.colenderInfo.colenderRoi = this.coLenderForm.controls.interestRate.value;
      this.colenderInfo.colenderPctg = this.coLenderForm.controls.sharePercentage.value;

      this.applicationService.saveColender(this.application.uid, this.colenderInfo).subscribe(response =>{
        this.colenderInfo = response.body;
        this._snackbar.open("Colender details saved successfully", "close", {
          duration: 5000,
        });
        // here instead of calling again the retreiveColender api we are updating the prestine value with the latest value 
        this.localPrestine = JSON.parse(JSON.stringify(this.colenderInfo));
        this.buildCoLenderForm();
        if(this.colenderInfo.colenderCode !== null && this.colenderInfo.colenderCode !== 'NONE'){
        this.setDefaultValues();
        }
      },(error)=>{
        this._snackbar.open(error, "close", {
          duration: 5000,
        });
      })
    }
  }

  cancelForm(){
     // we are updating colenderInfo to build the form with previous values if there is any error while saving the data
     this.colenderInfo = JSON.parse(JSON.stringify(this.localPrestine));
    this.buildCoLenderForm();
  }

  // these two fields need to be dissabled always. instead of disabling everytime we can call this function
  disableFormFields(){
    this.coLenderForm.controls.coLenderAmount.disable();
    this.coLenderForm.controls.coLenderTenure.disable();
  }

  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }


}
