import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AlertService, JhiLanguageService, DateUtils } from 'ng-jhipster';
import { Router } from '@angular/router';
import { DateConverterService } from '../shared/date/date-converter.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { TransactionSummaryDto } from './transaction-summary-dto.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { FeeDto } from './loan-od-accounts-fee-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-charge-fee-entry',
    templateUrl: './loan-od-accounts-charge-fee-entry.component.html'
})
export class LoanOdAccountsChargeFeeEntryComponent implements OnInit, OnDestroy {
    feeDto: FeeDto;
    @Input() loanOdAccounts: LoanOdAccounts;
    @Input() fee: any[];
    @Input() transactionSummaryDto: TransactionSummaryDto;
    instruments: any[] = [];
    accountCodes: any[];
    @Input() feeValues: any[];
    showReference: boolean;
    instrumentValuesForReference: string[];
    @Output() proceedToExecute: EventEmitter<TransactionSummaryDto> = new EventEmitter<TransactionSummaryDto>();
    defaultValue: any = null;
    constructor(private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService,
        private loanODAccountsService: LoanOdAccountsService,
        private router: Router,
        private dateUtils: DateUtils,
        private dateConverter: DateConverterService
    ) {
        this.instruments = null;
        this.accountCodes = null;
        this.instrumentValuesForReference = ['OTHER', 'CHEQUE_OR_DD', 'NEFT', 'RTGS', 'IMPS', 'CREDIT_CARD', 'DEBIT_CARD', 'AUTOPAY', 'UPI'];
    }
    ngOnInit() {
        if (this.transactionSummaryDto.param3) {
            this.paymentStatusChange(this.transactionSummaryDto.param3);
        }
        if (this.transactionSummaryDto.instrument) {
            this.instrumentChange(this.transactionSummaryDto.instrument);
        }
        if (this.transactionSummaryDto.amount1 === '-1') {
            this.transactionSummaryDto.amount1 = null;
        }
        this.transactionSummaryDto.instrument = null;
    }
    ngOnDestroy() {
    }

    getAmount(index: number) {
        const currentIndex = index - 1;
        if (typeof this.feeValues[currentIndex] !== 'undefined') {
            this.transactionSummaryDto.amount1 = this.feeValues[currentIndex];
        } else {
            this.transactionSummaryDto.amount1 = null;
        }
    }

    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }

    createNewLoan() {
        this.router.navigate(['/loan-od-accounts/create']);
    }

    proceed() {
        if (this.transactionSummaryDto.accountId !== undefined) {
            const accountOpenDate = this.dateConverter
                .fromServerToDateObject(this.loanOdAccounts.accountOpenDate).getTime();
            const valueDate = this.dateConverter
                .fromNgbDatePickerToDateObject(this.transactionSummaryDto.valueDate).getTime();
            const amount = Number(this.transactionSummaryDto.amount1).valueOf();
            const zero = Number(0).valueOf();
            if (valueDate < accountOpenDate) {
                const dateString = this.dateUtils.convertLocalDateToServer(
                    this.dateConverter.fromServerToNgbDatePicker(
                        this.loanOdAccounts.accountOpenDate));
                this.alertService.error('Fee Date cannot be before Account Open Date: ' + dateString);
            } else if (this.transactionSummaryDto.amount1 === undefined || this.transactionSummaryDto.amount1 === null || amount <= zero) {
                this.alertService.error('Amount should be grater than 0.');
            } else {
                this.feeDto = new FeeDto();
                this.feeDto.amount.magnitude = parseInt(this.transactionSummaryDto.amount1);
                this.feeDto.amount.currency = this.transactionSummaryDto.currencyCode;
                this.feeDto.accountId = this.transactionSummaryDto.accountId;
                this.feeDto.chargedValueDate = this.dateConverter.fromNgbDatePickerToString(this.transactionSummaryDto.valueDate);
                this.feeDto.transactionName = 'Fee Charge';
                this.feeDto.feeDescription = '';
                this.feeDto.feeCategory = this.transactionSummaryDto.param1;
                this.feeDto.currencyCode = this.transactionSummaryDto.currencyCode;
                this.feeDto.grossAmount.magnitude = parseInt(this.transactionSummaryDto.amount1);
                this.feeDto.grossAmount.currency = this.transactionSummaryDto.currencyCode;
                this.feeDto.instrument = this.transactionSummaryDto.instrument;
                this.feeDto.reference = this.transactionSummaryDto.reference;
                if (this.transactionSummaryDto.part6) {
                    this.feeDto.deduction.magnitude = this.transactionSummaryDto.part6 ? parseInt(this.transactionSummaryDto.part6) : 0;
                    this.feeDto.deduction.currency = this.transactionSummaryDto.part6 ? this.transactionSummaryDto.currencyCode : '';
                    this.feeDto.deduction.displayValue = this.transactionSummaryDto.part6 ? 'Rs. ' + this.transactionSummaryDto.currencyCode : '';
                }
                else {
                    this.feeDto.deduction = null;
                }
                this.loanODAccountsService.chargeFeeExecute(this.feeDto).subscribe((res) => this.onSaveSuccess(res), (res: Response) => this.onSaveError(res));
            }
        }
    }
    private onSaveSuccess(res) {
        this.proceedToExecute.emit(res);
    }
    private onSaveError(error) {
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
                this.onError();
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError();
                }
            } catch (exception) {
                this.onError();
            }
        }

    }
    private onError() {
        this.alertService.error('encoreclientApp.loanOdAccounts.saveError');
    }

    paymentStatusChange(paymentStatus) {
        if (paymentStatus && this.instruments == null) {
            this.loanODAccountsService.getInstruments().subscribe(
                (res) => {
                    this.instruments = res;
                    if (!this.transactionSummaryDto.instrument) {
                        this.transactionSummaryDto.instrument = this.instruments[0].code;
                    }
                    if (!this.transactionSummaryDto.part6) {
                        this.transactionSummaryDto.part6 = '0';
                    }
                }
            );

            this.instrumentChange(this.transactionSummaryDto.instrument);
            this.instrumentChange(this.transactionSummaryDto.instrument);
            this.showReference = false;
        }
    }

    instrumentChange(instrument) {
        const flag = this.instrumentValuesForReference.find(
            (value) => value === instrument
        )
        if (flag && this.accountCodes == null) {
            this.loanODAccountsService.getAccountCodesList().subscribe(
                (res) => { this.accountCodes = res; }
            );
            this.showReference = true;
        } else if (flag) {
            this.showReference = true;
        } else {
            this.showReference = false;
        }
    }
}
