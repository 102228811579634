import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import { MaterialLibModule } from '@app/material-lib.module';

import {EditableResolveService} from '@ig-core/resolves/editable-resolve.service';
import {NonEditableResolveService} from '@ig-core/resolves/non-editable-resolve.service';
import {AuthenticatedUserService} from '@ig-core/authentication/authenticated-user-service';

import {BranchResolverService} from '@app/admin/branch/branch.resolve';
import {BranchListComponent} from '@app/admin/branch/branch-list/branch-list.component';
import {BranchDeleteDialogComponent} from '@app/admin/branch/branch-list/branch-delete-dialog.component';
import {BranchEditorComponent} from '@app/admin/branch/branch-edit/branch-editor.component';

export const branchRoute: Route = {
  path: 'branch',
  canActivate: [AuthenticatedUserService],
  children: [
     {path: '', component: BranchListComponent},
    {path: 'create', component: BranchEditorComponent,
      resolve: {
        editable: EditableResolveService
      }},
    {path: 'update/:uid', component: BranchEditorComponent,
      resolve: {
        branch: BranchResolverService,
        editable: EditableResolveService
      }
    },
    {path: 'view/:uid', component: BranchEditorComponent,
      resolve: {
        branch: BranchResolverService,
        editable: NonEditableResolveService
      }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [ BranchEditorComponent, BranchDeleteDialogComponent],
  entryComponents: [BranchDeleteDialogComponent]
})
export class BranchModule { }
