import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'showName'
})
export class ReferenceCodeShowNamePipe implements PipeTransform {
  /* 
    Parameters -
    1. searchCode = 'code' string for which we want corresponding 'name'.
        Can be multiple also as a comma separated string, in which case a comma
        separated string of corresponding names are returned.
    2. referenceCodes = the reference list in which to search for input 
        and code(s) and return the corresponding name(s).
  */
  transform(searchCode: string, referenceCodes: any[]): string {
    
    /* If search code is not received (if null or undefined)
        or received as empty, return an empty string. */
    if(!searchCode || searchCode === '') return '';

    /* If reference list is not received (if null or undefined)
        or received as empty (no elements), return back search code as received. */
    if(!referenceCodes || referenceCodes.length === 0) return searchCode;

    // If execution reaches here, then both input parameters are received non empty.
    // variable to return the corresponding name(s).
    let referenceCodeName = '';

    /* Convert comma separated search code input string to a list, 
        so as to be able to interate over it. */
    let searchCodes = searchCode.split(",");
    
    searchCodes.forEach(searchCode => {
      /* Find the object in reference list which has serachCode
          value in it's 'code' property */
      let referenceCode = referenceCodes.find(referenceCode =>
        referenceCode.code === searchCode);
      
      /* If the return variable is not empty, suffix with a comma. */
      referenceCodeName += referenceCodeName === '' ? '' : ', ';

      /* Append the matching object's 'name' property to return variable.
          If no matching object is found append searchCode as received. */
      referenceCodeName += referenceCode ? referenceCode.name : searchCode;
    });

    /* Single string name or a comma separated string of names corresponding to
        searchCode input parameter. */
    return referenceCodeName;
  }
}