import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JhiLanguageService, AlertService} from 'ng-jhipster';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdRepaymentCompositeDto } from './loan-od-accounts-repayment-composite-dto.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
//import { Bank } from '../../base/bank';
import { DateConverterService } from '../shared/date/date-converter.service';

@Component({
    selector: 'jhi-loan-od-accounts-composite-repayment',
    templateUrl: './loan-od-accounts-composite-repayment.component.html'
})
export class LoanOdAccountsCompositeRepaymentComponent implements OnInit, OnDestroy {
    // bank: Bank;
    loanOdAccounts: LoanOdAccounts;
    loanOdRepaymentCompositeDto: LoanOdRepaymentCompositeDto;
    proceedIsTrue: boolean;
    proceedToResult: boolean;
    instruments: any[] = [];
    repaymentEffects: any[] = [];

    constructor(private route: ActivatedRoute,
        private router: Router,
        private dateConverter: DateConverterService,
        private loanODAccountsService: LoanOdAccountsService,
        private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.proceedIsTrue = false;
        this.proceedToResult = false;
    }
    ngOnInit() {
        this.loanOdRepaymentCompositeDto = this.route.snapshot.data['loanOdRepaymentCompositeDto'];
        this.loanOdAccounts = this.route.snapshot.data['loanOdAccounts'];
        // this.loanOdRepaymentCompositeDto.repaymentDate = this.dateConverter.fromServerToNgbDatePicker(this.loanOdRepaymentCompositeDto.repaymentDate);
        this.loanODAccountsService.getInstruments().subscribe(
            (res) => {
                this.setInstruments(res);
                this.loanOdRepaymentCompositeDto.instrument = this.instruments[0].code;
        });
        this.loanODAccountsService.getRepaymentEffects().subscribe(
            (res) => {
                this.setRepaymentEffects(res);
        });
    }

    private setInstruments(data) {
        this.instruments = data;
    }

    private setRepaymentEffects(data) {
        this.repaymentEffects = data;
    }

    ngOnDestroy() {
    }

    clear() {
        this.loanOdAccounts = new LoanOdAccounts();
        this.router.navigate(['/loan-od-accounts']);
    }

    proceed(loanOdRepaymentCompositeDto) {
        this.loanOdRepaymentCompositeDto = loanOdRepaymentCompositeDto;
        // this.loanOdRepaymentCompositeDto.repaymentDate = this.dateConverter.fromServerToDateObject(this.loanOdRepaymentCompositeDto.repaymentDate);
        this.proceedIsTrue = true;
    }

    back() {
        // this.loanOdRepaymentCompositeDto.repaymentDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.loanOdRepaymentCompositeDto.repaymentDate);
        this.proceedIsTrue = false;
    }

    executeError() {
        // this.loanOdRepaymentCompositeDto.repaymentDate = this.dateConverter.fromStringToNgbDatePicker(this.loanOdRepaymentCompositeDto.repaymentDate);
        this.proceedIsTrue = false;
    }

    executeSuccess(result) {
        this.loanOdRepaymentCompositeDto = result;
        this.proceedToResult = true;
    }
}

