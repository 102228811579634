import { Component, Input, OnInit } from "@angular/core";
import { BankAccountVerificationResponseComposite } from "@app/applications/application-details/business/businessBankAccount/business-bank-accounts.model";
import { BureauResponse } from "../xdata-response.model";
import { RowValueDto } from "@app/applications/applications.model";

@Component({
  selector: "bureau-verification",
  templateUrl: "bureau-verification.template.html",
  styleUrls: [
    "../../../application-details.styles.scss",
    "../xdata-service.style.scss",
  ],
})
export class BureauVerificationComponent implements OnInit {
  @Input()
  documentVerificationResponse: BureauResponse;

  constructor( ) { }

  ngOnInit() {

  }

}