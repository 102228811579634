
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { LoanApplication,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { PreConditions } from '../preCondition/pre-condition.model';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'eng-approval-pre-condition',
  templateUrl: './approval-pre-condition.component.html',
  styleUrls: ['../../application-details.styles.scss','./approval-pre-condition.component.css',]
})
export class ApprovalPreConditionComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;


  preConditionsItems: any;
  preConditionForm: FormGroup

  allowAccess:Boolean;
  disableEdit:Boolean;
  hideOrShowTrail:boolean=false;
  menuItemAllowAccess:boolean;

  trailLabel:string='HIDE TRAIL';

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar) {
      this.allowAccess = this.applicationService.allowAccess;
     }

  ngOnInit() {
    this.getPreConditionItem();
    this.getMenuItemAccess();

  }

  getPreConditionItem() {
    this.applicationService.getPreConditions(this.application.uid).subscribe(response => {
      this.preConditionsItems = response.body
      if(this.preConditionsItems.state == "fulfillment"){
        this.disableEdit = true
        this._snackbar.open("One or more conditions are not fulfilled yet. Return the application", "Close", {
          duration: 5000,
        });
       }else{
        this.disableEdit = false
      }
      this.preConditionForm = this.formBuilder.group({
        //preConditionCompletedFlag: new FormArray(this.preConditionsItems.conditions.map(data => new FormControl(data.condition.completedFlag))),
        preConditionRemarks: new FormArray(this.preConditionsItems.conditions.map(data => new FormControl(data.condition.recommendRemarks))),
        preConditionRecommendFlag: new FormArray(this.preConditionsItems.conditions.map(data => new FormControl(data.condition.recommendFlag))),
      })
      this.preConditionForm.disable()
    })
  }

  savePreCondition() {
    if (this.preConditionForm.get('preConditionRecommendFlag')['controls'].length !== 0 && this.preConditionForm.get('preConditionRemarks')['controls'].length !== 0) {
      this.preConditionsItems.conditions.forEach((element, index) => {
        element.approvaltrail = []
        element.condition.recommendFlag = this.preConditionForm.get('preConditionRecommendFlag')['controls'][index].value
        element.condition.recommendRemarks = this.preConditionForm.get('preConditionRemarks')['controls'][index].value
      });
    }
    this.applicationService.savePreconditions(this.application.uid,
      this.preConditionsItems).subscribe((response: any) => {
        this.getPreConditionItem();
        this.preConditionForm.disable()
      });
  }

  enableDisableForm() {
    this.preConditionForm.enable()
  }

  cancelForm() {
    this.preConditionForm.disable()
    this.preConditionForm = this.formBuilder.group({
      //preConditionCompletedFlag: new FormArray(this.preConditionsItems.conditions.map(data => new FormControl(data.condition.completedFlag))),
      preConditionRemarks: new FormArray(this.preConditionsItems.conditions.map(data => new FormControl(data.condition.recommendRemarks))),
      preConditionRecommendFlag: new FormArray(this.preConditionsItems.conditions.map(data => new FormControl(data.condition.recommendFlag))),
    })
   
    
  }

  hideOrShowTrailfunction(){
    this.hideOrShowTrail=!this.hideOrShowTrail
    if(this.hideOrShowTrail){
      this.trailLabel='SHOW TRAIL'
    }else{
      this.trailLabel='HIDE TRAIL'
    }
   
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}




