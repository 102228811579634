export class ProductMasterComposite {
    constructor(
        public uid?: string,
        public encoreProductCode?: string,
        public productType?: string,
        public productCode?: string,
        public productName?: string,
        public minAmount?: number,
        public maxAmount?: number,
        public minTenure?: number,
        public maxTenure?: number,
        public defaultTenure?: number,
        public tenureUnit?: string,
        public minInterest?: number,
        public maxInterest?: number,
        public defaultInterest?: number,
        public workflowName?: string,
        public startDate?: Date,
        public sourcingEndDate?: Date,
        public changeEndDate?: Date,
        public processEndDate?: Date,
        public sourcingFlag?: Boolean,
        public changeFlag?: Boolean,
        public processFlag?: Boolean,
        
        public status?: string,
        public version?: number,
        public collateralSet?: string
    ) { }
}