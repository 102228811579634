import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { LeadDetails,UpdateLead, LeadSearchCriteria, Lead } from './leads.model';
import { DatePipe } from '@angular/common';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
@Injectable({
    providedIn: 'root'
})
export class LeadsService {

    private API = 'api/leads';
    private APIv1 = 'api/v1/leads/';

    constructor(private http: HttpClient,
        private customDatepipe: DateFormatPipe) { }

    getLeads(pagingAndSortingCriteria: any, searchCriteria: LeadSearchCriteria): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.set('page', pagingAndSortingCriteria.page);
        params = params.set('size', pagingAndSortingCriteria.size);
        params = params.set('sort', pagingAndSortingCriteria.sort);
        
        searchCriteria.leadDateFrom = this.customDatepipe.transform(
            searchCriteria.leadDateFrom,'ISODATE');
        searchCriteria.leadDateTo = this.customDatepipe.transform(
            searchCriteria.leadDateTo,'ISODATE');

        return this.http.post<any>(this.API + '/list', searchCriteria, { params, observe: 'response' });
    }

    getLeadDetails(leadUid) {
        return this.http.get<LeadDetails>(this.API + '/details/' + leadUid, { observe: 'response' });
    }

    saveLead(lead: UpdateLead) {
        return this.http.put<any>(this.APIv1 + lead.opportunity.uid + '/save',
            lead, {observe: 'response'});
    }

    getInteractionTrail(opportunityUid){
        return this.http.get<LeadDetails>(this.API +'/'+ opportunityUid+'/interactiontrail', { observe: 'response' });
    }

    saveInteractionTrail(opportunityUid,trail) {
        return this.http.post<any>(this.API +'/'+ opportunityUid + '/interactions/'+0+'/save',
        trail, {observe: 'response'});
    }

}

