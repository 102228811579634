import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, EventManager, JhiLanguageService } from 'ng-jhipster';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { TransactionSummaryDto } from './transaction-summary-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-reverse-entry',
    templateUrl: './loan-od-accounts-reverse-entry.component.html'
})
export class LoanOdAccountsReverseEntryComponent implements OnInit, OnDestroy {
    onError: any;
    transactionSummaryDto:  any;
    transactionSummaryDtoReplica:  TransactionSummaryDto [] = [];
    transactionSummaryDtoReverse:  TransactionSummaryDto [] = [];
    transactionReverseObject: any;

    constructor(  private jhiLanguageService: JhiLanguageService,
                  private alertService: AlertService,
                  private loanODAccountsService: LoanOdAccountsService,
                  private eventManager: EventManager,
                  private route: ActivatedRoute,
                  private router: Router
    ) {
    }
    ngOnInit() {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.transactionSummaryDto = this.route.snapshot.data['transactionSummaryDto'];
        this.transactionSummaryDtoReplica = this.route.snapshot.data['transactionSummaryDto'];
    }
    ngOnDestroy() {
    }

    previousState() {
        window.history.back();
    }

    clear() {
        this.transactionSummaryDto = new TransactionSummaryDto();
        this.router.navigate(['/loan-od-accounts']);
    }

    createNewLoan() {
        this.router.navigate(['/loan-od-accounts/create']);
    }

    execute(i) {
        for ( const j in this.transactionSummaryDtoReplica) {
            if (i <= Number(j)) {
                this.transactionSummaryDtoReverse.push(this.transactionSummaryDto[j]);
            }
        }
        this.loanODAccountsService.setTransactionSummaryDto(this.transactionSummaryDtoReverse);
        this.router.navigate(['/loan-od-accounts/reverseExecute']);
    }

    goTo(res) {
        this.transactionSummaryDto = res;
        this.router.navigate(['/loan-od-accounts/reverseExecute'], this.transactionSummaryDto);
    }
}
