import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { ApplicationQuestionnaire, Questionnaire } from '../cam.model';

@Component({
  selector: 'eng-question-answer-system',
  templateUrl: './question-answer-system.template.html',
  styleUrls: ['../../application-details.styles.scss', './question-answer-system.style.scss']
})
export class QuestionAnswerSystemComponent implements OnInit {
  
  @Input() application: LoanApplication;
  
  @Input() questionnaireCode: string;
  
  @Input() menuCode: string;

  QandAForm: FormGroup

  applicationQuestionByQID: Questionnaire & ApplicationQuestionnaire;
  applicationQuestion: ApplicationQuestionnaire = null;
  allQuestionForCode: Questionnaire;

  prefilledDataSet: any = {}

  allowAccess: Boolean;
  menuItemAllowAccess: boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private applicationService: ApplicationService, private fileService: IgFileService, private imagePreviewDialogService: ImagePreviewDialogService,
    private _snackbar: MatSnackBar, private customDatepipe: DateFormatPipe, private domSanitizer: DomSanitizer) {
    this.allowAccess = this.applicationService.allowAccess;
  }


  ngOnInit() {
    this.QandAForm = new FormGroup({
      questionnaireResponseDetail: new FormArray([])
    })
    this.QandAForm.disable()
    this.getQandAbyQuestionnaireCode()
    this.getApplicationQandAbyQuestionnaireCode()
    this.getQuestionPrefilledData()
    this.getMenuItemAccess();

  }


  //"CAMCMQ"
  //"BCPVQ"
  //"RCPVQ"
  getQandAbyQuestionnaireCode() {
    this.applicationService.getquestionnairebyCode(this.questionnaireCode).subscribe(response => {
      this.allQuestionForCode = response.body[0]
    
     if( this.applicationQuestionByQID == undefined){
      console.log("IN getQandAbyQuestionnaireCode" ,this.applicationQuestionByQID)
      this.applicationQuestionByQID = response.body[0]
      this.parseQuestionConfiguration()
      this.buildQandAForm()
      }
    })
  }


  //this function will get a application question's for particular questionnaire code
  getApplicationQandAbyQuestionnaireCode() {
    this.applicationService.getApplicationQuestionnairebyCode(this.application.uid, this.questionnaireCode).subscribe(response => {
      this.applicationQuestionByQID = response.body
      this.applicationQuestion = response.body
      console.log("IN getApplicationQandAbyQuestionnaireCode" ,this.applicationQuestionByQID)
      if (response.body == null) {
        this.applicationQuestionByQID = this.allQuestionForCode as any
      } else {
        this.parseQuestionConfiguration()
      this.buildQandAForm()       
    }
    });
  }


  buildQandAForm() {
    this.QandAForm.controls.questionnaireResponseDetail = new FormArray([])
    const array = this.QandAForm.controls.questionnaireResponseDetail as FormArray;
    this.applicationQuestionByQID.questions.forEach((element) => {
      const group = new FormGroup({
        answer: new FormControl(element.questionnaireResponseDetail !== null ? element.questionnaireResponseDetail.answer : ''),
        questionUid: new FormControl(element.questionnaireResponseDetail !== null ? element.questionnaireResponseDetail.questionUid : ""),
      })
      array.push(group);
    });
    this.QandAForm.disable()
  }


  enableDisableForm() {
    this.QandAForm.enable()
  }


  saveQuestionAndAnswer() {
    let todaydate = new Date();
    this.setQuestionId()
    this.stringfyTheMultimediaFileNameIdMapString(this.applicationQuestionByQID)
    this.applicationQuestionByQID["applicationUid"] = this.application.uid
    this.applicationQuestionByQID["questionnaireUid"] = this.applicationQuestionByQID.questionnaireUid === undefined ? this.applicationQuestionByQID.uid : this.applicationQuestionByQID.questionnaireUid
    this.applicationQuestionByQID["endTime"] = this.customDatepipe.transform(todaydate, 'CAMDATE');
    if (this.applicationQuestion == null) {
      this.applicationQuestionByQID["startTime"] = this.customDatepipe.transform(todaydate, 'CAMDATE');
      delete this.applicationQuestionByQID.uid
      delete this.applicationQuestionByQID.name
      delete this.applicationQuestionByQID.version
      delete this.applicationQuestionByQID.description
      delete this.applicationQuestionByQID.questionnaireVersionCode
      delete this.applicationQuestionByQID.questionnaireVersionName
      delete this.applicationQuestionByQID.status
      delete this.applicationQuestionByQID.code
    }
    delete this.applicationQuestionByQID.startTime
    delete this.applicationQuestionByQID.questions
    this.applicationService.saveApplicationquestionnaireAnswers(this.applicationQuestionByQID).toPromise().then(
      (_success) => {
        this._snackbar.open("Answers are updated successfully", "Close", {
          duration: 2000,
        });
        this.getApplicationQandAbyQuestionnaireCode()
      }, (failure) => {
        let errormesg =[]
        errormesg.push("Answers update failed")
        errormesg.push(failure)
        this.applicationService.displayErrorMessages(errormesg);
        console.log(failure);
      }
    );
  }


  cancelForm() {
    // this.QandAForm.controls.questionnaireResponseDetail = new FormArray([])
    this.QandAForm.disable();
    this.buildQandAForm()
  }


  parseQuestionConfiguration() {
    if (this.applicationQuestionByQID.questions) {
      this.applicationQuestionByQID.questions.forEach((element, index) => {
        element.configuration = JSON.parse(element.configuration)
        if (element.questionnaireResponseDetail && element.questionnaireResponseDetail.multiMediaFileNameIdMapString != null) {
          // element.questionnaireResponseDetail.multiMediaFileNameIdMapString = element.questionnaireResponseDetail.multiMediaFileNameIdMapString.replace(/=/g, ":");
          element.questionnaireResponseDetail.multiMediaFileNameIdMapString = JSON.parse(element.questionnaireResponseDetail.multiMediaFileNameIdMapString)
          element.questionnaireResponseDetail["imageFiles"] = []
          this.getMultiMediaFiles(element.questionnaireResponseDetail.multiMediaFileNameIdMapString, index)
        }

      });
    }
  }


  //this function will call while saving a response
  //answers array will be generated based on save or update for a particular question
  //if a question exists "questionnaireResponseDetail" the complete question object will pushed to answers array
  // if a question does not exists "questionnaireResponseDetail" the answer and questionUid set with values will be pushed to answers array
  setQuestionId() {
    this.applicationQuestionByQID.answers = (this.applicationQuestionByQID.answers == null || this.applicationQuestionByQID.answers == undefined) ? [] : this.applicationQuestionByQID.answers
    this.applicationQuestionByQID.questions.forEach((element, index) => {
      const formgroup = this.QandAForm.get("questionnaireResponseDetail")['controls'][index]
      if (element.questionnaireResponseDetail !== null) {
        let newObject = element.questionnaireResponseDetail
        newObject.answer = formgroup.controls.answer.value
        this.applicationQuestionByQID.answers.push(newObject)
      } else {
        this.applicationQuestionByQID.answers.push({ "answer": formgroup.controls.answer.value, "questionUid": element.uid })
      }
    });
  }

  //this function will get prefilled CAM Data
  getQuestionPrefilledData() {
    this.applicationService.getQuestionPrefilledDataforApplication(this.application.uid).subscribe(response => {
      this.prefilledDataSet = response.body
    })
  }


  getMultiMediaFiles(files, index) {
    const images = [];
  //  console.log("files", files)
    for (var propName in files) {
      let imagedata = {}
      this.fileService.fileStreamById(files[propName],
        "true").subscribe(image => {
          let reader = new FileReader();
          reader.addEventListener("load", () => {
            let result = reader.result as string
            let fileContent = this.domSanitizer.bypassSecurityTrustUrl(result) as SafeUrl;
            imagedata = { "imageLoaded": true, "fileContent": fileContent };
            this.applicationQuestionByQID.questions[index].questionnaireResponseDetail.imageFiles.push(imagedata)
          }, false);
          if (image) reader.readAsDataURL(image.data);
        });
    }
  }

  //this function is called on clicking of question images's
  // this will open up a image viewer dialog box
  openImageViewer(documents) {
    this.imagePreviewDialogService.open({ "images": documents })
  }
  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  //this function is called before saving answers
  //to format the "multiMediaFileNameIdMapString" parameter to string
  //api will accept this "multiMediaFileNameIdMapString" parameter if datatype is string
  stringfyTheMultimediaFileNameIdMapString(data) {
    this.applicationQuestionByQID.questions.forEach((element, index) => {
      if(element.questionnaireResponseDetail){
      if (element.questionnaireResponseDetail["imageFiles"]) {
        delete element.questionnaireResponseDetail.imageFiles
      }
      element.questionnaireResponseDetail.multiMediaFileNameIdMapString = JSON.stringify(element.questionnaireResponseDetail.multiMediaFileNameIdMapString)
    }
     
    })
  
  }
}