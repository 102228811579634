import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SnackbarService } from '@app/utils/multiple-snackbar-messages-display.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApplicationDateService {
    lmsComponentsAccessFlag: boolean = true;
    private baseUrl = environment.API_URL;
    public bank: any;
    private applicationDate = new BehaviorSubject(undefined);
    private applicationDateDisplay = new BehaviorSubject(undefined);
    private bankNameValue = new BehaviorSubject(undefined);
    date = this.applicationDate.asObservable();
    dateDisplay = this.applicationDateDisplay.asObservable();
    bankName = this.bankNameValue.asObservable();
    constructor(
        private http: HttpClient,
        private _snackbar: MatSnackBar
    ) { }

    getDate() {
        return this.date;
    }

    getDateDisplayValue() {
        return this.dateDisplay;
    }

    getBankName() {
        return this.bankName;
    }

    getLmsComponentAccessStatus(){
        return this.lmsComponentsAccessFlag
    }

    syncDate() {
            this.http.get(`${this.baseUrl}/encore/api/banks/bankDetails`).subscribe(
                (res) => {
                    this.bank = res;
                    this.applicationDate.next(this.bank.currentWorkingDate);
                    this.applicationDateDisplay.next(this.bank.displayCurrentWorkingDate);
                    this.bankNameValue.next(this.bank.bankName);
                    this.lmsComponentsAccessFlag = true;
                },
                (res) => {
                    this.lmsComponentsAccessFlag = false;
                    console.log('Failure to update application date.'); }
            );
    }


}
