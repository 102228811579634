import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import { AllPipesModule } from '../utils/pipes.module'
import { MaterialLibModule } from '@app/material-lib.module';
import { TasksRoutingModule } from './tasks-routing.module';
import { TasksComponent } from './tasks.component';
import{ Task} from './task.model';




@NgModule({
    imports: [
      TasksRoutingModule,
      BrowserModule,
      HttpClientModule,
      MaterialLibModule,
      FormsModule,
      RouterModule,
      FlexLayoutModule,
      ReactiveFormsModule,
      AllPipesModule
    ],
    declarations: [TasksComponent]
})
export class TasksModule { }