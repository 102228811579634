import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { LiabilityComposite, LiabilityEmi, Liability } from './liability.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ValidationService } from '../../../application-validators/validation.service'
import { ActivatedRoute } from '@angular/router';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { MatSnackBar, DateAdapter } from '@angular/material';
@Component({
  selector: 'eng-liability',
  templateUrl: 'liability.template.html',
  styleUrls: ['../../application-details.styles.scss', 'liability.style.scss']
})
export class LiabilityComponent implements OnInit {
  @Input()
  application: LoanApplication;
  @Input()
    menuCode:string;

  applicationID: string
  liabilityComposite: LiabilityComposite[];
  liabilityTypes: NameValueDto[];
  liability: Liability;
  selectedLiability: LiabilityComposite;
  //for save
  LiabilityObject: LiabilityComposite;
  selectedLiabilityObject: LiabilityComposite;
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  liabilityForm: FormGroup;


  showForm: boolean;
  isFormDisabled: boolean;
  creditCardLiabilityTypes: boolean;
  showType: boolean = false;
  allowAccess: Boolean;
  cfaFinalized: boolean;
  menuItemAllowAccess:boolean;

  selectedLiabilitype: string = "";
  recentMonths: Array<string> = [];
  totalNumberOfLiabilities:number=0;
  sumofLiabilities:number=0;
  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private route: ActivatedRoute,
    private customDatepipe: DateFormatPipe,
    private _snackbar: MatSnackBar,
    private validationService: ValidationService,
    private dateAdapter: DateAdapter<Date>) {
    this.liabilityForm = this.formBuilder.group({
      creditInstitution: '',
      product: '',
      amount: '',
      emi: '',
      tenure: '',
      paidEmi: '',
      emiBounce6m: '',
      emiBounceTotal: '',
      creditProposedListedFlag: '',
      obligationFlag: '',
      liabilityType: {value:'',disable:true},
      accNumber: '',
      m1RepayDate: '',
      m2RepayDate: '',
      m3RepayDate: '',
      m4RepayDate: '',
      m5RepayDate: '',
      m6RepayDate: '',
      m7RepayDate: '',
      m8RepayDate: '',
      m9RepayDate: '',
      m10RepayDate: '',
      m11RepayDate: '',
      m12RepayDate: ''
    });
    this.allowAccess = this.applicationService.allowAccess;
    this.dateAdapter.setLocale('en-IN');
  }

  ngOnInit() {
    this.getRefCodes();
    this.getCfaStatus()
    this.route.parent.params.subscribe(params => {
      this.applicationID = params['applicationUid'];
    });
    this.liabilityComposite = [];
    this.liability = {};
    this.applyValidatorsToLiabilirty();
    this.isFormDisabled = true;
    this.getMenuItemAccess();

  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('family_liability').subscribe((response: any) => {
      this.liabilityTypes = response.family_liability;
    });
  }

  fetchLiability() {
    this.showForm = false;
    this.applicationService.getLiabilityDetails(this.application.uid).subscribe((response: any) => {
      if (response) {
        this.liabilityComposite = response;
        this.totalNumberOfLiabilities=0
        this.sumofLiabilities=0
        this.liabilityComposite.forEach((object, index) => {
        if(object.liability.liabilityType == 'LOAN'){
          this.totalNumberOfLiabilities=this.totalNumberOfLiabilities+1
          this.sumofLiabilities=this.sumofLiabilities+object.liability.amount
        }
        })
        this.showForm = (this.liabilityComposite && this.liabilityComposite.length > 0);
        this.fetchAssessmentYearAndMonths();
      }
    })
  }

  fetchAssessmentYearAndMonths() {
    this.applicationService.getAssessmentYearAndMonths(this.application.uid)
      .subscribe((response: any) => {
        if (response) {
          this.recentMonths = response.recentMonths;
          this.showForm = (this.liabilityComposite && this.liabilityComposite.length > 0);
          this.buildLiabilityForm();
        }
      });
  }

  changeSelectedLiability(liabilitySelect: LiabilityComposite) {

    if (!liabilitySelect) {
      liabilitySelect = new LiabilityComposite();
      liabilitySelect.liability = new Liability();
    }
    this.showForm = true;
    this.displayFormByLiabilityType(liabilitySelect.liability.liabilityType)
    this.buildLiabilityForm(liabilitySelect);
  }

  buildLiabilityForm(liabilitySelect?: LiabilityComposite) {
    if (this.showForm) {
      if (!liabilitySelect) liabilitySelect = this.liabilityComposite[0];
      this.selectedLiabilityObject = liabilitySelect
      this.displayFormByLiabilityType(liabilitySelect.liability.liabilityType)
      this.liabilityForm.patchValue({
        creditInstitution: liabilitySelect.liability.lendingInstitution,
        product: liabilitySelect.liability.product,
        amount: liabilitySelect.liability.amount,
        emi: liabilitySelect.liability.emi,
        tenure: liabilitySelect.liability.tenure,
        paidEmi: liabilitySelect.liability.emiPaid,
        emiBounce6m: liabilitySelect.liability.emiBounce6m,
        emiBounceTotal: liabilitySelect.liability.emiBounceTotal,
        creditProposedListedFlag: liabilitySelect.liability.bureauStatus,
        obligationFlag: liabilitySelect.liability.obligationStatus,
        liabilityType: liabilitySelect.liability.liabilityType,
        accNumber: liabilitySelect.liability.accountNumber,
        m1RepayDate: liabilitySelect.liabilityEmi === null ? '' :  (liabilitySelect.liabilityEmi.m1RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m1RepayDate)),
        m2RepayDate: liabilitySelect.liabilityEmi === null ? '' :  (liabilitySelect.liabilityEmi.m2RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m2RepayDate)),
        m3RepayDate: liabilitySelect.liabilityEmi === null ? '' :  (liabilitySelect.liabilityEmi.m3RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m3RepayDate)),
        m4RepayDate: liabilitySelect.liabilityEmi === null ? '' :  (liabilitySelect.liabilityEmi.m4RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m4RepayDate)),
        m5RepayDate: liabilitySelect.liabilityEmi === null ? '' : (liabilitySelect.liabilityEmi.m5RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m5RepayDate)),
        m6RepayDate: liabilitySelect.liabilityEmi === null ? '' : (liabilitySelect.liabilityEmi.m6RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m6RepayDate)),
        m7RepayDate: liabilitySelect.liabilityEmi === null ? '' : (liabilitySelect.liabilityEmi.m7RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m7RepayDate)),
        m8RepayDate: liabilitySelect.liabilityEmi === null ? '' : (liabilitySelect.liabilityEmi.m8RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m8RepayDate)),
        m9RepayDate: liabilitySelect.liabilityEmi === null ? '' : (liabilitySelect.liabilityEmi.m9RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m9RepayDate)),
        m10RepayDate: liabilitySelect.liabilityEmi === null ? '' : (liabilitySelect.liabilityEmi.m10RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m10RepayDate)),
        m11RepayDate: liabilitySelect.liabilityEmi === null ? '' : (liabilitySelect.liabilityEmi.m11RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m11RepayDate)),
        m12RepayDate: liabilitySelect.liabilityEmi === null ? '': (liabilitySelect.liabilityEmi.m12RepayDate==null?'':new Date(liabilitySelect.liabilityEmi.m12RepayDate))
      });
      // this.liabilityForm.controls.monthrepayDateFormArray = this.formBuilder.array([])
      // const array = this.liabilityForm.controls.monthrepayDateFormArray as FormArray;
      // this.recentMonths.forEach((month, index) => {
      //   array.push( new FormControl(new Date(liabilitySelect.liabilityEmi === null ? '' : liabilitySelect.liabilityEmi['m' + index + 1 + 'RepayDate'], 'DATE')));
      // })
      // let test =this.liabilityForm.get('monthrepayDateFormArray')['controls']
      this.selectedLiability = liabilitySelect;
      this.isFormDisabled = true
      this.liabilityForm.disable()
    }
  }

  //this function will apply validators to form group
  applyValidatorsToLiabilirty() {
    this.validationService.applyValidationRules(this.liabilityForm, "Liability").then((controlValidators) => {
      this.fetchLiability();
    }).catch(() => {
    })
  }

  clickAddNew() {
    this.showType = true
  }

  checkTypeValue(selectedType) {
    this.showForm = true;
    this.showType = false
    // this object should be undefined while creating new liability
    this.selectedLiabilityObject = undefined;
    this.displayFormByLiabilityType(selectedType)
    this.liabilityForm.reset()
    this.liabilityForm.patchValue({
      liabilityType: selectedType
    })
     // here we are using same form for two types of liability, so when credit card type is selected loan type fields will be disabled 
    //so that form will not trigger loan type fields validation .
    const ctrl = this.liabilityForm.get('liabilityType')
    if (ctrl) {
      if(ctrl.value == 'LOAN'){
        this.liabilityForm.enable()
      }else{
        this.liabilityForm.disable()
        this.liabilityForm.controls.creditInstitution.enable()
        this.liabilityForm.controls.amount.enable()
        this.liabilityForm.controls.accNumber.enable()
      }
  
    //this.liabilityForm.get('liabilityType').disable()
    this.isFormDisabled = false;
      ctrl.updateValueAndValidity()
      ctrl.disable()
    }
  }

  displayFormByLiabilityType(value) {
    if (value == 'CRCARD') {
      // if liability type is Credit Card then show credit card container else show loan container
      this.creditCardLiabilityTypes = true;
    } else {
      this.creditCardLiabilityTypes = false;
    }
  }

  //this function will call a api to get CFA Finalized or not status
  getCfaStatus() {
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response => {
      this.cfaFinalized = response.body.cfaFinalized
      if (this.cfaFinalized) {
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close", {
          duration: 5000,
        });
      }
    })
  }

  saveLiabilities() {
    this.validationService.markFormGroupTouched(this.liabilityForm)
    this.liabilityForm.updateValueAndValidity()
    if(this.liabilityForm.valid){
    this.LiabilityObject = this.selectedLiabilityObject
    // this.recentMonths.forEach((month, index) => {
    //   let repaydateGroup=this.liabilityForm.get('monthrepayDateFormArray')['controls'][index]
    // this.LiabilityObject.liabilityEmi['m'+index+1+'RepayDate']=repaydateGroup.value.mRepayDate
    // })
    if( this.LiabilityObject ==undefined){
      this.LiabilityObject=new LiabilityComposite();
      this.LiabilityObject.liability = {};
      this.LiabilityObject.liabilityEmi = {};
    }
    this.LiabilityObject.liability.lendingInstitution=this.liabilityForm.controls.creditInstitution.value
    this.LiabilityObject.liability.product=this.liabilityForm.controls.product.value
    this.LiabilityObject.liability.amount=this.liabilityForm.controls.amount.value
    this.LiabilityObject.liability.emi=this.liabilityForm.controls.emi.value
    this.LiabilityObject.liability.tenure=this.liabilityForm.controls.tenure.value
    this.LiabilityObject.liability.emiPaid=this.liabilityForm.controls.paidEmi.value
    this.LiabilityObject.liability.emiBounce6m=this.liabilityForm.controls.emiBounce6m.value
    this.LiabilityObject.liability.emiBounceTotal=this.liabilityForm.controls.emiBounceTotal.value
    this.LiabilityObject.liability.bureauStatus=this.liabilityForm.controls.creditProposedListedFlag.value
    this.LiabilityObject.liability.obligationStatus=this.liabilityForm.controls.obligationFlag.value
    this.LiabilityObject.liability.liabilityType=this.liabilityForm.controls.liabilityType.value
    this.LiabilityObject.liability.accountNumber=this.liabilityForm.controls.accNumber.value
    if( this.LiabilityObject.liabilityEmi == null || this.LiabilityObject.liabilityEmi == undefined ){
      this.LiabilityObject.liabilityEmi = {}
    }
    this.LiabilityObject.liabilityEmi.m1RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m1RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m2RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m2RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m3RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m3RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m4RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m4RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m5RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m5RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m6RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m6RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m7RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m7RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m8RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m8RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m9RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m9RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m10RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m10RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m11RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m11RepayDate.value,'ISODATE'),
    this.LiabilityObject.liabilityEmi.m12RepayDate = this.customDatepipe.transform(this.liabilityForm.controls.m12RepayDate.value,'ISODATE'),
    this.applicationService.savaLiabilityDetails(this.application.uid, this.LiabilityObject).subscribe(data => {
      this.fetchLiability()
      this._snackbar.open("Liability updated successfully", "Close", {
        duration: 2000,
      });
    }, err => { console.log(err) })
  }
  }

  enableDisableForm() {
    // here we are using same form for two types of liability, so when credit card type is selected loan type fields will be disabled 
    //so that form will not trigger loan type fields validation .
    const ctrl = this.liabilityForm.get('liabilityType')
    if (ctrl) {
      if(ctrl.value == 'LOAN'){
        this.liabilityForm.enable()
      }else{
        this.liabilityForm.disable()
        this.liabilityForm.controls.creditInstitution.enable()
        this.liabilityForm.controls.amount.enable()
        this.liabilityForm.controls.accNumber.enable()
      }
    }
    this.liabilityForm.get('liabilityType').disable()
    this.isFormDisabled = false;
  }

  cancelForm() {
    if (this.liabilityComposite && this.liabilityComposite.length > 0) {
      this.buildLiabilityForm(this.liabilityComposite[0]);
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
    this.showType = false
  }
  
  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}
