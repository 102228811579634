import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { OnDestroy } from '@angular/core';
import { Applicant } from '@app/applications/application-details/collateral/collateralDetails/collateral-details.model';
import { BureauResponse } from '../xdata-response.model';
import { DocumentVerificationDTO } from '@app/applications/application-details/business/kycProofs/kyc.model';
import { SecureStorageService } from '@ig-core/authentication/secureStorage.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { EXECUTE_POLICY } from '@app/constants/feature-set.constants';
import { BUREAU_VERIFICATION_RULE_LENIENT, BUREAU_VERIFICATION_RULE_STRICT } from '@app/constants/data.constants';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
@Component({
  selector: 'eng-equifax',
  templateUrl: 'bureau.template.html',
  styleUrls: ['../../../application-details.styles.scss', '../xdata-service.style.scss']
})
export class BureauComponent implements OnInit, OnDestroy {

  applicationUid: string;

  xDataServicesForm: FormGroup;
  applicants: Applicant[];
  showReportHighlight: boolean = false;
  bureauResponse: BureauResponse;
  serviceProviderCode: string;
  verificationDto: DocumentVerificationDTO = {};
  loginUser: any;
  // verifyButtonStatus is used to enable or disable verify button.
  verifyButtonStatus: boolean;
  showErrorCard: boolean;
  // this is used to show message next to verify button
  validationMessage: string;
  listOfFeatures: string[];
  sortSettingList: ReferenceCode[] = [];
  bureauVerificationRule: any;
  // isVerified is used to show the message green in color based on condition 
  isVerified: boolean;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private secureStorageService:SecureStorageService,
    private referenceCodeService:ReferenceCodeService) {
    this.xDataServicesForm = this.formBuilder.group({
      borrower: ['', Validators.required]
    });
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.applicationUid;
        this.serviceProviderCode = params.xDataService;
    });
  }

  ngOnInit() {
    this.referenceCodeService.getRefCodesForClassifier('settings').subscribe((response: any) => {
      this.sortSettingList = response;
      this.sortSettingList.forEach(element => {
        if(element.code == "BUREAU-VERIFICATION-RULE"){
          if (element.field1 !== null){
          this.bureauVerificationRule = element.field1;
          }
        }
      })
    })

    this.loginUser = this.secureStorageService.secureSessionStorage.getItem("logInUserDetails")
    // this is to get what are the features present in featureset list
    this.referenceCodeService.getFeatureSet().subscribe(response =>{
      this.listOfFeatures = response.body.map(feature => feature.toLocaleLowerCase());
    })
    this.onXDataServiceSelect();
  }

  onXDataServiceSelect(){
    this.applicationService.getApplicants(this.applicationUid).subscribe((response: any) => {
      if (response) {
        this.applicants = response;
      }
    })
  }

  onExecuteClick(){
    if(this.xDataServicesForm.valid){
      this.bureauResponse = null;
      this.verifyButtonStatus = false;
      this.showReportHighlight = false;
      this.showErrorCard = false;
      this.isVerified = false;
      this.validationMessage = '';
      let partyUid = this.xDataServicesForm.controls.borrower.value.uid;
      let partyPlay = this.xDataServicesForm.controls.borrower.value.play;
      this.applicationService.getReportScore(this.applicationUid, partyPlay, partyUid, this.serviceProviderCode).toPromise().then(
        (_success) => {
          this.bureauResponse = _success.body;
          if(this.bureauResponse.status === "SUCCESS" && this.bureauResponse.hitCode !== "00"){
            this.showReportHighlight = true;

            if (this.listOfFeatures.includes(EXECUTE_POLICY)) {
              // these are conditions to be met for enabling verify button, if any of the condtion is not met verifyButton will be disabled
              let validationDTO = this.bureauResponse.validationDetailDTO;
              // here all these conditions need to be met for enabling verify button, if we write all condition in single if it will be confusing.
              // hence we have split the conditions into two, first we are checking if it included authorities and verifiedFlag is false or null this is common.
              let verifyButtonConditionsMet = (this.loginUser.authorities.includes("VERIFY-BUREAU") || this.loginUser.authorities.includes("VERIFY-BUREAU-OFFSET")) &&
                (this.bureauResponse.verified === null || this.bureauResponse.verified === false);
              
              //if the above condition is met above condition is common for both and now we are checking for bureau verification rule is strict or not,
              // If bureauVerificationRule is strict, then both acceptanceCriteriaMet and inPermissibleTolerance should be true.
              // else if bureauVerificationRule is Linient, then inPermissibleTolerance should be true. 
              if (verifyButtonConditionsMet) {
                if ((this.bureauVerificationRule === BUREAU_VERIFICATION_RULE_STRICT && validationDTO.acceptanceCriteriaMet && validationDTO.inPermissibleTolerance) ||
                  (this.bureauVerificationRule === BUREAU_VERIFICATION_RULE_LENIENT && validationDTO.inPermissibleTolerance)) {
                  this.verifyButtonStatus = true;
                }
              }
              this.setValidationMessage()
            }
          }
          this._snackbar.open(this.bureauResponse.message, "Close", {
            duration: 8000,
          })
        },(failure) => {
          this._snackbar.open("Internal Server Error. " + failure , "Close", {
            duration: 4000,
          })
        })
    }
  }

  setValidationMessage(){
    let validationDTO = this.bureauResponse.validationDetailDTO;
    
    if (this.bureauResponse.verified) {
      this.validationMessage = "Bureau report is verified";
      this.isVerified = true;
    } else if (!validationDTO.inPermissibleTolerance) {
      this.validationMessage = "Cannot verify, found one or more validation errors";
    } else if(!validationDTO.acceptanceCriteriaMet && validationDTO.inPermissibleTolerance) {
      this.validationMessage = "Found one or more validation errors";
    }

    // if there is any validationMessage then only we are showing the error card
    if(this.bureauResponse.validationDetailDTO.validationMessages.length > 0){
      this.showErrorCard = true;
    }
  }

  onVerifyClick() {
    this.applicationService
      .verifyBureauDetails(this.applicationUid, this.bureauResponse.bureaureportuid, this.bureauResponse)
      .subscribe((response) => {
          this.bureauResponse.verified = response.body.verified;
        if(this.bureauResponse.verified){
          this.validationMessage = "Bureau report is verified";
          this.verifyButtonStatus = false;
          this.isVerified = true;
        }
        this._snackbar.open(response.body.message, "Close",{
          duration:6000
        })
      });    
  }
  ngOnDestroy(): void {
  }
}
