export class ServerErrorModel {

  constructor(
    public isValidationError: boolean,
    public formErrors?: any,
    public otherError?: any
  ) {
  }

}
