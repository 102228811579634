import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SwaggerComponent} from './swagger/swagger.component';
import {AuthenticatedUserService} from '../authentication/authenticated-user-service';
import {HealthComponent} from './health/health.component';
import {LogManagementComponent} from './log-management/log-management.component';
import {ServerConfigurationComponent} from './server-configuration/server-configuration.component';
import {AuditComponent} from './audit/audit.component';
import {MetricsComponent} from './metrics/metrics.component';
import {MetricsResolver} from './metrics/metrics.resolver';

const routes: Routes = [
  {
    path: 'system-admin', canActivate: [AuthenticatedUserService],
    children: [{path: 'swagger', component: SwaggerComponent},
      {path: 'health', component: HealthComponent},
      {path: 'log-management', component: LogManagementComponent},
      {path: 'server-configuration', component: ServerConfigurationComponent},
      {path: 'audit', component: AuditComponent},
      {path: 'metrics', component: MetricsComponent, resolve: {
        metrics: MetricsResolver
        }
      }
      ]
  }];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemAdminRoutingModule {
}
