import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import { MapMarkersComposite } from './map-marker.model';

@Injectable({
    providedIn: 'root'
})
export class RoTripViewService {

    private APIv1 = 'api/v1/';

    constructor(private http: HttpClient) {}

    generateCordinates(userTrack : any) {
        const copy: any = Object.assign({}, userTrack);
        return this.http.post<any>('api/user-track', copy, {observe: 'response'});
    }

    getRoTripCoordinates(filters : any) {
        return this.http.get<MapMarkersComposite>(this.APIv1 + 'map-marker/branch/'
            + filters.zoneUid + '/zone/' + filters.zoneUid + '/' + filters.markerType
            + '/' + filters.timeFrame, {observe: 'response'});
    }
}