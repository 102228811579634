import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { NgModule } from '@angular/core';
import { Injectable } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { PaginationUtil } from 'ng-jhipster';
import { LoanOdAccountsComponent } from './loan-od-accounts.component';
import { LoanOdAccountsDetailComponent } from './loan-od-accounts-detail.component';
import { LoanOdAccountsDeletePopupComponent } from './loan-od-accounts-delete-dialog.component';
import { LoanOdAccountsResolve } from './loan-od-accounts.resolve';
import { LoanOdAccountsEditorComponent } from './loan-od-accounts-editor.component';
import { LoanOdAccountsBulkActionsComponent } from './loan-od-accounts-bulk-actions.component';
import { LoanOdAccountsSetTagsComponent } from './loan-od-accounts-set-tags.component';
import { LoanOdAccountsAssignPostDatedChequesComponent } from './loan-od-accounts-assign-pdc.component';
import { LoanOdAccountsRejectPostDatedChequesComponent } from './loan-od-accounts-reject-pdc.component';
import { LoanOdAccountsDisbursementsComponent } from './loan-od-accounts-disbursements.component';
import { LoanOdAccountsDisburseEntryResolve } from './loan-od-accounts-disburse-entry.resolve';
import { LoanOdAccountsSetStatusComponent } from './loan-od-accounts-set-status.component';
import { LoanOdAccountsSetStatusResolve } from './loan-od-accounts-set-status-resolve';
import { LoanOdAccountsSetStatusExecuteComponent } from './loan-od-accounts-set-status-execute.component';
import { LoanOdAccountsChargeFeeResolve } from './loan-od-accounts-charge-fee-entry.resolve';
import { LoanOdAccountsReverseEntryComponent } from './loan-od-accounts-reverse-entry.component';
import { LoanOdAccountsReverseResolve } from './loan-od-accounts-reverse.resolve';
import { LoanOdAccountsReverseExecuteComponent } from './loan-od-accounts-reverse-execute.component';
import { LoanOdAccountsReverseResultComponent } from './loan-od-accounts-reverse-result.component';
import { LoanOdAccountsRepaymentResolve } from './loan-od-accounts-repayment-resolve';
import { LoanOdAccountsAmendAccountComponent } from './loan-od-account-amend-account.component';
import { LoanOdAccountsAmendAccountResolve } from './loan-od-accounts-amend-resolve';
import { LoanOdAccountsSetElectronicMandateComponent } from './loan-od-accounts-set-electronic-mandate.component';
import { LoanOdAccountsAmendEditorComponent } from './loan-od-accounts-amend-editor-component';
import { LoanOdAccountsAmendinstallmentamountEntryComponent } from './loan-od-accounts-amendinstallmentamount-entry.component';
import { LoanOdAccountsAmendtenureEntryResolve } from './loan-od-accounts-amendtenure-entry.resolve';
import { LoanOdAccountsCreatemoratoriumEntryResolve } from './loan-od-accounts-createmoratorium-entry.resolve';
import { LoanOdAccountsAmendguarantorEntryComponent } from './loan-od-accounts-amendguarantor-entry.component';
import { LoanOdAccountsSetpostdatedchequesResolve } from './loan-od-accounts-setpostdatedcheques.resolve';
import { LoanOdAccountsWriteoffEntryComponent } from './loan-od-accounts-writeoff-entry.component';
import { LoanOdAccountsTransfertoanotherbranchComponent } from './loan-od-accounts-transfertoanotherbranch-entry.component';
import { LoanOdAccountsPopupComponent } from './loan-od-accounts-popup.component';
import { LoanOdAccountsAmendNormalInterestComponent } from './loan-od-accounts-amend-normal-interest.component';
import { LoanOdAccountsAmendLoanAmountComponent } from './loan-od-accounts-amend-loan-amount.component';
import { LoanOdAccountsSecurityDepositRefundComponent } from './loan-od-accounts-security-deposit-refund.component';
import { LoanOdAccountsAmendguarantorExecuteComponent } from './loan-od-accounts-amendguarantor-execute.component';
import { LoanOdAccountsAmendguarantorResultComponent } from './loan-od-accounts-amendguarantor-result.component';
import { LoanOdAccountsAmendPenalInterestExecuteComponent } from './loan-od-accounts-amend-penal-interest-execute.component';
import { LoanOdAccountsAmendPenalInterestResultComponent } from './loan-od-accounts-amend-penal-interest-result.component';
import { LoanOdAccountsCheckTransactionsComponent } from './loan-od-accounts-check-transactions.component';
import { LoanOdAccountsTransfertoanotherbranchExecuteComponent } from './loan-od-accounts-transfertoanotherbranch-execute.component';
import { LoanOdAccountsScheduledDisbursementsComponent } from './loan-od-accounts-scheduled-disbursements.component';
import { LoanOdAccountsPostDatedChequeComponent } from './loan-od-accounts-post-dated-cheque.component';
import { LoanOdAccountsPdcDeleteComponent } from './loan-od-accounts-pdc-delete.component';
import { LoanOdAccountsSecurityDepositPaymentExecuteComponent } from './loan-od-accounts-security-deposit-payment-execute.component';
import { LoanOdAccountsPdcSaveComponent } from './loan-od-accounts-pdc-save.component';
import { LoanOdAccountsWriteoffExecuteComponent } from './loan-od-accounts-writeoff-execute.component';
import { LoanOdAccountsWriteoffrecoveryEntryResolve } from './loan-od-accounts-writeoffrecovery-entry.resolve';
import { LoanOdAccountsAmendPenalInterestComponent } from './loan-od-accounts-amend-penal-interest.component';
import { LoanOdAccountsSecurityDepositPaymentComponent } from './loan-od-accounts-security-deposit-payment.component';
import { LoanOdAccountsAmendtenureComponent } from './loan-od-accounts-amendtenure.component';
import { LoanOdAccountsAmendrepaymentdateComponent } from './loan-od-accounts-amendrepaymentdate.component';
import { LoanOdAccountsAmendUpdateComponent } from './loan-od-accounts-amend-update.component';
import { LoanOdAccountsDeleteMoratoriumComponent } from './loan-od-accounts-delete-moratorium.component';
import { LoanOdAccountsAmendMaturityDateComponent } from './loan-od-accounts-amend-maturity-date.component';
import { LoanOdAccountsTransactionsMakerCheckerComponent } from './loan-od-accounts-maker-checker-transactions.component';
import { LoanOdAccountsTransactionsMakerCheckerApproveComponent } from './loan-od-accounts-maker-checker-transactions-approve.component';
import { LoanOdAccountsTransactionsMakerCheckerRejectComponent } from './loan-od-accounts-maker-checker-transactions-reject.component';
import { LoanOdAccountsMakerCheckerComponent } from './loan-od-accounts-maker-checker.component';
import { LoanOdAccountsMakerCheckerApproveComponent } from './loan-od-accounts-maker-checker-approve.component';
import { LoanOdAccountsMakerCheckerRejectComponent } from './loan-od-accounts-maker-checker-reject.component';
import { LoanOdAccountsMakerCheckerViewComponent } from './loan-od-accounts-maker-checker-view.component';
import { LoanOdAccountsFeePaymentComponent } from './loan-od-accounts-fee-payment.component';
import { LoanOdAccountsChargeFeeComponent } from './loan-od-accounts-charge-fee.component';
import { LoanOdScheduledDemandRepaymentComponent } from './loan-od-scheduled-demand-repayment.component';
import { LoanOdAccountsPrepaymentComponent } from './loan-od-accounts-prepayment.component';
import { LoanOdAccountsAdvanceRepaymentComponent } from './loan-od-accounts-advance-repayment.component';
import { LoanOdAccountsPreclosureComponent } from './loan-od-accounts-preclosure.component';
import { LoanOdAccountsDisburseComponent } from './loan-od-accounts-disburse.component';
import { LoanOdAccountsAmendOpenDateResolve } from './loan-od-accounts-amend-open-date.resolve';
import { LoanOdAccountsAmendOpenDateComponent } from './loan-od-accounts-amend-open-date.component';
import { LoanOdAccountsAmendMoratoriumComponent } from './loan-od-accounts-amend-moratorium.component';
import { LoanOdAccountsCreateMoratoriumComponent } from './loan-od-accounts-create-moratorium.component';
import { LoanOdAccountsRecoveryComponent } from './loan-od-accounts-recovery.component';
import { LoanOdAccountsCompositeRepaymentComponent } from './loan-od-accounts-composite-repayment.component';
import { LoanOdAccountsComputePayOffAmountComponent } from './loan-od-accounts-compute-payOff-Amount.component';
import { LoanOdAccountsComputePayOffAmountResolve } from './loan-od-accounts-compute-payOff-Amount.resolve';
import { LoanOdAccountsAmendinstallmentamountEntryResolve } from './loan-od-accounts-amendinstallmentamount-entry.resolve';
import { LoanOdAccountsAmendRepaymentDateEntryResolve } from './loan-od-accounts-amendrepaymentdate-entry.resolve';
import { LoanOdAccountsCollectionsComponent } from './loan-od-accounts-collections.component';
import { LoanOdAccountsCollectionsResolve } from './loan-od-accounts-collections.resolve';
import { LoanOdAccountsCollectionsDownloadComponent } from './loan-od-accounts-collections-download.component';

import { ITEMS_PER_PAGE } from '../shared';
import { LoanOdAccountsCollectionsUploadReverseComponent } from './loan-od-accounts-collections-upload-reverse.component';
import { LoanOdAccountsCollectionsUploadComponent } from './loan-od-accounts-collections-upload.component';
import { LoanOdAccountsElectronicMandateComponent } from './loan-od-accounts-electronic-mandate.component';
import { LoanOdAccountsElectronicMandateResolve } from './loan-od-accounts-electronic-mandate.resolve';
import { LoanOdAccountsElectronicMandateShowComponent } from './loan-od-accounts-electronic-mandate-show.component';
import { LoanOdAccountsElectronicMandateShowResolve } from './loan-od-accounts-electronic-mandate-show.resolve';
import { LoanOdAccountsElectronicMandateDownloadComponent } from './loan-od-accounts-electronic-mandate-download.component';
import { LoanOdAccountsCollectionsUploadPdcComponent } from './upload-pdc-collection.component';
import { LoanOdAccountsTransactionsMakerCheckerShowComponent } from './LoanOdAccountsTransactionsMakerCheckerShowComponent';
import { LoanOdAccountsInsurancePolicyListComponent } from './loan-od-accounts-insurancepolicylist.component';
import { LoanOdAccountsInsurancePolicyProceedComponent } from './loan-od-accounts-insurance-policy-proceed';
import { LoanOdAccountsInsurancePolicyConfirmComponent } from './loan-od-accounts-insurance-policy-confirm';
import { LoanOdAccountsInsurancePolicyViewComponent } from './loan-od-accounts-insurance-policy-view';

// route-guard 
import {LoanOdAccountsRouteGuardService} from './loan-od-accounts-route-guard.service'
@Injectable()
export class LoanOdAccountsResolvePagingParams implements Resolve<any> {

    constructor(private paginationUtil: PaginationUtil) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const page = route.queryParams['page'] ? route.queryParams['page'] : '1';
        const sort = route.queryParams['sort'] ? route.queryParams['sort'] : 'id,asc';
        const size = route.queryParams['size'] ? route.queryParams['size'] : ITEMS_PER_PAGE;
        const search = route.queryParams['search'] ? route.queryParams['search'].slice(1, -1) : undefined;
        return {
            page: this.paginationUtil.parsePage(page),
            predicate: this.paginationUtil.parsePredicate(sort),
            ascending: this.paginationUtil.parseAscending(sort),
            size,
            search
        };
    }
}

export const loanOdAccountsRoute: Route = {
    path: 'loan-od-accounts',
    resolve: {
        'pagingParams': LoanOdAccountsResolvePagingParams
    },
    data: {

        pageTitle: 'encoreclientApp.loanOdAccounts.pageTitle'
    },
    canActivate: [AuthenticatedUserService,LoanOdAccountsRouteGuardService],

    children: [
        {
            path: '',
            component: LoanOdAccountsComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.pageTitle'
            },
            resolve: {
                'pagingParams': LoanOdAccountsResolvePagingParams
            },
            canActivate: [AuthenticatedUserService]
        }, {
            path: 'view/:accountId',
            component: LoanOdAccountsDetailComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                loanOdAccounts: LoanOdAccountsResolve
            }

        },
        {
            path: 'create',
            component: LoanOdAccountsEditorComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.create.pageTitle'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                'pagingParams': LoanOdAccountsResolvePagingParams
            },
        },
        {
            path: 'edit/:id',
            component: LoanOdAccountsEditorComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                'pagingParams': LoanOdAccountsResolvePagingParams
            },
        },
        {
            path: 'bulkActions',
            component: LoanOdAccountsBulkActionsComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                'pagingParams': LoanOdAccountsResolvePagingParams
            },
        },
        //     {
        //         path: 'setTags',
        //         component: LoanOdAccountsSetTagsComponent,
        //         data: {

        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //           // resolve: {
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams
        //         // },
        //     },
        //     {
        //         path: 'collections/assignPostDatedCheques',
        //         component: LoanOdAccountsAssignPostDatedChequesComponent,
        //         data: {

        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.bulkActionCategory.assignClearPostDatedCheque'
        //         },
        //         // resolve: {
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams,
        //         //     loanOdAccounts: LoanOdAccountsResolve
        //         // },
        //         canActivate: [AuthenticatedUserService]
        //     }, {
        //         path: 'collections/reversePostDatedCheques',
        //         component: LoanOdAccountsRejectPostDatedChequesComponent,
        //         data: {

        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.bulkActionCategory.rejectClearPostDtaedCheque'
        //         },
        //         // resolve: {
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams,
        //         //     loanOdAccounts: LoanOdAccountsResolve
        //         // },
        //         canActivate: [AuthenticatedUserService]
        //     },
        {
            path: 'disbursments',
            component: LoanOdAccountsDisbursementsComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                'pagingParams': LoanOdAccountsResolvePagingParams,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        //     {
        //         path: 'checkTransactions',
        //         component: LoanOdAccountsCheckTransactionsComponent,
        //         data: {

        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //           // resolve: {
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams
        //         // },
        //     },
        //     {
        //         path: 'makerCheckerTransactions',
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.pageTitle'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         children: [
        //             {
        //                 path: '',
        //                 component: LoanOdAccountsTransactionsMakerCheckerComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.pageTitle'
        //                 },
        //                 // resolve: {
        //                 //     'pagingParams': LoanOdAccountsResolvePagingParams
        //                 // },
        //                 canActivate: [AuthenticatedUserService]
        //             },
        //             {
        //                 path: 'approve/:id',
        //                 component: LoanOdAccountsTransactionsMakerCheckerApproveComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.executePageTitle'
        //                 },
        //                 canActivate: [AuthenticatedUserService]
        //             },
        //             {
        //                 path: 'reject/:id',
        //                 component: LoanOdAccountsTransactionsMakerCheckerRejectComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.executePageTitle'
        //                 },
        //                 canActivate: [AuthenticatedUserService]
        //             },
        //             {
        //                 path: 'show/:id',
        //                 component: LoanOdAccountsTransactionsMakerCheckerShowComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.executePageTitle'
        //                 },
        //                 canActivate: [AuthenticatedUserService]
        //             },
        //         ]
        //     },
        //     {
        //         path: 'makerChecker',
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.pageTitle'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         children: [
        //             {
        //                 path: '',
        //                 component: LoanOdAccountsMakerCheckerComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.pageTitle'
        //                 },
        //                 // resolve: {
        //                 //     'pagingParams': LoanOdAccountsResolvePagingParams
        //                 // },
        //                 canActivate: [AuthenticatedUserService]
        //             },
        //             {
        //                 path: 'approve/:id',
        //                 component: LoanOdAccountsMakerCheckerApproveComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.executePageTitle'
        //                 },
        //                 canActivate: [AuthenticatedUserService]
        //             },
        //             {
        //                 path: 'reject/:id',
        //                 component: LoanOdAccountsMakerCheckerRejectComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.executePageTitle'
        //                 },
        //                 canActivate: [AuthenticatedUserService]
        //             },
        //             {
        //                 path: 'view/:id',
        //                 component: LoanOdAccountsMakerCheckerViewComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.makerChecker.executePageTitle'
        //                 },
        //                 canActivate: [AuthenticatedUserService]
        //             },
        //         ]
        //     },
        {
            path: 'disburse/:accountId',
            component: LoanOdAccountsDisburseComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                compositeDto: LoanOdAccountsDisburseEntryResolve,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        {
            path: 'freeze/:accountId',
            component: LoanOdAccountsSetStatusComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                accountProfileDto: LoanOdAccountsSetStatusResolve,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        //     {
        //         path: 'freezeExecute',
        //         component: LoanOdAccountsSetStatusExecuteComponent,
        //         data: {

        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //           // resolve: {
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams
        //         // },
        //     },
        {
            path: 'chargeFee/:accountId',
            component: LoanOdAccountsChargeFeeComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                fee: LoanOdAccountsChargeFeeResolve,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        {
            path: 'findReverseEntry/:accountId',
            component: LoanOdAccountsReverseEntryComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                transactionSummaryDto: LoanOdAccountsReverseResolve
            }
        },
        {
            path: 'reverseExecute',
            component: LoanOdAccountsReverseExecuteComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                'pagingParams': LoanOdAccountsResolvePagingParams
            },
        },
        {
            path: 'reverseResult',
            component: LoanOdAccountsReverseResultComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                'pagingParams': LoanOdAccountsResolvePagingParams
            },
        },
        {
            path: 'scheduledDemand/:accountId',
            component: LoanOdScheduledDemandRepaymentComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                loanOdRepaymentCompositeDto: LoanOdAccountsRepaymentResolve,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        {
            path: 'prepayment/:accountId',
            component: LoanOdAccountsPrepaymentComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                loanOdRepaymentCompositeDto: LoanOdAccountsRepaymentResolve,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        {
            path: 'advanceRepayment/:accountId',
            component: LoanOdAccountsAdvanceRepaymentComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                loanOdRepaymentCompositeDto: LoanOdAccountsRepaymentResolve,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        {
            path: 'preclosure/:accountId',
            component: LoanOdAccountsPreclosureComponent,
            data: {
                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                loanOdRepaymentCompositeDto: LoanOdAccountsRepaymentResolve,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        {
            path: 'feePayment/:accountId',
            component: LoanOdAccountsFeePaymentComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                loanOdRepaymentCompositeDto: LoanOdAccountsRepaymentResolve,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        {
            path: 'compositeRepayment/:accountId',
            component: LoanOdAccountsCompositeRepaymentComponent,
            data: {

                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                loanOdRepaymentCompositeDto: LoanOdAccountsRepaymentResolve,
                loanOdAccounts: LoanOdAccountsResolve
            }
        },
        {
            path: 'amendAmount/:accountId',
            component: LoanOdAccountsAmendAccountComponent,
            data: {
                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                accountMandateDto: LoanOdAccountsAmendAccountResolve
            }
        },
        //     {
        //         path: 'setElectronicMandate/:accountId',
        //         component: LoanOdAccountsSetElectronicMandateComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             accountMandateDto: LoanOdAccountsAmendAccountResolve
        //         }
        //     },
        //     {
        //         path: 'amend/create',
        //         component: LoanOdAccountsAmendEditorComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.bulkActionCategory.newMandate'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //         }
        //     },
        //     {
        //         path: 'amend/update/:accountId/:id',
        //         component: LoanOdAccountsAmendUpdateComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsAmendAccountResolve*/
        //         }
        // },
        {
            path: 'viewDetails/:id',
            component: LoanOdAccountsDetailComponent,
            data: {
                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                'pagingParams': LoanOdAccountsResolvePagingParams
            },
        },
        {
            path: 'repaymentDateEntry/:accountId',
            component: LoanOdAccountsAmendrepaymentdateComponent,
            data: {
                pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
            },
            canActivate: [AuthenticatedUserService],
            resolve: {
                loanOdAccounts: LoanOdAccountsAmendRepaymentDateEntryResolve
            }
        },
        //     {
        //         path: 'amendTenure/:accountId',
        //         component: LoanOdAccountsAmendtenureComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             loanOdAccounts: LoanOdAccountsAmendtenureEntryResolve
        //         }
        //     },
        //     {
        //         path: 'amendInstallmentAmount/:accountId',
        //         component: LoanOdAccountsAmendinstallmentamountEntryComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             loanOdAccounts: LoanOdAccountsAmendinstallmentamountEntryResolve
        //         }
        //     },
        //     {
        //         path: 'amendMoratorium/:accountId',
        //         component: LoanOdAccountsAmendMoratoriumComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService]
        //     },
        //     {
        //         path: 'createMoratorium/:accountId',
        //         component: LoanOdAccountsCreateMoratoriumComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             moratorium: LoanOdAccountsCreatemoratoriumEntryResolve
        //         }
        //     },
        //     {
        //         path: 'deleteMoratorium/:accountId',
        //         component: LoanOdAccountsDeleteMoratoriumComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsWriteoffEntryResolve*/
        //         }
        //     },
        //     {
        //         path: 'amendGuarantor/:accountId',
        //         component: LoanOdAccountsAmendguarantorEntryComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsAmendguarantorEntryResolve*/
        //         }
        //     },
        //     {
        //         path: 'amendGuarantorExecute',
        //         component: LoanOdAccountsAmendguarantorExecuteComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsAmendguarantorEntryResolve*/
        //         }
        //     },
        //     {
        //         path: 'amendGuarantorResult',
        //         component: LoanOdAccountsAmendguarantorResultComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsAmendguarantorEntryResolve*/
        //         }
        //     },
        //     {
        //         path: 'securityDeposit/:accountId',
        //         component: LoanOdAccountsSecurityDepositPaymentComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //           // resolve: {
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams
        //         // },
        //     },
        //     {
        //         path: 'securityDepositExecute',
        //         component: LoanOdAccountsSecurityDepositPaymentExecuteComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsAmendguarantorEntryResolve*/
        //         }
        //     },
        //     {
        //         path: 'writeOff/:accountId',
        //         component: LoanOdAccountsWriteoffEntryComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //           // resolve: {
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams
        //         // },
        //     },
        //     {
        //         path: 'writeOffExecute',
        //         component: LoanOdAccountsWriteoffExecuteComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsAmendguarantorEntryResolve*/
        //         }
        //     }
        //     ,
        //     {
        //         path: 'writeOffRecovery/:accountId',
        //         component: LoanOdAccountsRecoveryComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             writeOffDto: LoanOdAccountsWriteoffrecoveryEntryResolve
        //         }
        //     },
        //     /* {
        //         path: 'writeOffRecoveryExecute',
        //         component: LoanOdAccountsWriteoffrecoveryExecuteComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //         }
        //     }, */
        //     {
        //         path: 'setDisbursementSchedule/:accountId',
        //         component: LoanOdAccountsScheduledDisbursementsComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //         }
        //     },
        //     {
        //         path: 'setPostDatedCheques/:accountId',
        //         component: LoanOdAccountsPostDatedChequeComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             postdatedcheques: LoanOdAccountsSetpostdatedchequesResolve
        //         }
        //     },
        //     {
        //         path: 'setPostDatedChequesExecute',
        //         component: LoanOdAccountsPdcDeleteComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsAmendguarantorEntryResolve*/
        //         }
        //     }
        //     ,
        //     {
        //         path: 'setPostDatedChequesSave',
        //         component: LoanOdAccountsPdcSaveComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsAmendguarantorEntryResolve*/
        //         }
        //     },
        //     {
        //         path: 'transferToAnotherBranch/:accountId',
        //         component: LoanOdAccountsTransfertoanotherbranchComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //           // resolve: {
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams
        //         // },
        //     },
        //     {
        //         path: 'insurancepolicylist/:accountId',
        //         component: LoanOdAccountsInsurancePolicyListComponent,

        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //           // resolve: {
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams
        //         // },
        //     },
        //     {
        //         path: 'addPolicy/:accountId',
        //         component: LoanOdAccountsInsurancePolicyProceedComponent,
        //         children: [

        //             {
        //                 path: 'proceed',
        //                 component: LoanOdAccountsInsurancePolicyConfirmComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //                 },
        //                 canActivate: [AuthenticatedUserService],
        //                 resolve: {
        //                     loanOdAccounts: LoanOdAccountsResolve
        //                 }
        //             },
        //             {
        //                 path: 'view',
        //                 component: LoanOdAccountsInsurancePolicyViewComponent,
        //                 data: {
        //                     pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //                 },
        //                 canActivate: [AuthenticatedUserService],
        //                 resolve: {
        //                     loanOdAccounts: LoanOdAccountsResolve
        //                 }
        //             },
        //         ],
        //         // data: {
        //         //     pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         // },
        //         // canActivate: [AuthenticatedUserService],
        //         // resolve: {
        //         //     loanOdAccounts: LoanOdAccountsResolve
        //         // }
        //     },
        //     {
        //         path: 'transferToAnotherBranchExecute',
        //         component: LoanOdAccountsTransfertoanotherbranchExecuteComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /*accountMandateDto: LoanOdAccountsAmendguarantorEntryResolve*/
        //         }
        //     },
        //     {
        //         path: 'amendPenalInterest/:accountId',
        //         component: LoanOdAccountsAmendPenalInterestComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /* accountMandateDto: LoanOdAccountsAmendpenalinterestEntryResolve */
        //         }
        //     },
        //     {
        //         path: 'amendPenalInterestExecute',
        //         component: LoanOdAccountsAmendPenalInterestExecuteComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //     },
        //     {
        //         path: 'amendPenalInterestResult',
        //         component: LoanOdAccountsAmendPenalInterestResultComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //     },
        //     {
        //         path: 'amendNormalInterest/:accountId',
        //         component: LoanOdAccountsAmendNormalInterestComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /* accountMandateDto: LoanOdAccountsTransfertoanotherbranchResolve*/
        //         }
        //     },
        //     {
        //         path: 'amendLoanAmount/:accountId',
        //         component: LoanOdAccountsAmendLoanAmountComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /* accountMandateDto: LoanOdAccountsTransfertoanotherbranchResolve*/
        //         }
        //     },
        //     {
        //         path: 'amendMaturityDate/:accountId',
        //         component: LoanOdAccountsAmendMaturityDateComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /* accountMandateDto: LoanOdAccountsTransfertoanotherbranchResolve*/
        //         }
        //     },
        //     {
        //         path: 'amendAccountOpenDate/:accountId',
        //         component: LoanOdAccountsAmendOpenDateComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             amendmentDto: LoanOdAccountsAmendOpenDateResolve
        //         }
        //     },
        //     {
        //         path: 'securityDepositRefund/:accountId',
        //         component: LoanOdAccountsSecurityDepositRefundComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             /* accountMandateDto: LoanOdAccountsTransfertoanotherbranchResolve*/
        //         }
        //     },
        //     {
        //         path: 'computePayOffAmount/:accountId',
        //         component: LoanOdAccountsComputePayOffAmountComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             computePayOffAmountDto: LoanOdAccountsComputePayOffAmountResolve
        //         }
        //     },
        //     {
        //         path: 'collections',
        //         component: LoanOdAccountsCollectionsComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.bulkActionCategory.collectionList'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             transactionSummary: LoanOdAccountsCollectionsResolve
        //         }
        //     },
        //     {
        //         path: 'collections/downloadCollections',
        //         component: LoanOdAccountsCollectionsDownloadComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.bulkActionCategory.downloadCollection'
        //         },
        //         canActivate: [AuthenticatedUserService]
        //     },
        //     {
        //         path: 'collections/uploadCollections',
        //         component: LoanOdAccountsCollectionsUploadComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.bulkActionCategory.uploadCollection'
        //         },
        //         canActivate: [AuthenticatedUserService]
        //     },
        //     {
        //         path: 'collections/uploadReverseCollections',
        //         component: LoanOdAccountsCollectionsUploadReverseComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService]
        //     },
        //     {
        //         path: 'collections/uploadPdcCollections',
        //         component: LoanOdAccountsCollectionsUploadPdcComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.bulkActionCategory.uploadPdcCollection'
        //         },
        //         canActivate: [AuthenticatedUserService]
        //     },
        //     {
        //         path: 'electronicMandate',
        //         component: LoanOdAccountsElectronicMandateComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.bulkActionCategory.electronicMandate'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         // resolve: {
        //         //     accountMandateDto: LoanOdAccountsElectronicMandateResolve,
        //         //     'pagingParams': LoanOdAccountsResolvePagingParams
        //         // }
        //     },
        //     {
        //         path: 'show/:accountId/:id',
        //         component: LoanOdAccountsElectronicMandateShowComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService],
        //         resolve: {
        //             accountMandateDto: LoanOdAccountsElectronicMandateShowResolve
        //         }
        //     },
        //     {
        //         path: 'downloadMandate',
        //         component: LoanOdAccountsElectronicMandateDownloadComponent,
        //         data: {
        //             pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
        //         },
        //         canActivate: [AuthenticatedUserService]
        //     }
    ]
};

export const loanOdAccountsPopupRoute: Route = {
    path: 'loan-od-accounts/:id/delete',
    component: LoanOdAccountsDeletePopupComponent,
    data: {

        pageTitle: 'encoreclientApp.loanOdAccounts.home.title'
    },
    canActivate: [AuthenticatedUserService],
    outlet: 'popup'
};

export const loanOdAccountsErrorMessage: Route = {
    path: 'loan-od-accounts/error',
    component: LoanOdAccountsPopupComponent,
    data: {

        pageTitle: 'encoreclientApp.depositProductComposite.home.pageTitle'
    },
    canActivate: [AuthenticatedUserService],
    outlet: 'popup'
};
