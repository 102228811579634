import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventManager, JhiLanguageService, DateUtils } from 'ng-jhipster';
import { of, Subscription } from 'rxjs';
import { ConfigureService } from '../build-config/configure.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import 'rxjs/Rx';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';

@Component({
    selector: 'jhi-loan-od-accounts-detail',
    templateUrl: './loan-od-accounts-detail.component.html'
})
export class LoanOdAccountsDetailComponent implements OnInit, OnDestroy {

    loanOdAccounts: LoanOdAccounts;
    private subscription: any;
    private eventSubscriber: Subscription;
    BASE_URL: string;
    summaryString: string;
    status: number;

    constructor(
        private eventManager: EventManager,
        private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private route: ActivatedRoute,
        private configService: ConfigureService,
        private dateUtils: DateUtils,
        private router: Router,
        private http: HttpClient
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.BASE_URL = this.configService.getBaseUrl();
        // this.BASE_URL = "https://engrow-uat.senseitech.com/engrow-server-new";
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['accountId']);
        });
        this.registerChangeInLoanOdAccounts();
    }

    load(accountId) {
        this.loanOdAccountsService.find(accountId).subscribe((loanOdAccounts) => {
            this.loanOdAccounts = loanOdAccounts;
            this.summaryString = loanOdAccounts.displayName1 + ' - AC#' + loanOdAccounts.accountId + ' opened on - ' +
                loanOdAccounts.displayAccountOpenDate + '; Amount - ' + loanOdAccounts.totalDisbursed.displayValue +
                ' at - ' + loanOdAccounts.normalInterestRate.displayValue;
            if (loanOdAccounts.daysPastDue === 0 && loanOdAccounts.operationalStatus === 'CLOSED') {
                this.status = 1;
            } else if (loanOdAccounts.daysPastDue > 0) {
                this.status = 2;
            } else {
                this.status = 0;
            }

        });
    }
    previousState() {
        this.router.navigate(['/loan-od-accounts']);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInLoanOdAccounts() {
        this.eventSubscriber = this.eventManager.subscribe('loanOdAccountsListModification', (response) => this.load(this.loanOdAccounts.accountId));
    }
    private baseUrl = environment.API_URL;

    fnDownloadCSV(accountId) {
        const header = new HttpHeaders({
            'Content-Type': 'application/csv',
        });
        this.http.get(`${environment.API_URL}/encore/api/loan-od-accounts/downloadTransactions/${accountId}`, {
            responseType: 'arraybuffer', headers: header
        }).subscribe(response => this.fnDownload1(response, "text/csv"));
    }
    csvData: any;
    fnDownload1(data, type) {
        let blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);
        saveAs(blob, "file_name.csv");
    }
    fnDownloadPDF(accountId) {
        const header = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });
        this.http.get(`${environment.API_URL}/encore/api/loan-od-accounts/download/${accountId}/PDF`, {
            responseType: 'arraybuffer', headers: header
        }).subscribe(response => this.downLoadFile(response, "application/pdf"));
    }
    fnDownloadP(entityId) {
        const header = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });
        this.http.get(`${environment.API_URL}/encore/api/loan-od-accounts/printReceipt/${entityId}`, {
            responseType: 'arraybuffer', headers: header
        }).subscribe(response => this.downLoadFile(response, "application/pdf"));
    }

    fnDownloadXIRR(accountId) {
        const header = new HttpHeaders({
            'Content-Type': 'application/vnd.ms-excel',
        });
        this.http.get(`${environment.API_URL}/encore/api/loan-od-accounts/downloadXirr/${accountId}`, {
            responseType: 'arraybuffer', headers: header
        }).subscribe(response => this.downLoadFile(response, "application/vnd.ms-excel"));
    }
    fnDownloadXL(accountId) {
        const header = new HttpHeaders({
            'Content-Type': 'application/vnd.ms-excel',
        });
        this.http.get(`${environment.API_URL}/encore/api/loan-od-accounts/download/${accountId}/EXCEL`, {
            responseType: 'arraybuffer', headers: header
        }).subscribe(response => this.downLoadFile(response, "application/vnd.ms-excel"));
    }

    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downLoadFile(data: any, type: string) {
        let blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
        }
    }
    private onSaveError(error: any) {
    }
    downloadFile(data) {
        let blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, "data.txt");
    }

}
