import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {LoanOdAccountsService} from './loan-od-accounts.service';
import {LoanOdWriteOffDtoModel} from './loan-od-write-off-dto.model';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AlertService } from 'ng-jhipster';
 
@Injectable()
export class LoanOdAccountsWriteoffrecoveryEntryResolve implements Resolve<LoanOdWriteOffDtoModel> {

    constructor(private loanOdAccountsService: LoanOdAccountsService,
        private alertService: AlertService,
        private router: Router) {}

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId')) {
            return this.loanOdAccountsService.writeOffRecoveryDetails(route.paramMap.get('accountId')).pipe(map(
                // tslint:disable-next-line:arrow-return-shorthand
                (res) =>  { return res.json(); }
            )).pipe(catchError(
                (err) => {
                    this.onSaveError(err, route.paramMap.get('accountId'));
                    return of(null);
                }
            ));
        }else {
            return new LoanOdWriteOffDtoModel();
        }
    }

    private onSaveError(error, accountId) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
                this.onError(accountId);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError(accountId);
                }
            } catch (exception) {
                this.onError(accountId);
            }
        }
        this.router.navigate(['/loans/loan-od-accounts/amendAmount', accountId]);
    }

    private onError(accountId) {
        this.alertService.error('encoreclientApp.loanOdAccounts.saveError');
        this.router.navigate(['/loans/loan-od-accounts/amendAmount', accountId]);
    }
}
