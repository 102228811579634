import { Component, OnInit ,Input} from '@angular/core';
import { LoanApplication, Person ,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router} from '@angular/router';
import { ValidationService } from '../../../application-validators/validation.service';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { PostApprovalChange } from '../../decisions/decision.model';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'eng-revise-application',
  templateUrl: './revise-application.component.html',
  styleUrls: ['../../application-details.styles.scss','./revise-application.scss']
})
export class ReviseApplicationComponent implements OnInit {
  @Input() application: LoanApplication;
  @Input() menuItem: string;
  @Input() menuCode:string;

  postApprovalList: PostApprovalChange[];

  isApprovalReset: boolean = false;

  productTypes: ReferenceCode[];
  applicationLoanTypeRefcode:ReferenceCode;

  allowAccess: Boolean;
  value: boolean = true;

  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(  private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private confirmationDialogService:ConfirmationDialogService,
    private _sanitizer: DomSanitizer,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,private validationService: ValidationService) { 
      this.allowAccess = this.applicationService.allowAccess;
    }

  ngOnInit() {
    this.refreshApplicationDataAndGetPostApprovalChanges();
    this.getRefCodes();
    this.getMenuItemAccess();
    }

  //this method is added to refresh the reset button and
  //also to refresh post approval changes after reverting a particular post approval change
  //TODO once post approval change is reset, there is no need to fetch post approval changes again. This API call should be avoided.
  refreshApplicationDataAndGetPostApprovalChanges() {
    this.refreshApplicationData();
    this.getpostApprovalList();
  }

  getpostApprovalList() {
    this.applicationService.getPostApprovalChangeRecord(this.application.uid, this.value).subscribe(response => {
      this.postApprovalList = response.body
  })
}

  getRefCodes() {
    this.referenceCodeService.getRefCodesForClassifier('loan_type').subscribe((response: any) => {
      this.productTypes = response;
      this.getApplicationTypeField1();
    });
  }
//this function will be changed tha value of this.application.
//this call is required after repayment account changed, the application values should be refreshed.
refreshApplicationData() {
  this.applicationService.getBasicApplicationInfo(
    this.application.uid, 'ApplicationOnly').subscribe((response) => {
      this.application = response.body.applicationDTO;
  });
}
//this function will get either this menu item  is editable or readonly 
getMenuItemAccess(){
  this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if(this.menuItemAccessSpecifier){
        this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
          }
    });
  }

  getApplicationTypeField1(){
    this.productTypes.forEach(element => {
      if(element.code == this.application.productTypeProp){
        this.applicationLoanTypeRefcode=element
      }
    });
  }


  //this function will be called on click of "Reset Final Approval" button
  resetFinalApproval(){
    this.applicationService.reviseFinalApproval(this.application.uid).subscribe(response =>{
      this._snackbar.open("Revise Final Approval completed successfully", "Close" ,{
        duration:5000,
      });
      this.refreshApplicationDataAndGetPostApprovalChanges()
    })
  }

    //this function will be called on click of " Reset Provisional Approval" button
  resetProvisionalApproval(){
    this.applicationService.reviseProvisionalApproval(this.application.uid).subscribe(response =>{
      this._snackbar.open("Revise Provisional Approval completed successfully", "Close" ,{
        duration:5000,
      });
      this.refreshApplicationDataAndGetPostApprovalChanges()
    })
  }

    //this function will be called on click of "Reset Approval" button
  resetApproval(){
    this.applicationService.reviseProvisionalApproval(this.application.uid).subscribe(response =>{
      this._snackbar.open("Revise Approval completed successfully", "Close" ,{
        duration:5000,
      });
      this.refreshApplicationDataAndGetPostApprovalChanges()
    })
  }


}
