/**
* Created by pavi2p on 13-06-2017.
*/
import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {isUndefined} from 'util';
import {AlertService} from 'ng-jhipster';

@Injectable()
export class IgFormHelperService {
constructor(private alertService: AlertService) {};

    public handleSubmitError(error: any, form: FormGroup) {
        if (error.status === 422) {
            const data = error.json().errors;
            const fields = Object.keys(data || {});
            fields.forEach((field) => {
                const control = this.findFieldControl(field, form);
                if (isUndefined(control)) {
                    this.alertService.error(`${field} is not defined in form but there is a validation error for it, error: ${data[field]}`, null, null);
                }else {
                    const errors = this.fetchFieldErrors(data, field);
                    control.setErrors(errors);
                    control.markAsDirty();
                }
            });
        }else {
            this.alertService.error(error.message, null, null);
        }
    }

    private findFieldControl(field: string, form: FormGroup): AbstractControl {
        let control: AbstractControl;
        if (field === 'base') {
            control = form;
        } else if (form.contains(field)) {
            control = form.get(field);
        } else if (field.match(/_id$/) && form.contains(field.substring(0, field.length - 3))) {
            control = form.get(field.substring(0, field.length - 3));
        } else if (field.indexOf('.') > 0) {
            let group = form;
            field.split('.').forEach((f) => {
                if (group.contains(f)) {
                    control = group.get(f);
                    if (control instanceof FormGroup) {
                        group = control;
                    }
                } else {
                    control = group;
                }
            });
        } else {
            // Field is not defined in form but there is a validation error for it, set it globally
            control = undefined;
        }
        return control;
    }

    private fetchFieldErrors(data: any, field: string): any {
        const errors = {};
        data[field].forEach((e) => {
            let name: string;
            name = 'remote';
            delete e.error;
            errors[name] = e;
        });
        return errors;
    }

}
