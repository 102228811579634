import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { AlertService, JhiLanguageService } from 'ng-jhipster';

@Component({
    selector: 'jhi-loan-od-accounts-insurancepolicylist',
    templateUrl: './loan-od-accounts-insurancepolicylist.component.html',
})
export class LoanOdAccountsInsurancePolicyListComponent implements OnInit {

    subscription: any;
    policyList: any = [];
    accountId: any;
    // tslint:disable-next-line: max-line-length
    constructor(private loanOdAccountsService: LoanOdAccountsService, private jhiLanguageService: JhiLanguageService, private alertService: AlertService, private router: Router, private route: ActivatedRoute) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }
    ngOnInit(): void {
        this.subscription = this.route.params.subscribe((params) => {
            this.accountId = params['accountId'];
            this.loadList(params['accountId']);
        });
    }
    loadList(id) {
        this.loanOdAccountsService.getPolicyList(id).subscribe((list) => {
            this.policyList = list;
        });
    }
    fnNavToAdd() {
        this.router.navigate([`/addPolicy`, this.accountId]);
    }
    // tslint:disable-next-line: member-ordering
    fileName: string = '';
    fnPrint(policyNum) {
        this.loanOdAccountsService.fnPrint(policyNum).subscribe((res: any) => {
            // this.loadList(this.accountId);
            const linkSource = `data:application/pdf,${res._body}`;
            const downloadLink = document.createElement("a");
            const fileName = policyNum + '.pdf';
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            // if (res) {
            //     const blob = new Blob([res._body], { type: 'application/pdf' });
            //     const file = new File([blob], policyNum + '.pdf', { type: 'application/pdf' });
            //     saveAs(file);
            // }
        });


    }
    fnCancel(applicationId) {
        this.loanOdAccountsService.fnCancel(applicationId).subscribe((res: any) => {
            // this.loadList(this.accountId);
            console.log(res);
            if (res._body === 'Success') {
                this.alertService.success('encoreclientApp.loanOdAccounts.cancelSuccess', { policyNum: applicationId });
                this.loadList(this.accountId);
            }
        });
    }
}