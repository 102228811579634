import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';
import {RouterModule, Route} from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import { MaterialLibModule } from '@app/material-lib.module';
import { ApprovalQueueComponent } from './approval-queue/approval-queue.component';
import { TatMatrixComponent } from './tat-matrix/tat-matrix.component';
import { DashboardRoutingModule} from './dashboard-routing.module';
import { SalesBoardComponent } from './sales-board/sales-board.component';
import { FieldBoardComponent } from './field-board/field-board.component'
@NgModule({
  declarations: [DashboardComponent, ApprovalQueueComponent, TatMatrixComponent, SalesBoardComponent, FieldBoardComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    DashboardRoutingModule
  ],
  providers: [DashboardService],
})
export class DashboardModule { }
