import {AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import {MetricsService} from './metrics.service';
import {ActivatedRoute} from '@angular/router';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

export class ChartData {

  constructor(
    public labels?: [],
    public data?: [],
    public type?: string,
    public options?: object
  ) {}
}

@Component({
  selector: 'ig-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.css'],
})
export class MetricsComponent implements OnInit, OnDestroy {
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: 'Card 1', cols: 2, rows: 1 },
        { title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 2 },
        { title: 'Card 4', cols: 1, rows: 1 }
      ];
    })
  );

  metrics: any;
  gauges: any;

  // PolarArea
  public polarAreaChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail Sales', 'Telesales', 'Corporate Sales'];
  public polarAreaChartData: number[] = [300, 500, 100, 40, 120];
  public polarAreaLegend = true;

  public polarAreaChartType = 'polarArea';

  public chartData: ChartData = new ChartData([], [], 'pie', {});

  private defaultChartOptions = {
    legend: {
      position: 'bottom',
    }
  };

  constructor(@Inject(LOCALE_ID) private locale: string,
              private breakpointObserver: BreakpointObserver,
              private metricService: MetricsService,
              private _route: ActivatedRoute) {
    this._route.data.pipe(takeUntil(componentDestroyed(this))).subscribe((res) => {
      this.metrics = res.metrics;
      this.gauges = this.metrics['gauges'];
    });
  }



  ngOnInit(): void {
    this.chartData = this.generateThreatCountData(this.gauges);
  }

  generateThreatCountData(gauge: any): ChartData {

    const chartLabels: any = [];
    const chartData: any = [];
    chartLabels.push(`Total`);
    chartData.push(gauge['jvm.threads.count'].value);
    chartLabels.push(`Runnable`);
    chartData.push(gauge['jvm.threads.runnable.count'].value);
    chartLabels.push(`Waiting`);
    chartData.push(gauge['jvm.threads.waiting.count'].value);
    chartLabels.push(`Timed Waiting`);
    chartData.push(gauge['jvm.threads.timed_waiting.count'].value);
    chartLabels.push(`Blocked`);
    chartData.push(gauge['jvm.threads.blocked.count'].value);
    chartLabels.push(`Deadlock`);
    chartData.push(gauge['jvm.threads.deadlock.count'].value);

    return new ChartData(chartLabels, chartData, 'polarArea', this.defaultChartOptions);

  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  ngOnDestroy(): void {
  }
}
