import {ActivatedRoute, Params} from '@angular/router';
import {MatPaginator, MatSort, PageEvent, Sort} from '@angular/material';
import {AsyncSubject, BehaviorSubject, merge, Observable, Subject} from 'rxjs';
import {PagingAndSortParams} from './paging-and-sort-params';
import {ListParams} from './list-params';
import {take, takeUntil, tap} from 'rxjs/operators';

type LCallback = ()  => void;

export class ListSource {

  private unsubscribe$ = new Subject<void>();

  private urlParams: Observable<Params>;

  readonly listParamReturn$:  BehaviorSubject<ListParams>;

  constructor(private activatedRoute: ActivatedRoute,
              private paginator: MatPaginator,
              private sort: MatSort) {
    this.listParamReturn$ = new BehaviorSubject<ListParams>(new ListParams([], new PagingAndSortParams()));
  }

  getQueryParamWithPaginationAndSortParams() {
    return {
      queryParams: {
        'page': this.paginator.pageIndex, 'size': this.paginator.pageSize,
        'sc': this.sort.active, 'sd': this.sort.direction
      },
      relativeTo: this.activatedRoute
    };
  }

  getQueryParam() {
    return {
      queryParams: {}
    };
  }
  subscribePaginationAndSortChange(): Observable<any> {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(take(1)).subscribe(() => this.paginator.pageIndex = 0);

    return merge(this.paginator.page, this.sort.sortChange);
  }

  getListParams(): Observable<ListParams> {
  //  const returnAsync = new BehaviorSubject<ListParams>(new ListParams([], new PagingAndSortParams()));
    merge(this.activatedRoute.params,
      this.activatedRoute.queryParams).pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      const pagingSortParam = new PagingAndSortParams(params[PagingAndSortParams.PAGEINDEXKEY],
        params[PagingAndSortParams.PAGESIZEKEY],
        params[PagingAndSortParams.SORTCOLUMNKEY],
        params[PagingAndSortParams.SORTDIRECTION]);
      this.listParamReturn$.next(new ListParams(params, pagingSortParam));
    });
    return this.listParamReturn$;
  }

  getPagingParams(): Observable<PagingAndSortParams> {
    const returnAsync = new AsyncSubject<PagingAndSortParams>();
    this.urlParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      const pagingSortParam = new PagingAndSortParams(params[PagingAndSortParams.PAGEINDEXKEY],
        params[PagingAndSortParams.PAGESIZEKEY],
        params[PagingAndSortParams.SORTCOLUMNKEY],
        params[PagingAndSortParams.SORTDIRECTION]);
      returnAsync.next(pagingSortParam);
      returnAsync.complete();
    });
    return returnAsync;
  }

  getParams(): Observable<Params> {
    const returnAsync = new AsyncSubject<Params>();
    this.urlParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      returnAsync.next(params);
      returnAsync.complete();
    });
    return returnAsync;
  }

  disconnect() {
    this.listParamReturn$.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
