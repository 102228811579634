import { Pipe, PipeTransform } from '@angular/core';
import { DefaultCurrencySymbolService } from './defaultCurrencySymbol.service';

@Pipe({name: 'moneyWithCurrency'})
export class MoneyWithCurrencyPipe implements PipeTransform {

   
    private symbol: string;

    constructor(private defaultCurrencySymbolService: DefaultCurrencySymbolService) {
        this.symbol = this.defaultCurrencySymbolService.getSymbol();
        if (!this.symbol) {
            this.defaultCurrencySymbolService.syncSymbol();
        }
    }

    transform(moneyMagnitude: number): string {
        if (!moneyMagnitude) {
            return '';
        } else {
            this.symbol = this.symbol != undefined || this.symbol != '' ? this.symbol : this.defaultCurrencySymbolService.getSymbol();
            let moneyMagnitudeType = moneyMagnitude;
            if (typeof moneyMagnitudeType === 'string') {
                moneyMagnitudeType = parseFloat(moneyMagnitudeType);
            }
            if (!this.symbol) {
                this.defaultCurrencySymbolService.syncSymbol();
                this.symbol = this.defaultCurrencySymbolService.getSymbol();
            }
            console.log(this.symbol);
            return this.symbol + moneyMagnitudeType.toFixed(2);
        }
    }
}
