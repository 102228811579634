import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { BusinessEntity } from '../businessInformation/business-information.model';
import { ReferenceBasic } from './business-reference.model';
import { LoanApplication,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { MatSnackBar } from '@angular/material';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { ValidationService } from '../../../application-validators/validation.service';
@Component({
  selector: 'eng-business-reference',
  templateUrl: 'business-reference.template.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class BusinessReferenceComponent implements OnInit {

  @Input()
  application: LoanApplication;

  @Input()
  business: BusinessEntity;
  @Input()
  menuCode:string;

  references: ReferenceBasic[];
  businessReferenceForm: FormGroup;
  referenceTypes: NameValueDto[];
  selectedReference: ReferenceBasic;

  isFormDisabled : boolean;
  showForm: boolean;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService,
    private validationService: ValidationService) {
      this.businessReferenceForm = this.formBuilder.group({
        referenceType:'',
        referenceName: '',
        firstLine: '',
        secondLine:'',
        thirdLine: '',
        mobileNumber: '',
        landlineNumber: ''
      });
      this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit(): void {
    this.getRefCodes();
    this.isFormDisabled = true;
    this.applyValidatorsToBusinessReference();
    this.getMenuItemAccess();

  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('business_reference_type').subscribe((response: any) => {
      this.referenceTypes = response.business_reference_type;
    });
  }

  fetchBusinessReference() {
    this.showForm = false;
    this.applicationService.getBusinessReference(this.application.uid,
      this.business.uid).subscribe((response: any) => {
        if (response) {
          this.references = response.body.referenceBasic;
          this.references.forEach((element, index) => {
            this.references[index]["deleteBtnDisabled"] = true;
          });
          this.showForm = (this.references && this.references.length > 0);
          this.buildBusinessReferenceForm();
        }
    });
  }

  addNewReference() {
    this.showForm = true;
      this.changeSelectedReference(new ReferenceBasic(), undefined, "new");
      this.isFormDisabled = false;
  } 

  changeSelectedReference(businessReference ?: ReferenceBasic, index?: number, from?: string) {
    this.showForm = true;
    this.references.forEach((element, i) => {
      this.references[i].deleteBtnDisabled = true;
    });
    if (from != "new") {
      this.references[index].deleteBtnDisabled = false;
    }
    this.buildBusinessReferenceForm(businessReference);
  }

  buildBusinessReferenceForm(reference ?: ReferenceBasic) {
    if(this.showForm){
      if (!reference){
        reference = this.references[0];
        this.references[0].deleteBtnDisabled = false;
      }
      this.businessReferenceForm.patchValue({
        referenceType: reference.relationshipType,
        referenceName: reference.name,
        firstLine: reference.addressLine1,
        secondLine: reference.addressLine2,
        thirdLine: reference.addressLine3,
        mobileNumber: reference.primaryPhone,
        landlineNumber: reference.landlinePhone
      });
      this.selectedReference = reference;
    }
  }

  saveReference() {
    this.validationService.markFormGroupTouched(this.businessReferenceForm)
    if(!this.isFormDisabled && this.businessReferenceForm.valid){
      let personUid = this.selectedReference.personUid ? this.selectedReference.personUid : "0";
      let businessReferenceFormFields = this.businessReferenceForm.value;
      this.selectedReference.relationshipType = businessReferenceFormFields.referenceType;
      this.selectedReference.name = businessReferenceFormFields.referenceName;
      this.selectedReference.addressLine1 = businessReferenceFormFields.firstLine;
      this.selectedReference.addressLine2 = businessReferenceFormFields.secondLine;
      this.selectedReference.addressLine3 = businessReferenceFormFields.thirdLine;
      this.selectedReference.primaryPhone = businessReferenceFormFields.mobileNumber;
      this.selectedReference.landlinePhone = businessReferenceFormFields.landlineNumber;
      this.applicationService.saveBusinessReference(this.application.uid,
        this.business.uid, personUid, this.selectedReference).toPromise().then(
          (_success) => {
            this._snackbar.open("Business reference updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.fetchBusinessReference();
          },(failure) => {
            let errormesg =[]
            errormesg.push("Business reference update failed")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
          console.log(failure);
        }
      );
    }
  }

  enableDisableForm() {
    this.isFormDisabled = false;
  }

  cancelForm() {
    this.references.forEach((element, i) => {
      this.references[i].deleteBtnDisabled = true;
    });
    if(this.references && this.references.length > 0) {
      this.references[0].deleteBtnDisabled = false;
      this.buildBusinessReferenceForm(this.references[0]);
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
  }
  //this function will apply validators to form group
  applyValidatorsToBusinessReference() {
    this.validationService.applyValidationRules(this.businessReferenceForm,"BusinessReferences").then((controlValidators) => {
      this.fetchBusinessReference();
    }).catch(() => {
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

     //this function will be called on click of delete icon on Reference
   deleteReference(selectedReference) {
    this.applicationService.referenceDelete(this.application.uid, selectedReference.partyLinkUid).subscribe((response) => {
      if(response.body.status == "success") {
      this._snackbar.open("Business reference deleted successfully", "Close", {
        duration: 2000,
      });
      this.fetchBusinessReference();
    } else {
      let errormesg = []
      errormesg.push("Business reference deleted failed")
      errormesg.push(response.body.message);
      this.applicationService.displayErrorMessages(errormesg);
    }}, (failure) => {
      let errormesg = []
      errormesg.push("Business reference deleted failed")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this Business reference?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteReference(this.selectedReference)
      }
    })
  }

}