import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  constructor(private http: HttpClient) { }

  getMetrics(): Observable<any> {
    return this.http.get('management/metrics');
  }

  threadDump(): Observable<any> {
    return this.http.get('management/threaddump');
  }

}
