import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { IgFileService } from '@ig-core/form/igFile.service';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['../single-file-upload/single-file-upload.component.css']
})
export class FileDownloadComponent implements OnInit, OnDestroy {

  constructor(
    private snackBar: MatSnackBar,
    private igFileService: IgFileService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  downloadFile(fileType: string, fileExt: string) {
    this.igFileService.downloadFile(fileType).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response: any) => this.downloadFileData(response));
  }

  downloadFileData(response: any) {
    if (response.data.size > 0) {
      saveAs(response.data, response.filename);
    } else {
      this.snackBar.open(`Unable to download the file...Please try again`, 'Close');
    }
  }
}
