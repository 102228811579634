
//import { PaginationConfig } from '../../../blocks/config/uib-pagination.config';
import { Principal } from '../shared/auth/principal.service';
import { ITEMS_PER_PAGE } from '../shared/constants/pagination.constants';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventManager, ParseLinks, PaginationUtil, JhiLanguageService, AlertService } from 'ng-jhipster';
import { DateConverterService } from '../shared/date/date-converter.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-disbursements',
    templateUrl: './loan-od-accounts-disbursements.component.html'
})
export class  LoanOdAccountsDisbursementsComponent implements OnInit, OnDestroy {

    currentAccount: any;
    loanOdAccounts: LoanOdAccounts[];
    error: any;
    success: any;
    /*eventSubscriber: Subscription;*/
    currentSearchString: string;
    currentSearch: {
        branchCode: string;
        accountId: string;
        disbursementDate: any;
    }
    routeData: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    aggregations: any;
    isSearch: any;
    branchList: any[];
    disbursementDto: any[];
    searchString: string;

    constructor(
        private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private parseLinks: ParseLinks,
        private alertService: AlertService,
        private principal: Principal,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eventManager: EventManager,
        private paginationUtil: PaginationUtil,
        private dateConverter: DateConverterService
    ) {
        this.loanOdAccounts = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            this.page = data['pagingParams'].page;
            this.previousPage = data['pagingParams'].page;
            this.reverse = data['pagingParams'].ascending;
            this.predicate = data['pagingParams'].predicate;
            this.itemsPerPage = data['pagingParams'].size;
            this.searchString = data['pagingParams'].search;
        });
        const searchParams = new URLSearchParams(this.searchString);
        this.currentSearch = {
            branchCode: searchParams.get('branchCode') ? searchParams.get('branchCode') : '',
            accountId: searchParams.get('accountId') ? searchParams.get('accountId') : '',
            disbursementDate: searchParams.get('disbursementDate') ? searchParams.get('disbursementDate') : undefined
        };
        this.setCurrentSearchString();
    }

    loadAll() {
        if (this.currentSearch.disbursementDate && (typeof(this.currentSearch.disbursementDate) !== 'string')) {
            this.currentSearch.disbursementDate = this.dateConverter.fromNgbDatePickerToString(this.currentSearch.disbursementDate);
        }
        this.loanOdAccountsService.queryScheduledDisbursements({
            page: this.page - 1,
            size: this.itemsPerPage,
            query: this.currentSearch,
            sort: this.sort()}).subscribe(
            (res: Response) => this.onSuccess(res.json()),
            (res: Response) => this.onError(res.json())
        );
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        this.router.navigate(['/loan-od-accounts/disbursments'],
            { queryParams:
                {
                    search: this.currentSearchString,
                    page: this.page,
                    size: this.itemsPerPage,
                    sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
                }
            });
        this.loadAll();
    }

    clear() {
        this.loanOdAccounts = [];
        this.page = 1;
        this.currentSearch =  {
            branchCode: '',
            accountId: '',
            disbursementDate: undefined
        }
        this.currentSearchString = '';
        this.transition();
    }
    search() {
        this.page = 1;
        this.setCurrentSearchString();
        this.transition();
    }

    setCurrentSearchString() {
        this.currentSearchString = '?';
        if (this.currentSearch) {
            for (const key of Object.keys(this.currentSearch)) {
                if (typeof(this.currentSearch[key]) === 'object') {
                    let month: any = 0;
                    let day: any = 0;

                    if (this.currentSearch[key].month < 10) {
                        month = '0' + this.currentSearch[key].month;
                    } else {
                        month = this.currentSearch[key].month;
                    }

                    if (this.currentSearch[key].day < 10) {
                        day = '0' + this.currentSearch[key].day;
                    } else {
                        day = this.currentSearch[key].day;
                    }

                    this.currentSearch[key] = this.currentSearch[key].year + '-' + month + '-' + day;
                }

                if (this.currentSearch[key] !== '' && this.currentSearch[key] !== undefined) {
                    this.currentSearchString += key + '=' + this.currentSearch[key] + '&';
                }
            }
        }
    }

    ngOnInit() {
        this.loadAll();
        this.principal.identity().then((account) => {
            this.currentAccount = account;
        });
        this.loanOdAccountsService.getBranches().subscribe(
            (res) => this.updateBranchList(res),
            (res) => this.onError(res)

        );
    }

    private updateBranchList(data) {
        this.branchList = data;
    }

    ngOnDestroy() {
    }

    trackId(index: number, item: LoanOdAccounts) {
        return item.id;
    }

    navigateToView(loanOdAccounts) {
        this.router.navigate(['/loan-od-accounts/disbursments/view/' + loanOdAccounts.id]);
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    private onSuccess(data) {
        if ((typeof(this.currentSearch.disbursementDate) === 'string') && this.currentSearch.disbursementDate !== '') {
            this.currentSearch.disbursementDate = this.dateConverter.fromStringToNgbDatePicker(this.currentSearch.disbursementDate);
        }
        this.disbursementDto = data;
    }

    private onError(error) {
        this.alertService.error('encoreclientApp.loanOdAccounts.error');
    }

    private onReindexSuccess() {
        this.alertService.success('encoreclientApp.loanOdAccounts.reindex');
    }

    disburse(accountId) {
        this.router.navigate(['/loan-od-accounts/disburse/' + accountId]);
    }

    close() {
        this.router.navigate(['/loan-od-accounts']);
    }
}
