import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecureStorageService } from '@ig-core/authentication/secureStorage.service';

@Component({
  selector: 'eng-reports-details',
  templateUrl: 'reports-details.template.html',
  styleUrls: ['reports-menu.style.scss']
})
export class ReportDetailsComponent implements OnInit {
  selectedMenu: string = '';
  user: any;
  constructor(
    private router: Router,
    private secureStorageService: SecureStorageService
  ) { }

  ngOnInit() {
    this.userIncludesReports()
  }

  userIncludesReports() {
    this.user = this.secureStorageService.secureSessionStorage.getItem("logInUserDetails")
  }
}
