import {NameDto} from './name-dto.model';
import { Money } from './money';

export class AccountMandateDto {
    constructor(
        public id?: number,
        public version?: number,
        public accountId?: string,
        public mandateActive?: boolean,
        public accountHolderName?: NameDto,
        public sponsorAccountCode?: string,
        public sponsorBankCode?: string,
        public utilityCode?: string,
        public bankName?: string,
        public bankCity?: string,
        public branchName?: string,
        public tenure?: number,
        public frequency?: string,
        public reference1?: string,
        public reference2?: string,
        public bankAccountNumber?: string,
        public accountType?: string,
        public ifscCode?: string,
        public micr?: string,
        public maximumAmount?: Money,
        public umrn?: string,
        public phoneNo?: string,
        public emailId?: string,
        public achStartDate?: any,
        public achEndDate?: any,
        public mandateOpenDate?: any,
        public mandateFilePath?: string,
        public registrationStatus?: string,
        public verificationStatus?: string,
        public remarks?: string,
        public registrationDate?: any,
        public mandateApplicationId?: number,
        public mandateFileDataId?: any,

    ) {
        this.mandateActive = false;
    }
}
