import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, EventManager, JhiLanguageService } from 'ng-jhipster';
//import { Branch } from '../../base/bank/branch';
//import { Product } from '../../casa/casaproduct/product.model';
//import { LoanOdProductCompositeDto } from '../loan-od-products/loan-od-products-composite.model';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-proceed',
    templateUrl: './loan-od-accounts-proceed.component.html',

})
export class LoanOdAccountsProceedComponent implements OnInit, OnDestroy {

    loanOdAccounts: LoanOdAccounts;
    // products: Product[];
    // selectedProduct: LoanOdProductCompositeDto;
    // branches: Branch;
    products: any;
     selectedProduct: any;
     branches: any;
    isProceed: Boolean;
    categories: any[];
    accountIdGenerationRules: any[];
    currencies: any[];
    @Output() basicCheck: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        // private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService,
        private loanODAccountsService: LoanOdAccountsService,
        private eventManager: EventManager,
        private route: ActivatedRoute
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.products = new Array<any>();
    }

    ngOnInit() {
        this.loanOdAccounts = new LoanOdAccounts();
        this.loanODAccountsService.setLoanOdAccount(this.loanOdAccounts);
        this.loanOdAccounts = this.loanODAccountsService.getLoanOdAccount();
        if (this.loanOdAccounts === undefined) {
            this.loanOdAccounts = new LoanOdAccounts();
        }
        this.loanODAccountsService.getProductList().subscribe(
            (res) => {
                this.setProductList(res);
            });
    }
    ngOnDestroy() {
        this.loanOdAccounts = this.loanODAccountsService.getLoanOdAccount();
        this.loanODAccountsService.setLoanOdAccount(this.loanOdAccounts);
        //this.loanODAccountsService.setLoanODProducts(this.selectedProduct);
    }

    getBranchSetListForProduct(productCode) {
        this.loanODAccountsService.findBranchSetCodeforProductCode(productCode).subscribe(
            (res) => {
                this.setBranchSetListForProduct(res);
            });
        this.loanODAccountsService.getProductForProductCode(productCode).subscribe(
            (res) => {
                this.setSelectedProduct(res);
            });
    }

    private setCategories(data) {
        this.categories = data;
    }
    private setAccountIdGenerationRules(data) {
        this.accountIdGenerationRules = data;
    }
    private setCurrencies(data) {
        this.currencies = data;
    }
    private setProductList(data) {
        this.products = data;
    }
    private setBranchSetListForProduct(data) {
        this.branches = data;
    }
    private setSelectedProduct(data) {
        this.selectedProduct = data;
    }
    clear() {
        this.loanOdAccounts = new LoanOdAccounts();
    }

    proceed() {
        if (this.loanOdAccounts.customerId1 === this.loanOdAccounts.customerId2) {
            this.alertService.error('encoreclientApp.loanOdAccounts.duplicateCustomer1&2');
        } else if (this.loanOdAccounts.customerId1 === this.loanOdAccounts.guarantorCustomerId1) {
            this.alertService.error('encoreclientApp.loanOdAccounts.duplicateCustomer1&guarantor');
        } else if (this.loanOdAccounts.customerId2 !== null && this.loanOdAccounts.customerId2 !== undefined &&
            (this.loanOdAccounts.customerId2 === this.loanOdAccounts.guarantorCustomerId1)) {
            this.alertService.error('encoreclientApp.loanOdAccounts.duplicateCustomer2&guarantor');
        } else if (this.loanOdAccounts.guarantorCustomerId1 !== null && this.loanOdAccounts.guarantorCustomerId2 !== undefined &&
            (this.loanOdAccounts.guarantorCustomerId1 === this.loanOdAccounts.guarantorCustomerId2)) {
            this.alertService.error('encoreclientApp.loanOdAccounts.duplicateGuarantor1&guarantor2');
        } else {
            this.basicCheck.emit(this.loanOdAccounts);
        }
    }

    save() {

    }
}
