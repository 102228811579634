export class AccountProfileDto {
    constructor(
        public id?: number,
        public version?: number,
        public accountId?: string,
        public accountName?: string,
        public branchCode?: string,
        public glSubHead?: string,
        public currencyCode?: string,
        public productType?: string,
        public productCode?: string,
        public operationalMode?: string,
        public operationalStatus?: string,
        public openedOnValueDate?: any,
        public openedOnTransactionDate?: any,
        public mandateOpenDate?: any,
        public lastActivatedDate?: any,
        public lastFreezedDate?: any,
    ) {}
}
