import { Component, Input, OnInit } from '@angular/core';
import { LoanApplication } from '@app/applications/applications.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { MatSnackBar } from '@angular/material';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { ApplicationService } from '@app/applications/application.service';

@Component({
  selector: 'eng-post-approval-change',
  templateUrl: './update-approval.component.html',
  styleUrls: ['../../../application-details.styles.scss', './update-approval.component.scss']
})
export class UpdateApprovalComponent implements OnInit {
  @Input() application: LoanApplication;
  @Input() menuItem:string;
  @Input() menuCode:string;
  @Input()
  postApprovalList: any;

  CategoryTypes: NameValueDto;

  constructor(private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService) {
  }

  ngOnInit() {
  }

  openRevertConfirmation(index:number){
    this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Revert","message":"Are you sure you want to revert this approval?"})
    this.confirmationDialogService.confirmed().subscribe(data=>{
      if(data){
        this.revertApproval(index)
      }
    })
  }

  revertApproval(index:number) {
    var postApprovalItem = this.postApprovalList[index];
    this.applicationService.revertSingleApproval(this.application.uid, postApprovalItem.uid).subscribe((response) => {
        this.postApprovalList.splice(index, 1)
        this._snackbar.open(postApprovalItem.category + " reverted successfully ", "Close", {
          duration: 2000,
        });
    }, (failure) => {
      this._snackbar.open("Failed to revert selected approval" , "close")
  })
  }
}