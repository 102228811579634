import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, EventManager, JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { ApplicationDateService } from '../shared/date/application-date.service';
import { DateConverterService } from '../shared/date/date-converter.service';
import { AccountMandateDto } from './account-mandate-dto.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-amend-update',
    templateUrl: './loan-od-accounts-amend-update.component.html',
})
export class LoanOdAccountsAmendUpdateComponent implements OnInit, OnDestroy {

    accountMandateDto: AccountMandateDto;
    mandateFileDataFile: any;
    files: FormGroup;
    @ViewChild('mandateFileData', null) mandateFileData: ElementRef;
    applicationDate: any;
    frequencyList: any[];
    accountCodeList: any[];
    accountTypeList: any[];
    registrationStatusList: any;
    accountId: string;
    id: string;
    dateSubscription: Subscription;
    constructor(
        private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService,
        private router: Router,
        private loanOdAccountsService: LoanOdAccountsService,
        private eventManager: EventManager,
        private route: ActivatedRoute,
        private applicationDateService: ApplicationDateService,
        private dateConverter: DateConverterService,
        private fb: FormBuilder
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.mandateFileDataFile = '';
        this.files = this.fb.group({
            photo: null,
        });
    }

    ngOnInit() {
        this.accountId = this.route.params['_value'].accountId;
        this.id = this.route.params['_value'].id;
        this.loanOdAccountsService.getAccountMandate(this.accountId, this.id).subscribe(
            (res) => {
                this.accountMandateDto = res.json();
                this.accountMandateDto.achEndDate = this.dateConverter.fromServerToNgbDatePicker(this.accountMandateDto.achEndDate);
                this.accountMandateDto.achStartDate = this.dateConverter.fromServerToNgbDatePicker(this.accountMandateDto.achStartDate);
                this.accountMandateDto.mandateOpenDate = this.dateConverter.fromServerToNgbDatePicker(this.accountMandateDto.mandateOpenDate);
            }
        );
        this.loanOdAccountsService.getFrequencies().subscribe(
            (data) => {
                this.frequencyList = data.json();
            }
        );
        this.loanOdAccountsService.getAccountCodesList().subscribe(
            (data) => {
                this.accountCodeList = data.json();
            }
        );
        this.loanOdAccountsService.getAccountTypes().subscribe(
            (data) => {
                this.accountTypeList = data.json();
            }
        );
        this.loanOdAccountsService.getEnumeration('registrationStatus').subscribe(
            (data) => {
                this.registrationStatusList = data;
            }
        );
        this.dateSubscription = this.applicationDateService.getDate().subscribe(
            (date) => {
                this.applicationDate = date;
            }
        );
    }

    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
    }

    clear() {
        this.accountMandateDto = new AccountMandateDto();
        this.router.navigate(['/loans/loan-od-accounts/electronicMandate']);
    }
    save() {
        if (this.dateConverter.fromNgbDatePickerToDateObject(this.accountMandateDto.achStartDate)
            >= this.dateConverter.fromNgbDatePickerToDateObject(this.accountMandateDto.achEndDate)) {
            this.alertService.error('encoreclientApp.loanOdAccounts.startEndDateError');
        } else if (this.accountMandateDto.mandateActive === true && this.accountMandateDto.registrationStatus !== 'ACCEPTED') {
            this.alertService.error('encoreclientApp.loanOdAccounts.activeMandateRegistrationStatusError1', { registrationStatus: this.accountMandateDto.registrationStatus });
        } else if (this.accountMandateDto.registrationStatus === 'ACCEPTED' && this.accountMandateDto.mandateActive === false) {
            this.alertService.error('encoreclientApp.loanOdAccounts.activeMandateRegistrationStatusError2', { registrationStatus: this.accountMandateDto.registrationStatus });
        } else if (this.accountMandateDto.registrationStatus === 'ACCEPTED' && !this.accountMandateDto.umrn) {
            this.alertService.error('encoreclientApp.loanOdAccounts.umrnError');
        } else {
            this.loanOdAccountsService.updateMandate(this.files, this.accountMandateDto).subscribe((res) =>
                this.onSaveSuccess(res.json()), (res: Response) => this.onSaveError(res));
        }
    }

    private onSaveSuccess(result) {
        this.eventManager.broadcast({ name: 'accountMandateUpdate', content: 'OK' });
        this.alertService.success('encoreclientApp.loanOdAccounts.mandtaeUpdatedSuccess');
        this.router.navigate(['/loans/loan-od-accounts/show/' + result.accountId + '/' + result.id]);
    }

    private onSaveError(error) {
        this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: error._body });
        this.onError(error);
    }

    private onFileSaveError(error) {
        try {
            error.json();
        } catch (exception) {
            error.message = error.text();
        }
        this.onError(error);
    }

    onFileChange(event, key) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.files.get(key).setValue(file);
        }
    }

    clearFile(key) {
        this.files.get(key).setValue(null);
        if (key === 'mandateFileData') {
            this.mandateFileData.nativeElement.value = '';
        }

    }
    private onError(error) {
        this.alertService.error('encoreclientApp.loanOdAccounts.saveError');
    }
}
