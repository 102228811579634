import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormErrorModel} from '@ig-core/form/form-error.model';
import {IgFormService} from '@ig-core/form/form.service';
import {IgPatternValidator} from '@ig-core/form/additional-validators/ig-pattern-validator';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

import {Branch} from '../branch.model';
import {BranchService} from '../branch.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ProductSetService } from '@app/admin/product-set/product-set.service';
import { ProductSet } from '@app/admin/product-set/product-set.model';

@Component({
    selector: 'app-branch-editor',
    templateUrl: './branch-editor.component.html',
    styleUrls: ['../branch.component.css']
})
export class BranchEditorComponent implements OnInit, OnDestroy {
    branch: Branch;
    title: string;
    editable = true;
    countryList: NameValueDto[];
    stateList: NameValueDto[];
    districtList: NameValueDto[];
    cityList: NameValueDto[];
    productsetList: ProductSet[];
    productList: string[];
    public branchEditorForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {

        'id': '',

        'version': '',

        'bName': '',

        'bCode': '',

        'bGstin': '',

        'bAddress1': '',

        'bAddress2': '',

        'bAddress3': '',

        'bCity': '',

        'bDistrict': '',

        'bState': '',

        'bCountry': '',

        'bPinCode': '',

    };
    constructor(private branchService: BranchService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder,
                private referenceCodeService: ReferenceCodeService,
                private _snackbar: MatSnackBar,
                private igFormService: IgFormService,
                private productsetService: ProductSetService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.branch === undefined) {
                    this.branch = {};
                } else {
                    this.branch = data.branch;
                    this.productList = this.branch.products;
                }
                this.editable = data.editable;
            });
            this.activatedRoute.queryParams
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((param) => {
                this.title = JSON.parse(param.title)
            });
    }

    ngOnInit() {
        this.getRefCodes();
        this.getProductsets();
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.branchEditorForm.disable();
        }
    }

    ngOnDestroy(): void {
    }

    buildForm() {
        this.branchEditorForm = this.formBuilder.group({

            'id': [this.branch.id],

            'uid': [this.branch.uid],

            'version': [this.branch.version],

            'name': [this.branch.name, Validators.required],

            'code': [this.branch.code, Validators.required],

            'gstin': [this.branch.gstin],

            'address1': [this.branch.address1],

            'address2': [this.branch.address2],

            'address3': [this.branch.address3],

            'city': [this.branch.city],

            'district': [this.branch.district],

            'state': [this.branch.state],

            'country': [this.branch.country],

            'pinCode': [this.branch.pinCode],

            'productsetCode': [this.branch.productsetCode],
        });

        this.branchEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.branchEditorForm, this.formErrors, true);
        });
    }

    getRefCodes() {
    this.referenceCodeService.getShortRefCodes('Country').subscribe((response: any) => {
        this.countryList = response.Country;
      });
      this.referenceCodeService.getShortRefCodes('State').subscribe((response: any) => {
        this.stateList = response.State;
      });
      this.referenceCodeService.getShortRefCodes('District').subscribe((response: any) => {
        this.districtList = response.District;
      });
      this.referenceCodeService.getShortRefCodes('City').subscribe((response: any) => {
        this.cityList = response.City;
      });
    }

    getProductsets() {
      this.productsetService.getAllProductsets().subscribe((response: any) => {
          this.productsetList = response;
        });
      }


    onSubmit() {
        this.formError = new FormErrorModel(false, '');

        // mark all fields as touched
        this.igFormService.markFormGroupTouched(this.branchEditorForm);
        if (this.branchEditorForm.valid) {
            this.branchService.saveBranch(this.branchEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
                .subscribe((response) => this.onSubmitSuccess(response),
                    (response) => this.onSubmitError(response));
        } else {
            this.formErrors = this.igFormService.validateForm(this.branchEditorForm, this.formErrors, false);
            return false;
        }
    }

    private onSubmitSuccess(response) {
        let msg = '';
        if (this.branch.uid) {
          msg = `Updated branch successfully`;
        } else {
          msg = `Created branch successfully`;
        }
        this._snackbar.open( msg, 'Close');
        this.goToBranchListPage();
    }

    private onSubmitError(response) {
        const errorModel = this.igFormService.handleServerError(this.branchEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        }
    }

    goToBranchListPage() {
      if (this.branch.uid) {
        this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
      } else {
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
      }
    }

    addBranchToOrgStructure() {
        this.branchService.branchToAddOrgStructure(this.branch.code).subscribe(
            (_success) => {
                if (this.branch.code) {
                this._snackbar.open("Branch is added to org structure", "Close", {
                  duration: 2000,
                });
                this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
              }
              },(failure) => {
                this._snackbar.open("Branch added to org structure failed, " + failure, "Close", {
                duration: 2000,
              })
              console.log(failure);
            } 
        )
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    }

}
