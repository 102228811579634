import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';

import {Scorekey} from './score-key.model';
import {ScorekeyService} from './score-key.service';


export class ScorekeyDataSource extends  DataSource<Scorekey> {

  private ScorekeySubject = new BehaviorSubject<Scorekey[]>([]);
  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private scorekeyService: ScorekeyService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<Scorekey[]> {
    return this.ScorekeySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.ScorekeySubject.complete();
    this.totalCount$.complete();
  }

  getScoreKeyList(sortDirection = 'asc', pageIndex = 0, pageSize = 10) {
    this.scorekeyService.getScoreKeyList({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }).pipe(
      catchError(() => of([])),
      finalize(() => { })
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['body'].totalElements);
      this.ScorekeySubject.next(response['body'].content);
    });
  }


}
