import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthenticatedUserService} from '@ig-core/authentication/authenticated-user-service';
import { ApprovalQueueComponent } from './approval-queue/approval-queue.component';
import { TatMatrixComponent } from './tat-matrix/tat-matrix.component';
import { DashboardComponent } from './dashboard.component';
import { DashboardRouteGuardService } from './dashboard-route-guard.service';
import { SalesBoardComponent} from './sales-board/sales-board.component'
import { FieldBoardComponent } from './field-board/field-board.component';
const routes: Routes = [{
  path: 'dashboard',
  component: DashboardComponent,
  canActivate: [AuthenticatedUserService, DashboardRouteGuardService],

  children: [
    {path: 'approvalQueue', component: ApprovalQueueComponent},
    {path: 'tatMatrix', component: TatMatrixComponent},
    {path: 'salesBoard', component: SalesBoardComponent},
    {path: 'fieldBoard', component: FieldBoardComponent},
    ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }