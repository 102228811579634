import { Component, OnInit, Input } from '@angular/core';
import { BusinessInformation, BusinessEntity } from './business-information.model';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { LoanApplication ,ApplicationAccessSpecifier} from '@app/applications/applications.model';
//validation code
import { ValidationService } from '../../../application-validators/validation.service'

@Component({
  selector: 'eng-business-information',
  templateUrl: 'business-information.template.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class BusinessInformationComponent implements OnInit {

  @Input()
  application: LoanApplication;

  @Input()
  business: BusinessEntity;
  @Input()
  menuCode:string;

  businessInformation: BusinessInformation;
  businessInformationForm: FormGroup;
  selectedAccount: string;
  industryTypes: NameValueDto[];
  constitutions: NameValueDto[];
  annualTurnovers: NameValueDto[];
  businessStructures: NameValueDto[];
  businessNatures: NameValueDto[];
  
  textBoxDisabled = true;
  natureBusinessOthDisabled = true;
  
  selectedPanNumber: boolean;
  isFormDisabled: boolean;
  showForm: boolean;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private validationService: ValidationService) {
    this.dateAdapter.setLocale('en-IN');
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit(): void {
    this.isFormDisabled = true;
    this.getRefCodes();
    this.applyValidatorsToBusinessInformationForm()
    this.getMenuItemAccess();

  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('industry_type').subscribe((response: any) => {
      this.industryTypes = response.industry_type;
    });
    this.referenceCodeService.getShortRefCodes('constitution').subscribe((response: any) => {
      this.constitutions = response.constitution;
    });
    this.referenceCodeService.getShortRefCodes('annual_turnover').subscribe((response: any) => {
      this.annualTurnovers = response.annual_turnover;
    });
    this.referenceCodeService.getShortRefCodes('business_structure').subscribe((response: any) => {
      this.businessStructures = response.business_structure;
    });
    this.referenceCodeService.getShortRefCodes('business_nature').subscribe((response: any) => {
      this.businessNatures = response.business_nature;
    });
  }

  fetchbusinessInformation() {
    this.showForm = false;
    this.applicationService.getBusinessInformation(this.application.uid,
      this.business.uid).subscribe((response: any) => {
        if (response) {
          this.processBusinessInformationResponse(response.body);
        }
      });
  }

  processBusinessInformationResponse(businessInformation: BusinessInformation) {
    this.businessInformation = businessInformation;
    this.showForm = true;
    this.buildBusinessInformationForm(this.businessInformation);
    this.changeDateOfIncorporation();
  }

  changeDateOfIncorporation() {
    let yearsInBuss;
    if (this.businessInformationForm.controls['dateOfIncorporation'].value !== null) {
      let dateOfIncoInput = this.businessInformationForm.controls['dateOfIncorporation'].value;
      let dateofincor = new Date(dateOfIncoInput);
      let todayDate = new Date();
      let diffDateInMs = todayDate.getTime() - dateofincor.getTime();
      yearsInBuss = Math.abs(new Date(diffDateInMs).getUTCFullYear() - 1970);
      this.businessInformationForm.patchValue({
        yearsInBusiness: yearsInBuss
      });
    }
    // 1. dateof incor = get value of date of incor
    // 2. currdate = todays date.
    // 3. diff date = currdate - dateofincor.
    // 4. yearsinbuss = get year
    // 5. set it back to form
  }

  buildBusinessInformationForm(businessInformation) {
    if (this.showForm) {
      //this.businessInformationForm = this.formBuilder.group({
      this.businessInformationForm.patchValue({
        businessName: businessInformation.businessEntity.name,
        dateOfIncorporation: businessInformation.businessEntity.dateOfIncorporation === null ?
          undefined : new Date(businessInformation.businessEntity.dateOfIncorporation),
        gstNumber: businessInformation.businessEntity.gstin,
        pan: businessInformation.businessEntity.pan,
        form: businessInformation.businessEntity.form6061Flag,
        mobileNumber: businessInformation.entityProfile.primaryPhone,
        landLineNumber: businessInformation.entityProfile.landlinePhone,
        alternateNumber: businessInformation.entityProfile.alternatePhone,
        email: businessInformation.entityProfile.emailId,
        industryType: businessInformation.entityProfile.industryType,
        constitution: businessInformation.entityProfile.constitution,
        annualTurnover: businessInformation.entityProfile.turnoverRange,
        yearsInBusiness: businessInformation.businessEntity.yearsInBusiness,
        employee: businessInformation.entityProfile.employeeCount,
        worksHoursPerWeek: businessInformation.entityProfile.workhoursPerWeek,
        natureOfBusiness: businessInformation.entityProfile.businessNature,
        naoutrOfBusinessIfOthers: businessInformation.entityProfile.businessNatureOther,
        businessStructure: businessInformation.entityProfile.businessStructure,
        businessContiniousInOwnerAbsence: businessInformation.entityProfile.businessWithoutOwner
      });
      if(businessInformation.businessEntity.pan != null){
        this.businessInformationForm.controls.panOrForm.patchValue(true)
      }
      this.businessInformationForm.controls['yearsInBusiness'].disable();
    }
  }
  
  saveBusinessInformation() {
    this.validationService.markFormGroupTouched(this.businessInformationForm)
    if (!this.isFormDisabled && this.businessInformationForm.valid) {
      let businessInformationFormFields = this.businessInformationForm.value;
      this.businessInformation.businessEntity.name = businessInformationFormFields.businessName;
      this.businessInformation.businessEntity.dateOfIncorporation = businessInformationFormFields.dateOfIncorporation;
      this.businessInformation.businessEntity.gstin = businessInformationFormFields.gstNumber;
      this.businessInformation.businessEntity.pan = businessInformationFormFields.pan;
      this.businessInformation.businessEntity.form6061Flag = businessInformationFormFields.form;
      this.businessInformation.entityProfile.primaryPhone = businessInformationFormFields.mobileNumber;
      this.businessInformation.entityProfile.landlinePhone = businessInformationFormFields.landLineNumber;
      this.businessInformation.entityProfile.alternatePhone = businessInformationFormFields.alternateNumber;
      this.businessInformation.entityProfile.emailId = businessInformationFormFields.email;
      this.businessInformation.entityProfile.industryType = businessInformationFormFields.industryType;
      this.businessInformation.entityProfile.constitution = businessInformationFormFields.constitution;
      this.businessInformation.entityProfile.turnoverRange = businessInformationFormFields.annualTurnover;
      //this.businessInformation.entityProfile.yearsInBusiness = businessInformationFormFields.yearsInBusiness;
      this.businessInformation.entityProfile.employeeCount = businessInformationFormFields.employee;
      this.businessInformation.entityProfile.workhoursPerWeek = businessInformationFormFields.worksHoursPerWeek;
      this.businessInformation.entityProfile.businessNature = businessInformationFormFields.natureOfBusiness;
      this.businessInformation.entityProfile.businessNatureOther = businessInformationFormFields.naoutrOfBusinessIfOthers;
      this.businessInformation.entityProfile.businessStructure = businessInformationFormFields.businessStructure;
      this.businessInformation.entityProfile.businessWithoutOwner = businessInformationFormFields.businessContiniousInOwnerAbsence;
      this.applicationService.saveBusinessInformation(this.businessInformation,
        this.application.uid, this.business.uid).toPromise().then(
          (_success) => {
            this.fetchbusinessInformation();
            this._snackbar.open("Business information updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
          }, (failure) => {
            let errormesg =[]
            errormesg.push("Business information update failed")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
          }
        );
    }
  }

  toggle(target: any) {
    const fieldValue = target.currentTarget.innerText;
    if (fieldValue === 'PAN') {
      this.businessInformationForm.controls['pan'].enable();
    } else {
      /* this.businessInformationForm.controls['pan'].reset(); */
      this.businessInformationForm.controls['pan'].disable();
    }
  }

  onChangeBusinessNature(businessNature) {
    if (businessNature && businessNature == 'OTH') {
      this.businessInformationForm.get('naoutrOfBusinessIfOthers').enable()
    } else {
      this.businessInformationForm.get('naoutrOfBusinessIfOthers').disable()
      this.businessInformationForm.get('naoutrOfBusinessIfOthers').reset()
    }
  }

  enableDisableForm() {
    this.isFormDisabled = false;
    this.onChangeBusinessNature(null);
  }

  cancelForm() {
    this.buildBusinessInformationForm(this.businessInformation);
    this.isFormDisabled = true;
  }

  //this function will apply validators to form group
  applyValidatorsToBusinessInformationForm() {
    this.businessInformationForm = this.formBuilder.group({
      businessName: '',
      dateOfIncorporation: '',
      gstNumber: '',
      pan: '',
      form: '',
      mobileNumber: '',
      landLineNumber: '',
      alternateNumber: '',
      email: '',
      industryType: '',
      constitution: '',
      annualTurnover: '',
      yearsInBusiness: null,
      employee: '',
      worksHoursPerWeek: '',
      natureOfBusiness: '',
      naoutrOfBusinessIfOthers: '',
      businessStructure: '',
      businessContiniousInOwnerAbsence: '',
      panOrForm:false
    });
    this.validationService.applyValidationRules(this.businessInformationForm, "BusinessInformation").then((controlValidators) => {
      this.fetchbusinessInformation();
    }).catch(() => {

    })
  }
  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}
