import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ITEMS_PER_PAGE, Principal} from '../shared';
import {EventManager, JhiLanguageService, AlertService, ParseLinks} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-maker-checker',
    templateUrl: './loan-od-accounts-maker-checker.component.html',
})
export class LoanOdAccountsMakerCheckerComponent implements OnInit, OnDestroy {

    eventSubscriber: Subscription;
    currentAccount: any;
    itemsPerPage: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    links: any;
    totalItems: any;
    queryCount: any;
    actionDtos: any[];
    page: any;
    routeData: any;
    currentSearch: {
        status: any,
        actionType: string
    };
    currentSearchString: any;
    statusList: any[];
    showApproveReject: boolean;
    searchString: string;

    constructor(private principal: Principal,
                private eventManager: EventManager,
                private loanOdAccountsService: LoanOdAccountsService,
                private alertService: AlertService,
                private parseLinks: ParseLinks,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private jhiLanguageService: JhiLanguageService,
                ) {
        this.actionDtos = [];
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            this.page = data['pagingParams'].page;
            this.previousPage = data['pagingParams'].page;
            this.reverse = data['pagingParams'].ascending;
            this.predicate = data['pagingParams'].predicate;
            this.itemsPerPage = data['pagingParams'].size;
            this.searchString = data['pagingParams'].search;
        });
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        const searchParams = new URLSearchParams(this.searchString);
        this.currentSearch = {
            status: searchParams.get('status') ? searchParams.get('status') : '',
            actionType: searchParams.get('actionType') ? searchParams.get('actionType') : ''
        };
        this.setCurrentSearchString();
    }

    private onSuccess(data, headers) {
        this.links = this.parseLinks.parse(headers.get('link'));
        this.totalItems = headers.get('X-Total-Count');
        this.queryCount = this.totalItems;
        this.itemsPerPage = this.itemsPerPage > 50 ? 50 : this.itemsPerPage;
        this.actionDtos = data.results;
    }

    private onError(error) {
        this.alertService.error('encoreclientApp.loanOdAccounts.error');
    }

    ngOnInit() {
        this.loadAll();
        this.registerChangeInMakerChecker();
        this.loanOdAccountsService.getStatusList().subscribe(
            (res) => {
                this.updateStatusList(res.json());
        });
    }

    private updateStatusList(data) {
        this.statusList = data;
    }

    loadAll() {
        this.loanOdAccountsService.makerCheckerQuery({
            page: this.page - 1,
            size: this.itemsPerPage,
            query: this.currentSearch,
            sort: this.sort()
        }).subscribe(
            (res) => this.onSuccess(res.json(), res.headers),
            (res) => this.onError(res)
        );
        if (this.currentSearch.status !== 'APPROVED' && this.currentSearch.status !== 'REJECTED') {
            this.showApproveReject = true;
        }else {
            this.showApproveReject = false;
        }
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    transition() {
        this.router.navigate(['/loans/loan-od-accounts/makerChecker'],
            {
                queryParams:
                    {
                        search: this.currentSearchString,
                        page: this.page,
                        size: this.itemsPerPage,
                        sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
                    }
            });
        this.loadAll();
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInMakerChecker() {
        this.eventSubscriber = this.eventManager.subscribe('transactionsMakerCheckerListModification', (response) => this.loadAll());
    }

    goToDiff(actionDto) {
        if (actionDto.eventAction === 'Create') {
            this.alertService.error('encoreclientApp.loanOdAccounts.diffRouteError');
        } else {
            this.router.navigate(['/loans/loan-od-accounts/makerChecker/difference', actionDto.id]);
        }
    }

    search() {
        this.page = 1;
        this.setCurrentSearchString();
        this.transition();
    }

    clear() {
        this.actionDtos = [];
        this.page = 1;
        this.currentSearch = {
            status: '',
            actionType: ''
        }
        this.currentSearchString = '';
        this.transition();
    }
    setCurrentSearchString() {
        this.currentSearchString = '?';
        if (this.currentSearch) {
            for (const key of Object.keys(this.currentSearch)) {
                if (typeof(this.currentSearch[key]) === 'object') {
                    let month: any = 0;
                    let day: any = 0;

                    if (this.currentSearch[key].month < 10) {
                        month = '0' + this.currentSearch[key].month;
                    } else {
                        month = this.currentSearch[key].month;
                    }

                    if (this.currentSearch[key].day < 10) {
                        day = '0' + this.currentSearch[key].day;
                    } else {
                        day = this.currentSearch[key].day;
                    }

                    this.currentSearch[key] = this.currentSearch[key].year + '-' + month + '-' + day;
                }

                if (this.currentSearch[key] !== '' && this.currentSearch[key] !== undefined) {
                    this.currentSearchString += key + '=' + this.currentSearch[key] + '&';
                }
            }
        }
    }

}
