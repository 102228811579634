import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';

@Component({
    selector: 'jhi-logout',
    template: `
    <div>
        loggin-out
    </div>
  `,
  styles: []
})
export class EncoreclientLogoutComponent implements OnInit {

    constructor(
        private loginService: LoginService,
        private router: Router,
    ) {

    }

    ngOnInit() {
        this.loginService.logout();
        this.router.navigate(['login']);
    }

}
