import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ProductSet } from './product-set.model';


@Injectable({
  providedIn: 'root'
})
export class ProductSetService {

  private API = 'api/master/productsets';

  constructor(private http: HttpClient) { }

  findProductSets(req: any): Observable<HttpResponse<ProductSet[]>> {
    let params = new HttpParams();
    params = params.set('page', req.page);
    params = params.set('size', req.size);
    params = params.set('sort', req.sort);
    return this.http.get<ProductSet[]>(this.API+ '/findAll', {
      params, observe: 'response'
    });
  }

  saveProductSet(productSet: ProductSet): Observable<HttpResponse<ProductSet>> {
    const copy: ProductSet = Object.assign({}, productSet);
    if (copy.id && copy.id != null) {
      return this.http.put<ProductSet>(this.API, copy, { observe: 'response' });
    } else {
      return this.http.post<ProductSet>(this.API, copy, { observe: 'response' });
    }
  }

  getProductSet(id: number) {
    return this.http.get<ProductSet>(this.API + '/' + id);
  }

  getAllProductsets() {
    return this.http.get<ProductSet>(this.API);
  }

}