import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../admin/user/user.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UserService } from '../../admin/user/user.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit, OnDestroy {

  user: User;
  key: string;

  constructor(private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.user = data.user;
        this.key = this.activatedRoute.snapshot.queryParams['key'];
      });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  onSubmit() {
    this.userService.activateUser(this.key).pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => this.onSubmitSuccess(response),
        (response) => this.onSubmitError(response));
  }

  private onSubmitSuccess(response) {
    this.snackBar.open(`User registered successfully`, 'Close');
    // redirect to login page;
    this.router.navigate([ 'signin' ]);
  }

  private onSubmitError(response) {
    if (response.error.status === 400) {
       this.snackBar.open(`${response.error.title}`, 'Close');
    } else {
      this.snackBar.open(`Unable to activate you account. Please contact admin`, 'Close');
    }
  }

}
