import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleFileUploadComponent } from './file-examples/single-file-upload/single-file-upload.component';
import { Routes, RouterModule } from '@angular/router';
import { MaterialLibModule } from '@app/material-lib.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MultipleFileUploadComponent } from './file-examples/multiple-file-upload/multiple-file-upload.component';
import { FileDownloadComponent } from './file-examples/file-download/file-download.component';
import { ListExampleComponent } from './crud-examples/list-example/list-example.component';
import { CreateExampleComponent } from './crud-examples/create-example/create-example.component';
import { ModelJsonComponent } from './model-json/model-json.component';
import { ModelDetailsComponent } from './model-json/model-details/model-details.component';

const routes: Routes = [
  {
    path: 'examples',
    children: [
      { path: 'singleFileUpload', component: SingleFileUploadComponent },
      { path: 'multipleFileUpload', component: MultipleFileUploadComponent },
      { path: 'downloadFile', component: FileDownloadComponent },
      { path: 'listExample', component: ListExampleComponent },
      { path: 'createExample', component: CreateExampleComponent },
      { path: 'modelJson', component: ModelJsonComponent },
      { path: 'modelDetails', component: ModelDetailsComponent}
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    MaterialLibModule,
    RouterModule.forChild(routes),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    SingleFileUploadComponent,
    MultipleFileUploadComponent,
    FileDownloadComponent,
    ListExampleComponent,
    CreateExampleComponent,
    ModelJsonComponent,
    ModelDetailsComponent
  ]
})
export class ExamplesModule { }
