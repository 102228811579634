export class ReferenceBasic {
    constructor (
        public name ?: string,
        public relationshipType ?: string,
        public addressLine1 ?: string,
        public addressLine2 ?: string,
        public addressLine3 ?: string,
        public pincode ?: string,
        public primaryPhone ?: string,
        public alternatePhone ?: string,
        public landlinePhone ?: string,
        public emailId ?: string,
        public partyLinkUid ?: string,
        public partyLinkVersion ?: number,
        public personUid ?: string,
        public personVersion ?: number,
        public personProfileUid ?: string,
        public personProfileVersion ?: number,
        public addressUid ?: string,
        public addressVersion ?: number,
        public deleteBtnDisabled?: boolean//added for client side use this paramenter will not come from server
    ) {}
}
