import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable} from 'rxjs';
import { BranchService } from './branch.service';
import { Branch } from './branch.model';

@Injectable({
    providedIn: 'root'
})
export class BranchResolverService implements Resolve<Branch> {
    constructor(private branchService: BranchService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Branch> | Observable<never> {
        return this.branchService.getBranch(route.paramMap.get('uid'));
    }
}
