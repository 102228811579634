import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { IgFileService } from '@ig-core/form/igFile.service';

@Component({
  selector: 'app-single-file-upload',
  templateUrl: './single-file-upload.component.html',
  styleUrls: ['./single-file-upload.component.css']
})
export class SingleFileUploadComponent implements OnInit, OnDestroy {

  public singleFileForm: FormGroup;
  public singleFile: File;
  public responseData: Array<any> = [];
  public fileName: string;
  public formError: FormErrorModel;
  public formErrors = {
    singleFile: '',
    fileName: ''
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService,
    private fileManagementService: IgFileService) {
  }

  ngOnInit() {
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
  }

  ngOnDestroy(): void {
  }

  buildForm() {
    this.singleFileForm = this.formBuilder.group({
      singleFile: ['', ],
      fileName: [ '', [ Validators.required]]
    });

    this.singleFileForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.singleFileForm, this.formErrors, false);
    });
  }

  onFileChanged(event) {
    this.singleFile = event.target.files[0];
    this.fileName = this.singleFile.name;
  }

  singleFIleUpload() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.singleFileForm);
    if (this.singleFileForm.valid) {
      this.fileManagementService.uploadFIle(this.singleFile).pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => this.onSubmitSuccess(response),
        (response) => this.onSubmitError(response));
    } else {
      this.formErrors = this.igFormService.validateForm(this.singleFileForm, this.formErrors, false);
      return false;
    }
  }

  private onSubmitSuccess(response) {
    this.snackBar.open(`File Uploaded successfully`, 'Close');
    this.ngOnInit();
  }

  private onSubmitError(response) {
    this.snackBar.open(`Unable to Upload files...Please try again`, 'Close');
  }

}
