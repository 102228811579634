import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eng-admin',
  templateUrl: './admin.template.html',
  styleUrls: ['./admin.styles.scss'],
})
export class AdminComponent implements OnInit {

  selectedMenu: string = '';
  
  constructor() {
  }
  
  ngOnInit(): void {
  }

  updateSelectedMenu(selectedMenu) {
    this.selectedMenu = selectedMenu;
  }

}