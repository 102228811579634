import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {FeeDto} from './loan-od-accounts-fee-dto.model';
import {LoanOdAccountsService} from './loan-od-accounts.service';
import {AccountMandateDto} from './account-mandate-dto.model';

@Injectable()
export class LoanOdAccountsAmendpenalinterestEntryResolve implements Resolve<any> {

    constructor(private loanOdAccountsService: LoanOdAccountsService) {}

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId')) {
            return this.loanOdAccountsService.amendPenalInterestRate(route.paramMap.get('accountId'));
        } else {
            return new AccountMandateDto();
        }
    }
}
