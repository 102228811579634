import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Route } from '@angular/router';
import { MaterialLibModule } from '@app/material-lib.module';
import { AllPipesModule } from '../utils/pipes.module'
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { ReportCriteriaComponent } from './criteria/report-criteria.component';
import { ReportsComponent } from './reports.component';

export const REPORT_ROUTES: Route = {
  path: 'reports',
  canActivate: [AuthenticatedUserService],
  children: [
    {
      path: '',
      component: ReportsComponent
    },
    {
      path: ':reportLinkUid',
      component: ReportCriteriaComponent
    }
  ]
};

@NgModule({
    imports: [
      //ReportsRoutingModule,
      BrowserModule,
      HttpClientModule,
      MaterialLibModule,
      FormsModule,
      RouterModule,
      FlexLayoutModule,
      ReactiveFormsModule,
      AllPipesModule
    ],
    declarations: [ReportsComponent, ReportCriteriaComponent],
    exports:[ReportsComponent, ReportCriteriaComponent]
})
export class ReportsModule { }