import { NgModule } from '@angular/core';
import {  RouterModule, Routes } from '@angular/router';
import { ReportsComponent } from '@app/reports/reports.component';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { ReportCriteriaComponent } from '@app/reports/criteria/report-criteria.component';
import { DataExportComponent } from './dataExport/data-export.component';
import { ReportDetailsComponent } from './reports-details.component';
import { SamunnatiExport } from './dataExport/samunnatiExport/samunnati-export.component';
import { DataPortMEnuRouteGuardService } from './reports-menu-route-guard.service';
const routes: Routes = [
  {
    path: 'dataport',
    canActivate: [AuthenticatedUserService, DataPortMEnuRouteGuardService],
    component: ReportDetailsComponent,
    children: [
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'reports/:reportLinkUid',
        component: ReportCriteriaComponent
      },
      {
        path: 'dataexport',
        component: DataExportComponent,
      },
      {
        path: 'dataexport/export',
        component: SamunnatiExport,
      }
    ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportMenuRoutingModule { }