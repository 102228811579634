import { RowValueDto } from "@app/applications/applications.model";

export class BureauResponse {
    constructor(
	public  score: string,
	public  writeOffs: number,
	public 	past30DaysEnquries: string,
	public 	past12MonthsEnquiries: string,
	public 	past24MonthsEnquries: string,
	public 	message: string,
	public  hitCode: string,
	public  status: string,
	public  reportNumber: string,
	public  totalEnquiry: number,
	public  totalPastDueAmount: number,
	public  suitFiledAccountCount: number,
	public  npaAccountCount: number,
	public  settledAccountCount: number,
	public 	bureauDetails: RowValueDto,
	public	verified: boolean,
	public  bureaureportuid: string,
	public  validationDetailDTO: validationDetailDTO,
    ) { }
}

export class validationDetailDTO {
    constructor(
	public  acceptanceCriteriaMet: boolean,
	public  inPermissibleTolerance: boolean,
	public  validationMessages: string[],
    ) { }
}