import {FeeDto} from './loan-od-accounts-fee-dto.model';
import {LoanOdDisbursementDto} from './loan-od-disbursement-dto.model';
import {Money} from './money';
import { Tenure } from './tenure';

export class LoanOdAmendment {
    constructor(
        public id?: number,
        public version?: number,
        public amendmentType?: string,
        public accountId?: string,
        public sequenceNum?: number,
        public transactionId?: string,
        public amount?: Money,
        public tenure?: Tenure,
        public oldInstallmentAmount?: Money,
        public valueDate?: any,
        public transactionDate?: any,
        public newDate?: any,
        public nextDemandScheduledDate?: any,
        public nextDemandRunDate?: any,
        public maturityDate?: any,
        public description?: string,
        public userId?: string,
        public oldTenure?: number,
        public loanAmount?: Money,
        public oldLoanAmount?: Money,
        public oldNextDemandScheduledDate?: any,
        public oldNextDemandRunDate?: any,
        public oldMaturityDate?: any,
        public oldReferenceRepaymentDate?: any,
        public instrument?: string,
        public transactionLotId?: string,
        public reference?: string,
        public tenureUnit?: string,
        public tenureMagnitude?: Number
    ) {}
}
