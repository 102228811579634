import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialLibModule} from '../material-lib.module';
import {LayoutModule} from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReportsModule } from '@app/reports/reports.module';
import { ReportDetailsComponent } from './reports-details.component';
import { DataExportComponent } from './dataExport/data-export.component';
import { ReportMenuRoutingModule } from './reports-menu.routing.module';
import { SamunnatiExport } from './dataExport/samunnatiExport/samunnati-export.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ReportDetailsComponent,DataExportComponent,SamunnatiExport],
  imports: [
    CommonModule,
    MaterialLibModule,
    LayoutModule,
    FlexLayoutModule,
    ReportsModule,
    ReportMenuRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class ReportsMenuModule { }
