import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable} from 'rxjs';
import { OrganizationStructure } from './organization-structure.model';
import { OrganizationStructureService } from './organization-struture.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationStructureResolverService implements Resolve<OrganizationStructure> {
    constructor(private organizationStructureService: OrganizationStructureService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):any {
        return this.organizationStructureService.getOrgStructure(route.paramMap.get('uid'));
    }
}