import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { BusinessIncomeSummary, BusinessIncomeType, BusinessIncomeDetail, BusinessIncome } from '../financials.model';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ValidationService } from '../../../application-validators/validation.service'
@Component({
  selector: 'eng-purchase-bills',
  templateUrl: 'purchase-bills.template.html',
  styleUrls: ['../../application-details.styles.scss', 'purchase-bills.styles.css']
})
export class PurchaseBillsComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;
  purchaseBills: BusinessIncomeSummary;
  purchaseBillsWithGst: BusinessIncomeType = new BusinessIncomeType();
  purchaseBillsWithoutGst: BusinessIncomeType = new BusinessIncomeType();
  recentMonths: string[];
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  purchaseBillsForm: FormGroup;

  showForm: boolean;
  isFormDisabled: boolean;
  allowAccess: Boolean;
  cfaFinalized: boolean;
  menuItemAllowAccess:boolean;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private validationService: ValidationService) {
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.purchaseBillsForm = this.formBuilder.group({
      purchaseSummaryForm: this.formBuilder.group({}),
      monthlyPurchaseForm: this.formBuilder.array([]),
      salesMargin: ''
    })
    this.isFormDisabled = true;
    this.getCfaStatus()
    this.fetchPurchaseBills();
    this.getMenuItemAccess();

  }

  fetchPurchaseBills() {
    this.showForm = false;
    this.applicationService.getBusinessFinancials(this.application.uid, 'purchasebill')
      .subscribe((response: any) => {
        if (response) {
          this.purchaseBills = response;
          this.showForm = (this.purchaseBills.businessIncome.length > 0
            && this.purchaseBills.businessIncomeDetail.length > 0);
          if (this.showForm) this.getSeparateWithAndWithoutGstPurchaseBillsSummary();
          if (this.showForm) this.fetchAssessmentYearAndMonths();
        }
      });
  }

  getSeparateWithAndWithoutGstPurchaseBillsSummary() {
    this.purchaseBillsWithGst.businessIncome =
      this.purchaseBills.businessIncome.find(
        businessIncome => businessIncome.incomeDocumentType === 'PURCH_GST');
    this.purchaseBillsWithGst.businessIncomeDetail =
      this.purchaseBills.businessIncomeDetail.find(
        businessIncomeDetail => businessIncomeDetail.incomeDocumentType === 'PURCH_GST');
    this.purchaseBillsWithoutGst.businessIncome =
      this.purchaseBills.businessIncome.find(
        businessIncome => businessIncome.incomeDocumentType === 'PURCH_WO_GST');
    this.purchaseBillsWithoutGst.businessIncomeDetail =
      this.purchaseBills.businessIncomeDetail.find(
        businessIncomeDetail => businessIncomeDetail.incomeDocumentType === 'PURCH_WO_GST');
    this.showForm = (this.purchaseBillsWithGst.businessIncome !== undefined &&
      this.purchaseBillsWithGst.businessIncomeDetail !== undefined &&
      this.purchaseBillsWithoutGst.businessIncome !== undefined &&
      this.purchaseBillsWithoutGst.businessIncomeDetail !== undefined);
  }

  fetchAssessmentYearAndMonths(purchaseBillsWithGst?: BusinessIncomeType,
    purchaseBillsWithoutGst?: BusinessIncomeType) {
    if (!purchaseBillsWithGst) purchaseBillsWithGst = this.purchaseBillsWithGst;
    if (!purchaseBillsWithoutGst) purchaseBillsWithoutGst = this.purchaseBillsWithoutGst;
    this.showForm = false;
    this.applicationService.getAssessmentYearAndMonths(this.application.uid)
      .subscribe((response: any) => {
        if (response) {
          this.showForm = true;
          this.recentMonths = response.recentMonths;
          this.buildPurchaseBillsForm(purchaseBillsWithGst, purchaseBillsWithoutGst);
        }
      });
  }

  addNewPurchaseBillsSummary() {
    this.isFormDisabled = false;

    let newPurchaseBillsWithGst = new BusinessIncomeType();
    newPurchaseBillsWithGst.businessIncome = new BusinessIncome();
    newPurchaseBillsWithGst.businessIncomeDetail = new BusinessIncomeDetail();

    let newPurchaseBillsWithoutGst = new BusinessIncomeType();
    newPurchaseBillsWithoutGst.businessIncome = new BusinessIncome();
    newPurchaseBillsWithoutGst.businessIncomeDetail = new BusinessIncomeDetail();

    this.fetchAssessmentYearAndMonths(newPurchaseBillsWithGst, newPurchaseBillsWithoutGst);
  }

  buildPurchaseBillsForm(purchaseBillsWithGst?: BusinessIncomeType,
    purchaseBillsWithoutGst?: BusinessIncomeType) {
    if (this.showForm) {
      this.purchaseBillsForm.controls.salesMargin.patchValue(purchaseBillsWithGst
        .businessIncome.salesMargin ? purchaseBillsWithGst.businessIncome
          .salesMargin.toFixed(0) : undefined);
      this.purchaseBillsForm.controls.purchaseSummaryForm = this.formBuilder.group({
        averagePurchaseWithGst: purchaseBillsWithGst.businessIncome.monthlySales,
        averagePurchaseWithoutGst: purchaseBillsWithoutGst.businessIncome.monthlySales,
        eligibleMonthlyTurnoverWithGst: purchaseBillsWithGst.businessIncome.eligibleMonthlyTurnover,
        eligibleMonthlyTurnoverWithoutGst: purchaseBillsWithoutGst.businessIncome.eligibleMonthlyTurnover
      });

      this.purchaseBillsForm.controls.monthlyPurchaseForm = this.formBuilder.array([]);
      const array = this.purchaseBillsForm.controls.monthlyPurchaseForm as FormArray;
      this.recentMonths.forEach((month, index) => {
        const group = new FormGroup({
          mWithGst: new FormControl(purchaseBillsWithGst
            .businessIncomeDetail['m' + (index + 1) + 'Amount']),
          mWithoutGst: new FormControl(purchaseBillsWithoutGst
            .businessIncomeDetail['m' + (index + 1) + 'Amount']),
        });
        this.applyValidatorsToPurchaseBill(group)
        array.push(group);
      });
      this.purchaseBillsWithGst = purchaseBillsWithGst;
      this.purchaseBillsWithoutGst = purchaseBillsWithoutGst;
      this.applyValidatorsToPurchaseBill(this.purchaseBillsForm)
    }
  }

  computeSummary() {
    this.validationService.markFormGroupTouched(this.purchaseBillsForm)
    if (this.purchaseBillsForm.valid) {
      let monthlyPurchaseBills: BusinessIncomeDetail[] = [];
      let monthlyPurchaseBillsWithoutGst = this.purchaseBillsWithoutGst.businessIncomeDetail;
      let monthlyPurchaseBillsWithGst = this.purchaseBillsWithGst.businessIncomeDetail;
      monthlyPurchaseBillsWithGst.salesMargin = this.purchaseBillsForm.value.salesMargin;

      this.recentMonths.forEach((month, index) => {
        monthlyPurchaseBillsWithGst['m' + (index + 1) + 'Amount'] =
          this.purchaseBillsForm.get('monthlyPurchaseForm')['controls'][index].value.mWithGst
        monthlyPurchaseBillsWithoutGst['m' + (index + 1) + 'Amount'] =
          this.purchaseBillsForm.get('monthlyPurchaseForm')['controls'][index].value.mWithoutGst
      });
      monthlyPurchaseBillsWithGst.incomeDocumentType ='PURCH_GST';
      monthlyPurchaseBillsWithoutGst.incomeDocumentType = 'PURCH_WO_GST';
      monthlyPurchaseBills.push(monthlyPurchaseBillsWithGst);
      monthlyPurchaseBills.push(monthlyPurchaseBillsWithoutGst);

      this.applicationService.getCalculatedBusinessIncomeSummary(this.application.uid, "purchasebill",
        monthlyPurchaseBills).subscribe(response => {
          if (response && response.body.length > 0) {
            this.updatePurchaseBillsSummary(response.body);
          }
        });
    }
  }

  updatePurchaseBillsSummary(purchasebillsSummary) {
    let purchaseBillsSummaryWithGst = purchasebillsSummary.find(
      businessIncome => businessIncome.incomeDocumentType === 'PURCH_GST');
    let purchaseBillsSummaryWithoutGst = purchasebillsSummary.find(
      businessIncome => businessIncome.incomeDocumentType === 'PURCH_WO_GST');
    this.purchaseBillsForm.patchValue({
      purchaseSummaryForm: {
        averagePurchaseWithGst: purchaseBillsSummaryWithGst.monthlySales,
        averagePurchaseWithoutGst: purchaseBillsSummaryWithoutGst.monthlySales,
        eligibleMonthlyTurnoverWithGst: purchaseBillsSummaryWithGst.eligibleMonthlyTurnover,
        eligibleMonthlyTurnoverWithoutGst: purchaseBillsSummaryWithoutGst.eligibleMonthlyTurnover
      }
    });
  }


  savePurchaseBillsDetails() {
    this.validationService.markFormGroupTouched(this.purchaseBillsForm)
    if (!this.isFormDisabled && this.purchaseBillsForm.valid) {
      if (!this.purchaseBillsWithGst.businessIncome.uid &&
        !this.purchaseBillsWithGst.businessIncomeDetail.uid) {
        this.purchaseBillsWithGst.businessIncome.category = 'purchasebill';
        this.purchaseBillsWithGst.businessIncome.incomeDocumentType = 'PURCH_GST';
        this.purchaseBillsWithGst.businessIncome.frequency = 'M';
        this.purchaseBillsWithGst.businessIncome.contextType = 'application';
        this.purchaseBillsWithGst.businessIncome.contextUid = this.application.uid;

        this.purchaseBillsWithGst.businessIncomeDetail.category = 'purchasebill';
        this.purchaseBillsWithGst.businessIncomeDetail.incomeDocumentType = 'PURCH_GST';
        this.purchaseBillsWithGst.businessIncomeDetail.frequency = 'M';
        this.purchaseBillsWithGst.businessIncomeDetail.contextType = 'application';
        this.purchaseBillsWithGst.businessIncomeDetail.contextUid = this.application.uid;
      }

      if (!this.purchaseBillsWithoutGst.businessIncome.uid &&
        !this.purchaseBillsWithoutGst.businessIncomeDetail.uid) {
        this.purchaseBillsWithoutGst.businessIncome.category = 'purchasebill';
        this.purchaseBillsWithoutGst.businessIncome.incomeDocumentType = 'PURCH_WO_GST';
        this.purchaseBillsWithoutGst.businessIncome.frequency = 'M';
        this.purchaseBillsWithoutGst.businessIncome.contextType = 'application';
        this.purchaseBillsWithoutGst.businessIncome.contextUid = this.application.uid;

        this.purchaseBillsWithoutGst.businessIncomeDetail.category = 'purchasebill';
        this.purchaseBillsWithoutGst.businessIncomeDetail.incomeDocumentType = 'PURCH_WO_GST';
        this.purchaseBillsWithoutGst.businessIncomeDetail.frequency = 'M';
        this.purchaseBillsWithoutGst.businessIncomeDetail.contextType = 'application';
        this.purchaseBillsWithoutGst.businessIncomeDetail.contextUid = this.application.uid;
      }

      let purchaseBillsFormFields = this.purchaseBillsForm.value;
      this.purchaseBillsWithGst.businessIncome.monthlySales =
        purchaseBillsFormFields.purchaseSummaryForm.averagePurchaseWithGst;
      this.purchaseBillsWithGst.businessIncome.eligibleMonthlyTurnover =
        purchaseBillsFormFields.purchaseSummaryForm.eligibleMonthlyTurnoverWithGst;
      this.purchaseBillsWithGst.businessIncomeDetail.salesMargin =
        purchaseBillsFormFields.salesMargin;
      this.purchaseBillsWithoutGst.businessIncome.monthlySales =
        purchaseBillsFormFields.purchaseSummaryForm.averagePurchaseWithoutGst;
      this.purchaseBillsWithoutGst.businessIncome.eligibleMonthlyTurnover =
        purchaseBillsFormFields.purchaseSummaryForm.eligibleMonthlyTurnoverWithoutGst;
      this.purchaseBillsWithoutGst.businessIncomeDetail.salesMargin =
        purchaseBillsFormFields.salesMargin;

      this.recentMonths.forEach((month, index) => {
        this.purchaseBillsWithGst.businessIncomeDetail['m' + (index + 1) + 'Amount'] =
          this.purchaseBillsForm.get('monthlyPurchaseForm')['controls'][index].value.mWithGst
        this.purchaseBillsWithoutGst.businessIncomeDetail['m' + (index + 1) + 'Amount'] =
          this.purchaseBillsForm.get('monthlyPurchaseForm')['controls'][index].value.mWithoutGst
      });

      this.purchaseBills.businessIncome = [];
      this.purchaseBills.businessIncomeDetail = [];

      this.purchaseBills.businessIncome.push(this.purchaseBillsWithGst.businessIncome);
      this.purchaseBills.businessIncomeDetail.push(this.purchaseBillsWithGst.businessIncomeDetail);
      this.purchaseBills.businessIncome.push(this.purchaseBillsWithoutGst.businessIncome);
      this.purchaseBills.businessIncomeDetail.push(this.purchaseBillsWithoutGst.businessIncomeDetail);

      this.applicationService.saveBusinessIncomeSummary(this.application.uid, "purchasebill",
        this.purchaseBills).toPromise().then(
          (_success) => {
            this._snackbar.open("Purchase bills details updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.fetchPurchaseBills();
          }, (failure) => {
            let errormesg =[]
            errormesg.push("Purchase bills details update failed")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
          });
    }
  }

  enableDisableForm() {
    this.isFormDisabled = !this.isFormDisabled;
  }

  cancelForm() {
    this.purchaseBillsForm = this.formBuilder.group({
      purchaseSummaryForm: this.formBuilder.group({}),
      monthlyPurchaseForm: this.formBuilder.array([]),
      salesMargin: ''
    });
    this.purchaseBillsWithGst.businessIncome = this.purchaseBills
      .businessIncome[0];
    this.purchaseBillsWithGst.businessIncomeDetail = this.purchaseBills
      .businessIncomeDetail[0];

    this.purchaseBillsWithoutGst.businessIncome = this.purchaseBills
      .businessIncome[0];
    this.purchaseBillsWithoutGst.businessIncomeDetail = this.purchaseBills
      .businessIncomeDetail[0];
    if (this.purchaseBillsWithGst.businessIncome == undefined) this.purchaseBillsWithGst.businessIncome = [] as any
    if (this.purchaseBillsWithGst.businessIncomeDetail == undefined) this.purchaseBillsWithGst.businessIncomeDetail = [] as any
    if (this.purchaseBillsWithoutGst.businessIncome == undefined) this.purchaseBillsWithoutGst.businessIncome = [] as any
    if (this.purchaseBillsWithoutGst.businessIncomeDetail == undefined) this.purchaseBillsWithoutGst.businessIncomeDetail = [] as any
    this.buildPurchaseBillsForm(this.purchaseBillsWithGst, this.purchaseBillsWithoutGst);
    this.isFormDisabled = true;
  }

  applyValidatorsToPurchaseBill(group) {
    this.validationService.applyValidationRules(group, "PurchaseBill")
      .then((controlValidators) => {
      }).catch(() => { });
  }

  //this function will call a api to get CFA Finalized or not status
  getCfaStatus() {
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response => {
      this.cfaFinalized = response.body.cfaFinalized
      if (this.cfaFinalized) {
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close", {
          duration: 5000,
        });
      }
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}