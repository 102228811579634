
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { JhiLanguageService } from 'ng-jhipster';
import { Router } from '@angular/router';

import { LoanOdDisbursementComposite } from './loan-od-disbursement-composite.model';

@Component({
    selector: 'jhi-loan-od-accounts-disburse-result',
    templateUrl: './loan-od-accounts-disburse-result.component.html'
})
export class LoanOdAccountsDisburseResultComponent implements OnInit, OnDestroy {

    @Input() compositeDto: LoanOdDisbursementComposite;

    constructor(private jhiLanguageService: JhiLanguageService,
        private router: Router
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }

    Print() {
        let printPage, pWindow;
        printPage = document.getElementById('print-section1').innerHTML;
        pWindow = window.open();
        pWindow.document.open();
        pWindow.document.write(`
      <html>
        <head>
          <style>
          body{
              font-size:20px !important;
          }
          .fontb {
            font-weight: bold;
        }
        .fs18 {
          font-size: 18px;
          line-height: 28px;
      }
      
      .fs16 {
          font-size: 16px;
          line-height: 26px;
      }
      
      .fs14 {
          font-size: 14px;
          line-height: 21px;
      }
      
      .fs12 {
          font-size: 12px;
          line-height: 18px;
      }
          </style>
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS" crossorigin="anonymous">
        </head>
    <body onload="window.print();window.close()">${printPage}
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.6/umd/popper.min.js" integrity="sha384-wHAiFfRlMFy6i5SRaxvfOCifBUQy1xHdJ/yoi7FRNXMRBu5WHdZYu1hA6ZOblgut" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js" integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k" crossorigin="anonymous"></script>
    </body>
      </html>`
        );
        pWindow.document.close();
    }

    PrintElem(elem) {

    }

    view() {
        this.router.navigate(['/loan-od-accounts/view', this.compositeDto.disbursement.accountId]);
    }

}
