import { Component, OnInit,OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute ,Router} from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { LeadDetails ,UpdateLead} from '../leads.model';
import {LeadsService} from '../leads.service';
import { BranchService } from '@app/admin/branch/branch.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';

@Component({
  selector: 'eng-lead-quick-view',
  templateUrl: './lead-quick-view.component.html',
  styleUrls: ['./lead-quick-view.component.scss']
})
export class LeadQuickViewComponent implements OnInit ,OnDestroy{
@Input() leadsData:any;
leadDetails:LeadDetails;
public allLeadDetails:LeadDetails[]=[]
userBranches: NameValueDto[];
leadStatusTypes:any;
hotColor:string;
warmColor:string;
coldColor:string;
highlightRow:string;
@Input()
pageNumber:Number
@Output()
backLeadQuickView: EventEmitter<boolean> = new EventEmitter(); 
  constructor(
    private activatedRoute: ActivatedRoute, private route : Router,private leadsService:LeadsService,
    private branchService: BranchService,    private referenceCodeService: ReferenceCodeService,) {
}
  ngOnInit() {
    this.getRefCodes()
    this.getUserBranches()
    this.leadsData.forEach((element, index) => {
      this.leadsService.getLeadDetails(element.uid).subscribe(Response=>{
        this.allLeadDetails[index] = Response.body;
        this.leadDetails=this.allLeadDetails[0]
      })
    });
  }

  onClickLead(lead,index){
    this.highlightRow = index;  
  this.leadDetails= this.allLeadDetails[index]
  }

  getUserBranches() {
    this.branchService.getBranchesForUser().subscribe((response: any) => {
      if (response) {
        this.userBranches = response.body;
      }
    });
  }

  getRefCodes() { 
    this.referenceCodeService.getRefCodesForClassifier('opportunity_status')
        .subscribe((response: any) => {
      this.leadStatusTypes = response;
      this.leadStatusTypes.forEach(element => {
        if(element.code == "1H"){
          this.hotColor=element.field1;
         
        }
        if(element.code == "2W"){
        this.warmColor= element.field1;
        }
        if(element.code == "3C"){
        this.coldColor=element.field1;
        }
      });
    });
  }

  ngOnDestroy(): void {
  }

  back(){
    this.backLeadQuickView.emit(true);
  }
}
