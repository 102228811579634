import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import { MaterialLibModule } from '@app/material-lib.module';

import {EditableResolveService} from '@ig-core/resolves/editable-resolve.service';
import {NonEditableResolveService} from '@ig-core/resolves/non-editable-resolve.service';
import {AuthenticatedUserService} from '@ig-core/authentication/authenticated-user-service';

import { UserEditorComponent } from './user-editor.component';
import { UserDeleteDialogComponent } from './user-delete-dialog.component';
import { UserListComponent } from './user-list.component';
import {UserResolverService} from './user-resolve.service';

export const userRoute: Route = {
  path: 'user',
  canActivate: [AuthenticatedUserService],
  children: [
    {path: '', component: UserListComponent},
    {path: 'create', component: UserEditorComponent,
      resolve: {
        editable: EditableResolveService
      }},
    {path: 'update/:username', component: UserEditorComponent,
      resolve: {
        user: UserResolverService,
        editable: EditableResolveService
      }
    },
    {path: 'view/:username', component: UserEditorComponent,
      resolve: {
        user: UserResolverService,
        editable: NonEditableResolveService
      }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
   ],
  declarations: [ UserEditorComponent, UserDeleteDialogComponent], /* UserListComponent, */
  entryComponents: [UserDeleteDialogComponent]
})
export class UserModule {}
