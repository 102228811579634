import { Component, OnInit } from '@angular/core';
import {ConfigureService} from '../build-config/configure.service';
import { environment } from 'src/environments/environment';
import { IgLayoutService } from '@app/ig-layout/ig-layout.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title: string;
  public version: string = environment.VERSION;
  
  constructor(private configService: ConfigureService,
    private igLayoutService: IgLayoutService) { }
  
    ngOnInit() {
    this.title = 'ENGROW Webapp!';
    console.log(this.title);
  }

}
