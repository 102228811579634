import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, DateUtils, EventManager, JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { ApplicationDateService } from '../shared/date/application-date.service';
import { DateConverterService } from '../shared/date/date-converter.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdRepaymentDto } from './loan-od-repayment-dto.model';
import { TransactionSummaryDto } from './transaction-summary-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-security-deposit-payment',
    templateUrl: 'loan-od-accounts-security-deposit-payment.component.html'
})
export class LoanOdAccountsSecurityDepositPaymentComponent implements OnInit, OnDestroy {
    loanOdAccounts: LoanOdAccounts;
    transactionSummaryDto: TransactionSummaryDto = {};
    loanOdRepaymentDto: LoanOdRepaymentDto;
    proceedIsTrue: boolean;
    showTemplate: boolean;
    applicationDate: any;
    dateSubscription: Subscription;

    constructor(  private jhiLanguageService: JhiLanguageService,
                  private alertService: AlertService,
                  private loanODAccountsService: LoanOdAccountsService,
                  private eventManager: EventManager,
                  private route: ActivatedRoute,
                  private router: Router,
                  private dateConverter: DateConverterService,
                  private applicationDateService: ApplicationDateService,
                  private dateUtils: DateUtils
    ) {
        this.proceedIsTrue = false;
    }
    ngOnInit() {
        this.loanOdAccounts = this.route.snapshot.data['loanOdAccounts'];
        this.loanOdRepaymentDto = new LoanOdRepaymentDto();
        this.loanOdRepaymentDto.accountId = this.loanOdAccounts.accountId;
        this.loanOdRepaymentDto.amount = this.loanOdAccounts.totalSecurityDepositDue;
        this.dateSubscription = this.applicationDateService.getDate().subscribe(
            (date) => {
                this.applicationDate = date;
                this.loanOdRepaymentDto.valueDate = this.dateConverter.fromServerToNgbDatePicker(this.applicationDate);
                this.showTemplate = true;
            }
        );
    }
    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
    }

    previousState() {
        window.history.back();
    }

    clear() {
        this.loanOdRepaymentDto = new LoanOdRepaymentDto();
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    proceed(loanOdRepaymentDto) {
        this.loanOdRepaymentDto = loanOdRepaymentDto;
        this.loanOdRepaymentDto.valueDate = this.dateConverter.fromNgbDatePickerToDateObject(this.loanOdRepaymentDto.valueDate);
        if (this.loanOdRepaymentDto.reference) {
            this.loanOdRepaymentDto.reference = 'accountCode:' + this.loanOdRepaymentDto.reference;
        }else if (this.loanOdRepaymentDto.details) {
            this.loanOdRepaymentDto.reference = 'accountCode:' + this.loanOdRepaymentDto.details;
        }
        this.proceedIsTrue = true;
    }

    back() {
        this.loanOdRepaymentDto.valueDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.loanOdRepaymentDto.valueDate);
        if (this.loanOdRepaymentDto.reference) {
            this.loanOdRepaymentDto.reference = this.loanOdRepaymentDto.reference.substring(12);
        }
        this.proceedIsTrue = false;
    }

    executeError(error) {
        this.loanOdRepaymentDto.valueDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.loanOdRepaymentDto.valueDate);
        this.proceedIsTrue = false;
    }

}
