import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FileUploadComponent} from './file-upload.component'
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<FileUploadComponent>;
  public open(options) {
    this.dialogRef = this.dialog.open(FileUploadComponent, {    
         data: {
          uploadType:options.uploadType,
          folderUid:options.folderUid,
          applicationUid:options.applicationUid,
          files:options.files,
          folderType:options.folderType
         },disableClose: true
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }
    ));
  }
}
