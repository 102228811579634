import { Component, Input, OnInit } from '@angular/core';
import { applicationTrail } from '../decision.model';
import { LoanApplication } from '@app/applications/applications.model';
import { ApplicationService } from '../../../application.service';
@Component({
  selector: 'eng-application-trail',
  templateUrl: './application-trail.template.html',
  styleUrls: ['../../application-details.styles.scss', './application-trail.styles.scss']
})
export class ApplicationTrailComponent implements OnInit {
  @Input() application: LoanApplication
  applicationTrail: Array<applicationTrail>=[]
  constructor(private applicationService: ApplicationService) { }

  ngOnInit() {
    this.getApplicationTrails()
  }
  getApplicationTrails() {
    this.applicationService.getApplicationWorkflowTrails(this.application.uid).subscribe(response => {
      this.applicationTrail = response.body
    })
  }
}
