import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventManager, JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { ConfigureService } from '../build-config/configure.service';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { ReverseCompositeDto } from './reverse-composite-dto';
import { TransactionSummaryDto } from './transaction-summary-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-reverse-result',
    templateUrl: './loan-od-accounts-reverse-result.component.html'
})
export class LoanOdAccountsReverseResultComponent implements OnInit, OnDestroy {

    reverseCompositeDto: ReverseCompositeDto;
    transactionSummaryDto: any;
    private subscription: any;
    private eventSubscriber: Subscription;
    BASE_URL: string;

    constructor(private eventManager: EventManager,
                private jhiLanguageService: JhiLanguageService,
                private loanOdAccountsService: LoanOdAccountsService,
                private configService: ConfigureService
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.reverseCompositeDto = this.loanOdAccountsService.getReverseResult();
        this.transactionSummaryDto = this.loanOdAccountsService.getTransactionSummaryDto();
    }

    ngOnInit() {
        this.registerChangeInInternalSettings();
        this.BASE_URL = this.configService.buildUrl();
    }

    load(id) {
        this.loanOdAccountsService.find(id).subscribe((reverseCompositeDto) => {
            this.reverseCompositeDto = reverseCompositeDto;
        });
    }

    previousState() {
        window.history.back();
    }

    cancel() {
    }

    ngOnDestroy() {
    }

    registerChangeInInternalSettings() {
        this.eventSubscriber = this.eventManager.subscribe('loanOdAccountsListModification', (response) => this.load(this.reverseCompositeDto.id));
    }

}
