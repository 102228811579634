import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { ListSource } from '@ig-core/list/list-source';
import { ReferenceCodeService } from '../reference-code.service';
import { ReferenceCodeListDataSource } from './reference-code-list.data.source';
import { ReferenceCodeDeleteDialogComponent } from './reference-code-delete-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReferenceCodeSearchCriteria } from '../reference-code.model';

@Component({
    selector: 'app-reference-code',
    templateUrl: 'reference-code-list.component.html',
    styleUrls: ['../reference-code.component.scss']
})
export class ReferenceCodeListComponent implements OnInit, AfterViewInit, OnDestroy {

    pagingAndSortParams: PagingAndSortParams;
    dataSource: ReferenceCodeListDataSource;
    listSource: ListSource;

    displayedColumns = ['classifier', 'name', 'code', 'parentReferenceCode', 'status', 'action'];

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    classifiers: any;

    // search criteria
    refCodeSearchForm: FormGroup;
    referenceCodeSearchCriteria: ReferenceCodeSearchCriteria;
    pageNumbers:any;

    constructor(private referenceCodeService: ReferenceCodeService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private snackBar: MatSnackBar) { }

    ngOnInit() {
        this.refCodeSearchForm = this.formBuilder.group({
            classifierName: ''
        });
        this.dataSource = new ReferenceCodeListDataSource(this.referenceCodeService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);

        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
        .subscribe((listParams) => {
          // Load paging and sort params from url
          this.pagingAndSortParams = listParams.pagingAndSortParam;
        this.getReferenceCode();
    });
    // this is to get all the classifiers and to show classifier name in dropdown to search
    this.classifiers = this.referenceCodeService.getClassifiers();
    }

    getReferenceCode(event?: any, referenceSearch ?: string) {
        if(referenceSearch === 'onClickReferenceSearch'){
            this.pagingAndSortParams.pageIndex = 0;
          }
          if(event != undefined && event != null){
            this.paginator.pageIndex = event;
            this.pagingAndSortParams.pageIndex = event;
          }
          this.generateRefCodeSearchCriteria();

          let sortCriteria = 'id' + ',' + 'asc';
              
            this.dataSource.loadReferenceCodes(sortCriteria, this.pagingAndSortParams.pageIndex, 
        this.pagingAndSortParams.pageSize, this.referenceCodeSearchCriteria);
        setTimeout(()=>{      
            let count= this.dataSource.totalCount$.value
            this.pageNumbers = this.paginator.getNumberOfPages()
          },1000)
          this.pageNumbers = this.paginator.getNumberOfPages()
    }

    generateRefCodeSearchCriteria(){
        this.referenceCodeSearchCriteria = {
            classifier: this.refCodeSearchForm.value.classifierName,
        }
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    ngAfterViewInit(): void {
        // initiate sort and pagination change once the view is initialized
        this.listSource.subscribePaginationAndSortChange()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadReferenceCodesPageWithParams());
    }

    loadReferenceCodesPageWithParams() {
        this.router.navigate([], this.getQueryParams());
    }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    deleteDialog(referenceCodeData: any) {
        const dialogRef = this.dialog.open(ReferenceCodeDeleteDialogComponent, {
            data: referenceCodeData
        });
        dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this))).subscribe((result) => {
            if (result && result === 'deleted') {
                this.snackBar.open(`Deleted referenceCode successfully`, 'Close');
                this.loadReferenceCodesPageWithParams();
            }
        });
    }

}
