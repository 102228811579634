import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { TasksComponent } from './tasks.component';



const routes: Routes = [
    {
        path: 'tasks',
        canActivate: [AuthenticatedUserService],
        children: [
          {path: '', component: TasksComponent}
        ]
      }

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksRoutingModule{}