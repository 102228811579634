import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {LoanOdAccountsService} from './loan-od-accounts.service';
import {AccountProfileDto} from './accountt-profile-dto.model';

@Injectable()
export class LoanOdAccountsSetStatusResolve implements Resolve<AccountProfileDto> {

    constructor(private loanOdAccountsService: LoanOdAccountsService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId')) {
            return this.loanOdAccountsService.freeze(route.paramMap.get('accountId'));
        } else {
            return new AccountProfileDto();
        }
    }
}
