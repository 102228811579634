import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSidenav, MatSort } from '@angular/material';
import { ActivatedRoute, Router ,NavigationEnd } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ListSource } from '@ig-core/list/list-source';
import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { LeadsDataSource } from './leads.data.source';
import { LeadSearchCriteria } from './leads.model';
import { LeadsService } from './leads.service';
import { ZoneService } from '@app/zones/zone.service';
import { BranchService } from '@app/admin/branch/branch.service';
import { filter } from 'rxjs/operators';
import { RecentOpenedApplicationsService } from '@app/applications/recent-opened-applications.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.template.html',
  styleUrls: ['./leads.style.scss'],
  animations: [
    trigger('openCloseLeadSidenav', [
      state('openLead', style({ width: '330px' })),
      state('closeLead', style({ width: '0px' })),
      transition('open <=> closed',
        animate('0.5s')
      ),
    ]),
    trigger('openCloseLeadSidenavContent', [
      state('openLead', style({ 'margin-right': '330px' })),
      state('closeLead', style({ 'margin-right': '0px' })),
      transition('open <=> closed', animate('0.5s')
      ),
    ])
  ]
})

export class LeadsComponent implements OnInit, OnDestroy {

  public showPreview: boolean=false;
  @ViewChild('leadSidenav')
  public leadSidenav: MatSidenav;
  public isLeadSidenavOpen = false;
  listSource: ListSource;
  public dataSource: LeadsDataSource;
  displayedColumns: string[] = [];
  userBranches: NameValueDto[];
  zoneLists: string[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pagingAndSortParams: PagingAndSortParams;

  //selectedLead: Lead;
  leadsSearchForm: FormGroup;
  leadsSearchCritera: LeadSearchCriteria;
  leadsStatuses: NameValueDto[];
  pageNumbers:any;
  
  //noteContent: string;
  flexMediaWatcher: Subscription;
  currentScreenWidth: string = '';
  isSmallScreen: boolean = false;

  manualPage:number;
  backPageNumber:number
  previousUrl: string = null;
    currentUrl: string = null;
    leadsData: any;
    
  constructor(private leadsService: LeadsService,
    private branchService:BranchService,
    private zoneService:  ZoneService,
    private referenceCodeService: ReferenceCodeService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private media: MediaObserver,
    private recentOpenedApplicationsService:RecentOpenedApplicationsService) {
    this.flexMediaWatcher = media.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias !== this.currentScreenWidth) {
        this.currentScreenWidth = change.mqAlias;
        this.setTableColumns();
      }
    });
  }

  setTableColumns() {
    if (this.currentScreenWidth === 'xs') {
      this.displayedColumns = ['leadStatus', 'ro'];
    } else if (this.currentScreenWidth === 'sm') {
      this.displayedColumns = ['leadStatus', 'followUp', 'ro'];
    } else if (this.currentScreenWidth === 'md') {
      this.displayedColumns = ['leadStatus', 'branchCode', 'followUp', 'ro'];
    } else {
      this.displayedColumns = ['leadStatus', 'branchCode', 'leadCreationDate',
        'contactPerson', 'phoneNumber', 'followUp', 'ro','roName', 'turnAroundTime'];
    }
  };

  ngOnInit() {
    // here we are setting openedApplicationDate to null to get all the values, here in this screan we need to display all the dropdown,
    // this is list of leads screen, if we don't set to null it will show the dropdown values based on previously opened lead
    this.recentOpenedApplicationsService.entityCreatedDate(null);
    this.activatedRoute.queryParams
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
     this.backPageNumber =JSON.parse(params.pageNumber)
  });
    this.getReferenceCodes();
    this.fetchUserBranch();
    
    this.leadsSearchForm = this.formBuilder.group({
      status: [],
      zone: '',
      branches: [],
      leadDateFrom: '',
      leadDateTo: ''
    });

    this.dataSource = new LeadsDataSource(this.leadsService);
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
    
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        this.getLeads(this.pagingAndSortParams.pageIndex);
      });
  }

  getReferenceCodes() {
    this.referenceCodeService.getShortRefCodes('opportunity_status')
      .subscribe((response: any) => {
        this.leadsStatuses = response.opportunity_status;
      });
  }

  getLeads(event?:any,from?:string) {

    if(from === 'onClickSearch'){
      this.pagingAndSortParams.pageIndex =0
    }
   
    if(event != undefined && event != null ){
      this.paginator.pageIndex=event
      this.pagingAndSortParams.pageIndex =event
    }
    
    if(this.backPageNumber){
      this.pagingAndSortParams.pageIndex = this.backPageNumber
      
    }
    this.generateLeadSearchCriteria();
    let sortCriteria = 'uid' + ',' + 'asc';
    this.dataSource.getLeads(sortCriteria, this.pagingAndSortParams.pageIndex,
      this.pagingAndSortParams.pageSize, this.leadsSearchCritera);
      setTimeout(()=>{      
        this.pageNumbers = this.paginator.getNumberOfPages()
        this.backPageNumber =undefined
      },1000)
      this.pageNumbers = this.paginator.getNumberOfPages()
  }

  generateLeadSearchCriteria() {
    this.leadsSearchCritera = {
      leadStatuses: this.leadsSearchForm.value.status,
      leadBranches: this.leadsSearchForm.value.branches,
      leadZone: this.leadsSearchForm.value.zone,
      leadDateFrom: this.leadsSearchForm.value.leadDateFrom,
      leadDateTo: this.leadsSearchForm.value.leadDateTo
    }
  }

  fetchUserBranch() {
    this.branchService.getBranchesForUser().subscribe((response: any) => {
      if (response) {
        this.userBranches = response.body;
      }
    });
  }

  fetchZoneForBranch() {
    let selectedBranches = this.leadsSearchForm.value.branches;
    if (selectedBranches && selectedBranches.length === 1 || selectedBranches.length === 0) {
      this.leadsSearchForm.controls.zone.enable();
      if (selectedBranches && selectedBranches.length === 0) {
        this.leadsSearchForm.controls.zone.reset();
      }
      this.zoneService.getZonesForBranch(selectedBranches[0]).subscribe((response: any) => {
        if (response) {
          this.zoneLists = response.body;
        }
      });
    } else {
      this.leadsSearchForm.controls.zone.disable();
      this.leadsSearchForm.controls.zone.reset();
    }
  }

  ngAfterViewInit(): void {
    this.listSource.subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this))).subscribe(
        () => this.loadLeadsPageWithParams());
  }

  loadLeadsPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  showLeadDetails(leadUid) {
    this.router.navigate(['/leads', leadUid]);
  }

  //on click of quick view button these function will be executed 
  openLeadQuickView() {
    if(this.dataSource.leadsSubject.value.length != 0){
        this.showPreview = true;
        this.leadsData = this.dataSource.leadsSubject.value;
    }
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  getBack(data) {
    this.showPreview = false;
  }

}