
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventManager, JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { ConfigureService } from '../build-config/configure.service';
import { AccountProfileDto } from './accountt-profile-dto.model';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-set-status-execute',
    templateUrl: './loan-od-accounts-set-status-execute.component.html'
})
export class LoanOdAccountsSetStatusExecuteComponent implements OnInit, OnDestroy {

    loanOdAccounts: LoanOdAccounts;
    accountProfileDto: AccountProfileDto;
    private subscription: any;
    private eventSubscriber: Subscription;
    BASE_URL: string;

    constructor(private eventManager: EventManager,
                private jhiLanguageService: JhiLanguageService,
                private loanOdAccountsService: LoanOdAccountsService,
                private route: ActivatedRoute,
                private configService: ConfigureService) {
        // this.jhiLanguageService.setLocations(['accountProfileDto']);
        this.accountProfileDto = this.loanOdAccountsService.getFreezeExecute();
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.registerChangeInInternalSettings();
        this.BASE_URL = this.configService.buildUrl();
    }

    load(id) {
        this.loanOdAccountsService.find(id).subscribe((accountProfileDto) => {
            this.accountProfileDto = accountProfileDto;
        });
    }

    previousState() {
        window.history.back();
    }

    confirm() {
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInInternalSettings() {
        this.eventSubscriber = this.eventManager.subscribe('loanOdAccountsListModification', (response) => this.load(this.accountProfileDto.id));
    }

}
