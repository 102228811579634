import { Component, Input, OnInit,OnChanges,SimpleChanges,Output,EventEmitter} from '@angular/core';
import { applicationTrail } from '../decision.model';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ApplicationService } from '../../../application.service';
import { FormControl, FormGroup, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { MatSnackBar } from '@angular/material';
import { MatCheckboxChange } from '@angular/material/checkbox';
@Component({
  selector: 'eng-change-approval',
  templateUrl: './change-approval.component.html',
  styleUrls: ['../../application-details.styles.scss', './change-approval.component.scss']
})
export class ChangeApprovalComponent implements OnInit ,OnChanges {
  @Input() application: LoanApplication;
  @Input() menuItem:string;
  @Input() menuCode:string;
  
  @Output() change: EventEmitter<MatCheckboxChange> 

  changeApprovalItems: any;
  CategoryTypes: NameValueDto;

  changeApprovalForm: FormGroup;

  trailLabel: string = 'HIDE TRAIL';

  hideOrShowTrail: boolean = false;
  allowAccess: Boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    private referenceCodeService: ReferenceCodeService) {
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.changeApprovalForm = this.formBuilder.group({})
    this.getChangeApprovalItems();
    this.getReferenceCodes();
    this.getMenuItemAccess();
    if(this.menuItem === 'changeTrail'){
      this.getAllChangeTrails();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.menuItem) {
      if(this.menuItem === 'changeTrail'){
        this.getAllChangeTrails();
      }
    }
  }

  getReferenceCodes() {
    this.referenceCodeService.getShortRefCodes('post_approval_item').subscribe((response: any) => {
      this.CategoryTypes = response.post_approval_item;
    });
  }

  getChangeApprovalItems() {
    // this.applicationService.getBankAccountById("140404922578656256").subscribe(response =>{
      
    //   })
    this.applicationService.getChangeApproval(this.application.uid).subscribe(response => {
      console.log('response', response)
      this.changeApprovalItems = response.body
      this.changeApprovalItems.sort((a,b) => { 
        if(a.postApprovalChange.approvedFlag == true ||a.postApprovalChange.rejectedflag == true) return 1;
        else if(b.postApprovalChange.approvedFlag == true ||b.postApprovalChange.rejectedflag == true) return -1;
        else return a - b;
    });
      this.buildFormGroup()

    })
  }

  buildFormGroup() {
    this.changeApprovalForm = this.formBuilder.group({
      recommendRemarks: new FormArray(this.changeApprovalItems.map(data => new FormControl(data.postApprovalChange.recommendRemarks))),
      recommendFlag: new FormArray(this.changeApprovalItems.map(data => new FormControl(data.postApprovalChange.recommendFlag))),
      notRecommendedFlag: new FormArray(this.changeApprovalItems.map(data => new FormControl(data.postApprovalChange.notRecommendedFlag))),
    })
    this.changeApprovalForm.disable()
  }


  enableDisableForm() {
    this.changeApprovalForm.enable()
    this.disableApprovedRejectedRecords()
  }

  saveChangeApproval() {
    if (this.changeApprovalForm.get('recommendFlag')['controls'].length !== 0 && this.changeApprovalForm.get('recommendRemarks')['controls'].length !== 0) {
      this.changeApprovalItems.forEach((element, index) => {
        element.postApprovalChange.recommendFlag = this.changeApprovalForm.get('recommendFlag')['controls'][index].value
        element.postApprovalChange.recommendRemarks = this.changeApprovalForm.get('recommendRemarks')['controls'][index].value
        element.postApprovalChange.notRecommendedFlag = this.changeApprovalForm.get('notRecommendedFlag')['controls'][index].value
      });
    }
    this.applicationService.saveChangeApproval(this.application.uid, this.changeApprovalItems).subscribe(data => {
      this._snackbar.open("Change Approval updated successfully", "Close", {
        duration: 2000,
      });
    }, error => {

    })
  }

  cancelForm() {
    this.changeApprovalForm.disable()
  }

  hideOrShowTrailfunction() {
    this.hideOrShowTrail = !this.hideOrShowTrail
    if (this.hideOrShowTrail) {
      this.trailLabel = 'SHOW TRAIL'
    } else {
      this.trailLabel = 'HIDE TRAIL'
    }
  }
  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

    getAllChangeTrails() {
      this.applicationService.getApplicationChangeTrails(this.application.uid).subscribe(response => {
        this.changeApprovalItems = response.body
      })
    }


    formatCommaSeperateBankDetails(){
    
    }

    //this function will call when recommend check box value change
    // Note that it has be either 'Recommend' or 'Reject'. Both cannot be checked.
    // so when Recommended checkbox checked clearing the reject checkbox value
  onChangerecommendFlag(ob: MatCheckboxChange, index) {
    this.changeApprovalForm.get('notRecommendedFlag')['controls'][index].patchValue(null)
  }

  //this function will call when reject check box value change
  // Note that it has be either 'Recommend' or 'Reject'. Both cannot be checked.
    // so when Reject checkbox checked clearing the Recommend checkbox value
  onChangenotRecommendedFlag(ob: MatCheckboxChange, index) {
    this.changeApprovalForm.get('recommendFlag')['controls'][index].patchValue(null)
  }

  disableApprovedRejectedRecords(){
    this.changeApprovalItems.forEach((element, index) => {
      if(element.postApprovalChange.approvedFlag || element.postApprovalChange.rejectedflag){
        this.changeApprovalForm.get('recommendFlag')['controls'][index].disable()
        this.changeApprovalForm.get('recommendRemarks')['controls'][index].disable()
        this.changeApprovalForm.get('notRecommendedFlag')['controls'][index].disable()
      }
    })
  }
}
