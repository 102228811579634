import { Injectable } from '@angular/core';
import SecureStorage from 'secure-web-storage';
import CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable } from 'rxjs';

const SECRET_KEY = 'ENGROW_STORAGE_SECURITY_KEY';

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {
    // scheduledMaintennanceTimeStatus emits current value whenever it is subscribed to.
    private scheduledMaintennanceTimeStatus = new BehaviorSubject<any>(null);
    // we have added scheduledMaintennanceTime as an observable. 
    //This allows other parts of the code to subscribe to changes in the scheduledMaintennanceTimeStatus.
    scheduledMaintennanceTime$: Observable<any> = this.scheduledMaintennanceTimeStatus.asObservable();
constructor() { }
public secureSessionStorage = new SecureStorage(sessionStorage, {
hash: function hash(key): any {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
},
// Encrypt the localstorage data
encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
},
// Decrypt the encrypted data
decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
}
});
public secureLocalStorage = new SecureStorage(localStorage, {
    hash: function hash(key): any {
        key = CryptoJS.SHA256(key, SECRET_KEY);
        return key.toString();
    },
    // Encrypt the localstorage data
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
    },
    // Decrypt the encrypted data
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
    }
    });

    // Using the 'next' method of the BehaviorSubject to update the value and notify observers.
    setScheduledMaintenanceTime(value) {
        this.scheduledMaintennanceTimeStatus.next(value);
    }
}