import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { KFSCompositeResponse, KFSFee, KFSRequest } from './kfs-component.model';
import { REQUEST_TYPE_APR, REQUEST_TYPE_INIT } from '@app/constants/data.constants';
@Component({
  selector: 'eng-kfs-sched',
  templateUrl: 'kfs.template.html',
  styleUrls: ['../../application-details.styles.scss', 'kfs.styles.scss']
})
export class KFSComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;

  menuItemAllowAccess:boolean;
  allowAccess: Boolean;
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  feeForm: FormGroup;
  feeList: KFSFee[] = [];
  kfsComposit: KFSCompositeResponse;
  kfsRequest: KFSRequest = {};


  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar) {
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.getKfsData();
    this.getMenuItemAccess();
  }

  getKfsData(){
    this.applicationService.getkfs(this.application.uid).subscribe(response =>{
      this.kfsComposit = response.body;
      this.buildkfs();
    })
  }

  buildkfs(){
    this.feeForm = this.formBuilder.group({
      feeList: this.formBuilder.array([])
    })
    const array = this.feeForm.controls.feeList as FormArray;
    this.kfsComposit.fees.forEach(element => {
      const group = new FormGroup({
        feeName: new FormControl(element.displayName),
        feeType: new FormControl(element.type),
        feeAmount: new FormControl({value:element.amount , disabled: element.type === 'Fixed'},[Validators.required,Validators.pattern('^[0-9]*\\.?[0-9]+$')]),
      });
      array.push(group);
    });
  }

  initiateKfs(){
    this.kfsRequest.requestType = REQUEST_TYPE_INIT;
    this.kfsRequest.applicationBasicInfo = this.kfsComposit.applicationBasicInfo;
    this.applicationService.fetchAccountSummary(this.application.uid,this.kfsRequest).subscribe(response =>{
      this.kfsComposit = response.body;
      this.buildkfs();
    })
  }

  computeApr() {
    if (this.feeForm.valid) {
      this.kfsRequest.requestType = REQUEST_TYPE_APR;
      this.kfsRequest.applicationBasicInfo = this.kfsComposit.applicationBasicInfo;
      this.kfsComposit.fees.forEach((element, index) => {
        element.amount = this.feeForm.getRawValue().feeList[index].feeAmount;
      });
      this.kfsRequest.fees = this.kfsComposit.fees;

      this.applicationService.getKfsApr(this.application.uid, this.kfsRequest).subscribe(response => {
        this.kfsComposit = response.body;
        this.buildkfs();
      })
    } else {
      this._snackbar.open("Please fill all mandatory fields", "Close", {
        duration: 6000,
      })
    }
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }
}