import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialLibModule } from '@app/material-lib.module';
import { EditableResolveService } from '@ig-core/resolves/editable-resolve.service';
import { NonEditableResolveService } from '@ig-core/resolves/non-editable-resolve.service';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { ProductMaster } from './product-master-list.component';
import { ProductMasterEditComponent } from './product-master-edit/product-master-edit.component';
import { AllPipesModule } from '@app/utils/pipes.module';
import { MatDatepickerModule } from '@angular/material';

export const productMasterRoute: Route = {
  path: 'product-master',
  canActivate: [AuthenticatedUserService],
  children: [
    { path: '', component: ProductMaster },
    {
      path: 'create', component: ProductMasterEditComponent,
      resolve: {
        editable: EditableResolveService
      }
    },
    {
      path: 'update/:uid', component: ProductMasterEditComponent,
      resolve: {
        editable: EditableResolveService
      }
    },
    {
      path: 'view/:uid', component: ProductMasterEditComponent,
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    MatDatepickerModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    AllPipesModule
  ],
  declarations: [ProductMasterEditComponent],
  entryComponents: []
})
export class ProductMasterModel { }
