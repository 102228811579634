import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MaterialLibModule } from '@app/material-lib.module';
import {EditableResolveService} from '@ig-core/resolves/editable-resolve.service';
import {NonEditableResolveService} from '@ig-core/resolves/non-editable-resolve.service';
import {AuthenticatedUserService} from '@ig-core/authentication/authenticated-user-service';
import { OrganizationStructureComponent } from './organization-structure.component';
import { OrganizationStructureEditorComponent } from './organization-structure-edit/organization-structure-edit.component';
import { OrganizationStructureService } from './organization-struture.service';
import { OrganizationStructureResolverService } from './organization-structure.resolve';
import { SnackbarService } from '@app/utils/multiple-snackbar-messages-display.service';

export const orgStructure: Route = {
  path: 'organization-structure',
  canActivate: [AuthenticatedUserService],
  children: [
     {path: '', component: OrganizationStructureComponent},
    {path: 'create', component: OrganizationStructureEditorComponent,
      resolve: {
        editable: EditableResolveService
      }},
    {path: 'update/:uid', component: OrganizationStructureEditorComponent,
      resolve: {
        organizationStructure: OrganizationStructureResolverService,
        editable: EditableResolveService
      }
    },
    {path: 'view/:uid', component: OrganizationStructureEditorComponent,
      resolve: {
        organizationStructure: OrganizationStructureResolverService,
        editable: NonEditableResolveService
      }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
  ],
  declarations: [ OrganizationStructureEditorComponent ],
  entryComponents: [],
  providers:[SnackbarService]
})
export class OrganizationStructureModule { }
