import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePreviewDialogComponent } from './image-preview-dialog.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { ImagePreviewDialogService } from './image-preview-dialog.service';
import { MaterialLibModule } from '@app/material-lib.module';
import { NgxImageZoomModule } from 'ngx-image-zoom';
@NgModule({
  declarations: [ImagePreviewDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MaterialLibModule,
    NgxImageZoomModule
  ],
  exports: [ImagePreviewDialogComponent],
  entryComponents: [ImagePreviewDialogComponent],
  providers: [ImagePreviewDialogService],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class ImagePreviewDialogModule { }
