import { Component, OnInit, OnChanges, Input, SimpleChanges, AfterViewInit,ViewEncapsulation} from '@angular/core';
import { LoanApplication, Person,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { DateAdapter, MatDialogRef, MatSnackBar } from '@angular/material';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ApplicantInformations } from './applicant-information.model';
import { IgFileService } from '@ig-core/form/igFile.service';
import { delay } from 'rxjs/operators';
import { ValidationService } from '../../../application-validators/validation.service';
import { ICurrentControlValidators } from '../../../application-validators/validation.models';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { FileUploadComponent } from '@app/utils/file-upload/file-upload.component';
import { SOURCE_DIGITAL } from '@app/constants/data.constants';
@Component({
  selector: 'eng-applicant-information',
  templateUrl: 'applicant-information.template.html',
  styleUrls: ['../../application-details.styles.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ApplicantInformationComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  application: LoanApplication;

  @Input()
  applicant: Person;

  @Input()
    menuCode:string;
  controlValidators:ICurrentControlValidators[];
  applicantInformations: ApplicantInformations;
  applicantInformationForm: FormGroup = new FormGroup({});

  isFormDisabled: boolean;
  showForm: boolean;
  imageLoaded: boolean;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;

  /* refCodes */
  relationshipToBusinesses: NameValueDto[] = [];
  titles: NameValueDto[] = [];
  genders: NameValueDto[] = [];
  educationLevels: NameValueDto[] = [];
  castes: NameValueDto[] = [];
  religions: NameValueDto[] = [];
  nationalities: NameValueDto[] = [];
  physicalChanges: NameValueDto[] = [];
  occupationCategories: NameValueDto[] = [];
  sourceOfIncomes: NameValueDto[] = [];
  grossAnnualIncomes: NameValueDto[] = [];
  maritalStatus: NameValueDto[] = [];
  childInstituteType: NameValueDto[] = [];
  childEducationType: NameValueDto[] = [];
  applicantImage: any;
personImageDetails:any
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  file: any;
  // this is to store the latest image uploaded. If upload fails we will show image uploaded in this variable
  localPrestineImage: any;
  showCapturePicture: boolean;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private fileService: IgFileService,
    private _snackbar: MatSnackBar,
    private referenceCodeService: ReferenceCodeService,
    private dateAdapter: DateAdapter<Date>,
    private validationService: ValidationService,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private fileUploadService:FileUploadService) {
    this.dateAdapter.setLocale('en-IN');
    this.applicantInformationForm = this.formBuilder.group({
      relationshipToBusiness: '',
      title:'',
      customerId:'',
      fName: '',
      nName: '',
      mName:'',
      dob: '',
      age: null,
      lName:'',
      gender: '',
      mobileNumber: '',
      landLineNumber: '',
      alternateNumber: '',
      email: '',
      educationLevel: '',
      caste: '',
      religion: '',
      religionOther: '',
      nationality: '',
      nationalityOther: '',
      differentlyAbledFlag: '',
      differentlyAbled: '',
      differentlyAbledOther: '',
      occupationCategory:'',
      sourceOfIncome: '',
      monthlyIncome:'',
      grossAnnualIncome: '',
      yearInCurrentBusiness: '',
      maritalStatus: '',
      spouseName: '',
      fatherName: '',
      motherName: '',
      totalHouseholdMembers: '',
      NoOfWorkingMembers: '',
      NoOfChildrens: '',
      NoOfDependents: '',
      familyGoodHealthFlag: '',
      familyInsuranceFlag: '',
      dependents: this.formBuilder.array([])
    });
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.getRefCodes();
    this.applyValidatorsToApplicantInformation();
    this.getMenuItemAccess();

  }

  ngOnChanges(changes: SimpleChanges) {
    this.isFormDisabled = true;
    if (changes.applicant) {
      this.applicantImage =''
      this.fetchApplicantInformation();
    }
  }

  ngAfterViewInit() {
    this.fetchApplicantInformation();
  }

  loadApplicantImage(folderUid: string) {
    //Currently applicant/coapplicant image is mandatory while capturing data. Hence we always get foulderUid. That's why if record found we are making showCapturePicture true.
		//In future if folderUid is not present then this code need to be changed.
    //Fetch all FileDtos stored in the folder
    this.fileService.getAllFilesFromFolder(folderUid).subscribe((response) => {
      if (response && response.body && response.body.length > 0) {
        this.personImageDetails=response.body
        if(this.personImageDetails){
          this.showCapturePicture = true;
        }
        //If response received then use fileId from response's first object to stream
        this.fileService.fileStreamById(this.personImageDetails[0].fileId,
          "true").subscribe(image => {
            let reader = new FileReader();
            reader.addEventListener("load", () => {
              //"applicantImage" is used in img src attr
              this.applicantImage = reader.result;
              this.localPrestineImage = this.applicantImage
              this.imageLoaded = true;
            }, false);
            if (image) reader.readAsDataURL(image.data);
          });
      }
    });
  }

  /*
   Applicant Information component, on loading executes this method to retrieve Reference Codes from server by passing classifier name,
    Reference Codes data will be retrieved for first time call and stored in cache, for next time reference code service give response from its cache 
   This Reference Codes  used to display dropdown values for TITLE,GENDER,EDUCATION LEVEL,CASTE,RELIGION,NATIONALITY,SOURCE OF INCOME and etc
   These Reference Codes are case sensitive
  */
  getRefCodes() {
   
    this.referenceCodeService.getShortRefCodes('title').subscribe((response: any) => {
      this.titles = response.title;
    });
    this.referenceCodeService.getShortRefCodes('Gender').subscribe((response: any) => {
      this.genders = response.Gender;
    });
    this.referenceCodeService.getShortRefCodes('education_level').subscribe((response: any) => {
      this.educationLevels = response.education_level;
    });
    this.referenceCodeService.getShortRefCodes('caste').subscribe((response: any) => {
      this.castes = response.caste;
    });
    this.referenceCodeService.getShortRefCodes('religion').subscribe((response: any) => {
      this.religions = response.religion;
    });
    this.referenceCodeService.getShortRefCodes('nationality').subscribe((response: any) => {
      this.nationalities = response.nationality;
    });
    this.referenceCodeService.getShortRefCodes('physical_challenge').subscribe((response: any) => {
      this.physicalChanges = response.physical_challenge;
    });
    this.referenceCodeService.getShortRefCodes('occupation_type').subscribe((response: any) => {
      this.occupationCategories = response.occupation_type;
    });
    this.referenceCodeService.getShortRefCodes('income_source').subscribe((response: any) => {
      this.sourceOfIncomes = response.income_source;
    });
    this.referenceCodeService.getShortRefCodes('gross_annual_income').subscribe((response: any) => {
      this.grossAnnualIncomes = response.gross_annual_income;
    });
    this.referenceCodeService.getShortRefCodes('marital_status').subscribe((response: any) => {
      this.maritalStatus = response.marital_status;
    });
    this.referenceCodeService.getShortRefCodes('child_education').subscribe((response: any) => {
      this.childEducationType = response.child_education;
    });
    this.referenceCodeService.getShortRefCodes('child_institute_type').subscribe((response: any) => {
      this.childInstituteType = response.child_institute_type;
    });
  }


    /*
   Applicant Information component, on loading executes this method to retrieve the Applicant Information from server by passing Application UID and Applicant UID,
    Based on server response, using "partyPlay" value, respective classifier name will be passed to retrieve person Type Reference codes,
  */

  fetchApplicantInformation() {
    this.showForm = false;
    this.applicationService.getApplicantInformation(this.application.uid, this.applicant.uid)
      .subscribe((response: any) => {
        if (response) {
          this.applicantInformations = response;
          if(this.applicantInformations.partyLink.partyPlay == 'applicant'){
          this.getRelationship('applicant_type')
          }else if(this.applicantInformations.partyLink.partyPlay == 'coapplicant'){
            this.getRelationship('coApplicant_type')
          }
          this.showForm = true;
          this.buildDependentsFormArray(this.applicantInformations)
          this.buildApplicantInformationForm(this.applicantInformations);
          this.loadApplicantImage(this.applicantInformations.personProfile.profilePicFileUid);
        }
      });
  }

  /*
   Applicant Information component, Once Applicant Information is retrieved from server then this method will be executed,
  Here each input has one form control name to pass data to html view and read user entered data from view 
  The Applicant Information response will be used to assign initial values for form controls in form group,
  */
  buildApplicantInformationForm(applicantInformations) {
    if (this.showForm) {
      this.applicantInformationForm.patchValue({
        relationshipToBusiness: applicantInformations.personProfile.relationshipType,
        title: applicantInformations.person.title,
        customerId: applicantInformations.person.personId,
        fName: applicantInformations.person.firstName,
        nName: applicantInformations.person.aliasName,
        mName: applicantInformations.person.middleName,
        dob: applicantInformations.person.dateOfBirth === null ?
          undefined : new Date(applicantInformations.person.dateOfBirth),
        age: applicantInformations.person.age ? applicantInformations.person.age : null,
        lName: applicantInformations.person.lastName,

        gender: applicantInformations.person.gender,
        mobileNumber: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.primaryPhone,
        landLineNumber: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.landlinePhone,
        alternateNumber: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.alternatePhone,
        email: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.emailId,
        educationLevel: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.education,
        caste: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.socialCategory,
        religion: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.religion,

        religionOther: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.religionOther,
        nationality: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.nationality,
        nationalityOther: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.nationalityOther,
        differentlyAbledFlag: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.physicallyChallengedFlag,
        differentlyAbled: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.physicalChallenge,
        differentlyAbledOther: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.physicalChallengeOther,
        occupationCategory: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.occupation,

        sourceOfIncome: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.incomeSource,
        monthlyIncome: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.monthlyIncome,
        grossAnnualIncome: applicantInformations.personProfile === null ? '' : (applicantInformations.personProfile.monthlyIncome * 12),
        yearInCurrentBusiness: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.experienceInYears,

        maritalStatus: applicantInformations.person.maritalStatus,
        spouseName: applicantInformations.person.spouseName,
        fatherName: applicantInformations.person.fatherName,
        motherName: applicantInformations.person.motherName,
        
        totalHouseholdMembers: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.noOfFamilyMembers,
        NoOfWorkingMembers: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.noOfWorkingMembers,
        NoOfChildrens: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.noOfChildren,
        NoOfDependents: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.noOfDependents,
        familyGoodHealthFlag: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.familyGoodHealthFlag,
        familyInsuranceFlag: applicantInformations.personProfile === null ? '' : applicantInformations.personProfile.familyInsuranceFlag
      });
      // Spouse name is validated when marital status is MARR or SEPARATED. Else it is removed
      if ((applicantInformations.person.maritalStatus == "SEPARATED") || (applicantInformations.person.maritalStatus == "MARR")) {
      }else{
        const ctrl = this.applicantInformationForm.get('spouseName')
        ctrl.clearValidators();
        ctrl.updateValueAndValidity();
      }
      this.applicantInformationForm.controls.grossAnnualIncome.disable()
      this.applicantInformationForm.controls.gender.disable();
      this.OnChangeDifferentlyAbled(applicantInformations.personProfile.physicallyChallengedFlag);
    }
  }


  /*
   Applicant Information component, Just before saving the applicant information data this method will be executed to check and validate each form control values ,
   if any value is invalid the error message will be displayed on respected input 
  */
   markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }


  /*
   Applicant Information component, this method will be executed on saving applicant information details,before saving form controls will be validated,
   after validating, the values will be set as per server request format and,
   then sent to the applicant save http request along with the application UID and applicant UID.
   If data saved successfully, success message will be displayed if not error message will be displayed
  */

  saveApplicantInformation() {
    this.markFormGroupTouched(this.applicantInformationForm)
    if (!this.isFormDisabled && this.applicantInformationForm.valid) {
      let applicantInformationFormFields = this.applicantInformationForm.value;
    
      this.applicantInformations.person.title = applicantInformationFormFields.title;
      this.applicantInformations.person.personId = applicantInformationFormFields.customerId
      this.applicantInformations.person.firstName = this.applicantInformationForm.getRawValue().fName;
      this.applicantInformations.person.aliasName = applicantInformationFormFields.nName
      this.applicantInformations.person.middleName = this.applicantInformationForm.getRawValue().mName;
      this.applicantInformations.person.lastName = this.applicantInformationForm.getRawValue().lName;
      this.applicantInformations.person.dateOfBirth = this.applicantInformationForm.getRawValue().dob;
      this.applicantInformations.person.age = applicantInformationFormFields.age;

      this.applicantInformations.person.gender = this.applicantInformationForm.getRawValue().gender;
      this.applicantInformations.person.maritalStatus = applicantInformationFormFields.maritalStatus;
      this.applicantInformations.person.spouseName = applicantInformationFormFields.spouseName;
      this.applicantInformations.person.fatherName = applicantInformationFormFields.fatherName;
      this.applicantInformations.person.motherName = applicantInformationFormFields.motherName;

      this.applicantInformations.personProfile.relationshipType = applicantInformationFormFields.relationshipToBusiness;
      this.applicantInformations.personProfile.primaryPhone = applicantInformationFormFields.mobileNumber;
      this.applicantInformations.personProfile.landlinePhone = applicantInformationFormFields.landLineNumber;
      this.applicantInformations.personProfile.alternatePhone = applicantInformationFormFields.alternateNumber;
      this.applicantInformations.personProfile.emailId = applicantInformationFormFields.email;

      this.applicantInformations.personProfile.education = applicantInformationFormFields.educationLevel;
      this.applicantInformations.personProfile.socialCategory = applicantInformationFormFields.caste;
      this.applicantInformations.personProfile.religion = applicantInformationFormFields.religion;
      this.applicantInformations.personProfile.religionOther = applicantInformationFormFields.religionOther;
      this.applicantInformations.personProfile.nationality = applicantInformationFormFields.nationality;
      this.applicantInformations.personProfile.nationalityOther = applicantInformationFormFields.nationalityOther;

      this.applicantInformations.personProfile.physicallyChallengedFlag = applicantInformationFormFields.differentlyAbledFlag;
      this.applicantInformations.personProfile.physicalChallenge = applicantInformationFormFields.differentlyAbled;
      this.applicantInformations.personProfile.physicalChallengeOther = applicantInformationFormFields.differentlyAbledOther;
      this.applicantInformations.personProfile.occupation = applicantInformationFormFields.occupationCategory;

      this.applicantInformations.personProfile.incomeSource = applicantInformationFormFields.sourceOfIncome;
      this.applicantInformations.personProfile.monthlyIncome = applicantInformationFormFields.monthlyIncome;
      this.applicantInformations.personProfile.annualIncome = applicantInformationFormFields.grossAnnualIncome;
      this.applicantInformations.personProfile.experienceInYears = applicantInformationFormFields.yearInCurrentBusiness;

      this.applicantInformations.personProfile.noOfFamilyMembers = applicantInformationFormFields.totalHouseholdMembers;
      this.applicantInformations.personProfile.noOfWorkingMembers = applicantInformationFormFields.NoOfWorkingMembers;
      this.applicantInformations.personProfile.noOfChildren = applicantInformationFormFields.NoOfChildrens;
      this.applicantInformations.personProfile.noOfDependents = applicantInformationFormFields.NoOfDependents;
      this.applicantInformations.personProfile.familyGoodHealthFlag = applicantInformationFormFields.familyGoodHealthFlag;
      this.applicantInformations.personProfile.familyInsuranceFlag = applicantInformationFormFields.familyInsuranceFlag;


      this.applicantInformations.dependents = applicantInformationFormFields.dependents;
      this.applicationService.saveApplicantInformation(this.applicantInformations,
        this.application.uid, this.applicant.uid).toPromise().then(
          (_success) => {
            this._snackbar.open("Applicant information updated successfully", "Close", {
              duration: 2000,
            });
            this.fetchApplicantInformation();
            this.isFormDisabled =true
          }, (failure) => {
            let errormesg =[]
            errormesg.push("Applicant information update failed.")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
          }
        );
    }else{
      this._snackbar.open("Please fill all mandatory fields", "Close", {
        duration: 2000,
      })
    }
  }


  /*
   Applicant Information component, this method will be executed on click of Edit icon button,
   Here disabled form will be enabled for editing, editing not allowed input fields will be disabled
  */
  enableDisableForm() {
    this.applicantInformationForm.controls.dob.enable();
    this.applicantInformationForm.controls.gender.enable();
    this.showCapturePicture = true;
    this.isFormDisabled = !this.isFormDisabled;
    this.onChangeReligion(null);
    this.onChangeNationality(null);
    this.OnChangeDifferentlyAbled(null);
    this.physicalChangeOthers(null);
    this.applicantInformationForm.get('age').disable()
    if(this.applicantInformations.person.source === SOURCE_DIGITAL){
      this.applicantInformationForm.controls.dob.disable();
      this.applicantInformationForm.controls.gender.disable();
      this.applicantInformationForm.controls.fName.disable();
      this.applicantInformationForm.controls.mName.disable();
      this.applicantInformationForm.controls.lName.disable();
      this.showCapturePicture = false;
    }
  }


  /*
   Applicant Information component, this method will be executed on change of Religion dropdown,
   Here Religion if others dropdown will be enabled if Religion value is "others" Else it is disabled.
  */
  onChangeReligion(religion) {
    if (religion && religion == 'OTH') {
      this.applicantInformationForm.get('religionOther').enable()
    } else {
      this.applicantInformationForm.get('religionOther').disable()
      this.applicantInformationForm.get('religionOther').reset()
    }
  }


  /*
   Applicant Information component, this method will be executed on change of NATIONALITY field,
   Here NATIONALITY IF OTHERS field will be enabled if NATIONALITY value is "others" Else it is disabled.
  */
  onChangeNationality(nationality) {
    if (nationality && nationality == 'OTH') {
      this.applicantInformationForm.get('nationalityOther').enable()
    } else {
      this.applicantInformationForm.get('nationalityOther').disable()
      this.applicantInformationForm.get('nationalityOther').reset()
    }
  }

  
/*
   Applicant Information component, this method will be executed on selecting of "DIFFERENTLY ABLED ?" radio button,
   Here "DIFFERENTLY ABLED ?" radio button value is true then dropdown will be enabled for selecting the disabilities Else it is disabled.
  */
  OnChangeDifferentlyAbled(differentlyAbled) { 
    if (differentlyAbled == true) {
      this.applicantInformationForm.get("differentlyAbled").enable()
    } else {
      this.applicantInformationForm.get("differentlyAbled").disable()
    }
  }
/*
   Applicant Information component, this method will be executed on selecting of "DIFFERENTLY ABLED?" dropdown,
   Here "DIFFERENTLY ABLED ?" dropdown value is 'others' then 'DIFFERENTLY ABLED, IF OTHERS' field will be enabled Else it is disabled.
  */
  physicalChangeOthers(others) {
    if (others && others == "OTH") {
      this.applicantInformationForm.get("differentlyAbledOther").enable()
    } else {
      this.applicantInformationForm.get("differentlyAbledOther").disable()
      this.applicantInformationForm.get("differentlyAbledOther").reset()
    }
  }

  /*
   Applicant Information component, this method will be executed on clicking  of 'Cancel' button,
   Here two functions are calling for assigning the saved applicant information for inputs,
   it will clear unsaved data  and update it with saved data.
  */
  cancelForm() {
    this.buildApplicantInformationForm(this.applicantInformations);
    this.buildDependentsFormArray(this.applicantInformations)
    this.isFormDisabled = true;
  }

  /*
   Applicant Information component, this method will be executed on 'MONTHLY INCOME' value change,
   Here using 'MONTHLY INCOME' value 'GROSS ANNUAL INCOME' will be calculated,
   The calculation expression is GROSS ANNUAL INCOME = (MONTHLY INCOME * 12),
   The calculated gross annual income value is updated to formcontrol.
  */
  updateGrossAnnualIncome(event: any) {
    let monthlyIncome = Number(event.target.value)
    this.applicantInformationForm.controls.grossAnnualIncome.setValue(monthlyIncome * 12)
  }


  /*
   Applicant Information component, on loading this method will be executed to apply validators to form controls,
   Here validations are applied dynamically by passing formgroup, and the JSON validators object name ,
   the validators appiled based on JSON objects for each formcontrol
  */

  applyValidatorsToApplicantInformation() {
    this.validationService.applyValidationRules(this.applicantInformationForm, "ApplicantInformation").then((controlValidators) => {
      this.controlValidators = controlValidators
    }).catch(() => {
    })
  }

 
  /*
   Applicant Information component,this method will be execute on changing of 'MARITAL STATUS' dropdown value,
   Based on 'MARITAL STATUS' value the 'SPOUSE NAME' input required validation will be appied,
  If 'MARITAL STATUS' value is  Married or Seperated then the 'SPOUSE NAME' field will be mandetory,
  for other values validators are removed 
  */ 
  checkValue(event) {
    const ctrl = this.applicantInformationForm.get('spouseName')
    if (event.value === "SEPARATED" || event.value === "MARR") {
      this.controlValidators.forEach(element => {
        if (element.controlName === 'spouseName') {
          ctrl.setValidators(element.validators);
          ctrl.updateValueAndValidity();
        }
      })
    } else {
      ctrl.clearValidators();
      ctrl.updateValueAndValidity();
    }
  }

  /*
   Applicant Information component, Once Applicant Information is retrieved from server then this method will be executed to build the 'DEPENDENT CHILDREN' related formarray,
  Here each input has one form control name to pass data to html view and read user entered data from view 
  The Applicant Information response will be used to assign initial values for form controls in form array,
  */

  buildDependentsFormArray(applicantInformations) {
    if (this.applicantInformationForm.get('dependents')['controls'].length !== 0) {
      this.applicantInformationForm.get('dependents')['controls'] = []
    }
    //this.applicantInformationForm.addControl('dependents', this.formBuilder.array([]))
    if (applicantInformations.dependents !== null && applicantInformations.dependents !== undefined && applicantInformations.dependents.length !== 0) {
      const array = this.applicantInformationForm.controls.dependents as FormArray;
      applicantInformations.dependents.forEach(element => {
        const group = this.formBuilder.group({
          contextId: element.contextId,
          contextType: element.contextType,
          contextUid: element.contextUid,
          dependentType: element.dependentType,
          frozenFlag: element.frozenFlag,
          linkToId: element.linkToId,
          linkToType: element.linkToType,
          linkToUid: element.linkToUid,
          uid: element.uid,
          version: element.version,
          versionHistoryFlag: element.versionHistoryFlag,
          name: new FormControl(element.name),
          educationType: new FormControl(element.educationType),
          age: new FormControl(element.age),
          gender: new FormControl(element.gender),
          instituteType: new FormControl(element.instituteType),
          instituteName: new FormControl(element.instituteName),
        });
        array.push(group);
      });
    }
  }



/*
   Applicant Information component, 
   Onclick of 'Add New Child Dependent' button this method will be executed to build the new 'DEPENDENT CHILDREN' formarray record,
  Here each new input has one new form control name as defined to pass data to html view and read user entered data from view then added to dependents formarray
 the form control values are initially empty  
  */

  addChildDependent() {
    this.isFormDisabled = false;
    const newArray = this.applicantInformationForm.controls.dependents as FormArray;
    const group = this.formBuilder.group({
      name: new FormControl(''),
      educationType: new FormControl(''),
      age: new FormControl(''),
      gender: new FormControl(''),
      instituteType: new FormControl(''),
      instituteName: new FormControl(''),
    });
    newArray.push(group);
  }

  onFileSelected(event) {
    console.log(event.target);
    
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.file=event.target.files[0];
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
       // called once readAsDataURL is completed
      this.applicantImage =reader.result;
      this.imageUpload();
      }
    }
  }

    imageUpload(){
    this.applicationService.uploadBorrowerProfilePicture(this.application.uid, this.applicant.uid, this.file).toPromise().then( 
      (_success) => {
      this._snackbar.open("File has been uploaded successfully", "Close", {
        duration: 5000,
      });
      this.localPrestineImage = this.applicantImage
    },(failure) => {
      this._snackbar.open(failure, "Close", {
        duration: 5000,
      });
      this.applicantImage = this.localPrestineImage
    });
  }


   /*
   Applicant Information component, 
   on loading executes this method to retrieve Application Access Modifiers (MenuItems) by passing application uid,
   Application Access Modifiers data will be retrieved filter by using this component menu item code,
    and stored in 'menuItemAccessSpecifier'parameter,
   if 'menuItemAccessSpecifier' parameter allow access is true then this component editing is allowed for user,
   if not editing is disabled (readonly)
  */
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }


     /*
   Applicant Information component, 
    this method will be executed after fetching Applicant Information from server,
    Based on server response, bypassing  "partyPlay" value, respective classifier name will be passed to retrieve person Type Reference codes,
    and stored in 'relationshipToBusinesses' parameter
  */
    getRelationship(personTypeRefcode){
      this.relationshipToBusinesses=[]
      this.referenceCodeService.getShortRefCodes(personTypeRefcode).subscribe((response: any) => {
        this.relationshipToBusinesses = response[personTypeRefcode];
      });
    }

/*
   Applicant Information component, 
    this method will be executed onclick of applicant/co-applicant image ,
    Here by passing image details the view image popup will open  
  */
   openImageViewElement() {
    // to get the latest image to display we are calling this getAllFilesFromFolder api
    this.fileService.getAllFilesFromFolder(this.applicantInformations.personProfile.profilePicFileUid).subscribe((response) => {
      if (response && response.body && response.body.length > 0) {
        this.personImageDetails=response.body;
      }
     let fileDetails=  {
       folderInfo : {},
       fileInfo :  [],
     }
     fileDetails.fileInfo = this.personImageDetails
    this.imagePreviewDialogService.open({ "images": fileDetails})
  })
}

  selectFile() {
    document.getElementById("fileInput").click();
  }
}