import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable} from 'rxjs';
import { RoleService } from './role.service';
import { Role } from './role.model';

@Injectable({
    providedIn: 'root'
})
export class RoleResolverService implements Resolve<Role> {
    constructor(private roleService: RoleService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Role> | Observable<never> {
        return this.roleService.getRole(+route.paramMap.get('id'));
    }
}
