import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {LoanOdAccountsService} from './loan-od-accounts.service';
import {LoanOdDisbursementComposite} from './loan-od-disbursement-composite.model';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AlertService } from 'ng-jhipster';

@Injectable()
export class LoanOdAccountsDisburseEntryResolve implements Resolve<LoanOdDisbursementComposite> {

    constructor(private loanOdAccountsService: LoanOdAccountsService,
        private alertService: AlertService,
        private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId')) {
            return this.loanOdAccountsService.disburseEntry(route.paramMap.get('accountId')).pipe(map(
                // tslint:disable-next-line:arrow-return-shorthand
                (res) =>  { return res; }
            )).pipe(catchError(
                (err) => {
                    this.onSaveError(err);
                    return of(null);
                }
            ));
        } else {
            return new LoanOdDisbursementComposite();
        }
    }

    private onSaveError(error) {
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
        this.router.navigate(['/loan-od-accounts']);
    }

    private onError(error) {
        this.alertService.error(error);
    }
}
