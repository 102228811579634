import { Component, OnInit, Input,OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { LoanApplication,LoanProduct } from '@app/applications/applications.model';
import { MatSnackBar } from '@angular/material';
//validation code
import { ValidationService } from '../../../application-validators/validation.service'

@Component({
    selector: 'eng-loan-requested',
    templateUrl: 'loan-requested.template.html',
    styleUrls: ['../../application-details.styles.scss']
})
export class LoanRequestedComponent implements OnInit ,OnChanges{

    @Input()
    application: LoanApplication;
    @Input()
    menuCode:string;
    
    loanRequestForm: FormGroup;
    productTypes: NameValueDto[];
    secutityOfferedTypes: NameValueDto[];
    loanPurposeTypes: NameValueDto[];
    requestedLoanProduct:LoanProduct;
    isFormDisabled: boolean;
    
    constructor(private formBuilder: FormBuilder,
        private applicationService: ApplicationService,
        private referenceCodeService: ReferenceCodeService,
        private _snackbar: MatSnackBar,
        private validationService: ValidationService) {
          this.loanRequestForm = this.formBuilder.group({
            product:'',
            productName:'',
            requestedAmount: '',
            tenure: '',
            interestRate: '',
            purpose: '',
            securityOffered: '',
            purposeTypeRequested: ''
          });
    }

    ngOnInit() {
      this.isFormDisabled = true;
      this.getRefCodes();
      this.getloanrequestedProductStatus();
      
    }
    ngOnChanges(){
      this.buildLoanApplicationForm(this.application);
    }
    getRefCodes() {
      this.referenceCodeService.getShortRefCodes('loan_type').subscribe((response: any) => {
        this.productTypes = response.loan_type;
      });
      this.referenceCodeService.getShortRefCodes('collateral_security').subscribe((response: any) => {
        this.secutityOfferedTypes = response.collateral_security;
      });
      this.referenceCodeService.getShortRefCodes('loan_purpose').subscribe((response: any) => {
        this.loanPurposeTypes = response.loan_purpose;
      });
    }

    buildLoanApplicationForm(loanApplication) {
      this.loanRequestForm.patchValue({
        product: loanApplication.productTypeReq,
        productName: loanApplication.productName,
        requestedAmount: loanApplication.amountReq,
        tenure: loanApplication.tenureReq,
        interestRate: loanApplication.interestReq,
        purpose: loanApplication.purposeReq,
        securityOffered: loanApplication.securityOfferedReq,
        purposeTypeRequested: loanApplication.purposeReqCode,
      });
    }
  

  getloanrequestedProductStatus(){
    this.referenceCodeService.getLoanProducts(this.application.productTypeReq)
    .subscribe((response: any) => {
      let requestedloanProducts=response.body
    requestedloanProducts.forEach(product => {
      if(product.productCode == this.application.productCodeReq){
        this.requestedLoanProduct =product
      }
    });
  });
  }
}