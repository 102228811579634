import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';
import { CollectionViewer } from '@angular/cdk/collections';
import { Device, DeviceSearchCriteria } from './device.model';
import { DeviceService } from './device.service';


export class DeviceDataSource extends DataSource<Device> {

  private deviceSubject = new BehaviorSubject<Device[]>([]);
  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private deviceService: DeviceService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<Device[]> {
    return this.deviceSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.deviceSubject.complete();
    this.totalCount$.complete();
  }

  getDeviceDirectory(sortDirection = 'desc', pageIndex = 0, pageSize = 10, searchCriteria: DeviceSearchCriteria) {
    this.deviceService.getDeviceData({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }, searchCriteria).pipe(
      catchError(() => of([])),
      finalize(() => { })
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['body'].totalElements);
      this.deviceSubject.next(response['body'].content);
    });
  }
} 