import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { ApplicationAccessSpecifier, LoanApplication } from '@app/applications/applications.model';
import { XDSRegistry } from './xdata-registry.model';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { OnDestroy } from '@angular/core';
@Component({
  selector: 'eng-services',
  templateUrl: 'xdata-service.template.html',
  styleUrls: ['../../application-details.styles.scss', 'xdata-service.style.scss',
  "../../../../applications/application-details/application-details.styles.scss"]
})
export class XDataServiceComponent implements OnInit, OnDestroy {

  applicationUid: string;

  xDataServices: XDSRegistry[];
 applicationAccessSpecifiers: ApplicationAccessSpecifier[];

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.contextUid;
    });
  }

  ngOnInit() {
    this.getMenuItemAccess();
  }


  getXDataServices() {
      this.applicationService.getXDataServices()
      .subscribe((response: any) => {
        this.xDataServices = response.body;
        this.xDataServices.forEach(element => {
          element.hasAuthority = this.applicationAccessSpecifiers.some(accessSpecifier => accessSpecifier.category === element.menuGrant);
        });
      });
  }

  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.applicationUid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.getXDataServices();
    });
    }

    onExecuteClick(xDataService){
      this.router.navigate(['/applications',this.applicationUid,'services',xDataService.serviceType.toLowerCase()],{ queryParams: { xDataService:xDataService.serviceProviderCode , applicationUid: this.applicationUid, serviceType: xDataService.serviceType} })
    }
  ngOnDestroy(): void {
  }
}
