import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';

import {Task} from './task.model';
import {TaskService} from './task.service';
import { toDate } from '@angular/common/src/i18n/format_date';


export class TaskListDataSource extends  DataSource<Task> {

  value: any;

  private taskSubject = new BehaviorSubject<Task[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private taskService: TaskService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<Task[]> {
    return this.taskSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.taskSubject.complete();
    this.totalCount$.complete();
  }

  loadTask(sortDirection = 'asc', pageIndex = 0, pageSize = 3, toDate, status) {

 this.taskService.findTask({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
      status: status,
      toDate : toDate
    }).pipe(take(1)).subscribe((response: any) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.taskSubject.next(response['body']);
      this.value = response.body;
    });
  }

}
