import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {MetricsService} from './metrics.service';
import {map} from 'rxjs/operators';

@Injectable()
export class MetricsResolver implements Resolve<any> {
  constructor(private metricService: MetricsService) {}

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.metricService.getMetrics();
  }

}
