import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {IgErrorLogService} from './ig-error-log.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class IgGlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse): void {
    const igErrorLogService = this.injector.get(IgErrorLogService);
    igErrorLogService.logError(error);
  }
}
