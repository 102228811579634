import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoanApplication, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { BookLoanValidate, LoanBookFillAccount } from '../book-loan/book-loan.model';

@Component({
  selector: 'eng-fill-account',
  templateUrl: './fill-account.component.html',
  styleUrls: ['../../application-details.styles.scss', './fill-account.style.scss']
})
export class FillAccountComponent implements OnInit {
  @Input() application: LoanApplication;
  @Input()
  menuCode: string;

  // this event emitter is added to update notes in application-details component
  @Output() onNotesChange = new EventEmitter<string>();

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  allowAccess: Boolean;
  menuItemAllowAccess: boolean;

  bookLoanValidateDetails: BookLoanValidate;
  loanBookFillAccount: LoanBookFillAccount;
  remarks: string = '';
  isFillAccountEnabled: boolean = false;
  foundEmptyCustomerId:boolean = false;
    //this variable is used not to show future dates in account open date
    maxDate: Date;
  

  constructor(
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar
  ) {
    this.maxDate = new Date();
   }

  ngOnInit() {
    this.bookLoanValidate()
    this.getMenuItemAccess()
    this.getFillAccount()
    
  }

  bookLoanValidate() {
    this.applicationService.loanBookValidate(this.application.uid).subscribe(response => {
      this.bookLoanValidateDetails = response.body
      if(this.bookLoanValidateDetails){
      this.enableFillAccount()
      }
    })
  }

  getFillAccount(){
    this.applicationService.getFillAccount(this.application.uid).subscribe(response =>{
      this.loanBookFillAccount = response.body;
    })
  }


  submit() {
    this.loanBookFillAccount.remarks = this.remarks;
    this.applicationService.fillAccount(this.application.uid, this.loanBookFillAccount).toPromise().then(
      (_success) => {
        this.loanBookFillAccount = _success.body;
        this.loanBookFillAccount.accountOpenDate = this.loanBookFillAccount.accountOpenDate === null ? undefined : new Date(this.loanBookFillAccount.accountOpenDate);
        this._snackbar.open("Fill account successfull", "close" , {
          duration: 5000
        })
        // on creation of fill account note is created from server side, to show the change in notes we are using event emitter this will be called
        // if there is any change in notes list.
        this.onNotesChange.emit()
      }, (failure) => {
        this._snackbar.open(failure , "close" , {
          duration: 7000
        })
      }
    );
  }

  applyValidations(){
    this.foundEmptyCustomerId = false;
    if(!this.loanBookFillAccount.accountId || this.loanBookFillAccount.accountId.trim() === ''){
      this._snackbar.open("Account number is mandatory.", "close" ,{
        duration: 5000
      })
      return;
    }

    if(!this.loanBookFillAccount.accountOpenDate || this.loanBookFillAccount.accountOpenDate === null){
      this._snackbar.open("Account open date is mandatory.", "close" ,{
        duration: 5000
      })
      return;
    }

    for (let i = 0; i < this.loanBookFillAccount.personBasicInformation.length; i++) {
      let element = this.loanBookFillAccount.personBasicInformation[i];
      if (!element.personId || element.personId.trim() === '') {
        this._snackbar.open("Customer id cannot be empty for customer " + element.customerDisplayName, "close", {
          duration: 5000
        });
        this.foundEmptyCustomerId = true;
        break;
      }
    }
    if(!this.foundEmptyCustomerId){
    this.submit()
    }
  }

  updateDateField(date){
    if(date > this.maxDate){  
      this._snackbar.open("Account open date cannot be future date.", "close" ,{
        duration: 5000
      })
      return;
    }
  }

  enableFillAccount(){
    if(this.bookLoanValidateDetails.readyForBooking && !this.bookLoanValidateDetails.loanBooked){
      this.isFillAccountEnabled = true
    }
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

}
