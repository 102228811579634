import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator, MatSort} from '@angular/material';
import {AfterViewInit, OnDestroy, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { ListSource } from '@ig-core/list/list-source';

import { UserService } from './user.service';
import { UserListDataSource } from './user-list.data.source';
import { UserDeleteDialogComponent } from './user-delete-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserSearchCriteria } from './user.model';
import { getCurrentSanitizer } from '@angular/core/src/render3/instructions';
@Component({
  selector: 'app-user',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy {

  pagingAndSortParams: PagingAndSortParams;
  dataSource: UserListDataSource;
  listSource: ListSource;
  pageNumbers:any;

  displayedColumns = ['firstName', 'email', 'login', 'branchCode', 'role', 'isActivated', 'action'];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  userSearchForm: FormGroup;
  userSearchCriteria: UserSearchCriteria;
  showOptions: string[] = ['ACTIVE', 'ALL'];


  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog) { }

  ngOnInit() {
    
    this.userSearchForm = this.formBuilder.group({
      login: null,
      branchCode: null,
      show:'ACTIVE'
    });
    this.dataSource = new UserListDataSource(this.userService);
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);

    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        // Load paging and sort params from url
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        this.getUser();
    });
  }
  
    getUser(event?: any, userSearch ?: string) {
      if(userSearch === 'onClickUserSearch'){
        this.pagingAndSortParams.pageIndex = 0;
      }

      if(event != undefined && event != null){
        this.paginator.pageIndex = event;
        this.pagingAndSortParams.pageIndex = event;
      }

      this.generateUserSearchCriteria();
      
      let sortCriteria = 'firstName' + ',' + 'asc';
      this.dataSource.loadUsers(sortCriteria, this.pagingAndSortParams.pageIndex, 
        this.pagingAndSortParams.pageSize, this.userSearchCriteria);
        setTimeout(()=>{      
          let count= this.dataSource.totalCount$.value
          this.pageNumbers = this.paginator.getNumberOfPages()
         
         // let number=( count+ this.pagingAndSortParams.pageSize - 1) / this.pagingAndSortParams.pageSize 
        //  this.pageNumbers=Array.from(Array(pagecount).keys())
    
        },1000)
        this.pageNumbers = this.paginator.getNumberOfPages()
    }

  generateUserSearchCriteria(){
    this.userSearchCriteria = {
        login: this.userSearchForm.value.login,
        branchCode: this.userSearchForm.value.branchCode,
        show: this.userSearchForm.value.show
    }
}

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  ngAfterViewInit(): void {
    // initiate sort and pagination change once the view is initialized
    this.listSource.subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadUsersPageWithParams());
  }

  loadUsersPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  deleteDialog(userData: any) {
    const dialogRef = this.dialog.open(UserDeleteDialogComponent, {
       data: userData
    });

    dialogRef.afterClosed().pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      this.ngOnInit();
    });
  }

}
