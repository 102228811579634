import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JhiLanguageService, AlertService} from 'ng-jhipster';
import { DateConverterService } from '../shared/date/date-converter.service';
import { LoanOdWriteOffDtoModel } from './loan-od-write-off-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-recovery',
    templateUrl: './loan-od-accounts-recovery.component.html'
})
export class LoanOdAccountsRecoveryComponent implements OnInit, OnDestroy {
    proceedIsTrue: boolean;
    proceedToResult: boolean;
    showTemplate: boolean;
    writeOffDto: LoanOdWriteOffDtoModel;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private dateConverter: DateConverterService,
        private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.proceedIsTrue = false;
        this.proceedToResult = false;
    }
    ngOnInit() {
        this.writeOffDto = this.route.snapshot.data['writeOffDto'];
        this.writeOffDto.valueDate = this.dateConverter.fromServerToNgbDatePicker(this.writeOffDto.valueDate);
        this.showTemplate = true;
    }

    ngOnDestroy() {
    }

    clear() {
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    proceed() {
        this.writeOffDto.valueDate = this.dateConverter.fromNgbDatePickerToDateObject(this.writeOffDto.valueDate);
        this.proceedIsTrue = true;
    }

    back() {
        this.writeOffDto.valueDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.writeOffDto.valueDate);
        this.proceedIsTrue = false;
    }

    executeError() {
        this.writeOffDto.valueDate = this.dateConverter.fromStringToNgbDatePicker(this.writeOffDto.valueDate);
        this.proceedIsTrue = false;
    }

    executeSuccess() {
        this.alertService.success('encoreclientApp.loanOdAccounts.writeOffRecoverySuccess', {accountId : this.writeOffDto.accountId});
        this.router.navigate(['/loans/loan-od-accounts']);
    }
}
