import { Component, Input, OnInit } from "@angular/core";
import { BankAccountVerificationResponseComposite } from "@app/applications/application-details/business/businessBankAccount/business-bank-accounts.model";
import { RowValueDto } from "@app/applications/applications.model";

@Component({
  selector: "eng-validation-errors",
  templateUrl: "validation-error.template.html",
  styleUrls: ["../application-details.styles.scss"],
})
export class ValidationErrorsComponent implements OnInit {
  @Input()
  messageList: string[];
  // validationType are of two types one is "warning" and another is "error"
  @Input()
  validationType: string;

  constructor( ) { }

  ngOnInit() {
    
  }

}