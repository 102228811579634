import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationService } from "@app/applications/application.service";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { OnDestroy } from "@angular/core";
import { ValidationService } from "@app/applications/application-validators/validation.service";
import { FeePaymentDto } from "@app/applications/application-details/loanInformation/feePayment/fee-payment.model";
import { NameValueDto } from "@app/loan-od-accounts/name-value-dto";
import { ReferenceCodeService } from "@app/admin/reference-code/reference-code.service";
import { ReferenceCode } from "@app/admin/reference-code/reference-code.model";
@Component({
  selector: "eng-book-voucher",
  templateUrl: "book-voucher.template.html",
  styleUrls: [
    "../../../application-details.styles.scss",
    "../xdata-service.style.scss",
  ],
})
export class BookVoucherComponent implements OnInit, OnDestroy {
  applicationUid: string;

  bookVoucherForm: FormGroup;
  voucherResponse: any;
  isShowResultCard: boolean;
  feePayments: FeePaymentDto[];
  selectedFeePayment:FeePaymentDto;
  debitGlAccount: NameValueDto;
  creditGlAccount: NameValueDto;
  voucherGl: NameValueDto[];
  feeTypeCategories: ReferenceCode[]
  paymentMethodMap: Map<string, any> = new Map();
  subCategoryMap: Map<string, any> = new Map();
  voucherMap: Map<string, any> = new Map();

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private validationService: ValidationService,
    private referenceCodeService:ReferenceCodeService
  ) {
    this.bookVoucherForm = this.formBuilder.group({
      feeType: ["", Validators.required],
    });
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.applicationUid;
      });
  }

  ngOnInit() {
    this.getRefCodes();
  }

  getRefCodes(){
    this.referenceCodeService.getShortRefCodes('voucher_gl').subscribe((response: any) => {
      this.voucherGl = response.voucher_gl;
      this.getPaymentMethodRefcode();
      response.voucher_gl.forEach((element: any) => {
        this.voucherMap.set(element.code, element);
      });
    });
  }

    getPaymentMethodRefcode(){
    this.referenceCodeService.getRefCodesForClassifier('fees_and_charges_payment_method').subscribe((response: any) => {
      this.getFeeAndChargesSubCategoryRefCode();
      response.forEach((element: any) => {
        this.paymentMethodMap.set(element.code, element);
      });
    });
  }

  getFeeAndChargesSubCategoryRefCode(){
    this.referenceCodeService.getRefCodesForClassifier('fees_and_charges_subcategory').subscribe((response: any) => {
      this.feeTypeCategories =response;
      this.fetchFeePayment()
      response.forEach((element: any) => {
        this.subCategoryMap.set(element.code, element);
      });
    });
  }

  onBookVoucherClick() {
    this.validationService.markFormGroupTouched(this.bookVoucherForm)
    if (this.bookVoucherForm.valid) {
      let feeUid = this.bookVoucherForm.controls.feeType.value.uid;
      this.applicationService.bookVoucher(this.applicationUid,feeUid).toPromise().then(
          (_success) => {
            this.voucherResponse = _success;
            this.isShowResultCard = true;
            this._snackbar.open("Voucher created successfully", "Close", {
              duration: 5000,
            });
          },
          (failure) => {
            this.isShowResultCard = false;
            this._snackbar.open(failure, "close", {
              duration: 5000,
            });
          }
        );
    }
  }

  fetchFeePayment() {
    this.applicationService.getFeesForApplication(
      this.applicationUid).subscribe((response: any) => {
          this.feePayments = response.body;

        // if the length is zero then there is no feePayment is captured to book voucher
        if (this.feePayments.length === 0 ) {
          this._snackbar.open(
            "Fee payment not available to book voucher." , "close",
            {
              duration: 5000,
            }
          );
          return;
        }
    
        // if there is only one feePayment is captured then we are pre-populating in the dropdown,
        // if there are more than one recored then user need to select from the dropdown 
        if (this.feePayments.length === 1 ) {
          this.bookVoucherForm.patchValue({
            feeType: this.feePayments[0]
          });
          this.onFeeTypeChange(this.feePayments[0])
        }
    })
  }

  onFeeTypeChange(feePayment){
    this.isShowResultCard = false;
    this.debitGlAccount = new NameValueDto;
    this.creditGlAccount = new NameValueDto;

    let paymentMethodRefcode = this.paymentMethodMap.get(feePayment.paymentMethod);
    let feeTypeSubCategoryRefcode = this.subCategoryMap.get(feePayment.subCategory);
  
    let debitGlAccountCode = paymentMethodRefcode ? paymentMethodRefcode.field3 : null;
    let creditGlAccountCode = feeTypeSubCategoryRefcode ? feeTypeSubCategoryRefcode.field3 : null;
  
    this.voucherMap.forEach((element: any) => {
      if (debitGlAccountCode === element.code) {
        this.debitGlAccount = element;
      }
  
      if (creditGlAccountCode === element.code) {
        this.creditGlAccount = element;
      }
    });

    this.selectedFeePayment = feePayment
  }

  ngOnDestroy(): void {}
}
