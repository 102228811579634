import {Money} from './money';

export class AccountPdcDtoModel {
    constructor(
        public id?: number,
        public version?: number,
        public accountId?: string,
        public pdcNum?: number,
        public chequeNo?: string,
        public bankName?: string,
        public bankAccountNo?: string,
        public branchName?: string,
        public ifscCode?: string,
        public micr?: string,
        public rejectReason?: string,
        public status?: string,
        public chequeDate?: any,
        public amount?: any,
        public transactionId?: string,
        public clearingDate?: any,
        public bankSubmissionDate?: any,
        public rejectionDate?: any,
        public selectToAssign?: boolean,
        public deleteRow?: boolean,
        public addRow?: number,
    ) {}
}
