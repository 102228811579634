import { Component, OnDestroy, OnInit } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, EventManager, JhiLanguageService, PaginationUtil, ParseLinks } from 'ng-jhipster';
import { Subscription } from 'rxjs';
//import { PaginationConfig } from '../../../blocks/config/uib-pagination.config';
import { ConfigureService } from '../build-config/configure.service';
import { ITEMS_PER_PAGE, Principal } from '../shared';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { saveAs } from 'file-saver';
//import { FileService } from '../../../files/file.service';

@Component({
    selector: 'jhi-loan-od-accounts-bulk-actions',
    templateUrl: './loan-od-accounts-bulk-actions.component.html'
})
export class LoanOdAccountsBulkActionsComponent implements OnInit, OnDestroy {

    loanOdAccounts: LoanOdAccounts[];
    ENCORE_BASE_URL: string;
    currentAccount: any;
    branchCode: string;

    error: any;
    success: any;

    eventSubscriber: Subscription;
    routeData: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    aggregations: any;
    isSearch: any;
    filesToUpload: any;

    constructor(private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private parseLinks: ParseLinks,
        private alertService: AlertService,
        private principal: Principal,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eventManager: EventManager,
        private paginationUtil: PaginationUtil,
        //private paginationConfig: PaginationConfig,
        configService: ConfigureService,
        //private fileService: FileService
        ) {
        this.loanOdAccounts = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            this.page = data['pagingParams'].page;
            this.previousPage = data['pagingParams'].page;
            this.reverse = data['pagingParams'].ascending;
            this.predicate = data['pagingParams'].predicate;
        });
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.filesToUpload = {
            accountOpening: '',
            disbursements: '',
            repayments: '',
            securitization: '',
            transactions: '',
            customerBranchTransfer: '',
            retrospect: ''
        }
        this.ENCORE_BASE_URL = configService.config.ENCORE_BASE_URL;
        this.branchCode = '';
    }

    loadAll() {

        this.loanOdAccountsService.query({
            page: this.page - 1,
            size: this.itemsPerPage,
            sort: this.sort()
        }).subscribe(
            (res: Response) => this.onSuccess(res.json(), res.headers),
            (res: Response) => this.onError(res.json())
        );
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        this.router.navigate(['/loan-od-accounts'], {
            queryParams:
            {
                page: this.page,
                size: this.itemsPerPage,
                sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
            }
        });
        this.loadAll();
    }

    clear() {
        this.page = 1;
        this.router.navigate(['/loan-od-accounts/bulkActions', {
            page: this.page,
            sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
        }]);
        this.loadAll();
    }
    setTags() {
        this.router.navigate(['/loans/loan-od-accounts/setTags']);
    }
    assignPostDatedCheques() {
        this.router.navigate(['/loans/loan-od-accounts/assignPostDatedCheques']);
    }
    rejectPostDatedCheques() {
        this.router.navigate(['/loans/loan-od-accounts/rejectPostDatedCheques']);
    }
    disbursments() {
        this.router.navigate(['/loans/loan-od-accounts/disbursments']);
    }
    collections() {
        this.router.navigate(['/loans/loan-od-accounts/collections']);
    }
    electronicMandate() {
        this.router.navigate(['/loans/loan-od-accounts/electronicMandate']);
    }
    checkTransactions() {
        this.router.navigate(['/loans/loan-od-accounts/checkTransactions']);
    }
    accountApprovals() {
        this.router.navigate(['/loans/loan-od-accounts/makerChecker']);
    }
    transactionsApprovals() {
        this.router.navigate(['/loans/loan-od-accounts/makerCheckerTransactions']);
    }
    ngOnInit() {
        this.loadAll();
        this.principal.identity().then((account) => {
            this.currentAccount = account;
        });
        this.registerChangeInProfiles();
    }

    ngOnDestroy() {
        // this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: LoanOdAccounts) {
        return item.id;
    }

    registerChangeInProfiles() {
        // this.eventSubscriber = this.eventManager.subscribe('loanOdAccountsListModification', (response) => this.loadAll());
    }
    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }
    private onSuccess(data, headers) {

        this.loanOdAccounts = data;

    }

    private onError(error) {
        this.alertService.error('encoreclientApp.loanOdAccounts.error');
    }

    onFileChange(event, key, branchCode?: string) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.filesToUpload[key] = file;
        }
    }

    private prepareSave(key: string): any {
        const input: FormData = new FormData();
        input.append(key, this.filesToUpload[key], this.filesToUpload[key].name);
        if (key === 'customerBranchTransfer') {
            input.append('branchCode', this.branchCode);
        }
        return input;
    }
    fileName:string = '';
    onSubmit(key: string) {
        if (this.filesToUpload[key]) {
            const formModel = this.prepareSave(key);
            this.loanOdAccountsService.bulkUpload(formModel, key).subscribe(
                (res) => this.onUploadSuccess(res, key),
                (res) => this.onUploadError(res, key),
            );
        } else {
            this.alertService.error('encoreclientApp.loanOdAccounts.fileNotPresentError');
        }
        if (key === 'accountOpening') {
            // document.getElementById('downloadXLS').click();
              // download file
        // this.fileService.download().subscribe(
        //     res => {
                const blob = new Blob([this.filesToUpload[key]], { type : 'application/vnd.ms.excel' });
                const file = new File([blob], this.filesToUpload[key].name + '.xls', { type: 'application/vnd.ms.excel' });
                saveAs(file);
            // },
            // res => {
            //     // notify error
            // }
        // );
        }
    }

    private onUploadSuccess(res, key) {
        if (key === 'accountOpening') {
            this.alertService.success('encoreclientApp.loanOdAccounts.uploadAccountOpening');
        }
        if (key === 'disbursements') {
            this.alertService.success('encoreclientApp.loanOdAccounts.uploadDisbursementsSuccess');
        }
        if (key === 'repayments') {
            this.alertService.success('encoreclientApp.loanOdAccounts.uploadRepaymentsSuccess');
        }
        if (key === 'securitization') {
            this.alertService.success('encoreclientApp.loanOdAccounts.uploadSecuritizationSuccess');
        }
        if (key === 'transactions') {
            this.alertService.success('encoreclientApp.loanOdAccounts.uploadTransactionsSuccess');
        }
        if (key === 'customerBranchTransfer') {
            this.alertService.success('encoreclientApp.loanOdAccounts.uploadCustomerBranchTransferSuccess');
        }
        if (key === 'retrospect') {
            try {
                const retrospectList = res.text();
                this.alertService.success('encoreclientApp.loanOdAccounts.uploadRetrospectSuccess', { retrospectList });
            } catch (e) {
                this.alertService.success('encoreclientApp.loanOdAccounts.uploadRetrospectSuccessAlt');
            }
        }
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    private onUploadError(error, key) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.errorMessage(errorString, key);
            } else {
                this.errorMessage('', key);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.errorMessage(errorString, key);
                } else {
                    this.errorMessage('', key);
                }
            } catch (exception) {
                this.errorMessage('', key);
            }
        }
        if (key !== 'accountOpening'){
            this.router.navigate(['/loans/loan-od-accounts']);
        }
    }

    private errorMessage(errorString, key) {
        if (key === 'accountOpening') {
            this.alertService.error('encoreclientApp.loanOdAccounts.bulkErrorAccountOpening', { message: errorString });
        }
        if (key === 'disbursements') {
            this.alertService.error('encoreclientApp.loanOdAccounts.bulkErrorDisbursement', { message: errorString });
        }
        if (key === 'repayments') {
            this.alertService.error('encoreclientApp.loanOdAccounts.bulkErrorRepayment', { message: errorString });
        }
        if (key === 'securitization') {
            this.alertService.error('encoreclientApp.loanOdAccounts.bulkErrorSecuritization', { message: errorString });
        }
        if (key === 'transactions') {
            this.alertService.error('encoreclientApp.loanOdAccounts.bulkErrorTransaction', { message: errorString });
        }
        if (key === 'customerBranchTransfer') {
            this.alertService.error('encoreclientApp.loanOdAccounts.bulkErrorCustomerBranchTransfer', { message: errorString });
        }
        if (key === 'retrospect') {
            this.alertService.error('encoreclientApp.loanOdAccounts.bulkErrorRetrospect', { message: errorString });
        }
    }
}
