import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { SessionStorageService} from 'angular-web-storage';
import { MatSnackBar } from '@angular/material';
import { SecureStorageService} from '../../../projects/ig-core/src/lib/authentication/secureStorage.service';
@Injectable({
  providedIn: 'root'
})
export class AdminRouteGuardService implements CanActivate {

  constructor(private secureStorageService: SecureStorageService,
    private _snackbar: MatSnackBar,) { }

  public canActivate(route: ActivatedRouteSnapshot){
    let user = this.secureStorageService.secureSessionStorage.getItem("logInUserDetails")
    if(user.authorities.includes('ROLE_ADMIN')){
      return true;
    }else{
        this._snackbar.open("Permission denied ", "Close", {
            duration: 2000,
          })
    }
    return false;
  }
}
