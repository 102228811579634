import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable} from 'rxjs';
import { BranchSetService } from './branch-set.service';
import { BranchSet } from './branch-set.model';

@Injectable({
    providedIn: 'root'
})
export class BranchSetResolverService implements Resolve<BranchSet> {
    constructor(private branchSetService: BranchSetService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BranchSet> | Observable<never> {
        return this.branchSetService.getBranchSet(+route.paramMap.get('id'));
    }
}
