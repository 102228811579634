import {LoanOdAccountsService} from './loan-od-accounts.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {LoanOdAccounts} from './loan-od-accounts.model';

@Injectable()
export class LoanOdAccountsDisburseExecuteResolve implements Resolve<LoanOdAccounts> {

    constructor(private loanOdAccountsService: LoanOdAccountsService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('loanOdAccounts')) {
            return this.loanOdAccountsService.disburseExecute(route.paramMap.get('loanOdAccounts'));
        } else {
            return new LoanOdAccounts();
        }
    }
}
