export default class FunctionUtils {

  static physicalMemoryUnits = {'B': 0,
    'KB': 1, 'MB': 2, 'GB': 3,
    'TB': 4, 'PB': 5};

  static convertNumberToPhysicalMemory(_memory: number,
                                       _precision: number = 2,
                                       _unit: string = 'B'): number {
    let memory = _memory;
    const unit = this.physicalMemoryUnits[_unit];
    if (!unit) {
      return memory;
    }
    for (let i = 0; i < unit; i++) {
      memory /= 1024;
    }
    /*if(memory <= unit){
      return memory;
    }*/
    /*memory /= unit;*/
    return parseFloat(memory.toFixed(+ _precision));
  }
}


