
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { LoanApplication, Person, LoanProduct, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { BookLoanValidate, EngrowEncoreValidationInfo } from './book-loan.model'
@Component({
  selector: 'eng-book-loan',
  templateUrl: './book-loan.component.html',
  styleUrls: ['../../application-details.styles.scss','./book-loan.component.css']
})
export class BookLoanComponent implements OnInit {
  @Input() application: LoanApplication;
  @Input()
  menuCode: string;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  allowAccess: Boolean;
  menuItemAllowAccess: boolean;

  bookLoanValidateDetails: BookLoanValidate;
  engrowEncoreValidateDetails: EngrowEncoreValidationInfo[];

    //this to pass data to parent component when application data chnaged
    @Output() applicationDataChanged: EventEmitter<object> = new EventEmitter();

  constructor(
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    console.log("application",this.application)
    this.bookLoanValidate()
    this.getMenuItemAccess()
    this.getEngrowEncoreInfo()
    
  }

  bookLoanValidate() {
    this.applicationService.loanBookValidate(this.application.uid).subscribe(response => {
      this.bookLoanValidateDetails = response.body
    })
  }
  bookLoan() {
    this.applicationService.bookLoan(this.application.uid).toPromise().then(
      (_success) => {
        this._snackbar.open(_success.status + ": " + _success.message, "Close", {
          duration: 15000,
        });
        // to update applicationDTO
        this.getApplicationInfo();
      }, (failure) => {
        let errormesg =[]
            errormesg.push("Failed to book loan application")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
        console.log(failure);
      }
    );
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  getEngrowEncoreInfo() {
    this.applicationService.getEngrowEncoreInfo(this.application.uid).subscribe(response => {
      this.engrowEncoreValidateDetails = response.body
    })
  }

  // after loan booked, we need to show accountOpenDate in booking details
  // loan book api doesnot send accountOpenDate in the resposne.
  // so application data is fetched again and updated. And this is emmited to application details.
  getApplicationInfo(){
    this.applicationService.getBasicApplicationInfo(
      this.application.uid, 'ApplicationOnly').subscribe((response) => {
        this.application = response.body.applicationDTO;
        this.applicationDataChanged.emit(this.application);
      })
  }
}
