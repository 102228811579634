import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'insertErrorMessage'
})
export class InsertErrorMessagePipe implements PipeTransform {
    transform(data: any): string {
        let value=''
        for (var propName in data) {
            if (data.hasOwnProperty(propName)) {
                value = data[propName];
            }
        }
        return value;
    }
}
