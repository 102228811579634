import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';

@Component({
  selector: 'ig-error-bottomsheet',
  templateUrl: './error-bottomsheet.component.html',
  styleUrls: ['./error-bottomsheet.component.css']
})
export class ErrorBottomsheetComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
  }

}
