import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService, JhiLanguageService } from 'ng-jhipster';
import { Router } from '@angular/router';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { DateConverterService } from '../shared/date/date-converter.service';
import { ApplicationDateService } from '../shared/date/application-date.service';
import { MoneyWithCurrencyPipe } from '../shared/moneyWithCurrency.pipe';
import { LoanOdDisbursementComposite } from './loan-od-disbursement-composite.model';
import moment from 'moment';

@Component({
    selector: 'jhi-loan-od-accounts-disburse-execute',
    templateUrl: './loan-od-accounts-disburse-execute.component.html'
})
export class LoanOdAccountsDisburseExecuteComponent implements OnInit, OnDestroy {

    dateSubscription: Subscription;
    loanOdAccounts: LoanOdAccounts;
    @Input() compositeDto: LoanOdDisbursementComposite;
    @Output() executeError: EventEmitter<any> = new EventEmitter<any>();
    @Output() previousState: EventEmitter<any> = new EventEmitter<any>();
    @Output() executeSuccess: EventEmitter<any> = new EventEmitter<any>();
    backDatedTransaction: boolean;
    currentWorkingDate: any;

    constructor(private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private router: Router,
        private alertService: AlertService,
        private applicationDateService: ApplicationDateService,
        private dateConverter: DateConverterService,
        private moneyWithCurrencyPipe: MoneyWithCurrencyPipe) {
    }

    ngOnInit() {
        this.compositeDto.fees.forEach((fee) => {
            if (fee.grossAmount.magnitude === -1) {
                fee.grossAmount.magnitude = null;
                fee['inputRequired'] = true;
            }
        });
        this.calculateNetDisbursementAmount();
        this.dateSubscription = this.applicationDateService.getDate().subscribe(
            (date) => {
                this.currentWorkingDate = date;
                this.isBackDated();
            }
        );
    }

    calculateNetDisbursementAmount() {
        let netDisbursementAmount: number = this.compositeDto.disbursement.amount.magnitude;
        netDisbursementAmount = this.compositeDto.securityDeposit ? netDisbursementAmount -= this.compositeDto.securityDeposit.magnitude : netDisbursementAmount;
        this.compositeDto.fees.forEach((fee) => {
            if (!fee.capitalized && fee.paymentStatus) {
                netDisbursementAmount -= fee.grossAmount.magnitude;
            }
        });
        this.compositeDto.netDisbursementAmount = {
            magnitude: netDisbursementAmount,
            displayValue: this.moneyWithCurrencyPipe.transform(netDisbursementAmount)
        }
    }

    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
    }

    private isBackDated() {
        const currentDate = this.dateConverter.fromServerToDateObject(this.currentWorkingDate);
        if (this.compositeDto.disbursement.valueDate < currentDate) {
            this.backDatedTransaction = true;
        }
    }

    execute() {
        if (this.compositeDto.netDisbursementAmount.magnitude < 0) {
            this.alertService.error('Net Disbursement Amount cannot be negative.');
        } else {
            this.loanOdAccountsService.disbursementResult(this.compositeDto).subscribe((res) =>
                this.onSaveSuccess(res), (res) => this.onSaveError(res));
        }
    }

    private onSaveSuccess(result) {
        this.executeSuccess.emit(result);
    }


    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }

    private onSaveError(error) {
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
        this.executeError.emit('error');
    }


    private onError(error) {
        this.alertService.error(error.error);
    }

    back() {
        this.previousState.emit('back');
    }
    save() {
    }
}

