import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, EventManager, JhiLanguageService } from 'ng-jhipster';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdMoratoriumDtoModel } from './loan-od-moratorium-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-delete-moratorium-execute',
    templateUrl: './loan-od-accounts-delete-moratorium-execute.component.html'
})
export class LoanOdAccountsDeleteMoratoriumExecuteComponent implements OnInit, OnDestroy {

    @Input() loanOdMoratoriumDtoModel: LoanOdMoratoriumDtoModel;
    @Output() executeError: EventEmitter<any> = new EventEmitter<any>();
    @Output() previousState: EventEmitter<any> = new EventEmitter<any>();
    @Output() executeSuccess: EventEmitter<any> = new EventEmitter<any>();

    constructor(  private jhiLanguageService: JhiLanguageService,
                  private alertService: AlertService,
                  private loanODAccountsService: LoanOdAccountsService,
                  private eventManager: EventManager,
                  private route: ActivatedRoute,
                  private router: Router
    ) {
    }
    ngOnInit() {

    }
    ngOnDestroy() {
    }

    private onSaveError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
            this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
        this.executeError.emit('error');
    }

    private onError(error) {
        this.alertService.error('encoreclientApp.loanOdAccounts.genericError');
    }

    clear() {
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    back() {
        this.previousState.emit('back');
    }

    private onSaveSuccess(result) {
        this.executeSuccess.emit(result);
    }

    execute() {
        this.loanODAccountsService.deleteMoratorium(this.loanOdMoratoriumDtoModel).subscribe(
            (res) => this.onSaveSuccess(res),
            (res) => this.onSaveError(res)
        );
    }
}
