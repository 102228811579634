import { NgModule } from '@angular/core';
import { ReferenceCodeShowNamePipe} from './reference-code-show-name.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { InsertErrorMessagePipe } from './validation-error-message.pipe';
import {MyFilterPipe} from './my-filter.pipe';
import {ClickOutsideDirective} from './click-outside.directive'
import { NewLineFilterPipe } from './newline-filter.pipe';
@NgModule({
    imports: [],
    declarations: [ReferenceCodeShowNamePipe, DateFormatPipe, InsertErrorMessagePipe,MyFilterPipe,ClickOutsideDirective,NewLineFilterPipe],
    exports: [ReferenceCodeShowNamePipe, DateFormatPipe, InsertErrorMessagePipe,MyFilterPipe,ClickOutsideDirective,NewLineFilterPipe],
    providers:[DateFormatPipe, InsertErrorMessagePipe,MyFilterPipe,ClickOutsideDirective]
})
export class AllPipesModule { }