import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialLibModule } from '@app/material-lib.module';
import { EditableResolveService } from '@ig-core/resolves/editable-resolve.service';
import { NonEditableResolveService } from '@ig-core/resolves/non-editable-resolve.service';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { DeviationApprovalEditComponent } from './deviation-approval-edit/deviation-approval-edit.component';
import { DeviationApproval } from './deviation-approval.component';

export const deviationApprovalRoute: Route = {
  path: 'deviation-approval',
  canActivate: [AuthenticatedUserService],
  children: [
    { path: '', component: DeviationApproval },
    {
      path: 'create', component: DeviationApprovalEditComponent,
      resolve: {
        editable: EditableResolveService
      }
    },
    {
      path: 'update/:uid', component: DeviationApprovalEditComponent,
      resolve: {
        editable: EditableResolveService
      }
    },
    {
      path: 'view/:uid', component: DeviationApprovalEditComponent,
      resolve: {
        editable: NonEditableResolveService
      }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [DeviationApprovalEditComponent],
  entryComponents: []
})
export class DeviationApprovalModel { }
