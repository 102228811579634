import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdMoratoriumDtoModel } from './loan-od-moratorium-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-amend-moratorium-execute',
    templateUrl: './loan-od-accounts-amend-moratorium-execute.component.html'
})
export class LoanOdAccountsAmendMoratoriumExecuteComponent implements OnInit, OnDestroy {

    dateSubscription: Subscription;
    @Input() moratorium: LoanOdMoratoriumDtoModel;
    @Input() moratoriumTypeList: any[];
    @Output() executeError: EventEmitter<any> = new EventEmitter<any>();
    @Output() previousState: EventEmitter<any> = new EventEmitter<any>();
    @Output() executeSuccess: EventEmitter<any> = new EventEmitter<any>();

    constructor(private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private router: Router,
        private alertService: AlertService) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    execute() {
        this.loanOdAccountsService.amendMoratoriumResult(this.moratorium).subscribe(() => this.onSaveSuccess(), (res) => this.onSaveError(res));
    }

    private onSaveSuccess() {
        this.executeSuccess.emit(this.moratorium.accountId);
    }

    clear() {
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    private onSaveError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
                this.onError();
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError();
                }
            } catch (exception) {
                this.onError();
            }
        }
    }

    private onError() {
        this.alertService.error('encoreclientApp.loanOdAccounts.genericError');
    }

    back() {
        this.previousState.emit('back');
    }

    proceed() {

    }
}
