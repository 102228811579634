import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';
import { CollectionViewer } from '@angular/cdk/collections';

import { LeadLite } from './leads-lite.model';
import { LeadsService } from './leads.service';
import { LeadSearchCriteria } from './leads.model';

export class LeadsDataSource extends DataSource<LeadLite> {

  public leadsSubject = new BehaviorSubject<LeadLite[]>([]);
  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private leadsService: LeadsService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<LeadLite[]> {
    return this.leadsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.leadsSubject.complete();
    this.totalCount$.complete();
  }

  getLeads(sortDirection = 'asc', pageIndex = 0, pageSize = 10,
      searchCritera: LeadSearchCriteria) {
    this.leadsService.getLeads({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }, searchCritera).pipe(
      catchError(() => of([])),
      finalize(() => { })
    ).pipe(take(1)).subscribe((response) => {

      // To calculate : Turnaround Time = statusDate - currentDate
      response['body'].content.forEach(element => {
        /* If statusDate is not null then use statusDate to calculate turnAroundTime */
        if(element.statusDate != null){
         let dt1 = new Date(element.statusDate);
          let dt2 = new Date();
          let turnAroundDate = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
          element["turnAroundTime"] = turnAroundDate;
        } else { /* If statusDate is null then use initiatedOn for turnAroundTime */
          let dt1 = new Date(element.initiatedOn);
          let dt2 = new Date();
          let turnAroundDate = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
          element["turnAroundTime"] = turnAroundDate;
        }
      }); 
      
      this.totalCount$.next(response['body'].totalElements);
      this.leadsSubject.next(response['body'].content);
    });
  }
}
