export class Money {
    constructor(
        public currency?: string,
        public value?: number,
        public magnitude?: number,
        public displayValue?: String
    ) {
        this.value = 0;
        this.magnitude = 0;
        this.displayValue = null;
    }
}
