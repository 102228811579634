import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ZonesComponent } from './zones.component';
import { AuthenticatedUserService } from '../../../projects/ig-core/src/lib/authentication/authenticated-user-service';
import { ROTripViewComponent } from './ro-trip-view/ro-trip-view.component';
import { ZoneResolverService } from './zone-resolve.service';
import { ZoneRouteGuardService } from './zone-route-guard.service';

const routes: Routes = [
  {
    path: 'zones',
    canActivate: [AuthenticatedUserService, ZoneRouteGuardService],
    children: [
      { path: '', component: ZonesComponent },
      { path: 'roTripView', component: ROTripViewComponent,
        resolve: { zone: ZoneResolverService }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ZonesRoutingModule { }
