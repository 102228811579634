import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JhiLanguageService, AlertService} from 'ng-jhipster';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { DateConverterService } from '../shared/date/date-converter.service';
import {LoanOdMoratoriumDtoModel} from './loan-od-moratorium-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-create-moratorium',
    templateUrl: './loan-od-accounts-create-moratorium.component.html'
})
export class LoanOdAccountsCreateMoratoriumComponent implements OnInit, OnDestroy {
    moratorium: LoanOdMoratoriumDtoModel;
    proceedIsTrue: boolean;
    proceedToResult: boolean;
    moratoriumTypeList: any[];
    showTemplate: boolean;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private dateConverter: DateConverterService,
        private loanODAccountsService: LoanOdAccountsService,
        private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService,
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        this.proceedIsTrue = false;
        this.proceedToResult = false;
    }
    ngOnInit() {
        this.moratorium = this.route.snapshot.data['moratorium'];
        this.moratorium.valueDate = this.dateConverter.fromServerToNgbDatePicker(this.moratorium.valueDate);
        this.moratorium.startDate = this.dateConverter.fromServerToNgbDatePicker(this.moratorium.startDate);
        this.loanODAccountsService.getMoratoriumTypes().subscribe(
            (res) => {
                this.moratoriumTypeList = res.json();
                this.moratorium['selectedMoratoriumType'] = this.moratoriumTypeList[0];
                this.moratorium.moratoriumType = this.moratoriumTypeList[0].code;
                this.showTemplate = true;
            }
        );
    }

    ngOnDestroy() {
    }

    clear() {
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    proceed(moratorium) {
        this.moratorium.valueDate = this.dateConverter.fromNgbDatePickerToDateObject(this.moratorium.valueDate);
        this.moratorium.startDate = this.dateConverter.fromNgbDatePickerToDateObject(this.moratorium.startDate);
        this.proceedIsTrue = true;
    }

    back() {
        this.moratorium.valueDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.moratorium.valueDate);
        this.moratorium.startDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.moratorium.startDate);
        this.proceedIsTrue = false;
    }

    executeError() {
        this.moratorium.valueDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.moratorium.valueDate);
        this.moratorium.startDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.moratorium.startDate);
        this.proceedIsTrue = false;
    }

    executeSuccess(result) {
        this.moratorium = result;
        this.alertService.success('encoreclientApp.loanOdAccounts.moratorium.createSuccess', {accountId: this.moratorium.accountId});
        this.proceedToResult = true;
    }
}
