import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EventManager, JhiLanguageService } from 'ng-jhipster';

import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsPopupService } from './loan-od-accounts-popup.service';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-delete-dialog',
    templateUrl: './loan-od-accounts-delete-dialog.component.html'
})
export class LoanOdAccountsDeleteDialogComponent {

    loanOdAccounts: LoanOdAccounts;

    constructor(
        private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        public activeModal: NgbActiveModal,
        private eventManager: EventManager
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.loanOdAccountsService.delete(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'loanOdAccountsListModification',
                content: 'Deleted an loanOdAccounts'
            });
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'jhi-loan-od-accounts-delete-popup',
    template: ''
})
export class LoanOdAccountsDeletePopupComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private loanOdAccountsPopupService: LoanOdAccountsPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.modalRef = this.loanOdAccountsPopupService
                .open(LoanOdAccountsDeleteDialogComponent, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
