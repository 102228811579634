
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseRequestOptions, URLSearchParams } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationConfig } from '@app/blocks/ui-pagination.config';
import { ApplicationDateService } from '@app/shared/date/application-date.service';
import { EventManager, ParseLinks } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { Principal } from '../shared/auth/principal.service';
import { ITEMS_PER_PAGE } from '../shared/constants/pagination.constants';
import { DateConverterService } from '../shared/date/date-converter.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({ 
    selector: 'jhi-loan-od-accounts',
    templateUrl: './loan-od-accounts.component.html'
})
export class LoanOdAccountsComponent implements OnInit, OnDestroy {

    currentAccount: any;
    loanOdAccounts: LoanOdAccounts[];
    error: any;
    success: any;
    eventSubscriber: Subscription;
    currentSearchString: string;
    currentSearch: {
        accountId: string;
        productCode: string;
        branchCode: string;
        glSubHead: string;
        customerName: string;
        startDate: any;
        endDate: any;
        operationalStatus: string;
        guarantorCustomerId: any;
        customerId: any
    }
    routeData: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    aggregations: any;
    isSearch: any;
    productCodeList: any[];
    glSubHeadsList: any[];
    operationStatusList: any[];
    searchString: string;

    constructor(
        // private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private parseLinks: ParseLinks,
        // private alertService: AlertService,
        private principal: Principal,
        private applicationService: ApplicationDateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eventManager: EventManager,
        private paginationConfig: PaginationConfig,
        private dateConverter: DateConverterService) {
        this.loanOdAccounts = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            this.page = data['pagingParams'].page;
            this.previousPage = data['pagingParams'].page;
            this.reverse = data['pagingParams'].ascending;
            this.predicate = data['pagingParams'].predicate;
            this.itemsPerPage = data['pagingParams'].size;
            this.searchString = data['pagingParams'].search;
        });
       // this.jhiLanguageService.setLocations(['loanOdAccounts']);
        const searchParams = new URLSearchParams(this.searchString);
        this.currentSearch = {
            accountId: searchParams.get('accountId') ? searchParams.get('accountId') : '',
            productCode: searchParams.get('productCode') ? searchParams.get('productCode') : '',
            branchCode: searchParams.get('branchCode') ? searchParams.get('branchCode') : '',
            glSubHead: searchParams.get('glSubHead') ? searchParams.get('glSubHead') : '',
            customerName: searchParams.get('customerName') ? searchParams.get('customerName') : '',
            startDate: searchParams.get('startDate') ? searchParams.get('startDate') : undefined,
            endDate: searchParams.get('endDate') ? searchParams.get('endDate') : undefined,
            operationalStatus: searchParams.get('operationalStatus') ? searchParams.get('operationalStatus') : '',
            guarantorCustomerId: searchParams.get('guarantorCustomerId') ? searchParams.get('guarantorCustomerId') : '',
            customerId: searchParams.get('customerId') ? searchParams.get('customerId') : '',
        };
        
        this.setCurrentSearchString();
        applicationService.syncDate();
    }

    loadAll() {
        if (this.currentSearch.startDate && (typeof (this.currentSearch.startDate) !== 'string')) {
            this.currentSearch.startDate = this.dateConverter.fromNgbDatePickerToString(this.currentSearch.startDate);
        }
        if (this.currentSearch.endDate && (typeof (this.currentSearch.endDate) !== 'string')) {
            this.currentSearch.endDate = this.dateConverter.fromNgbDatePickerToString(this.currentSearch.endDate);
        }
        this.loanOdAccountsService.query({
            page: this.page - 1,
            size: this.itemsPerPage,
            query: this.currentSearch,
            sort: this.sort()
        }).subscribe(
            (res) => this.onSuccess(res),
            (res) => this.onError(res)
        );
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        this.router.navigate(['/loan-od-accounts'], {
            queryParams:
            {
                search: this.currentSearchString,
                page: this.page,
                size: this.itemsPerPage,
                sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
            }
        });
        this.loadAll();
    }

    clear() {
        this.page = 1;
        this.currentSearchString = '';
        this.currentSearch.branchCode = '';
        this.currentSearch.accountId = '';
        this.currentSearch.productCode = '';
        this.currentSearch.glSubHead = '';
        this.currentSearch.operationalStatus = '';
        this.currentSearch.customerName = '';
        this.currentSearch.startDate = undefined;
        this.currentSearch.endDate = undefined;
        this.currentSearch.customerId = '';
        this.currentSearch.guarantorCustomerId = '';
        this.transition();
    }

    search() {
        this.page = 1;
        this.setCurrentSearchString();
        this.transition();
    }

    setCurrentSearchString() {
        this.currentSearchString = '?';
        if (this.currentSearch) {
            for (const key of Object.keys(this.currentSearch)) {
                if (typeof (this.currentSearch[key]) === 'object') {
                    let month: any = 0;
                    let day: any = 0;

                    if (this.currentSearch[key].month < 10) {
                        month = '0' + this.currentSearch[key].month;
                    } else {
                        month = this.currentSearch[key].month;
                    }

                    if (this.currentSearch[key].day < 10) {
                        day = '0' + this.currentSearch[key].day;
                    } else {
                        day = this.currentSearch[key].day;
                    }

                    this.currentSearch[key] = this.currentSearch[key].year + '-' + month + '-' + day;
                }

                if (this.currentSearch[key] !== '' && this.currentSearch[key] !== undefined) {
                    this.currentSearchString += key + '=' + this.currentSearch[key] + '&';
                }
            }
        }
    }

    ngOnInit() {
        this.loadAll();
        this.loanOdAccountsService.getProductCodesList().subscribe(
            (res) => this.productCodeList = res
        );
        this.loanOdAccountsService.getGlSubHeadsList().subscribe(
            (res) => this.glSubHeadsList = res
        );
        this.loanOdAccountsService.getOperationalStatus().subscribe(
            (res) => this.operationStatusList = res
        );
        this.principal.identity().then((account) => {
            this.currentAccount = account;
        });
        this.registerChangeInLoanOdAccounts();
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: LoanOdAccounts) {
        return item.id;
    }

    registerChangeInLoanOdAccounts() {
        this.eventSubscriber = this.eventManager.subscribe('loanOdAccountsListModification', (response) => this.loadAll());
    }
    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    private onSuccess(data) {
        if ((typeof (this.currentSearch.startDate) === 'string') && this.currentSearch.startDate !== '') {
            this.currentSearch.startDate = this.dateConverter.fromStringToNgbDatePicker(this.currentSearch.startDate);
        }
        if ((typeof (this.currentSearch.endDate) === 'string') && this.currentSearch.endDate !== '') {
            this.currentSearch.endDate = this.dateConverter.fromStringToNgbDatePicker(this.currentSearch.endDate);
        }
        // this.links = this.parseLinks.parse(headers.get('link'));
        // this.totalItems = headers.get('X-Total-Count');
        this.totalItems = data.totalResults
        this.queryCount = this.totalItems;
        this.itemsPerPage = this.itemsPerPage > 50 ? 50 : this.itemsPerPage;
        this.loanOdAccounts = data.results;
    }

    private onError(error) {
        // this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: error.message });
        
    }

    private createRequestOption(req?: any): BaseRequestOptions {
        const options: BaseRequestOptions = new BaseRequestOptions();
        if (req) {
            const params: URLSearchParams = new URLSearchParams();
            params.set('page', req.page);
            params.set('size', req.size);
            if (req.sort) {
                params.paramsMap.set('sort', req.sort);
            }

            for (const key in req.query) {
                if (req.query[key] !== '' || req.query[key] !== 0) {
                    params.set(key, req.query[key]);
                }
            }
            options.search = params;
        }
        return options;
    }
}
