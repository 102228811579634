
import { FeeDto } from './loan-od-accounts-fee-dto.model';
import { LoanOdRepaymentItemsDto } from './loan-od-repayment-item-dto.model';

export class LoanOdRepaymentCompositeDto {
    constructor(
        public summary?: any,
        public repaymentItems?: LoanOdRepaymentItemsDto,
        public repayments?: LoanOdRepaymentItemsDto[],
        public fees?: FeeDto[],
        public accountId?: string,
        public tolerance?: string,
        public repaymentDate?: any,
        public transactionName?: string,
        public feeNumStr?: string,
        public instrument?: string,
        public reference?: any,
        public details?: string,
        public displayTotalFee?: any,
        public remarks?: string,
        public displayPreclosure?: any,
        public recoverableInterest?: any,
        public autoCloseWithSecurityDeposit?: any,
        public principalBalance?: any,
        public displayTotal?: any,
        public feePayments?: any,
        public displayInstrument?: any,
        public transactionSummaryDto?: any
    ) {}
}
