import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class NoteService {
    private API = 'api/notes';

    constructor(private http: HttpClient,
        public datepipe: DatePipe) {}
    saveNote(note:any): Observable<HttpResponse<any>>{
        const copy: any = Object.assign({}, note);
        if(note.linkToUid !== null){
            return this.http.post<any>(this.API, copy, {observe: 'response'});
        }
    }

    getNote(request:any){
        let params = new HttpParams();
        params =params.set('linkToUid', request.linkToUid );
        params = params.set('linkToType', request.linkToType);
        return this.http.get<any>(this.API+'/get-by-taskuid', {params, observe: 'response'});
    }

    updateNote(note:any): Observable<HttpResponse<any>>{
        const copy: any = Object.assign({}, note);
        return this.http.put<any>(this.API, copy,{observe: 'response'});
    }
}