import {Money} from './money';

export class TransactionSummaryDto {
    constructor(

        public transactionId?: string,
        public accountId?: string,
        public valueDate?: any,
        public transactionDate?: any,
        public systemDateAndTime?: string,
        public entityId?: string,
        public sequenceNum?: number,
        public transactionName?: string,
        public part1?: string,
        public part2?: string,
        public part3?: string,
        public part4?: string,
        public part5?: string,
        public part6?: string,
        public part7?: string,
        public part8?: string,
        public amount1?: string,
        public amount2?: string,
        public amount3?: string,
        public description?: string,
        public userId?: string,
        public customerName?: string,
        public status?: boolean,
        public responseCode?: string,
        public payeeAccountId?: string,
        public param1?: string,
        public param2?: any,
        public param3?: string,
        public instrument?: string,
        public reference?: string,
        public currencyCode?: string,
        public transactionLotId?: string,

    ) {}
}
