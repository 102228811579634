import { Money } from './money';
import { FeeDto } from './loan-od-accounts-fee-dto.model';
import { LoanOdDisbursementDto } from './loan-od-disbursement-dto.model';
import { Tenure } from './tenure';
export class LoanOdDisbursementComposite {
    constructor(
        public disbursement?: LoanOdDisbursementDto,
        public fee1?: FeeDto,
        public fee2?: FeeDto,
        public fee3?: FeeDto,
        public fee4?: FeeDto,
        public fee5?: FeeDto,
        public fee6?: FeeDto,
        public fee7?: FeeDto,
        public fee8?: FeeDto,
        public remarks?: string,
        public securityDeposit?: Money,
        public securityDepositBrokenPeriodInterest?: Money,
        public securityDepositAdvanceInterest?: Money,
        public securityDepositInstallment?: Money,
        public numDisbursements?: number,
        public firstRepaymentDate?: any,
        public minFirstRepaymentDate?: any,
        public maxFirstRepaymentDate?: any,
        public lastArchiveDate?: any,
        public linkedAccountPayOffAmount?: Money,
        public postExecute?: number,
        public fees?: any,
        public netDisbursementAmount?: any,
        public transactionSummaryDto?: any,
        public instrumentId?: number,
        public partyInfo?: string,
        public ifscCode?: string,
        public bankAccountNumber?: number,
        public bankName?: string,
        public bankBranchDetails?: string,
        public customerBankAccounts?: any,
        public userInstallment?: Money,
        public userTenure?: Tenure,
        public reversalReference?: string,
        public backValueDated?: boolean,
        public collectFeePayment?: any
    ) {

    }
}
