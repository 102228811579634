import { Component, Input, OnInit } from '@angular/core';
import { LoanApplication ,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { MatSnackBar } from '@angular/material';
import { ApplicationService } from '../../../application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { FamilyFinance } from '../financials.model';
import { ValidationService } from '../../../application-validators/validation.service'
@Component({
  selector: 'eng-family-expenses',
  templateUrl: './family-expenses.template.html',
  styleUrls: ['../../application-details.styles.scss', 'family-expenses.style.css']
})
export class FamilyExpensesComponent implements OnInit {
  
  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string
  familyExpensesList: FamilyFinance[];
  familyexpensesTypes: object;
  familyExpenseForm: FormGroup;
  
  isFormDisbled: Boolean = true;
  allowAccess:Boolean;
  cfaFinalized:boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(public toast: MatSnackBar,
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,
    private validationService: ValidationService) {
      this.allowAccess = this.applicationService.allowAccess; 
  }
  
  ngOnInit() {
    this.familyExpenseForm = this.formBuilder.group({
      expenseslist: this.formBuilder.array([]),
      familyExpansesSummaryForm: this.formBuilder.group({
        averageFamilyExpanses:null
      })
    })
    this.getCfaStatus()
    this.getRefCodes()
    this.getFamilyExpensesList()
    this.getMenuItemAccess();

  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('family_expense').subscribe((response: any) => {
      this.familyexpensesTypes = response.family_expense;
    });
  }

  getFamilyExpensesList() {
    this.applicationService.getFamilyExpenses(this.application.uid).subscribe((response) => {
      this.familyExpensesList = response.body;
     this.buildFormArrayWithData()
    })
  }
  buildFormArrayWithData(){
    const array = this.familyExpenseForm.controls.expenseslist as FormArray;
    this.familyExpensesList.forEach(element => {
      const group = new FormGroup({
        expenseType: new FormControl(element.expenseType),
        amount: new FormControl(element.pmAmount),
        description: new FormControl(element.description),
      });
      this.applyValidatorsToFamilyExpanse(group)
      array.push(group);
    });
    this.computeSummary()
    this.familyExpenseForm.disable();
  }
  async addNew() {
    this.familyExpenseForm.enable();
    this.isFormDisbled = false;
    this.disableExpenseType();
    const newArray = this.familyExpenseForm.controls.expenseslist as FormArray;
    const group = new FormGroup({
      expenseType: new FormControl(''),
      amount: new FormControl(''),
      description: new FormControl('')
    });
    this.applyValidatorsToFamilyExpanse(group)
    newArray.push(group);
  }

  enableDisableForm() {
    this.familyExpenseForm.enable();
    this.disableExpenseType();
    this.isFormDisbled = false;
  }

  cancelForm() {
    this.familyExpenseForm.get('expenseslist')['controls'] =[]
    this.buildFormArrayWithData()
    this.isFormDisbled = true;
  }

  disableExpenseType() {
    let expensesFormArray = this.familyExpenseForm.get('expenseslist')['controls'];
    for(let i=0; i<this.familyExpensesList.length; i++) {
      expensesFormArray[i].controls.expenseType.disable();
    }
  }

  saveFamilyExpenses() {
    this.validationService.markFormGroupTouched(this.familyExpenseForm)
    this.familyExpenseForm.updateValueAndValidity();
    if(!this.isFormDisbled && this.familyExpenseForm.valid) {
      let expensesFormArray = this.familyExpenseForm.get('expenseslist')['controls'];
      expensesFormArray.forEach((expensesFormGroup, index) => {
        if(index === this.familyExpensesList.length) {
          let newFamilyExpense = new FamilyFinance();
          newFamilyExpense.contextType = 'application';
          newFamilyExpense.contextUid = this.application.uid;
          this.familyExpensesList.push(newFamilyExpense);
        }
        this.familyExpensesList[index].expenseType = expensesFormGroup.controls.expenseType.value;
        this.familyExpensesList[index].pmAmount = expensesFormGroup.controls.amount.value;
        this.familyExpensesList[index].description = expensesFormGroup.controls.description.value;
      });
      this.applicationService.saveFamilyExpenses(this.application.uid,
            this.familyExpensesList).toPromise().then(
          (_success) => {
            this._snackbar.open("Family expenses updated successfully", "Close", {
              duration: 2000,
            });
            this.familyExpenseForm.get('expenseslist')['controls'] =[]
            this.getFamilyExpensesList();
          },(failure) => {
            let errormesg =[]
            errormesg.push("Failed to update family expenses")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
          console.log(failure);
        }
      );
    }
  }
   //this function will apply validators to form group
   applyValidatorsToFamilyExpanse(group) {
    this.validationService.applyValidationRules(group,"FamilyExpense")
        .then((controlValidators) => {
      //this.buildFormArrayWithData();
    }).catch(() => {});
  }

  //this function is called onclick of "compute summary" button
  //this function will calculate sum of all amounts
  computeSummary() {
    let amountArray = this.familyExpenseForm.controls.expenseslist.value
    let sumOfAmount = amountArray
      .filter((expensesObject) => expensesObject.amount)
      .reduce((sum: number, expensesObject: any) => sum + parseInt(expensesObject.amount), 0);
    this.familyExpenseForm.get("familyExpansesSummaryForm")['controls'].averageFamilyExpanses.patchValue(sumOfAmount);

  }


  //this function will call a api to get CFA Finalized or not status
  getCfaStatus(){
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response =>{
      this.cfaFinalized=response.body.cfaFinalized
      if(this.cfaFinalized){
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close" ,{
          duration:5000,
        });
      }
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}