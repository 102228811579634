import {DataSource} from '@angular/cdk/table';
import {Audit} from './audit.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {AuditService} from './audit.service';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';

export class AuditDataSource extends  DataSource<Audit> {

  private auditsSubject = new BehaviorSubject<Audit[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private auditService: AuditService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<Audit[]> {
    return this.auditsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.auditsSubject.complete();
    this.totalCount$.complete();
  }

  loadAudits( fromDate: string, toDate: string,
              sortDirection = 'asc', pageIndex = 0, pageSize = 3) {

    this.auditService.query({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
      fromDate: fromDate,
      toDate: toDate
    }).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.auditsSubject.next(response['body']);
    });
  }

}
