import { Injectable } from '@angular/core';
import { JhiLanguageService } from 'ng-jhipster';
import { Principal } from '../auth/principal.service';
import { AuthServerProvider } from '../auth/auth-session.service';
import { CookieService } from 'angular2-cookie';
import { Router } from '@angular/router';

@Injectable()
export class LoginService {

    constructor(
        private languageService: JhiLanguageService,
        private principal: Principal,
        private authServerProvider: AuthServerProvider,
        private cookieService: CookieService
    ) {}

    login(credentials, callback?) {
        const cb = callback || function() {};

        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe((data) => {
                this.principal.setAppRelease(data.json().appRelease);
                this.principal.identity(true).then((account) => {
                    const responseCodeJson = JSON.parse(data._body);
                    if (account !== null) {
                        this.languageService.changeLanguage('en');
                    }
                    if (data.status = 200 && responseCodeJson['responseCode'] === 'SUCCESS') {
                        resolve(data);
                    }else if (data.status = 200 && responseCodeJson['responseCode'] === 'FAILURE') {
                        this.logout();
                        reject(responseCodeJson['error']);
                        return cb(responseCodeJson['error']);
                    }
                });
                return cb();
            }, (err) => {
                this.logout();
                reject(err);
                return cb(err);
            });
        });
    }

    logout() {
        this.authServerProvider.logout().subscribe();
        this.cookieService.removeAll();
        this.principal.authenticate(null);
    }
}
