import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { MaterialLibModule } from '@app/material-lib.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';

import { EditableResolveService } from '@ig-core/resolves/editable-resolve.service';
import { NonEditableResolveService } from '@ig-core/resolves/non-editable-resolve.service';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';

import { ProductSetResolverService } from './product-set.resolve';
import { ProductSetListComponent } from './product-set-list/product-set-list.component';
import { ProductSetEditComponent } from './product-set-edit/product-set-edit.component';
import { AllPipesModule } from '@app/utils/pipes.module';

export const productSetRoute: Route = {
  path: 'product-set',
  canActivate: [AuthenticatedUserService],

  children: [
    { path: '', component: ProductSetListComponent },
    {
      path: 'create', component: ProductSetEditComponent,
      resolve: {
        editable: EditableResolveService
      }
    },
    {
      path: 'update/:id', component: ProductSetEditComponent,
      resolve: {
        productSet: ProductSetResolverService,
        editable: EditableResolveService
      }
    },
    {
      path: 'view/:id', component: ProductSetEditComponent,
      resolve: {
        productSet: ProductSetResolverService,
        editable: NonEditableResolveService
      }
    }
  ]
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    AllPipesModule
  ],
  declarations: [ProductSetEditComponent],
  entryComponents: []
})

export class ProductSetModule { }
