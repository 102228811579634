import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSidenav, MatSnackBar, MatSort, DateAdapter } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ListSource } from '../../../../projects/ig-core/src/lib/list/list-source';
import { PagingAndSortParams } from '../../../../projects/ig-core/src/lib/list/paging-and-sort-params';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { takeUntil, delay } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ProductMasterService } from './product-master.service';
import { ProductMasterSource } from './product-master.data.source';
import { ReferenceCodeService } from '../reference-code/reference-code.service';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';

@Component({
  selector: 'eng-product-master',
  templateUrl: './product-master-list.template.html',
  styleUrls: ['./product-master.style.scss'],
})

export class ProductMaster implements OnInit, OnDestroy {

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  pagingAndSortParams: PagingAndSortParams;

  dataSource: ProductMasterSource;
  listSource: ListSource;

  flexMediaWatcher: Subscription;
  currentScreenWidth: string = '';
  displayedColumns: string[] = ['productCode', 'productName', 'productType', 'minAmount', 'maxAmount', 'minTenure', 'maxTenure', 'maxInterest','sourcingFlag','changeFlag','processFlag','action'];
  productType: NameValueDto[];

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private router: Router,
    private media: MediaObserver,
    private productMasterService: ProductMasterService,
    private dateAdapter: DateAdapter<Date>,
    private referenceCodeService:ReferenceCodeService) {
    this.flexMediaWatcher = media.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias !== this.currentScreenWidth) {
        this.currentScreenWidth = change.mqAlias;
        this.setTableColumns();
      }
    });
    this.dateAdapter.setLocale('en-IN');
  }

  setTableColumns() {
    this.displayedColumns = ['productCode', 'productName', 'productType', 'minAmount', 'maxAmount', 'minTenure', 'maxTenure', 'maxInterest','sourcingFlag','changeFlag','processFlag','action'];
    if (this.currentScreenWidth === 'xs') {
      this.displayedColumns = ['productCode', 'action'];
    }
  };

  ngOnInit() {
    //Instantiate dataSource for subscribing changes in list data
    this.dataSource = new ProductMasterSource(this.productMasterService);

    //Instantiate listSource for subscribing changes in search criteria
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);

    //Subscribe to changes in search criteria
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        this.getProductMaster();
      });
      this.referenceCodeService.getShortRefCodes('loan_type').subscribe((response: any) => {
        this.productType = response.loan_type;
    }); 
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  loadProductMasterPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  ngAfterViewInit(): void {
    //Subscribe to changes in pagination and sort criteria
    this.listSource.subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this))).subscribe(
        () => this.loadProductMasterPageWithParams());
  }

  getProductMaster() {
    let sortCriteria = 'productCode' + ',' + 'asc';
    //Subscribe to changes in list data
    this.dataSource.getProductMaster(sortCriteria, this.pagingAndSortParams.pageIndex,
      this.pagingAndSortParams.pageSize);
  }

  //this is to redirect the page based on user click, by passing Controller, mode and respective title
  redirectEditProductMaster(productMaster, mode, title) {
    if (mode === 'update') {
      this.router.navigate(['admin/product-master/update/' + productMaster.uid], { queryParams: { data: JSON.stringify({ "mode": mode, "productMaster": productMaster, "title": title }) } })
    } else if (mode === 'create') {
      this.router.navigate(['admin/product-master/create'], { queryParams: { data: JSON.stringify({ "mode": mode, "productMaster": productMaster, "title": title }) } })
    } else {
      this.router.navigate(['admin/product-master/view/' + productMaster.uid], { queryParams: { data: JSON.stringify({ "mode": mode, "productMaster": productMaster, "title": title }) } })
    }
  }

}
