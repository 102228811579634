import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { TransactionSummaryDto } from './transaction-summary-dto.model';
import { AlertService } from 'ng-jhipster';

@Injectable()
export class LoanOdAccountsReverseResolve implements Resolve<TransactionSummaryDto> {

    constructor(private loanOdAccountsService: LoanOdAccountsService,
        private alertService: AlertService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId')) {
            return this.loanOdAccountsService.findReverseEntry(route.paramMap.get('accountId')).catch(
                (err) => {
                    this.onFetchError(err);
                    return null;
                }
            );
        } else {
            return new TransactionSummaryDto();
        }
    }

    private onFetchError(error) {
        console.log(error);
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
    }

    private onError(error) {
        this.alertService.error(error.error);
    }
}
