
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationDateService } from '@app/shared/date/application-date.service';
import { DateConverterService } from '@app/shared/date/date-converter.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { AlertService, EventManager, JhiLanguageService } from 'ng-jhipster';
import { AccountProfileDto } from './accountt-profile-dto.model';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdAmendment } from './loan-od-amendment.model';


@Component({
    selector: 'jhi-loan-od-accounts-set-status',
    templateUrl: './loan-od-accounts-set-status.component.html'
})
export class LoanOdAccountsSetStatusComponent implements OnInit, OnDestroy {
    loanOdAccounts: LoanOdAccounts;
    loanOdAmendment: LoanOdAmendment;
    // accountProfileDto: AccountProfileDto;

    constructor(private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService,
        private loanODAccountsService: LoanOdAccountsService,
        private applicationService: ApplicationDateService,
        private eventManager: EventManager,
        private route: ActivatedRoute,
        private dateConverter: DateConverterService,
        private router: Router) {
        this.loanOdAmendment = this.loanODAccountsService.getFreezeExecute();
    }
    ngOnInit() {
        this.loanOdAccounts = this.route.snapshot.data['loanOdAccounts'];
        if (this.loanOdAccounts.operationalStatus == 'ACTIVE') this.loanOdAccounts.operationalStatus = 'FROZEN';
        else if (this.loanOdAccounts.operationalStatus == 'FROZEN') this.loanOdAccounts.operationalStatus = 'ACTIVE';
        this.loanOdAmendment = new LoanOdAmendment();
        // this.loanOdAmendment.runReversedTransactions = false;

        this.loanOdAmendment.valueDate = this.applicationService.bank.currentWorkingDate ? new NgbDate(parseInt(this.applicationService.bank.currentWorkingDate.split('-')[0]), parseInt(this.applicationService.bank.currentWorkingDate.split('-')[1]), parseInt(this.applicationService.bank.currentWorkingDate.split('-')[2])) : null;
    }
    ngOnDestroy() {
    }

    previousState() {
        window.history.back();
    }
    isExecute = false;
    fnBack() {
        this.isExecute = false;
    }
    proceed() {
        this.loanOdAmendment.accountId = this.loanOdAccounts.accountId;
        this.loanOdAmendment.reference = this.loanOdAccounts.operationalStatus;
        this.loanOdAmendment.valueDate = this.dateConverter.fromNgbDatePickerToString(this.loanOdAmendment.valueDate);
        this.loanODAccountsService.setStatusExecute(this.loanOdAmendment).subscribe(
            (res: any) => { this.loanOdAmendment = res; this.isExecute = true; });
    }
    clear() {
        this.loanOdAmendment = new AccountProfileDto();
        this.router.navigate(['/loan-od-accounts']);
    }

    createNewLoan() {
        this.router.navigate(['/loan-od-accounts/create']);
    }

    execute() {
        if (this.loanOdAmendment.accountId !== undefined) {
            this.loanODAccountsService.freezeExecute(this.loanOdAmendment).subscribe(
                (res) => this.goTo(res),
                (res) => this.onSaveError(res)
            );
        }

    }
    private onSaveError(error) {
        try {
            const errorString = error.error.localizedMessage ? error.error.localizedMessage : error.error;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.error.localizedMessage ? error.error.localizedMessage : error.error;
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
        this.router.navigate(['/loan-od-accounts']);
    }
    private onError(error) {
        this.alertService.error(error);
    }
    goTo(res) {
        this.loanOdAmendment = res;
        this.alertService.success(`Loan Account ${this.loanOdAccounts.accountId} is ${this.loanOdAccounts.operationalStatus === 'ACTIVE' ? 'Freezed' : 'Activated'}`);
        this.router.navigate(['/loan-od-accounts']);
    }
}
