/**
* Created by pavi2p on 10-05-2017.
*/
import {Component, Input, OnChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {IgValidationService} from './igvalidation.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'ig-input',
    template: `
        <div class="form-group"
             [ngClass]="{'has-error':errorMessage}">
            <ng-content></ng-content>
            <span class="help-block form-text text-danger"
                  *ngIf="errorMessage">
                              {{errorMessage}}
                        </span>
        </div>`
})
export class IgInputComponent implements OnChanges {

    // <td class="help-block form-text text-danger pl-2 pb-2"
    // *ngIf="errorMessage">
    //             {{errorMessage}}
    //       </td>

    @Input()
    errorDefs: any;
    @Input()
    inputErrors: any;
    @Input()
    inputField: FormControl;

    errorMessage: string;

    ngOnChanges(changes: any): void {
        this.errorMessage = '';
        if (this.inputField && this.inputField.errors && this.inputField.dirty) {
            Object.keys(this.inputField.errors).some((key) => {
                if (this.inputField.errors[key]) {
                    if (key === 'validateAadhar') {
                        this.errorMessage = 'Invalid Aadhar number';
                    }
                    if (key === 'remote') {
                        this.errorMessage = this.inputField.errors[key];
                    } else if (this.errorDefs && this.errorDefs[key]) {
                        this.errorMessage = this.errorDefs[key];
                    }else if (IgValidationService.getValidatorErrorMessage(key, this.inputField.errors[key])) {
                        this.errorMessage = IgValidationService.getValidatorErrorMessage(key, this.inputField.errors[key]);
                    }else if (key === 'ngbDate') {
                        this.errorMessage = 'Invalid date format';
                    }else {
                        this.errorMessage = `No Message found for ${key} Please add to erroDefs or ValidationService`;
                    }
                    return true;
                }
            });
        }
    }
}
