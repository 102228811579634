import {Routes} from '@angular/router';

import { SocialAuthComponent } from './social-auth.component';
import {AuthenticatedUserService} from '../authenticated-user-service';

export const socialAuthRoute: Routes = [{
    path: 'social-auth',
    component: SocialAuthComponent,
    data: {
        authorities: [],
        pageTitle: 'social.register.title'
    },
    // canActivate: [AuthenticatedUserService]
}];
