import { DataSource } from '@angular/cdk/table';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';

import { CollectionViewer } from '@angular/cdk/collections';

import { ProductSet } from '../product-set.model';
import { ProductSetService } from '../product-set.service';

export class ProductSetListDataSource extends DataSource<ProductSet> {

  private productsetSubject = new BehaviorSubject<ProductSet[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private productSetService: ProductSetService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<ProductSet[]> {
    return this.productsetSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.productsetSubject.complete();
    this.totalCount$.complete();
  }

  loadProductsets(sortDirection = 'asc', pageIndex = 0, pageSize = 3) {

    this.productSetService.findProductSets({
      page: pageIndex,
      size: pageSize,
      sort: 'asc',
    }).pipe(
      catchError(() => of([])),
      finalize(() => { })
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].get('X-Total-Count'));
      this.productsetSubject.next(response['body']);
    });
  }

}
