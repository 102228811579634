import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { MaterialLibModule } from '@app/material-lib.module';
@NgModule({
  declarations: [ConfirmationDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MaterialLibModule
  ],
  exports: [ConfirmationDialogComponent],
  entryComponents: [ConfirmationDialogComponent],
  providers: [ConfirmationDialogService],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class ConfirmationDialogModule { }
