import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { RouteStorageService } from './route-storage.service';
import {PrincipalService} from './principal.service';

@Injectable({providedIn: 'root'})
export class AuthenticatedUserService implements CanActivate {

    constructor(private router: Router,
                private principalService: PrincipalService,
                private stateStorageService: RouteStorageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {

        const authorities = route.data['authorities'];
        // We need to call the checkLogin / and so the principal.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkLogin(authorities, state.url);
    }

    checkLogin(authorities: string[], url: string): Promise<boolean> {
        return Promise.resolve(this.principalService.identity().then(account => {
            if (account) {
              if (!authorities || authorities.length === 0) {
                return true;
              }

              return this.principalService.hasAnyAuthority(authorities).then(response => {
                    if (response) {
                        return true;
                    }
                    if (isDevMode()) {
                        console.error('User has not any of required authorities: ', authorities);
                    }
                    return false;
                });
            }

            this.stateStorageService.storeUrl(url);
            this.router.navigate(['signin']);
            return false;
        }));
    }
}
