import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthServerProvider {

    constructor(
        private http: Http
    ) { }

    login(credentials): Observable<any> {
        const data = 'j_username=' + encodeURIComponent(credentials.username) +
            '&j_password=' + encodeURIComponent(credentials.password) +
            '&remember-me=fasle&submit=Login';
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        });

        return this.http.post('resources/j_spring_security_check', data, { headers });
    }

    logout(): Observable<any> {
        // logout from the server
        return this.http.post('resources/j_spring_security_logout', {}).pipe(map((response: Response) => {
            // to get a new csrf token call the api
            this.http.get('api/account').subscribe(() => { }, () => { });
            return response;
        }));
    }
}
