import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Branch} from './branch.model';

@Injectable({
    providedIn: 'root'
})
export class BranchService {

    private API = 'api/branches';
    private APIV1 = 'api/v1/masters/orgstructure/'

    constructor(private http: HttpClient) {}

    findBranchs(req: any): Observable<HttpResponse<Branch[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        return this.http.get<Branch[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveBranch(branch: Branch): Observable<HttpResponse<Branch>> {
        const copy: Branch = Object.assign({}, branch);
        if (copy.uid && copy.uid != null) {
            return this.http.put<Branch>(this.API, copy, {observe: 'response'});
        } else {
            return this.http.post<Branch>(this.API, copy, {observe: 'response'});
        }
    }

    getBranch(uid: string) {
        return this.http.get<Branch>(this.API + '/' + uid);
    }

    deleteBranch(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    getAllBranches() {
      return this.http.get<Branch[]>(this.API + '/' + 'user');
    }

    getBranchesForUser() {
        return this.http.get<any>(this.APIV1 + 'branches', { observe: 'response' });
    }

    branchToAddOrgStructure(branchCode: String) { 
        return this.http.get<Branch>(this.APIV1 + 'branchbase/' + branchCode, {observe: 'response'})
    }
}

