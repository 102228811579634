import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductMasterComposite } from './product-master.model';
@Injectable({
    providedIn: 'root'
})
export class ProductMasterService {
    private API = 'api/productMaster';
    constructor(private http: HttpClient) { }

    getProductMasterList(req: any): Observable<HttpResponse<ProductMasterComposite[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        return this.http.get<any>(this.API, { params, observe: 'response' });
    }

    saveProductMaster(productMaster: ProductMasterComposite): Observable<HttpResponse<ProductMasterComposite>> {
        return this.http.put<ProductMasterComposite>(this.API + '/save', productMaster, { observe: 'response' });
    }

    reterieve(productType: string): Observable<HttpResponse<ProductMasterComposite>> {
        let params = new HttpParams();
        params = params.set('productCode', productType);
        return this.http.get<ProductMasterComposite>(this.API + '/retrive', { params, observe: 'response' });
    }

    getProductTypeDetails(productType: string): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/v1/masters/loanproduct/' + productType, { observe: 'response' });
    }

    getProductsByBranchCode(branchCode: string): Observable<HttpResponse<any>> {
        return this.http.get<any>('api/v1/masters/productsets/products/' + branchCode, { observe: 'response' });
    }

}