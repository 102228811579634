export class BankAccount {
    constructor (
        public uid ?: string,
        public bankName ?: string,
        public branchName ?: string,
        public accountType ?: string,
        public accountNumber ?: string,
        public accountHolderName ?: string,
        public bankAddress ?: string,
        public ifsc ?: string,
        public bankingSince ?: number,
        public repaymentAccount ?: boolean,
        public acOpeningYear ?: Date,
        public accountCategory ?: string,
        public linkToType ?: string,
        public linkToId ?: string,
        public linkToUid ?: string,
        public linkedAs ?: string,
        public versionHistoryFlag ?: boolean,
        public frozenFlag ?: boolean,
        public contextType ?: string,
        public contextId ?: string,
        public contextUid ?: string,
        public dedupFlag ?: boolean,
        public dedupReference ?: string,
        public dedupResolution ?: string,
        public overdraftLimit ?: number,
        public version ?: number,
        public verificationMode?: string,
        public verifiedBy?: string,
        public verifiedFlag?: boolean
    ) {}
}

export class BankAccountHolderComposite {
    constructor (
        public name ?: string,
        public bankAccounts ?: BankAccount[]
    ){}
}

export class BankAccountVerificationResponseComposite {
    constructor(
        public internalData?: XDataBankAccountVerificationResponse,
        public serviceProviderData?: XDataBankAccountVerificationResponse,
        public label?: string,
        public status?: string
        ) { }
    }

export class XDataBankAccountVerificationResponse {
    constructor(
        public beneficiaryName? : string,
        public status? : string,
        public nameMatch? : string,
        public message? : string
    ) { }
}

export class BankAccountVerificationDTO {
    constructor(
        public verificationMode?: string,
        public jdata?: XDataBankAccountVerificationResponse
    ) { }
}

export class AnalyzerRequestDTO {
    constructor(
        public applicationUid?: string,
        public linkToType?: string,
        public linkToUid?: string,
        public mobileNumber?: string,
        public requestId?: string,
        public transactionId?: string,
        public userAgent?: string,
        public name?: string
    ) { }
}

export class AnalyzerResponseDTO {
    constructor(
        public status?: string,
        public linkUrl?: string,
        public requestId?: string,
        public message?: string,
        public transactionStatus?: string,
        public transactionStatusCode?: string,
        public transactionId?: string,
        public name?: string,
        public mobileNumber?: string,
        public applicationUid?: string
    ) { }
}