import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';
import { ApprovalLimitService } from './approval-limit.service';
import { ApprovalLimit, ApprovalLimitSearchCriteria } from './approval-limit.model';


export class ApprovalLimitSource extends DataSource<ApprovalLimit> {

  private approvalLimitSubject = new BehaviorSubject<ApprovalLimit[]>([]);
  public totalCount$ = new BehaviorSubject<number>(0);
  
  constructor(private approvalLimitService: ApprovalLimitService) {
    super();
  }
  
  connect(collectionViewer: CollectionViewer): Observable<ApprovalLimit[]> {
    return this.approvalLimitSubject.asObservable();
  }
  
  disconnect(collectionViewer: CollectionViewer): void {
    this.approvalLimitSubject.complete();
    this.totalCount$.complete();
  }

  getApprovalLimits(sortDirection = 'asc', pageIndex = 0, pageSize = 10, approvalLimitSearchCriteria : ApprovalLimitSearchCriteria) {
    this.approvalLimitService.getApprovalLimit({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }, approvalLimitSearchCriteria).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['body'].totalElements);
      this.approvalLimitSubject.next(response['body'].content);
    });
  }
} 