import { User } from '@app/admin/user/user.model';

export class Zone {
    constructor (
        public uid ?: number,
        public zoneCode ?: string,
        public branchCode ?: string,
        public name ?: string,
        public description ?: string,
        public keyLocalities ?: string,
        public branchUid ?: string,
        public coordinates ?: string,
        public center ?: string,
        public ro ?: User,
        public roId ?: string,
        public color ?: string,
        public version ?: number
    ) {}
}

export class Coordinate {
    constructor (
        public lat ?: number,
        public lng ?: number
    ) {}
}