
export class Deviation {
  constructor(
    public uid?: string,
    public version?: number,
    public category?: string,
    public deviationCode?: number,
    public description?: string,
    public setbyUser?: string,
    public setbyRole?: string,
    public affordableEmi?: string,
    public status?: string,
    public approvedFlag?: boolean,
    public deletedFlag?: boolean,
    public documentFolder?: string,
    public contextType?: string,
    public contextId?: string,
    public contextUid?: string,
  ) { }
}
export class Condition {
  constructor(
    public uid?: string,
    public version?: number,
    public type?: string,
    public description?: string,
    public dueDate?: string,
    public setbyUser?: string,
    public setbyRole?: string,
    public status?: string,
    public completedFlag?: string,
    public approvedFlag?: boolean,
    public deletedFlag?: boolean,
    public documentFolder?: string,
    public contextType?: string,
    public contextId?: string,
    public contextUid?: string,
  ) { }
}

export class Questionnaire {
  constructor(
    public code: string,
    public description: string,
    public name: string,
    public questionnaireVersionCode: string,
    public questionnaireVersionName: string,
    public questions: Questions[],
    public status: string,
    public uid: string,
    public version: Number
  ) { }
}
export class ApplicationQuestionnaire {
  constructor(
    public answers: Array<object>,
    public applicationUid: string,
    public endTime: string,
    public fileInfo: string,
    public latitude: string,
    public longitude: string,
    public questionnaireUid: string,
    public questions: Questions[],
    public startTime: string,
    public uid: string,
    public version: 1,
  ) { }
}

export class Questions {
  constructor(
    public category: string,
    public code: string,
    public configuration: string,
    public name: string,
    public questionnaireResponse: string,
    public questionnaireResponseDetail: QuestionnaireResponseDetail,
    public questionnaireUid: string,
    public required: Boolean,
    public sequence: 1,
    public subCategory: string,
    public type: string,
    public uid: string,
    public version: Number,
  ) { }
}

export class QuestionnaireResponseDetail {
  constructor(
    public answer: string,
    public multiMediaFileNameIdMapString: string,
    public question: string,
    public questionUid: string,
    public questionnaireResponseUid: string,
    public uid: string,
    public version: Number,
    public imageFiles?:Array<object>//this parameter is used in client side, this will not come from server response
  ) { }
}
