import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialLibModule } from './material-lib.module';
import { HomeModule } from './home/home.module';
import { LayoutModule } from '@angular/cdk/layout';
import { IgLayoutModule } from './ig-layout/ig-layout.module';
import { MultiLevelMenuModule } from '../../projects/multi-level-menu/src/lib/multi-level-menu.module';
import { NgConfigureModule } from './build-config/configure.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UserAccountModule } from './user-account/user-account.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IgCoreModule } from '../../projects/ig-core/src/lib/ig-core.module';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AdminModule } from './admin/admin.module';
import { FileManagementComponent } from './file-management/file-management.component';
import { ExamplesModule } from '@app/examples/examples.module';
import { AngularWebStorageModule } from 'angular-web-storage';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { ZonesModule } from './zones/zones.module';
import { TasksModule } from './tasks/tasks.module';
import { BottomSheetComponent } from './ig-layout/bottom-sheet/bottom-sheet.component';
import { DatePipe,registerLocaleData  } from '@angular/common';
import { LeadsModule } from './leads/leads.module';
import { ApplicationModule } from './applications/application.module';
import { AllPipesModule } from './utils/pipes.module';

/* Unsubscribe - https://medium.com/thecodecampus-knowledge/the-easiest-way-to-unsubscribe-from-observables-in-angular-5abde80a5ae3*/
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ReportsModule } from './reports/reports.module';
import { LoanOdAccountsModule } from './loan-od-accounts/loan-od-accounts.module';
import { PaginationConfig } from './blocks/ui-pagination.config';
import { SnackbarService } from '@app/utils/multiple-snackbar-messages-display.service';
import { DashboardModule }from '@app/dashboard/dashboard.module';

import localeIn from '@angular/common/locales/en-IN';
import { ReportsMenuModule } from './reports-menu/reports-menu.module';
registerLocaleData(localeIn);
@NgModule({
  declarations: [AppComponent, FileManagementComponent, BottomSheetComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgConfigureModule.forRoot(),
    AngularWebStorageModule,
    FlexLayoutModule.withConfig({ useColumnBasisZero: false }),
    MaterialLibModule,
    ReactiveFormsModule,
    IgCoreModule,
    LayoutModule,
    IgLayoutModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(), /*https://github.com/mpalourdio/ng-http-loader*/
    MultiLevelMenuModule,
    HomeModule,
    UserAccountModule,
    AdminModule,
    ExamplesModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    ZonesModule,
    TasksModule,
    LeadsModule,
    ApplicationModule,
    ReportsModule,
    AllPipesModule,
    NgxImageZoomModule,
    LoanOdAccountsModule,
    DashboardModule,
    ReportsMenuModule

  ],
  entryComponents: [BottomSheetComponent],
  providers: [DatePipe, PaginationConfig,SnackbarService],
  bootstrap: [AppComponent]
})
export class AppModule { }

/* https://www.npmjs.com/package/google-libphonenumber for phone-number validation */
