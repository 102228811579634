import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Scorekey} from './score-key.model';

@Injectable({
    providedIn: 'root'
})
export class ScorekeyService {

    private API = 'api/v1/masters/scorekey';

    constructor(private http: HttpClient) {}

    getScoreKeyList(req: any): Observable<HttpResponse<Scorekey[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        return this.http.get<any>(this.API, { params, observe: 'response' });
    }
}

