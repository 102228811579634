import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';
import { IgPatternValidator } from '@ig-core/form/additional-validators/ig-pattern-validator';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

import { Role } from '../role.model';
import { RoleService } from '../role.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-role-editor',
    templateUrl: './role-editor.component.html',
    styleUrls: ['../role.component.css']
})
export class RoleEditorComponent implements OnInit, OnDestroy {
    role: Role;
    editable = true;
    title: String;
    public roleEditorForm: FormGroup;
    public formError: FormErrorModel;
    subscription: Subscription;
    public formErrors = {
        'id': '', 'version': '', 'name': '', 'code': '', 'authorities': '',
    };
    public authoritiesObservable$;
    authoritiesList:any;
    constructor(private roleService: RoleService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private _snackbar: MatSnackBar,
        private igFormService: IgFormService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.role === undefined) {
                    this.role = {};
                } else {
                    this.role = data.role;
                }
                this.editable = data.editable;
            });
        this.authoritiesObservable$ = this.roleService.getAuthorities();

        this.activatedRoute.queryParams
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((param) => {
                this.title = JSON.parse(param.title)
            });
    }

    ngOnInit() {
        this.roleService.getAuthoritiesList().subscribe(response=>{
            this.authoritiesList= response.body
            this.authoritiesList=  this.authoritiesList.sort((a,b) => (a.authorityName.toLowerCase() > b.authorityName.toLowerCase()) ? 1 : ((b.authorityName.toLowerCase() > a.authorityName.toLowerCase()) ? -1 : 0))
            this.buildForm();
        })
        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.roleEditorForm.disable();
        }
    }
    ngOnDestroy(): void {
    }

    buildForm() {
        this.roleEditorForm = this.formBuilder.group({

            'id': [this.role.id, []],

            'version': [this.role.version, []],

            'name': [this.role.name, []],

            'code': [this.role.code, []],

            'authorities': [this.role.authorities, []],
            'checkboxes': this.formBuilder.array( this.authoritiesList.map(x => false))
        });
        let checkboxControl = (this.roleEditorForm.controls.checkboxes as FormArray);
        checkboxControl.controls.forEach((element,index) => {
          if(this.role.authorities.includes(this.authoritiesList[index].authorityName)){
            checkboxControl.controls[index].patchValue(true)
          }
        });

        this.subscription = checkboxControl.valueChanges.subscribe(checkbox => {
          checkboxControl.setValue(
            checkboxControl.value.map((value, i) => value ? this.authoritiesList[i].authorityName : false),
            { emitEvent: false }
          );
        });
        this.roleEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.roleEditorForm, this.formErrors, true);
        });
    }

    private markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
          control.markAsTouched();
    
          if (control.controls) {
            this.markFormGroupTouched(control);
          }
        });
      }

    saveRole() {
        this.markFormGroupTouched(this.roleEditorForm)
        let checkboxControl = (this.roleEditorForm.controls.checkboxes as FormArray);
        let authoritiesNames = []
        checkboxControl.controls.forEach((element, index) => {
          if (element.value) {
            authoritiesNames.push(this.authoritiesList[index].authorityName)
          }
  
        });
        this.roleEditorForm.controls.authorities.patchValue(authoritiesNames)
        if (this.roleEditorForm.valid) {
          let roleFormFields = this.roleEditorForm.value;
          this.role.id = roleFormFields.id;
          this.role.version = roleFormFields.version;
          this.role.name = roleFormFields.name;
          this.role.code = roleFormFields.code;
          this.role.authorities = roleFormFields.authorities;
          this.roleService.saveRole(this.role).toPromise().then(
              (_success) => {
                if (this.role.id) {
                  this._snackbar.open("Role updated successfully", "Close", {
                    duration: 2000,
                  });
                  this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
                } else {
                  this._snackbar.open("Role created successfully", "Close", {
                    duration: 2000,
                  });
                  this.router.navigate(['../'], { relativeTo: this.activatedRoute });
                }
              }, (failure) => {
                this._snackbar.open("Role update failed, " + failure, "Close", {
                  duration: 2000,
                })
                console.log(failure);
              }
            );
        }
      }

   /*  onSubmit() {
        this.formError = new FormErrorModel(false, '');

        // mark all fields as touched
        this.igFormService.markFormGroupTouched(this.roleEditorForm);

        if (this.roleEditorForm.valid) {
            this.roleService.saveRole(this.roleEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
                .subscribe((response) => this.onSubmitSuccess(response),
                    (response) => this.onSubmitError(response));
        } else {
            this.formErrors = this.igFormService.validateForm(this.roleEditorForm, this.formErrors, false);
            return false;
        }

    }

    private onSubmitSuccess(response) {
        let msg = '';
        if (this.role.id) {
            msg = `Updated role ${this.role.id} successfully`;
        } else {
            msg = `Created role successfully`;
        }
        this.snackBar.open(msg, 'Close');
        this.goToRoleListPage();
    }

    private onSubmitError(response) {
        const errorModel = this.igFormService.handleServerError(this.roleEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        }
    } */

    goToRoleListPage() {
        if (this.role.id) {
            this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        }
    }

}
