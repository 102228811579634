import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSidenav, MatPaginator,MatSort } from '@angular/material';
import { Task } from './task.model';
import { trigger,style,state,transition,animate} from '@angular/animations';
import { tasks} from './task-table';
import { TaskService } from './task.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BottomSheetService } from '@app/ig-layout/bottom-sheet/bottom-sheet.service';
import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { MatBottomSheetRef, MatSnackBar } from '@angular/material';
import {  TaskListDataSource } from './task-list.data.source';
import { ListSource } from '@ig-core/list/list-source';
import { ActivatedRoute, Router } from '@angular/router';
  import { from } from 'rxjs';
import { NoteService } from '@app/note/note.service';
import {Note} from '../note/note.model';
import {ReferenceCodeService} from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '../../../projects/ig-core/src/lib/interfaces/name-value-dto';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Subscription } from 'rxjs';
import { MediaObserver , MediaChange } from '@angular/flex-layout';


@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
  animations :[
    trigger('openCloseTaskSidenav', [
      state('openTask', style({width: '330px'})),
      state('closeTask', style({width: '0px'})),
      transition('open <=> closed',
        animate('0.5s')
      ),
    ]),
    trigger('openCloseTaskSidenavContent', [
      state('openTask', style({'margin-right': '330px'})),
      state('closeTask', style({'margin-right': '0px'})),
      transition('open <=> closed',
        animate('0.5s')
      ),
    ])
  ]
})

export class TasksComponent implements OnInit, OnDestroy {
  
  @ViewChild('showsidenav')
  public showsidenav: MatSidenav;
  public isOuterSideNavOpen = false;

  displayedColumns: string[] = ['heading', 'taskNotes', 'priority','plannedDueDate',
    'assignedTo', 'category', 'status'];
    currentScreenWidth: string = '';
    flexMediaWatcher: Subscription;

  pagingAndSortParams: PagingAndSortParams;
  dataSource:TaskListDataSource;
  listSource: ListSource;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  selectedTask : Task;
  taskForm : FormGroup;
  taskStatus: string;
  
  notes : Note[];
  noteContent : string;
  
  priorityList : NameValueDto[] = [];
  statusList :NameValueDto[] = [];
    
  isOpen: boolean = false;
  selectedDate: Date = null;

  searchParameterStatus: any;
  
  constructor(private taskService: TaskService,
      private formBuilder:FormBuilder,
      private activatedRoute: ActivatedRoute,
      private referenceCodeService:ReferenceCodeService,
      private _snackbar: MatSnackBar,
      private bottomSheetService:BottomSheetService,
      private router: Router,
      private noteService: NoteService,
      private media: MediaObserver ) {
         this.flexMediaWatcher = media.media$.subscribe((change: MediaChange) => {
          if(change.mqAlias !== this.currentScreenWidth) {
            this.currentScreenWidth = change.mqAlias;
            this.setTableColumns();
          }
      });
    }
    setTableColumns() {
      this.displayedColumns = ['heading', 'taskNotes', 'priority','plannedDueDate',
      'assignedTo', 'category', 'status'];
      if (this.currentScreenWidth === 'xs') {
        this.displayedColumns = ['heading', 'taskNotes'];
      }
    };
  
  
  ngOnInit() {
    this.getStatus('task_status');
    this.taskService.$taskloader.subscribe(
      (taskloaddata) =>{
        if(taskloaddata) this.getSearchStatusList();
      }
    ); 
    this.dataSource = new TaskListDataSource(this.taskService);
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        let sortColumn = this.pagingAndSortParams.sortColumn;
        if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
          sortColumn = 'uid';
        }
        this.dataSource.loadTask(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
          this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize,
          this.selectedDate,this.searchParameterStatus);
    });
  }

    ngAfterViewInit(): void {
      // initiate sort and pagination change once the view is initialized
      this.listSource.subscribePaginationAndSortChange()
          .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadTaskPageWithParams());
         
  }

    loadTaskPageWithParams() {
      this.router.navigate([], this.getQueryParams());
  }

    getQueryParams() {
      const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
      return queryParams;
  }

    ngOnDestroy():void{
      this.listSource.disconnect();
     }
     
    openSideNav(selectedTask){
      this.selectedTask = selectedTask;
      
      this.getpriority('task_priority');

      this.selectedTask.plannedDueDate = new Date(selectedTask.plannedDueDate);

      this.taskForm = this.formBuilder.group({
        priority : selectedTask.priority,
        assignedTo : "",
        plannedDueDate : selectedTask.plannedDueDate,
        uid : selectedTask.uid,
        status : ""
      });

      if(selectedTask.assignedTo === '1') {
        this.taskForm.patchValue({
          assignedTo: 1
        });
      }else if(selectedTask.assignedTo === '2') {
        this.taskForm.patchValue({
          assignedTo: 2
        });
      }

      this.taskStatus = selectedTask.status;

      this.notes = [];
      this.getNotes(selectedTask.uid, "task");
      
      this.isOpen = selectedTask.openFlag;
      if(!this.isOpen) {
        this.taskForm.disable();
      }
      
      this.isOuterSideNavOpen= true;
      this.showsidenav.open();
    }

    getpriority(name: string) {
      this.referenceCodeService.getShortRefCodes(name).subscribe((response: any) => {
        this.priorityList = response.task_priority;
      });
    }

    getStatus(name: string){
      this.referenceCodeService.getShortRefCodes(name).subscribe((response: any) => {
        this.statusList = response.task_status;
      })
    }

    getNotes(taskUid, linkToType) {
      this.noteService.getNote({linkToUid : taskUid, linkToType : linkToType })
        .subscribe((response:any) => {
          if(response.body){
            this.notes = response.body;
          }
      });
    }

    openBottomSheet(): void {
        this.bottomSheetService.open(); 
    }

      updateTask(){
        this.taskForm.patchValue({
          status: this.taskStatus
        });
        if(this.taskForm.valid) {
          this.taskService.updateTask(this.taskForm.value).toPromise().then(
            (success) => {
              this._snackbar.open("Task updated successfully", "Close", {
                duration: 2000,
              })
              this.taskForm.reset();
               this.closeSideNav();
              this.taskService.$taskloader.next(true);
            },
            (failure) => {
              this._snackbar.open("Task updation failed, " + failure, "Close", {
                duration: 2000,
              })
              console.log(failure);
            }
          )
        }
        
      }
      
      saveNote() {
        let note : Note = {
          linkToUid : this.selectedTask.uid,
          linkToType : 'task',
          content : this.noteContent
        }
        this.noteService.saveNote(note).toPromise().then(
          (success) => {
            this._snackbar.open("Note created sucessfully" , "Close" ,{
              duration:2000,
            });
            this.noteContent = '';
            this.getNotes(this.selectedTask.uid, "task");
          },
          (failure) => {
            this._snackbar.open("Note creation failed, " + failure, "Close", {
              duration: 2000,
            })
            console.log(failure);
          } 
        )
      }
      getStatusCount(){
        this.taskService.getStatusCount().subscribe((response: any)=>{
         const value = response;
        });

      }

      closeSideNav(){
        this.isOuterSideNavOpen= false;
        this.showsidenav.close();
      }

      getSearchStatusList() {
        // const searchValue = {'toDate': this.selectedDate, 'status': this.searchParameterStatus};
        // this.taskService.getAllTask(searchValue).subscribe((response: any) => {
        //   this.dataSource = response.body;
        //   this.taskService.$taskloader.next(false);
        // })
        let sortColumn = this.pagingAndSortParams.sortColumn;
   if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
    sortColumn = 'uid';
   }
        this.dataSource.loadTask(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
   this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize,this.selectedDate,this.searchParameterStatus);
      }
}
