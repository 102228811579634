// export class ApplicantAddressComposite {
//     constructor(
//         public applicantAddresses?: ApplicantAddress[]
//     ) { }
// }

export class ApplicantAddress {
    constructor(
        public address?: Address,
        public addressLink?: AddressLink
    ) { }
}

export class Address {
    
    constructor(
        public id?: number,
        public uid?: string,
        public line1?: string,
        public line2?: string,
        public line3?: string,
        public landmark?: string,
        public city?: string,
        public district?: string,
        public state?: string,
        public country?: string,
        public postalCode?: string,
        public lat?: number,
        public lng?: number,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public dedupFlag?: boolean,
        public dedupReference?: string,
        public dedupResolution?: string,
        public version?: number,
        public deleteBtnDisabled?: boolean//added for client side use this parameter will not come from server
    ) { }
}

export class AddressLink {
    constructor(
        public id?: number,
        public uid?: string,
        public addresslinkId?: string,
        public linkToType?: string,
        public linkToId?: string,
        public linkToUid?: string,
        public addressId?: string,
        public linkedAs?: string,
        public linkToSubtype?: string,
        public sameAsFlag?: boolean,
        public sameasAddressLinkId?: string,
        public sameasSource ?: string,
        public ownershipType?: string,
        public yearsInProperty?: number,
        public yearsInArea?: number,
        public localityType?: boolean,
        public roofType?: boolean,
        public flooringType?: string,
        public livingStandardType?: string,
        public primaryPhone?: string,
        public alternatePhone?: string,
        public landlinePhone?: string,
        public emailId?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public addressUid?: string,
        public phoneContactId?: string,
        public version?: number
    ) { }
}