import { Component, OnInit, AfterViewInit, Renderer, ElementRef, Input } from '@angular/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { JhiLanguageService, EventManager, AlertService } from 'ng-jhipster';

import { LoginService } from './login.service';
import { StateStorageService } from '../auth/state-storage.service';
import { ApplicationDateService } from '../date/application-date.service';

@Component({
    selector: 'jhi-login-modal',
    templateUrl: './login.component.html'
})
export class JhiLoginModalComponent implements OnInit, AfterViewInit {
    authenticationError: boolean;
    serverError: boolean;
    errorMessage: string[];
    password: string;
    rememberMe: boolean;
    username: string;
    credentials: any;
    @Input('timeoutError') timeoutError?: string;

    constructor(
        private eventManager: EventManager,
        private languageService: JhiLanguageService,
        private loginService: LoginService,
        private elementRef: ElementRef,
        private renderer: Renderer,
        private router: Router,
    ) {
        this.credentials = {};
    }

    ngOnInit() {
        this.languageService.addLocation('login');
        this.languageService.addLocation('global');
        if (this.timeoutError) {
            this.errorMessage.push(this.timeoutError);
        }
    }

    ngAfterViewInit() {
        this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#username'), 'focus', []);
        this.addReadOnlyAttr();
    }

    cancel() {
        this.credentials = {
            username: null,
            password: null,
            rememberMe: true
        };
        this.authenticationError = false;
        this.serverError = false;
        // this.activeModal.dismiss('cancel');
    }

    login() {
        this.addReadOnlyAttr();
        this.loginService.login({
            username: this.username,
            password: this.password,
            rememberMe: this.rememberMe
        }).then((response: any) => {
            const responseCodeJson = JSON.parse(response._body);
            if (responseCodeJson.passwordExpiry !== undefined && responseCodeJson.passwordExpiry === 'true') {
                this.router.navigateByUrl('/settings');
            } else {
                this.authenticationError = false;
            this.serverError = false;
            if (this.router.url === '/register' || (/activate/.test(this.router.url)) ||
                this.router.url === '/finishReset' || this.router.url === '/requestReset' || this.router.url === '/login' || this.router.url === null) {
                this.router.navigate(['home']);
            }

            this.eventManager.broadcast({
                name: 'authenticationSuccess',
                content: 'Sending Authentication Success'
            });
            }
        }).catch((response) => {
            try {
                const responseJson = response.json();
                this.errorMessage = responseJson.error.split(',');
                if (this.errorMessage) {
                    this.serverError = true;
                    this.authenticationError = false;
                } else {
                    this.serverError = false;
                    this.authenticationError = true;
                }
            }catch (exception) {
                this.serverError = false;
                this.authenticationError = true;
            }
        });
    }

    register() {
        // this.activeModal.dismiss('to state register');
        this.router.navigate(['/register']);
    }

    requestResetPassword() {
        // this.activeModal.dismiss('to state requestReset');
        this.router.navigate(['/reset', 'request']);
    }

    removeReadOnlyAttr() {
        this.renderer.setElementAttribute(this.elementRef.nativeElement.querySelector('#username'), 'readonly', null);
        this.renderer.setElementAttribute(this.elementRef.nativeElement.querySelector('#password'), 'readonly', null);
    }

    addReadOnlyAttr() {
        this.renderer.setElementAttribute(this.elementRef.nativeElement.querySelector('#password'), 'readonly', 'readonly');
    }
}
