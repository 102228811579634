import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { SecureStorageService} from '../../../projects/ig-core/src/lib/authentication/secureStorage.service';
import { MatSnackBar } from '@angular/material';
import { ApplicationDateService } from '@app/shared/date/application-date.service';
@Injectable({
  providedIn: 'root'
})
export class LoanOdAccountsRouteGuardService implements CanActivate {
  lmsComponentAccessStatus:boolean;

  constructor(private secureStorageService: SecureStorageService,
    private _snackbar: MatSnackBar,
    private applicationDateService: ApplicationDateService) { }

  public canActivate(route: ActivatedRouteSnapshot){
    let user =this.secureStorageService.secureSessionStorage.getItem("logInUserDetails")
    // if lmsComponentAccessFlagStatus is false then passThroughAPI is failed and we won't get LMS data,
    // so we are not showing the accounts screen and displaying the error message to contact admin
    if(user.authorities.includes('ACCOUNTS')){
      this.lmsComponentAccessStatus = this.applicationDateService.getLmsComponentAccessStatus()
      if(!this.lmsComponentAccessStatus){
        this._snackbar.open("This service is temporarily unavailable, please contact administrator ", "Close", {
          duration: 6000,
        })
        return false;
      }
      return true;
    }else{
        this._snackbar.open("Permission denied ", "Close", {
            duration: 2000,
          })
    }
    return false;
  }
}