import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, DateUtils, EventManager, JhiLanguageService } from 'ng-jhipster';
import { DateConverterService } from '../shared/date/date-converter.service';
import { NameValueDto } from './name-value-dto';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdMoratoriumDtoModel } from './loan-od-moratorium-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-delete-moratorium',
    templateUrl: './loan-od-accounts-delete-moratorium.component.html'
})
export class LoanOdAccountsDeleteMoratoriumComponent implements OnInit, OnDestroy {
    onError: any;
    deleteMoratoriums: NameValueDto;
    loanOdMoratoriumDtoModel: LoanOdMoratoriumDtoModel;
    accountId: string;
    proceedIsTrue: boolean;
    proceedToResult: boolean;

    constructor(  private jhiLanguageService: JhiLanguageService,
                  private alertService: AlertService,
                  private loanODAccountsService: LoanOdAccountsService,
                  private eventManager: EventManager,
                  private route: ActivatedRoute,
                  private router: Router,
                  private dateConverter: DateConverterService,
                  private dateUtils: DateUtils
    ) {
        this.proceedIsTrue = false;
        this.proceedToResult = false;
    }
    ngOnInit() {
        this.loanOdMoratoriumDtoModel =  new LoanOdMoratoriumDtoModel();
        this.accountId = this.route.params['_value'].accountId;
        this.loanOdMoratoriumDtoModel.accountId = this.accountId;
        this.loanODAccountsService.findMoratoriumPeriodDeleteList(this.loanOdMoratoriumDtoModel.accountId).subscribe(
            (res) => {
                this.setDeleteMoratoriums(res.json());
            });
    }
    ngOnDestroy() {
    }

    private setDeleteMoratoriums(data) {
        this.deleteMoratoriums = data;
    }

    clear() {
        this.loanOdMoratoriumDtoModel = new LoanOdMoratoriumDtoModel();
        this.router.navigate(['/loan-od-accounts']);
    }

    proceed(moratoriumNum) {
        this.loanODAccountsService.getDeleteMoratoriumDetailsForMorNum(moratoriumNum, this.loanOdMoratoriumDtoModel.accountId).subscribe(
            (res) => {
                this.loanOdMoratoriumDtoModel = res.json();
                this.loanOdMoratoriumDtoModel.accountId = this.accountId;
                this.proceedIsTrue = true;
            }
        );
    }

    back() {
        this.proceedIsTrue = false;
    }

    executeError(error) {
        this.proceedIsTrue = false;
    }

    executeSuccess(result)  {
        this.proceedToResult = true;
    }

}
