import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {FeeDto} from './loan-od-accounts-fee-dto.model';
import {LoanOdAccountsService} from './loan-od-accounts.service';
import {AccountMandateDto} from './account-mandate-dto.model';

@Injectable()
export class LoanOdAccountsTransfertoanotherbranchResolve implements Resolve<AccountMandateDto> {

    constructor(private loanOdAccountsService: LoanOdAccountsService) {}

    resolve(route: ActivatedRouteSnapshot): any {
        if (route.paramMap.get('accountId')) {
            return this.loanOdAccountsService.transferToAnotherBranch(route.paramMap.get('accountId'));
        } else {
            return new AccountMandateDto();
        }
    }
}
