import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RequestOptions, Response, ResponseContentType, URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { DateUtils } from 'ng-jhipster';
import { Observable } from 'rxjs';
import { DateConverterService } from '../shared/date/date-converter.service';
import { MakerCheckerMasterAction, MakerCheckerTransaction } from '../shared/model/maker-checker-master-action.model';
import { AccountMandateDto } from './account-mandate-dto.model';
import { AccountPdcDtoModel } from './account-pdc-dto.model';
import { AccountProfileDto } from './accountt-profile-dto.model';
import { CompositeScheduledDisbursementDtoModel } from './composite-scheduled-disbursement-dto.model';
import { CompositeaccountPDCDtoModel } from './compositeaccount-PDC-Dto.model';
import { FeeDto } from './loan-od-accounts-fee-dto.model';
import { LoanOdRepaymentCompositeDto } from './loan-od-accounts-repayment-composite-dto.model';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAmendment } from './loan-od-amendment.model';
import { LoanOdComputePayOffAmountDto } from './loan-od-compute-payOff-Amount-dto.model';
import { LoanOdDisbursementComposite } from './loan-od-disbursement-composite.model';
import { LoanOdMoratoriumDtoModel } from './loan-od-moratorium-dto.model';
import { LoanOdRepaymentDto } from './loan-od-repayment-dto.model';
import { LoanOdWriteOffDtoModel } from './loan-od-write-off-dto.model';
import { ReverseCompositeDto } from './reverse-composite-dto';
import { TransactionSummaryDto } from './transaction-summary-dto.model';
import { ConfigureService } from '../build-config/configure.service';
import { map } from 'rxjs/operators';
import { InsurancePolicyDto } from './insurance-policy-dto';
import { API_URL } from '../../config/config.json'
import moment from 'moment';
import { environment } from '../../environments/environment';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
@Injectable({
    providedIn: 'root'
})
export class LoanOdAccountsService {
    private resourceUrl = 'api/loan-od-accounts';
    private baseUrl = environment.API_URL;
    public loanOdAccounts: LoanOdAccounts;
    public loanOdAccount: LoanOdAccounts;
    public loanOdAmendment: LoanOdAmendment;
    public loanOdMoratoriumDtoModel: LoanOdMoratoriumDtoModel;
    public feeDto: FeeDto;
    public accountProfileDto: AccountProfileDto;
    public transactionSummaryDto: TransactionSummaryDto;
    public transactionSummaryDtoList: TransactionSummaryDto[] = [];
    public reverseCompositeDto: ReverseCompositeDto;
    public loanOdRepaymentCompositeDto: LoanOdRepaymentCompositeDto;
    public accountMandateDto: AccountMandateDto;
    public accountPdcDtoModel: AccountPdcDtoModel[] = [];
    public loanOdRepaymentDto: LoanOdRepaymentDto;
    public loanOdWriteOffDtoModel: LoanOdWriteOffDtoModel;
    public loanOdComputePayOffAmountDto: LoanOdComputePayOffAmountDto;
    private accountId: string;
    public insurancePolicyDto: InsurancePolicyDto;

    constructor(private httpClient: HttpClient, private dateUtils: DateUtils, private router: Router, private datePipe: DatePipe,
        private dateConverter: DateConverterService) {
        this.loanOdAccount = new LoanOdAccounts();
    }

    create(loanOdAccounts: LoanOdAccounts): Observable<LoanOdAccounts> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccounts);
        copy.disbursementByDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.disbursementByDate);
        copy.accountOpenDate = this.dateConverter
            .fromServerToNgbDatePicker(loanOdAccounts.accountOpenDate);
        copy.lastArchiveDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastArchiveDate);
        copy.lastEntryDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastEntryDate);
        copy.firstDisbursementDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.firstDisbursementDate);
        copy.lastDisbursementDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastDisbursementDate);
        copy.lastDemandRunDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastDemandRunDate);
        copy.nextDemandRunDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.nextDemandRunDate);
        copy.nextDemandScheduledDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.nextDemandScheduledDate);
        copy.lastBookingDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastBookingDate);
        copy.lastRepaymentDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastRepaymentDate);
        copy.maturityDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.maturityDate);
        copy.closedOnValueDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.closedOnValueDate);
        copy.lastActivatedDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastActivatedDate);
        copy.lastFreezedDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastFreezedDate);
        copy.writeOffDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.writeOffDate);
        if (loanOdAccounts.transactionSummary !== undefined) {
            for (let i = 0; i > loanOdAccounts.transactionSummary.length; i++) {
                copy.transactionSummary[0].valueDate = this.dateUtils
                    .convertLocalDateToServer(loanOdAccounts.transactionSummary[0].valueDate);
                copy.transactionSummary[1].transactionDate = this.dateUtils
                    .convertLocalDateToServer(loanOdAccounts.transactionSummary[1].transactionDate);
                copy.transactionSummary[2].param2 = this.dateUtils
                    .convertLocalDateToServer(loanOdAccounts.transactionSummary[2].param2);
            }
        }
        copy.defaultFirstRepaymentDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.defaultFirstRepaymentDate);
        copy.minFirstRepaymentDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.minFirstRepaymentDate);
        copy.maxFirstRepaymentDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.maxFirstRepaymentDate);
        if (loanOdAccounts.accountMandateDto !== undefined) {
            copy.accountMandateDto.achStartDate = this.dateUtils
                .convertLocalDateToServer(loanOdAccounts.accountMandateDto.achStartDate);
            copy.accountMandateDto.achEndDate = this.dateUtils
                .convertLocalDateToServer(loanOdAccounts.accountMandateDto.achEndDate);
            copy.accountMandateDto.mandateOpenDate = this.dateUtils
                .convertLocalDateToServer(loanOdAccounts.accountMandateDto.mandateOpenDate);
            copy.accountMandateDto.registrationDate = this.dateUtils
                .convertLocalDateToServer(loanOdAccounts.accountMandateDto.registrationDate);
        }
        copy.lastProvisioningDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastProvisioningDate);
        copy.nextProvisioningDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.nextProvisioningDate);
        copy.udfDate1 = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.udfDate1);
        copy.udfDate2 = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.udfDate2);
        copy.lastNpaMarkingDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastNpaMarkingDate);
        copy.lastNpaUnmarkingDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastNpaUnmarkingDate);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.resourceUrl, copy, options).pipe(map((response: any) => {
            return response
        }));
    }

    update(loanOdAccounts: LoanOdAccounts): Observable<LoanOdAccounts> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccounts);
        copy.disbursementByDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.disbursementByDate);
        copy.accountOpenDate = this.dateConverter
            .fromServerToNgbDatePicker(loanOdAccounts.accountOpenDate);
        copy.lastArchiveDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastArchiveDate);
        copy.lastEntryDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastEntryDate);
        copy.firstDisbursementDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.firstDisbursementDate);
        copy.lastDisbursementDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastDisbursementDate);
        copy.lastDemandRunDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastDemandRunDate);
        copy.nextDemandRunDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.nextDemandRunDate);
        copy.nextDemandScheduledDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.nextDemandScheduledDate);
        copy.lastBookingDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastBookingDate);
        if (copy.firstRepaymentDate !== undefined) {
            copy.firstRepaymentDate = this.dateUtils
                .convertLocalDateToServer(loanOdAccounts.firstRepaymentDate);
        }
        copy.lastRepaymentDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastRepaymentDate);
        copy.maturityDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.maturityDate);
        copy.closedOnValueDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.closedOnValueDate);
        copy.lastActivatedDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastActivatedDate);
        copy.lastFreezedDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastFreezedDate);
        copy.writeOffDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.writeOffDate);
        for (let i = 0; i > loanOdAccounts.transactionSummary.length; i++) {
            copy.transactionSummary[0].valueDate = this.dateUtils
                .convertLocalDateToServer(loanOdAccounts.transactionSummary[0].valueDate);
            copy.transactionSummary[1].transactionDate = this.dateUtils
                .convertLocalDateToServer(loanOdAccounts.transactionSummary[1].transactionDate);
            copy.transactionSummary[2].param2 = this.dateUtils
                .convertLocalDateToServer(loanOdAccounts.transactionSummary[2].param2);
        }
        copy.defaultFirstRepaymentDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.defaultFirstRepaymentDate);
        copy.minFirstRepaymentDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.minFirstRepaymentDate);
        copy.maxFirstRepaymentDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.maxFirstRepaymentDate);
        copy.accountMandateDto.achStartDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.accountMandateDto.achStartDate);
        copy.accountMandateDto.achEndDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.accountMandateDto.achEndDate);
        copy.accountMandateDto.mandateOpenDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.accountMandateDto.mandateOpenDate);
        copy.accountMandateDto.registrationDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.accountMandateDto.registrationDate);
        copy.lastProvisioningDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastProvisioningDate);
        copy.nextProvisioningDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.nextProvisioningDate);
        copy.udfDate1 = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.udfDate1);
        copy.udfDate2 = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.udfDate2);
        copy.lastNpaMarkingDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastNpaMarkingDate);
        copy.lastNpaUnmarkingDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.lastNpaUnmarkingDate);
        const options = this.createRequestOptionJson();
        return this.httpClient.put(this.resourceUrl, copy, options).pipe(map((res: any) => {
            return res;
        }));
    }


    find(accountId: string): Observable<LoanOdAccounts> {

        return this.httpClient.get(`${this.baseUrl}/encore/api/loan-od-accounts/${accountId}`);
    }
    getReport(accountID): Observable<any> {
        const out = { data: null, filename: null };
        const options = this.createRequestOptionJson();
        return this.httpClient.get(`${this.baseUrl}/encore/api/loan-od-accounts/downloadTransactions/${accountID}`, options)
    }
    dowloadPDF(accountId): Observable<any> {
        const options = this.createRequestOptionJson();
        return this.httpClient.get(`${this.baseUrl}/encore/api/loan-od-accounts/download/${accountId}/PDF`, options)
    }

    downloadreport(accountId: any): Observable<any> {
        const out = { data: null, filename: null };
        const options = this.createRequestOptionJson();
        return this.httpClient.get(`${this.baseUrl}/encore/api/loan-od-accounts/downloadTransactions/${accountId}`, options).pipe(
            map((res: any) => {
                out.data = new Blob([res.blob()]);
                out.filename = res.headers.get('x-file-name');
                return out;
            }
            ));
    }

    convertLocalDateFromServer(date: string): any {
        if (date !== undefined && date != null) {
            const split = date.toString().split('-');
            const dateObj = { year: parseInt(split[0]), month: parseInt(split[1]), day: parseInt(split[2]) };
            return dateObj;
        } else {
            return undefined;
        }
    }

    convertLocalDateFromServerForDateArray(date: string): any {
        if (date !== undefined && date != null) {
            const dateObj = { year: parseInt(date[0]), month: parseInt(date[1]), day: parseInt(date[2]) };
            return dateObj;
        } else {
            return undefined;
        }
    }
    query(req?: any): Observable<any> {
        if (req && req.query) {
            let query = Object.assign({},req.query);
            for (const propName in query) {
                if (['', null, undefined].indexOf(query[propName]) > -1) {
                  delete query[propName];
                }
              }
            const options = this.createRequestOptionJson();
            options.params = { page: req.page, sort: req.sort, size: req.size, ...query } ;
            return this.httpClient.get(this.baseUrl + "/encore/" + this.resourceUrl , options);
        }
        else {
            const options = this.createRequestOptionJson();
            return this.httpClient.get(this.baseUrl + "/encore/" + this.resourceUrl, options);
        }

    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.baseUrl}/encore/${this.resourceUrl}/${id}`);
    }
    // private createRequestOption(req?: any): BaseRequestOptions {
    //     const options = new BaseRequestOptions();
    //     if (req) {
    //         const params = new URLSearchParams();
    //         params.set('page', req.page);
    //         params.set('size', req.size);
    //         if (req.sort) {
    //             params.paramsMap.set('sort', req.sort);
    //         }

    //         for (const key in req.query) {
    //             if (req.query[key] !== '' || req.query[key] !== 0) {
    //                 params.set(key, req.query[key]);
    //             }
    //         }

    //         options.search = params;
    //     }
    //     return options;
    // }
    // private createRequestOption1(req?: any): URLSearchParams {
    //     //let options = new HttpParams();
    //     const params =  new URLSearchParams();
    //     if (req) {

    //         params.set('page', req.page);
    //         params.set('size', req.size);
    //         if (req.sort) {
    //             params.set('sort', req.sort);
    //         }

    //         for (const key in req.query) {
    //             if (req.query[key] !== '' || req.query[key] !== 0) {
    //                 params.set(key, req.query[key]);
    //             }
    //         }

    //        // options.search = params;
    //     }
    //     return params;
    // }
    getInsuranceProductList() {
        return this.httpClient.get(`${this.baseUrl}/encore/api/insurance-policy/getProductList`);
    }
    getPolicyList(accountId) {
        return this.httpClient.get(`${this.baseUrl}/encore/api/insurance-policy/${accountId}`);
    }
    fnPrint(policyNum) {
        return this.httpClient.get(`${this.baseUrl}/encore/api/insurance-policy/getPolicyCertificate/${policyNum}`);
    }
    fnCancel(applicationId) {
        return this.httpClient.get(`${this.baseUrl}/encore/api/insurance-policy/cancelPolicyCertificate/${applicationId}`);
    }

    getApplicantList(accountId) {
        return this.httpClient.get(`${this.baseUrl}/encore/api/insurance-policy/getApplicants/${accountId}`);
    }
    fnProceed(dto: InsurancePolicyDto): Observable<any> {
        const copy: InsurancePolicyDto = Object.assign({}, dto);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/insurance-policy/getInsurancePremium`, copy, options);
    }
    fnPolicyExecute(dto: InsurancePolicyDto): Observable<any> {
        const copy: InsurancePolicyDto = Object.assign({}, dto);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/insurance-policy/sendPolicyApplication`, copy, options);
    }
    getProductCodesList(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getProductCodesList`);
    }
    getGlSubHeadsList(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getGlSubHeadsList`);
    }
    getOperationalStatus(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getOperationStatusList`);
    }
    getActionStatusList(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getActionStatusList`);
    }
    getTransactionType(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/geteWriteOffTransactionTypes`);
    }
    getBranches(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/loan-od-accounts/getBranches`);
    }
    getAccountCodes(instrument): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getAccountCodesByGroup?currencyCode=` + instrument);
    }
    getAccountByCodes(instrument): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getAccountCodes?currencyCode=` + instrument);
    }
    getStatus(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/loan-od-accounts/getStatus`);
    }
    /* getCountries(): Observable<Response> {
        return this.httpClient.get(`api/getCountries`);
    } */
    getStates(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getStates`);
    }
    /* getCities(): Observable<Response> {
        return this.httpClient.get(`api/getCities`);
    } */

    findDisburseEntry(accountId: string): Observable<LoanOdAccounts> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/${accountId}`).pipe(map((res: Response) => res.json()));
    }

    computeFees(accountId: string, amount: number, currencyCode: string): Observable<FeeDto[]> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/computeFees/${accountId}/${amount}/${currencyCode}`).pipe(map((res: Response) => res.json()));
    }

    computeFeeForFeeAmount(accountId: string, amount: number, currencyCode: string, feeName: string): Observable<FeeDto[]> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/computeFeeForFeeAmount/${accountId}/${amount}/${currencyCode}/${feeName}`).pipe(map((res: any) => {
            return res;
        }));
    }

    disburseExecutePage(loanOdAccounts: LoanOdAccounts) {
        this.router.navigate(['loan-od-accounts/disburseExecute', loanOdAccounts]);
    }

    disburseExecute(loanOdAccounts: any): Observable<LoanOdAccounts> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccounts);
        return this.httpClient.put(`${this.baseUrl}/encore/api/loan-od-accounts/disburse/execute`, copy).pipe(map((res: any) => {
            this.loanOdAccounts = res;
            return this.loanOdAccounts;
        }));
    }

    disburseResult(transactionSummaryDto: TransactionSummaryDto) {
        this.router.navigate(['loan-od-accounts/disburse/result', transactionSummaryDto]);
    }

    // setLoanODProducts(loanODProducts) {
    //     this.loanODProducts = loanODProducts;
    // }
    // getLoanODProducts(): LoanOdProductCompositeDto {
    //     return this.loanODProducts;
    // }

    setLoanOdWriteOffDtoModel(loanOdWriteOffDtoModel) {
        this.loanOdWriteOffDtoModel = loanOdWriteOffDtoModel;
    }
    getLoanOdWriteOffDtoModel(): LoanOdWriteOffDtoModel {
        return this.loanOdWriteOffDtoModel;
    }
    setLoanOdComputePayOffAmountDto(loanOdComputePayOffAmountDto) {
        this.loanOdComputePayOffAmountDto = loanOdComputePayOffAmountDto;
    }
    getLoanOdComputePayOffAmountDto(): LoanOdComputePayOffAmountDto {
        return this.loanOdComputePayOffAmountDto;
    }
    setAccountPdcDtoModel(accountPdcDtoModel) {
        this.accountPdcDtoModel = accountPdcDtoModel;
    }
    getAccountPdcDtoModel(): AccountPdcDtoModel[] {
        return this.accountPdcDtoModel;
    }
    setDisburseExecute(loanOdAccounts) {
        this.loanOdAccounts = loanOdAccounts;
    }
    getDisburseExecute(): LoanOdAccounts {
        return this.loanOdAccounts;
    }
    setLoanOdAccount(loanOdAccount) {
        this.loanOdAccount = loanOdAccount;
    }
    getLoanOdAccount(): LoanOdAccounts {
        return this.loanOdAccount;
    }

    setPostTransactions(transactionSummaryDto) {
        this.transactionSummaryDto = transactionSummaryDto;
    }
    getPostTransactions(): TransactionSummaryDto {
        return this.transactionSummaryDto;
    }

    getDisburseResult(): LoanOdAccounts {
        return this.loanOdAccounts;
    }

    freeze(accountId: string): Observable<AccountProfileDto> {
        return this.httpClient.get(`${API_URL}/encore/${this.resourceUrl}/${accountId}`).pipe(map((res: any) => {
            return res;
        }));
    }

    freezeExecute(loanOdAmendment: LoanOdAmendment): Observable<LoanOdAmendment> {
        return this.httpClient.post(`${this.baseUrl}/encore/api/loans/accounts/setStatus`, loanOdAmendment).pipe(map((res: any) => {
            return res;
        }));
    }
    setStatusExecute(loanOdAmendment: LoanOdAmendment): Observable<LoanOdAmendment> {
        return this.httpClient.post(`${this.baseUrl}/encore/api/loans/accounts/setStatus/execute`, loanOdAmendment).pipe(map((res: any) => {
            return res;
        }));
    }
    getFreezeExecute(): AccountProfileDto {
        return this.accountProfileDto;
    }

    findChargeFeeEntry(accountId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/loan-od-accounts/chargeFee/${accountId}`);
    }

    chargeFeeExecute(feeDto: FeeDto): Observable<FeeDto> {
        const copy: FeeDto = Object.assign({}, feeDto);
        return this.httpClient.post(`${this.baseUrl}/encore/api/loan-od-accounts/chargeFee/execute`, copy).pipe(map((res: any) => {
            this.feeDto = res;
            return this.feeDto;
        }));
    }
    chargeFeeResult(dto: FeeDto): Observable<any> {
        const copy: FeeDto = Object.assign({}, dto);
        return this.httpClient.post(`${this.baseUrl}/encore/api/loan-od-accounts/chargeFee/result`, copy).pipe(map((res: any) => {
            return res
        }));
    }

    setLoanOdRepaymentDto(loanOdRepaymentDto) {
        this.loanOdRepaymentDto = loanOdRepaymentDto;
    }
    getLoanOdRepaymentDto(): LoanOdRepaymentDto {
        return this.loanOdRepaymentDto;
    }
    createLoanAccountPrecheck(loanOdAccounts: LoanOdAccounts): Observable<any> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccounts);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/loan-od-accounts/precheck`, copy, options).pipe(map((res: any) => {
            return res;
        }));;
    }
    createLoanAccountCreateMandate(files: FormGroup, accountMandateDto: AccountMandateDto): Observable<any> {
        const copy: AccountMandateDto = Object.assign({}, accountMandateDto);
        copy.achStartDate = this.dateUtils.convertLocalDateToServer(copy.achStartDate);
        copy.achEndDate = this.dateUtils.convertLocalDateToServer(copy.achEndDate);
        copy.mandateOpenDate = this.dateUtils.convertLocalDateToServer(copy.mandateOpenDate);
        let formData: FormData;
        formData = this.prepareFormData(files);
        formData.append('customer', JSON.stringify(copy));
        return this.httpClient.post(`${this.baseUrl}/encore/api/loan-od-accounts/createAccountManadateList`, formData);
    }
    updateMandate(files: FormGroup, accountMandateDto: AccountMandateDto): Observable<any> {
        const copy: AccountMandateDto = Object.assign({}, accountMandateDto);
        copy.achStartDate = this.dateUtils.convertLocalDateToServer(copy.achStartDate);
        copy.achEndDate = this.dateUtils.convertLocalDateToServer(copy.achEndDate);
        copy.mandateOpenDate = this.dateUtils.convertLocalDateToServer(copy.mandateOpenDate);
        let formData: FormData;
        formData = this.prepareFormData(files);
        formData.append('customer', JSON.stringify(copy));
        return this.httpClient.post(`${this.baseUrl}/encore/api/amend/updateMandate`, formData);
    }
    getAccountMandate(accountId: string, id: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/amend/` + accountId + '/' + id);
    }
    private prepareFormData(files: FormGroup): FormData {
        const formData: FormData = new FormData();
        Object.keys(files.controls).forEach((key) => {
            const file = files.get(key).value;
            if (file !== null) {
                formData.append(key, file, file.name);
            }
        });
        return formData;
    }

    getChargeFeeExecute(): FeeDto {
        return this.feeDto;
    }
    getChargeFeeResult(): FeeDto {
        return this.feeDto;
    }
    getProductList(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getProductList`);
    }
    getBanks(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/banks/bank`);
    }
    getInstruments(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getInstruments`);
    }
    getRepaymentEffects(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getRepaymentEffects`);
    }
    getAccountCodesByGroup(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getAccountCodesByGroup`);
    }
    getMoratoriumTypes(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getMoratoriumTypes`);
    }
    findBranchSetCodeforProductCode(productCode: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/findBranchSetCodeforProductCode/${productCode}`);
    }
    findMoratoriumPeriodDeleteList(accountId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getDeleteMoratoriumList/${accountId}`);
    }
    getDeleteMoratoriumDetailsForMorNum(moratoriumNum: number, accountId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getDeleteMoratoriumDetailsForMorNum/${accountId}/${moratoriumNum}`);
    }
    deleteMoratorium(moratoriumDto: LoanOdMoratoriumDtoModel) {
        const copy: LoanOdMoratoriumDtoModel = Object.assign({}, moratoriumDto);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/deleteMoratorium`, copy, options);
    }

    getProductForProductCode(productCode: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getProductForProductCode/${productCode}`);
    }

    postTransactions(transactionSummaryDto?: TransactionSummaryDto[]): Observable<TransactionSummaryDto> {
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/loans/accounts/postTransactions`, transactionSummaryDto, options).pipe(map((res: any) => {
            return res;
        }));
    }

    findReverseEntry(accountId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/reverselist/${accountId}`).pipe(map((res: any) => {
            return res;
        }));
    }

    reverseResult(reverseCompositeDto: ReverseCompositeDto): Observable<ReverseCompositeDto> {
        const copy: ReverseCompositeDto = Object.assign({}, reverseCompositeDto);
        return this.httpClient.put(`${this.baseUrl}/encore/api/loan-od-accounts/reverse/result`, copy).pipe(map((res: any) => {
            this.reverseCompositeDto = res;
            return this.reverseCompositeDto;
        }));
    }

    reverseExecute(accountId: string, transactionId: string, transactionName: string, valueDate: any): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/reverse/${accountId}/${transactionId}/${transactionName}/${valueDate}`);
        // return this.httpClient.get(`api/reverse/${accountId}/${transactionId}/${transactionName}/${valueDate}`, { 'responseType': 0 });
    }

    getReverseExecute(): ReverseCompositeDto {
        return this.reverseCompositeDto;
    }
    getReverseResult(): ReverseCompositeDto {
        return this.reverseCompositeDto;
    }
    findScheduledDemand(accountId: string): Observable<LoanOdAccounts> {
        return this.httpClient.get(`${this.resourceUrl}/${accountId}`).pipe(map((res: Response) => res.json()));
    }
    scheduledDemandExecutePage(loanOdAccounts: LoanOdAccounts) {
        this.router.navigate(['loan-od-accounts/demandExecute', loanOdAccounts]);
    }
    findPrepaymentEntry(accountId: string): Observable<LoanOdRepaymentCompositeDto> {
        return this.httpClient.get(`${this.resourceUrl}/${accountId}`).pipe(map((res: Response) => res.json()));
    }

    prepaymentExecutePage(loanOdAccounts: LoanOdAccounts) {
        this.router.navigate(['loan-od-accounts/prepaymentExecute', loanOdAccounts]);
    }

    findAdvanceRepaymentEntry(accountId: string): Observable<LoanOdAccounts> {
        return this.httpClient.get(`${this.resourceUrl}/${accountId}`).pipe(map((res: Response) => res.json()));
    }

    advanceRepaymentExecutePage(loanOdAccounts: LoanOdAccounts) {
        this.router.navigate(['loan-od-accounts/advanceRepaymentExecute', loanOdAccounts]);
    }
    findPreclosureEntry(accountId: string): Observable<LoanOdRepaymentCompositeDto> {
        return this.httpClient.get(`${this.resourceUrl}/${accountId}`).pipe(map((res: Response) => res.json()));
    }

    preclosureRepaymentExecutePage(loanOdAccounts: LoanOdAccounts) {
        this.router.navigate(['loan-od-accounts/preClosureExecute', loanOdAccounts]);
    }

    findFeePaymentEntry(accountId: string): Observable<LoanOdRepaymentCompositeDto> {
        return this.httpClient.get(`${this.resourceUrl}/${accountId}`).pipe(map((res: Response) => res.json()));
    }
    repayExecute(loanOdAccounts: LoanOdAccounts): Observable<LoanOdAccounts> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccounts);
        return this.httpClient.put(`${this.baseUrl}/encore/api/loan-od-accounts/repay/execute`, copy).pipe(map((res: any) => {
            this.loanOdRepaymentCompositeDto = res;
            return this.loanOdRepaymentCompositeDto;
        }));
    }

    repayResult(transactionSummaryDto: TransactionSummaryDto): Observable<TransactionSummaryDto> {
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/loans/accounts/postTransactions`, transactionSummaryDto, options).pipe(map((res: any) => {
            return res;
        }));
    }

    getRepayExecute(): TransactionSummaryDto {
        return this.transactionSummaryDto;
    }

    setRepayExecute(transactionSummaryDto) {
        this.transactionSummaryDto = transactionSummaryDto;
    }
    getRepayResult(): LoanOdRepaymentCompositeDto {
        return this.loanOdRepaymentCompositeDto;
    }

    setRepayResult(loanOdRepaymentCompositeDto) {
        this.loanOdRepaymentCompositeDto = loanOdRepaymentCompositeDto;
    }

    amendAmount(accountId: string): Observable<AccountMandateDto> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/loan-od-accounts/getAccountManadateList/${accountId}`).pipe(map((res: Response) => res.json()));
    }

    getAmendAmount(): AccountMandateDto {
        return this.accountMandateDto;
    }

    setElectronicMandate(accountId: string): Observable<AccountMandateDto> {
        return this.httpClient.get(`${this.resourceUrl}/getAccountManadateList/${accountId}`).pipe(map((res: Response) => res.json()));
    }

    repaymentDateEntry(loanOdAmendment: LoanOdAmendment): Observable<LoanOdAmendment> {
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/amendRepaymentDate`, loanOdAmendment, options).pipe(map((res: any) => {
            return res;
        }));
    }

    amendTenure(loanOdAmendment: LoanOdAmendment): Observable<LoanOdAmendment> {
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/amendTenure`, loanOdAmendment, options).pipe(map((res: any) => {
            return res;
        }));
    }

    amendInstallmentAmount(loanOdAmendment: LoanOdAmendment): Observable<LoanOdAmendment> {
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/amendInstallmentAmount`, loanOdAmendment, options).pipe(map((res: any) => {
            return res;
        }));
    }

    createMoratoriumEntry(accountId: any): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}api/createMoratorium/entry/` + accountId);
    }

    createMoratorium(moratorium: LoanOdMoratoriumDtoModel): Observable<LoanOdMoratoriumDtoModel> {
        const options = this.createRequestOptionJson();
        const copy: LoanOdMoratoriumDtoModel = JSON.parse(JSON.stringify(moratorium));
        copy.valueDate = this.dateConverter.fromDateObjectToNgbDatePicker(moratorium.valueDate);
        copy.startDate = this.dateConverter.fromDateObjectToNgbDatePicker(moratorium.startDate);
        copy.valueDate = this.dateUtils.convertLocalDateToServer(copy.valueDate);
        copy.startDate = this.dateUtils.convertLocalDateToServer(copy.startDate);
        return this.httpClient.post(`${this.baseUrl}/encore/api/createMoratorium`, copy, options).pipe(map((res: any) => {
            return res;
        }));
    }

    amendGuarantor(loanOdAccounts: LoanOdAccounts): Observable<Response> {
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/amendGuarantor`, loanOdAccounts, options).pipe(map((res: any) => {
            return res;
        }));
    }
    getPostDatedCheques(accountId: string): Observable<AccountPdcDtoModel> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getPostDatedCheques/${accountId}`).pipe(map((res: Response) => res.json()));
    }

    amendPenalInterestRateEntry(accountId: string): Observable<LoanOdAccounts> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/amendPenalInterest/` + accountId).pipe(map((res: Response) => {
            return res.json();
        }));
    }

    amendPenalInterestRate(loanOdAccounts: any): Observable<LoanOdAccounts> {
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/amendPenalInterest`, loanOdAccounts, options).pipe(map((res: any) => {
            return res;
        }));
    }

    setPostDatedCheques(compositeaccountPDCDtoModel: CompositeaccountPDCDtoModel): Observable<CompositeaccountPDCDtoModel> {
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/setPostDatedCheques`, compositeaccountPDCDtoModel, options).pipe(map((res: any) => {
            return res;
        }));
    }

    transferToAnotherBranch(loanOdAccounts: any): Observable<LoanOdAccounts> {
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/transferToAnotherBranch`, loanOdAccounts, options).pipe(map((res: any) => {
            return res;
        }));
    }

    createMandate(accountId: string): Observable<AccountMandateDto> {
        return this.httpClient.get(`${this.resourceUrl}/amend/create/${accountId}`).pipe(map((res: any) => res));
    }

    viewDetails(id: number): Observable<LoanOdAccounts> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res: any) => res));
    }

    getViewDetails(): LoanOdAccounts {
        return this.loanOdAccounts;
    }
    getCountries(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getCountries`);
    }
    getState(countryCode: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getState/${countryCode}`);
    }
    getCities(countryCode: string, stateCode: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getCities/${countryCode}/${stateCode}`);
    }
    getDistrict(countryCode: string, stateCode: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getDistrict/${countryCode}/${stateCode}`);
    }
    private createRequestOptionJson(): any {
        const header = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return { headers: header };
    }

    setLoanAmendment(loanOdAmendment) {
        this.loanOdAmendment = loanOdAmendment;
    }
    getLoanAmendment(): LoanOdAmendment {
        return this.loanOdAmendment;
    }

    setLoanOdMoratoriumDtoModel(loanOdMoratoriumDtoModel) {
        this.loanOdMoratoriumDtoModel = loanOdMoratoriumDtoModel;
    }
    getLoanOdMoratoriumDtoModel(): LoanOdMoratoriumDtoModel {
        return this.loanOdMoratoriumDtoModel;
    }

    setTransactionSummaryDto(transactionSummaryDtoList) {
        this.transactionSummaryDtoList = transactionSummaryDtoList;
    }
    getTransactionSummaryDto(): TransactionSummaryDto[] {
        return this.transactionSummaryDtoList;
    }

    amendNormalInterestEntry(accountId: string): Observable<LoanOdAccounts> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/amendNormalInterest/${accountId}`)
            .pipe(map((res: any) => res))
    }

    amendNormalInterestExecute(loanOdAccounts: LoanOdAccounts): Observable<any> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccounts);
        copy.openedOnDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.openedOnDate);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore' + this.resourceUrl + '/amendNormalInterest/execute'
            , copy, options).pipe(map((res: any) => {
                return res;
            }));
    }

    amendNormalInterestResult(loanOdAccounts: LoanOdAccounts): Observable<any> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccounts);
        copy.openedOnDate = this.dateUtils
            .convertLocalDateToServer(this.dateConverter
                .fromDateObjectToNgbDatePicker(loanOdAccounts.openedOnDate));
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore' + this.resourceUrl + '/amendNormalInterest/result'
            , copy, options);
    }

    removePostDatedCheques(compositeaccountPDCDtoModel?: CompositeaccountPDCDtoModel): Observable<CompositeaccountPDCDtoModel> {
        const copy: CompositeaccountPDCDtoModel = Object.assign({}, compositeaccountPDCDtoModel);
        const options = this.createRequestOptionJson();
        return this.httpClient.post('api/removePostDatedCheques', copy, options).pipe(map((res: any) => {
            return res;
        }));
    }

    amendLoanAmountEntry(accountId: string): Observable<LoanOdAccounts> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/amendLoanAmount/${accountId}`)
            .pipe(map((res: Response) => res.json()))
    }

    amendLoanAmountExecute(loanOdAccounts: LoanOdAccounts): Observable<LoanOdAccounts> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccounts);
        copy.openedOnDate = this.dateUtils
            .convertLocalDateToServer(loanOdAccounts.openedOnDate);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore' + this.resourceUrl + '/amendLoanAmount/execute'
            , copy, options).pipe(map((res: any) => {
                return res;
            }));
    }

    amendLoanAmountResult(loanOdAccounts: LoanOdAccounts): Observable<any> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccounts);
        copy.openedOnDate = this.dateUtils
            .convertLocalDateToServer(this.dateConverter
                .fromServerToNgbDatePicker(loanOdAccounts.openedOnDate));
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore' + this.resourceUrl + '/amendLoanAmount/result'
            , copy, options);
    }

    amendMaturityDateEntry(accountId: string): Observable<LoanOdAmendment> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/amendMaturityDate/${accountId}`)
            .pipe(map((res: any) => res))
    }

    amendMaturityDateExecute(amendmentDto: LoanOdAmendment): Observable<LoanOdRepaymentDto> {
        const copy: LoanOdAmendment = Object.assign({}, amendmentDto);
        copy.valueDate = this.dateUtils.convertLocalDateToServer(amendmentDto.valueDate);
        copy.maturityDate = this.dateUtils.convertLocalDateToServer(amendmentDto.maturityDate);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore' + this.resourceUrl + '/amendMaturityDate/execute'
            , copy, options).pipe(map((res: any) => {
                return res;
            }));
    }

    amendMaturityDateResult(amendmentDto: LoanOdAmendment): Observable<any> {
        const copy: LoanOdAmendment = Object.assign({}, amendmentDto);
        copy.valueDate = this.dateUtils
            .convertLocalDateToServer(this.dateConverter
                .fromDateObjectToNgbDatePicker(amendmentDto.valueDate));
        copy.newDate = this.dateUtils
            .convertLocalDateToServer(this.dateConverter
                .fromDateObjectToNgbDatePicker(amendmentDto.maturityDate));
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore' + this.resourceUrl + '/amendMaturityDate/result'
            , copy, options);
    }

    securityDepositRefundEntry(accountId: string): Observable<LoanOdRepaymentDto> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/securityDepositRefund/${accountId}`)
            .pipe(map((res: any) => res))
    }

    securityDeposit(loanOdRepaymentDto: LoanOdRepaymentDto): Observable<LoanOdRepaymentDto> {
        const copy: LoanOdRepaymentDto = Object.assign({}, loanOdRepaymentDto);
        copy.valueDate = this.dateUtils.convertLocalDateToServer(
            this.dateConverter
                .fromDateObjectToNgbDatePicker(loanOdRepaymentDto.valueDate));
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/api/securityDepositPayment`
            , copy, options).pipe(map((res: any) => {
                return res;
            }));
    }

    setDisbursementSchedule(compositeScheduledDisbursementDtoModel: CompositeScheduledDisbursementDtoModel): Observable<CompositeScheduledDisbursementDtoModel[]> {
        const copy: CompositeScheduledDisbursementDtoModel = Object.assign({}, compositeScheduledDisbursementDtoModel);
        copy.scheduledDisbursements.forEach((disbursement) => {
            disbursement.disbursementDate = this.dateUtils
                .convertLocalDateToServer(disbursement.valueDate);
        });
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/api/setDisbursementSchedule`
            , copy, options).pipe(map((res: any) => {
                return res;
            }));
    }

    securityDepositRefundResult(loanOdAccounts: LoanOdRepaymentDto): Observable<any> {
        const copy: LoanOdRepaymentDto = Object.assign({}, loanOdAccounts);
        copy.valueDate = this.dateUtils
            .convertLocalDateToServer(this.dateConverter
                .fromDateObjectToNgbDatePicker(loanOdAccounts.valueDate));
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore' + this.resourceUrl + '/securityDepositRefund/result'
            , copy, options);
    }

    bulkUpload(form: FormData, key: string): Observable<any> {
        let urlString: string;
        if (key === 'accountOpening') {
            urlString = 'uploadAccountOpening';
        }
        if (key === 'disbursements') {
            urlString = 'uploadDisbursements';
        }
        if (key === 'repayments') {
            urlString = 'uploadRepayments';
        }
        if (key === 'securitization') {
            urlString = 'uploadSecuritization';
        }
        if (key === 'transactions') {
            urlString = 'uploadTransact';
        }
        if (key === 'customerBranchTransfer') {
            urlString = 'uploadCustomerBranchTransfer';
        }
        if (key === 'retrospect') {
            urlString = 'uploadRetrospect';
        }
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/' + urlString, form);
    }

    queryUnassignedPostDatedCheques(req): Observable<any> {
        const options = this.createRequestOptionJson();
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/unassignedPostDatedCheques', options);
    }

    queryListPostDatedCheques(req): Observable<any> {
        const options = this.createRequestOptionJson();
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/listPostDatedCheques', options);
    }

    queryScheduledDisbursements(req): Observable<any> {
        const options = this.createRequestOptionJson();
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/scheduledDisbursements', options);
    }

    setTag(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/setTag');
    }

    updateTags(configurationPropertyDto): Observable<any> {
        const copy = Object.assign({}, configurationPropertyDto);
        const options = this.createRequestOptionJson();
        return this.httpClient.put(this.baseUrl + '/encore/' + this.resourceUrl + '/updateTags'
            , copy, options);
    }

    assignPDCs(accountPDCs): Observable<any> {
        const copy = Object.assign({}, accountPDCs);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/accountPDCs/assign'
            , copy, options);
    }

    rejectPDCs(accountPDCs): Observable<any> {
        const copy = Object.assign({}, accountPDCs);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/accountPDCs/reject'
            , copy, options);
    }

    convertLocalDateToServer(date: any): string {
        if (date === null || date === undefined) {
            return null;
        }
        const newDate = new Date(date[0], date[1] - 1, date[2]);
        return this.datePipe.transform(newDate, 'yyyy-MM-dd');
    }

    queryCheckTransactions(req): Observable<any> {
        const options = this.createRequestOptionJson();
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/checkTransactions', options);
    }

    approveCheckTransaction(transactionId): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/checkTransactions/approve/' + transactionId);
    }

    rejectCheckTransaction(transactionId): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/checkTransactions/reject/' + transactionId);
    }

    writeOffRecoveryDetails(accountId: string): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/getWriteOffRecovery/' + accountId);
    }

    writeOff(loanOdWriteOffDtoModel: any): Observable<LoanOdWriteOffDtoModel> {
        const copy: LoanOdWriteOffDtoModel = Object.assign({}, loanOdWriteOffDtoModel);
        const options = this.createRequestOptionJson();
        return this.httpClient.post('api/writeOff'
            , copy, options).pipe(map((res: any) => {
                return res;
            }));
    }

    writeOffRecovery(loanOdWriteOffDtoModel: LoanOdWriteOffDtoModel): Observable<LoanOdWriteOffDtoModel> {
        const copy: LoanOdWriteOffDtoModel = Object.assign({}, loanOdWriteOffDtoModel);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/writeOffRecovery'
            , copy, options).pipe(map((res: any) => {
                return res;
            }));
    }

    confirmCheckTransaction(checkerTransactionDto): Observable<any> {
        const copy = Object.assign({}, checkerTransactionDto);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/checkTransactions/confirm', copy, options);
    }

    getEnumeration(classifier: string): Observable<Response> {
        return this.httpClient.get(`${this.baseUrl}/api/getEnumeration/` + classifier).pipe(map((res: any) => {
            return res;
        }));
    }

    getLocationCodes(customerId1: string): Observable<Response> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getLocationCodes/` + customerId1).pipe(map((res: any) => {
            return res;
        }));
    }

    getFrequencies(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getFrequencies`);
    }

    getAccountTypes(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getAccountTypes`);
    }

    getRegistrationStatus(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getRegistrationStatus`);
    }

    getAccountCodesList(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getAccountCodeList`);
    }

    getStatusList(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/getMakerCheckerStatus');
    }

    transactionsMakerCheckerQuery(req?: any): Observable<any> {
        const options = this.createRequestOptionJson();
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/makerCheckerTransactions', options);
    }

    transactionsMakerCheckerApproveEntry(id: number): Observable<MakerCheckerTransaction> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/makerCheckerTransactions/approveEntry/${id}`).pipe(map((res: Response) => {
            return res.json();
        }));
    }

    transactionsMakerCheckerRejectEntry(id: number): Observable<MakerCheckerTransaction> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/makerCheckerTransactions/rejectEntry/${id}`).pipe(map((res: Response) => {
            return res.json();
        }));
    }

    transactionsMakerCheckerExecute(makerCheckerMaster: MakerCheckerTransaction): Observable<any> {
        const copy: MakerCheckerTransaction = Object.assign({}, makerCheckerMaster);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/makerCheckerTransactions/execute', copy, options);
    }

    makerCheckerQuery(req?: any): Observable<any> {
        const options = this.createRequestOptionJson();
        return this.httpClient.get(this.baseUrl + '/encore/' + this.resourceUrl + '/makerChecker', options);
    }

    makerCheckerApproveEntry(id: number): Observable<MakerCheckerMasterAction> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/makerChecker/approveEntry/${id}`).pipe(map((res: Response) => {
            return res.json();
        }));
    }

    makerCheckerRejectEntry(id: number): Observable<MakerCheckerMasterAction> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/makerChecker/rejectEntry/${id}`).pipe(map((res: Response) => {
            return res.json();
        }));
    }

    makerCheckerExecute(makerCheckerMaster: MakerCheckerMasterAction): Observable<any> {
        const copy: MakerCheckerMasterAction = Object.assign({}, makerCheckerMaster);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/makerChecker/execute', copy, options);
    }

    makerCheckerView(id: number): Observable<LoanOdAccounts> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/makerChecker/view/${id}`).pipe(map((res: any) => {
            return res;
        }));
    }

    getChargeFeeValues(accountId: string): Observable<string[]> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/getChargeFeeValues/${accountId}`).pipe(map((res: any) => {
            return res;
        }));
    }

    repayEntry(accountId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/repay/${accountId}`);
    }

    feePaymentExecute(loanOdRepaymentCompositeDto: any): Observable<any> {
        const copy: any = Object.assign({}, loanOdRepaymentCompositeDto);
        const options = this.createRequestOptionJson();
        copy.repaymentDate = this.dateUtils.convertLocalDateToServer(loanOdRepaymentCompositeDto.repaymentDate);
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/repay/execute', copy, options).pipe(map(
            (res: any) => {
                return res;
            }));
    }

    feePaymentResult(loanOdRepaymentCompositeDto: LoanOdRepaymentCompositeDto): Observable<LoanOdRepaymentCompositeDto> {
        const copy: LoanOdRepaymentCompositeDto = Object.assign({}, loanOdRepaymentCompositeDto);
        const options = this.createRequestOptionJson();
        if (copy.feeNumStr === '') {
            copy.feeNumStr = null;
        }
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/repay/result', copy, options).pipe(map((res: any) => {
            return res;
        }));
    }

    disburseEntry(accountId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/disburse/${accountId}`);
    }

    disbursementExecute(loanOdDisbursementComposite: LoanOdDisbursementComposite): Observable<LoanOdDisbursementComposite> {
        const copy: LoanOdDisbursementComposite = JSON.parse(JSON.stringify(loanOdDisbursementComposite));
        const options = this.createRequestOptionJson();
        copy.disbursement.valueDate = copy.disbursement.valueDate ? moment(new Date(copy.disbursement.valueDate.year,copy.disbursement.valueDate.month - 1, copy.disbursement.valueDate.day)).format('YYYY-MM-DD') : null;
        copy.firstRepaymentDate = copy.firstRepaymentDate ? moment(new Date(copy.firstRepaymentDate.year, copy.firstRepaymentDate.month - 1, copy.firstRepaymentDate.day)).format('YYYY-MM-DD') : null;
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/disburse/execute', copy, options).pipe(map((res: any) => {
            return res;
        }));
    }

    disbursementResult(loanOdDisbursementComposite: LoanOdDisbursementComposite): Observable<LoanOdDisbursementComposite> {
        const copy: LoanOdDisbursementComposite = Object.assign({}, loanOdDisbursementComposite);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + '/encore/' + this.resourceUrl + '/disburse/result', copy, options).pipe(map((res: any) => {
            return res;
        }));
    }

    amendAccountOpenDateEntry(accountId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/amendAccountOpenDate/${accountId}`);
    }

    amendAccountOpenDateResult(amendmentDto: LoanOdAmendment): Observable<any> {
        const copy: LoanOdAmendment = Object.assign({}, amendmentDto);
        copy.valueDate = this.dateUtils
            .convertLocalDateToServer(this.dateConverter
                .fromDateObjectToNgbDatePicker(amendmentDto.valueDate));
        copy.newDate = this.dateUtils
            .convertLocalDateToServer(this.dateConverter
                .fromDateObjectToNgbDatePicker(amendmentDto.maturityDate));
        copy.maturityDate = null;
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + "/encore/" + this.resourceUrl + '/amendAccountOpenDate/result'
            , copy, options);
    }

    amendMoratorium(accountId: string): Observable<any> {
        return this.httpClient.get(this.baseUrl + "/encore/" + this.resourceUrl + '/amendMoratorium/' + accountId);
    }

    amendMoratoriumResult(moratorium: LoanOdMoratoriumDtoModel): Observable<any> {
        const copy: LoanOdMoratoriumDtoModel = JSON.parse(JSON.stringify(moratorium));
        copy.valueDate = this.dateUtils.convertLocalDateToServer(moratorium.valueDate);
        copy.startDate = this.dateUtils.convertLocalDateToServer(moratorium.startDate);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(this.baseUrl + "/encore/" + this.resourceUrl + '/amendMoratorium/result'
            , copy, options);
    }

    computePayOffAmount(accountId: string, valueDate?: any): Observable<any> {
        if (valueDate) {
            return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/computePayOffAmount/${accountId}?valueDate=${valueDate}`);
        }
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/computePayOffAmount/${accountId}`);
    }

    amendInstallmentAmountResult(amendmentDto: LoanOdAmendment): Observable<LoanOdAmendment> {
        const copy: LoanOdAmendment = Object.assign({}, amendmentDto);
        const options = this.createRequestOptionJson();
        return this.httpClient.post(`${this.baseUrl}/encore/api/amendInstallAmount/result`, copy, options).pipe(map((res: any) => {
            return res;
        }));
    }

    amendTenureResult(amendmentDto: LoanOdAmendment): Observable<LoanOdAmendment> {
        const copy: LoanOdAmendment = Object.assign({}, amendmentDto);
        const options = this.createRequestOptionJson();
        copy.amount = null;
        return this.httpClient.post(`${this.baseUrl}/encore/api/amendTenure/result`, copy, options).pipe(map((res: any) => {
            return res;
        }));
    }

    collectionsEntry(instrument?: string, branchCode?: string, productCode?: string, demandDate?: any): Observable<any> {
        // const params = new URLSearchParams();
        if (instrument) {
            // params.append('instrument', instrument);
            // const instrument = instrument;
            return this.httpClient.get(`${this.baseUrl}/encore/api/collections/list?instrument=` + instrument);
        }
        if (branchCode) {
            // params.append('branchCode', branchCode);
            return this.httpClient.get(`${this.baseUrl}/encore/api/collections/list?branchCode=` + branchCode);
        }
        if (productCode) {
            // params.append('productCode', productCode);
            return this.httpClient.get(`${this.baseUrl}/encore/api/collections/list?productCode=` + productCode);
        }
        if (demandDate) {
            // params.append('demandDate', demandDate);
            return this.httpClient.get(`${this.baseUrl}/encore/api/collections/list?demandDate=` + demandDate);
        }
        if (!instrument && !branchCode && !productCode && !demandDate) {
            return this.httpClient.get(`${this.baseUrl}/encore/api/collections/list`);
        }

    }

    downloadCollectionsEntry(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/downloadCollections`);
    }

    downloadCollectionsExecute(report: LoanOdAccounts): Observable<any> {
        const copy: LoanOdAccounts = Object.assign({}, report);
        copy.openedOnDate = this.dateUtils
            .convertLocalDateToServer(report.openedOnDate);
        const options = this.createRequestOptionJson();
        options.responseType = ResponseContentType.Blob;
        return this.httpClient.post(`${this.baseUrl}/encore/api/downloadCollections/execute`, copy, options);
    }

    uploadCollections(files: FormData, loanOdAccount: LoanOdAccounts): Observable<any> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccount);
        files.append('loanOdAccount', JSON.stringify(copy));
        return this.httpClient.post(`${this.baseUrl}/encore/api/uploadCollections`, files);
    }

    uploadPdcCollections(files: FormData, loanOdAccount: LoanOdAccounts): Observable<any> {
        const copy: LoanOdAccounts = Object.assign({}, loanOdAccount);
        files.append('loanOdAccount', JSON.stringify(copy));
        return this.httpClient.post(`${this.baseUrl}/encore/api/uploadPdcCollections`, files);
    }

    uploadReverseCollections(form: FormData, key: string): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/encore/api/uploadReverseCollections`, form);
    }

    electronicMandate(accountId?: string, umrn?: string): Observable<any> {
        const params = new URLSearchParams();
        if (accountId) {
            // params.append('accountId', accountId);
            return this.httpClient.get(`${this.baseUrl}/encore/api/electronicMandates?accountId=` + accountId);
        }
        if (umrn) {
            // params.append('umrn', umrn);
            return this.httpClient.get(`${this.baseUrl}/encore/api/electronicMandates?umrn=` + umrn);
        }
        if (!accountId && !umrn) {
            return this.httpClient.get(`${this.baseUrl}/encore/api/electronicMandates`);
        }
    }

    downloadMandate(accountId: string): Observable<any> {
        const options = this.createRequestOptionJson();
        options.responseType = ResponseContentType.Blob;
        return this.httpClient.post(`${this.baseUrl}/encore/api/loan-od-accounts/downloadMandates/` + accountId, options);
    }

    getLoanAmendParameters(accountId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/loan-od-accounts/amendLoanParameters/` + accountId).pipe(map((res: Response) => {
            return res;
        }));
    }

    getIfscCodes(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getIfscCodes`);
    }

    getBankDetailsFromIfscCode(ifscCode: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/findBankDetails?enumerationCode=` + ifscCode);
    }

    public setAccountId(accountId: string) {
        this.accountId = accountId;
    }

    public getAccountId() {
        return this.accountId;
    }

    getFormatsType(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getFormats`);
    }

    getDisburseInstruments(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/api/getDisbursementInstruments`);
    }

    findTrasactionAprovelShow(id: number): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/encore/${this.resourceUrl}/viewTransaction/${id}`).pipe(map((res: Response) => {
            return res.json();
        }));
    }
}
