import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RegisterComponent } from './register/register.component';
import { ActivateComponent } from './activate/activate.component';
import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../admin/user/user.service';
import { User } from '../admin/user/user.model';
import { map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { ResetPasswordComponent } from '@app/user-account/reset-password/reset-password.component';
import { LoginService } from '@ig-core/authentication/login.service';
import { ForgotPasswordDialogComponent } from './login/forgot-password-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AccountResolverService implements Resolve<User> {
  key: string;
  constructor(
    private cs: UserService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.cs.getUserByActivationKey(route.queryParamMap.get('key')).pipe(
      map(result => {
        return result.body;
      }),
      catchError((err, caught) => {
        this.snackBar.open('Sorry!! Link has been expired', 'Close');
        return this.router.navigate(['signin']);
      })
    );
  }
}

@Injectable()
export class ResolveResetKey implements Resolve<any> {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (route.queryParamMap.get('key')) {
      return this.loginService
        .checkResetKey(route.queryParamMap.get('key'))
        .pipe(
          map(result => {
            if (!result) {
              console.log('no data found');
            } else {
              return result.body;
            }
          }),
          catchError((err, caught) => {
            this.snackBar.open('Sorry!!Link has been expired', 'Close');
            return this.router.navigate(['signin']);
          })
        );
    }
  }
}

const routes: Routes = [
  { path: 'signin', component: LoginComponent },
  { path: 'signout', component: LogoutComponent },
  { path: 'register', component: RegisterComponent },
  {path:'forgot_password', component: ForgotPasswordDialogComponent },
  {
    path: 'reset/finish',
    component: ResetPasswordComponent,
    resolve: {
      user: ResolveResetKey
    }
  },
  {
    path: 'activate',
    component: ActivateComponent,
    resolve: {
      user: AccountResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserAccountRoutingModule {}
