import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import { Zone } from './zone.model';

@Injectable({
    providedIn: 'root'
})
export class ZoneService {

    private API = 'api/zones';
    public $zoneListFeeder = new Subject<any>();
    public $selectedZoneFeeder = new Subject<any>();
    public $createNewZoneFeeder = new Subject<boolean>();
    public $zoneFormFeeder = new Subject<any>();
    public $zoneFormCancelFeeder = new Subject<boolean>();
    public $zoneFormResetFeeder = new Subject<boolean>();
    constructor(private http: HttpClient) {}

    getZones() {
        return this.http.get<Zone[]>(this.API, {observe: 'response'});
    }

    saveZone(zone: Zone): Observable<HttpResponse<Zone>> {
        if (zone.uid && zone.uid != null) {
            return this.http.put<Zone>(this.API, zone, {observe: 'response'});
        } else {
            return this.http.post<Zone>(this.API, zone, {observe: 'response'});
        }
    }

    deleteZone(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    getZone(zoneUid: string) {
        return this.http.get<Zone>('api/get-zone/' + zoneUid);
    }

    getZonesForBranch(branchCode: string, geoFence?: boolean) {
        let params = new HttpParams();
        if(geoFence){
            params = params.set('geofence', geoFence.toString());
        }

        return this.http.get<any>('api/v1/masters/branches/' + branchCode
            + '/zones', { params, observe: 'response' });
    }

}

