import { NgModule } from '@angular/core';
import { MultiLevelMenuComponent } from './multi-level-menu.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import {CommonModule} from '@angular/common';
import {MaterialLibModule} from '../../../../src/app/material-lib.module';

/*https://stackblitz.com/edit/dynamic-nested-sidenav-menu?file=app%2Fmenu-list-item%2Fmenu-list-item.component.scss*/

@NgModule({
  imports: [
    CommonModule,
    MaterialLibModule
  ],
  declarations: [MultiLevelMenuComponent, MenuItemComponent],
  exports: [MultiLevelMenuComponent]
})
export class MultiLevelMenuModule { }
