import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {UserService} from './user.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {User} from './user.model';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserDeleteDialogComponent implements OnInit, OnDestroy {
  user: User;
  errorMessage: string;
  constructor(private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UserDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: User) {
    this.user = data;
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
  }

  delete() {
    this.errorMessage = '';
    this.userService.deleteUser(this.user.login)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
  }

  onSaveSuccess(result) {
    this.snackBar.open(`Deleted user ${this.user.login} successfully`, 'Close');
    this.dialogRef.close();
    // this.router.navigateByUrl('/admin/user');
  }

  onSaveError(response) {
    this.errorMessage = response;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
