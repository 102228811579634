import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

import { BranchService } from '@app/admin/branch/branch.service';
import { User } from '@app/admin/user/user.model';
import { UserService } from '@app/admin/user/user.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { GoogleMapsHelperService } from './google-maps-helpers/google-maps-helper.service';
import { Zone, Coordinate } from './zone.model';
import { ZoneService } from './zone.service';
import { ValidationService } from '@app/applications/application-validators/validation.service';

@Component({
    selector: 'eng-zones',
    templateUrl: 'zones.template.html',
    styleUrls: ['zones.styles.scss']
})
export class ZonesComponent implements AfterViewInit {

  @ViewChild('mapContainer')
  mapContainer: ElementRef;
  
  map: google.maps.Map;
  
  zones: Zone[] = [];
  
  /* To hold the zone that is being edited. Used in html to determine whether zone
    list to be shown in sidenav or zone form for selected zone. */
  selectedZone: Zone;
  
  /* Set to true while creating a new zone. Any other time set to false.
    Used in html to determine whether zone list to be shown in sidenav or
    zone form for a new zone. */
  createZone: boolean;

  // To store the created polygons
  createdPolygons: any[] = [];
  
  zoneForm : FormGroup; // Zone data from.
  zonesFilterForm : FormGroup; // Zone search form.

  userBranches: NameValueDto[] = []; // User accessible branch list.
  branchROs : User[] = []; // RO users for a given branch. Changes as per selected branch.

  // Polygon color codes pool.
  polygonColorHexCodes = [ '#B22222', '#FF4500', '#228B22', '#808000', '#008080', '#4169E1',
    '#483D8B', '#800080', '#C71585', '#2F4F4F', '#696969', '#8B4513', '#800000'
  ];

  // Default map center coordinate.
  centerLat = 12.96;
  centerLng = 77.58;
  centerCoordinates = new google.maps.LatLng(this.centerLat, this.centerLng);
  
  // Map will use these options to initialize.
  mapOptions: google.maps.MapOptions = {
    center: this.centerCoordinates,
    zoom: 11,
    disableDoubleClickZoom: true
  };
  
  constructor(private zoneService: ZoneService,
      private googleMapsHelper: GoogleMapsHelperService,
      public router: Router,
      private formBuilder: FormBuilder,
      private _snackbar: MatSnackBar,
      private branchService: BranchService,
      private userService: UserService,
      private validationService: ValidationService) {
        
    // Zone data form
    this.zoneForm = this.formBuilder.group({
      name : '',
      description : '',
      keyLocalities : '',
      roId: '',
      coordinatesArray : []
    });

    // Zone search form
    this.zonesFilterForm = formBuilder.group({
      branch : '',
    });
  }

  ngOnInit() {
    this.applyValidatorsToZonesForm();
  }
  

  ngAfterViewInit(): void {
    this.mapInitializer();
    this.getUserBranches();
  }

  applyValidatorsToZonesForm() {
    this.validationService.applyValidationRules(this.zoneForm, "Zones")
          .then((controlValidators) => {}).catch(() => {});
  }

  // Initialize the map using predefined map options.
  mapInitializer() {
    this.map = new google.maps.Map(this.mapContainer.nativeElement,
      this.mapOptions);
  }

  // Fetch accessible branches for logged-in user.
  getUserBranches() {
    this.branchService.getBranchesForUser().subscribe((response) => {
      if(response) {
        this.userBranches = response.body;
        if(this.userBranches && this.userBranches.length == 1) {
          /* If only one branch is present, make it selected by default
            and get zones for it. */
          this.zonesFilterForm.patchValue({
            branch : this.userBranches[0].code,
          });
          this.getZones(this.userBranches[0].code);

          /* Fetch ROs for the said branch*/
          this.getROsForBranch(this.zonesFilterForm.value.branch);
        }
      }
    });
  }

  //Fetch zones for a given branch.
  getZones(branchCode ?: string) {
    this.createZone = false;
    //If 'branchCode' is not provided, read it from search form.
    if(!branchCode) branchCode = this.zonesFilterForm.value.branch;
    
    // Second paramater=true will fetch geofence details also for the zones.
    this.zoneService.getZonesForBranch(branchCode, true).subscribe((response) => {
      this.zones = response.body;
      if(this.zones.length > 0) {
        /* Assign color hexcodes from color codes pool to the zones,
          that will be used to fill polygon color later. */
        for(let i=0; i<this.zones.length; i++) {
          this.zones[i].color = this.polygonColorHexCodes[i];
        }
        
        // Recenter the map as per the first zone's center.
        var firstZoneCenterLatLong = JSON.parse(this.zones[0].center);
        this.map.setCenter(new google.maps.LatLng(firstZoneCenterLatLong.lat,
          firstZoneCenterLatLong.lng));
      }
      this.updateMap();
    });
  }

  // This method is to be used only for RO users list, fetch 'RO' users for a given branch code.
  getROsForBranch(branchCode) {
    this.userService.getUsersForBranchByRole(branchCode, "RO")
      .subscribe(response => {
        let roArrayList =[]
        let ros =response.body
        ros.forEach( ro=> {
          if(ro.activated){
            roArrayList.push(ro)
          }
        });
        this.branchROs = roArrayList
        // this is added to show None option in the dropdown
        this.branchROs.push({ id: null, login: "NONE", firstName: "NONE" });
    });
  }

  // Prepares polygon create options for the current zones and initiates their creation.
  updateMap() {
    /* Type was not determinate so it is not defined for 'polygons'.
      Maybe 'google.maps.PolygonOptions'? */
    let polygons = [];
    for (let i = 0; i < this.zones.length; i++) {
      polygons.push(new google.maps.Polygon(
          /* Get polygon options for a zone. If 'selectedZone' is empty then none of the
            polygons will be editable. Otherwise the polygon for 'selectedZone' will be
            editable and shown in red. Read 'getPolygonForZone' method description for more
            info. */
          this.googleMapsHelper.getPolygonForZone( 
            JSON.parse(this.zones[i].coordinates) as Coordinate[],
              this.zones[i], this.selectedZone)
      ));
    }
    this.createPolygons(polygons);
  }

  createPolygons(newPolygons: any[]) {
    // Remove the currently created polygons by setting their map to null.
    for (let i = 0; i < this.createdPolygons.length; i++) {
      const createdPolygon = this.createdPolygons[i];
      createdPolygon.setMap(null);
    }
    
    // Create new polygons.
    for (let i = 0; i < newPolygons.length; i++) {
      const polygon = newPolygons[i];
      polygon.setMap(this.map); //Setting the map will make the polygon visible on the map.
      
      if(polygon.editable) { // This is for selected polygon in edit mode.
        
        // Add event to detect polygon move actions.
        google.maps.event.addListener(polygon.getPath(), "set_at", () => {
          /* To hold updated coordinates after the changes to the polygon caused by
            the polygon move event. */
          let updatedPolygonCoordinatesAfterSetEvent = [];
          for (var i = 0; i < polygon.getPath().getLength(); i++) {
            updatedPolygonCoordinatesAfterSetEvent.push(this.googleMapsHelper.
              convertToLatLngObject(polygon.getPath().getAt(i).toUrlValue(5)));
              // Convert the coordinates to Coordinate object form. 
          }
          
          /* Store the updated polygon coordinates to zone's form-control 'coordinatesArray'.
            The form control only server is only storage. */
          this.zoneForm.patchValue({
            coordinatesArray: updatedPolygonCoordinatesAfterSetEvent
          });
        });
      
        // Add event to detect polygon coordinate changes.
        google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
          /* To hold updated coordinates after the changes to the polygon caused by
            the coordinate change event. */
          let updatedPolygonCoordinatesAfterInsertEvent = [];
          for (var i = 0; i < polygon.getPath().getLength(); i++) {
            updatedPolygonCoordinatesAfterInsertEvent.push(this.googleMapsHelper.
              convertToLatLngObject(polygon.getPath().getAt(i).toUrlValue(5)));
              // Convert the coordinates to Coordinate object form. 
          }
          
          /* Store the updated polygon coordinates to zoneForm's form-control 'coordinatesArray'.
            This form control serves only for storage purpose till it hands over
            the coordinates to zone model object. */
          this.zoneForm.patchValue({
            coordinatesArray: updatedPolygonCoordinatesAfterInsertEvent
          });
        });
      }
    }

    /* Assign the newly polygons received in parameter to created polygons,
      now that we have finished creating them. */
    this.createdPolygons = newPolygons;
  }

  // On selection of a zone. 
  selectZone(zone: Zone) {
    this.createZone = false;
    this.selectedZone = zone;

    // Update zoneForm with selected zone's values.
    this.zoneForm.patchValue({
      name: zone.name,
      description: zone.description,
      keyLocalities: zone.keyLocalities,
      //Both 'id' and 'login' are required for server logic, hence concatenated.
      roId: zone.ro ? zone.ro.id + '|' + zone.ro.login : null + '|' + "NONE",
      coordinatesArray: JSON.parse(zone.coordinates) as Coordinate[]
    });

    /* Call map update function to redraw polygons. But this time, the selected zone will
       be editable and shown in red because 'selectedZone' is not empty.
       Read 'getPolygonForZone' method description for more info. */
    this.updateMap();
  }
  
  createNewZone() {
    let zoneBranchControl = this.zonesFilterForm.controls["branch"];
    if(zoneBranchControl.value) { // If a branch is selected..
      zoneBranchControl.setErrors({ "required": null });
      zoneBranchControl.updateValueAndValidity();

      this.createZone = true;
      this.selectedZone = undefined;
      this.zoneForm.reset();

      // Redraw polygons. None of them will be in edit mode because 'selectedZone' is set to empty.
      this.updateMap();
    
      let mapCenter = this.map.getCenter();
      let centerLat = mapCenter.lat();
      let centerLng = mapCenter.lng();
      // Construct new polygon. By default make it editable and red.
      let newZonePolygon = new google.maps.Polygon({
        paths: this.googleMapsHelper.getNewZonePolygonCoordinates(centerLat, centerLng),
        draggable: true,
        editable: true,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });
      // Setting a map to a polygon will display the polygon on that map.
      newZonePolygon.setMap(this.map);

      // Add the new polygon to list of created polygons.
      this.createdPolygons.push(newZonePolygon);

      // Get new polygon coordinates in Coordinate array representation.
      let newPolygonCoordinates: Coordinate[] = [];
      for (var i = 0; i < newZonePolygon.getPath().getLength(); i++) {
        newPolygonCoordinates.push(this.googleMapsHelper.
          convertToLatLngObject(newZonePolygon.getPath().getAt(i).toUrlValue(5)));
      }

      // Store new polygon coordinate array in 'coordinatesArray' form-control of zoneForm.
      this.zoneForm.patchValue({
        coordinatesArray: newPolygonCoordinates
      });

      // Add event to detect move actions for new polygon.
      google.maps.event.addListener(newZonePolygon.getPath(), "set_at", () => {
        /* To hold updated coordinates after the changes caused by
          the polygon move event. */
        let updatedNewPolygonCoordinatesAfterSetEvent = [];
        for (var i = 0; i < newZonePolygon.getPath().getLength(); i++) {
          updatedNewPolygonCoordinatesAfterSetEvent.push(this.googleMapsHelper.
            convertToLatLngObject(newZonePolygon.getPath().getAt(i).toUrlValue(5)));
            // Convert the coordinates to Coordinate object form. 
        }
        
        /* Store the updated polygon coordinates to zone's form-control 'coordinatesArray'.
          The form control only server is only storage. */
        this.zoneForm.patchValue({
          coordinatesArray: updatedNewPolygonCoordinatesAfterSetEvent
        });
      });

      // Add event to detect new polygon's coordinate changes.
      google.maps.event.addListener(newZonePolygon.getPath(), "insert_at", () => {
        /* To hold updated coordinates after the changes to the polygon caused by
          the coordinate change event. */
        let updatedNewPolygonCoordinatesAfterInsertEvent = [];
        for (var i = 0; i < newZonePolygon.getPath().getLength(); i++) {
          updatedNewPolygonCoordinatesAfterInsertEvent.push(this.googleMapsHelper.
            convertToLatLngObject(newZonePolygon.getPath().getAt(i).toUrlValue(5)));
            // Convert the coordinates to Coordinate object form. 
        }
        
        /* Store the updated polygon coordinates to zoneForm's form-control 'coordinatesArray'.
          This form control serves only for storage purpose till it hands over
          the coordinates to zone model object. */
        this.zoneForm.patchValue({
          coordinatesArray: updatedNewPolygonCoordinatesAfterInsertEvent
        });
      });
    } else { // Branch is not selected, yet 'add' button was clicked.
      zoneBranchControl.setErrors({ "required": "Please select a branch first.." });
      zoneBranchControl.markAsDirty();
      zoneBranchControl.markAsTouched();
    }
  }

  saveZone() {
    this.markFormGroupTouched(this.zoneForm);
    if(!this.zoneForm.value.roId){
      this.zoneForm.controls["roId"].setErrors({ "required": "RO is required" });
      return
    }
    if(this.zoneForm.valid) { // Save only if zone form is valid.
      let zoneFormFields = this.zoneForm.value;
      
      if(!this.selectedZone) {
        // If it is a new zone, create a new model object and set branch code to selected branch.
        this.selectedZone = new Zone();
        this.selectedZone.branchCode = this.zonesFilterForm.value.branch;
      }

      // Set model object's fields from user input fields.
      this.selectedZone.name = zoneFormFields.name;
      this.selectedZone.description = zoneFormFields.description;
      this.selectedZone.keyLocalities = zoneFormFields.keyLocalities;
      //if selected RO is none we need to send ro and roId as null hence we are checking and updating the value on click of save
      this.updateRoValue(zoneFormFields.roId);
      /* Retrieve the coordinate array stored in zoneForm, convert them to string format
        and set into model object. */
      this.selectedZone.coordinates = JSON.stringify(zoneFormFields.coordinatesArray);

      /* Retrieve zone's center by calling center finding helper method
        and set into model object. */
      this.selectedZone.center = JSON.stringify(this.googleMapsHelper
        .getPolygonCenter(zoneFormFields.coordinatesArray));

      // Call save API (create/update).
      this.zoneService.saveZone(this.selectedZone).toPromise().then(
        (success) => {
          this._snackbar.open("Zone saved successfully.", "Close", {
            duration: 6000,
          });
          // Call 'closeEditMode' with 'afterSaveSuccess' = true.
          this.closeEditMode(true);
        },
        (failure) => {
          this._snackbar.open("Failed to save zone. " + failure.errorKey, "Close", {
            duration: 6000,
          })
          console.log(failure);
        }
      );
    }
  }

  branchChanged() {
    this.getZones(); // Get zones for the new selected branch.
    /* To show list of zones always after a branch is changed. Because branch can be
      changed during zone edit as well, and we want to revert to a list view after new
      zones are fetched. */

    // Get Ros for newly selected branch.
    this.getROsForBranch(this.zonesFilterForm.value.branch);
    
    this.closeEditMode();
  }

  // Closes edit mode and reverts back to list mode.
  closeEditMode(afterSaveSuccess ?: boolean) {
    this.createZone = false;
    this.selectedZone = undefined;
    this.zoneForm.reset();
    
    /* If 'afterSaveSuccess' is true then this method was called after successly saving
      a zone. So reload zones list by making api call again. Otherwise,
      just updating the map is sufficient. */
    if(afterSaveSuccess) this.getZones();
    else this.updateMap();
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  ngOnDestroy(): void {
  }

  // we are supporting none value as well hence we need to split and we are checking if the option selected is none
  // if selected is none we need to send ro and roId as null, else we are sending "ro.id | ro.login" this structure is expected in server side
  updateRoValue(rovalue){
    let selectedValue = rovalue;
    let [roId] = selectedValue.split('|');

    if (roId === "null") {
      this.selectedZone.ro = null;
      this.selectedZone.roId = null;
    } else {
      this.selectedZone.roId = selectedValue;
    }
  }

}