import { Component, OnInit, Input ,OnChanges, EventEmitter, Output} from '@angular/core';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { LoanApplication, Person ,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { BankAccount } from '../../business/businessBankAccount/business-bank-accounts.model';
import {BehaviorSubject, of} from 'rxjs';
@Component({
  selector: 'eng-repayment-account',
  templateUrl: './repayment-account.template.html',
  styleUrls: ['../../application-details.styles.scss', './repayment-account.styles.css']
})
export class RepaymentAccountComponent implements OnInit  {
  @Input() application: LoanApplication;
  @Input() applicants: Person[];
  @Input()
    menuCode:string;
  businessAccountTypes: NameValueDto;
  personAccountTypes: NameValueDto;

  allowAccess: Boolean;
  isFormDisabled: Boolean;
  menuItemAllowAccess:boolean;

  allBankAccounts: BankAccount[] = [];
  repaymentAccount: BankAccount;

  repaymentForm: FormGroup;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  // here applicationDataChanged is used to update application data value after saving repayment bankAccount, this will emit value and this will be caught in application-details component.
  // then in application component we will update this.application with new updated value
  @Output() applicationDataChanged: EventEmitter<object> = new EventEmitter();

  constructor(
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
  ) {
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.repaymentForm = new FormGroup({
      repaymentAccount: new FormControl(""),
      selectedBankAccount: new FormControl("")
    })
    this.getRefCodes();
    this.getMenuItemAccess();
    this.fetchbusinessBankAccounts();
  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('business_bankac_type').subscribe((response: any) => {
      this.businessAccountTypes = response.business_bankac_type;
    });
    this.referenceCodeService.getShortRefCodes('person_bankac_type').subscribe((response: any) => {
      this.personAccountTypes = response.person_bankac_type;
    });
  }

  buildForm() {
    this.repaymentForm.controls.repaymentAccount.patchValue(this.application.repaymentBankaccountUid)
    this.repaymentForm.disable()
  }

  
  saveRepaymentBankAccount() {
    if(this.repaymentForm.controls.selectedBankAccount.value){
      let saveRepayAccount = {
        "applicationUid": this.application.uid,
        "applicationVersion": this.application.version,
        "repaymentBankAccountUid": this.repaymentForm.controls.selectedBankAccount.value
      }
      this.applicationService.saveRepaymentAccount(this.application.uid, saveRepayAccount).subscribe(response => {
        // in success case after saving first we are calling refreshApplicationData to update application information and we are emitting this.applicatoin data to application-details.component
        this.refreshApplicationData()
        this.application.repaymentBankaccountUid=response.body.repaymentBankAccountUid
        if(this.repaymentAccount){
          this.allBankAccounts.push( this.repaymentAccount)
        }
        
        this.getRepaymentAccount()
        this.buildForm()
      })
    }
  }

  enableDisableForm() {
    this.repaymentForm.enable()
  }

  cancelForm() {
    this.repaymentForm.reset()
    this.buildForm()
  }


  fetchbusinessBankAccounts() {
    this.applicationService.getBusinessBankAccounts(
      this.application.uid).subscribe((response: any) => {
        if (response) {
          this.allBankAccounts = response.body;
          this.fetchApplicantBankAccounts()
          this.buildForm()
        }
      });
  }


  fetchApplicantBankAccounts() {
    if (this.applicants.length !== 0) {
      this.applicants.forEach(person => {
        if (person.partyPlay !== 'reference') {
        this.applicationService.getApplicantBankAccounts(this.application.uid, person.uid)
          .subscribe((response: any) => {
            if (response) {
              this.allBankAccounts = this.allBankAccounts.concat(response.body);
             this.getRepaymentAccount()
            }
          },(_failure) =>{
            let errormesg =[]
            errormesg.push("Repayment Account update Faild.")
            errormesg.push(_failure)
            this.applicationService.displayErrorMessages(errormesg);
          });
      }
      });
    }
  }

//this function will be changed tha value of this.application.
//this call is required after repayment account changed, the application values should be refreshed.
  refreshApplicationData() {
    this.applicationService.getBasicApplicationInfo(
      this.application.uid, 'ApplicationOnly').subscribe((response) => {
        this.application = response.body.applicationDTO;
        this.applicationDataChanged.emit(this.application);
    });
  }

//this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

    getRepaymentAccount(){
      this.allBankAccounts.forEach((person, index) => {
        if (person.uid == this.application.repaymentBankaccountUid) {
          this.repaymentAccount = person
          this.allBankAccounts.splice(index, 1);
        }
      });
    }
}
