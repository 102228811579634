import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AlertService } from 'ng-jhipster';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Injectable()
export class LoanOdAccountsResolve implements Resolve<LoanOdAccounts> {

    constructor(private loanOdAccountsService: LoanOdAccountsService,
        private alertService: AlertService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId')) {
            return this.loanOdAccountsService.find(route.paramMap.get('accountId')).catch(
                (err) => {
                    this.onFetchError(err);
                    return null;
                }
            );
        } else {
            return new LoanOdAccounts();
        }
    }

    private onFetchError(error) {
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
    }

    private onError(error) {
        this.alertService.error(error.error);
    }
}
