import {FeeDto} from './loan-od-accounts-fee-dto.model';
import {LoanOdDisbursementDto} from './loan-od-disbursement-dto.model';
import {Money} from './money';
import {PercentRate} from './percentrate';

export class LoanOdMoratoriumDtoModel {
    constructor(

        public id?: number,
        public version?: number,
        public accountId?: string,
        public moratoriumType?: string,
        public moratoriumPeriod?: string,
        public tenure?: any,
        public moratoriumNum?: number,
        public transactionId?: string,
        public startDate?: any,
        public tenuremagnitude?: number,
        public tenureunit?: string,
        public createdWithLoan?: Boolean,
        public capitalizedInterest?: Money,
        public moratoriumInstallment?: Money,
        public moratoriumNormalInterestRate?: PercentRate,
        public valueDate?: any,
        public transactionDate?: any,
        public remarks?: string,
        public userId?: string,
        public reference?: string,
        public transactionLotId?: string,
        public displayMoratoriumType?: any,
        public selectedMoratoriumType?: any) {}
}
