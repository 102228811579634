import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationService } from "@app/applications/application.service";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { OnDestroy } from "@angular/core";
import { Applicant } from "@app/applications/application-details/collateral/collateralDetails/collateral-details.model";
import { BANK_ACCOUNT_VERIFICATION_MODE_PENNY_DROP } from "@app/constants/data.constants";
import { BankAccountHolderComposite, BankAccountVerificationDTO, BankAccountVerificationResponseComposite } from "@app/applications/application-details/business/businessBankAccount/business-bank-accounts.model";
import { ValidationService } from "@app/applications/application-validators/validation.service";
@Component({
  selector: "eng-penny-drop",
  templateUrl: "penny-drop.template.html",
  styleUrls: [
    "../../../application-details.styles.scss",
    "../xdata-service.style.scss",
  ],
})
export class PennyDropComponent implements OnInit, OnDestroy {
  applicationUid: string;

  bankDetailsForm: FormGroup;
  applicants: Applicant[];
  serviceProviderCode: string;
  bankAccountHolderComposite: BankAccountHolderComposite[] = [];
  bankAccountVerificationResponse: BankAccountVerificationResponseComposite;
  verificationMode: string;
  isShowResultCard: boolean;
  verificationDto: BankAccountVerificationDTO = {};
  isCheckboxSelected: boolean;
  bankAccountDetails: BankAccountHolderComposite[];
  isVerified: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private validationService: ValidationService,
  ) {
    this.bankDetailsForm = this.formBuilder.group({
      borrower: ["", Validators.required],
      bankDetails: ["", Validators.required],
    });
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.applicationUid;
        this.serviceProviderCode = params.xDataService;
        this.verificationMode = params.serviceType;
      });
  }

  ngOnInit() {
    this.getAllBankAccountsForApplication();
  }

  onInitiateClick() {
    this.validationService.markFormGroupTouched(this.bankDetailsForm)
    if (this.bankDetailsForm.valid) {
      this.isVerified = false;
      this.isCheckboxSelected = false;
      let bankAccountUid = this.bankDetailsForm.controls.bankDetails.value.uid;
      this.applicationService.initiateBankAccountVerification(this.applicationUid,bankAccountUid).toPromise().then(
          (_success) => {
            this.bankAccountVerificationResponse = _success;
            this.isShowResultCard = true;
            this._snackbar.open("Data retrieved successfully", "Close", {
              duration: 5000,
            });
          },
          (failure) => {
            this.isShowResultCard = false;
            this._snackbar.open(failure, "close", {
              duration: 5000,
            });
          }
        );
    }
  }

  getAllBankAccountsForApplication() {
    this.applicationService.getAllBankAccountsForApplication(this.applicationUid).subscribe((response: any) => {
        this.bankAccountHolderComposite = response.body;
      });
  }

  onBankAccountHolderChange(bankAccountHolder){
    // previous value will be stored in bankDetails, hence we are reseting the value to empty
    this.bankDetailsForm.controls.bankDetails.patchValue("");
    this.onBankAccountChange();
    // this variable is used to show or hide verify button and checkbox 
    this.isVerified = false;
    // here we are using this.bankAccountDetails to show bankName and accountNumber
    this.bankAccountDetails = bankAccountHolder.bankAccounts

    if (this.bankAccountDetails.length === 0 ) {
      this._snackbar.open(
        "Bank account not available for " + bankAccountHolder.name + " to validate." , "close",
        {
          duration: 5000,
        }
      );
      return;
    }

    if (this.bankAccountDetails.length === 1 ) {
      this.bankDetailsForm.patchValue({
        bankDetails: this.bankAccountDetails[0]
      });
    }
  }

  onBankAccountChange() {
    this.isShowResultCard = false;
    this.bankAccountVerificationResponse = new BankAccountVerificationResponseComposite();
  }

  onVerifyClick() {
    if (!this.isCheckboxSelected) {
      this._snackbar.open(
        "Checkbox must be ticked to confirm verification",
        "close",
        {
          duration: 5000,
        }
      );
      return;
    }
    let bankAccountUid = this.bankDetailsForm.controls.bankDetails.value.uid;
    this.verificationDto.verificationMode = BANK_ACCOUNT_VERIFICATION_MODE_PENNY_DROP;
    this.verificationDto.jdata = this.bankAccountVerificationResponse.serviceProviderData;
    this.applicationService.verifyBankAccount(this.applicationUid, bankAccountUid, this.verificationDto)
      .subscribe((response) => {
        if (response) {
          this._snackbar.open("Bank account verified", "close", {
            duration: 5000,
          });
          this.isVerified = true;
        }
      },(failure =>{
        this.verificationDto = new BankAccountVerificationDTO();
        this._snackbar.open("Bank account verification failed " + failure, "close", {
          duration: 5000,
        });
      }));
  }

  ngOnDestroy(): void {}
}
