import {FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import {ServerErrorModel} from './server-error.model';
import {ErrorBottomsheetComponent} from '../error-handler/bottom-sheet/error-bottomsheet/error-bottomsheet.component';
import {MatBottomSheet} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class IgFormService {

  constructor(private bottomSheet: MatBottomSheet) {}

// get all values of the formGroup, loop over them
  // then mark each field as touched
  public markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).map(function(controlKey) {
      formGroup.controls[controlKey].markAsTouched();

      /*if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }*/
    });
  }

  // return list of error messages
  public validationMessages() {
    let messages = new Object();
    messages = {
      required: 'This field is required',
      email: 'This email address is invalid',
      igEmail: 'This email address is invalid',
      igContactNumber: 'This contact number is invalid',
      igPincode: 'This pincode is invalid',
      igPanCard: 'Invalid PAN. The Pan Number Should be in Format(AAAAA0000A)',
      igAadhar: 'Invalid Aadhar. The Aadhar Number Should be in Format(109898769876)',
      hasNumber: 'Must contain at least 1 number!',
      hasCapitalCase: 'Must contain at least 1 in Capital Case!',
      hasSmallCase: 'Must contain at least 1 Letter in Small Case!',
      hasSpecialCharacters: ' Must contain at least 1 Special Character!',
      mustMatch: 'Password Missmatch',
      minlength: (lengthObj: any) => {
        /*return 'Must be at least ' + lengthObj['requiredLength'] + '(' + lengthObj['actualLength'] + ')' + 'characters! ';*/
        return 'Must be at least ' + lengthObj['requiredLength'] + 'characters! ';
      },
      maxlength: (lengthObj: any) => {
        /*return 'Must be at least ' + lengthObj['requiredLength'] + '(' + lengthObj['actualLength'] + ')' + 'characters! ';*/
        return 'Must be at least ' + lengthObj['requiredLength'] + 'characters! ';
      },
      invalid_characters: (matches: any[]) => {

        let matchedCharacters = matches;

        matchedCharacters = matchedCharacters.reduce((characterString, character, index) => {
          let string = characterString;
          string += character;

          if (matchedCharacters.length !== index + 1) {
            string += ', ';
          }

          return string;
        }, '');

        return `These characters are not allowed: ${matchedCharacters}`;
      },
    };

    return messages;
  }

  // Validate form instance
  // check_dirty true will only emit errors if the field is touched
  // check_dirty false will check all fields independent of
  // being touched or not. Use this as the last check before submitting
  public validateForm(formToValidate: FormGroup, formErrors: any, checkDirty?: boolean) {
    const form = formToValidate;

    for (const field in formErrors) {
      if (field) {
        formErrors[field] = '';
        const control = form.get(field);

        let messages = new Object();
        messages = this.validationMessages();
        if (control && !control.valid) {
          if (!checkDirty || (control.dirty || control.touched)) {
            for (const key in control.errors) {
              if (key) {
                if (key === 'invalid_characters' || key === 'minlength' || key === 'maxlength') {
                  formErrors[field] = formErrors[field] || messages[key](control.errors[key]);
                } else {
                  formErrors[field] = formErrors[field] || messages[key];
                }
              }
            }
          }
        }
      }
    }

    return formErrors;
  }

  public handleServerError(formToValidate: FormGroup, formErrors: any, error: any, showError: boolean= true): ServerErrorModel {
    if (!(error.message === 'error.validation' && error.fieldErrors)) {
      if (showError) {
        this.bottomSheet.open(ErrorBottomsheetComponent, {
          data: error,
        });
      }
      return new ServerErrorModel(false, null, error);
    } else {
      const form = formToValidate;
      for (let i = 0; i < error.fieldErrors.length; i++) {
        const fieldErr = error.fieldErrors[i];
        if (fieldErr) {
          const control = form.get(fieldErr['field']);
          if (control && formErrors.hasOwnProperty(fieldErr['field'])) {
            control.setErrors({'server-error': true});
            formErrors[fieldErr['field']] = fieldErr['message'];
          }
        }
      }
      return new ServerErrorModel(true, formErrors);
    }
  }
}
