import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import {
  ModelObject,
  FieldData,
  API,
  Validator
} from '@app/examples/model-json/model-json.model';
import { MatSnackBar } from '@angular/material';
import { IgFormService } from '@ig-core/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

import {
  FieldDataTypes,
  WidgetDataTypeS,
  ValidatorsList
} from './model-json.model';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-model-json',
  templateUrl: './model-json.component.html',
  styleUrls: ['./model-json.component.css']
})
export class ModelJsonComponent implements OnInit, OnDestroy {
  modelJson: ModelObject;
  api: API;
  fieldData: FieldData;
  dataTypes: string[] = FieldDataTypes;
  widgetDataTypeS: string[] = WidgetDataTypeS;
  validatorsList: string[] = ValidatorsList;
  fieldsData: FormArray;
  idField: FieldData;
  fields: any;

  public modelEditorForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    title: '',
    entity: '',
    maticon: '',
    url: ''
  };
  public fieldFormErrors = {
    name: '',
    label: '',
    type: '',
    widget: ''
  };
  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService
  ) {
    this.modelJson = {};
    this.api = {};
    this.idField = {};
    // this.fieldData = {};
    // this.fieldsData.push({});
  }

  ngOnInit() {
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    // this.fieldFormErrors = new FormErrorModel(false, '');
  }

  ngOnDestroy(): void { }

  buildForm() {
    this.modelEditorForm = this.formBuilder.group({
      title: [this.modelJson.title, Validators.required],
      entity: [this.modelJson.entity, Validators.required],
      maticon: [this.modelJson.maticon, Validators.required],
      url: [this.api.url, Validators.required],
      /* name: [this.fieldData.name, Validators.required],
      label: [this.fieldData.label, Validators.required],
      type: [this.fieldData.type, Validators.required],
      widget: [this.fieldData.widget, Validators.required] */

      fieldsData: this.formBuilder.array([this.createFields()])
    });

    this.modelEditorForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.formErrors = this.igFormService.validateForm(
          this.modelEditorForm,
          this.formErrors,
          true
        );
        // this.fieldFormErrors = this.igFormService.validateForm(this.modelEditorForm, this.fieldFormErrors, true);
      });
    this.fieldsData = this.modelEditorForm.get('fieldsData') as FormArray;
    this.fields = this.fieldsData.controls;
  }

  createFields(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      label: ['', Validators.required],
      type: ['', Validators.required],
      widget: ['', Validators.required],
      validators: []
    });
  }

  addFields(): void {
    this.fieldsData = this.modelEditorForm.get('fieldsData') as FormArray;
    this.fieldsData.push(this.createFields());
    this.fields = this.fieldsData.controls;
  }

  deleteField(index) {
    console.log(index);
    this.fieldsData = this.modelEditorForm.get('fieldsData') as FormArray;
    this.fieldsData.removeAt(index);
    this.fields = this.fieldsData.controls;
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    this.igFormService.markFormGroupTouched(this.modelEditorForm);

    if (this.modelEditorForm.valid) {
      this.modelJson.entity = this.modelEditorForm.value.entity;
      this.modelJson.title = this.modelEditorForm.value.title;
      this.modelJson.maticon = this.modelEditorForm.value.maticon;
      this.api.url = this.modelEditorForm.value.url;
      this.modelJson.api = this.api;

      this.modelEditorForm.value.fieldsData.forEach(object => {
        if (object.validators != null) {
          const listData = [];
          for (let i = 0; i < object.validators.length; i++) {
            const x: Validator = {};
            x.name = object.validators[i];
            listData.push(x);
          }
          object.validators = listData;
        }
      });

      // adding Id field into fields list
      this.idField.name = 'id';
      this.idField.label = 'Id';
      this.idField.type = 'number';
      this.idField.widget = 'hidden';
      this.idField.validators = [{ name: 'required' }];

      this.modelJson.fields = this.modelEditorForm.value.fieldsData;
      this.modelJson.fields.push(this.idField);

      // download json file
      this.downloadFile(JSON.stringify(this.modelJson), this.modelJson.entity);
    } else {
      this.formErrors = this.igFormService.validateForm(
        this.modelEditorForm,
        this.formErrors,
        false
      );
      return false;
    }
  }

  downloadFile(data, filename) {
    const blob = new Blob([data], { type: 'text/json' });
    saveAs(blob, 'model.json');
  }
}
