import { Component, OnInit, OnDestroy, PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';

import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

import { ProductSet } from '../product-set.model';
import { ProductSetService } from '../product-set.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';

@Component({
  selector: 'app-product-set-edit',
  templateUrl: './product-set-edit.component.html',
  styleUrls: ['../product-set.component.css']
})


export class ProductSetEditComponent implements OnInit, OnDestroy {
  productSet: ProductSet;
  editable = true;
  public productSetEditorForm: FormGroup;
  subscription: Subscription;
  public formError: FormErrorModel;
  productList: any;
  loanType: NameValueDto[];
  public formErrors = {
    'name': '', 'code': '', 'productCodes': '',
  };
  public allProducts$;
  constructor(private productSetService: ProductSetService,
    private referenceCodeService: ReferenceCodeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.productSet === undefined) {
          this.productSet = {};
        } else {
          this.productSet = data.productSet;
          this.updateProductIds();
        }
        this.editable = data.editable;
      });
    this.allProducts$ = this.referenceCodeService.getAllProducts();

  }

  updateProductIds() {
    this.productSet.productCodes = [];
    this.productSet.products.forEach((product) => {
      this.productSet.productCodes.push(product.productCode);
    });
  }

  ngOnInit() {
    this.getAllProducts();
    this.getRefCodes();
  }

  getAllProducts() {
    this.referenceCodeService.getAllProducts().subscribe(response => {
      this.productList = response.body
      this.productList = this.productList.sort((a, b) => (a.productName.toLowerCase() > b.productName.toLowerCase()) ? 1 : ((b.productName.toLowerCase() > a.productName.toLowerCase()) ? -1 : 0))
      this.buildForm();
    })
  }
  ngOnDestroy(): void {
  }

  buildForm() {
    this.productSetEditorForm = this.formBuilder.group({

      'id': [this.productSet.id == undefined ? '' : this.productSet.id],

      'version': [this.productSet.version == undefined ? '' : this.productSet.version],

      'name': [this.productSet.name == undefined ? '' : this.productSet.name, [Validators.required]],

      'code': [this.productSet.code == undefined ? '' : this.productSet.code, Validators.required],

      'productCodes': [this.productSet.productCodes],
      'checkboxes': this.formBuilder.array(this.productList.map(x => false))
    });
    let checkboxControl = (this.productSetEditorForm.controls.checkboxes as FormArray);
    checkboxControl.controls.forEach((element, index) => {
      if (this.productSet.productCodes.includes(this.productList[index].productCode)) {
        checkboxControl.controls[index].patchValue(true)
      }
    });

    this.subscription = checkboxControl.valueChanges.subscribe(checkbox => {
      checkboxControl.setValue(
        checkboxControl.value.map((value, i) => value ? this.productSet.products[i].productCode : false),
        { emitEvent: false }
      );
    });
    this.productSetEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.productSetEditorForm, this.formErrors, true);
    });

    this.formError = new FormErrorModel(false, '');
    if (!this.editable) {
      this.productSetEditorForm.disable();
    }
  }

  onSubmit() {

    this.formError = new FormErrorModel(false, '');

    // mark all fields as touched
    this.igFormService.markFormGroupTouched(this.productSetEditorForm);

    let checkboxControl = (this.productSetEditorForm.controls.checkboxes as FormArray);
    let codes = []
    checkboxControl.controls.forEach((element, index) => {
      if (element.value) {
        codes.push(this.productList[index].productCode)
      }

    });
    this.productSetEditorForm.controls.productCodes.patchValue(codes)

    if (this.productSetEditorForm.valid) {
      let request = this.productSetEditorForm.value
      delete request.checkboxes
      this.productSetService.saveProductSet(request)
        .subscribe((response) => {
          this.onSubmitSuccess(response)
        })

    } else {
      this.formErrors = this.igFormService.validateForm(this.productSetEditorForm, this.formErrors, false);
      return false;
    }

  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('loan_type').subscribe((response: any) => {
      this.loanType = response.loan_type;
    });
  }

  private onSubmitSuccess(response) {
    let msg = '';
    if (this.productSet.id) {
      msg = `Updated Productset ${this.productSet.name} successfully`;
    } else {
      msg = `Created Productset successfully`
    }
    this.snackBar.open(msg, 'Close');
    this.goToProductSetListPage();
  }

  private onSubmitError(response) {
    const errorModel = this.igFormService.handleServerError(this.productSetEditorForm, this.formErrors, response.error);
    if (errorModel && errorModel.isValidationError) {
      this.formErrors = errorModel.formErrors;
    }
  }

  goToProductSetListPage() {
    if (this.productSet.id) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigateByUrl('/admin/product-set');
    }
  }

}
