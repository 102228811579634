import { Injectable } from '@angular/core';
import * as rules from './validationRules.json';

@Injectable({
  providedIn: 'root'
})
export class DataService {
 data:any=rules

  constructor() { 
  }

//this function will return json data  
  getRules(){
    return this.data.default
  }
}