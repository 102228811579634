export class IgValidationService {
    static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        const config = {
            'required': 'This field is required.',
            'invalidCreditCard': 'Is invalid credit card number',
            'invalidEmailAddress': 'Invalid email address',
            'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
            'minlength': `Minimum length ${validatorValue.requiredLength}`,
            'remote': `There was an error from server`,
            'igmin': 'Value lesser than the minimum value',
            'igmax': 'Value greater than the maximum value',
            'pattern': `Input value must match "${validatorValue.requiredPattern}"`,
            'minFloat': 'Value lesser than the minimum value',
            'maxFloat': 'Value greater than the maximum value',
        };

        return config[validatorName];
    }
}
