import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ImagePreviewDialogComponent } from './image-preview-dialog.component';
@Injectable()
export class ImagePreviewDialogService {
  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<ImagePreviewDialogComponent>;
  
  public open(options) {
    this.dialogRef = this.dialog.open(ImagePreviewDialogComponent, {    
         data: {
            images: options.images
         },disableClose: true
    });
  }
  public confirmed(): Observable<any> {
    
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }
    ));
  }
}