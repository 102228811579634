import { Component, OnInit, OnDestroy } from '@angular/core';
import {Response} from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { JhiLanguageService, AlertService } from 'ng-jhipster';
import {LoanOdMoratoriumDtoModel} from './loan-od-moratorium-dto.model';
import {DateConverterService} from '../shared/date/date-converter.service';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-amend-moratorium',
    templateUrl: './loan-od-accounts-amend-moratorium.component.html'
})
export class LoanOdAccountsAmendMoratoriumComponent implements OnInit, OnDestroy {
    moratoriums: LoanOdMoratoriumDtoModel[];
    currentSearchString: string;
    currentAccount: any;
    subscription: Subscription;
    accountId: string;
    proceedIsTrue: boolean;
    proceedToResult: boolean;
    showTemplate: boolean;
    moratoriumToEdit: LoanOdMoratoriumDtoModel;
    moratoriumTypeList: any[];

    constructor(
        private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private dateConverter: DateConverterService
    ) {
        this.moratoriums = [];
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    load(accountId: string) {
        this.loanOdAccountsService.amendMoratorium(accountId).subscribe(
            (res: Response) => this.onSuccess(res.json()),
            () => this.onError()
        );
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['accountId']);
            this.accountId = params['accountId']
        });
        this.loanOdAccountsService.getMoratoriumTypes().subscribe(
            (res) => {
                this.moratoriumTypeList = res.json();
            }
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private onError() {
        this.alertService.error('encoreclientApp.loanOdAccounts.error');
    }

    private onSuccess(data) {
        this.moratoriums = data;
        this.showTemplate = true;
    }

    createMoratorium() {
        this.router.navigate(['/loans/loan-od-accounts/createMoratorium', this.accountId]);
    }

    proceed(data) {
        this.moratoriumToEdit = data;
        this.moratoriumToEdit.valueDate = this.dateConverter.fromServerToNgbDatePicker(this.moratoriumToEdit.valueDate);
        this.moratoriumToEdit.startDate = this.dateConverter.fromServerToNgbDatePicker(this.moratoriumToEdit.startDate);
        this.proceedIsTrue = true;
    }

    back() {
        this.proceedIsTrue = false;
        this.moratoriumToEdit = null;
    }

    executeError() {
    }

    executeSuccess(id) {
        this.moratoriums = []
        this.alertService.success('encoreclientApp.loanOdAccounts.moratorium.amendSuccess', {accountId: id});
        this.load(id);
        this.proceedIsTrue = false;
        this.moratoriumToEdit = null;
    }

}
