import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {FormBuilder, FormGroup, Validators,FormArray} from '@angular/forms';
import {FormErrorModel} from '@ig-core/form/form-error.model';
import {IgFormService} from '@ig-core/form/form.service';
import {IgPatternValidator} from '@ig-core/form/additional-validators/ig-pattern-validator';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

import {BranchSet} from '../branch-set.model';
import {BranchSetService} from '../branch-set.service';
import {BranchService} from '@app/admin/branch/branch.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-branch-set-editor',
    templateUrl: './branch-set-editor.component.html',
    styleUrls: ['../branch-set.component.css']
})
export class BranchSetEditorComponent implements OnInit, OnDestroy {
    branchSet: BranchSet;
    editable = true;
    public branchSetEditorForm: FormGroup;
    subscription: Subscription;
    public formError: FormErrorModel;
    branchList:any;
    public formErrors = {
                  'name': '',    'code': '',    'branchCodes': '',
    };
    public allBranches$;
    constructor(private branchSetService: BranchSetService,
                private branchService: BranchService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder,
                private snackBar: MatSnackBar,
                private igFormService: IgFormService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.branchSet === undefined) {
                    this.branchSet = {};
                } else {
                    this.branchSet = data.branchSet;
                    this.updateBranchIds();
                }
                this.editable = data.editable;
            });
        this.allBranches$ = this.branchService.getAllBranches();
      
    }

    updateBranchIds() {
      this.branchSet.branchCodes = [];
      this.branchSet.branches.forEach((branch) => {
        this.branchSet.branchCodes.push(branch.code);
      });
    }
    ngOnInit() {
      this.getAllBranches()
    }

    getAllBranches(){
      this.branchService.getAllBranches().subscribe(response=>{
        this.branchList =response
        this.branchList=  this.branchList.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)) 
        this.buildForm();
      })
    }
    ngOnDestroy(): void {
     // this.subscription.unsubscribe();
    }

    buildForm() {
        this.branchSetEditorForm = this.formBuilder.group({

          'id': [this.branchSet.id == undefined?'':this.branchSet.id],

          'version': [this.branchSet.version == undefined?'':this.branchSet.version],

            'name': [ this.branchSet.name == undefined?'':this.branchSet.name, [Validators.required]],

            'code': [this.branchSet.code == undefined?'':this.branchSet.code, Validators.required],

            'branchCodes': [this.branchSet.branchCodes],  
            'checkboxes': this.formBuilder.array( this.branchList.map(x => false))
        });
        let checkboxControl = (this.branchSetEditorForm.controls.checkboxes as FormArray);
        checkboxControl.controls.forEach((element,index) => {
          if(this.branchSet.branchCodes.includes(this.branchList[index].code)){
            checkboxControl.controls[index].patchValue(true)
          }
        });

        this.subscription = checkboxControl.valueChanges.subscribe(checkbox => {
          checkboxControl.setValue(
            checkboxControl.value.map((value, i) => value ? this.branchSet.branches[i].code : false),
            { emitEvent: false }
          );
        });
        this.branchSetEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.branchSetEditorForm, this.formErrors, true);
        });

        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.branchSetEditorForm.disable();
        }
    }



    onSubmit() {

      this.formError = new FormErrorModel(false, '');

      // mark all fields as touched
      this.igFormService.markFormGroupTouched(this.branchSetEditorForm);

      let checkboxControl = (this.branchSetEditorForm.controls.checkboxes as FormArray);
      let codes = []
      checkboxControl.controls.forEach((element, index) => {
        if (element.value) {
          codes.push(this.branchList[index].code)
        }

      });
      this.branchSetEditorForm.controls.branchCodes.patchValue(codes)

      if (this.branchSetEditorForm.valid) {
        //this.branchSetEditorForm.removeControl('checkboxes')
        let request=this.branchSetEditorForm.value
        delete request.checkboxes
        this.branchSetService.saveBranchSet(request)
          .subscribe((response) => {
            this.onSubmitSuccess(response)
          })
           
      } else {
        this.formErrors = this.igFormService.validateForm(this.branchSetEditorForm, this.formErrors, false);
        return false;
      }

    }

    private onSubmitSuccess(response) {
      let msg = '';
      if(this.branchSet.id) {
        msg = `Updated branchSet ${this.branchSet.id} successfully`;
      } else {
        msg = `Created branchSet successfully`
      }
      this.snackBar.open( msg, 'Close');
      this.goToBranchSetListPage();
    }

    private onSubmitError(response) {
        const errorModel = this.igFormService.handleServerError(this.branchSetEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        }
    }

    goToBranchSetListPage() {
      if (this.branchSet.id) {
        this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
      } else {
        this.router.navigateByUrl('/admin/branch-set');
      }
    }

}
