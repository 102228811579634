import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormErrorModel} from '@ig-core/form/form-error.model';
import {IgFormService} from '@ig-core/form/form.service';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-create-example',
  templateUrl: './create-example.component.html',
  styleUrls: ['./create-example.component.css']
})
export class CreateExampleComponent implements OnInit , OnDestroy {
  public userEditorForm: FormGroup;
  public userEditorFormData: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    userName: '',
    email: '',
    salary: ''
  };
  constructor(private igFormService: IgFormService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    this.userEditorForm.disable();
    this.userEditorFormData.disable();
  }

  ngOnDestroy(): void {
  }

  buildForm() {
    this.userEditorForm = this.formBuilder.group({
      userName: [''],
      email: [''],
      salary: ['']
    });

    this.userEditorFormData = this.formBuilder.group({
      userName: ['John'],
      email: ['john@gmail.com'],
      salary: ['10000']
    });
    this.userEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.userEditorForm, this.formErrors, true);
    });
  }
}
