import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { AccountMandateDto } from './account-mandate-dto.model';

@Injectable()
export class LoanOdAccountsElectronicMandateResolve implements Resolve<AccountMandateDto> {

    constructor(private loanOdAccountsService: LoanOdAccountsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.loanOdAccountsService.electronicMandate().map(
            (res) => res
        );
    }
}