export class User {
    constructor(
        public id ?: number,
        public login ?: string,
        public firstName ?: string,
        public lastName ?: string,
        public email ?: string,
        public imageUrl ?: string,
        public langKey ?: string,
        public newPassword ?: string,
        public role ?: number,
        public orgStrUid ?: string,
        public branchSet ?: string,
        public activated ?: boolean,
        public passwordAutoGenerate ?: boolean,
        public branchCode ?: string,
        public branchsetCode ?: string,
        public userType ?:string,
        public version ?:string,
        public additionalBranches ?: string[],
        public partnerCode ?:string,
    ) {}
}

export class UserSearchCriteria {
    constructor (
        public login ?: string,
        public branchCode ?: string,
        public show ?: string
    ) {}
}
