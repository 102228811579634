import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

import {BranchService} from '../branch.service';
import {Branch} from '../branch.model';

@Component({
  selector: 'app-branch-delete-dialog',
  templateUrl: './branch-delete-dialog.component.html',
  styleUrls: ['../branch.component.css']
})
export class BranchDeleteDialogComponent implements OnInit, OnDestroy {
  branch: Branch;
  errorMessage: string;
  constructor(private branchService: BranchService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<BranchDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Branch) {
    this.branch = data;
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
  }

  delete() {
    this.errorMessage = '';
    this.branchService.deleteBranch(this.branch.id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(response => this.onSaveSuccess(response), (response) => this.onSaveError(response));
  }

  onSaveSuccess(result) {
    this.dialogRef.close('deleted');
  }

  onSaveError(response) {
    this.errorMessage = response;
  }

  cancel(): void {
    this.dialogRef.close('canceled');
  }
}
