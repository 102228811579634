import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { DateConverterService } from '../shared/date/date-converter.service';

@Component({
    selector: 'jhi-loan-od-accounts-amend-penal-interest',
    templateUrl: './loan-od-accounts-amend-penal-interest.component.html'
})
export class LoanOdAccountsAmendPenalInterestComponent implements OnInit, OnDestroy {
    onError: any;
    proceedIsTrue: boolean;
    loanOdAccounts: LoanOdAccounts;
    accountId: string;
    dataIsAvailable: boolean;

    constructor(  private loanODAccountsService: LoanOdAccountsService,
                  private route: ActivatedRoute,
                  private router: Router,
                  private dateConverter: DateConverterService
    ) {
        this.proceedIsTrue = false;
    }
    ngOnInit() {
        this.accountId = this.route.params['_value'].accountId;
        this.loanODAccountsService.amendPenalInterestRateEntry(this.accountId).subscribe(
            (res) => {
                this.loanOdAccounts = res;
                this.loanOdAccounts.openedOnDate = this.dateConverter.fromServerToNgbDatePicker(this.loanOdAccounts.openedOnDate);
                this.dataIsAvailable = true;
            }
        );
    }
    ngOnDestroy() {
    }

    previousState() {
        window.history.back();
    }

    clear() {
        this.loanOdAccounts = new LoanOdAccounts();
        this.router.navigate(['/loan-od-accounts']);
    }

    back() {
        this.loanOdAccounts.openedOnDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.loanOdAccounts.openedOnDate);
        this.proceedIsTrue = false;
    }

    proceed(loanOdAccounts) {
        this.loanOdAccounts = loanOdAccounts;
        this.loanOdAccounts.openedOnDate = this.dateConverter.fromNgbDatePickerToDateObject(this.loanOdAccounts.openedOnDate);
        this.proceedIsTrue = true;
    }

    executeError() {
        this.loanOdAccounts.openedOnDate = this.dateConverter.fromStringToNgbDatePicker(this.loanOdAccounts.openedOnDate);
        this.proceedIsTrue = false;
    }
}
