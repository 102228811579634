///<reference path="transaction-summary-dto.model.ts"/>
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AlertService, JhiLanguageService } from 'ng-jhipster';
import { Router } from '@angular/router';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdRepaymentCompositeDto } from './loan-od-accounts-repayment-composite-dto.model';
import { NameValueDto } from './name-value-dto';
import { Accountcode } from '../internal/accountcode.model';
import { MoneyWithCurrencyPipe } from '../shared/moneyWithCurrency.pipe';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';

@Component({
    selector: 'jhi-loan-od-accounts-fee-payment-entry',
    templateUrl: 'loan-od-accounts-fee-payment-entry.component.html'
})
export class LoanOdAccountsFeePaymentEntryComponent implements OnInit, OnDestroy {
    @Input() instruments: NameValueDto;
    accountcodes: Accountcode;
    @Input() loanOdAccounts: LoanOdAccounts;
    @Input() loanOdRepaymentCompositeDto: LoanOdRepaymentCompositeDto;
    totalAmount: number;
    displayTotalAmount: string;
    @Output() proceedToExecute: EventEmitter<LoanOdRepaymentCompositeDto> = new EventEmitter<LoanOdRepaymentCompositeDto>();

    constructor(
        private jhiLanguageService: JhiLanguageService,
        private loanODAccountsService: LoanOdAccountsService,
        private router: Router,
        private alertService: AlertService,
        private moneyWithCurrencyPipe: MoneyWithCurrencyPipe,
        // private dateservice: DateConverterService
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }
    ngOnInit() {
        this.displayTotalAmount = this.loanOdRepaymentCompositeDto.displayTotalFee.displayValue;
        this.totalAmount = this.loanOdRepaymentCompositeDto.displayTotalFee.magnitude;
        this.loanOdRepaymentCompositeDto.repaymentDate = new NgbDate(this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[0] ?
            parseInt(this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[0]) : 2020, this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[1] ?
            parseInt(this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[1]) : 1, this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[2] ?
            parseInt(this.loanOdRepaymentCompositeDto.repaymentDate.split('-')[2]) : 1);
        this.displayAccountCodes(this.loanOdRepaymentCompositeDto.instrument);
    }
    ngOnDestroy() {
    }

    displayAccountCodes(instrument) {
        if (instrument === 'CHEQUE_OR_DD' || instrument === 'OTHER' || instrument === 'NEFT' || instrument === 'RTGS' || instrument === 'IMPS'
            || instrument === 'UPI' || instrument === 'AUTOPAY' || instrument === 'DEBIT_CARD' || instrument === 'CREDIT_CARD') {
            this.loanODAccountsService.getAccountCodesByGroup().subscribe(
                (res) => {
                    this.setAccountCodes(res);
                });
        } else {
            this.accountcodes = undefined;
        }
    }

    private setAccountCodes(data) {
        this.accountcodes = data;
    }

    getTotal() {
        let total = 0;
        for (let i = 0; i < this.loanOdRepaymentCompositeDto.summary.fees.length; i++) {
            if (this.loanOdRepaymentCompositeDto.summary.fees[i].status) {
                total += parseFloat(this.loanOdRepaymentCompositeDto.summary.fees[i].amount2.magnitude);
            }
        }
        if (total === 0) {
            this.loanOdRepaymentCompositeDto.displayTotalFee.magnitude = this.totalAmount;
            this.loanOdRepaymentCompositeDto.displayTotalFee.displayValue = this.displayTotalAmount;
        } else {
            this.loanOdRepaymentCompositeDto.displayTotalFee.magnitude = total;
            this.loanOdRepaymentCompositeDto.displayTotalFee.displayValue = this.moneyWithCurrencyPipe.transform(total);
        }
    }


    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }

    proceed() {
        this.loanODAccountsService.feePaymentExecute(this.loanOdRepaymentCompositeDto).subscribe(
            (res) => {
                this.proceedToExecute.emit(res);
            },
            (res) => this.onSaveError(res)
        );
    }

    private onSaveError(error) {
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
    }

    private onError(error) {
        this.alertService.error(error.error);
    }
}
