export class Tenure {
    constructor(
        public unit?: string,
        public days?: number,
        public magnitude?: number,
        public displayValue?: String,
        public magnitudeAndUnit?: String,
        public magnitudeAsBigDecimal?: number,
    ) {
        this.unit = 'NONE';
        this.days = 0;
        this.magnitude = 0;
        this.displayValue = null;
        this.magnitudeAndUnit = `${this.magnitude} ${this.unit}`;
        this.magnitudeAsBigDecimal = 0;
    }
}
