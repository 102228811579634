import { Component, OnInit, Input,OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { LoanApplication, Person, LoanProduct,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { BankAccount } from '../../business/businessBankAccount/business-bank-accounts.model';
@Component({
  selector: 'eng-change-request',
  templateUrl: './change-request.template.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class ChangeRequestComponent implements OnInit,OnChanges {
  @Input() application: LoanApplication;
  @Input() applicants: Person[];
  @Input()
  menuCode:string;

  changeTypes: NameValueDto[];
  businessAccountTypes: NameValueDto[];
  personAccountTypes: NameValueDto[];
  remainRequests: NameValueDto[] = [];

  applicationProductsDetails: LoanProduct;
  changeRequestForm: FormGroup;

  showType: boolean = false;
  isFormDisabled: Boolean = true;
  allowAccess: Boolean;
  menuItemAllowAccess:boolean;

  selectedChangeReuesttype: string = "";
  displayForm: string;

  allBankAccounts: BankAccount[] = [];
  repaymentAccount: BankAccount;

  allChangeRequests: any = [];
  saveChangeRequest: any;

  selectedChangeRequest: string;

  selectedChangeRequestObject: any;
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private applicationService: ApplicationService,
      private referenceCodeService: ReferenceCodeService) {
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.changeRequestForm = new FormGroup({
      approvedTenure: new FormControl(null),
      revisedTenure: new FormControl(null),
      approvedROI: new FormControl(null),
      revisedROI: new FormControl(null),
      repaymentAccount: new FormControl(""),
      revisedAccount: new FormControl(""),
      // selectedBankAccount: new FormControl("")
    });
    this.getRefCodes();
    this.getChangeRequests();
    this.getMenuItemAccess();

    //this.fetchbusinessBankAccounts()
  }

  ngOnChanges(){
    this.fetchbusinessBankAccounts();
  }

  buildForm() {
    if (this.selectedChangeRequestObject.category === "ROI_CHG") {
      this.changeRequestForm.controls.approvedROI.patchValue(this.selectedChangeRequestObject.approvedValue === null ? this.application.interestSanc : this.selectedChangeRequestObject.approvedValue)
      this.changeRequestForm.controls.revisedROI.patchValue(this.selectedChangeRequestObject.revisedValue === null ? null : this.selectedChangeRequestObject.revisedValue)
    } else if (this.selectedChangeRequestObject.category === "TENURE_CHGS") {
      this.changeRequestForm.controls.approvedTenure.patchValue(this.selectedChangeRequestObject.approvedValue === null ? this.application.tenureSanc : this.selectedChangeRequestObject.approvedValue)
      this.changeRequestForm.controls.revisedTenure.patchValue(this.selectedChangeRequestObject.revisedValue === null ? "" : this.selectedChangeRequestObject.revisedValue)
    } else if (this.selectedChangeRequestObject.category === "BNKAC_CHG") {
      this.changeRequestForm.controls.repaymentAccount.patchValue(this.selectedChangeRequestObject.approvedValue === null ? this.application.repaymentBankaccountUid : this.selectedChangeRequestObject.approvedValue)
      this.changeRequestForm.controls.revisedAccount.patchValue(this.selectedChangeRequestObject.revisedValue === null ? "" : this.selectedChangeRequestObject.revisedValue)
    }
    this.changeRequestForm.disable()
  }

  getChangeRequests() {
    this.applicationService.getPostApprovalChangeRequests(this.application.uid).subscribe((response: any) => {
      this.allChangeRequests = response.body
      if (response.body.length !== 0) {
        this.displayForm = response.body[0].category;
        this.selectedChangeRequestObject = response.body[0]
        this.selectedChangeRequest = response.body[0].uid;
        this.buildForm()
        //this.changeSelectedRequest(response.body[0].checklist.uid)
      }
      this.checkRequestIsExistsInApplicationChangeRequests()

    })
  }

  getRefCodes() {
    this.referenceCodeService.getLoanProducts(this.application.productTypeReq, this.application.productCodeReq).subscribe((response: any) => {
      this.applicationProductsDetails = response.body[0];
    });
    this.referenceCodeService.getShortRefCodes('business_bankac_type').subscribe((response: any) => {
      this.businessAccountTypes = response.business_bankac_type;
    });
    this.referenceCodeService.getShortRefCodes('person_bankac_type').subscribe((response: any) => {
      this.personAccountTypes = response.person_bankac_type;
    });
    this.referenceCodeService.getShortRefCodes('post_approval_item').subscribe((response: any) => {
      this.changeTypes = response.post_approval_item;
    });

  }

  clickAddNew() {
    this.showType = true
  }

  checkChangeTypeValue(value) {
    this.showType = false
    this.displayForm = value
    this.isFormDisabled = !this.isFormDisabled
    this.changeRequestForm.enable()
    this.disableApprovedInputControls()
  }

  fetchbusinessBankAccounts() {
    this.applicationService.getBusinessBankAccounts(
      this.application.uid).subscribe((response: any) => {
        if (response) {
          this.allBankAccounts = response.body;
          this.fetchApplicantBankAccounts();
        }
      });
  }

  fetchApplicantBankAccounts() {
    if (this.applicants.length !== 0) {
      this.applicants.forEach(element => {
        this.applicationService.getApplicantBankAccounts(this.application.uid, element.uid)
          .subscribe((response: any) => {
            if (response) {
              this.allBankAccounts = this.allBankAccounts.concat(response.body);
              this.allBankAccounts.forEach((element, index) => {
                if (element.uid === this.application.repaymentBankaccountUid) {
                  this.repaymentAccount = element
                  this.allBankAccounts.splice(index, 1);
                }
              });
            }
          });
      });
    }
  }


  enableDisableForm() {
    this.isFormDisabled = false
    this.changeRequestForm.enable()
    this.disableApprovedInputControls()
  }


  disableApprovedInputControls() {
    this.changeRequestForm.controls.approvedTenure.disable()
    this.changeRequestForm.controls.approvedROI.disable()
  }


  cancelForm() {
    this.showType = false
    this.displayForm = this.selectedChangeRequestObject.category
    this.isFormDisabled = !this.isFormDisabled
    this.changeRequestForm.reset()
    this.buildForm()
  }

  saveChangeRequests() {
    if (this.changeRequestForm.valid) {
      if (this.allChangeRequests.length !== 0) {
        this.saveChangeRequest = this.selectedChangeRequestObject

      } else {
        this.saveChangeRequest = {
          "category": this.displayForm,
        }
      }
      if (this.selectedChangeRequestObject) {
        if (this.selectedChangeRequestObject.category === "ROI_CHG") {
          this.saveChangeRequest["approvedValue"] = this.changeRequestForm.controls.approvedROI.value
          this.saveChangeRequest["revisedValue"] = this.changeRequestForm.controls.revisedROI.value
        } else if (this.selectedChangeRequestObject.category === "TENURE_CHGS") {
          this.saveChangeRequest["approvedValue"] = this.changeRequestForm.controls.approvedTenure.value
          this.saveChangeRequest["revisedValue"] = this.changeRequestForm.controls.revisedTenure.value
        } else if (this.selectedChangeRequestObject.category === "BNKAC_CHG") {
          this.saveChangeRequest["approvedValue"] = this.changeRequestForm.controls.repaymentAccount.value
          this.saveChangeRequest["revisedValue"] = this.changeRequestForm.controls.revisedAccount.value
        }
      } else {
        if (this.saveChangeRequest.category === "ROI_CHG") {
          this.saveChangeRequest["approvedValue"] = this.changeRequestForm.controls.approvedROI.value
          this.saveChangeRequest["revisedValue"] = this.changeRequestForm.controls.revisedROI.value
        } else if (this.saveChangeRequest.category === "TENURE_CHGS") {
          this.saveChangeRequest["approvedValue"] = this.changeRequestForm.controls.approvedTenure.value
          this.saveChangeRequest["revisedValue"] = this.changeRequestForm.controls.revisedTenure.value
        } else if (this.saveChangeRequest.category === "BNKAC_CHG") {
          this.saveChangeRequest["approvedValue"] = this.changeRequestForm.controls.repaymentAccount.value
          this.saveChangeRequest["revisedValue"] = this.changeRequestForm.controls.revisedAccount.value
        }
      }

      this.applicationService.savePostApprovelChangeRequests(this.application.uid, this.saveChangeRequest).subscribe(response => {
        this.getChangeRequests();
        this.isFormDisabled = !this.isFormDisabled;
      })
    }
  }

  checkRequestIsExistsInApplicationChangeRequests() {
    this.remainRequests = []
    if (this.allChangeRequests.length !== 0) {
      if (this.changeTypes.length !== 0) {
        this.changeTypes.forEach(element => {
          this.allChangeRequests.forEach(applicationChangeRequest => {
            if (element.code !== applicationChangeRequest.category) {
              this.remainRequests.push(element)
            }
          });
        });
      }
    } else {
      this.remainRequests = this.changeTypes;
    }
  }

  revisedROIValidator() {
    let control = this.changeRequestForm.controls["revisedROI"]
    if (control.value === this.changeRequestForm.controls.approvedROI.value) {
      control.setErrors({ "revisedROI": "the value should not be same as approved value" })
      control.markAsDirty()
    } else if (this.applicationProductsDetails !== undefined) {
      if (control.value < this.applicationProductsDetails.minInterest || control.value > this.applicationProductsDetails.maxInterest) {
        control.setErrors({
          "revisedROI": "The tenure should be with in the range.." + this.applicationProductsDetails.minInterest.toLocaleString('en-IN') + " to " +
            this.applicationProductsDetails.maxInterest.toLocaleString('en-IN')
        })
        control.markAsDirty()
      }
    } else if (control.value > this.changeRequestForm.controls.approvedROI.value) {
      control.setErrors({ "revisedROI": "The revised value must be lower than approved ROI." })
      control.markAsDirty()
    } else {
      control.setErrors({ 'revisedROI': null })
      control.updateValueAndValidity();
    }
  }

  revisedTenureValidator() {
    let control = this.changeRequestForm.controls["revisedTenure"];
    if (control.value === this.changeRequestForm.controls.approvedTenure.value) {
      control.setErrors({ "revisedTenure": "The value should not be same as approved value" });
      control.markAsDirty();
    } else if (this.applicationProductsDetails !== undefined) {
      if (control.value < this.applicationProductsDetails.minTenure || control.value > this.applicationProductsDetails.maxTenure) {
        control.setErrors({
          "revisedTenure": "The tenure should be in the range.." 
            + this.applicationProductsDetails.minTenure.toLocaleString('en-IN') + " to "
            + this.applicationProductsDetails.maxTenure.toLocaleString('en-IN')
        });
        control.markAsDirty();
      }
    } else {
      control.setErrors({ 'revisedTenure': null })
      control.updateValueAndValidity();
    }
  }
  

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}
