import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {IgLayoutService} from '../ig-layout.service';
import {PrincipalService} from '../../../../projects/ig-core/src/lib/authentication/principal.service';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import { Router, NavigationStart } from '@angular/router';
import { SecureStorageService} from '../../../../projects/ig-core/src/lib/authentication/secureStorage.service';
import { environment } from '../../../environments/environment'
import { DateFormatPipe } from '@app/utils/date-format.pipe';
@Component({
  selector: 'ig-header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  loggedInUser: any;
  isProduction:boolean=environment.production;
  environmentVariable:any=environment;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches)
  );
  serverDownTime: any;
  
  constructor(private breakpointObserver: BreakpointObserver,
              private igLayoutService: IgLayoutService,
              private principalService: PrincipalService,
              private router: Router,
              private secureStorageService: SecureStorageService,
              private customDatepipe: DateFormatPipe) {

                
  }

  ngOnInit() {
    this.principalService.getAuthenticationState()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((account) => {
      if(account !== null){
        this.secureStorageService.secureSessionStorage.setItem("logInUserDetails",account)
        
      }else{
        this.secureStorageService.secureSessionStorage.clear('logInUserDetails')
      }
      this.loggedInUser = account;
      // Subscribe to changes in the scheduled maintenance time using the observable 'scheduledMaintennanceTime$'.
      // When 'scheduledMaintennanceTimeStatus' in 'secureStorageService' changes, update the 'serverDownTime' with a new value.
      this.secureStorageService.scheduledMaintennanceTime$.subscribe(value => {
          this.showDowntime(value);
      });
    })
  }

  ngOnDestroy(): void {
  }

  toggleSideNav() {
    if (this.loggedInUser) {
      this.igLayoutService.toggleSideNav();
    } else {
      this.igLayoutService.closeSideNav();
    }
  }
  
  changePassword() {
    const changePassword = true;
    this.router.navigate(['reset/finish'], { queryParams: { changePassword } });
  }

  showDowntime(value){
    this.serverDownTime = this.customDatepipe.transform(value, 'DATEHM12');
  }
}
