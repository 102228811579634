import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
    selector: 'validation-error',
    template: `
        <ul *ngIf="shouldShowErrors()" class="list-unstyled">
            <li style="color: red" *ngFor="let error of listOfErrors()">{{error}}</li>
        </ul>
    `,
})
export class ValidationErrorComponent {

    private static readonly errorMessages = {
        'required': () => 'This field is required.',
        'minlength': (params) => 'Please enter a minimum of ' + params.requiredLength + ' characters.',
        'maxlength': (params) => 'Please enter a maximum of ' + params.requiredLength + ' characters.',
        'pattern': (params) => 'The required pattern is: ' + params.requiredPattern,
        'email': (params) => 'Invalid email format',
        'ngbDate': (params) => 'Invalid date format. The correct format is YYYY-MM-DD',
        'min': (params) => 'Please enter a value greater than ' + params.min + '.',
        'max': (params) => 'Please enter a value lesser than ' + params.max + '.',
        'minFloat': (params) => 'Please enter a value greater than ' + params.min + '.',
        'maxFloat': (params) => 'Please enter a value lesser than ' + params.max + '.',
    }

    @Input()
    private control: AbstractControlDirective | AbstractControl;

    shouldShowErrors(): boolean {
        return this.control &&
            this.control.errors &&
            (this.control.dirty || this.control.touched);
    }

    listOfErrors(): string[] {
        return Object.keys(this.control.errors)
            .map((field) => this.getMessage(field, this.control.errors[field]));
    }

    private getMessage(type: string, params: any) {
        return ValidationErrorComponent.errorMessages[type](params);
    }
}
