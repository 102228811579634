import { Person } from '@app/applications/applications.model';
import { KycProof } from '../../business/kycProofs/kyc.model';
import { Address, ApplicantAddress } from '../applicantAddress/applicant-address.model';

// export class ApplicantInformationComposite {
//     constructor(
//         public applicantInformations?: ApplicantInformations[]
//     ) { }
// }

export class ApplicantInformations {
    constructor(
        public person?: Person,
        public personProfile?: PersonProfile,
        public dependents?: Dependents,
        public partyLink?: PartyLink,
        public personAddressInformations?: ApplicantAddress[],
        public kyc?: KycProof
        
    ) { }
}

export class PersonProfile {
    constructor(
        public uid?: string,
        public personId?: string,
        public nationality?: string,
        public nationalityOther?: string,
        public education?: string,
        public educationOther?: string,
        public incomeSource?: string,
        public incomeSourceOther?: string,
        public annualIncome?: string,
        public annualIncomeOther?: string,
        public occupation?: string,
        public occupationOther?: string,
        public religion?: string,
        public religionOther?: string,
        public socialCategory?: string,
        public socialCategoryOther?: string,
        public physicallyChallengedFlag?: boolean,
        public physicalChallenge?: string,
        public physicalChallengeOther?: string,
        public experienceInYears?: number,
        public noOfFamilyMembers?: number,
        public noOfDependents?: number,
        public noOfChildren?: number,
        public noOfWorkingMembers?: number,
        public familyGoodHealthFlag?: boolean,
        public familyInsuranceFlag?: boolean,
        public primaryPhone?: string,
        public alternatePhone?: string,
        public landlinePhone?: string,
        public emailId?: string,
        public profilePicFileUid?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public personUid?: string,
        public version?: number,
        public incomeSourceMulti?: string,
        public monthlyIncome?: string,
        public relationshipType?:string
    ) {}
    
}

export class Dependents {
    constructor(
        public uid?: string,
        public dependentType?: string,
        public name?: string,
        public educationType?: string,
        public instituteType?: string,
        public instituteName?: string,
        public age?: any,
        public gender?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public version?: number,
        public linkToType?: string,
        public linkToId?: string,
        public linkToUid?: string
        ) {}
    
}

export class PartyLink{
    constructor(
        public uid?: string,
        public partyId?: string,
        public partyPlay?: string,
        public partyType?: string,
        public partyUid?: string,
        public primaryPartyFlag?: boolean,
        public referenceFor?: string,
        public referenceForUid?: string,
        public relationshipType?: string,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public version?: number
        ) {}
    
}