import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { BottomSheetComponent } from './bottom-sheet.component';

@Injectable({
  providedIn: 'root'
})
export class BottomSheetService {

    constructor(private bottomSheet: MatBottomSheet) {
    }

    open() {
        this.bottomSheet.open(BottomSheetComponent, {disableClose: true});
    }
}
