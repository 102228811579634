import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { BusinessIncomeType, BusinessIncomeSummary, BusinessIncomeDetail, BusinessIncome } from '../financials.model';
import { MatSnackBar } from '@angular/material';
import { ValidationService } from '../../../application-validators/validation.service'
@Component({
  selector: 'eng-gst-returns',
  templateUrl: 'gst-returns.template.html',
  styleUrls: ['../../application-details.styles.scss', 'gst-returns.styles.css']
})
export class GSTReturnsComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;

  gstReturns: BusinessIncomeSummary;
  gstReturnsDetails: BusinessIncomeType = new BusinessIncomeType();
  recentMonths: string[];

  gstReturnsForm: FormGroup;

  showForm: boolean;
  isFormDisabled: boolean;
  allowAccess:Boolean; 
  cfaFinalized:boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private validationService: ValidationService) {
      this.allowAccess = this.applicationService.allowAccess; 
  }

  ngOnInit() {
    this.gstReturnsForm = this.formBuilder.group({
      gstReturnsSummaryForm: this.formBuilder.group({}),
      monthlyGstReturnsForm: this.formBuilder.array([])
    })
    this.isFormDisabled = true;
    this.getCfaStatus()
    this.fetchGstReturns();
    this.getMenuItemAccess();

  }

  fetchGstReturns() {
    this.showForm = false;
    this.applicationService.getBusinessFinancials(this.application.uid, 'gst')
      .subscribe((response: any) => {
        if (response) {
          this.gstReturns = response;
          this.showForm = (this.gstReturns.businessIncome.length > 0
            && this.gstReturns.businessIncomeDetail.length > 0);
          if (this.showForm) {
            this.gstReturnsDetails.businessIncome = this.gstReturns.businessIncome[0];
            this.gstReturnsDetails.businessIncomeDetail = this.gstReturns.businessIncomeDetail[0];
            this.fetchAssessmentYearAndMonths();
          }
        }
      });
  }

  fetchAssessmentYearAndMonths(gstReturnsDetails ?: BusinessIncomeType) {
    if(!gstReturnsDetails) gstReturnsDetails = this.gstReturnsDetails;
    this.showForm = false;
    this.applicationService.getAssessmentYearAndMonths(this.application.uid)
      .subscribe((response: any) => {
        if (response) {
          this.showForm = true;
          this.recentMonths = response.recentMonths;
          this.buildGstReturnsForm(gstReturnsDetails);
        }
      });
  }

  addNewGstReturnsSummary() {
    this.isFormDisabled = false;
    let newGstReturnsDetails = new BusinessIncomeType();
    newGstReturnsDetails.businessIncome = new BusinessIncome();
    newGstReturnsDetails.businessIncomeDetail = new BusinessIncomeDetail();
    this.fetchAssessmentYearAndMonths(newGstReturnsDetails)
  }

  buildGstReturnsForm(gstReturnsDetails: BusinessIncomeType) {
    if (this.showForm) {
      this.gstReturnsForm.controls.gstReturnsSummaryForm = this.formBuilder.group({
        averageGstReturns: gstReturnsDetails.businessIncome.monthlySales,
        incomeMultiplier: gstReturnsDetails.businessIncome.incomeMultiplier ?
          gstReturnsDetails.businessIncome.incomeMultiplier.toFixed(2) : undefined,
        eligibleMonthlyTurnover: gstReturnsDetails.businessIncome.eligibleMonthlyTurnover
      });
      this.gstReturnsForm.controls.monthlyGstReturnsForm = this.formBuilder.array([]);
      const array = this.gstReturnsForm.controls.monthlyGstReturnsForm as FormArray;
      this.recentMonths.forEach((month, index) => {
        const group = new FormGroup({
          mGstReturns: new FormControl(gstReturnsDetails
            .businessIncomeDetail['m' + (index + 1) + 'Amount']),
        });
        this.applyValidatorsToGstReturn(group)
        array.push(group);
      });
      this.gstReturnsDetails = gstReturnsDetails;
    }
  }

  computeSummary() {
    this.validationService.markFormGroupTouched(this.gstReturnsForm)
    if (this.gstReturnsForm.valid) {
      let monthlyGstReturnsDetails: BusinessIncomeDetail[] = [];
      let monthlyGstReturns = this.gstReturnsDetails.businessIncomeDetail;
      this.recentMonths.forEach((month, index) => {
        monthlyGstReturns['m' + (index + 1) + 'Amount'] =
          this.gstReturnsForm.get('monthlyGstReturnsForm')['controls'][index].value.mGstReturns
      });
      monthlyGstReturns.incomeDocumentType = 'GST_RTN';
      monthlyGstReturnsDetails.push(monthlyGstReturns);
      
      this.applicationService.getCalculatedBusinessIncomeSummary(this.application.uid, "gst",
        monthlyGstReturnsDetails).subscribe(response => {
          if (response && response.body.length > 0) {
            this.updateGstReturnsSummary(response.body[0]);
          }
      });
    }
  }

  updateGstReturnsSummary(gstReturnsSummary) {
    this.gstReturnsForm.patchValue({
      gstReturnsSummaryForm: {
        averageGstReturns: gstReturnsSummary.monthlySales,
        incomeMultiplier: gstReturnsSummary.incomeMultiplier.toFixed(2),
        eligibleMonthlyTurnover: gstReturnsSummary.eligibleMonthlyTurnover
      }
    });
  }
  
  saveGstReturnsDetails() {
    this.validationService.markFormGroupTouched(this.gstReturnsForm)
    if(!this.isFormDisabled && this.gstReturnsForm.valid && this.gstReturnsForm.controls.monthlyGstReturnsForm.valid ){
      if(this.gstReturns.businessIncome && this.gstReturns.businessIncome.length === 0) {
        let newGstReturns = new BusinessIncome();
        newGstReturns.category = 'gst';
        newGstReturns.incomeDocumentType = 'GST_RTN';
        newGstReturns.frequency = 'M';
        newGstReturns.contextType = 'application';
        newGstReturns.contextUid = this.application.uid;
        this.gstReturns.businessIncome.push(newGstReturns);

        let newGstReturnDetails = new BusinessIncomeDetail();
        newGstReturnDetails.category = 'gst';
        newGstReturnDetails.incomeDocumentType = 'GST_RTN';
        newGstReturnDetails.frequency = 'M';
        newGstReturnDetails.contextType = 'application';
        newGstReturnDetails.contextUid = this.application.uid;
        this.gstReturns.businessIncomeDetail.push(newGstReturnDetails);
      }
      let gstReturnsFormFields = this.gstReturnsForm.value;
      this.gstReturns.businessIncome[0].monthlySales =
        gstReturnsFormFields.gstReturnsSummaryForm.averageGstReturns;
      this.gstReturns.businessIncome[0].incomeMultiplier =
        gstReturnsFormFields.gstReturnsSummaryForm.incomeMultiplier;
      this.gstReturns.businessIncome[0].eligibleMonthlyTurnover =
        gstReturnsFormFields.gstReturnsSummaryForm.eligibleMonthlyTurnover;
      this.recentMonths.forEach((month, index) => {
        this.gstReturns.businessIncomeDetail[0]['m' + (index + 1) + 'Amount'] =
          this.gstReturnsForm.get('monthlyGstReturnsForm')['controls'][index].value.mGstReturns
      });

      this.applicationService.saveBusinessIncomeSummary(this.application.uid, "gst",
        this.gstReturns).toPromise().then(
          (_success) => {
            this._snackbar.open("GST Returns details updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.fetchGstReturns();
          }, (failure) => {
            let errormesg =[]
            errormesg.push("GST Returns details update failed")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
          }
        );
    }else{
      this._snackbar.open("Please fill all fields correctly", "Close", {
        duration: 2000,
      })
    }
  }

  enableDisableForm() {
    this.isFormDisabled = !this.isFormDisabled;
  }

  cancelForm() {
    this.gstReturnsForm = this.formBuilder.group({
      gstReturnsSummaryForm: this.formBuilder.group({}),
      monthlyGstReturnsForm: this.formBuilder.array([])
    })
    this.gstReturnsDetails.businessIncome = this.gstReturns.businessIncome[0];
    this.gstReturnsDetails.businessIncomeDetail = this.gstReturns.businessIncomeDetail[0];
    if( this.gstReturnsDetails.businessIncome == undefined) this.gstReturnsDetails.businessIncome = []as any
    if( this.gstReturnsDetails.businessIncomeDetail == undefined) this.gstReturnsDetails.businessIncomeDetail = []as any
    this.buildGstReturnsForm(this.gstReturnsDetails);
    this.isFormDisabled = true;
  }
  
  applyValidatorsToGstReturn(group) {
    this.validationService.applyValidationRules(group, "GSTReturns")
      .then((controlValidators) => {
      }).catch(() => { });
  }

  //this function will call a api to get CFA Finalized or not status
  getCfaStatus(){
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response =>{
      this.cfaFinalized=response.body.cfaFinalized
      if(this.cfaFinalized){
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close" ,{
          duration:5000,
        });
      }
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}