import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationService } from "@app/applications/application.service";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { OnDestroy } from "@angular/core";
import { Applicant } from "@app/applications/application-details/collateral/collateralDetails/collateral-details.model";
import { LINK_TO_TYPE_PERSON, USER_AGENT_WEB } from "@app/constants/data.constants";
import { AnalyzerRequestDTO, AnalyzerResponseDTO, BankAccountHolderComposite } from "@app/applications/application-details/business/businessBankAccount/business-bank-accounts.model";
import { ValidationService } from "@app/applications/application-validators/validation.service";
import { ControlValidators } from "@app/applications/application-validators/control-validators.directive";
import { ReferenceCodeService } from "@app/admin/reference-code/reference-code.service";
import { NameValueDto } from "@app/loan-od-accounts/name-value-dto";
@Component({
  selector: "eng-bank-analyzer",
  templateUrl: "bank-analyzer.template.html",
  styleUrls: [
    "../../../application-details.styles.scss",
    "../xdata-service.style.scss",
  ],
})
export class BankAnalyzerComponent implements OnInit, OnDestroy {
  applicationUid: string;

  bankDetailsForm: FormGroup;
  serviceProviderCode: string;
  accountTypes: NameValueDto[];
  analyzerRequestDTO: AnalyzerRequestDTO = {};
  bankAnalyzerResponse: AnalyzerResponseDTO = {};
  isShowResultCard: boolean;
  bankAccountDetails: BankAccountHolderComposite[];
  applicants: Applicant[];
  // this is list of request made for bank statement analysis
  recentRequests: AnalyzerResponseDTO[];

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private validationService: ValidationService,
    private referenceCodeService: ReferenceCodeService
  ) {
    this.bankDetailsForm = this.formBuilder.group({
      borrower: ["", Validators.required],
      mobileNumber: [""],
    });
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.applicationUid;
        this.serviceProviderCode = params.xDataService;
      });
  }

  ngOnInit() {
    this.getRefCodes();
    this.getApplicants();
    this.applyValidations();
    this.getAnalyzerRequests();
  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('person_bankac_type').subscribe((response: any) => {
      this.accountTypes = response.person_bankac_type;
    });
  }

  getApplicants() {
    this.applicationService.getApplicants(this.applicationUid).subscribe((response: any) => {
        if (response) {
          this.applicants = response;
        }
      });
  }

  // this api is to list all bank statement analysis requests
  getAnalyzerRequests(){
    this.applicationService.fetchBankStatementAnalyzerRequests(this.applicationUid).subscribe(response =>{
      this.recentRequests = response.body
    })
  }

  onInitiateClick() {
    this.validationService.markFormGroupTouched(this.bankDetailsForm)
    if (this.bankDetailsForm.valid) {
      this.analyzerRequestDTO.applicationUid = this.applicationUid;
      this.analyzerRequestDTO.name = this.bankDetailsForm.controls.borrower.value.name;
      this.analyzerRequestDTO.mobileNumber = this.bankDetailsForm.controls.mobileNumber.value;
      this.analyzerRequestDTO.linkToType = LINK_TO_TYPE_PERSON;
      this.analyzerRequestDTO.linkToUid = this.bankDetailsForm.controls.borrower.value.uid;

      this.applicationService.bankAnalyzerInitiate(this.applicationUid, this.analyzerRequestDTO).toPromise().then(
        (_success) => {
          this.bankAnalyzerResponse = _success.body;
          window.open(this.bankAnalyzerResponse.linkUrl, '_blank');
          this.getAnalyzerRequests();
          this._snackbar.open("Request initiated successfully", "Close", {
            duration: 9000,
          });
        },
        (failure) => {
          this.isShowResultCard = false;
          this._snackbar.open(failure, "Close", {
            duration: 9000,
          });
        }
      );
    }
  }

  onBorrowerChange(borrower) {
    // previous value will be stored in mobileNumber, hence we are reseting the value to empty
    this.bankDetailsForm.controls.mobileNumber.patchValue("");
    this.isShowResultCard = false;
    if (borrower.primaryPhone) {
      this.bankDetailsForm.patchValue({
        mobileNumber: borrower.primaryPhone
      });
    }
  }

  onRetriveClick(analyzerRequestDTO?: any) {
    this.isShowResultCard = false;
    analyzerRequestDTO.userAgent = USER_AGENT_WEB;

    this.applicationService.retriveBankDetails(this.applicationUid, analyzerRequestDTO).toPromise().then(
      (_success) => {
        this.bankAnalyzerResponse = _success.body;
        this.isShowResultCard = true;
        // calling getAnalyzerRequests if status is "SUCCESS" will update the recentRequests status and it will change to success
        if(this.bankAnalyzerResponse.status === 'SUCCESS'){
          this.getAnalyzerRequests();
        }
        this._snackbar.open(this.bankAnalyzerResponse.message, "close",{
          duration: 9000
        })
      },(failure) => {
        this.isShowResultCard = false;
        this._snackbar.open(failure, "close", {
          duration: 8000,
        });
      }
    )
  }

  applyValidations(){
    this.bankDetailsForm.controls['mobileNumber'].setValidators(
      [ControlValidators.patternValidator("((\\+91-?)|0)?[0-9]{10}", "Mobile number should be 10 digits"),ControlValidators.requiredValidator("Mobile Number is Required")]);
      this.bankDetailsForm.controls['mobileNumber'].updateValueAndValidity()
  }

  ngOnDestroy(): void { }
}
