/*
* @Author Divyjyot Saraon
*
* This directive changes input values to null in the corresponding ngModel if the input is erased.
* The default behaviour of Angular is to set the ngModel to an empty string if the input is touched
* and then the value is erased. instead it will now become null.
*
*/

import { NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[defaultValueNull]'
})
export class DefaultValueNullDirective {
  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event.target'])
  onEvent(target: HTMLInputElement) {
    this.control.viewToModelUpdate((target.value === '') ? null : target.value);
  }
}
