import { AbstractControl } from '@angular/forms';


export class ControlValidators {

    private static _instance: ControlValidators;

    private static get instance() {
        if (!ControlValidators._instance) {
            ControlValidators._instance = new ControlValidators();
        }
        return ControlValidators._instance;
    }

    static numberValidator = (errorMessage: string | number) => {
        return (control: AbstractControl) => {
            if (isNaN(control.value)) {
                return {
                    numberValidator: errorMessage
                };
            }
            return null;
        };
    }

    static wholeNumberValidator = (errorMessage: string | number) => {
        return (control: AbstractControl) => {
            if (isNaN(control.value) || (!isNaN(control.value) &&
                    control.value%1 !== 0)) {
                return {
                    wholeNumberValidator: errorMessage
                };
            }
            return null;
        };
    }

    static requiredValidator = (errorMessage: string | number) => {
        return (control: AbstractControl) => {
            const val: string = control.value;
            if (val == null || val.length === 0) {
                return {
                    requiredValidator: errorMessage
                };
            }
            return null;
        };
    }

    static minValueValidator = (min: number, inclusive: boolean,
        errorMessage: string, allowZero?: boolean) => {
        return (control: AbstractControl) => {
            if (control.value === null) {
                return null;
            }
            if (allowZero && control.value === 0) {
                return null;
            }
            if ( Number(control.value) < min ||
                (!inclusive && Number(control.value) === min)) {
                return {
                    minValueValidator: errorMessage
                };
            }
            return null;
        };
    }

    static maxValueValidator = (max: number, inclusive: boolean, errorMessage: string) => {
        return (control: AbstractControl) => {
            if (control.value === null) {
                return null;
            }
            if (isNaN(control.value) || Number(control.value) > max ||
                (!inclusive && Number(control.value) === max)) {
                return {
                    maxValueValidator: errorMessage
                };
            }
            return null;
        };
    }

    // This control must be numerically less than the other control
    static lessThanAnotherValidator = (controlKey: string, inclusive: boolean,
        errorMessage: string) => {
        return (control: AbstractControl) => {
            if (control.value === null) {
                return null;
            }
            const otherControl = ControlValidators.getOtherControl(control, controlKey);
            if (!otherControl || otherControl.value === null || isNaN(control.value)) {
                return null;
            }
            if (isNaN(control.value) || Number(control.value) > Number(otherControl.value) ||
                (inclusive && Number(control.value) === Number(otherControl.value))) {
                return {
                    lessThanAnotherValidator: errorMessage
                };
            }
            return null;
        };
    }

    // This control must be numerically greater than the other control
    static greaterThanAnotherValidator = (controlKey: string, inclusive: boolean,
        errorMessage: string) => {
        return (control: AbstractControl) => {
            if (control.value === null) {
                return null;
            }
            const otherControl = ControlValidators.getOtherControl(control, controlKey);
            if (!otherControl || otherControl.value === null || isNaN(control.value)) {
                return null;
            }
            if (isNaN(control.value) || Number(control.value) < Number(otherControl.value) ||
                (inclusive && Number(control.value) === Number(otherControl.value))) {
                return {
                    lessThanAnotherValidator: errorMessage
                };
            }
            return null;
        };
    }

    // If this control is true (has a value), then the other control is required
    static requiredIfTrueValidator = (controlKey: string, errorMessage: string,
        zeroIsEmpty = false) => {
        return (control: AbstractControl) => {
            const otherControl = ControlValidators.getOtherControl(control, controlKey);
            if (!otherControl || !otherControl.value) {
                return null;
            }
            const val: string = control.value;
            if (val == null || val.length === 0 || (zeroIsEmpty && +val === 0)) {
                return {
                    requiredIfTrueValidator: errorMessage
                };
            }
            return null;
        };
    }

    // If this control is false (empty), then the other control is required
    static requiredIfFalseValidator = (controlKey: string, errorMessage: string,
        zeroIsEmpty = false) => {
        return (control: AbstractControl) => {
            const otherControl = ControlValidators.getOtherControl(control, controlKey);
            if (!otherControl || !otherControl.value) {
                return null;
            }
            const val: string = control.value;
            if (!(val == null || val.length === 0 || (zeroIsEmpty && +val === 0))) {
                return {
                    requiredIfFalseValidator: errorMessage
                };
            }
            return null;
        };
    }

    // If this control has a value, then the other control cannot be false/0/empty
    static notAllowedIfFalseValidator = (controlKey: string, errorMessage: string,
        zeroIsFalse = true) => {
        return (control: AbstractControl) => {
            const otherControl = ControlValidators.getOtherControl(control, controlKey);
            if (!otherControl) {
                return null;
            }
            const val: string = control.value;
            // If this control has a value
            if (val !== null && (val.length > 0 || +val !== 0 || !zeroIsFalse)) {
                const otherValue: string = otherControl.value;
                // and the other control is empty, this is an error
                if (otherValue === null || (otherValue.length === 0 ||
                    (zeroIsFalse && +otherValue === 0))) {
                    return {
                        notAllowedIfFalseValidator: errorMessage
                    };
                }
            }
            return null;
        };
    }

    // Valid if either (or both) of the controls has a value
    static eitherRequiredValidator = (controlKey: string, errorMessage: string,
        zeroIsEmpty = false) => {
        return (control: AbstractControl) => {
            const val: any = control.value;
            if (val == null || val === false || val.length === 0 || (zeroIsEmpty && +val === 0)) {
                const otherControl = ControlValidators.getOtherControl(control, controlKey);
                if (otherControl) {
                    const val2 = otherControl.value;
                    if (val2 == null || val2 === false || val2.length === 0 || (zeroIsEmpty && +val2 === 0)) {
                        return {
                            eitherRequiredValidator: errorMessage
                        };
                    }
                }
            }
            return null;
        };
    }

    // If one control has a value, then the other cannot also have a value
    static bothNotAllowedValidator = (controlKey: string, errorMessage: string,
        zeroIsEmpty = false) => {
        return (control: AbstractControl) => {
            let thisControlEmpty = false;
            let otherControlEmpty = false;

            const val: any = control.value;
            if (val == null || val === false || val.length === 0 || (zeroIsEmpty && +val === 0)) {
                thisControlEmpty = true;
            }

            const otherControl = ControlValidators.getOtherControl(control, controlKey);
            if (otherControl) {
                const val2 = otherControl.value;
                if (val2 == null || val2 === false || val2.length === 0 || (zeroIsEmpty && +val2 === 0)) {
                    otherControlEmpty = true;
                }
            }

            // If just cleared this control, need to remove error from the other control
            if (thisControlEmpty && otherControl && !otherControlEmpty &&
                otherControl.errors && otherControl.errors['bothNotAllowedValidator']) {
                delete otherControl.errors['bothNotAllowedValidator'];
                otherControl.updateValueAndValidity();
            }

            // No error if either control is empty
            if (thisControlEmpty || otherControlEmpty) {
                // For some reason, the error is sometimes not being removed
                if (control.errors && control.errors['bothNotAllowedValidator']) {
                    delete control.errors['bothNotAllowedValidator'];
                }
                return null;
            }
            return {
                bothNotAllowedValidator: errorMessage
            };
        };
    }

    // If one field has a value, then the other is also required
    static bothRequiredTogetherValidator = (controlKey: string, errorMessage: string,
        zeroIsEmpty = false) => {
        return (control: AbstractControl) => {
            let thisControlEmpty = false;
            let otherControlEmpty = false;

            const val: any = control.value;
            if (val == null || val === false || val.length === 0 || (zeroIsEmpty && +val === 0)) {
                thisControlEmpty = true;
            }

            const otherControl = ControlValidators.getOtherControl(control, controlKey);
            if (otherControl) {
                const val2 = otherControl.value;
                if (val2 == null || val2 === false || val2.length === 0 || (zeroIsEmpty && +val2 === 0)) {
                    otherControlEmpty = true;
                }
            }

            // No error both have values or both are empty
            if ((!thisControlEmpty && !otherControlEmpty) || (thisControlEmpty && otherControlEmpty)) {
                // Need to remove error from the other control if:
                //   just cleared this control and the other was empty OR
                //   just entered a value in this control and the other had a value
                if (otherControl && otherControl.errors && otherControl.errors['bothRequiredTogetherValidator']) {
                    if ((!thisControlEmpty && !otherControlEmpty) || (thisControlEmpty && otherControlEmpty)) {
                        delete otherControl.errors['bothRequiredTogetherValidator'];
                        otherControl.updateValueAndValidity();
                    }
                }
                return null;
            }
            return {
                bothRequiredTogetherValidator: errorMessage
            };
        };
    }


    static numericRangeValidator = (
        minValue: number,
        maxValue: number,
        allowZero?: boolean,
        inclusive?: boolean,
        errorMessage?: string) => {
        return (control: AbstractControl) => {
            if (control.value === null) {
                return null;
            }
            if (allowZero && control.value === 0) {
                return null;
            }
            // Default to inclusive
            if (!inclusive) {
                inclusive = true;
            }
            const num = +control.value;
            if (isNaN(control.value) || !(num <= maxValue && num >= minValue) ||
                (!inclusive && (num === maxValue || num === minValue))) {
                return {
                    rangeValueValidator: errorMessage
                };
            }
            return null;
        };
    }

    static maxLengthValidator = (maxLength: number, errorMessage: string) => {
        return (control: AbstractControl) => {
            if (control.value && control.value.length > maxLength) {
                return {
                    maxLengthValidator: errorMessage
                };
            }
            return null;
        };
    }

    static patternValidator = (pattern: string, errorMessage: string) => {
        
        return (control: AbstractControl) => {
            if (control.value) {
                const regex = new RegExp(`^${pattern}$`);
                const value = <string>control.value;
                if (!regex.test(value)) {
                    return {
                        patternValidator: errorMessage
                    };
                }
            }
            return null;
        };
    }

    static anyValueExceptValidator = (except: Array<string>, errorMessage: string) => {
        return (control: AbstractControl) => {
            const val: string = control.value;
            if (val == null || val.length === 0 || except.indexOf(val) !== -1) {
                return {
                    anyValueExceptValidator: errorMessage
                };
            }
            return null;
        };
    }

    // Must equal one of these values
    static mustEqualValidator = (options: Array<string>, errorMessage: string) => {
        return (control: AbstractControl) => {
            const val: string = control.value;
            if (options.indexOf(val) === -1) {
                return {
                    mustEqualValidator: errorMessage
                };
            }
            return null;
        };
    }

    private static getOtherControl(control: AbstractControl, controlKey: string) {
        const parts = controlKey.split('.');
        let parent = control;
        parts.forEach(part => {
            parent = parent.parent;
        });
        return parent.get(controlKey);
    }

    static pastDateValidator = (errorMessage: string) => {
        return (control: AbstractControl) => {
            let currentDateTime = new Date();
            let controlValue = new Date(control.value);
            if (control.value <= currentDateTime) {
                return {
                    pastDateValidator: errorMessage
                };
            }
            return null;
        };
    }

    static futureDateValidator = (errorMessage: string) => {
        return (control: AbstractControl) => {
            let currentDateTime = new Date();
            let controlValue = new Date(control.value);
            if (currentDateTime < control.value) {
                return {
                    futureDateValidator: errorMessage
                };
            }
            return null;
        };
    }
    static maxDateValidator = (maxDate: Date, inclusive: boolean, errorMessage: string) => {
        return (control: AbstractControl) => {
            if ((new Date(control.value) > new Date(maxDate)) || (!inclusive && (new Date(control.value) == new Date(maxDate)))) {
                return {
                    maxDateValidator: errorMessage
                };
            }
            return null;
        };
    }
    static minDateValidator = (minValue: any, inclusive: boolean, errorMessage: string) => {
        return (control: AbstractControl) => {
            if ((control.value < minValue) || (!inclusive && (control.value == minValue))) {
                return {
                    minDateValidator: errorMessage
                };
            }
            return null;
        };
    }
    // This date control must be after the other control
    static afterAnotherDateValidator = (controlKey: string, inclusive: boolean, errorMessage: string) => {
        return (control: AbstractControl) => {
            let invalidDate = false;
            if (!new Date(control.value)) {
                invalidDate = true;
            }
            const otherControl = ControlValidators.getOtherControl(control, controlKey);
            if (!new Date(otherControl.value)) {
                invalidDate = true;
            }
            if (!invalidDate &&
                ((new Date(control.value) < new Date(otherControl.value)) || (!inclusive && (new Date(control.value) == new Date(otherControl.value)))
                )) {
                return {
                    afterAnotherDateValidator: errorMessage
                };
            }
            if (otherControl && otherControl.errors && otherControl.errors['beforeAnotherDateValidator']) {
                delete otherControl.errors['beforeAnotherDateValidator'];
                otherControl.updateValueAndValidity();
            }
            return null;
        };
    }

    
    static emptyValidator = (errorMessage: string | number) => {
        return (control: AbstractControl) => {
            const val: string = control.value;
            if (val == null || val.trim().length === 0) {
                return {
                    emptyValidator: errorMessage
                };
            }
            return null;
        };
    }
}
