import { Money } from "./money";
import { Tenure } from "./tenure";
enum OperationalStatus {
    'ACTIVE',
    'INACTIVE',
    'CLOSED',
    'FROZEN',
    'WRITE_OFF',
    'SETTLED',
}
export class InsurancePolicyDto {
    public id?: number;
    public version?: number;
    public productCode?: string;
    public currencyCode?: string;
    public status?: OperationalStatus;
    public accountId?: string;
    public customerId?: string;
    public jobId?: string;
    public insuredName?: string;
    public insurer?: string;
    public policyStartDate?: any;
    public policyNum?: string;
    public premiumAmount?: Money;
    public tenure?: Tenure;
}