import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';

import { ProductSet } from './product-set.model';
import { ProductSetService } from './product-set.service';

@Injectable({
    providedIn: 'root'
})
export class ProductSetResolverService implements Resolve<ProductSet> {
    constructor(private productSetService: ProductSetService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductSet> | Observable<never> {
        return this.productSetService.getProductSet(+route.paramMap.get('id'));
    }
}
