import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute , Router} from '@angular/router';
import { Response } from '@angular/http';

import { EventManager, AlertService, JhiLanguageService } from 'ng-jhipster';
import { DateConverterService } from '../shared/date/date-converter.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdAmendment} from './loan-od-amendment.model';
import { LoanOdRepaymentDto} from './loan-od-repayment-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-amend-open-date',
    templateUrl: './loan-od-accounts-amend-open-date.component.html',
})
export class LoanOdAccountsAmendOpenDateComponent implements OnInit {

    amendmentDto: LoanOdAmendment;
    subscription: any;
    proceedIsTrue = false;
    dataIsAvailable  = false;
    isSuccessful = false;

    constructor(
        private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService,
        private router: Router,
        private loanODAccountsService: LoanOdAccountsService,
        private eventManager: EventManager,
        private route: ActivatedRoute,
        private dateConverter: DateConverterService
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        this.amendmentDto = this.route.snapshot.data['amendmentDto'];
        this.amendmentDto.valueDate = this.dateConverter.fromServerToNgbDatePicker(this.amendmentDto.valueDate);
            this.amendmentDto.maturityDate = this.dateConverter.fromServerToNgbDatePicker(this.amendmentDto.newDate);
    }

    clear() {
        this.amendmentDto = new LoanOdAmendment();
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    print() {
        window.print();
    }

    private onError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
            this.alertService.error('encoreclientApp.loanOdAccounts.genericError');
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.alertService.error('encoreclientApp.loanOdAccounts.genericError');
                }
            } catch (exception) {
                this.alertService.error('encoreclientApp.loanOdAccounts.genericError');
            }
        }
    }

    execute() {
        this.amendmentDto.valueDate = this.dateConverter.fromNgbDatePickerToDateObject(this.amendmentDto.valueDate);
        this.amendmentDto.maturityDate = this.dateConverter.fromNgbDatePickerToDateObject(this.amendmentDto.maturityDate);
        this.proceedIsTrue = true;
    }

    confirm() {
        this.loanODAccountsService.amendAccountOpenDateResult(this.amendmentDto)
            .subscribe(
                (res) => this.onConfirmSuccess(res),
                (res) => {
                    this.onError(res);
                    this.back();
                }
            );
    }

    private onConfirmSuccess(data) {
        this.isSuccessful = true;
        /* this.alertService.success('encoreclientApp.loanOdAccounts.amendLoanAmountSuccess'
            , { id: this.amendmentDto.accountId });
        this.router.navigate(['/loans/loan-od-accounts']); */
    }

    back() {
        this.amendmentDto.valueDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.amendmentDto.valueDate);
        this.amendmentDto.maturityDate = this.dateConverter.fromDateObjectToNgbDatePicker(this.amendmentDto.maturityDate);
        this.proceedIsTrue = !this.proceedIsTrue;
    }

}
