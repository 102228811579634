import { Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter, ViewChild } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { MatExpansionPanel } from '@angular/material';
import { ApplicationCheckList,CheckListItem } from '../application-document.model';
import { MatSnackBar } from '@angular/material';
import { SecureStorageService} from '../../../../../../projects/ig-core/src/lib/authentication/secureStorage.service';
import { user } from '@app/constants/data.constants';
import { RecentOpenedApplicationsService } from '@app/applications/recent-opened-applications.service';
import { ApplicationDetailsComponent } from '../../application-details.component';

@Component({
  selector: 'eng-check-list',
  templateUrl: './check-list.template.html',
  styleUrls: ['../../application-details.styles.scss', './check-list.style.scss']
})
export class CheckListComponent implements OnInit {
  checklisttemplates: any
  selectedCheckListTemplate: string
  @ViewChildren(MatExpansionPanel) myPanel!: QueryList<MatExpansionPanel>;
  matIcon = 'keyboard_arrow_down' || 'keyboard_arrow_up';
  @Input() application: LoanApplication;
  @Input()
  menuCode:string;
  @Input() defaultChecklistSectionCode: any;
  
  @Output() selectedChecklistSection = new EventEmitter<any>();
  @Output() closeSidebarchecklistEvent = new EventEmitter<any>();

  checklists: Array<any> = []
  applicationchecklists: Array<ApplicationCheckList>=[]
  applicationchecklistItems:CheckListItem={}
 
  remainTemplates:any=[]
  selectedtemplate:string='';
  //this field will be used to decide which repsonse field to populate in checklist_item.
  userResponseField:Number;
  userName:string
  
  checkListSection:NameValueDto[]
  checkListResponseTypes:NameValueDto[]

  panelOpenState: boolean = false;
  allowAccess:Boolean;
  showTemplateDropdown:boolean=false
  isFormDisabled: Boolean = true;
  menuItemAllowAccess:boolean;

  saveChecklistObject:any={
    "checklist":{},
    "checklistItem":[],
    "userResponseField": 1
  }
  selectedCheckList: any = {
    accountNumber: ''
  }
 applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  // this variable is used to show top edit button along with allowAccess and menuItemAllowAccess 
  isUserAuthorised: boolean;
  opsChecklistDataFromCache: any;
  // this variable is used to show message in the top card, whether data is cached or not
  hasUnsavedChecklistResponse : boolean;

  constructor(
    private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private secureStorageService: SecureStorageService,
    private recentOpenedApplicationsService: RecentOpenedApplicationsService
  ) {
    this.allowAccess = this.applicationService.allowAccess;
   }

  expandPannel(index) {
    this.myPanel.forEach((element, i) => {
      if (i == index) {
        element.expanded = !element.expanded
        element.expandedChange.subscribe((data) => {
          this.matIcon = data ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
        })
      }
    })
  }
  ngOnInit() { 
    this.userName =this.secureStorageService.secureSessionStorage.getItem("logInUserDetails").login
    this.getRefCodes()
    this.fetchCheckLists()
    this.getMenuItemAccess();
    this.isUserAuthorised = true;
  }
  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('checklist_response').subscribe((response: any) => {
      this.checkListResponseTypes = response.checklist_response;
    });
    this.referenceCodeService.getShortRefCodes('checklist_section').subscribe((response: any) => {
      this.checkListSection = response.checklist_section;
    });
  }
  fetchCheckLists() {
    this.applicationService.getCheckListTemplates().subscribe((response: any) => {
      if (response) {
        this.checklists = response.body;
        this.fetchApplicationCheckList()
      }
    });
  }
  changeSelectedCheckList(uid) {
    this.applicationService.getCheckListTemplateItems(uid).subscribe((response: any) => {
      if (response) {
        this.checklisttemplates = response.body;
      }
    });
  }
  enableDisableForm() {
    this.isFormDisabled = false
  }
  fetchApplicationCheckList() {
    this.applicationService.getApplicationCheckLists(this.application.uid).subscribe((response: any) => {
      if (response) {
        this.applicationchecklists = response.body;
        this.remainTemplates=[]
        this.checkTemplateIsExistsInApplicationCheckList()
        if (response.body.length !== 0) {
          this.userResponseField = this.applicationchecklists[0].userResponseField
          // if userResponseField is 0 or empty, user not allowed to respond to checklist.
          if(this.userResponseField == null || this.userResponseField == 0){
            this.isUserAuthorised = false;
          }
          // if the user don't have edit access then we are clearing the cached data, because on click of proceed or return of application
          // if same user have access to that application stage but not access to checklist component then cached data will be prepopulated
          if(!this.allowAccess || !this.menuItemAllowAccess){
            this.recentOpenedApplicationsService.clearChecklistItems(this.application.uid)
          }
          this.filterCheckListsBySectionCode(this.applicationchecklists[0].checklistItem)
          this.selectedCheckListTemplate = response.body[0].checklist.uid
          this.changeSelectedCheckList(response.body[0].checklist.uid)
        }
      } 
    });
  }
  clickAddNew() {
    this.selectedtemplate=this.remainTemplates[0].uid
    this.showTemplateDropdown=true
  }
  checkTemplateIsExistsInApplicationCheckList() {
    if(this.applicationchecklists.length !== 0){
      this.checklists.forEach(element => {
        this.applicationchecklists.forEach(applicationChecklist => {
          if (element.uid !== applicationChecklist.checklist.templateUid) {
            this.remainTemplates.push(element)
          }
        });
      });
    }else{
      this.remainTemplates=this.checklists
    }
   
  }
  //onclick ok function this function will trigger
  checkTemplateValue(selectedtemplate){
    this.isFormDisabled = false
    this.showTemplateDropdown=false
    this.applicationService.getNewCheckListTemplteItems(this.application.uid,selectedtemplate).subscribe((response: any) => {
      if (response) {
      //  this.checklisttemplates = response.body;
      //this.applicationchecklists.push(response.body)
      let newChecklistArray =[]
      newChecklistArray.push( response.body)
      this.applicationchecklists =newChecklistArray
      this.userResponseField = this.applicationchecklists[0].userResponseField
      this.filterCheckListsBySectionCode(response.body.checklistItem)
      } 
    });
  }
  //on clicking 'x' button thi function will trigger
  cancelForm(){
    this.showTemplateDropdown=false
    this.isFormDisabled = true
    this.applicationchecklistItems={}
    // this is to close sidebar card if it is opened when click of cancel in main component
    this.closeSidebarchecklistEvent.emit()
    this.hasUnsavedChecklistResponse = false;
    // on click of cancel we are clearing the cache value and showing the server provided data, hence weare calling clearChecklistItems
    this.recentOpenedApplicationsService.clearChecklistItems(this.application.uid)
    this.fetchApplicationCheckList()
  }
  filterCheckListsBySectionCode(checkListItemsArray){
    // for first time creation of checklist we are not showing launch button and we cannot use checklist assist, because we are matching checklistItem based on uid.
    // Only after save we can use checklist assist. hence we are checking for uid present for checklist or not, if uid is present then we are fetching cached data
    // and we will update the cached values for matching uid. This code need to change further for caching of new added checklist.
    if(checkListItemsArray[0].uid){
      this.hasUnsavedChecklistResponse = false;
      this.opsChecklistDataFromCache = this.recentOpenedApplicationsService.getChecklistItems(this.application.uid, checkListItemsArray)
      // if data present in cachedData then we are pre-populating the data and we are showing the message. Else we are not showing any error message.
      // Hence we are checking for length not equal to zero
      if (this.opsChecklistDataFromCache.length !== 0) {
        this.hasUnsavedChecklistResponse = true;
        let checklistItemsMap = new Map(this.opsChecklistDataFromCache.map(element => [element.uid, element]));
        checkListItemsArray.forEach((ele, index) => {
          if (checklistItemsMap.has(ele.uid)) {
            checkListItemsArray[index] = checklistItemsMap.get(ele.uid);
          }
        });
      }
    }

    this.checkListSection.forEach(element => {
      let arrayName = element.code+'CheckList' 
      arrayName = checkListItemsArray.filter(function (checklist) {
        return checklist.sectionCode == element.code
      });
      this.applicationchecklistItems[element.code+'CheckList']=arrayName
    });

    // on opening this component if the sidebar card is open then on selecting the value in sidebar card should reflect in main component 
    // hence we are checking code of which card is opened and sending selected section to sidebar card
    if(this.defaultChecklistSectionCode && this.defaultChecklistSectionCode.showCard){
      let index = this.defaultChecklistSectionCode.selectedIndex
      this.launchChecklistAssit(this.applicationchecklistItems,index)
    }
  }

  // this method will show particular selected section in sidebar card and j indicates the index of which section we have clicked on launch
  launchChecklistAssit(checklistItemsOfSelectedSection, j){
    let checklistComposite = {
      checkListResponseTypes: this.checkListResponseTypes,
      userResponseField: this.userResponseField,
      selectedOpsChecklistSection: checklistItemsOfSelectedSection,
      checklistSectionReferenceCode: this.checkListSection,
      index: j
    };
  
    this.selectedChecklistSection.emit(checklistComposite);
  }

  // on change of radio button this method is called and this will store value in cache
  saveChecklistItemResponse(event: any,checklistItem: any){
    // in list of values we are updating the response value. Here event.value has the updated latest value from radio button selected
    checklistItem['response'+this.userResponseField] = event.value
    this.recentOpenedApplicationsService.addChecklistItem(checklistItem,this.application.uid)
  }

  saveCheckListResponse(){
    // checking for form is enabled or disabled, save should work only if the form is enabled
    if(!this.isFormDisabled){
      this.mergeAllCheckListItems()
      this.applicationchecklistItems
      this.saveChecklistObject.userResponseField = this.applicationchecklists[0].userResponseField;
      if( this.applicationchecklists.length === 0){
        let uid =this.checklists[0].uid
        this.saveChecklistObject.checklist=this.checklists[0]
        delete this.saveChecklistObject.checklist.uid
        this.saveChecklistObject.checklist["templateUid"]=uid
      }else{
        this.saveChecklistObject.checklist=this.applicationchecklists[0].checklist
      }
      if( this.saveChecklistObject.checklistItem.length != 0 &&  this.saveChecklistObject.checklistItem != undefined){
      this.applicationService.saveCheckListResponse(this.application.uid,this.saveChecklistObject).toPromise().then(
        (_success) => {
          this._snackbar.open("Answers are updated successfully", "Close", {
            duration: 2000,
          });
          this.isFormDisabled = !this.isFormDisabled
          this.applicationchecklistItems={}
          // if sidebar card is opened on click of save we are clearing the cached and to close the sidebar card we are calling this.
          this.closeSidebarchecklistEvent.emit()
          this.hasUnsavedChecklistResponse = false;
          // after save we need to clear cached data present, hence we are calling clearChecklistItems
          this.recentOpenedApplicationsService.clearChecklistItems(this.application.uid)
          this.fetchApplicationCheckList()
        }, (failure) => {
          let errormesg =[]
          errormesg.push("Answers update failed")
          errormesg.push(failure)
          this.applicationService.displayErrorMessages(errormesg);
        })
      }else{
        this._snackbar.open("Checklist items are empty or not filled", "Close", {
          duration: 2000,
        });
        
      }
    }
  }
  mergeAllCheckListItems(){
    this.saveChecklistObject.checklistItem=[]
    for (var propName in this.applicationchecklistItems) {
      if (this.applicationchecklistItems.hasOwnProperty(propName)) {
          this.saveChecklistObject.checklistItem=this.saveChecklistObject.checklistItem.concat(this.applicationchecklistItems[propName])
      }
  }
  }
  //this function is for repeating previous responses 
  array(n: number): any[] {
    return Array(n);
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}
