import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IgFormService } from '@ig-core/form/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { IgFileService } from '@ig-core/form/igFile.service';

@Component({
  selector: 'app-multiple-file-upload',
  templateUrl: './multiple-file-upload.component.html',
  styleUrls: ['../single-file-upload/single-file-upload.component.css']
})
export class MultipleFileUploadComponent implements OnInit, OnDestroy {

  public multiFileForm: FormGroup;
  public multiFile: Array<any> = [];
  public multiFileData: Array<File> = [];
  public responseData: Array<any> = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService,
    private igFileService: IgFileService) {
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy(): void {
  }

  buildForm() {
    this.multiFileForm = this.formBuilder.group({
      multiFile: [ [], ]
    });
  }

  onMultiFileChanged(event) {
    this.multiFileData = [];
    const fileData = event.target.files;
    for ( const data of fileData) {
      this.multiFileData.push(data);
    }
  }

  deleteFile(data) {
    const index = this.multiFileData.indexOf(data);
    this.multiFileData.splice(index, 1);
  }

  uploadMultiFile() {
    this.igFileService.uploadMultipleFIle(this.multiFileData).pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => this.onSubmitSuccess(response),
        (response) => this.onSubmitError(response));
  }

  private onSubmitSuccess(response) {
    this.snackBar.open(`File Uploaded successfully`, 'Close');
    this.responseData = response.body;
    this.multiFileData = [];
    this.ngOnInit();
  }

  private onSubmitError(response) {
    this.snackBar.open(`Unable to Upload files...Please try again`, 'Close');
  }

}
