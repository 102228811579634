import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { ApplicationsResolverService } from './applications-resolver.service';
import { ApplicationsComponent } from './applications.component';
import { ApplicationDetailsComponent } from './application-details/application-details.component';
import { REPORT_ROUTES } from '@app/reports/reports.module';
import { ApplicationRouteGuardService } from './application-route-guard.service';
import { Services } from './application-details/applicationDocument/xdata-service/xdata-services.module';

const routes: Routes = [
  {
    path: 'applications',
    canActivate: [AuthenticatedUserService, ApplicationRouteGuardService],
    children: [
      {
        path: '',
        component: ApplicationsComponent
      },
      {
        path: ':applicationUid',
        component: ApplicationDetailsComponent,
        resolve: {
          application: ApplicationsResolverService
        },
        children: [REPORT_ROUTES,Services]
      }
    ]
  }
];
  
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApplicationsRoutingModule { }