import {LoanOdAccounts} from './loan-od-accounts.model';
import {FeeDto} from './loan-od-accounts-fee-dto.model' ;
import {Money} from './money';

export class LoanOdComputePayOffAmountDto {
    constructor(
        public  valueDate?: any,
        public  accountId?: string,
        public  payOffAmount?: Money,
        public  payOffAmountWithDue?: Money,
        public  preclosureFee?: Money,
        public  recoverableInterest?: Money,
        public  principal?: Money,
        public  normalInterest?: Money,
        public  penalInterest?: Money,
        public  bookedNotDueNormalInterest?: Money,
        public  bookedNotDuePenalInterest?: Money,
        public  totalFee?: Money,
    ) {}
}
