import { Component, Input, OnInit } from "@angular/core";
import { BankAccountVerificationResponseComposite } from "@app/applications/application-details/business/businessBankAccount/business-bank-accounts.model";

@Component({
  selector: "bank-account-verification",
  templateUrl: "bank-account-verification.template.html",
  styleUrls: [
    "../../../application-details.styles.scss",
    "../xdata-service.style.scss",
  ],
})
export class BankAccountVerificationComponent implements OnInit {
  @Input()
  bankAccountVerificationResponse: BankAccountVerificationResponseComposite;

  @Input()
  // this is to determine from which component this common component is called from, right now possible values are "evidence" and "verification"
  // "evidence" will be from bankAccount component and business bankAccount component which will show server provider data used for verification, "verification" is from pennyDropComponent which will show both internal and service provider data for user to verify.
  caller: any;

  constructor( ) { }

  ngOnInit() {

  }

}