import { Component, OnChanges, Input, SimpleChanges, OnInit } from '@angular/core';
import { ApplicantDocumentComposite, Documents } from './applicant-document.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { LoanApplication, Person, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ValidationService } from '../../../application-validators/validation.service';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { SecureStorageService} from '../../../../../../projects/ig-core/src/lib/authentication/secureStorage.service';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { File, Folder, FolderFilesComposite } from '@app/applications/application-details/loanInformation/loan-folder-files/loan-folder-files.model';
import * as fileSaver from 'file-saver';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'eng-applicant-document',
  templateUrl: 'applicant-document.template.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class ApplicantDocumentComponent implements OnInit, OnChanges {

  @Input()
  application: LoanApplication;

  @Input()
  applicant: Person;

  @Input()
  menuCode: string;

  applicantDocuments: ApplicantDocumentComposite[];
  applicantDocumentsForm: FormGroup;
  selectedDocument: ApplicantDocumentComposite;
  applicantDocumentTypes: NameValueDto[];


  allFilesInFolder: FolderFilesComposite;
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  isFormDisabled: boolean;
  showForm: boolean;
  allowAccess: boolean;
  menuItemAllowAccess: boolean;

  constructor(private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private fileService: IgFileService,
    private _snackbar: MatSnackBar,
    private secureStorageService: SecureStorageService,
    private customDatepipe: DateFormatPipe,
    private confirmationDialogService: ConfirmationDialogService,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private fileUploadService: FileUploadService) {
    this.applicantDocumentsForm = this.formBuilder.group({
      documentType: '',
      documentName: '',
      verifiedFlag: ''
    });
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.applyValidatorsToApplicantDocuments()
    this.getRefCodes();
    this.getMenuItemAccess();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.applicant) {
      this.isFormDisabled = true;
      this.fetchApplicantDocuments();
    }
  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('person_misc_doc').subscribe((response: any) => {
      this.applicantDocumentTypes = response.person_misc_doc;
    });
  }

  fetchApplicantDocuments() {
    this.showForm = false;
    this.applicationService.getApplicantDocuments(this.application.uid, this.applicant.uid)
      .subscribe((response: any) => {
        if (response) {
          this.applicantDocuments = response.body;
          this.applicantDocuments.forEach((element, index) => {
            this.applicantDocuments[index].documents["deleteBtnDisabled"] = true;
          });
          this.showForm = (this.applicantDocuments && this.applicantDocuments.length > 0);
          this.buildApplicantDocumentForm();
        }
      });
  }

  changeSelectedDocuments(applicantDocument?: ApplicantDocumentComposite, index?: number, from?: string) {
    if (!applicantDocument) {
      applicantDocument = new ApplicantDocumentComposite();
    }
    this.showForm = true;
    this.applicantDocuments.forEach((element, i) => {
      this.applicantDocuments[i].documents.deleteBtnDisabled = true;
    });
    if (from != "new") {
      this.applicantDocuments[index].documents.deleteBtnDisabled = false;
    }
    this.buildApplicantDocumentForm(applicantDocument);
  }

  buildApplicantDocumentForm(applicantDocuments?: ApplicantDocumentComposite) {
    if (this.showForm) {
      if (!applicantDocuments) {
        applicantDocuments = this.applicantDocuments[0];
        this.applicantDocuments[0].documents.deleteBtnDisabled = false;
      }
      // if (!applicantDocuments) applicantDocuments = this.applicantDocuments[0];
      this.applicantDocumentsForm.patchValue({
        documentType: applicantDocuments.documents.documentType,
        documentName: applicantDocuments.documents.documentName,
        verifiedFlag: applicantDocuments.documents.verifiedFlag
      });
      this.selectedDocument = applicantDocuments;
      this.getSelectedFolderFiles(this.selectedDocument.documents.fileFolderUid)
    }
  }

  enableDisableForm() {
    this.isFormDisabled = false;
  }

  cancelForm() {
    this.applicantDocuments.forEach((element, i) => {
      this.applicantDocuments[i].documents.deleteBtnDisabled = true;
    });
    if (this.applicantDocuments && this.applicantDocuments.length > 0) {
      this.applicantDocuments[0].documents.deleteBtnDisabled = false;
      this.buildApplicantDocumentForm(this.applicantDocuments[0]);
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
  }
  //this function will apply validators to form group
  applyValidatorsToApplicantDocuments() {
    this.validationService.applyValidationRules(this.applicantDocumentsForm, "ApplicantDocuments").then((controlValidators) => {
      this.fetchApplicantDocuments();
    }).catch(() => {
    })
  }


  //this function open up the image viewer dialog box
  openImagePreviewDialog() {
    this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  //this function is called onclick of '+' button,
  //after clicking the new empty form will open to enter details
  addNewDocument() {
    this.showForm = true;
    let newDocument = new ApplicantDocumentComposite();
    newDocument.documents = new Documents()
    this.buildApplicantDocumentForm(newDocument);
    this.isFormDisabled = false;
    this.changeSelectedDocuments(newDocument, undefined, "new");
  }

  //this function is called onclick of save button
  saveDocument() {
    //this will mark touched all controls in formgroup
    this.validationService.markFormGroupTouched(this.applicantDocumentsForm)
    //check the document form is valid or not,if valid then only save will happen 
    if (this.applicantDocumentsForm.valid) {
      let todaydate = new Date();
      let savingDocument = this.selectedDocument.documents
      if (!savingDocument.uid) {
        savingDocument.linkToType = 'entity';
        savingDocument.linkToUid = this.applicant.uid;
        savingDocument.contextType = 'application';
        savingDocument.contextUid = this.application.uid;
      }

      let documentFormFields = this.applicantDocumentsForm.value;
      savingDocument.verifiedBy = this.secureStorageService.secureSessionStorage.getItem("logInUserDetails").firstName;
      //savingDocument.verificationDate = this.customDatepipe.transform(todaydate, 'ISODATE');
      savingDocument.documentType = documentFormFields.documentType;
      savingDocument.documentName = documentFormFields.documentName;
      savingDocument.verifiedFlag = documentFormFields.verifiedFlag;
      this.applicationService.saveApplicantDocuments(this.application.uid,
        this.applicant.uid, savingDocument).subscribe((response) => {
          this.isFormDisabled = true;
          this._snackbar.open("Document updated successfully", "Close", {
            duration: 2000,
          });
          this.isFormDisabled = true;
          this.fetchApplicantDocuments();
        }, (failure) => {
          console.log(failure);
          let errormesg = []
          errormesg.push("Document update failed, ")
          errormesg.push(failure)
          this.applicationService.displayErrorMessages(errormesg);
        }
        );
    } else {
      let errormesg = []
      errormesg.push("Form is not valid")
      this.applicationService.displayErrorMessages(errormesg);
    }
  }

  //this function open up the image upload dialog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if (this.selectedDocument.documents.fileFolderUid) {
      this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.selectedDocument.documents.fileFolderUid, "applicationUid": this.application.uid, "files": this.allFilesInFolder.fileInfo })
      this.fileUploadService.confirmed().subscribe(data => {
        this.getSelectedFolderFiles(data.folderUid)
      })

    } else {
      this._snackbar.open("Please add a folder to upload files", "Close", {
        duration: 5000,
      });
    }
  }

  getSelectedFolderFiles(folderUid) {
    if (folderUid) {
      this.applicationService.getAllFilesInApplicationFolder(this.application.uid, folderUid).subscribe((response: any) => {
        if (response) {
          this.allFilesInFolder = response.body;
          this.allFilesInFolder.fileInfo.forEach(element => {
            if (element.fileSize) {
              element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
            }
          });
        }
      });
    }
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      fileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }


  //this function will be called on click of delete icon on Document
  deleteDocument(selectedDocument) {
    this.applicationService.documentDelete(this.application.uid, selectedDocument.documents.uid).subscribe((response) => {
      if(response.body.status == "success") {
      this._snackbar.open("Document deleted successfully", "Close", {
        duration: 2000,
      });
      this.fetchApplicantDocuments();
    } else {
      let errormesg = []
      errormesg.push("Document deleted failed")
      errormesg.push(response.body.message);
      this.applicationService.displayErrorMessages(errormesg);
    }}, (failure) => {
      let errormesg = []
      errormesg.push("Document deleted failed")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this document?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteDocument(this.selectedDocument)
      }
    })
  }

  //this function will be called on click of delete icon on file list
deleteFile(selectedFile){
  this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
    if(response.body.status == "success") {
    this._snackbar.open("File deleted successfully", "Close", {
      duration: 2000,
    });
    this.getSelectedFolderFiles(this.selectedDocument.documents.fileFolderUid);
  } else {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(response.body.message)
    this.applicationService.displayErrorMessages(errormesg);
  }}, (failure) => {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(failure)
    this.applicationService.displayErrorMessages(errormesg);
  });
}

  openFileDeleteConfirmation(selectedFile){
      this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this File?"})
      this.confirmationDialogService.confirmed().subscribe(data=>{
        if(data){
        this.deleteFile(selectedFile)
        }
      })
    
  }
}