import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JhiLanguageService } from 'ng-jhipster';
import { DateConverterService } from '../shared/date/date-converter.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdDisbursementComposite } from './loan-od-disbursement-composite.model';

@Component({
    selector: 'jhi-loan-od-accounts-disburse',
    templateUrl: './loan-od-accounts-disburse.component.html'
})
export class LoanOdAccountsDisburseComponent implements OnInit, OnDestroy {
    loanOdAccounts: LoanOdAccounts;
    compositeDto: LoanOdDisbursementComposite;
    proceedIsTrue: boolean;
    proceedToResult: boolean;
    instruments: any[] = [];
    ifscCodes: any[];

    constructor(private route: ActivatedRoute,
        private router: Router,
        private dateConverter: DateConverterService,
        private loanODAccountsService: LoanOdAccountsService,
        private jhiLanguageService: JhiLanguageService,
    ) {
        this.proceedIsTrue = false;
        this.proceedToResult = false;
    }
    ngOnInit() {
        this.compositeDto = this.route.snapshot.data['compositeDto'];
        this.loanOdAccounts = this.route.snapshot.data['loanOdAccounts'];
        this.loanODAccountsService.getInstruments().subscribe(
            (res) => {
                this.setInstruments(res);
                this.compositeDto.disbursement.instrument = this.instruments[0].code;
            });
        this.loanODAccountsService.getIfscCodes().subscribe((res) => {
            this.ifscCodes = res;
        })
    }

    private setInstruments(data) {
        this.instruments = data;
    }

    ngOnDestroy() {
    }

    clear() {
        this.loanOdAccounts = new LoanOdAccounts();
        this.router.navigate(['/loan-od-accounts']);
    }

    proceed(compositeDto) {
        this.compositeDto = compositeDto;
        this.proceedIsTrue = true;
    }

    back() {
        this.compositeDto.fees = [];
        for (let i = 1; i < 9; i++ ) {
            this.compositeDto['fee' + i] = null;
        }
        this.proceedIsTrue = false;
    }

    executeError() {
        this.compositeDto.fees = [];
        for (let i = 1; i < 9; i++ ) {
            this.compositeDto['fee' + i] = null;
        }
        this.proceedIsTrue = false;
    }

    executeSuccess(result) {
        this.compositeDto = result;
        this.proceedToResult = true;
    }
}
