import {Money} from './money';

export class LoanOdWriteOffDtoModel {
    constructor(

        public id?: number,
        public version?: number,
        public accountId?: string,
        public transactionId?: string,
        public writeOffTransactionType?: string,
        public valueDate?: any,
        public transactionDate?: any,
        public amount?: Money,
        public balance?: Money,
        public principal?: Money,
        public normalInterest?: Money,
        public penalInterest?: Money,
        public bookedNotDueNormalInterest?: Money,
        public bookedNotDuePenalInterest?: Money,
        public fee?: Money,
        public recoverableInterest?: Money,
        public userId?: string,
        public instrument?: string,
        public reference?: string,
        public remarks?: string,
        public transactionLotId?: string,
        public writtenOffPrincipal?: Money,
        public waivedNormalInterest?: Money,
        public waivedPenalInterest?: Money,
        public waivedBookedNotDueNormalInterest?: Money,
        public waivedBookedNotDuePenalInterest?: Money,
        public waivedFee?: Money,
        public waivedRecoverableInterest?: Money,
        public waivedAmount?: Money,

        public duePrincipal?: Money,
        public dueNormalInterest?: Money,
        public duePenalInterest?: Money,
        public dueBookedNotDueNormalInterest?: Money,
        public dueBookedNotDuePenalInterest?: Money,
        public dueFee?: Money,
        public dueAmount?: Money

    ) {}
}
