
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportLink } from '@app/reports/reports.model';
import { ReportService } from '@app/reports/reports.service';
import { Chart } from 'chart.js';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'data-export',
  templateUrl: './data-export.template.html',
  styleUrls: ['./data-export.style.scss', '../../applications/application-details/application-details.styles.scss']
})

export class DataExportComponent implements OnInit, OnDestroy {

  category: any;
  reportLinks: ReportLink[];
  contextUid: string;
    
  constructor(
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit() {

  }


  ngOnDestroy(): void {
  }

}