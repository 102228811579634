import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSidenav, MatSnackBar, MatSort, DateAdapter } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ListSource } from '../../../../projects/ig-core/src/lib/list/list-source';
import { PagingAndSortParams } from '../../../../projects/ig-core/src/lib/list/paging-and-sort-params';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { takeUntil, delay } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ReferenceCodeService } from '../reference-code/reference-code.service';
import { DeviationApprovalSource } from './deviation-approval.data.source';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { DeviationApprovalSearchCriteria } from './deviation-approval.model';
import { DeviationApprovalService } from './deviation-approval.service';

@Component({
  selector: 'eng-deviation-approval',
  templateUrl: './deviation-approval.template.html',
  styleUrls: ['./deviation-approval.style.scss'],
})

export class DeviationApproval implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  pagingAndSortParams: PagingAndSortParams;

  dataSource: DeviationApprovalSource;
  listSource: ListSource;

  deviationApprovalSearchForm: FormGroup
  deviationApprovalSearchCriteria: DeviationApprovalSearchCriteria;

  flexMediaWatcher: Subscription;
  currentScreenWidth: string = '';
  displayedColumns: string[] = ['category', 'deviationType', 'approvalcode', 'allowDeny', 'action'];

  deviationItems: NameValueDto[];
  showOptions: string[] = ['ALL','GRANTED','REVOKED'];

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private router: Router,
    private media: MediaObserver,
    private deviationApprovalService: DeviationApprovalService,
    private referenceCodeService: ReferenceCodeService,
    private dateAdapter: DateAdapter<Date>) {
    this.flexMediaWatcher = media.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias !== this.currentScreenWidth) {
        this.currentScreenWidth = change.mqAlias;
        this.setTableColumns();
      }
    });
    this.dateAdapter.setLocale('en-IN');
  }

  setTableColumns() {
    this.displayedColumns = ['category', 'deviationType', 'approvalcode', 'allowDeny', 'action'];
    if (this.currentScreenWidth === 'xs') {
      this.displayedColumns = ['category', 'deviationType', 'action'];
    }
  };

  ngOnInit() {
    this.getReferenceCodes()
    //Instantiate empty search form
    this.deviationApprovalSearchForm = this.formBuilder.group({
      category: null,
      role: null,
      show: 'GRANTED'
    });

    //Instantiate dataSource for subscribing changes in list data
    this.dataSource = new DeviationApprovalSource(this.deviationApprovalService);

    //Instantiate listSource for subscribing changes in search criteria
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);

    //Subscribe to changes in search criteria
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        this.getDeviationApprovals('');
      });
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  loadDeviationApprovalsPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  ngAfterViewInit(): void {
    //Subscribe to changes in pagination and sort criteria
    this.listSource.subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this))).subscribe(
        () => this.loadDeviationApprovalsPageWithParams());
  }

  getDeviationApprovals(deviationSearch) {
    if(deviationSearch === 'onClickDeviationSearch'){
      this.pagingAndSortParams.pageIndex = 0;
    }
    this.generateDeviationApprovalSearchCriteria();
    //Sorting on uid in ascending order by default 
    let sortCriteria = 'uid' + ',' + 'asc';
    //Subscribe to changes in list data
    this.dataSource.getDeviationApprovals(sortCriteria, this.pagingAndSortParams.pageIndex,
      this.pagingAndSortParams.pageSize, this.deviationApprovalSearchCriteria);
  }

  generateDeviationApprovalSearchCriteria() {
    this.deviationApprovalSearchCriteria = {
      user: this.deviationApprovalSearchForm.value.role,
      productType: this.deviationApprovalSearchForm.value.category,
      grant: this.deviationApprovalSearchForm.value.show
    }
    // this.getDeviationApprovals();
  }

  getReferenceCodes() {
    this.referenceCodeService.getShortRefCodes('deviation_item').subscribe((response: any) => {
      this.deviationItems = response.deviation_item;
    });
  }

  redirectEditDeviation(deviationApproval, mode, title) {
    if (mode === 'update') {
      this.router.navigate(['admin/deviation-approval/update/' + deviationApproval.uid], { queryParams: { data: JSON.stringify({ "mode": mode, "deviationApproval": deviationApproval, "title": title }) } })
    } else if (mode === 'create') {
      this.router.navigate(['admin/deviation-approval/create'], { queryParams: { data: JSON.stringify({ "mode": mode, "deviationApproval": deviationApproval, "title": title }) } })
    } else {
      this.router.navigate(['admin/deviation-approval/view/' + deviationApproval.uid], { queryParams: { data: JSON.stringify({ "mode": mode, "deviationApproval": deviationApproval, "title": title }) } })
    }
  }
}