import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, DateUtils, EventManager, JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { ApplicationDateService } from '../shared/date/application-date.service';
import { DateConverterService } from '../shared/date/date-converter.service';
import { Accountcode } from '../internal/accountcode.model';
import { NameValueDto } from './name-value-dto';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { LoanOdDisbursementComposite } from './loan-od-disbursement-composite.model';
import moment from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'jhi-loan-od-accounts-disburse-entry',
    templateUrl: './loan-od-accounts-disburse-entry.component.html'
})
export class LoanOdAccountsDisburseEntryComponent implements OnInit, OnDestroy {
    @Input() instruments: NameValueDto;
    @Input() loanOdAccounts: LoanOdAccounts;
    @Input() compositeDto: LoanOdDisbursementComposite;
    accountcodes: Accountcode;
    feeAccountcodes: Accountcode;
    @Output() proceedToExecute: EventEmitter<LoanOdDisbursementComposite> = new EventEmitter<LoanOdDisbursementComposite>();
    dateSubscription: Subscription;
    currentWorkingDate: any;
    @Input() ifscCodes: any;
    feeInstrument = false;
    selectedBankAccount: any = null;


    constructor(private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService,
        private loanODAccountsService: LoanOdAccountsService,
        private eventManager: EventManager,
        private route: ActivatedRoute,
        private router: Router,
        private dateConverter: DateConverterService,
        private dateUtils: DateUtils,
        private applicationDateService: ApplicationDateService,
        private dateservice: DateConverterService,
        private calender: NgbCalendar
    ) {
    }
    ngOnInit() {
        this.displayAccountCodes(this.compositeDto.disbursement.instrument);
        this.displayFeeAccountCodes(this.compositeDto.disbursement.feeInstrument);
        this.dateSubscription = this.applicationDateService.getDate().subscribe(
            (date) => {
                this.currentWorkingDate = date;
            }
        );
        this.compositeDto.disbursement.valueDate = this.compositeDto.disbursement.valueDate ? new NgbDate(parseInt(this.compositeDto.disbursement.valueDate.split('-')[0]), parseInt(this.compositeDto.disbursement.valueDate.split('-')[1]), parseInt(this.compositeDto.disbursement.valueDate.split('-')[2])) : null;
        this.compositeDto.firstRepaymentDate = this.compositeDto.firstRepaymentDate ? new NgbDate(parseInt(this.compositeDto.firstRepaymentDate.split('-')[0]), parseInt(this.compositeDto.firstRepaymentDate.split('-')[1]), parseInt(this.compositeDto.firstRepaymentDate.split('-')[2])) : null;
    }
    ngOnChanges(changes: any) {
      // if magnitude of moratoriumPeriod is null , then set default value of 0;
      if(changes.compositeDto.currentValue.disbursement.moratoriumPeriod.magnitude == null) {
        this.compositeDto.disbursement.moratoriumPeriod.magnitude=0;
      }
       // if magnitude of unitTenure is undefined , then set default value to 0;
       if(changes.compositeDto.currentValue.userTenure.magnitude == null) {
        // skip minfloat error
        this.compositeDto.userTenure.magnitude=0;
      }
      if(changes.compositeDto.currentValue.userInstallment.magnitude === 0){
        this.compositeDto.userInstallment.magnitude=0;
      }
    }
    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
    }
    fnSelectBank(obj) {
        if (obj) {
            this.compositeDto.partyInfo = obj.accountHolderName;
            this.compositeDto.bankAccountNumber = obj.accountNumber;
            this.compositeDto.ifscCode = obj.ifscCode;
            this.compositeDto.bankName = obj.bankName;
            this.compositeDto.bankBranchDetails = obj.branchName;
        }
    }
    fnSelcetIFSC(code) {
        for (let index = 0; index < this.ifscCodes.length; index++) {
            if (code == this.ifscCodes[index].code) {
                this.compositeDto.bankName = this.ifscCodes[index].reference;
                this.compositeDto.bankBranchDetails = this.ifscCodes[index].parentCode;
            }
        }
    }
    clear() {
        this.router.navigate(['/loan-od-accounts']);
    }

    private setAccountCodes(data) {
        this.compositeDto.disbursement.reference = null;
        this.accountcodes = data;
        this.accountcodes = this.accountcodes.filter((item) => item.isBankAccount === true);
    }
    private setFeeAccountCodes(data) {
        this.feeAccountcodes = data;
        this.feeAccountcodes = this.feeAccountcodes.filter((item) => item.isBankAccount === true);
    }

    displayAccountCodes(instrument) {
        if (instrument === 'CHEQUE_OR_DD' || instrument === 'OTHER' || instrument === 'NEFT' || instrument === 'RTGS' || instrument === 'IMPS'
            || instrument === 'UPI' || instrument === 'AUTOPAY' || instrument === 'DEBIT_CARD' || instrument === 'CREDIT_CARD') {
            this.loanODAccountsService.getAccountCodes(instrument).subscribe(
                (res) => {
                    this.setAccountCodes(res);
                });
        } else {
            this.accountcodes = undefined;
        }
    }
    displayFeeAccountCodes(feeInstrument) {
        if (feeInstrument === 'CHEQUE_OR_DD' || feeInstrument === 'OTHER' || feeInstrument === 'NEFT' || feeInstrument === 'RTGS' || feeInstrument === 'IMPS'
            || feeInstrument === 'UPI' || feeInstrument === 'AUTOPAY' || feeInstrument === 'DEBIT_CARD' || feeInstrument === 'CREDIT_CARD') {
            this.loanODAccountsService.getAccountCodes(feeInstrument).subscribe(
                (res) => {
                    this.setFeeAccountCodes(res);
                });
        } else {
            this.feeAccountcodes = undefined;
        }
    }

    proceed() {
        let flag = true;
        var IFSC_REGEX = new RegExp("^[A-Z]{4}0[A-Z0-9]{6}$");
        
        const disbursementDate = moment(new Date(this.compositeDto.disbursement.valueDate.year,this.compositeDto.disbursement.valueDate.month - 1, this.compositeDto.disbursement.valueDate.day)).valueOf();
        const currentWorkingDate = moment(this.currentWorkingDate).valueOf();
        const lastArchiveDate = moment(this.compositeDto.lastArchiveDate).valueOf();
        if(this.compositeDto.ifscCode && !IFSC_REGEX.test(this.compositeDto.ifscCode)){
            flag = false;
            this.alertService.error('Please enter a valid IFSC code');
        }
        if (disbursementDate < lastArchiveDate) {
            flag = false;
            const dateString = moment(this.compositeDto.lastArchiveDate).format('DD-MM-YYYY');
            this.alertService.error('Disbursement cannot be done before Last Archive Date: ' + dateString);
        } else if (currentWorkingDate < disbursementDate) {
            flag = false;
            const dateString = moment(this.currentWorkingDate).format('DD-MM-YYYY');
            this.alertService.error('Disbursement cannot be done after Current Working Date:' + dateString);
        } else if (this.compositeDto.firstRepaymentDate) {
            const firstRepaymentDate = moment(new Date(this.compositeDto.firstRepaymentDate.year, this.compositeDto.firstRepaymentDate.month - 1,this.compositeDto.firstRepaymentDate.day)).valueOf();
            const minFirstRepaymentDate = moment(this.loanOdAccounts.minFirstRepaymentDate).valueOf();
            const maxFirstRepaymentDate = moment(this.loanOdAccounts.maxFirstRepaymentDate).valueOf();
            if (firstRepaymentDate < minFirstRepaymentDate || firstRepaymentDate > maxFirstRepaymentDate) {
                flag = false;
                const minDateString = moment(this.compositeDto.minFirstRepaymentDate).format('DD-MM-YYYY');
                const maxDateString = moment(this.compositeDto.maxFirstRepaymentDate).format("DD-MM-YYYY");
                this.alertService.error('FirstRepaymentDate should be between ' + minDateString + ' and ' + maxDateString);
            }
        }

        if (flag) {
            this.loanODAccountsService.disbursementExecute(this.compositeDto).subscribe(
                (res) => {
                    this.proceedToExecute.emit(res);
                },
                (res) => this.onSaveError(res)
            );
        }
    }


    private onSaveError(error) {
        try {
            const errorString = error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
    }

    private onError(error) {
        //added timeout because the error message is too long and it will dissapear in 5seconds 
        //hence added timeout of 10 seconds 
        this.alertService.addAlert({
            timeout: 10000,
            type: 'danger',
            msg: error
        }, []);
    }

    searchIfscCodes() {
        this.loanODAccountsService.getBankDetailsFromIfscCode(this.compositeDto.ifscCode).subscribe((res) => {
            this.compositeDto.bankName = res.parentClassifier;
            this.compositeDto.bankBranchDetails = res.parentCode;
        }, (res) => {
            this.compositeDto.bankName = null;
            this.compositeDto.bankBranchDetails = null;
            this.alertService.error('No Details Found');
        })
    }
}