import { Component, Input, OnInit } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ApplicationService } from '@app/applications/application.service';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { FamilyFinance } from '../financials.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ValidationService } from '../../../application-validators/validation.service'
@Component({
  selector: 'eng-family-income',
  templateUrl: 'family-income.template.html',
  styleUrls: ['../../application-details.styles.scss', 'family-income.component.css']
})
export class FamilyIncomeComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
    menuCode:string;

  familyIncomeList: FamilyFinance[];
  familyIncomeTypes: NameValueDto[];
  familyMembers: NameValueDto[];
  familyIncomeForm: FormGroup;
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  isFormDisbled: Boolean = true;
  allowAccess:Boolean; 
  cfaFinalized:boolean;
menuItemAllowAccess:boolean;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,
    private validationService: ValidationService) {
      this.allowAccess = this.applicationService.allowAccess; 
  }

  ngOnInit() {
    this.familyIncomeForm = this.formBuilder.group({
      incomeList: this.formBuilder.array([]),
      familyIncomeSummaryForm: this.formBuilder.group({
        averageFamilyIncome: null
      })
    })
    this.getCfaStatus()
    this.getRefCodes();
    this.getFamilyIncomeList();
    this.getMenuItemAccess();

  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('family_income').subscribe((response: any) => {
      this.familyIncomeTypes = response.family_income;
    });
    this.referenceCodeService.getShortRefCodes('family_member').subscribe((response: any) => {
      this.familyMembers = response.family_member;
    });
  }

  getFamilyIncomeList() {
    this.applicationService.getFamilyIncome(this.application.uid).subscribe((response) => {
      this.familyIncomeList = response.body;
      this.buildFormArrayWithData();
    })
  }
  
  buildFormArrayWithData(){
    const familyIncomeFormArray = this.familyIncomeForm.controls.incomeList as FormArray;
    this.familyIncomeList.forEach(familyIncome => {
      const familyIncomeFormGroup = new FormGroup({
        familyMember: new FormControl(familyIncome.familyMember),
        incomeSource: new FormControl(familyIncome.incomeSource),
        amount: new FormControl(familyIncome.pmAmount),
        description: new FormControl(familyIncome.description)
      });
      this.applyValidatorsToFamilyIncome(familyIncomeFormGroup)
      familyIncomeFormArray.push(familyIncomeFormGroup);
    });
   this.computeSummary()
    this.familyIncomeForm.disable();
  }
  async addNew() {
    this.isFormDisbled = false;
    this.familyIncomeForm.enable();
    this.disableFamilyMemberAndIncomeSource();
    const familyIncomeFormArray = this.familyIncomeForm.controls.incomeList as FormArray;
    const newFamilyIncome = new FormGroup({
      familyMember: new FormControl(''),
      incomeSource: new FormControl(''),
      amount: new FormControl(''),
      description: new FormControl('')
    });
    this.applyValidatorsToFamilyIncome(newFamilyIncome)
    familyIncomeFormArray.push(newFamilyIncome);
  }

  enableDisableForm() {
    this.familyIncomeForm.enable();
    this.disableFamilyMemberAndIncomeSource();
    this.isFormDisbled = false;
  }

  cancelForm() {
    this.familyIncomeForm.get('incomeList')['controls'] =[]
    this.buildFormArrayWithData();
    this.isFormDisbled = true;

  }

  disableFamilyMemberAndIncomeSource() {
    let expensesFormArray = this.familyIncomeForm.get('incomeList')['controls'];
    for(let i=0; i<this.familyIncomeList.length; i++) {
      expensesFormArray[i].controls.familyMember.disable();
      expensesFormArray[i].controls.incomeSource.disable();
    }
  }

  saveFamilyIncome() {
    this.validationService.markFormGroupTouched(this.familyIncomeForm)
    this.familyIncomeForm.updateValueAndValidity();
    if(!this.isFormDisbled && this.familyIncomeForm.valid) {
      let familyIncomeFormArray = this.familyIncomeForm.get('incomeList')['controls'];
      familyIncomeFormArray.forEach((familyIncomeFormGroup, index) => {
        if(index === this.familyIncomeList.length) {
          let newFamilyIncome = new FamilyFinance();
          newFamilyIncome.contextType = 'application';
          newFamilyIncome.contextUid = this.application.uid;
          this.familyIncomeList.push(newFamilyIncome);
        }
        this.familyIncomeList[index].familyMember = familyIncomeFormGroup.controls.familyMember.value;
        this.familyIncomeList[index].incomeSource = familyIncomeFormGroup.controls.incomeSource.value;
        this.familyIncomeList[index].pmAmount = familyIncomeFormGroup.controls.amount.value;
        this.familyIncomeList[index].description = familyIncomeFormGroup.controls.description.value;
      });
      this.applicationService.saveFamilyIncomes(this.application.uid,
            this.familyIncomeList).toPromise().then(
          (_success) => {
            this._snackbar.open("Family income updated successfully", "Close", {
              duration: 2000,
            });
            this.familyIncomeForm.get('incomeList')['controls'] =[]
            this.getFamilyIncomeList();
          },(failure) => {
            let errormesg =[]
            errormesg.push("Failed to update family income")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
          console.log(failure);
        }
      );
    }
  }
    //this function will apply validators to form group
    applyValidatorsToFamilyIncome(group) {
      this.validationService.applyValidationRules(group,"FamilyIncome")
          .then((controlValidators) => {
        //this.buildFormArrayWithData();
      }).catch(() => {});
    }

    //this function is called onclick of "compute summary" button
  //this function will calculate sum of all amounts
  computeSummary() {
    let amountArray = this.familyIncomeForm.controls.incomeList.value
    let sumOfAmount = amountArray
      .filter((incomeObject) => incomeObject.amount)
      .reduce((sum: number, incomeObject: any) => sum + parseInt(incomeObject.amount), 0);
    this.familyIncomeForm.get("familyIncomeSummaryForm")['controls'].averageFamilyIncome.patchValue(sumOfAmount);

  }

  //this function will call a api to get CFA Finalized or not status
  getCfaStatus(){
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response =>{
      this.cfaFinalized=response.body.cfaFinalized
      if(this.cfaFinalized){
        this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close" ,{
          duration:5000,
        });
      }
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

}