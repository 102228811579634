import { ReportLink } from "@app/reports/reports.model";
import { CheckListItem } from "./application-details/applicationDocument/application-document.model";

export class LoanApplicationComposite {
    constructor (
        public applicationDTO ?: LoanApplication,
        public applicationStatusInfo ?: any,
        public personDTO ?: Person[],
        public businessEntityDTO ?: any
    ) {}
}

export class LoanApplication {
    constructor (
        public uid ?: string,
        public applicationNo ?: string,
        public applicationType ?: string,
        public productTypeReq ?: string,
        public productCodeReq ?: string,
        public productName ?: string, // Maps to product_name_req in db. Model field name is misleading, should have been 'productNameReq'
        public amountReq ?: number,
        public tenureReq ?: number,
        public interestReq ?: number,
        public purposeReq ?: string,
        public securityOfferedReq ?: string,
        public securityOfferedOtherReq ?: string,
        public emi ?: number,
        public affordableEmi ?: number,
        public productTypeProp ?: string,
        public productCodeProp ?: string,
        public productNameProp ?: string,
        public amountProp ?: number,
        public tenureProp ?: number,
        public interestProp ?: number,
        public purposeProp ?: string,
        public securityOfferedProp ?: string,
        public securityOfferedOtherProp ?: string,
        public productTypeSanc ?: string,
        public productCodeSanc ?: string,
        public amountSanc ?: number,
        public tenureSanc ?: number,
        public interestSanc ?: number,
        public purposeSanc ?: string,
        public amount ?: number,
        public product ?: string,
        public tenure ?: number,
        public interest ?: number,
        public applicationDate ?: Date,
        public repaymentBankaccountUid ?: string,
        public existingRelationship ?: string,
        public customerNum ?: string,
        public loanNum ?: string,
        public customerUid ?: string,
        public loanUid ?: string,
        public opportunityUid ?: string,
        public opportunitySource ?: string,
        public processingStage ?: string,
        public status ?: string,
        public branchCode ?: string,
        public salesPersonId ?: string,
        public lat ?: number,
        public lng ?: number,
        public dedupFlag ?: boolean,
        public dedupReference ?: string,
        public dedupResolution ?: string,
        public branchUid ?: string,
        public zoneUid ?: string,
        public salesPersonUid ?: string,
        public trackingId ?: string,
        public approvedFlag ?: boolean,
        public provisionalApprovedFlag ?: boolean,
        public finalApprovedFlag ?: boolean,
        public tenureChangeApprovedFlag ?: boolean,
        public roiChangeApprovedFlag ?: boolean,
        public bankAcChangeApprovedFlag ?: boolean,
        public maxLoanWithDeviationFlag ?: boolean,
        public lockFlag ?:boolean,
        public roName ?: string,
        public bmName ?: string,
        public bcmName ?: string,
        public loanCategory ?:string,
        public surrogateEmi ?: number,
        //Keep version always at the last
        public version ?: number,
        public currentWorkflowStage ?: string,
        public collateralSet?: string,
        public guarantorUid?: string,
        public rejectReason?: string,
        public purposeReqCode ?: string,
        public purposePropCode ?: string,
        public purposeSancCode ?: string,
    ) {}
}

export class LoanApplicationLite {
    // Fields marked with an empty comment are local fields and not received in api response.
    constructor (
        public uid ?: string,
        public branchCode?: string,
        public applicantName ?: string,
        public productCodeReq ?: string,
        public productCodeProp ?: string,
        public productCodeSanc ?: string,
        public product ?: string, //
        public amountReq ?: number,
        public amountProp ?: number,
        public amountSanc ?: number,
        public amount ?: number, //
        public currentWorkflowStage ?: string,
        public applicationDate ?: Date,
        public submissionDate ?: Date,
        public workflowStageStartTime ?: Date,
        public turnAroundTime ?: number,
        public WaitingTime ?: number,//
        public opportunitySource ?: string,
        public businessName ?: string,
        public businessImageFolderUid ?: string,
        public roName ?: string,
        public bmName ?: string,
        public bcmName ?: string
    ) {}
}

export class Person {
    constructor (
        public uid ?: string,
        public personId ?: string,
        public partyPlay ?: string,
        public name ?: string,
        public title ?: string,
        public firstName ?: string,
        public middleName ?: string,
        public lastName ?: string,
        public aliasName ?: string,
        public dateOfBirth ?: Date,
        public age ?: number,
        public gender ?: string,
        public fatherName ?: string,
        public fatherFirstName ?: string,
        public fatherMiddleName ?: string,
        public fatherLastName ?: string,
        public motherName ?: string,
        public motherFirstName ?: string,
        public motherMiddleName ?: string,
        public motherLastName ?: string,
        public maritalStatus ?: string,
        public spouseName ?: string,
        public spouseFirstName ?: string,
        public spouseMiddleName ?: string,
        public spouseLastName ?: string,
        public status ?: string,
        public homeBranch ?: string,
        public profileFlag ?: boolean,
        public profilePicFileId ?: string,
        public dedupFlag ?: boolean,
        public dedupReference ?: string,
        public dedupResolution ?: string,
        public branchUid ?: string,
        public version ?: number,
        public profilePicFileUid ?: string,
        public customerDisplayName ?: string,
        public source?: string
    ) {}
} 

export class ApplicationSearchCriteria {
    constructor (
        public applicationDateFrom ?: string,
        public applicationDateTo ?: string,
        public productTypes ?: string[],
        public stages ?: string[],
        public branches ?: string[],
        public show ?: string,
        public applicantName ?: string,
        public applicationNo ?: string 
    ) {}
}
export class BankAccounts {
    constructor(
        public id?: number,
        public uid?: string,
        public accountNumber?: string,
        public accountHolderName?: string,
        public branchName?: string,
        public bankName?: string,
        public ifsc?: string,
        public bankingSince?: number,
        public accountType?: string,
        public bankAddress?: string,
        public repaymentAccount?: boolean,
        public acOpeningYear?: Date,
        public accountCategory?: string,
        public linkToType?: string,
        public linkToId?: string,
        public linkToUid?: string,
        public linkedAs?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public dedupFlag?: boolean,
        public dedupReference?: string,
        public dedupResolution?: string,
        public application?: LoanApplication,
        public person?: Person,
        public deleteBtnDisabled?:boolean, // added for client purpose only
        public verificationMode?: string,
        public verifiedBy?: string,
        public verifiedFlag?: boolean,
        public maskedAccountNumber?: string,
        public source?: string
    ) { }
}

export class LoanProduct {
    constructor (
        public uid ?: string,
        public encoreProductCode ?: string,
        public productType ?: string,
        public productCode ?: string,
        public productName ?: string,
        public minAmount ?: number,
        public maxAmount ?: number,
        public minTenure ?: number,
        public maxTenure ?: number,
        public defaultTenure ?: number,
        public tenureUnit ?: string,
        public minInterest ?: number,
        public maxInterest ?: number,
        public defaultInterest ?: number,
        public workflowName ?: string,
        public processFlag ?:boolean,
        public changeFlag ?:boolean,
        public sourcingFlag ?:boolean,
        public status ?: string,
        public version ?: number
    ) {}
}



export class EmiComputeCriteria {
    constructor (
        public amount ?: number,
        public tenure ?: number,
        public roi ?: number
    ) {}
}

export class ApplicationAccessSpecifier {
    constructor (
        public category ?: string,
        public allowAccess ?: boolean
    ) {}
}

/* Currently incomplete. To be filled as required */
export class ProductMaster {
    constructor (
        public productCode ?: string,
        public productName ?: string
    ) {}
}

export class CFAStatus {
    constructor (
        public cfaFinalized ?: boolean
    ) {}
}

export class RowValueDto{
	constructor(
	  public ordNo?: number,
	  public code?: string,
	  public name?: string,
	  public val1?: number,
	  public val2?: number,
	  public val3?: number,
	  public val4?: number,
	  public val5?: number,
	  public val6?: number,
	  public style?: string
	){}
  }

  export class RecentApplicationsLocalDataComposite{
    constructor(
      public application ?: LoanApplication,
      public reportInputParameters ?: ReportLink[],
      public checklistOptions ?: CheckListItem[]
    ){}
  }

  export class ApplicationUpdateDTO{
    constructor(
      public salesPersonId ?: string,
      public branchCode  ?: string,
      public opportunitySource ?: string
    ){}
  }