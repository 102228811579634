
import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { LoanApplication, Person } from '@app/applications/applications.model';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { validationDetailDTO } from '../../applicationDocument/xdata-service/xdata-response.model';
import { SecureStorageService } from '@ig-core/authentication/secureStorage.service';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'eng-delete-coapplicant',
  templateUrl: './delete-coapplicant.component.html',
  styleUrls: ['../../application-details.styles.scss', './delete-coapplicant.style.scss']
})
export class DeleteCoApplicant implements OnInit, OnChanges {
  @Input()
  application: LoanApplication;
  @Input()
  applicant: Person;
  @Input()
  menuCode: string;

  validateCoApplicantDelete: validationDetailDTO[];
  loginUser: any;
  fullName: string;

  //this to pass data to parent component when application data chnaged
  @Output() applicationDataChanged: EventEmitter<object> = new EventEmitter();

  @Output() changeSelectedMenu: EventEmitter<string> = new EventEmitter();

  constructor(
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private secureStorageService: SecureStorageService,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  ngOnInit() {
    this.loginUser = this.secureStorageService.secureSessionStorage.getItem("logInUserDetails")
  }


  ngOnChanges(changes: SimpleChanges) {
    // If it's the first change (i.e., if the component is just initialized), call a method validateCoapplicant.
    if (changes.applicant.firstChange) {
      this.validateCoapplicant()
    } else {
      // If it's not the first change (i.e., if the component has been initialized and 'applicant' input property changes thereafter),
      // we are setting selected menu to "COAPL-INFO", and emit this is to show applicant information on click of other co-applicant.
      // we have done this because user need to always select delete co-applicant menu and do the changes.
      let selectedMenu = "COAPL-INFO";
      this.changeSelectedMenu.emit(selectedMenu)
    }
  }

  validateCoapplicant() {
    this.fullName = this.applicant.title + ". " + this.applicant.firstName + " " + this.applicant.lastName
    this.applicationService.validateCoapplicantDelete(this.application.uid, this.applicant.uid).subscribe(response => {
      this.validateCoApplicantDelete = response
    })
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete co-applicant " + this.fullName + " permanently?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteCoApplicant();
      }
    })
  }

  deleteCoApplicant() {
    this.applicationService.deletePerson(this.application.uid, this.applicant.uid).subscribe((response) => {
      if (response.body.status == "SUCCESS") {
        this._snackbar.open(response.body.message, "Close", {
          duration: 8000,
        });
        // on successful deletion of co-applicant we need to reload the page to show updated data, hence we are emiting and calling reloadApplication() in application-details component
        this.applicationDataChanged.emit();
      }
    }, (failure) => {
      let errormesg = []
      errormesg.push("Failed to delete co-applicant")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    }
    );
  }

}
