import { HttpClient, HttpParams, HttpResponse, HttpEvent, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { SnackbarService } from '@app/utils/multiple-snackbar-messages-display.service';
import { Observable } from 'rxjs-compat';
import { Task } from '@app/tasks/task.model';
@Injectable({
    providedIn: 'root'
})
export class ActionItemService {
    private API = 'api/application';
    private APIv1 = 'api/v1/applications/';

    public allowAccess: boolean;
    constructor(private http: HttpClient,
        private _snackbar: MatSnackBar,
        private customSnackBarService: SnackbarService,
        private customDatepipe: DateFormatPipe) { }


        // this api is called to save actionItem/create action item 
    saveActionItems(applicationUid: string, actionItem: any): Observable<HttpResponse<any>> {
        const applicantInformationObject: any = Object.assign({}, actionItem);
        applicantInformationObject.plannedDueDate = this.customDatepipe.transform(
            applicantInformationObject.plannedDueDate, 'ISODATE');
        return this.http.post<any>(this.APIv1 + applicationUid +  '/actionitems/new/save', applicantInformationObject, { observe: 'response' });
    }    

    getAssignRolesToActionItems(applicationUid: string): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.APIv1 + applicationUid + '/assignToRoles' , { observe: 'response' });
    }

    getActionItem(applicaitonUid: string): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.APIv1 + applicaitonUid + '/actionitems' , { observe: 'response' });
    }

    // this api is called on click of save inside actionItem component
    updateActionItems(applicationUid: string, actionItem: any): Observable<HttpResponse<any>> {
        actionItem.forEach(item =>{
            const applicantInformationObject = Object.assign({}, item);
            applicantInformationObject.task.revisedDueDate = this.customDatepipe.transform(
                applicantInformationObject.task.revisedDueDate, 'ISODATE');
        })
        return this.http.put<any>(this.APIv1 + applicationUid +  '/actionitems/save', actionItem, { observe: 'response' });
    }   
}
