import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigureService } from '../../../../../src/app/build-config/configure.service';
import { retry, tap } from 'rxjs/operators';
import { IgErrorLogService } from '../error-handler/ig-error-log.service';
import { AuthTokenService } from '../authentication/auth-token.service';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { SnackbarService } from '@app/utils/multiple-snackbar-messages-display.service';
import { LocalStorageService, SessionStorageService} from 'angular-web-storage';
import { catchError } from 'rxjs/operators';
import { SecureStorageService } from '.././authentication/secureStorage.service'
@Injectable()
export class IgServerErrorInterceptor implements HttpInterceptor {
  constructor(private igErrorLogService: IgErrorLogService,
    private authTokenService: AuthTokenService,
    private router: Router,private secureStorageService: SecureStorageService,
    private snackBar: MatSnackBar,private customSnackBarService: SnackbarService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        let errorMsg:any = '';
        if (err.error instanceof ErrorEvent) {
              console.error('An error occurred:', err.error.message);
              errorMsg = 'An error occurred:' + err.error.message
              this.snackBar.open('An error occurred:' + err.error.message, 'Close');
            } else if (err instanceof HttpErrorResponse) {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong.
              // A client-side or network error occurred.
              if (err.status === 0) {
                errorMsg = 'An error occurred:' + err.error.message
                this.snackBar.open('An error occurred: ' + 'Please check your internet connection', 'Close');
              } //this check is added only for Engrow api, because if passThrough API from Encore fails it was signing out.
                //hence we are checking if error in encore/api then we are not signing out
              else if (err.status === 401 && !err.url.includes('encore/api')) {
                if(err.error.detail == "Full authentication is required to access this resource"){
                  this.snackBar.open("Please login to continue", 'Close');
                }else{
                  this.snackBar.open(err.error.detail, 'Close');
                }
                if(this.secureStorageService.secureSessionStorage.getItem('authenticationToken')){
                this.router.navigate(['signout']);
                }
              } else if (err.status === 500) {
                //this check is added for Encore api's because in some cases for internal server error 
                //there is no error message it will show as null 
                if(err.error.message === null){
                  err.error.message = "Internal Server Error";
                }
                errorMsg = 'An error occurred: ' + err.error.message
                //this check is added only for Encore api calls will not have err.error.path to split
                //so we are only setting the message from encore and displaying it
                if(!err.url.includes('encore/api')){
                let apiWord = err.error.path.split("/")
                this.snackBar.open('An Internal server error : ' + 'The ' + apiWord[apiWord.length - 1] + ' request was not completed.Please try again', 'Close');
                }
              }else if (err.status === 502) {
                this.snackBar.open('Server connection failed. Please contact system administrator', 'Close');
              } else if (err.status === 400) {
                // this check is required for encore api's because encore error message structure is different from engrow 
                //for encore api's we will not get err.error.params hence we are checking if the url contains encore/api
                //then we are setting whatever error message sent by encore that is inside err.error.message
                if(err.url.includes('encore/api')){
                  if(err.error.message){ /** will return true if exist */
                  errorMsg=err.error.message
                  } else {
                    errorMsg=err.error
                  }
                }
                else if(Object.keys(err.error.params).length !== 0) {
                    errorMsg=err.error
                }else if (err.error.message) {
                  errorMsg = err.error.message
                  this.snackBar.open(errorMsg, 'Close');
                }else  if (err.error.detail) {
                  errorMsg = err.error.detail.split(";")[0]
                  this.snackBar.open(errorMsg, 'Close');
                    //this.customSnackBarService.openSnackBar(apiErrDetail, 'Close');
                }
                
              } else if (err.status === 404) {
                errorMsg = 'Request Not Found: The server can not find the requested URL.Please try again'
                this.snackBar.open('Request Not Found: ' + 'The server can not find the requested URL.Please try again', 'Close');
              } // this status 409 is used to indicate a conflict(version mismatch error)
                else if (err.status === 409) {
                  errorMsg = err.error.message
                }
            }
      
        console.log(errorMsg);
        return throwError(errorMsg);
      })
    )
  }

}

