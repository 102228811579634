import { ProductMaster } from "@app/applications/applications.model";

export class Branch {
    id?: number;
    uid?: string;
    version?: number;
    name?: string;
    code?: string;
    gstin?: string;
    address1?: string;
    address2?: string;
    address3?: string;
    city?: string;
    district?: string;
    state?: string;
    country?: string;
    pinCode?: string;
    productsetCode?: string;
    products?: string[];
}
