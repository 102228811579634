import { Component, OnInit, ViewChild,Output, OnDestroy, AfterViewInit,EventEmitter, Input } from '@angular/core';
@Component({
    selector: 'eng-go-to-page',
    templateUrl: './go-to-page.component.html',
    styleUrls: ['./go-to-page.component.scss'],
  })
  export class GoToPageComponent implements OnInit{
@Input() paginator:any;
@Input() pageNumbers:any;

@Output() updatePage: EventEmitter<any> = new EventEmitter();

manualPage:any;

    ngOnInit() {}

 /*
   Go To Page component,On click of 'ok' button this method will be executed,
  Here 'this.paginator' will give number of pages of parent component table,
  using table pageNumbers if user entered page number is grater that parent pageNumbers the last page number will be passed to parent component 
  if user entered page number is within parent page numbers range, user entered value will be passed to parent component 
  */
  updateManualPage(): void {
    if (this.manualPage >= this.paginator.getNumberOfPages()) {
      this.manualPage = this.paginator.getNumberOfPages() - 1
      this.paginator.pageIndex = parseInt(this.manualPage)
    } else {
      this.paginator.pageIndex = parseInt(this.manualPage) - 1;
    }
    this.updatePage.emit(this.paginator.pageIndex);
    this.manualPage = undefined
  }

  }