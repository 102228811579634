import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';

import {ReferenceCode, ReferenceCodeSearchCriteria} from '../reference-code.model';
import {ReferenceCodeService} from '../reference-code.service';


export class ReferenceCodeListDataSource extends  DataSource<ReferenceCode> {

  private referenceCodeSubject = new BehaviorSubject<ReferenceCode[]>([]);

  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private referenceCodeService: ReferenceCodeService) { super(); }

  connect(collectionViewer: CollectionViewer): Observable<ReferenceCode[]> {
    return this.referenceCodeSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.referenceCodeSubject.complete();
    this.totalCount$.complete();
  }

  loadReferenceCodes(sortDirection = 'asc', pageIndex = 0, pageSize = 10, referenceCodeSearchCriteria : ReferenceCodeSearchCriteria) {

    this.referenceCodeService.findReferenceCodes({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    },referenceCodeSearchCriteria).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['headers'].getAll('X-Total-Count'));
      this.referenceCodeSubject.next(response['body']);
    });
  }

}
