import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

const formats = {
  "DATE": "dd-MM-yyyy",//just date in dd-MM-yyyy format
  "DATEYMD": "yyyy-MM-dd",//just date in yyyy-MM-dd format
  "DATEHM12": "dd-MM-yyyy h:mm a",//date and time upto minute in 12 hour format with am/pm.
  "DATEHM24": "dd-MM-yyyy HH:mm",//date and time upto minute in 24 hour format without am/pm
  "DATEHMS12": "dd-MM-yyyy h:mm:ss a",// date and time upto seconds in 12 hour format with am/pm
  "DATEHMS24": "dd-MM-yyyy HH:mm:ss",//date and time upto seconds in 24 hour format without am/pm
  "ISODATE": "yyyy-MM-dd HH:mm:ss",//this date format is used to send to server to save date,server will accept only this format.
  "DATEMONTH": "dd-MM-yyyy",//only date and month in short format.
  "HOURMINUTE": "h:mm a",//hour and minutes with meridian.
  "CAMDATE":"dd-MM-yyyy HH:mm:ss",//this date format is used to send to server to save CAM date,server will accept only this format.
  "ShortDATE":"dd MMM yyyy"
}
@Pipe({
  name: 'customDateFormat'
})
export class DateFormatPipe extends
  DatePipe implements PipeTransform {
  transform(value: any, arg: string): any {
    arg = arg != undefined ? arg : "DATE"//if arg is not passed from component then default date format will assign
    let dateFormat = formats[arg]
    return super.transform(value, dateFormat);
  }
}