import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Audit} from './audit.model';
import {createRequestOption} from '../../utils/request-util';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private http: HttpClient ) {}

  query(req: any): Observable<HttpResponse<Audit[]>> {
    let params = new HttpParams();
    params = params.set('fromDate', req.fromDate);
    params = params.set('toDate', req.toDate);
    params = params.set('page', req.page);
    params = params.set('size', req.size);
    params = params.set('sort', req.sort);
    const requestURL = 'management/audits';

    return this.http.get<Audit[]>(requestURL, {
      params, observe: 'response'
    });
  }
}
