import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {LoanOdAccountsService} from './loan-od-accounts.service';
import { LoanOdAccounts } from './loan-od-accounts.model';

@Injectable()
export class LoanOdAccountsAmendtenureEntryResolve implements Resolve<LoanOdAccounts> {

    constructor(private loanOdAccountsService: LoanOdAccountsService) {}

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId')) {
            return this.loanOdAccountsService.find(route.paramMap.get('accountId'));
        }else {
            return new LoanOdAccounts();
        }
    }
}
