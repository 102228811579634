import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {ReferenceCode, ReferenceCodeSearchCriteria} from './reference-code.model';
import {NameValueDto} from '@ig-core/interfaces/name-value-dto';
import {map,share} from 'rxjs/operators'
import { LoanProduct, ProductMaster } from '@app/applications/applications.model';
import { RecentOpenedApplicationsService } from '@app/applications/recent-opened-applications.service';
@Injectable({
    providedIn: 'root'
})
export class ReferenceCodeService {

  // entityCreatedDate will have the application created date, i.e., applicationDate.
  // This date is used for comparison with the inactiveDate in the reference code.
  // The purpose is to determine whether to include the corresponding data in the response.
  entityCreatedDate: Date;

    private API = 'api/reference-codes';

    private refCodesRequested: { [key: string]: Observable<NameValueDto[]> } = {};
    private refCodesFetched: { [key: string]: NameValueDto[] } = {};
    private referenceCodesRequested: { [key: string]: Observable<ReferenceCode[]> } = {};
    private referenceCodesFetched: { [key: string]: ReferenceCode[] } = {};
    private featureSet: any = null;
    private refCodesFetchedPrestine: any;
    constructor(private http: HttpClient, private recentOpenedApplicationsService:RecentOpenedApplicationsService) {}

    findReferenceCodes(req: any, referenceCodeSearchCriteria : ReferenceCodeSearchCriteria): Observable<HttpResponse<ReferenceCode[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if (referenceCodeSearchCriteria != undefined && referenceCodeSearchCriteria.classifier != null && referenceCodeSearchCriteria.classifier !=  "") {
          params = params.set('classifier', referenceCodeSearchCriteria.classifier);
      }
        return this.http.get<ReferenceCode[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveReferenceCode(referenceCode: ReferenceCode): Observable<HttpResponse<ReferenceCode>> {
        const copy: ReferenceCode = Object.assign({}, referenceCode);
        if (copy.id && copy.id != null) {
            return this.http.put<ReferenceCode>(this.API, copy, {observe: 'response'});
        } else {
            return this.http.post<ReferenceCode>(this.API, copy, {observe: 'response'});
        }
    }

    getReferenceCode(id: number) {
        return this.http.get<ReferenceCode>(this.API + '/' + id);
    }

    deleteReferenceCode(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    getClassifiers() {
      return this.http.get<NameValueDto>('api/_refs/reference-codes/classifiers');
    }

    getParentCodes(classifier: string) {
      return this.http.get<NameValueDto[]>('api/_refs/reference-codes/parentcodes/' + classifier);
    }

    getShortRefCodes(classifier: string) {
      if (this.refCodesFetched[classifier]) { // Is ref code already fetched
        return of({[classifier]:this.filterRefcodeByInactiveDate(this.refCodesFetched[classifier][classifier])});
      } else if (this.refCodesRequested[classifier]) { // Is ref code requested
        return this.refCodesRequested[classifier];
      } else { // New request needed
        this.refCodesRequested[classifier] = this.getShortRefCodesForClassifier(classifier);
        return this.refCodesRequested[classifier];
      }
    }
    
    getShortRefCodesForClassifier(classifier: string) {
      const response = this.http.get<NameValueDto[]>('api/_refs/reference-codes/all/codes/'
          + classifier).pipe(map(response =>{
            // here we are keeping copy of response to save the original response in this.refCodesFetched
            this.refCodesFetchedPrestine = Object.assign({} ,response);
            this.refCodesRequested[classifier] = null;
            this.refCodesFetched[classifier] = this.refCodesFetchedPrestine;
            // we need to send response as response.[classifierName] hence we are assigning to other variable and returning.
            let filteredResponse = this.filterRefcodeByInactiveDate(response[classifier]);

            // Assign the filtered result back to the original response
            response[classifier] = filteredResponse;
    
            return response;
          }), share());
      return response;
    }

    getRefCodesForClassifier(classifier: string) {
      if (this.referenceCodesFetched[classifier]) { // Is ref code already fetched
        return of(this.filterRefcodeByInactiveDate(this.referenceCodesFetched[classifier]));
      } else if (this.referenceCodesRequested[classifier]) { // Is ref code requested
        return this.referenceCodesRequested[classifier];
      } else { // New request needed
        this.referenceCodesRequested[classifier] = this.requestRefCodesForClassfier(classifier);
        return this.referenceCodesRequested[classifier];
      }
    }
    
    //this function will give 1st time results
    requestRefCodesForClassfier(classifier: string) {
      const response = this.http.get<ReferenceCode[]>('api/refCodeForClassifier/'
          + classifier).pipe(map(response => {  //Cache the refcodes received
            this.referenceCodesRequested[classifier] = null;
            this.referenceCodesFetched[classifier] = response; // Cache the fetched reference codes

            // After caching, return the filtered response using the filterItems method
            return this.filterRefcodeByInactiveDate(response);
          }), share());
      return response;
    }

    getLoanProducts(productType: string, productCode?: string) {
      if(productCode) {
        return this.http.get<LoanProduct[]>('api/v1/masters/loan-products/'
          + productType + '/' + productCode, {observe: 'response'});
      }else{
        return this.http.get<LoanProduct[]>('api/v1/masters/loan-products/'
          + productType, { observe: 'response' });
      }
    }

    getAllProducts() {
      return this.http.get<ProductMaster[]>('api/fetchAllProducts', { observe: 'response' });
    }

    getDistinctStages() {
      return this.http.get<string[]>('api/v1/masters/workflow/stages', { observe: 'response' });
    }

    getWorkflowName() {
      return this.http.get<string[]>('api/v1/masters/workflow/workflowNames', { observe: 'response' });
    }

    // this api is used to get what all features need to show in the list like guarantor, incomeProfile etc. 
    getFeatureSet() {
      if (this.featureSet) { // Feature set is already fetched
        return of(this.featureSet);
      } else {
        const response = this.http.get<string[]>('api/v1/system/license/featureset', { observe: 'response' });
        response.subscribe((data) => {
          this.featureSet = data;
        });
        return response;
      }
    }

    clearReferenceCodeFetchedData(){
      this.referenceCodesFetched = {}
      this.refCodesFetched = {}
      this.featureSet = null;
    }

  // this method will get opened application applicationDate and compare it with refcode inactiveDate.
  // This will return items which has inactiveDate greater than or equal to application date 
  // and it will also include refcode which don't have inactiveDate, that is inactive date is equal to "null" 
  filterRefcodeByInactiveDate(items) {
    // we are getting opened applicaiton date to comapare with inactiveDate in refcode. 
    this.entityCreatedDate = this.recentOpenedApplicationsService.getEntityCreatedDate()
    // here we are moving inactive refcode that is status = 0 to last items of list.
    items = items.sort((a, b) => b.status - a.status);
    if (this.entityCreatedDate) {
      //we are filtering the items based on application date and inactiveDate  
      items = items.filter((item) => {
        let createdDate = new Date(this.entityCreatedDate);
        createdDate.setHours(0, 0, 0, 0);
        let inactiveDate = item.inactiveDate ? new Date(item.inactiveDate) : null;
        // Include items with inactiveDate greater than or equal to appDate or inactiveDate is null
        return !inactiveDate || inactiveDate >= createdDate;
      });
    }
    return items
  }
}

