import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowDetailsDTO } from '../workflow/workflow.model';
@Injectable({
    providedIn: 'root'
})
export class WorkflowService {
    private API = 'api/v1/masters/';
    constructor(private http: HttpClient) { }

     //Get who is who. To get workflow details on stages,
     //roles assigned to stages and users assigned to each role
    getWhoIsWho(workflowName?: string, branch?: string) {
        let params = new HttpParams();
        if (workflowName && workflowName !== ''){
            params = params.set('workflowName', workflowName);
        }
        if (branch && branch !== ''){
            params = params.set('branch', branch);
        }
        return this.http.get<any>(this.API + 'workflow/whoiswho', { params, observe: 'response' });
    }

}