import { Component, Inject, HostListener, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as fileSaver from 'file-saver';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper-upgraded';
@Component({
  selector: 'eng-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {
  files: any=[]
  uploadFile: any
  uploadType: string;
  folderUid: string;
  applicationUid: string;

  removable: boolean = true;
  // progressbarValue = 100;
  // curSec: number = 0;
  showWebcam: boolean = false;
  showCropper:boolean = false;
  allowCameraSwitch=false;
  previewImage: string;
  trigger: Subject<void> = new Subject();
  imageChangedEvent: any;
  croppedImage: string;
  photoNumber: number = 1;
  public facingMode: string = 'user';
  public multipleWebcamsAvailable: boolean = false;
  public errors: WebcamInitError[] = [];

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { uploadType: string, folderUid: string, applicationUid: string, files: Array<Files>, folderType: string }
    , private mdDialogRef: MatDialogRef<FileUploadComponent>,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private spinner: SpinnerVisibilityService,
    private fileService: IgFileService) {
    this.uploadType = this.data.uploadType;
    this.folderUid = this.data.folderUid
    this.applicationUid = this.data.applicationUid;
    //this.files = this.data.files
  }

  public cancel() {
    this.close(false);
  }
  public close(value) {
    this.mdDialogRef.close({ folderUid: this.folderUid });
  }
  public confirm() {
    this.close(true);
  }
  @HostListener("keydown.esc")
  public onEsc() {
    this.close(false);
  }
  ngOnInit() {
    this.loadExistedFilesInFolder()
    this.readAvailableVideoInputs()
  }

  private readAvailableVideoInputs() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
    }

  openWebcam(){
    this.showWebcam = true;
    this.showCropper = false;
  }

  get $trigger():Observable<void>{
    return this.trigger.asObservable();
  }
  
  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }

    return result;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
}

  takeSnapshot(){
    this.showWebcam = false;
    this.showCropper = true;
    this.trigger.next();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  snapshot(event: WebcamImage){
    console.log(event);
    this.previewImage = event.imageAsDataUrl;
  }

  saveImage(){
    this.showCropper = false;
    const imageName = 'photo'+ String(this.photoNumber)+'.png';
    this.photoNumber = this.photoNumber + 1;
    const imageBlob = this.dataURItoBlob(this.croppedImage);
    const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
    var imageList: any=[]
    imageList.push(imageFile)
    this.prepareFilesList(imageList)
}
  
dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  const blob = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
  return blob;
}

  /* this function will cann on file drop */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /* this function will handle file from browsing*/
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      if (item) {
        const file = item;
        if (file.type != 'application/pdf') {
          const reader = new FileReader();
          reader.onload = e => item["fileContent"] = reader.result;

          reader.readAsDataURL(file);
        }
      }
      this.files.push(item);
      this.uploadFilesSimulator(this.files.length - 1);
    }

  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  //this function will load already exited files
  loadExistedFilesInFolder() {
    this.spinner.hide();
    this.files.forEach(document => {
      if (document.type != 'application/pdf') {
        //stream all images found in the folder
        this.applicationService.downloadFileFromApplicationFolder(this.applicationUid, document.folderUid, document.fileId).subscribe(image => {
          let reader = new FileReader();
          reader.addEventListener("load", () => {
            document.fileContent = reader.result; //used in img [src]
            document.imageLoaded = true;
          }, false);
          if (image) reader.readAsDataURL(image.data);
        });
      }
  });
}

ngOnDestroy(): void {

}

//this function will call onclick of download icon in file's section
downloadFile(file) {
  this.applicationService.downloadFileFromApplicationFolder(this.applicationUid, file.folderUid, file.fileId).subscribe(image => {
    const blob = new Blob([image.data], { type: file.type });
    let fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    fileSaver.saveAs(blob, file.fileName);
    this._snackbar.open("File has been downloaded successfully", "Close", {
      duration: 5000,
    });
  })
}

saveFiles() {
  if(this.files && this.files.length == 0){
    this._snackbar.open("Select file to upload", "close")
    return;
  }
  // we are passing folderType to check for uploadfile option is from docket or from any other component
  // In docket we are allowing only pdf files to upload, and in other cases we can add image and pdf both
  // hence if the folderType is docket then we need to call dockets/file/upload api and in other cases we need to call folders/file/upload api
  let folderType = this.data.folderType && this.data.folderType == 'docket' ? 'dockets': 'folders'
  this.applicationService.uploadFileInApplicationFolder(this.applicationUid, this.folderUid,this.files, folderType).subscribe(response => {
    if(response.type != 0){
      this.close(true)
    }
    this._snackbar.open("File has been uploaded successfully", "Close", {
      duration: 5000,
    });
  }
  );
}
}
