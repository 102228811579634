import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../admin/user/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorModel } from '../../../../projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from '../../../../projects/ig-core/src/lib/form/form.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { IgPatternValidator } from '../../../../projects/ig-core/src/lib/form/additional-validators/ig-pattern-validator';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { IgFormValidator } from 'projects/ig-core/src/lib/form/igFormValidator';
import { UserService } from '../../admin/user/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {

  user: User;
  editable = true;

  public userRegistrationForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    firstName: '',
    lastName: '',
    email: '',
    login: '',
    langKey: '',
    newPassword: ''
  };

  constructor(private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService) {
      this.user = {},
      this.editable = true;
  }

  ngOnInit() {
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
  }
  ngOnDestroy(): void {
  }

  buildForm() {
    this.userRegistrationForm = this.formBuilder.group({
      id: [this.user.id],
      firstName: [this.user.firstName],
      lastName: [this.user.lastName],
      email: [this.user.email, [Validators.required, IgFormValidator.email]],
      login: [this.user.login, Validators.required],
      langKey: [this.user.langKey],
      password: [''],
    });
    if (this.editable && !this.user.id) {
      this.userRegistrationForm.controls['password'].setValidators(Validators.compose([Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
      // check whether the entered password has a number
      IgPatternValidator.patternValidator(/\d/, {
        hasNumber: true
      }),
      // check whether the entered password has upper case letter
      IgPatternValidator.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      // check whether the entered password has a lower case letter
      IgPatternValidator.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      // check whether the entered password has a special character
      IgPatternValidator.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      ),
      Validators.minLength(8)]));
    }
    // on each value change we call the validateForm function
    // We only validate form controls that are dirty, meaning they are touched
    // the result is passed to the formErrors object
    this.userRegistrationForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
      this.formErrors = this.igFormService.validateForm(this.userRegistrationForm, this.formErrors, true);
    });
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, '');
    // this.fireClientError();
    // mark all fields as touched
    this.igFormService.markFormGroupTouched(this.userRegistrationForm);

    /*right before we submit our form to the server we check if the form is valid
    if not, we pass the form to the validateform function again. Now with check dirty false
    this means we check every form field independent of wether it's touched*/

    if (this.userRegistrationForm.valid) {
      // this.fireClientError();
      this.userService.registerUser(this.userRegistrationForm.value).pipe(takeUntil(componentDestroyed(this)))
        .subscribe((response) => this.onSubmitSuccess(response),
        (response) => this.onSubmitError(response));
    } else {
      this.formErrors = this.igFormService.validateForm(this.userRegistrationForm, this.formErrors, false);
      return false;
    }

  }

  private onSubmitSuccess(response) {
    this.snackBar.open(`User registered successfully`, 'Close');
    // redirect to login page;
    this.router.navigate(['signin']);
  }

  private onSubmitError(response) {
    if (response.error.status === 400 ) {
      this.snackBar.open(`${response.error.title}`, 'Close');
    } else {
      this.snackBar.open(`Unable to register new user. Please try again`, 'Close');
    }
  }

  gotoLogin() {
    this.router.navigate(['signin']);
  }

}
