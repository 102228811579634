
export class BureauReport {
    constructor (
        public uid?: string,
        public version ?: number,
        public bureauReportType ?: string,
        public reportNumber ?: string,
        public reportDate ?: string,
        public reportExpiryDate ?: string,
        public validUpto ?: Date,
        public requestRef ?: string,
        public requestDate ?: string,
        public requestStatus ?: string,
        public creditScore ?: string,
        public scoreCategory ?: string,
        public reportDescription ?: string,
        public verifiedBy ?: string,
        public verificationDate ?: string,
        public verifiedFlag ?: boolean,
        public fileFolderUid ?: string,
        public linkToType ?: string,
        public linkToId ?: string,
        public linkToUid ?: string,
        public versionHistoryFlag ?: boolean,
        public frozenFlag ?: boolean,
        public contextType ?: string,
        public contextId ?: string,
        public contextUid ?: string,
        public enquiry3m ?: number,
        public enquiry6m?: number,
        public enquiryTotal ?: number,
        public writeoff2y?: number,
        public writeoffTotal?: number,
        public deleteBtnDisabled?: boolean,//added for client side use this paramenter will not come from server
        public source?: string
    ) {}
}