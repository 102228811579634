import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApprovalLimit } from '../approval-limit.model';
import { ApprovalLimitService } from '../approval-limit.service';
import { ApplicationService} from '@app/applications/application.service'
@Component({
  selector: 'app-approval-limit',
  templateUrl: './approval-limit-edit.template.html',
  styleUrls: ['../approval-limit.style.scss']
})

export class ApprovalLimitEditComponent implements OnInit, OnDestroy {

  approvalLimit: ApprovalLimit;
  editable = true;
  title: string;
  mode: string;
  itemTypes: NameValueDto[];

  public approvalLimitEditForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    itemType: '',
    approver: '',
    grant: '',
    approvalAmount: ''
  };

  constructor(
    private approvalLimitService: ApprovalLimitService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private referenceCodeService: ReferenceCodeService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    private igFormService: IgFormService,
    private applicationService:ApplicationService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let data = JSON.parse(params.data)
      this.title = data.title;
      this.mode = data.mode;
      if (data.mode === 'create') {
        this.approvalLimit = {};
      } else if (data.mode === 'edit') {
        this.approvalLimit = data.approvalLimit;
      } else if (data.mode === 'view') {
        this.approvalLimit = data.approvalLimit;
      }
    })
    this.getReferenceCodes();
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
  }

  ngOnDestroy(): void { }

  buildForm() {
    this.approvalLimitEditForm = this.formBuilder.group({
      uid: [this.approvalLimit.uid, []],
      grantType: [this.approvalLimit.grantType === undefined ? 'loanamount' : this.approvalLimit.grantType, []],
      approverType: [this.approvalLimit.approverType === undefined ? 'user' : this.approvalLimit.approverType, []],
      itemType: [this.approvalLimit.itemType, []],
      approver: [this.approvalLimit.approverCode, []],
      approvalAmount: [this.approvalLimit.approvalValue, []],
      grant: [this.approvalLimit.allowdenyFlag, []],
    });
    if (this.mode === 'view') {
      this.approvalLimitEditForm.disable();
    }

    this.approvalLimitEditForm.controls.approverType.disable();
    this.approvalLimitEditForm.controls.grantType.disable();
    this.approvalLimitEditForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.formErrors = this.igFormService.validateForm(
          this.approvalLimitEditForm,
          this.formErrors,
          true
        );
      });
  }

  getReferenceCodes() {
    this.referenceCodeService.getShortRefCodes('loan_type').subscribe((response: any) => {
      this.itemTypes = response.loan_type;
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  saveApprovalLimit() {
    this.markFormGroupTouched(this.approvalLimitEditForm)
    if (this.approvalLimitEditForm.valid) {
      let approvalLimitFormFields = this.approvalLimitEditForm.value;
      this.approvalLimit.uid = approvalLimitFormFields.uid;
      this.approvalLimit.grantType = approvalLimitFormFields.grantType === undefined ? 'loanamount' : this.approvalLimit.grantType;
      this.approvalLimit.approverType = approvalLimitFormFields.approverType === undefined ? 'user' : this.approvalLimit.approverType;
      this.approvalLimit.itemType = approvalLimitFormFields.itemType;
      this.approvalLimit.approverCode = approvalLimitFormFields.approver;
      this.approvalLimit.approvalValue = approvalLimitFormFields.approvalAmount;
      this.approvalLimit.allowdenyFlag = approvalLimitFormFields.grant;
      this.approvalLimitService.saveApprovalLimit(this.approvalLimit.uid,
        this.approvalLimit).toPromise().then(
          (_success) => {
            if (this.approvalLimit.uid) {
              this._snackbar.open("Approval Limit updated successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
            } else {
              this._snackbar.open("Approval Limit created successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../'], { relativeTo: this.activatedRoute });
            }
          }, (failure) => {
            let errormesg =[]
            errormesg.push("Approval Limit update failed.")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
          }
        );
    }
  }

  goToApprovalLimitListPage() {
    if (this.approvalLimit.uid) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

}
