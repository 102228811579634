import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';
import {LoanApplicationLite, ApplicationSearchCriteria} from './applications.model';
import {ApplicationService} from './application.service';

export class ApplicationsDataSource extends DataSource<LoanApplicationLite> {

  private applicationSubject = new BehaviorSubject<LoanApplicationLite[]>([]);
  public totalCount$ = new BehaviorSubject<number>(0);
  
  constructor(private applicationService: ApplicationService) {
    super();
  }
  
  connect(collectionViewer: CollectionViewer): Observable<LoanApplicationLite[]> {
    return this.applicationSubject.asObservable();
  }
  
  disconnect(collectionViewer: CollectionViewer): void {
    this.applicationSubject.complete();
    this.totalCount$.complete();
  }

  getApplications(sortDirection = 'asc', pageIndex = 0, pageSize = 10,
      searchCriteria: ApplicationSearchCriteria) {
    this.applicationService.getApplications({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }, searchCriteria).pipe(
      catchError(() => of([])),
      finalize(() => {})
    ).pipe(take(1)).subscribe((response) => {
      if(response['body']){
      response['body'].content.forEach(application => {

        application['product'] = !!application.productCodeSanc ? application.productCodeSanc
          : (!!application.productCodeProp ? application.productCodeProp
            : application.productCodeReq);
        
        /* Amount, tenure, interest all have same checks.
              amount sanc ? sanc : (amount prop ? prop : req. */
        application['amount'] = (application.amountSanc && application.amountSanc > 0)
          ? application.amountSanc : (application.amountProp && application.amountProp > 0
          ? application.amountProp : application.amountReq);
        
        application['tenure'] = (application.amountSanc && application.amountSanc > 0)
          ? application.tenureSanc : (application.amountProp && application.amountProp > 0
          ? application.tenureProp : application.tenureReq);

        application['interest'] = (application.amountSanc && application.amountSanc > 0)
          ? application.interestSanc : (application.amountProp && application.amountProp > 0
          ? application.interestProp : application.interestReq);

        // Turn around time calculation
        let startDate = application.submissionDate ? new Date(application.submissionDate)
            : new Date(application.applicationDate);
        let endDate = application.workflowStageStartTime ?
          new Date(application.workflowStageStartTime) : undefined;
        let toDay=new Date()
        if(endDate) {
          let dt1 = new Date(startDate);
          let dt2 = new Date(endDate);
          let turnAroundDate = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
          application["turnAroundTime"] = turnAroundDate;
          let WaitingTime = Math.floor((Date.UTC(toDay.getFullYear(), toDay.getMonth(), toDay.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24));
          application["WaitingTime"] = WaitingTime;
        }
      });
      this.totalCount$.next(response['body'].totalElements);
      this.applicationSubject.next(response['body'].content);
    }else{
      let errormesg =[]
      errormesg.push("Session time out please try again, or ")
      this.applicationService.displayErrorMessages(errormesg)
    }
    });
  }
} 