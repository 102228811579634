import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {MenuItem} from '../menu-item';
import {Router} from '@angular/router';
import {IgLayoutService} from '../../../../../src/app/ig-layout/ig-layout.service';

@Component({
  selector: 'ig-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent {
  
  @Input() item: MenuItem;
  mobileQuery: MediaQueryList;

  constructor(public router: Router,
              private igLayoutService: IgLayoutService ) {
    this.mobileQuery = this.igLayoutService.getMediaQueryForMobile();
  }

  onItemSelected(item: MenuItem) {
    this.igLayoutService.changeSelectedMenu(item.displayName);
    this.router.navigate([item.route]);
    if (this.mobileQuery.matches) {
      this.igLayoutService.closeSideNav();
    }
  }

}
