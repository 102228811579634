import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Log} from './log.model';

@Injectable({
  providedIn: 'root'
})
export class LogManagementService {

  private _dataUpdatedEvent = new Subject<any>();
  constructor(private http: HttpClient) { }

  changeLevel(log: Log): Observable<HttpResponse<any>> {
    return this.http.put('management/logs', log, {observe: 'response'});
  }

  findAll(): Observable<HttpResponse<Log[]>> {
    return this.http.get<Log[]>('management/logs', {observe: 'response'});
  }

  dataUpdated() {
    this._dataUpdatedEvent.next(true);
  }

  whenDataUpdated(): Observable<any> {
    return this._dataUpdatedEvent.asObservable();
  }

}
