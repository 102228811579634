// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration=prayaan-prod` replaces `environment.ts` with `environment.prayaan-prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: true,
    API_URL: "https://engrow.conatus.co.in/engrow-server",
    header:"conatus-prod",
    logo:"assets/additional-icons/logo-white-trans.png",
    clientLogo:"assets/client-logo/conatus-logo.png",
    VERSION: require('../../package.json').version,
    firebase: {
      apiKey: "AIzaSyBFJjWshrUJMSLE-Sl4mkO1D-QJY8DvObE",
      authDomain: "loyal-karma-261908.firebaseapp.com",
      databaseURL: "https://loyal-karma-261908.firebaseio.com",
      projectId: "loyal-karma-261908",
      storageBucket: "loyal-karma-261908.appspot.com",
      messagingSenderId: "239745651272",
      appId: "1:239745651272:web:02c1feda0e4919f574fe7d",
      measurementId: "G-CMY54M5WL2"
    },
    applicationDisplayColumns: ['applicationNo','branchCode', 'applicantName', 'product', 'amount','submissionDate',
  'currentWorkflowStage','WaitingTime', 'ro','turnAroundTime']
  };