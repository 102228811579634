import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from './menu-item';

@Component({
  selector: 'ig-multi-level-menu',
  template: `
    <mat-nav-list>
    <div *ngFor="let menu of menus">
    <ig-menu-item *ngIf="!menu.disabled" [item]="menu"></ig-menu-item>
    </div>
    </mat-nav-list>
  `,
  styles: []
})
export class MultiLevelMenuComponent implements OnInit {

  @Input() menus: MenuItem[];

  constructor() { }

  ngOnInit() {
  }

}
