import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {BranchSet} from './branch-set.model';

@Injectable({
    providedIn: 'root'
})
export class BranchSetService {

    private API = 'api/branchsets';
    private API1 = 'api';

    constructor(private http: HttpClient) {}

    findBranchSets(req: any): Observable<HttpResponse<BranchSet[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        return this.http.get<BranchSet[]>(this.API, {
            params, observe: 'response'
        });
    }

    saveBranchSet(branchSet: BranchSet): Observable<HttpResponse<BranchSet>> {
        const copy: BranchSet = Object.assign({}, branchSet);
        if (copy.id && copy.id != null) {
            return this.http.put<BranchSet>(this.API, copy, {observe: 'response'});
        } else {
            return this.http.post<BranchSet>(this.API, copy, {observe: 'response'});
        }
    }

    getBranchSet(id: number) {
        return this.http.get<BranchSet>(this.API + '/' + id);
    }

    deleteBranchSet(id: number) {
        return this.http.delete(this.API + '/' + id);
    }

    getAllBranchSet() {
        return this.http.get<BranchSet>(this.API1 + '/_getAll/branchsets');
    }
}

