import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute , Router} from '@angular/router';
import { Response } from '@angular/http';

import { EventManager, AlertService, JhiLanguageService } from 'ng-jhipster';
import { DateConverterService } from '../shared/date/date-converter.service';
import { LoanOdAccounts } from './loan-od-accounts.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Component({
    selector: 'jhi-loan-od-accounts-amend-loan-amount',
    templateUrl: './loan-od-accounts-amend-loan-amount.component.html',
})
export class LoanOdAccountsAmendLoanAmountComponent implements OnInit {

    loanOdAccounts: LoanOdAccounts;
    subscription: any;
    proceedIsTrue = false;
    transactionTypeList: any[];
    dataIsAvailable  = false;
    isSuccessful = false;

    constructor(
        private jhiLanguageService: JhiLanguageService,
        private alertService: AlertService,
        private router: Router,
        private loanODAccountsService: LoanOdAccountsService,
        private eventManager: EventManager,
        private route: ActivatedRoute,
        private dateConverter: DateConverterService
    ) {
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['accountId']);
        });
    }

    load(accountId) {
        this.loanODAccountsService.amendLoanAmountEntry(accountId).subscribe((loanOdAccounts) => {
            this.loanOdAccounts = loanOdAccounts;
            this.loanOdAccounts.openedOnDate = this.dateConverter
                .fromServerToNgbDatePicker(this.loanOdAccounts.openedOnDate);
            if (this.loanOdAccounts.amount === undefined || this.loanOdAccounts.amount === null) {
                this.loanOdAccounts.amount = {
                    magnitude: 0,
                }
            }
        },
        (res) => {
            this.onError(res);
            this.router.navigate(['/loans/loan-od-accounts']);
        });
    }

    clear() {
        this.loanOdAccounts = new LoanOdAccounts();
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    print() {
        window.print();
    }

    private onError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            }else {
            this.alertService.error('encoreclientApp.loanOdAccounts.genericError');
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.alertService.error('encoreclientApp.loanOdAccounts.genericError');
                }
            } catch (exception) {
                this.alertService.error('encoreclientApp.loanOdAccounts.genericError');
            }
        }
    }

    execute() {
        this.loanODAccountsService.amendLoanAmountExecute(this.loanOdAccounts)
            .subscribe(
                (res) => this.onExecuteSuccess(res),
                (res) => this.onError(res)
            );
    }

    private onExecuteSuccess(data) {
        this.loanOdAccounts = data;
        this.proceedIsTrue = true;
    }

    confirm() {
        this.loanODAccountsService.amendLoanAmountResult(this.loanOdAccounts)
            .subscribe(
                (res) => this.onConfirmSuccess(res),
                (res) => {
                    this.onError(res);
                    this.back();
                }
            );
    }

    private onConfirmSuccess(data) {
        this.isSuccessful = true;
        this.alertService.success('encoreclientApp.loanOdAccounts.amendLoanAmountSuccess'
            , { id: this.loanOdAccounts.accountId });
        this.router.navigate(['/loans/loan-od-accounts']);
    }

    back() {
        this.loanOdAccounts.openedOnDate = this.dateConverter
            .fromServerToNgbDatePicker(this.loanOdAccounts.openedOnDate);
        this.proceedIsTrue = !this.proceedIsTrue;
    }

}
