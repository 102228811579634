
import {LoanOdAccounts} from './loan-od-accounts.model';
import {FeeDto} from './loan-od-accounts-fee-dto.model' ;
import {Money} from './money';

export class LoanOdRepaymentDto {
    constructor(
        public id?: number,
        public  repaymentType?: any,
        public  accountId?: string,
        public  repaymentNum?: number,
        public  transactionId?: string,
        public  amount?: Money,
        public  balance?: Money,
        public  valueDate?: any,
        public  transactionDate?: any,
        public  userId?: string,
        public  systemDate?: any,
        public  principal?: Money,
        public  normalInterest?: Money,
        public  penalInterest?: Money,
        public  interestTds?: Money,
        public  instrument?: any,
        public  reference?: string,
        public  details?: string,
        public  remarks?: string,
        public  transactionLotId?: string
    ) {}
}
