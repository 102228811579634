import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Device } from '../device.model';
import { DeviceService } from '../device.service';

@Component({
    selector: 'app-device-edit',
    templateUrl: './device-edit.template.html',
    styleUrls: ['../device.style.scss']
})

export class DeviceEditorComponent implements OnInit, OnDestroy {

    device: Device;
    deviceUid: string;
    editable = false;

    public deviceEditorForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        approve: '',
        deactivate: '',
        transfer: '',
    };

    constructor(
        private deviceService: DeviceService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private igFormService: IgFormService
    ) { }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.device = JSON.parse(params.data)
        })
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        if (!this.editable) {
            this.deviceEditorForm.disable();
        }
    }

    buildForm() {
        
        this.deviceEditorForm = this.formBuilder.group({
            version: [this.device.version, []],
            user: [this.device.registeredUser, []],
            status: [this.device.status, []],
            deviceUuid: [this.device.deviceUuid, []],
            deviceNodeId: [this.device.deviceNodeId, []],
            appName: [this.device.appName, []],
        });
        this.deviceEditorForm.valueChanges
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
                this.formErrors = this.igFormService.validateForm(
                    this.deviceEditorForm,
                    this.formErrors,
                    true
                );
            });
    }

    changeDeviceStatus(action) {
        if (!this.device.deviceNodeId) {
            this.deviceService.deviceAction(this.device.uid, action).subscribe(response => {
                this.router.navigateByUrl('admin/device-directory')
            })
        }
    }

    deactivateDeviceStatus(action) {
            this.deviceService.deviceAction(this.device.uid, action).subscribe(response => {
                this.router.navigateByUrl('admin/device-directory')
            })
    }

    goToDeviceListPage() {
        if (this.device.uid) {
            this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        }
    }

    ngOnDestroy(): void { }

}
