import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {IgCoreComponent} from './ig-core.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {IgAPIInterceptor} from './interceptors/Ig-API.interceptor';
import {IgGlobalErrorHandler} from './error-handler/ig-global-error-handler';
import {IgServerErrorInterceptor} from './interceptors/Ig-server-error.interceptor';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {CommonModule} from '@angular/common';
import {MaterialLibModule} from '../../../../src/app/material-lib.module';
import { SystemAdminModule } from './system-admin/system-admin.module';
import {SocialAuthComponent} from './authentication/social-auth/social-auth.component';
import {RouterModule} from '@angular/router';
import {socialAuthRoute} from './authentication/social-auth/social.route';
import {SocialComponent} from './social-container/social.component';
import { ErrorBottomsheetComponent } from './error-handler/bottom-sheet/error-bottomsheet/error-bottomsheet.component';
import { CookieService } from 'ngx-cookie-service';
import { BlobErrorHttpInterceptor } from './interceptors/blob-error.interceptor';
import { ScheduledMaintenanceInterceptor } from './interceptors/schedule-maintenance.interceptor';


@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    MaterialLibModule,
    SystemAdminModule,
    RouterModule.forChild(socialAuthRoute)
  ],
  declarations: [IgCoreComponent, SocialAuthComponent, SocialComponent, ErrorBottomsheetComponent],
  exports: [IgCoreComponent, SocialAuthComponent, SocialComponent, ErrorBottomsheetComponent],
  entryComponents: [ErrorBottomsheetComponent],
  providers: [
    CookieService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: IgAPIInterceptor,
    multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IgServerErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScheduledMaintenanceInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: IgGlobalErrorHandler
    }
  ]
})
export class IgCoreModule {
}
