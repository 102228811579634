import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { AccountMandateDto } from './account-mandate-dto.model';

@Injectable()
export class LoanOdAccountsElectronicMandateShowResolve implements Resolve<AccountMandateDto> {

    constructor(private loanOdAccountsService: LoanOdAccountsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId'), route.paramMap.get('id')) {
            return this.loanOdAccountsService.getAccountMandate(route.paramMap.get('accountId'), route.paramMap.get('id')).map(
                (res) => res.json()
            );
        }
    }
}
