import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AlertService, DateUtils } from 'ng-jhipster';
import { LoanOdComputePayOffAmountDto } from './loan-od-compute-payOff-Amount-dto.model';

@Injectable()
export class LoanOdAccountsComputePayOffAmountResolve implements Resolve<LoanOdComputePayOffAmountDto> {

    accountId: string;
    constructor(private loanOdAccountsService: LoanOdAccountsService,
        private alertService: AlertService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('accountId')) {
            this.accountId = route.paramMap.get('accountId');
            return this.loanOdAccountsService.computePayOffAmount(route.paramMap.get('accountId')).pipe(map(
                (res) => res.json()
            )).pipe(catchError(
                (err) => {
                    this.onSaveError(err);
                    return of(null);
                }
            ));
        } else {
            return new LoanOdComputePayOffAmountDto();
        }
    }

    private onSaveError(error) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
                this.onError();
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError();
                }
            } catch (exception) {
                this.onError();
            }
        }
    }

    private onError() {
        this.alertService.error('encoreclientApp.loanOdAccounts.saveError');
    }
}
