import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { OnDestroy } from '@angular/core';
import { Applicant } from '@app/applications/application-details/collateral/collateralDetails/collateral-details.model';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
import { DataExportSearchCriteriaDTO } from './data-export.model';
import { HttpResponse } from '@angular/common/http';
import { ValidationService } from '@app/applications/application-validators/validation.service';
import { ProductMaster } from '@app/applications/applications.model';
@Component({
  selector: 'eng-equifax',
  templateUrl: 'samunnati-export.template.html',
  styleUrls: ['../../../applications/application-details/application-details.styles.scss', './samunnati-export.style.scss']
})
export class SamunnatiExport implements OnInit, OnDestroy {

  dataExportForm: FormGroup;
  products: ProductMaster[];
  dataExportSearchCriteria: DataExportSearchCriteriaDTO = {};


  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private referenceCodeService:ReferenceCodeService,
    private validationService:ValidationService) {
    this.dataExportForm = this.formBuilder.group({
      products: ['', Validators.required],
      accountOpenDate: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getProducts();
  }

  // getting all products and sorting based on productCode
  getProducts(){
    this.referenceCodeService.getAllProducts().subscribe((response: any) => {
      this.products = response.body
      this.products.sort((a, b) =>  a.productCode.localeCompare(b.productCode));
      });
  }

  onExportClick(){
    this.validationService.markFormGroupTouched(this.dataExportForm)
    if(this.dataExportForm.valid){
      this.dataExportSearchCriteria.accountDate = this.dataExportForm.controls.accountOpenDate.value;
      this.dataExportSearchCriteria.products = this.dataExportForm.controls.products.value;

      this.applicationService.exportToExcel(this.dataExportSearchCriteria).pipe(takeUntil(componentDestroyed(this))).subscribe(response => {
        this.saveDownloadedFile(response);
        this._snackbar.open("Excel generated successfully", "Close", {
          duration: 8000,
        })
      },(failure) => {
        this._snackbar.open(failure , "close", {
          duration: 8000,
        })
      })
    }
  }

    saveDownloadedFile(response: HttpResponse<any>) {
      const url = window.URL.createObjectURL(response.body);
      console.log("headder",response.headers);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      const responseHeaderFilename = response.headers.get('Content-Disposition');
      var filename = responseHeaderFilename.split(';')[1].split('=')[1].trim();
      a.download = filename
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  
  ngOnDestroy(): void {
  }
}
