import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MatSnackBar } from '@angular/material';
import { TaskService } from '@app/tasks/task.service';
import { DatePipe } from '@angular/common';
import {ReferenceCodeService} from '@app/admin/reference-code/reference-code.service';
import { TasksComponent } from '@app/tasks/tasks.component';
import { NameValueDto } from '../../../../projects/ig-core/src/lib/interfaces/name-value-dto';
@Component({
    selector: 'app-bottom-sheet',
    templateUrl: './bottom-sheet.html',
    styleUrls: ['./bottom-sheet.css'],
})
export class BottomSheetComponent {
    taskForm : FormGroup;
    nameValue :NameValueDto[] = [];
    
    constructor(private formBuilder: FormBuilder,
            private _snackbar: MatSnackBar,
            private taskService: TaskService,
            private referenceCodeService: ReferenceCodeService,
            private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>){
        this.taskForm = formBuilder.group({
            heading : ['', Validators.required],
            description : ['', Validators.required],
            plannedDueDate : [],
            priority : [''],
            linkedItem : [''],
            assignedTo : [''],
            status: ['NEW'],
            openFlag : [true]
        });
        this.getpriority('task_priority');
    }

    createTask() {
      if(this.taskForm.valid) {
        // let pdate=new Date(this.taskForm.value.plannedDueDate.getUTCFullYear(),this.taskForm.value.plannedDueDate.getUTCMonth(), this.taskForm.value.plannedDueDate.getUTCDate());
        // this.taskForm.value.plannedDueDate = pdate;
        this.taskService.createTask(this.taskForm.value).toPromise().then(
          (success) => {
            this._snackbar.open("Task created successfully", "Close", {
              duration: 2000,
            })
            this.taskForm.reset();
            this.closeBottomSheet();
            this.taskService.$taskloader.next(true);
          },
          (failure) => {
            this._snackbar.open("Task creation failed, " + failure, "Close", {
              duration: 2000,
            })
            console.log(failure);
          }
        )
      }
    }

    closeBottomSheet() {
        this.bottomSheetRef.dismiss();
        this.taskService.$taskloader.subscribe()
    }

    getpriority(name: string){
      this.referenceCodeService.getShortRefCodes(name).subscribe((response: any) => {
        this.nameValue = response.task_priority;
      })

    }
}