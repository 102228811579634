import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviationApproval, DeviationApprovalSearchCriteria } from './deviation-approval.model';
@Injectable({
    providedIn: 'root'
})
export class DeviationApprovalService {
    private API = 'api/v1/masters/approvalgrants';
    constructor(private http: HttpClient) { }

    getDeviationApproval(req: any, searchCriteria: DeviationApprovalSearchCriteria)
        : Observable<HttpResponse<DeviationApproval[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        params = params.set('type', req.type = "deviation");
        if (searchCriteria != undefined && searchCriteria.user != null && searchCriteria.user != "") {
            params = params.set('user', searchCriteria.user);
        }
        if (searchCriteria != undefined && searchCriteria.productType != null && searchCriteria.productType != "") {
            params = params.set('productType', searchCriteria.productType);
        }
        if (searchCriteria != undefined && searchCriteria.grant != null && searchCriteria.grant != "") {
            params = params.set('grant', searchCriteria.grant);
        }
        return this.http.get<any>(this.API, { params, observe: 'response' });
    }

    saveDeviationApproval(approvalGrantUid: string, deviationLimit: DeviationApproval): Observable<HttpResponse<DeviationApproval>> {
        if (deviationLimit.uid && deviationLimit.uid != null) {
            return this.http.put<DeviationApproval>(this.API + '/' + approvalGrantUid + '/save', deviationLimit, { observe: 'response' });
        } else {
            return this.http.post<DeviationApproval>(this.API + '/' + 0 + '/save', deviationLimit, { observe: 'response' });
        }
    }
}