import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserAccountRoutingModule, ResolveResetKey } from './user-account-routing.module';
import { LoginComponent} from './login/login.component';
import { MaterialLibModule } from '../material-lib.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoutComponent } from './logout/logout.component';
import { IgCoreModule } from '../../../projects/ig-core/src/lib/ig-core.module';
import { RegisterComponent } from './register/register.component';
import { ActivateComponent } from './activate/activate.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordDialogComponent } from './login/forgot-password-dialog.component';
import {MatDividerModule} from '@angular/material/divider';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UserAccountRoutingModule,
    MaterialLibModule,
    FlexLayoutModule,
    IgCoreModule,
    MatDividerModule
  ],
  declarations: [LoginComponent, LogoutComponent, ResetPasswordComponent, ForgotPasswordDialogComponent, ActivateComponent,
    RegisterComponent],
  exports: [LoginComponent],
  entryComponents: [ForgotPasswordDialogComponent],
  providers: [ResolveResetKey]
})
export class UserAccountModule { }
