import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { ListSource } from '@ig-core/list/list-source';
import {ScorekeyDataSource} from './score-key.data.source'
import { ScorekeyService } from './score-key.service'
@Component({
  selector: 'app-score-key',
  templateUrl: 'score-key.component.html',
  styleUrls: ['./score-key.component.scss']
})
export class ScorekeyComponent implements OnInit, AfterViewInit, OnDestroy {
  pagingAndSortParams: PagingAndSortParams;
  dataSource: ScorekeyDataSource;
  listSource: ListSource;

  displayedColumns: string[] = ['sheetCode', 'section', 'itemDesc', 'startValue', 'endValue', 'score', 'scoreDesc', 'maxScore'];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  constructor(
    private scorekeyService: ScorekeyService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.dataSource = new ScorekeyDataSource(this.scorekeyService);
    this.listSource = new ListSource(
      this.activatedRoute,
      this.paginator,
      this.sort
    );
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        this.getScoreKeyList();
      });
  }


  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  ngAfterViewInit(): void {
    // initiate sort and pagination change once the view is initialized
    this.listSource
      .subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => this.loadBranchsPageWithParams());
  }

  loadBranchsPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  getScoreKeyList() {
    //let sortCriteria = '' + ',' + 'asc';
    //Subscribe to changes in list data
    this.dataSource.getScoreKeyList( '',this.pagingAndSortParams.pageIndex,
      this.pagingAndSortParams.pageSize);
  }


}
