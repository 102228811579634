import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { LeadsComponent } from './leads.component';
import { LeadsResolverService } from './leads-resolver.service';
import { LeadDetailsComponent } from './leads-details/lead-details.component';
import { LeadRouteGuardService } from './leads-details/lead-route-guard.service';
import { LeadQuickViewComponent} from './lead-quick-view/lead-quick-view.component';
import { LeadsViewComponent} from './leads-view/leads-view.component';
const routes: Routes = [
  {
    path: 'leads',
    canActivate: [AuthenticatedUserService,LeadRouteGuardService],
    children: [
      {
        path: '',
        component: LeadsComponent
      },
     
      {
        path: ':leadsUid',
        component: LeadDetailsComponent,
        resolve: {
          application: LeadsResolverService
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadsRoutingModule { }