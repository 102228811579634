import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { Task } from './task.model';

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    

    private API = 'api/tasks';
    public $taskloader= new Subject<boolean>();
    
    constructor(private http: HttpClient,
        private customDatepipe: DateFormatPipe,) {}

    createTask(task: any): Observable<HttpResponse<any>> {
        const copy: any = Object.assign({}, task);
        copy.plannedDueDate = this.customDatepipe.transform(copy.plannedDueDate,'ISODATE' );
        return this.http.post<any>(this.API, copy, {observe: 'response'});
    }
    getAllTask(req: any){
        if(req !== null) {
            let params = new HttpParams();
            if(req.toDate != null) {
                const dateValue= this.customDatepipe.transform(req.toDate,'ISODATE' );
                params = params.set('toDate', dateValue);
            }
            if(req.status != null) {
                params = params.set('status', req.status);
            }
            return this.http.get<any>('api/tasks/fetchTask', {params,observe: 'response'});
        }else {
            return this.http.get<any>('api/tasks/fetchTask', {observe: 'response'});
        }
    }
    updateTask(task:any): Observable<HttpResponse<any>>{
        const copy: any = Object.assign({}, task);
        copy.plannedDueDate = this.customDatepipe.transform(copy.plannedDueDate,'ISODATE' );
        return this.http.put<any>(this.API, copy,{observe: 'response'}); 
    }

    findTask(req: any): Observable<HttpResponse<Task[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        if(req.toDate != null) {
            const dateValue= this.customDatepipe.transform(req.toDate,'ISODATE' );
            params = params.set('toDate', dateValue);
        }
        if(req.status != null) {
            params = params.set('status', req.status);
        }
        // if(req.fromDate !== undefined || req.toDate !== undefined|| req.status !== undefined) {
            return this.http.get<Task[]>('api/tasks/fetchTask', {
                params, observe: 'response'
            });
        // } else {
        //     return this.http.get<Task[]>(this.API, {
        //         params, observe: 'response'
        //     });
        // }
       
    }
    getStatusCount(){
        return this.http.get<any>('api/tasks/get-status-count', {observe: 'response'});
    }

    getByLink(linkType: string, linkUid: string) {
        let params = new HttpParams();
        params = params.set('linkToType', linkType);
        params = params.set('linkToUid', linkUid);
        return this.http.get<Task[]>(this.API + '/getBylink', {params, observe: 'response'});
    }
}

 