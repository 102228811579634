import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, EventManager, JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { ConfigureService } from '../build-config/configure.service';
import { LoanOdAccountsService } from './loan-od-accounts.service';
import { ReverseCompositeDto } from './reverse-composite-dto';
import { TransactionSummaryDto } from './transaction-summary-dto.model';

@Component({
    selector: 'jhi-loan-od-accounts-reverse-execute',
    templateUrl: './loan-od-accounts-reverse-execute.component.html'
})
export class LoanOdAccountsReverseExecuteComponent implements OnInit, OnDestroy {

    reverseCompositeDto: ReverseCompositeDto;
    transactionSummaryDto: TransactionSummaryDto[] = [];
    private subscription: any;
    private eventSubscriber: Subscription;
    BASE_URL: string;
    date = '';
    month = '';

    constructor(private eventManager: EventManager,
        private jhiLanguageService: JhiLanguageService,
        private loanOdAccountsService: LoanOdAccountsService,
        private router: Router,
        private route: ActivatedRoute,
        public alertService: AlertService,
        private configService: ConfigureService) {
        this.reverseCompositeDto = this.loanOdAccountsService.getReverseExecute();
        this.transactionSummaryDto = this.loanOdAccountsService.getTransactionSummaryDto();
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
        });
        this.registerChangeInInternalSettings();
        this.BASE_URL = this.configService.buildUrl();
    }

    load(id) {
        this.loanOdAccountsService.find(id).subscribe((reverseCompositeDto) => {
            this.reverseCompositeDto = reverseCompositeDto;
        });
    }
    previousState() {
        window.history.back();
    }
    ngOnDestroy() {
    }

    registerChangeInInternalSettings() {
        this.eventSubscriber = this.eventManager.subscribe('loanOdAccountsListModification', (response) => this.load(this.reverseCompositeDto.id));
    }
    confirm() {
        this.loanOdAccountsService.reverseExecute(this.transactionSummaryDto[0].accountId, this.transactionSummaryDto[0].transactionId,
            this.transactionSummaryDto[0].transactionName, this.transactionSummaryDto[0].valueDate.toString()).subscribe(
                (res: any) => this.goTo(res), (res: any) => this.onReversalError(res));
    }

    goTo(res: any) {
        this.alertService.success(this.transactionSummaryDto[0].transactionName + ' Reversed Success');
        this.router.navigate(['/loan-od-accounts/view/' + this.transactionSummaryDto[0].accountId]);
    }

    cancel() {
        this.router.navigate(['/loan-od-accounts']);
    }
    private onReversalError(error) {
        console.log(error);
        try {
            const errorString = error.error.text ? error.error.text : error.error.localizedMessage;
            if (errorString) {
                this.alertService.error(errorString);
            } else {
                this.onError(error);
            }
        } catch (exception) {
            try {
                const errorString = error.error.text;
                if (errorString) {
                    this.alertService.error(errorString);
                } else {
                    this.onError(error);
                }
            } catch (exception) {
                this.onError(error);
            }
        }
        this.router.navigate(['/loan-od-accounts/view/' + this.transactionSummaryDto[0].accountId]);
    }

    private onError(error) {
        this.alertService.error(error.error);
    }

}
