import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { ChartsModule } from 'ng2-charts';
import { UserModule } from './user/user.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AdminRoutingModule } from './admin-routing.module';
import { MaterialLibModule } from '../material-lib.module';
import { BranchModule } from '@app/admin/branch/branch.module';
import { BranchSetModule } from '@app/admin/branch-set/branch-set.module';
import { RoleModule } from '@app/admin/role/role.module';
import { ReferenceCodeModule } from '@app/admin/reference-code/reference-code.module';
import { AdminComponent } from './admin.component';
import { BranchListComponent } from './branch/branch-list/branch-list.component';
import { OrganizationStructureComponent } from './organization-structure/organization-structure.component'
import { AllPipesModule } from '../utils/pipes.module';
import { UserListComponent } from './user/user-list.component';
import { ReferenceCodeListComponent } from './reference-code/reference-code-list/reference-code-list.component';
import { RoleListComponent } from './role/role-list/role-list.component';
import { RouterModule } from '@angular/router';
import { DeviceComponent } from './devices/device.component';
import { OrganizationStructureModule } from './organization-structure/organization-structure.module';
import { ApprovalLimit } from './approval-limits/approval-limit.component';
import { DeviceModule } from './devices/device.module';
import { DeviationApproval } from './deviation-approval/deviation-approval.component';
import { ApprovalLimitModel } from './approval-limits/approval-limit.module';
import { DeviationApprovalModel } from './deviation-approval/deviation-approval.module';
import { ProductMaster } from './product-master/product-master-list.component';
import { ProductMasterModel } from './product-master/product-master.module';
import { GoToPageModule } from '@app/utils/go-to-page/go-to-page.module';
import { ScorekeyModule } from './score-key/score-key.module';
import { WorkflowModule } from './workflow/workflow.module';
import { WorkflowComponent } from './workflow/workflow.component';
import { ProductSetModule } from './product-set/product-set.module';
import { ProductSetListComponent } from './product-set/product-set-list/product-set-list.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MaterialLibModule,
    NgxJsonViewerModule, /*https://github.com/hivivo/ngx-json-viewer#readme*/
    FlexLayoutModule,
    ChartsModule,
    HttpClientModule,
    UserModule,
    RoleModule,
    AdminRoutingModule,
    BranchModule,
    BranchSetModule,
    ReferenceCodeModule,
    AllPipesModule,
    RouterModule,
    OrganizationStructureModule,
    DeviceModule,
    ApprovalLimitModel,
    DeviationApprovalModel,
    ProductMasterModel,
    GoToPageModule,
    ScorekeyModule,
    WorkflowModule,
    ProductSetModule
  ],

  declarations: [
    AdminComponent,
    BranchListComponent,
    OrganizationStructureComponent,
    UserListComponent,
    ReferenceCodeListComponent,
    RoleListComponent,
    DeviceComponent,
    ApprovalLimit,
    DeviationApproval,
    ProductMaster,
    WorkflowComponent,
    ProductSetListComponent
  ],


  exports: [],
  entryComponents: [

  ],
  providers: []
})
export class AdminModule { }
