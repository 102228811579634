import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable()
export class SnackbarService {

    timeOut = 6000;


  constructor(  public snackBar: MatSnackBar) { }

  
  openSnackBar(message, action: string) {

    
    if ( message instanceof Array) {

        message.forEach( (message, index) => {

            setTimeout(() => {
              
                this.snackBar.open(message, action, {
                    duration: this.timeOut,
                    verticalPosition: 'bottom', // 'top' | 'bottom'
                    horizontalPosition: 'start', //'start' | 'center' | 'end' | 'left' | 'right'
                    //panelClass: [className],
                });
                

            }, index * (1000)); // 500 - timeout between two messages

        });


    } else {

      this.snackBar.open(message.text, action, {
        duration: this.timeOut,
        verticalPosition: 'bottom', // 'top' | 'bottom'
        horizontalPosition: 'end', //'start' | 'center' | 'end' | 'left' | 'right';
       // panelClass: [className],
      });

    }


  }
}