
import { Component, Input, OnInit } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { MatSnackBar } from '@angular/material';
import { ApplicationService } from '../../../application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Deviation } from '../cam.model';
import { ValidationService } from '../../../application-validators/validation.service';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
@Component({
  selector: 'eng-cam-deviation',
  templateUrl: './deviation.template.html',
  styleUrls: ['../../application-details.styles.scss', 'deviation.style.css']
})

export class DeviationComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;

  deviationList: Deviation[];
  deviationCategories: NameValueDto[];
  deviationcodes: Array<any> = [];
  deviationcodesAsIndex: any = []
  deviationForm: FormGroup;

  isFormDisbled: Boolean = true;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(public toast: MatSnackBar,
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,
    private customDatepipe: DateFormatPipe,
    private validationService: ValidationService,
    private confirmationDialogService: ConfirmationDialogService) {
      this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.deviationForm = this.formBuilder.group({
      deviationList: this.formBuilder.array([])
    })
    this.getRefCodes()
    this.getMenuItemAccess();

  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('deviation_type').subscribe((response: any) => {
      this.deviationCategories = response.deviation_type;
    });
    this.referenceCodeService.getRefCodesForClassifier('deviation_item').subscribe((response: any) => {
      this.deviationcodes = response;
      this.getDeviationList()
    });

  }

  getDeviationList() {
    this.applicationService.getDeviations(this.application.uid).subscribe((response) => {
      this.deviationList = response.body.deviation;
     this.buildFormArrayWithData()
    })
  }
  buildFormArrayWithData(){
    // on cancel deviationCode was not populating properly so we have made below changes.
    // based on matching deviationList's category we are getting list of deviationCode and pushing it to deviationcodesAsIndex
    // on changing of category we are updating matching deviationCode in deviationcodesAsIndex to that particular index.
    // On cancel for deviationcodesAsIndex it will push the matching value instead of updaing that particular index value, hence we are get miss match in indexvalue hence deviationCode was not populating
    // to fix this if we set deviationcodesAsIndex as empty now deviationCode list will match with deviationcodesAsIndex index values and populate properly
    this.deviationcodesAsIndex = []
    const array = this.deviationForm.controls.deviationList as FormArray;
    this.deviationList.forEach(element => {
      const group = new FormGroup({
        uid:new FormControl(element.uid),
        category: new FormControl(element.category),
        deviationCode: new FormControl(element.deviationCode),
        description: new FormControl(element.description),
        approvedFlag:new FormControl(element.approvedFlag),
      });
      this.applyValidatorsToDeviationForm(group)
      array.push(group);
      if (element.category) {
        const codes = this.deviationcodes.filter(item => item.parentReferenceCode === element.category)
        this.deviationcodesAsIndex.push(codes);
      }
    });
    this.deviationForm.disable();
  }
  async addNew() {
    this.deviationForm.enable();
    this.disableCardBasedapprovedFlag()
    const newArray = this.deviationForm.controls.deviationList as FormArray;
    const group = new FormGroup({
      category: new FormControl(''),
      deviationCode: new FormControl(''),
      description: new FormControl(''),
      approvedFlag: new FormControl(false)
    });
    this.applyValidatorsToDeviationForm(group)
    newArray.push(group);
  }
  checkValue(event, index) {
    this.deviationForm.get('deviationList')['controls'][index].controls.deviationCode.patchValue("")
    if (this.deviationcodes.length > 0) {
      const codes = this.deviationcodes.filter(item => item.parentReferenceCode === event.value)
      if (index !== -1) {
        this.deviationcodesAsIndex[index] = codes;
      }
    }
  }
  enableDisableForm() {
    this.deviationForm.enable();
    this.disableCardBasedapprovedFlag()
  }

  cancelForm() {
    this.deviationForm.get('deviationList')['controls'] =[]
    this.buildFormArrayWithData()
  }

  saveBusinessExpenses() {
    this.validationService.markFormGroupTouched(this.deviationForm)
    this.deviationForm.updateValueAndValidity();
    if (this.deviationForm.valid) {
      let deviationsFormArray = this.deviationForm.get('deviationList')['controls'];
      deviationsFormArray.forEach((deviationFormGroup, index) => {
        if (index === this.deviationList.length) {
          let newDeviation = new Deviation();
          newDeviation.contextType = 'application';
          newDeviation.contextUid = this.application.uid;
          this.deviationList.push(newDeviation);
        }
        this.deviationList[index].category = deviationFormGroup.controls.category.value;
        this.deviationList[index].deviationCode =  deviationFormGroup.controls.deviationCode.value;
        this.deviationList[index].description = deviationFormGroup.controls.description.value;
      });
      this.applicationService.saveCamDeviations(this.application.uid,
        this.deviationList).toPromise().then(
          (_success) => {
            this._snackbar.open("Deviations updated successfully", "Close", {
              duration: 2000,
            });
            this.deviationForm = this.formBuilder.group({
              deviationList: this.formBuilder.array([])
            })
            this.getDeviationList();
          }, (failure) => {
            let errormesg =[]
            errormesg.push("Failed to update deviations")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
          }
        );
    }
  }
  arrayList(i): any[] {
    return this.deviationcodesAsIndex[i];
  }
  disableCardBasedapprovedFlag(){
    const newArray = this.deviationForm.get('deviationList')['controls']
    newArray.forEach((element,index) => {
      if(element.controls.approvedFlag != undefined){
        if(element.controls.approvedFlag.value === true ){ 
          element.disable()
        }
      }
     
    });
  }

  //this function will apply validators to form group
  applyValidatorsToDeviationForm(group) {
    this.validationService.applyValidationRules(group, "CAMDeviation")
      .then((controlValidators) => {
        //this.buildFormArrayWithData();
      }).catch(() => { });
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

    openDeleteConfirmation(index:number){
      this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this deviation?"})
      this.confirmationDialogService.confirmed().subscribe(data=>{
        if(data){
        this.deleteDeviation(index)
        }
      })
    
  }

  deleteDeviation(index:number){
    const selectedDeviation = this.deviationList[index];
    const newArray = this.deviationForm.controls.deviationList as FormArray;
    if(selectedDeviation && selectedDeviation.uid){
      this.applicationService.deviationDelete(this.application.uid, selectedDeviation.uid).subscribe((response) => {
        if(response.body.status == "success") {
          this.deviationList.splice(index, 1);
          newArray.removeAt(index)
          this.deviationcodesAsIndex.splice(index, 1)
        this._snackbar.open("Deviation deleted successfully", "Close", {
          duration: 2000,
        });
      } else {
        let errormesg =[]
        errormesg.push("Deviation deletion failed")
        errormesg.push(response.body.message)
        this.applicationService.displayErrorMessages(errormesg);
      }}, (failure) => {
        let errormesg =[]
        errormesg.push("Deviation deletion failed")
        errormesg.push(failure)
        this.applicationService.displayErrorMessages(errormesg);
      });
    }
    else {
      newArray.removeAt(index)
    }
  }

}