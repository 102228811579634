import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JhiLanguageService } from 'ng-jhipster';
import { AccountMandateDto } from './account-mandate-dto.model';

@Component({
    selector: 'jhi-loan-od-account-amend-account',
    templateUrl: './loan-od-account-amend-account.component.html'
})
export class LoanOdAccountsAmendAccountComponent implements OnInit, OnDestroy {
    onError: any;
    accountId: string = null;

    accountMandateDto: AccountMandateDto;
    constructor(private jhiLanguageService: JhiLanguageService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }
    ngOnInit() {
        this.accountMandateDto = new AccountMandateDto();
        this.accountMandateDto.accountId = this.route.params['_value'].accountId;
        // this.jhiLanguageService.setLocations(['loanOdAccounts']);
    }

    ngOnDestroy() {
    }

    previousState() {
        window.history.back();
    }

    clear() {
        this.accountMandateDto = new AccountMandateDto();
        this.router.navigate(['/loan-od-accounts']);
    }

    createNewLoan() {
        this.router.navigate(['/loan-od-accounts/create']);
    }

    setElectronicMandate(accountId) {
        /*this.loanODAccountsService.setElectronicMandate(accountId).subscribe(
            (res: Response) => this.goTo(res));*/
    }
    goTo(res) {
        /*this.accountMandateDto = res;
        this.router.navigate(['/loan-od-accounts/setElectronicMandate', this.accountMandateDto.accountId]);*/
    }
}
