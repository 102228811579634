import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AlertService } from 'ng-jhipster';
//import { TransactionSummary } from '../../casa/casaaccount/transaction-summary.model';
import { LoanOdAccountsService } from './loan-od-accounts.service';

@Injectable()
export class LoanOdAccountsCollectionsResolve implements Resolve<any> {

    constructor(private loanOdAccountsService: LoanOdAccountsService,
        private alertService: AlertService) { }

    resolve() {
        return this.loanOdAccountsService.collectionsEntry().map(
            (res) => res.json()
        ).catch(
            (err) => {
                this.onFetchError(err);
                return null;
            }
        );
    }

    private onFetchError(error: any) {
        try {
            const errorString = error.json().localizedMessage;
            if (errorString) {
                this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
            } else {
                this.onError();
            }
        } catch (exception) {
            try {
                const errorString = error.text();
                if (errorString) {
                    this.alertService.error('encoreclientApp.loanOdAccounts.serverError', { message: errorString });
                } else {
                    this.onError();
                }
            } catch (exception) {
                this.onError();
            }
        }
    }

    private onError() {
        this.alertService.error('encoreclientApp.loanOdAccounts.saveError');
    }
}
