import { Component, OnInit, Input,Inject,HostListener } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'eng-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  documents:Array<File>=[]
  fullresolutionImage: any
  myThumbnail: any
  myFullresImage: any

  state: string = 'default';

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: {btnCancelText:string,btnConfirmText:string,message:string}
  ,private mdDialogRef: MatDialogRef<ConfirmationDialogComponent>) {
   }

  public close(value) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }
  @HostListener("keydown.esc")
  public onEsc() {
    this.close(false);
  }
  test(){
    this.data
  }
  ngOnInit() {
  }

 
}
