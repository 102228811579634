import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialLibModule } from '@app/material-lib.module';
import { EditableResolveService } from '@ig-core/resolves/editable-resolve.service';
import { NonEditableResolveService } from '@ig-core/resolves/non-editable-resolve.service';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { ApprovalLimit } from './approval-limit.component';
import { ApprovalLimitEditComponent } from './approval-limit-edit/approval-limit-edit.component';

export const approvalLimitRoute: Route = {
  path: 'approval-limits',
  canActivate: [AuthenticatedUserService],
  children: [
    { path: '', component: ApprovalLimit },
    {path: 'create', component: ApprovalLimitEditComponent,
    resolve: {
      editable: EditableResolveService
    }},
    {path: 'update/:uid', component: ApprovalLimitEditComponent,
      resolve: {
        editable: EditableResolveService
      }
    },
    {path: 'view/:uid', component: ApprovalLimitEditComponent,
      resolve: {
        editable: NonEditableResolveService
      }
    }]
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialLibModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [ApprovalLimitEditComponent],
  entryComponents: []
})
export class ApprovalLimitModel { }
