import { Injectable } from '@angular/core';
import {ConfigureService} from '../../../../../../src/app/build-config/configure.service';
import { environment } from '../../../../../../src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class SocialService {

    constructor(private configService: ConfigureService) {}

    getProviderSetting(provider) {
        switch (provider) {
            case 'google': return 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
            case 'facebook': return 'public_profile,email';
            case 'twitter': return '';
            // jhipster-needle-add-social-button
            default: return 'Provider setting not defined';
        }
    }

    getProviderURL(provider) {
        return environment.API_URL + '/' + 'signin/' + provider;
    }
}
