import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';
import { CollectionViewer } from '@angular/cdk/collections';
import { ProductMasterComposite } from './product-master.model';
import { ProductMasterService } from './product-master.service';


export class ProductMasterSource extends DataSource<ProductMasterComposite> {

  private productMasterSubject = new BehaviorSubject<ProductMasterComposite[]>([]);
  public totalCount$ = new BehaviorSubject<number>(0);

  constructor(private productMasterService: ProductMasterService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<ProductMasterComposite[]> {
    return this.productMasterSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.productMasterSubject.complete();
    this.totalCount$.complete();
  }

  getProductMaster(sortDirection = 'asc', pageIndex = 0, pageSize = 10) {
    this.productMasterService.getProductMasterList({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
    }).pipe(
      catchError(() => of([])),
      finalize(() => { })
    ).pipe(take(1)).subscribe((response) => {
      this.totalCount$.next(response['body'].totalElements);
      this.productMasterSubject.next(response['body'].content);
    });
  }
}