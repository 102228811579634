import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto, } from '@ig-core/interfaces/name-value-dto';
import { ApplicationAccessSpecifier, LoanApplication, LoanProduct, EmiComputeCriteria } from '@app/applications/applications.model';
import { MatSnackBar } from '@angular/material';
//validation code
import { ValidationService } from '../../../application-validators/validation.service'
import { ControlValidators } from '../../../application-validators/control-validators.directive'
import { HttpResponse } from '@angular/common/http';
import { LoggedInUserService } from '@ig-core/authentication/logged-in-user.service';
@Component({
  selector: 'eng-loan-proposed',
  templateUrl: 'loan-proposed.template.html',
  styleUrls: ['../../application-details.styles.scss', 'loan-proposed.styles.css']
})
export class LoanProposedComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode: string;
  account: any;
  loanProposedForm: FormGroup;
  productTypes: NameValueDto[];
  secutityOfferedTypes: NameValueDto[];
  loanCategoryTypes: NameValueDto[];
  loanPurposeTypes: NameValueDto[];
  allLoanTypes: any;
  securedLoanTypes: Array<any> = [];
  unSecuredLoanTypes: Array<any> = [];
  //this to pass data to parent component when application data chnaged
  @Output() applicationDataChanged: EventEmitter<object> = new EventEmitter();

  @Output() presentProduct: EventEmitter<object> = new EventEmitter();

  @Output() closeChooseProductDialog = new EventEmitter<any>();

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  loanProducts: LoanProduct[];
  requestedloanProducts: LoanProduct[];
  matchingProduct: LoanProduct;
  requestedLoanProduct: LoanProduct;
  localPrestine: any;

  minLoanAmount: number;
  maxLoanAmount: number;

  cfaComputeValidationFailed: boolean;
  cfaComputeValidationError: string = "Please enter amount, tenure and rate of interest."
  applicationState: string;

  cfaFinalized: boolean;
  isFormDisabled: boolean;
  isCollateralFinalized: boolean;
  allowAccess: boolean;
  menuItemAllowAccess: boolean;
  // isButtonDisabled is used to make choose button disable, after final approval we need to disable choose button
  isButtonDisabled: boolean;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,
    private loggedInUserService: LoggedInUserService,
    private validationService: ValidationService) {
    this.loanProposedForm = this.formBuilder.group({
      proposedProductType: '',
      proposedProductCode: '',
      proposedProductName: '',
      proposedRequestedAmount: '',
      proposedTenure: '',
      proposedInterestRate: '',
      proposedPurpose: '',
      proposedSecurityOffered: '',
      proposedEmi: '',
      proposedAffordableEmi: '',
      requestedProduct: '',
      requestedProductName: '',
      requestedRequestedAmount: '',
      requestedTenure: '',
      requestedInterestRate: '',
      requestedPurpose: '',
      requestedSecurityOffered: '',
      purposeTypeProposed:'',
      purposeTypeRequested: '',
      statusFlagsGroup: this.formBuilder.group({
        provisionalApprovalFlag: { value: true, disabled: true },
        finalApprovalFlag: { value: false, disabled: true },
        tenureChangeApprovalFlag: { value: false, disabled: true },
        roiChangeApprovalFlag: { value: false, disabled: true },
        repaymentAccountChangeApprovalFlag: { value: false, disabled: true },
        applyTenPercentDeviation: { value: false, disabled: true }
      }),
      loanCategory: '',
      surrogateEmi: ''
    });
  }

  ngOnInit() {
    this.isFormDisabled = true;
    this.getRefCodes();
    this.getApplication(this.application.uid);
    this.getCollateralStatus()
    this.getApplicationState();
    this.getMenuItemAccess();
    this.getCfaStatus();
    this.getloanrequestedProductStatus();
    this.allowAccess = !this.application.approvedFlag && this.applicationService.allowAccess;
  }

  getRefCodes() {
    this.referenceCodeService.getRefCodesForClassifier('loan_type').subscribe((response: any) => {
      this.productTypes = response
      // this.allLoanTypes.forEach(element => {
      //   if(element.field2 === 'CHK-COLLATERAL'){
      //     this.securedLoanTypes.push(element)
      //   }else{
      //     this.unSecuredLoanTypes.push(element)
      //   }
      // });

      // let applicationProductType =this.application.productTypeProp ==null?this.application.productTypeReq:this.application.productTypeProp
      // this.securedLoanTypes.forEach(element => {
      //   if(element.code == applicationProductType ){
      //     this.productTypes =this.securedLoanTypes
      //   }
      // });
      // this.unSecuredLoanTypes.forEach(element => {
      //   if(element.code == applicationProductType ){
      //     this.productTypes =this.unSecuredLoanTypes
      //   }
      // });
    });
    // this.referenceCodeService.getShortRefCodes('loan_type').subscribe((response: any) => {
    //   this.productTypes = response.loan_type;
    // });
    this.referenceCodeService.getShortRefCodes('collateral_security').subscribe((response: any) => {
      this.secutityOfferedTypes = response.collateral_security;
    });

    this.referenceCodeService.getShortRefCodes('loan_category').subscribe((response: any) => {
      this.loanCategoryTypes = response.loan_category;
    });

    this.referenceCodeService.getShortRefCodes('loan_purpose').subscribe((response: any) => {
      this.loanPurposeTypes = response.loan_purpose;
      // to show "Others" option at last we are removing "Other" option from list and adding it as last value
      let indexValue = this.loanPurposeTypes.findIndex(ele => ele.code === 'OTH');
      if (indexValue !== -1) {
        let removedElement = this.loanPurposeTypes.splice(indexValue, 1)[0];
        this.loanPurposeTypes.push(removedElement);
      }
    });

    this.loggedInUserService.get().subscribe(response => {
      this.account = response.body;
    })
  }

  getApplication(applicationUid: string) {
    this.applicationService.getApplication(applicationUid)
      .subscribe((response: HttpResponse<LoanApplication>) => {
        this.application = response.body;
        this.getLoanProducts();
      });
  }

  getLoanProducts() {
    this.referenceCodeService.getLoanProducts(this.application.productTypeProp)
      .subscribe((response: any) => {
        this.loanProducts = response.body;
        this.loanProducts.forEach(loanProduct => {
          if (!this.minLoanAmount || loanProduct.minAmount < this.minLoanAmount) {
            this.minLoanAmount = loanProduct.minAmount;
          }
          if (!this.maxLoanAmount || loanProduct.maxAmount > this.maxLoanAmount) {
            this.maxLoanAmount = loanProduct.maxAmount;
          }
        });
        this.applyValidatorsToLoanProposed();
      });
    this.referenceCodeService.getLoanProducts(this.application.productTypeReq)
      .subscribe((response: any) => {
        this.requestedloanProducts = response.body;
        if (this.application.productTypeProp == null) {
          this.loanProducts = this.requestedloanProducts
          this.requestedloanProducts.forEach(loanProduct => {
            if (!this.minLoanAmount || loanProduct.minAmount < this.minLoanAmount) {
              this.minLoanAmount = loanProduct.minAmount;
            }
            if (!this.maxLoanAmount || loanProduct.maxAmount > this.maxLoanAmount) {
              this.maxLoanAmount = loanProduct.maxAmount;
            }
          });
          this.applyValidatorsToLoanProposed();
        }
      });
  }

  //this function will apply validators to form group
  applyValidatorsToLoanProposed() {
    this.validationService.applyValidationRules(this.loanProposedForm, "LoanProposed")
      .then((controlValidators) => {
        this.buildLoanApplicationForm(this.application);
      }).catch(() => { });
  }

  buildLoanApplicationForm(loanApplication) {
    let purposeProposed
    let purposeTypeProposed
    if (loanApplication.purposePropCode == null || loanApplication.purposePropCode == "") {
      purposeProposed = loanApplication.purposeReq
      purposeTypeProposed = loanApplication.purposeReqCode
    } else {
      purposeProposed = loanApplication.purposeProp
      purposeTypeProposed = loanApplication.purposePropCode
    }
    this.loanProposedForm.patchValue({
      proposedProductName: loanApplication.productNameProp == null
        || loanApplication.productNameProp == '' ?
        loanApplication.productName : loanApplication.productNameProp,
      proposedProductType: loanApplication.productTypeProp == null
        || loanApplication.productTypeProp == '' ?
        loanApplication.productTypeReq : loanApplication.productTypeProp,
      proposedRequestedAmount: loanApplication.amountProp == null
        || loanApplication.amountProp == '' ?
        loanApplication.amountReq : loanApplication.amountProp,
        proposedInterestRate: loanApplication.interestProp == null
        || loanApplication.interestProp == ''?
        loanApplication.interestReq : loanApplication.interestProp,
        proposedProductCode: loanApplication.productCodeProp == null
        || loanApplication.productCodeProp == '' ?
        loanApplication.productCodeReq : loanApplication.productCodeProp,
      proposedTenure: loanApplication.tenureProp == null
        || loanApplication.tenureProp == '' ?
        loanApplication.tenureReq : loanApplication.tenureProp,
      proposedPurpose: purposeProposed,
      purposeTypeProposed: purposeTypeProposed,
      proposedSecurityOffered: loanApplication.securityOfferedProp == null
        || loanApplication.securityOfferedProp == '' ?
        loanApplication.securityOfferedReq : loanApplication.securityOfferedProp,
      proposedEmi: loanApplication.emi,
      proposedAffordableEmi: loanApplication.affordableEmi,
      requestedProduct: loanApplication.productTypeReq,
      requestedProductName: loanApplication.productName,
      requestedRequestedAmount: loanApplication.amountReq,
      requestedTenure: loanApplication.tenureReq,
      requestedInterestRate: loanApplication.interestReq,
      requestedPurpose: loanApplication.purposeReq,
      purposeTypeRequested: loanApplication.purposeReqCode,
      requestedSecurityOffered: loanApplication.securityOfferedReq,
      statusFlagsGroup: {
        provisionalApprovalFlag: loanApplication.provisionalApprovedFlag,
        finalApprovalFlag: loanApplication.finalApprovedFlag,
        tenureChangeApprovalFlag: loanApplication.tenureChangeApprovedFlag,
        roiChangeApprovalFlag: loanApplication.roiChangeApprovedFlag,
        repaymentAccountChangeApprovalFlag: loanApplication.bankAcChangeApprovedFlag,
        applyTenPercentDeviation: loanApplication.maxLoanWithDeviationFlag
      },
      loanCategory: loanApplication.loanCategory,
      surrogateEmi: loanApplication.surrogateEmi
    });
    this.loanProposedForm.controls['proposedRequestedAmount']
      .setValidators([ControlValidators.numericRangeValidator(this.minLoanAmount,
        this.maxLoanAmount, true, true, "Loan amount should be in the range.. " +
        this.minLoanAmount.toLocaleString('en-IN') + " to " +
      this.maxLoanAmount.toLocaleString('en-IN')), ControlValidators.patternValidator("[0-9]*", "Amount shoud be number,Decimal values are not allowed")]);
    this.loanProposedForm.controls['proposedRequestedAmount'].updateValueAndValidity()
    this.loanProposedForm.controls['proposedProductType'].disable();

    // this localPrestine is used to store the intrestRate value and productCode locally and to prepopulate the same value if there is change from product 'a' to product 'b'
    this.setPrestineValues(loanApplication);
    this.setMatchingProduct()

    this.loanProposedForm.controls['purposeTypeProposed'].disable();
    this.loanProposedForm.controls['proposedPurpose'].disable();
  }

  setPrestineValues(loanApplication){
    // this localPrestine is used to store the intrestRate value and productCode locally and to prepopulate the same value if there is change from 
    // product 'a' to product 'b' and without saving if we change back to product 'a' it should show the saved value not the maxInterest  
    this.localPrestine = {
      proposedInterestRate: loanApplication.interestProp == null
      || loanApplication.interestProp == ''?
      loanApplication.interestReq : loanApplication.interestProp,
      proposedProductCode: loanApplication.productCodeProp == null
          || loanApplication.productCodeProp == '' ?
          loanApplication.productCodeReq : loanApplication.productCodeProp,
    }
  }
  
  clearTenureAndInterestValidators(){
    let tenureControl = this.loanProposedForm.controls['proposedTenure'];
    tenureControl.clearValidators();

    let interestControl = this.loanProposedForm.controls['proposedInterestRate'];
    interestControl.clearValidators();
  }


  validateTenureAndInterest(){
    let tenureControl = this.loanProposedForm.controls['proposedTenure'];
    let interestControl = this.loanProposedForm.controls['proposedInterestRate'];

    if(this.matchingProduct != undefined){
            tenureControl.setErrors({});
            tenureControl.setValidators([ControlValidators.numericRangeValidator(
              this.matchingProduct.minTenure, this.matchingProduct.maxTenure,
              true, true, "Tenure should be in the range.. " +
              this.matchingProduct.minTenure.toLocaleString('en-IN') + " to " +
            this.matchingProduct.maxTenure.toLocaleString('en-IN')), ControlValidators.patternValidator("[0-9]*", "Decimal values are not allowed")]);
  
            interestControl.setErrors({});
            interestControl.setValidators([ControlValidators.numericRangeValidator(
              this.matchingProduct.minInterest, this.matchingProduct.maxInterest,
              true, true, "Interest should be in the range.. " +
              this.matchingProduct.minInterest.toLocaleString('en-IN') + " to " +
            this.matchingProduct.maxInterest.toLocaleString('en-IN')), ControlValidators.maxLengthValidator(5,"Invalid")]);
    }else{
      // if product is not found then matching product will be undefined then we are clearing validators and setting error message for proposed amount field
        this.loanProposedForm.controls['proposedRequestedAmount']
          .setErrors({ customError: 'Product not found for this amount' });
    }
    
    tenureControl.updateValueAndValidity();
    interestControl.updateValueAndValidity();
  }


  setMatchingProduct() {
    this.matchingProduct = this.loanProducts.find(loanProduct => loanProduct.productCode === (this.application.productCodeProp? this.application.productCodeProp : this.application.productCodeReq));
    this.setMinMaxLoanAmount(this.matchingProduct)
  }

  computeEmi() {
    this.validationService.markFormGroupTouched(this.loanProposedForm)
    if (this.loanProposedForm.valid) {
      let emiComputeCriteria = new EmiComputeCriteria();
      let loanProposedFormFields = this.loanProposedForm.controls;
      let amount = loanProposedFormFields.proposedRequestedAmount.value;
      let tenure = loanProposedFormFields.proposedTenure.value;
      let roi = loanProposedFormFields.proposedInterestRate.value;
      if (amount && tenure && roi) {
        this.cfaComputeValidationFailed = false;
        emiComputeCriteria.amount = amount;
        emiComputeCriteria.tenure = tenure;
        emiComputeCriteria.roi = roi;
        this.applicationService.getCalculatedEmi(this.application.uid,
          emiComputeCriteria).subscribe(response => {
            this.loanProposedForm.patchValue({
              proposedEmi: response.body
            });
          });
      } else {
        /* let proposedEmiControl = this.loanProposedForm.controls['proposedEmi'];
        proposedEmiControl.markAsTouched();
        proposedEmiControl.setErrors({'incorrect': true}); */
        this.cfaComputeValidationFailed = true;
      }
    }
  }

  validateApplication() {
    this.validationService.markFormGroupTouched(this.loanProposedForm)
    if (this.loanProposedForm.valid) {
      this.updateApplicationModel();
      this.applicationService.validateApplication(this.application).subscribe(
        (_success) => {
          this._snackbar.open("SUCCESS: Loan application is VALID", "Close", {
            duration: 2000,
          });
        }, (_failure) => {
          let errormesg = []
          errormesg.push("Loan application is validation Faild.")
          errormesg.push(_failure)
          this.applicationService.displayErrorMessages(errormesg);
        }
      );
    }
  }

  updateApplicationModel() {
    let loanProposedFormFields = this.loanProposedForm.controls;
    let applicationStatusFields = this.loanProposedForm.controls.statusFlagsGroup.value;
    this.application.productTypeProp = loanProposedFormFields.proposedProductType.value;
    this.application.productCodeProp = loanProposedFormFields.proposedProductCode.value;
    this.application.productNameProp = this.loanProposedForm.controls.proposedProductName.value;
    this.application.amountProp = loanProposedFormFields.proposedRequestedAmount.value;
    this.application.tenureProp = loanProposedFormFields.proposedTenure.value;
    this.application.interestProp = this.loanProposedForm.controls.proposedInterestRate.value;
    this.application.purposeProp = this.loanProposedForm.getRawValue().proposedPurpose;
    this.application.purposePropCode = loanProposedFormFields.purposeTypeProposed.value;
    this.application.securityOfferedProp = loanProposedFormFields.proposedSecurityOffered.value;
    this.application.affordableEmi = loanProposedFormFields.proposedAffordableEmi.value;
    this.application.provisionalApprovedFlag = applicationStatusFields.provisionalApprovalFlag;
    this.application.finalApprovedFlag = applicationStatusFields.finalApprovalFlag;
    this.application.tenureChangeApprovedFlag = applicationStatusFields.tenureChangeApprovalFlag;
    this.application.roiChangeApprovedFlag = applicationStatusFields.roiChangeApprovalFlag;
    this.application.bankAcChangeApprovedFlag = applicationStatusFields.repaymentAccountChangeApprovalFlag;
    this.application.maxLoanWithDeviationFlag = applicationStatusFields.applyTenPercentDeviation;
    this.application.loanCategory = this.loanProposedForm.controls.loanCategory.value,
      this.application.surrogateEmi = this.loanProposedForm.controls.surrogateEmi.value

  }

  saveLoanApplication() {
    this.validationService.markFormGroupTouched(this.loanProposedForm)
    if (this.matchingProduct.changeFlag) {
      if (this.loanProposedForm.valid && !this.isFormDisabled) {
        this.updateApplicationModel();
        this.applicationService.saveLoanApplication(this.application).subscribe(
          (_success) => {
            this._snackbar.open("Loan proposed application updated successfully", "Close", {
              duration: 2000,
            });
            this.applicationService.getBasicApplicationInfo(
              this.application.uid, 'ApplicationOnly').subscribe((response) => {
                this.application = response.body.applicationDTO;
                this.buildLoanApplicationForm(this.application);
                this.applicationDataChanged.emit(this.application);
                this.isFormDisabled = true;
                this.closeChooseProductDialog.emit()
              });
          }, (_failure) => {
            let errormesg = []
            errormesg.push("Loan application update failed.")
            errormesg.push(_failure)
            this.applicationService.displayErrorMessages(errormesg);

          }
        );
      }
    } else {
      this._snackbar.open("Change is not allowed", "Close", {
        duration: 2000,
      });
    }
  }


  enableDisableForm() {
    this.isFormDisabled = false;
    this.validateTenureAndInterest();
    this.disableLoanProductRelatedFields();
    this.checkPurposeType()
  }

  cancelForm() {
    this.closeChooseProductDialog.emit()
    this.getLoanProducts()
    this.isFormDisabled = true;
    // on click of cancel we need to remove validation applied for all the fields, here we are clearing all the validations on click of cancel
    this.clearAllValidators()
  } 

  // this method is to clear validation applied for all the fields  
  clearAllValidators(){
    Object.values(this.loanProposedForm.controls).forEach(control =>{
      control.clearValidators()
      control.updateValueAndValidity()
    })
  }

  disableLoanProductRelatedFields() {
    this.loanProposedForm.controls['proposedProductType'].disable();
    this.loanProposedForm.controls['proposedProductName'].disable();
    this.loanProposedForm.controls['proposedEmi'].disable();
  // first we are checking for is applicationState final approved or not if approved we need to check for APPROVED-ROI-EDIT authority and 
  // roiChangeApproved falg should be false
  // else if not in finalApproved state we need to check for ROI-EDIT authority if authority present then we need to enable else disable proposedIntrestRate 
    if (this.applicationState == "FINAL_APPROVED") {
      this.loanProposedForm.disable();
      this.isButtonDisabled = true;
      if (!this.application.tenureChangeApprovedFlag) {
        this.loanProposedForm.controls['proposedTenure'].enable();
      } else {
        this.loanProposedForm.controls['proposedTenure'].disable();
      }
      if (!this.application.roiChangeApprovedFlag && this.account.authorities.includes("APPROVED-ROI-EDIT")) {
        this.loanProposedForm.controls['proposedInterestRate'].enable();
      } else {
        this.loanProposedForm.controls['proposedInterestRate'].disable();
      }
    }
    else {
      // we need to enable rateOfInterest based on authority if ROI-EDIT authority is present we need to enable else disable proposedIntrestRate
      if(this.account.authorities.includes("ROI-EDIT")) {
      this.loanProposedForm.controls['proposedInterestRate'].enable();
      } else {
      this.loanProposedForm.controls['proposedInterestRate'].disable();
    }
    //this.loanProposedForm.controls['statusFlagsGroup'].disable();
  }
}

  //this function will get Collateral finalize Status is ture or false
  getCollateralStatus() {
    this.applicationService.getCollateralStatus(this.application.uid)
      .subscribe(response => {
        this.isCollateralFinalized = response.body.collateralFinalized;
      });
  }

  getApplicationState() {
    this.applicationService.getApplicationState(this.application.uid)
      .subscribe(response => {
        this.applicationState = response.body.applicationState;
      });
  }

  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }


  //this function will call a api to get CFA Finalized or not status
  getCfaStatus() {
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response => {
      this.cfaFinalized = response.body.cfaFinalized
    })
  }

  getloanrequestedProductStatus() {
    this.referenceCodeService.getLoanProducts(this.application.productTypeReq)
      .subscribe((response: any) => {
        let requestedloanProducts = response.body
        requestedloanProducts.forEach(product => {
          if (product.productCode == this.application.productCodeReq) {
            this.requestedLoanProduct = product
          }
        });
      });
  }

  openProductSelectionDialog(){
      let existingProductDetails = {
        amount: this.loanProposedForm.controls['proposedRequestedAmount'].value,
        existingProduct: this.matchingProduct,
        cfaStatus: this.cfaFinalized
      };
    
      this.presentProduct.emit(existingProductDetails);
  }
  
  buildFormFromProduct(product,selectedAmount,loanProducts){
    this.matchingProduct = product
    this.loanProducts = loanProducts;
    
    this.loanProposedForm.patchValue({
      proposedProductType: this.matchingProduct.productType,
      proposedProductCode: this.matchingProduct.productCode,
      proposedProductName: this.matchingProduct.productName,
      proposedRequestedAmount: selectedAmount,
      proposedInterestRate: this.matchingProduct.maxInterest
    });

    // this condition is added to retain the interest rate on just change of product and selecting same product again without save 
    // then we need to show the old value instead of maxInterest
    // here in localPrestine has proposedProductCode and proposedInterestRate values
    if(this.localPrestine.proposedProductCode === this.matchingProduct.productCode){
      this.loanProposedForm.patchValue({
        proposedInterestRate: this.localPrestine.proposedInterestRate
      });
    }

    this.validateTenureAndInterest()
    this.setMinMaxLoanAmount(product)
  }

  setMinMaxLoanAmount(product){
    this.minLoanAmount = product.minAmount;
    this.maxLoanAmount = product.maxAmount;

    this.loanProposedForm.controls['proposedRequestedAmount']
      .setValidators([ControlValidators.numericRangeValidator(this.minLoanAmount,
        this.maxLoanAmount, true, true, "Loan amount should be in the range.. " +
        this.minLoanAmount.toLocaleString('en-IN') + " to " +
      this.maxLoanAmount.toLocaleString('en-IN')), ControlValidators.patternValidator("[0-9]*", "Amount shoud be number,Decimal values are not allowed")]);
    this.loanProposedForm.controls['proposedRequestedAmount'].updateValueAndValidity()
    this.validationService.markFormGroupTouched(this.loanProposedForm)
  }

  // based on "purposeTypeProposed" value we need to enable and disable "proposedPurpose"
  // if "purposeTypeProposed" value is "other" then we need to enable "proposedPurpose" else we need to disable hence we are checking this 
  checkPurposeType(){
    this.loanProposedForm.controls['purposeTypeProposed'].enable();
    this.loanProposedForm.controls['proposedPurpose'].disable();
    this.loanProposedForm.controls['proposedPurpose'].setErrors({});
    if(this.loanProposedForm.controls.purposeTypeProposed.value === 'OTH'){
      this.loanProposedForm.patchValue({
        proposedPurpose: this.application.purposeProp
      })
      this.loanProposedForm.controls['proposedPurpose'].enable();
      this.loanProposedForm.controls['proposedPurpose'].setValidators(ControlValidators.requiredValidator("This field is required"))
      this.loanProposedForm.controls['proposedPurpose'].updateValueAndValidity()
    }else{
      this.loanProposedForm.patchValue({
        proposedPurpose: null
      })
    }
  }

}