import { Component, Input, OnInit } from '@angular/core';
import { WorkflowDetailsDTO } from '../workflow/workflow.model';
import { LoanApplication } from '@app/applications/applications.model';
import { WorkflowService } from '../workflow/workflow.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ListSource } from '@ig-core/list/list-source';
import { buildMapFromList } from '@angular/flex-layout/extended/typings/style/style-transforms';
@Component({
  selector: 'eng-workflow',
  templateUrl: './workflow.template.html',
  styleUrls: ['./workflow.styles.scss']
})
export class WorkflowComponent implements OnInit {
  @Input() application: LoanApplication
  workflowDetailsDTOs: WorkflowDetailsDTO[];
  public whoIsWhoSearchForm: FormGroup;
  
  userSearchForm: FormGroup;
  branch: string;
  constructor(private workflowService: WorkflowService, private formBuilder: FormBuilder) { }    
  ngOnInit() {
      this.buildForm();
      this.getWhoisWho(null, null);
  }

  buildForm() {
    this.whoIsWhoSearchForm = this.formBuilder.group({
      branchCode: ''
    });
  }

  getWhoisWho(workflowName?: string, branch?: string) {
   this.workflowService.getWhoIsWho(workflowName, branch).subscribe((response: any) => {
     if (response) {
      this.workflowDetailsDTOs = response.body;
     }
   });
 }

 searchByBranch(){
  this.branch = this.whoIsWhoSearchForm.value.branchCode;
  this.getWhoisWho(null, this.branch);
 }
}
